<template lang="pug">
a-section.ui-prop-wrapper(:wh='$attrs.wh || "100%"', v-bind='$attrs', :throttle='1000', @a-tap='previewProp()', :class='{ "of-h": isOfH }')
  template(v-if='effectInfo.isEffectStateReadying && isShow')
    a-position.tap-through.ui-prop-wrapper__pag(v-if='isOpenEffectPAG && propData?.svg', :bg-i='propData?.svg', v-bind='svgaStyle || { wh: propData?.svgaWh || "100%", pc: "xy", "bg-s": "100%" }')
    a-svga.tap-through.ui-prop-wrapper__svga(v-else-if='isOpenEffect && propData?.svga', :key='gender', :url='propData?.svga', v-bind='svgaStyle || { wh: propData?.svgaWh || "100%", pc: "xy" }')
    ui-prop.ui-prop-wrapper__prop(v-else, :data='propData', :extra='{ ...uiPropExtra, canPreview: false }', v-bind='imgStyle || { wh: "100%", pc: "xy" }')
</template>

<script>
export default {
  name: 'ui-prop-wrapper',

  props: {
    // 源码开发暂时用不到
    uiMeta: {
      type: Object,
      default: () => {
        return {
          props: {
            id: null
          }
        }
      }
    },

    // 道具id，方便源码开发使用
    id: {
      type: [Number, String],
      default: 0
    },

    // 女性道具id,传了之后会根据性别进行展示
    femaleId: {
      type: Number,
      default: 0
    },

    // 独立的道具预览id
    previewId: {
      type: Number,
      default: 0
    },

    // 是否展示道具
    isShow: {
      type: [String, Boolean],
      default: true
    },

    // 是否可以预览
    canPreview: {
      type: [String, Boolean],
      default: true
    },

    // svga的自定义样式，会直接覆盖{ wh: "100%", pc: "xy" }
    svgaStyle: {
      type: Object,
      default: () => {}
    },

    // 静态图的自定义样式，会直接覆盖{ wh: "100%", pc: "xy" }
    imgStyle: {
      type: Object,
      default: () => {}
    },

    // 是否 overflow: hidden
    isOfH: {
      type: Boolean,
      default: false
    },

    // ui-prop 的 extra，全量传给组件内的 ui-prop
    uiPropExtra: {
      type: Object,
      default: () => {
        return {
          // 礼物卡是否展示背景颜色，默认不展示
          showGiftCardBgColor: false,
          // 类型 normal 正方形 circle 圆形，默认normal
          type: 'normal',
          // 特化道具宽高比例
          stylesConfig: {},
          // 是否在阿语服头像框下展示用户头像
          showUserAvatar: false
        }
      }
    }
  },

  computed: {
    effectInfo() {
      return this.$base.effectInfo()
    },
    isOpenEffect() {
      return this.$base.isOpenEffect()
    },
    isOpenEffectPAG() {
      return this.$base.isOpenEffectPAG()
    },

    gender() {
      return this.$base.amBasic()?.user?.gender
    },

    // 道具id
    propId() {
      const maleId = this.uiMeta.props.id || this.id
      if (this.femaleId) {
        return this.gender === 1 ? maleId : this.femaleId
      } else {
        return maleId
      }
    },

    // 道具配置
    propData() {
      return this.$propsConfig(this.propId)
    }
  },

  mounted() {
    const { isOpenEffect, isOpenEffectPAG, propData } = this
    if (isOpenEffect && isOpenEffectPAG && propData.svga && !propData.svg) {
      console.error(`若展示动效，把SVGA转成SVG，ID:${this.propId}`)
    }
  },

  afterInit() {},

  methods: {
    previewProp() {
      if (!this.canPreview) return
      const previewId = this.previewId || this.propId
      this.$propPreview(previewId, this.$propsConfig(previewId))
    }
  }
}
</script>

<style></style>
