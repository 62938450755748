import config from '@/config'

import getQuery from './getQuery'

const query = getQuery()

export const isMock = () => query.mock === 'true' && isLocal()

export const isLocal = () => config.$env.LOCAL

export const isProd = () => config.$env.PROD

export const isDev = () => config.$env.DEV

export const isDebug = () => {
  // 本地使用 debug
  if (isLocal()) {
    return true
  }

  // prod 禁止使用 debug
  if (isProd()) {
    return false
  }

  // dev 环境，加上 debug query 使用 debug
  return isDev() && query.debug === 'true'
}
