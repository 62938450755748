import { PAGE_REGION, IS_WEPLAY } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

const footerWpImage = proxyAssets({
  likeIcon: 'https://fe-center.weplayapp.com/page-center/assets/rio4D9OM/7ZdsIk9s.png?_w=48&_h=48',
  commentIcon: 'https://fe-center.weplayapp.com/page-center/assets/rio4D9OM/9ATtkKfP.png?_w=48&_h=48'
})

const footerHwImage = proxyAssets({
  likeIcon: 'https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/5fA6l0qe.png?_w=48&_h=48',
  commentIcon: 'https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/6xndhnOm.png?_w=48&_h=48'
})

export const commonImage = IS_WEPLAY ? footerWpImage : footerHwImage

const seeMoreRegionText = {
  HW: '查看更多',
  J: 'コメントをもっと表示',
  K: '더보기'
}

export const seeMoreText = seeMoreRegionText[PAGE_REGION]

const langType = {
  HW: 'zh-cn',
  J: 'ja',
  K: 'ko'
}

export const lang = langType[PAGE_REGION]

export const image_wh = {
  1: '4.74 4.2', // 展示一行或者不展示
  2: '4.74 3.85', // 展示2行
  3: '4.74 3.4' // 展示3行
}

export const handleNum = (num = 0) => {
  if (num > 1000) {
    return (num / 1000).toFixed(1) + 'k'
  } else {
    return num
  }
}

export const showPaginationNum = 5 // 展示分页器的数量，一般奇数个
