export default [
  // {
  //   path: '/activity-20220823-main-ar',
  //   component: () => import('./pages/main/page.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.params.id
  //       to.meta.title = to.meta.params.title
  //     },
  //     quickConfig: '85edede918c0343d42ff8de02184a045'
  //   }
  // },
  {
    path: '/activity-20220909-recharge-ar',
    component: () => import('./pages/recharge/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id || 387
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/04/14 23:59:59 UTC+8',
      quickConfig: '4d51a8b4b7f85453889953b6eee6e329'
    }
  },
  {
    path: '/activity-20220909-main-ar',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id || 387
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/04/14 23:59:59 UTC+8',
      quickConfig: '6b1e4e8a4eee5cc14ed077f2767ae42d'
    }
  }
]
