<template lang="pug">
//- 搜索
a-section.search-box.fx-row(:bg-i='useImages.bg', v-bind='defaultStyle.bg')
  a-section(v-bind='defaultStyle.input')
    input.input(v-model='searchVal', :placeholder='text.inputPlaceholder', :style='placeholderStyle')
  a-section(v-bind='defaultStyle.btn', @a-tap='doSearch()')

  //- 搜索结果
  a-position.fx-col(v-if='resultList !== null', w='100%', :pt='defaultStyle.bg.h', pz='1')
    //- 有结果时
    template(v-if='resultList.length > 0')
      a-section(v-for='(item, index) in resultList', :key='index', :bg-i='useImages.result')
        //- 作品编号
        a-position(pc='y', pl='0.18', :style='getResultStyle("workId")') {{ item.id }}
        //- 头像
        a-position(pc='y', pl='1.16', wh='0.78', bdr='50%', bg-s='contain', :bg-i='item.head_img')
        //- 用户昵称
        a-position.ws-nowrap(pl='2.12', pt='0.13', h='0.31', lh='0.31', :style='getResultStyle("nickname")') {{ $ts(item.nickname, 7) }}
        //- 票数
        a-position(pl='2.12', pt='0.5', h='0.34')
          a-position(pl='0', pc='y', :bg-i='images.scoreIcon', bg-s='contain', wh='0.34')
          a-position.ws-nowrap(pl='0.36', pc='y', :style='getResultStyle("voteNumber")') {{ item.vote_count }}
        //- 播放
        play-btn(pl='3.82', pc='y', :bg-i='images.playBtn', :item='item')
        //- 投票
        vote-btn(pl='4.76', pc='y', :bg-i='images.voteBtn', :item='item')
    //- 无结果时
    a-section(v-else, :bg-i='useImages.result')
      a-position(pc, :style='useStyle.result.none') {{ text.none }}
</template>

<script>
import voteBtn from '../components/vote-btn.vue'
import playBtn from '../components/play-btn.vue'

import { genUsePropsMixin } from '../mixins'
import { defaultStyle } from '../config'
import { SEARCH } from '../const'

import { get } from 'lodash'

const isPhone = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
const TOUCHEND_EVENT = isPhone ? 'touchend' : 'mouseup'

export default {
  components: {
    voteBtn,
    playBtn
  },

  mixins: [genUsePropsMixin(SEARCH)],

  props: {},

  data() {
    return {
      defaultStyle: defaultStyle[SEARCH],

      searchVal: ''
    }
  },

  computed: {
    placeholderStyle() {
      return Object.keys(this.useStyle.inputPlaceholder).reduce((total, cur) => {
        total[`--PH${cur}`] = this.useStyle.inputPlaceholder[cur]
        return total
      }, {})
    },

    resultList() {
      return this._storeState('searchResultList')
    }
  },

  mounted() {},

  methods: {
    resetResult() {
      this._storeCommit('setState', { searchResultList: null })
    },

    async doSearch() {
      if (!this.searchVal) {
        this.resetResult()
        return
      }

      this._storeCommit('setState', { searchVal: this.searchVal })
      await this._storeDispatch('doSearch')
      this.addListenClick()
    },

    addListenClick() {
      this.touchWrapper = document.querySelector('#activity-runtime')

      this.touchWrapper.addEventListener(TOUCHEND_EVENT, this.touchEndHandle)
    },

    touchEndHandle(event) {
      let that = this
      const target = event.target
      const box = document.querySelector('.search-box')

      if (!box?.contains(target)) {
        that.resetResult()
        this.touchWrapper.removeEventListener(TOUCHEND_EVENT, that.touchEndHandle)
      }
    },

    getResultStyle(target) {
      return get(this.useStyle, `result.${target}`, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  line-height: 100%;
  padding: 0.18rem;

  &::placeholder {
    color: var(--PHcolor);
    font-weight: var(--PHfontWeight);
    font-size: var(--PHfontSize);
  }
}
</style>
