import get from 'lodash/get'
import { isA, isQ } from '../../utils/config'
import { PAGE_REGION } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

const spStyle = {
  J: { lh: 0.2, fs: 0.18, letterSpacing: '-0.01rem' },
  M: { lh: 0.2, fs: 0.18, letterSpacing: '-0.01rem' },
  T: { lh: 0.2, fs: 0.18, letterSpacing: '-0.01rem' },
  U: { lh: 0.2, fs: 0.18, letterSpacing: '-0.01rem' },
  P: { lh: 0.2, fs: 0.18, letterSpacing: '-0.01rem' },
  K: { lh: 0.25, letterSpacing: '-0.008rem' },
  Q: { lh: 0.2, fs: 0.18, letterSpacing: '-0.01rem' }
}
const commonTextStyle = { fs: '.2', fw: 500, pc: 'x', lh: '.27', ...(spStyle[PAGE_REGION] || {}) }

export const rewardsStyleConfig = {
  1: {
    dialog: { wh: '6.54', wh1: '6.54' },
    wrap: (pt) => ({ wh: '1.9', pc: 'x', pt: pt || '1.98' }),
    reward: { wh: '1.9' },
    rewardNameStyle: { w: '1.9', pt: '2', ...commonTextStyle }
  },

  2: {
    dialog: { wh: '6.54', wh1: '6.54' },
    wrap: (pt) => ({ wh: '3.26 1.54', pc: 'x', pt: pt || '2.28' }),
    reward: { wh: '1.54' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  },

  3: {
    dialog: { wh: '6.54', wh1: '6.54' },
    wrap: (pt) => ({ wh: '4.98 1.54', pc: 'x', pt: pt || '2.28' }),
    reward: { wh: '1.54' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  },

  4: {
    dialog: { wh: '6.54', wh1: '6.54' },
    wrap: (pt) => ({ wh: '5.64 1.32', pc: 'x', pt: pt || '2.44' }),
    reward: { wh: '1.32' },
    rewardNameStyle: { w: '1.32', pt: '1.36', ...commonTextStyle }
  },

  5: {
    dialog: { wh: '6.54 8.96', wh1: '6.54 8.32' },
    wrap: (pt, showTag) => ({ wh: showTag ? '5.84 4.6' : '5.64 4.6', pc: 'x', pt: pt || '2.44' }),
    reward: { wh: '1.32' },
    rewardNameStyle: { w: '1.32', pt: '1.36', ...commonTextStyle }
  }
}

export const commonSubscriptStyle = { fs: isA ? 0.32 : 0.28, fw: 700, pb: 0, [isA ? 'pl' : 'pr']: isA ? 0.08 : 0 }

export const lightConfig = proxyAssets({
  wp: 'https://fe-center.weplayapp.com/page-center/assets/VhJmuMcc/lxQFntNy.png?_w=188&_h=187',
  hw: 'https://fe-center.afunapp.com/page-center/assets/oHImqVrd/VCmFlDHI.png?_w=188&_h=187'
})

export const calculateMargin = (index) => {
  const m = isA ? 'ml' : 'mr'
  const rowNum = isA || isQ ? 3 : 4
  const margin = isA || isQ ? 0.42 : 0.12
  const mbMap = {
    J: 0.89,
    K: 0.89,
    U: 1.12,
    P: 1.12,
    V: 1.12,
    M: 1.12,
    T: 1.12,
    Q: 0.89,
    R: 1.12,
    B: 1.12
  }
  return {
    mb: isA ? 0.97 : mbMap[PAGE_REGION] || 0.75,
    [m]: !((index + 1) % rowNum) ? 0 : margin
  }
}

export const classList = {
  1: ['fx-row', 'fx-m-between'],
  2: ['fx-row', 'fx-wrap', 'fx-cl-start', 'of-y-a', 'of-h-x']
}

export const rewardsStyleConfigA = {
  1: {
    dialog: { wh: '6.54' },
    wrap: (pt) => ({ wh: '1.9', pc: 'x', pt: pt || '1.98' }),
    reward: { wh: '1.9' },
    rewardNameStyle: { w: '1.9', pt: '2', ...commonTextStyle }
  },

  2: {
    dialog: { wh: '6.54' },
    wrap: (pt) => ({ wh: '3.48 1.54', pc: 'x', pt: pt || '2.3' }),
    reward: { wh: '1.54' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  },

  3: {
    dialog: { wh: '6.54' },
    wrap: (pt) => ({ wh: '5.46 1.54', pc: 'x', pt: pt || '2.3' }),
    reward: { wh: '1.54' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  },

  4: {
    dialog: { wh: '6.54 8.86' },
    wrap: (pt) => ({ wh: '3.5 4.94', pc: 'x', pt: pt || '2.11' }),
    reward: { wh: '1.54', mb: '0.89' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  },

  5: {
    dialog: { wh: '6.54 8.86' },
    wrap: (pt) => ({ wh: '5.46 5.02', pc: 'x', pt: pt || '2.32' }),
    reward: { wh: '1.54' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  },

  6: {
    dialog: { wh: '6.54 8.86' },
    wrap: (pt) => ({ wh: '5.46 5.02', pc: 'x', pt: pt || '2.32' }),
    reward: { wh: '1.54' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  },

  7: {
    dialog: { wh: '6.54 8.86' },
    wrap: (pt) => ({ wh: '5.46 5.38', pc: 'x', pt: pt || '1.88' }),
    reward: { wh: '1.54' },
    rewardNameStyle: { w: '1.54', pt: '1.62', ...commonTextStyle }
  }
}

export const classListA = {
  1: ['fx-row', 'fx-m-between', 'fx-wrap', 'fx-cl-between'],
  2: ['fx-row', 'fx-wrap', 'fx-cl-start', 'of-y-a', 'of-h-x'],
  3: ['fx-row', 'fx-wrap', 'fx-cl-start']
}

export const getRewardsWrapPt = (num, rewardWrapStyle) => {
  let key = ''
  if (isA || isQ) {
    if (num === 1) {
      key = 'reward1'
    } else if (num === 2 || num === 3) {
      key = 'reward2_3'
    } else if (num === 4) {
      key = 'reward4'
    } else if (num === 5 || num === 6) {
      key = 'reward5_6'
    } else {
      key = 'reward7'
    }
    return get(rewardWrapStyle, `ptA.${key}`, 0)
  } else {
    if (num === 1) {
      key = 'reward1'
    } else if (num === 2 || num === 3) {
      key = 'reward2_3'
    } else if (num === 4) {
      key = 'reward4'
    } else {
      key = 'reward5'
    }
    return get(rewardWrapStyle, `pt.${key}`, 0)
  }
}

export const rewardWrapPadding = {
  pdt: 0.15,
  pdl: 0.5,
  pdr: 0.5
}

// 获取奖励wrap的class
export function getClass(len) {
  if (isA || isQ) {
    if (len < 5) {
      return classListA[1]
    } else if (len < 7) {
      return classListA[3]
    } else {
      return classListA[2]
    }
  } else {
    return classList[len < 5 ? 1 : 2]
  }
}

// 奖励wrap的style
export function getWrapStyle(wrapStyle) {
  const wh = wrapStyle.wh.split(' ')
  const wrapW = +wh[0] + rewardWrapPadding.pdl + rewardWrapPadding.pdr
  const wrapH = wh[1] || wh[0]
  return { ...wrapStyle, wh: `${wrapW} ${wrapH}`, pt: +wrapStyle.pt - rewardWrapPadding.pdt, ...rewardWrapPadding }
}
