import { APP_TYPE, APP_VERSION, IS_APP } from '@wepie/we-sdk'
export { APP_TYPE, APP_VERSION, IS_APP }

const UA = navigator.userAgent

export const IS_DD = /DingTalk/i.test(UA)
export const IS_WECHAT = /micromessenger/i.test(UA)
export const IS_QQ = /QQTheme/.test(UA)
export const IS_IOS = /iphone|ipod|ipad/i.test(UA)
export const IS_ANDROID = /android/i.test(UA)
export const IS_IPAD = /ipad/i.test(UA)
export const IS_MOBILE = /mobile/i.test(UA) || IS_APP

// beta 环境使用 dev 的逻辑
export const IS_DEV = process.env.APP_CONFIG.$env.DEV || process.env.APP_CONFIG.$env.BETA

export const W = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth || window.screen.width
export const H = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight || window.screen.height

export const IS_WEPLAY = true

const PARAMS = new URLSearchParams(window.location.search)

export const USE_LANGS = ['zh-tw', 'zh-hans', 'ja', 'en', 'ar', 'ko', 'th', 'ms', 'id', 'vi', 'tr', 'pt', 'ru', 'hi', 'es']
export const DEFAULT_LANG = USE_LANGS.find((i) => i === navigator.language.toLowerCase()) || USE_LANGS[0]
export const PAGE_LANG = USE_LANGS.find((i) => i === (PARAMS.get('lang') || '').toLowerCase()) || DEFAULT_LANG

export const USE_REGION = ['C', 'U', 'J', 'A', 'K', 'T', 'M', 'P', 'V', 'Q', 'B', 'R', 'S', 'I']
export const DEFAULT_REGION = USE_REGION[0]
export const PAGE_REGION = USE_REGION.find((i) => i === (PARAMS.get('region') || '').toUpperCase()) || DEFAULT_REGION

export default {
  IS_DD,
  IS_WECHAT,
  IS_QQ,
  IS_IOS,
  IS_ANDROID,
  IS_MOBILE,
  APP_TYPE,
  APP_VERSION,
  IS_APP
}
