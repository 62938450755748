<template lang="pug">
a-section.ui-buy-task
  a-pull-up.ui-buy-task__pull(ref='buyTask', :name='compKey')
    a-position.ui-buy-task__close(v-if='image.closeBtn', v-bind='{ ...uiOuterOperation.closeIcon, ...specialStyle.closeIcon }', @a-tap='$refs.buyTask.close()', :bg-i='image.closeBtn')
    a-position.ui-buy-task__tab1(v-bind='{ ...uiOuterOperation.tab1Button, ...specialStyle.tab1Btn }', @a-tap='tabActive = "tab1"')
    a-position.ui-buy-task__tab2(v-bind='{ ...uiOuterOperation.tab2Button, ...specialStyle.tab2Btn }', @a-tap='tabActive = "tab2"')
      a-position.red-dot.ui-buy-task__tab2__redDot(v-if='this.$task.buyTaskRedDot()', v-bind='{ ...uiOuterOperation.redDot, ...specialStyle.tab2RedDot }')

    a-tabs.ui-buy-task__tabs(:active='tabActive', :items='["tab1", "tab2"]')
      a-section.ui-buy-task__tabs__tab1(slot='tab1', :bg-i='image.bg1')
        //- 抽奖币数量
        a-position.ui-buy-task__tabs__tab1__chip(v-bind='{ ...uiBuy.chip1, ...buyStyle.chip1 }', :fc='textColor.chip1') {{ chip1Num }}
        //- 金币数量
        a-position.ui-buy-task__tabs__tab1__coin(v-bind='{ ...uiBuy.coin, ...buyStyle.coin }', :fc='textColor.coin') {{ coin }}
        //- 充值加号
        a-position.ui-buy-task__tabs__tab1__add(v-bind='{ ...uiBuy.addIcon, ...buyStyle.addIcon }', @a-tap='$we("pay")')
        //- 购买抽奖币列表
        a-list.ui-buy-task__tabs__tab1__list(v-bind='{ ...uiBuy.buyListContainer, ...buyStyle.buyListContainer }')
          a-section.ui-buy-task__tabs__tab1__list__bg(:bg-i='image.buyItemsBg')
            a-section.ui-buy-task__tabs__tab1__item(v-for='(item, index) in buyConfig.list', :key='"buyConfigList" + index', v-bind='uiBuy.buyItemContainer')
              a-button1.ui-buy-task__tabs__tab1__item__buy(v-bind='uiBuy.buyButton', :bg-i='image.buyBtn', @a-tap='buy(item)')
                a-position.ui-buy-task__tabs__tab1__item__buy__tag(v-if='item.tagImage', :bg-i='item.tagImage', v-bind='{ ...uiBuy.buyTag, ...buyStyle.buyTag }')
        slot(name='tab1')

      a-section.ui-buy-task__tabs__tab2(slot='tab2', :bg-i='image.bg2')
        template(v-if='isShowTab2Chip1Coin')
          //- 抽奖币数量
          a-position.ui-buy-task__tabs__tab2__chip1(v-bind='{ ...uiTask.chip1, ...taskStyle.chip1 }', :fc='textColor.chip1') {{ chip1Num }}
          //- 金币数量
          a-position.ui-buy-task__tabs__tab2__coin(v-bind='{ ...uiTask.coin, ...taskStyle.coin }', :fc='textColor.coin') {{ coin }}
          //- 充值加号
          a-position.ui-buy-task__tabs__tab2__add(v-bind='{ ...uiTask.addIcon, ...taskStyle.addIcon }', @a-tap='$we("pay")')

        //- 兑换相关
        template(v-if='exchangeConfig.isShow')
          //- 兑换碎片
          a-position.ui-buy-task__tabs__tab2__chip2(v-bind='{ ...uiTask.chip2, ...taskStyle.chip2 }', :fc='textColor.chip2') {{ chip2Num }}
          //- 立即兑换
          a-button1.ui-buy-task__tabs__tab2__exchange(:bg-i='canExchange ? image.exchange1Btn : image.exchange0Btn', v-bind='{ ...uiTask.exchangeButton, ...taskStyle.exchangeBtn }', @a-tap='exchange')
            a-position.red-dot.ui-buy-task__tabs__tab2__exchange__redDot(v-if='canExchange', v-bind='uiTask.exchangeRedDot')

        //- 任务列表
        a-list(v-bind='{ ...uiTask.taskListContainer, ...taskStyle.taskListContainer }')
          a-section(v-for='(item, index) in dailyTaskListSort.filter((task) => taskList.find((i) => i.id == task.id))', :key='"taskList" + index', v-bind='{ ...uiTask.taskItemContainer, ...taskStyle.taskItemContainer }')
            a-section(:bg-i='taskImageMap[item.id]')
            ui-daily-task-repeat(v-bind='uiTask.dailyTaskButton', :uiMeta='genDailyTaskUiMeta(item.id)', :dailyTaskEvent='dailyTaskEvents[item.id]', @close-task='close')
              a-position.red-dot(v-if='item.state === 1', v-bind='uiTask.exchangeRedDot')
        slot(name='tab2')

  a-dialog.ui-buy-task-coin-short(v-if='buyConfig.coinShortDialogKey === "uiBuyTaskCoinShort"', name='uiBuyTaskCoinShort', :bg-i='image.coinShortDialog')
    a-button1.ui-buy-task-coin-short__pay(:bg-i='image.toPayBtn', v-bind='{ ...uiCoinShortDialog.toPayBtn, ...coinShortStyle.toPayBtn }', @a-tap='$we("pay")')

  a-dialog.ui-buy-task__buy-confirm(name='uiBuyTaskBuyConfirm', :bg-i='image.buyConfirmDialog')
    template(slot-scope='scope', v-if='scope.data')
      a-position(v-bind='{ ...uiBuyConfirmDialog.text, ...buyConfirmStyle.text }')
        a-section(:fc='textColor.buyConfirm', v-html='buyConfirmText(buyConfig.buyConfirmText || textConfig.buyConfirm, scope.data.confirmPrice || scope.data.price, scope.data.confirmNum || scope.data.num, $tf(buyConfig.name))')

      a-position.ui-buy-task__buy-confirm__noBuyConfirm(:bg-i='localStorage[storageKey.noSecondConfirm] ? image.noBuyConfirm1 : image.noBuyConfirm0', v-bind='{ ...uiBuyConfirmDialog.tip, ...buyConfirmStyle.tip }', @a-tap='toggleTip()')

    a-button1.ui-buy-task__buy-confirm__cancel(:bg-i='image.buyCancelBtn', slot='cancel', v-bind='{ ...uiBuyConfirmDialog.cancelButton, ...buyConfirmStyle.cancelButton }', @a-tap='$dialogs.uiBuyTaskBuyConfirm.close()')
    a-button1.ui-buy-task__buy-confirm__confirm(:bg-i='image.buyConfirmBtn', slot='confirm', v-bind='{ ...uiBuyConfirmDialog.confirmButton, ...buyConfirmStyle.confirmButton }', @a-tap='$dialogs.uiBuyTaskBuyConfirm.close()')
</template>

<script>
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { defaultImage, defaultTextColor, defaultSpecialStyle, buyTaskStyleConfig, genBuyTaskTextConfig } from './utils/config'
import { PAGE_REGION } from '@/utils/env'

import { buildApi } from '@/utils/request'
import event from '@sub/event'
const buyChip = buildApi('/activity_v2/collect_chip/buy_collect_chip')
const exchange = buildApi('/activity_v2/exchange_store/exchange')

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    /**
     * {
     *   任务id: {
     *     去完成事件
     *     领取事件
     *   },
     *   ...
     * }
     */
    dailyTaskEvents: {
      type: Object,
      default: () => {
        return {
          1: {
            completeTap: null,
            receiveTap: null
          }
        }
      }
    },
    needSort: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      tabActive: 'tab1',
      ...buyTaskStyleConfig
    }
  },

  computed: {
    tab2RedDot() {
      return this.$task.buyTaskInfo()?.canExchange || this.taskList.some((task) => this.$task.dailyTasksInfo()[task.id]?.state == 1)
    },
    compKey({ uiMeta }) {
      return uiMeta?.key || 'buyTask'
    },
    // 图片
    image({ uiMeta }) {
      const image = get(uiMeta, 'props.image', {})
      return { ...defaultImage, ...image }
    },
    // 字体颜色
    textColor({ uiMeta }) {
      const textColor = get(uiMeta, 'props.textColor', {})
      return { ...defaultTextColor, ...textColor }
    },
    // 特化样式
    specialStyle({ uiMeta }) {
      const specialStyle = get(uiMeta, 'props.specialStyle', {})
      return { ...defaultSpecialStyle, ...specialStyle }
    },
    toPayBtn({ specialStyle }) {
      return specialStyle.coinShort.toPayBtn
    },
    buyStyle({ specialStyle }) {
      return specialStyle.buy
    },
    taskStyle({ specialStyle }) {
      return specialStyle.task
    },
    buyConfirmStyle({ specialStyle }) {
      return specialStyle.buyConfirm
    },
    coinShortStyle({ specialStyle }) {
      return specialStyle.coinShort
    },
    // 购买相关配置
    buyConfig({ uiMeta }) {
      return get(uiMeta, 'props.buyConfig', {
        name: '碎片1',
        chipId: 1,
        storageKey: {},
        successDialogKey: '',
        coinShortDialogKey: '',
        list: []
      })
    },
    // 勾选框缓存的key的配置
    storageKey({ buyConfig }) {
      return { noSecondConfirm: 'noBuyConfirm', restoreSecondConfirm: 'restoreBuyConfirm', ...buyConfig.storageKey }
    },
    // 任务列表相关配置 TODO: 二次包装一下，传更少的参数
    taskList({ uiMeta }) {
      return get(uiMeta, 'props.taskList', [])
    },
    taskImageMap() {
      return this.taskList.reduce((total, cur) => {
        total[cur.id] = cur?.image || `buyTask/task${cur.id}.png`
        return total
      }, {})
    },
    // 是否展示tab2的碎片和金币数量
    isShowTab2Chip1Coin({ uiMeta }) {
      return get(uiMeta, 'props.isShowTab2Chip1Coin', true)
    },
    // 兑换相关配置
    exchangeConfig({ uiMeta }) {
      return get(uiMeta, 'props.exchangeConfig', {
        isShow: true,
        chipId: 2,
        needNum: 10,
        params: {}
      })
    },
    canExchange() {
      if (!this.exchangeConfig.isShow) {
        this.$store.commit('task/setBuyTaskInfo', { canExchange: false })
        return false
      }
      const val = this.chip2Num >= this.exchangeConfig.needNum
      this.$store.commit('task/setBuyTaskInfo', { canExchange: val })
      return val
    },

    // 文案
    textConfig({ textColor }) {
      return genBuyTaskTextConfig(textColor.buyHighlight)
    },

    amApiParam() {
      return this.$store.getters['base/amApiParam']
    },
    coin() {
      return this.$store.state.base.coin || 0
    },
    chip1Num() {
      return this.$store.state.base.chipInfo[this.buyConfig.chipId || 1] || 0
    },
    chip2Num() {
      return this.$store.state.base.chipInfo[this.exchangeConfig.chipId || 2] || 0
    },
    dailyTaskList() {
      return this.$store.getters['task/dailyTaskList']
    },
    dailyTaskListSort() {
      return this.$store.getters['task/dailyTaskListSort']
    },
    dailyTaskListSortInNeed({ dailyTaskListSort, dailyTaskList }) {
      return this.needSort ? dailyTaskListSort : dailyTaskList
    },

    localStorage() {
      return this.$store.state.storage.localStorage || {}
    }
  },

  created() {
    this.$uiComp.setUiCompConfig({
      compName: 'uiBuyTask',
      compKey: this.compKey,
      value: {
        open: this.open.bind(this)
      }
    })
  },

  mounted() {
    event.onChipChange(this.updateCoinChip.bind(this))
    this.initStorage()
  },

  afterInit() {
    this.initDailyTask()
  },

  onPageShow() {
    this.onShow()
  },

  methods: {
    onShow() {
      this.updateCoinChip()
      this.$store.dispatch('task/getTasksGroupList')
    },

    open({ tabActive = 'tab1' } = {}) {
      this.tabActive = tabActive
      this.$refs.buyTask.open()
    },

    close() {
      this.$refs.buyTask.close()
    },

    initStorage() {
      this.$store.dispatch('storage/init', { [this.storageKey.noSecondConfirm]: false })
    },

    toggleTip() {
      const noSecondConfirmKey = this.storageKey.noSecondConfirm
      const restoreSecondConfirmKey = this.storageKey.restoreSecondConfirm

      if (noSecondConfirmKey === restoreSecondConfirmKey) {
        // 恢复购买确认弹窗和不再提示弹窗用同一个 key 的情况
        this.$storage.toggle(noSecondConfirmKey)
      } else {
        // 恢复购买确认弹窗和不再提示弹窗用不同 key 的情况
        this.$storage.toggle(noSecondConfirmKey)
        if (restoreSecondConfirmKey) {
          this.$storage.update({ [restoreSecondConfirmKey]: !this.localStorage[noSecondConfirmKey] })
        }
      }
    },

    initDailyTask() {
      this.taskList.forEach((i) => {
        this.$store.commit('task/initDailyTaskItemDefault', i.id)
      })
      this.$store.dispatch('task/getTasksGroupList')
    },

    buy: throttle(
      async function (data) {
        if (this.coin < data.price) {
          if (this.buyConfig.coinShortDialogKey) {
            this.$dialogs[this.buyConfig.coinShortDialogKey].open({ options: { bgClose: true } })
          } else {
            await this.$toast(this.textConfig.coinShort)
            this.$we('pay')
          }
          // this.$dialogs.uiBuyTaskCoinShort.open()
          return
        }

        if (!this.localStorage[this.storageKey.noSecondConfirm]) {
          const res = await this.$dialogs.uiBuyTaskBuyConfirm.confirm({ data })
          if (!res) {
            return
          }
        }

        document.body.classList.add('loading')
        try {
          const buyNum = await buyChip({ ...this.amApiParam, chip_id: this.buyConfig.chipId || 1, number: data.num, confirm_popped: !this.localStorage[this.storageKey.noSecondConfirm] })
          document.body.classList.remove('loading')

          // 如果传递了successDialogKey，则打开对应的弹窗，否则toast
          if (this.buyConfig.successDialogKey) {
            const rewardList = [
              {
                unit: this._isA ? `${buyNum}x` : `x${buyNum}`,
                reward_name: this.buyConfig.name,
                img: this.$route.meta.assets[data.rewardImage] || this.$propsConfig(this.buyConfig.chipId)?.img
              }
            ]
            this.$dialogs[this.buyConfig.successDialogKey].open({ data: { rewardList } })
          } else {
            this.$toast(this.textConfig.buySuccess)
          }

          this.updateCoinChip()
        } catch (e) {
          console.log(e)
          document.body.classList.remove('loading')
        }
      },
      1000,
      { trailing: false }
    ),

    async exchange() {
      if (!this.canExchange) {
        this.exchangeConfig.cannotText && this.$toast(this.exchangeConfig.cannotText)
        return
      }
      const exchange_count = Math.floor(this.chip2Num / this.exchangeConfig.needNum)
      document.body.classList.add('loading')
      try {
        const { rewards } = await exchange({ ...this.amApiParam, target_uid: this.amApiParam.uid, exchange_count, ...this.exchangeConfig.params })
        document.body.classList.remove('loading')

        // 如果传递了exchangeDialogKey，则打开对应的弹窗，否则toast
        if (this.exchangeConfig.exchangeDialogKey) {
          const rewardList = rewards.map((i) => {
            return {
              unit: this._isA ? `${i.reward_num}x` : `x${i.reward_num}`,
              reward_name: i.reward_type_name,
              img: this.$propsConfig(i.reward_id)?.img
            }
          })
          this.$dialogs[this.exchangeConfig.exchangeDialogKey].open({ data: { rewardList } })
        } else {
          //- 各区服文案TODO
          this.$toast(this.textConfig.exchangeSuccess)
        }
      } catch (e) {
        console.log(e)
        document.body.classList.remove('loading')
      }

      this.$store.dispatch('base/getChipInfo')
    },

    updateCoinChip() {
      this.$store.dispatch('base/getUserCoin')
      this.$store.dispatch('base/getChipInfo')
    },

    genDailyTaskUiMeta(taskId) {
      const task = this.taskList.find((i) => i.id === taskId)
      const { toFinish, receive, completed } = this.image
      const { dailyTask } = this.textColor
      return {
        props: {
          taskId,
          buttonImage: {
            toFinish,
            receive,
            completed
          },
          textStyle: {
            color: dailyTask
          }
        },
        // TODO：按钮事件
        events: task.events
      }
    },

    // 各区服语序不一样的返回
    buyConfirmText(text, price, num, name) {
      if (PAGE_REGION === 'K') {
        return this.$tf(text, price, name, num)
      } else {
        return this.$tf(text, price, num, name)
      }
    },

    doPoint(name, extra = {}) {
      this.$point.track('ClickH5', {
        act_id: this.amApiParam.act_id,
        url: `${window.location.origin}${window.location.pathname}`,
        url_path: window.location.pathname,
        btn_name: name,
        title: this.$route.meta.title,
        activity: this.$base.amActivityInfo()?.activity_name || '',
        ...extra
      })
    }
  }
}
</script>

<style></style>
