class MemoryStorage {
  constructor() {
    this._store = {}
  }
  setItem(key, val) {
    this._store[key] = val
  }
  getItem(key) {
    return Object.hasOwnProperty.call(this._store, key) ? this._store[key] : null
  }
  removeItem(key) {
    delete this._store[key]
  }
  clear() {
    this._store = {}
  }
}

const localStorage = window.localStorage || new MemoryStorage()

export default {
  set(key, val) {
    return localStorage.setItem(key, JSON.stringify(val))
  },
  get(key) {
    const val = localStorage.getItem(key)
    if (val === null) return null
    try {
      return JSON.parse(val)
    } catch (e) {
      localStorage.removeItem(key)
      return null
    }
  },
  remove(key) {
    return localStorage.removeItem(key)
  },
  clear() {
    return localStorage.clear()
  }
}
