import { isA } from '@sub/ui-components/utils/config'

const prl = isA ? 'pr' : 'pl'
const plr = isA ? 'pl' : 'pr'

const commonBtnStyleLeft = { pb: 0.74, pl: 0.69, 'bg-s': '100%' }
const commonBtnStyleRight = { pb: 0.74, pr: 0.69, 'bg-s': '100%' }

export const exchangeConfirmStyleConfig = {
  reward: {
    wrap: { wh: 1.9, pc: 'x', pt: 1.56, 'bg-s': '100%' },
    subscript: { pb: 0, [plr]: 0, fs: 0.28, fw: 400 },
    rewardName: { w: 5, fs: 0.22, fw: 500, lh: 0.3, pc: 'x', pt: 2.02 }
  },

  input: {
    inputBg: { 'bg-s': '100%', pt: 4.17, [prl]: isA ? 2.5 : 1.47 },
    reduce: { pc: 'y', pl: 0.04, 'bg-s': '100%', wh: 0.44 },
    add: { pc: 'y', pr: 0.04, 'bg-s': '100%', wh: 0.44 },
    num: { wh: '1.2 .39', pc: 'pc', fs: 0.28, fw: 500, lh: 0.39 }
  },

  max: { pt: 4.19, pl: 4.13, 'bg-s': '100%' },
  permanentExchange: { fs: 0.28, fw: 500, lh: 0.3, w: 5, pt: 4.3, pc: 'x' },
  canExchangeNum: { fs: 0.2, fw: 500, lh: 0.3, w: 5, pt: 4.77, pc: 'x' },
  cancelBtn: commonBtnStyleLeft,
  confirmBtn: commonBtnStyleRight
}

export const confirmStyleConfig = {
  textWrap: { wh: '5.25 .39', pc: 'x', pt: 1.28, fs: 0.28, fw: 500, lh: 0.38 },
  tipWrap: { wh: '5 .36', pc: 'x', pt: 2.23, fs: 0.22, fw: 400 },
  cancelButton: commonBtnStyleLeft,
  confirmButton: commonBtnStyleRight
}

export const calculateMargin = (index, num) => {
  // 是否是4个一行
  const isRow4 = !(num % 4)
  const m = isA ? 'ml' : 'mr'
  const rowNum = isRow4 ? 4 : 3
  const marginLR = isRow4 ? 0.12 : 0.4

  return {
    mb: 1.06,
    [m]: !((index + 1) % rowNum) ? 0 : marginLR
  }
}

export const textConfig = {
  HW: {
    permanentExchange: '确定消耗{{costNum}}个{{chipName}}兑换{{rewardName}}吗？',
    maxText: '可兑换数量：{{maxNum}}',
    confirmText: '确定消耗{{costNum}}个{{chipName}}兑换{{exchangeNum}}次{{rewardName}}吗？',
    overToast: '不能再多了哦~',
    leastToast: '最少为1哦~'
  },
  C: {
    maxText: '可兌換數量：{{maxNum}}',
    confirmText: '确定消耗{{costNum}}个{{chipName}}兑换{{exchangeNum}}次{{rewardName}}吗？',
    overToast: '',
    leastToast: ''
  },
  J: {
    maxText: '交換できる数：{{maxNum}}',
    confirmText: '{{chipName}}を{{costNum}}個を消耗して、{{rewardName}}×{{exchangeNum}}を交換しますか？',
    overToast: '',
    leastToast: ''
  },
  K: {
    maxText: '교환 가능 수량：{{maxNum}}',
    confirmText: '{{costNum}} {{chipName}}개를 사용하여 {{exchangeNum}} {{rewardName}}개로 교환하시겠습니까?',
    overToast: '증가 불가!',
    leastToast: '최소 1!'
  },
  A: {
    permanentExchange: 'هل أنت متأكد أنك تريد استهلاك {{costNum}}*{{chipName}} واستبدالها بـ {{rewardName}}؟',
    maxText: 'الكمية التي يمكنك استبدالها: {{maxNum}}الكمية التي يمكنك استبدالها: {{}}',
    confirmText: 'هل أنت متأكد أنك تريد استهلاك {{}}*{{}} واستبدالها بـ {{}} {{}} مرة؟هل أنت متأكد أنك تريد استهلاك {{costNum}}*{{chipName}} واستبدالها بـ {{rewardName}} {{exchangeNum}} مرة؟',
    overToast: 'لا يمكن أكثر من ذلك~لا يمكن أكثر من ذلك~',
    leastToast: 'على الأقل واحدة~على الأقل واحدة~'
  },
  M: {
    maxText: 'Boleh tukar: {{maxNum}}',
    confirmText: 'Adakah anda pasti mahu menggunakan {{costNum}} {{chipName}} untuk bertukar {{exchangeNum}} kali {{rewardName}}?',
    overToast: 'Tidak boleh tambah lagi',
    leastToast: 'Sekurang-kurangnya 1~ '
  },
  id: {
    maxText: 'Dapat ditukar: {{maxNum}}',
    confirmText: 'Anda yakin ingin menggunakan {{costNum}} {{chipName}} untuk bertukar {{exchangeNum}} kali {{rewardName}}?',
    overToast: 'Tidak dapat menambahkan lebih banyak',
    leastToast: 'Setidaknya 1~'
  },
  ms: {
    maxText: 'Boleh tukar: {{maxNum}}',
    confirmText: 'Adakah anda pasti mahu menggunakan {{costNum}} {{chipName}} untuk bertukar {{exchangeNum}} kali {{rewardName}}?',
    overToast: 'Tidak boleh tambah lagi',
    leastToast: 'Sekurang-kurangnya 1~ '
  },
  T: {
    maxText: 'จำนวนที่แลกได้：{{maxNum}}',
    confirmText: 'ยืนยันใช้{{costNum}}ชิ้น{{chipName}}แลก{{exchangeNum}}ครั้ง{{rewardName}}ไหม？',
    overToast: 'มากกว่านี้ไม่ได้แล้ว~',
    leastToast: 'น้อยสุดที่ 1 ชิ้นนะ~'
  },

  V: {
    permanentExchange: 'Xác nhận muốn dùng {{costNum}} cái {{chipName}} để đổi lấy {{rewardName}} không？',
    maxText: 'Số lượng có thể đổi：{{maxNum}}',
    confirmText: 'Xác nhận muốn dùng {{costNum}} cái {{chipName}} để đổi lấy {{exchangeNum}} lần {{rewardName}} không？',
    overToast: 'Không thể thêm nữa đâu~',
    leastToast: 'Tối thiểu là 1 nha~'
  },
  P: {
    maxText: 'Convertible quantity：{{maxNum}}',
    confirmText: 'Confirm to spend {{costNum}} {{chipName}} for {{exchangeNum}} {{rewardName}}？',
    overToast: "Can't be more~",
    leastToast: 'At least one~'
  },
  U: {
    maxText: 'Convertible quantity：{{maxNum}}',
    confirmText: 'Confirm to spend {{costNum}} {{chipName}} for {{exchangeNum}} {{rewardName}}？',
    overToast: "Can't be more~",
    leastToast: 'At least one~'
  },
  Q: {
    maxText: 'Geriye kalan ：{{maxNum}}',
    confirmText: '{{exchangeNum}} {{rewardName}} değişmek için {{costNum}} {{chipName}} harcamak istediğinizden emin misiniz？',
    overToast: 'Limitinize ulaştı~',
    leastToast: 'En az 1~'
  },
  R: {
    permanentExchange: 'Использовать {{costNum}} {{chipName}}, чтобы обменять на {{rewardName}}?',
    maxText: 'Можно обменять: {{maxNum}}',
    confirmText: 'Использовать {{costNum}} {{chipName}}, чтобы обменять на {{exchangeNum}} раз(а) {{rewardName}}?',
    overToast: 'Нельзя больше~',
    leastToast: 'Не менее 1~'
  },
  B: {
    permanentExchange: 'Usar {{costNum}} {{chipName}} para trocar por {{rewardName}}?',
    maxText: 'Disponível para troca: {{maxNum}}',
    confirmText: 'Usar {{costNum}} {{chipName}} para trocar por {{exchangeNum}} vezes {{rewardName}}?',
    overToast: 'Excedeu o máximo',
    leastToast: ' O mínimo é 1~'
  },
  I: {
    permanentExchange: 'Are you sure you want to spend {{costNum}} {{chipName}} to exchange {{rewardName}}?',
    maxText: 'Convertible quantity：{{maxNum}}',
    confirmText: 'Confirm to spend {{costNum}} {{chipName}} for {{exchangeNum}} {{rewardName}}？',
    overToast: "Can't be more~",
    leastToast: 'At least one~'
  }
}
// export const genExchangeTextConfig = multiLanguage.getMultiTextConfig(textConfig)
