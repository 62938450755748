<template lang="pug">
  a-position.ui-broadcast(v-if='showBullet && broadcast?.length', v-bind='$attrs')
    a-broadcast(
      mode='slot',
      ref='bullet',
      w='7.5',
      :h='broadcastItemStyle.h || 0.38',
      :items='broadcast',
      :direction='_isA ? "ltr" : "rtl"',
      :duration='duration',
      :between='between',
      :loop='loop',
      :interval='interval'
    )
      slot(v-if='$scopedSlots.bullet', name='bullet', slot-scope='scope', v-bind='{ data: scope.data }')
      a-section.fx-row.ws-nowrap.fx-m-center(
        v-else,
        slot-scope='scope',
        v-bind='broadcastItemStyle',
        :style='`background: ${broadcastItemStyle.background}`',
        @a-tap='eventsHandler($event, scope.data)'
      )
        a-section(v-for='(item, index) in contents', :key='index')
          a-section(:id='index', v-if='item.type == "text" && item.content', v-html='handlerContent($tf(item.content), scope.data)', v-bind='item.style')
          a-section(:id='index', v-else-if='item.type == "text"', v-html='handlerDefaultContent(scope.data)')
          a-avatar(:id='index', v-else-if='item.type == "avatar"', :bg-i='getPropValue(scope.data, $tf(item.url))', :uid='getPropValue(scope.data, item.uid)', v-bind='item.style')
          a-section(:id='index', v-else-if='item.type == "image"', :bg-i='getPropValue(scope.data, $tf(item.src))', v-bind='item.style')
</template>

<script>
import { buildApi, concatCatch } from '@sub/utils/request'
import config, { defaultConfig } from './utils/config'

import get from 'lodash/get'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    duration: {
      type: [Number, String],
      default: '50'
    },

    between: {
      type: [Number, String],
      default: '50'
    },

    loop: {
      type: Boolean,
      default: true
    },

    interval: {
      type: [Number, String],
      default: 120
    },
    destroyOnHide: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    broadcastItemStyle({ uiMeta }) {
      const broadcastItemStyle = get(uiMeta, 'props.broadcastItemStyle', {})
      return { ...config.broadcastItemStyle, ...broadcastItemStyle }
    },

    contents({ uiMeta }) {
      return get(uiMeta, 'props.contents', config.contents)
    },

    request({ uiMeta }) {
      const request = get(uiMeta, 'props.request', {})
      return { ...config.request, ...request }
    },
    highlightColor({ uiMeta }) {
      return get(uiMeta, 'props.highlightColor', config.highlightColor)
    },
    nicknameSliceLen({ uiMeta }) {
      return get(uiMeta, 'props.nicknameSliceLen', config.nicknameSliceLen)
    }
  },
  data() {
    return {
      broadcast: [],
      showBullet: true
    }
  },

  afterInit() {
    this.getBroadcastInfo()
  },

  onPageShow() {
    this.startMove()
    this.showBullet = true
  },

  onPageHide() {
    this.stopMove()
    this.showBullet = !this.destroyOnHide
  },

  mounted() {
    if (this.$base.amBasic().time) {
      this.getBroadcastInfo()
    }
  },
  methods: {
    startMove() {
      this.$refs.bullet && this.$refs.bullet.startMove()
    },
    stopMove() {
      this.$refs.bullet && this.$refs.bullet.stopMove()
    },
    jumpUserInfo(uid) {
      if (this._region === 'HW') return
      this.$we('jumpUserInfo', uid)
    },
    handlerContent(content, data) {
      const regexp = /\{\{\w+\}\}/g
      const originRes = [...content.matchAll(regexp)].map((item) => item[0])
      originRes.forEach((item) => (content = content.replace(item, '{{}}')))
      const res = originRes.map((item) => {
        const name = item.substring(2).replace('}}', '')
        if (name == 'nickname') {
          return this.getNickname(data.nickname)
        } else {
          return data[name]
        }
      })
      return this.$tf(content, ...res)
    },
    getNickname(nickname) {
      return this._isA ? this.$tsAr(nickname, 5) : this.$ts(nickname, 5)
    },
    handlerDefaultContent(data) {
      return this.$tf(defaultConfig.text, this.highlightColor, this.getNickname(data.nickname), this.highlightColor, data.reward_name)
    },
    getPropValue(data, path) {
      if (path.indexOf('{{') >= 0) {
        const tPath = path.substring(2).replace('}}', '').split('.')
        return tPath.reduce((res, item) => {
          return res[item]
        }, data)
      } else if (path.indexOf('$') >= 0) {
        return this.$route.meta.assets[path.replaceAll('$', '')]
      }
    },
    eventsHandler(e, data) {
      const id = !e.target.id ? e.target.parentNode.id : e.target.id
      const event = this.contents[id].event
      // 先执行子组件的事件
      if (event.type == 'emit') {
        return this.$emit(event.name, data)
      }
      // 再执行父组件的事件
      const fEvent = this.uiMeta.props.event
      if (fEvent && fEvent.type == 'emit') {
        return this.$emit(fEvent.name, data)
      }
      // 没有配置事件，默认跳转个人主页
      if (data.uid) {
        return this.jumpUserInfo(data.uid)
      }
    },

    async getBroadcastInfo() {
      const [err, res] = await concatCatch(buildApi(this.request.api)({ ...this.request.params }))
      if (err) {
        console.log(err.msg)
      }
      this.broadcast = res || []
    }
  }
}
</script>

<style lang="scss" scoped></style>
