import { IS_WEPLAY } from '@/utils/env'
import store from '@/store'
import { createBtn } from '@sub/utils/eruda-plugins/utils'
import _openAllDialogs from './ui-check/open-all-dialogs'
import _toggleOutline from './ui-check/outline'
import { _switch2Dev, _switch2Pre, _switch2Prod, _switchPC2Dev, _switchEncryption, _switchSyncVConsole } from './api-change'
import { pageJumpNew } from '@sub/utils/page-jump-methods'
import { getQuery1 } from '@/utils/utils'

const genTemplate = () => `
  <div id="activity-tools" style="overflow-y: auto; height:100%">
    <div class="eruda-activity-test fx-row fx-wrap fx-m-center" style="width: 100%; padding: 10px">
      <h3>活动测试</h3>
    </div>
    <div class="eruda-activity-ui-check" style="width: 100%; padding: 10px">
      <h3>ui检查</h3>
    </div>
    <div class="eruda-activity-api-change" style="width: 100%; padding: 10px">
      <h3>域名修改(仅预发环境)</h3>
    </div>
    <div class="eruda-activity-api-encryption" style="width: 100%; padding: 10px">
      <h3>接口加密</h3>
    </div>
  <div>
`

export function install() {
  return {
    name: '活动工具',

    init($el) {
      $el.html(genTemplate())
      this._$el = $el
      this._activityTest = $el[0].querySelector('.eruda-activity-test')
      this._uiCheck = $el[0].querySelector('.eruda-activity-ui-check')
      this._apiChange = $el[0].querySelector('.eruda-activity-api-change')
      this._apiEncryption = $el[0].querySelector('.eruda-activity-api-encryption')

      this._switchToPre()
      this._toggleEffect()
      this._pageReload()
      this._toDebug()
      this._forceCloseEffectRefresh()
      this._forceOpenEffectRefresh()
      this._openAllDialogs(this._uiCheck)
      this._toggleOutline(this._uiCheck)

      // 仅pre开入口
      const host = window.location.host
      if (host === 'activity-pre.afunapp.com' || host === 'activity-pre.weplayapp.com') {
        this._switch2Dev(this._apiChange)
        this._switch2Pre(this._apiChange)
        this._switch2Prod(this._apiChange)
        this._switchPC2Dev(this._apiChange)
      }

      this._switchSyncVConsole(this._apiChange)

      this._switchEncryption(this._apiEncryption, '接口不加密', -1)
      this._switchEncryption(this._apiEncryption, '接口加密', 1)
      this._switchEncryption(this._apiEncryption, '接口加密&压缩', 2)
    },
    show() {
      this._$el.show()
      this._setToggleEffectInnerHTML()
      return this
    },
    hide() {
      this._$el.hide()
      return this
    },
    destroy() {
      this._$el.remove()
    },

    // 活动测试
    _switchToPre,
    _pageReload,
    _toggleEffect,
    _setToggleEffectInnerHTML,
    _switchSyncVConsole,
    _toDebug,
    _forceCloseEffectRefresh,
    _forceOpenEffectRefresh,

    // ui检查
    _openAllDialogs,
    _toggleOutline,

    // 服务器api change
    _switch2Dev,
    _switch2Pre,
    _switch2Prod,
    // pc api change
    _switchPC2Dev,
    // api 加密
    _switchEncryption
  }
}

// 一键切换到预发布环境
function _switchToPre() {
  const btn = createBtn('switch-to-pre', '切换到预发布环境')

  btn.onclick = function () {
    const host = IS_WEPLAY ? 'https://activity-pre.weplayapp.com' : 'https://activity-pre.afunapp.com'
    window.location = host + window.location.pathname + window.location.search + window.location.hash
  }

  this._activityTest.appendChild(btn)
}

// 一键开启/关闭动效
function _toggleEffect() {
  const btn = createBtn('toggle-effect')

  btn.onclick = function () {
    store.commit('base/setForceIsOpenEffect', !getIsOpenEffect())
    btn.innerHTML = getIsOpenEffect() ? '关闭动效' : '开启动效'
  }

  this._activityTest.appendChild(btn)
}
function _setToggleEffectInnerHTML() {
  const btn = this._$el[0].querySelector('.toggle-effect')
  btn.innerHTML = getIsOpenEffect() ? '关闭动效' : '开启动效'
}
function getIsOpenEffect() {
  const isOpenEffect = store.getters['base/isOpenEffect']
  const forceIsOpenEffect = store.state.base.forceIsOpenEffect
  return forceIsOpenEffect === null ? isOpenEffect : forceIsOpenEffect
}

// 一键页面重新加载
function _pageReload() {
  const btn = createBtn('page-reload', '页面重新加载')

  btn.onclick = function () {
    window.location.reload()
  }

  this._activityTest.appendChild(btn)
}

// debug 模式
function _toDebug() {
  const btn = createBtn('to-debug', 'debug模式')
  btn.onclick = function () {
    refreshAddQuery({ debug: true })
  }

  this._activityTest.appendChild(btn)
}

// 强制关闭动效并刷新页面
function _forceCloseEffectRefresh() {
  const btn = createBtn('force-close-effect', '关动效模式')
  btn.onclick = function () {
    refreshAddQuery({ forceIsOpenEffect: false })
  }

  this._activityTest.appendChild(btn)
}

// 强制打开动效并刷新页面
function _forceOpenEffectRefresh() {
  const btn = createBtn('force-open-effect', '开动效模式')
  btn.onclick = function () {
    refreshAddQuery({ forceIsOpenEffect: true })
  }

  this._activityTest.appendChild(btn)
}

function refreshAddQuery(query) {
  pageJumpNew(window.location.pathname, {
    ...getQuery1(),
    ...query
  })
}
