import localStorage from './local-storage'
import sessionStorage from './session-storage'
import { buildApi } from './request-next'
import { IS_IOS, IS_APP } from '@/utils/env'

const getEffectState = buildApi('/activity_v1/common/get_special_effects_closed')
export const effectConfigMixin = {
  data() {
    return {
      // 0 打开道具特效  1 关闭道具特效
      effectConfig: 0,
      androidInfo: {},
      androidVersion: 26,
      iosVersion: '11.5.0',
      iosVersion2: '16.4.0',
      isEffectStateReadying: false
    }
  },

  computed: {
    isOpenEffect() {
      return this.effectConfig !== 1 && !this.isLowVersion
    },

    isLowVersion() {
      if (!IS_APP) {
        return false
      }

      let res = false

      try {
        if (IS_IOS) {
          const version = navigator.userAgent.split('OS')[1].split('like')[0].trim().replace(/_/g, '.')
          res = this.$we.compareVersion(this.iosVersion, version) === '>' || this.isIOS16_4() ? true : false
        } else {
          res = (this.androidInfo.android_version || 26) < this.androidVersion
        }
      } catch (e) {
        return true
      }

      return res
    }
  },

  methods: {
    async getEffectState() {
      this.effectConfig = await getEffectState(this.amApiParam)
      if (!IS_IOS) {
        this.androidInfo = (await this.$we('getDeviceInfo')) || {}
      }

      this.isEffectStateReadying = true
    },
    isIOS16_4() {
      if (!IS_IOS || !IS_APP) {
        return false
      }
      const version = navigator.userAgent.split('OS')[1].split('like')[0].trim().replace(/_/g, '.')
      console.log('IOS_VERSION: ', version)
      if (['16.4', '16.4.0', '16.4.1'].includes(version)) {
        return true
      } else {
        return false
      }
    }
  }
}

export const headerBarHeightMixin = {
  data() {
    return {
      headerBarHeight: 0 // 头部bar高度
    }
  },

  computed: {
    isFullWindow() {
      return Number(this.$route.query.full) !== 0
    },

    divPosition() {
      const t = this.headerBarHeight
      return this.isFullWindow ? { nav: t, user: t + 0.8 + 0.4, list: t + 0.8 + 3.16 } : { nav: 0, user: 0.8, list: 3.56 }
    },

    isVoice() {
      return this.$base.isInVoiceRoom()
    }
  },

  methods: {
    async initBarHeight() {
      if (this.isVoice) {
        this.headerBarHeight = 0
      } else if (this.isFullWindow) {
        this.headerBarHeight = Number(sessionStorage.get('headerBarHeight')) || 0
        if (!this.headerBarHeight) {
          let statusBarHeight = localStorage.get('StorageStatusBarHeight') || 0

          if (!statusBarHeight) {
            let res = await this.$we('getStatusBarHeight')
            statusBarHeight = res.statusBarHeight
            localStorage.set('StorageStatusBarHeight', statusBarHeight)
          }

          this.headerBarHeight = (Number(statusBarHeight) * 2) / 100
          sessionStorage.set('headerBarHeight', this.headerBarHeight)
        }
      }
    }
  }
}

// ref='bgm'
export const audioMixin = {
  data() {
    const { scene } = this.$route.query
    return {
      scene,
      voice: 1, // 1-打开音乐 0-关闭音乐,
      _visibleStatus: true,
      closeIcon: '',
      openIcon: '',
      storageKey: ''
    }
  },

  computed: {
    isInVoice() {
      return this.scene === 'voice_room'
    },
    voiceBgI({ openIcon, closeIcon, voice }) {
      if (voice) {
        return openIcon
      } else {
        return closeIcon
      }
    }
  },

  watch: {
    storageKey() {
      this.initVoice()
    }
  },

  created() {
    if (this._isRouteInstances) {
      document.addEventListener('visibilitychange', this.handleVisibility)
    }
  },

  mounted() {
    this.initVoice()
  },

  beforeDestroy() {
    this.allAudioPause()
    document.removeEventListener('visibilitychange', this.handleVisibility)
  },
  methods: {
    initBGMIcon(openIcon, closeIcon, storageKey) {
      this.openIcon = openIcon
      this.closeIcon = closeIcon
      this.storageKey = storageKey
    },

    handleVisibility() {
      if (this._visibleStatus !== document.hidden) {
        document.hidden ? this.onHide() : this.onShow()
        this._visibleStatus = document.hidden
      }
    },

    initVoice() {
      if (!this.storageKey) {
        return
      }

      this.voice = (localStorage.get(this.storageKey) || { voice: 1 }).voice
      document.body.addEventListener('touchend', this.initBgVoice, { once: true })
    },

    initBgVoice() {
      if (this.voice && !this.isInVoice) {
        this.$refs.bgm?.play().catch((e) => {
          this.voice = false
          console.log(e)
        })
      }
    },

    allAudioPause(refs = ['bgm']) {
      refs.forEach((ref) => this.$refs[ref]?.pause())
      this.voice = false
    },

    audioPlay(ref) {
      console.log('audioPlay ref=', ref, '声效开关=', this.voice)
      if (this.voice) {
        const audio = this.$refs[ref]
        if (Array.isArray(audio)) {
          audio[0]?.play()
        } else {
          audio?.play()
        }
      }
    },

    audioPause(ref) {
      this.$refs[ref]?.pause()
    },

    changeVoice() {
      this.voice = Number(!this.voice)
      if (this.voice) {
        this.initBgVoice()
      } else {
        this.audioPause('bgm')
      }
      // localStorage.set(`bgmVoiceControl`, this.voice)
      localStorage.set(this.storageKey, { voice: this.voice })
    },

    onHide() {
      this.allAudioPause()
    },

    onShow() {
      // 同步同一活动下其他页面设置了voice
      this.initVoice()
    }
  }
}
