<template lang="pug">
a-section.ui-normal-reward-dialog(v-bind='attrs')
  //- 非阿语服
  a-dialog.ui-normal-reward-dialog__not-isA(v-if='!isA', :name='dialogName', :cache='extra.cache', wh='6.54 8.96')
    template(slot-scope='scope', v-if='scope.data')
      //- 定义临时变量
      span(:data-var='(len = scope.data.rewardList.length)')
      span(:data-var='(styleConfig = rewardsStyleConfig[len < 5 ? len : 5])')
      span(:data-var='(wrapStyle = styleConfig.wrap(getRewardsWrapPt(len, rewardWrapStyle)))')
      //- 小于5
      a-position.ui-normal-reward-dialog__not-isA__dialog-bg(:bg-i='len < 5 ? image.dialogBg1 : image.dialogBg2', pc, bg-s='100%')
        slot(:data='scope.data')
        a-position.ui-normal-reward-dialog__not-isA__dialog-bg__reward-list-wrapper(v-bind='getWrapStyle(wrapStyle)', :class='getClass(len)')
          a-section.ui-normal-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item(v-for='(r, i) in scope.data.rewardList', :key='i', v-bind='{ ...(len < 5 ? {} : calculateMargin(i)), ...styleConfig.reward }', :bg-i='image.rewardBg', bg-s='contain')
            a-position.ui-normal-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__isSp(v-if='r.isSp', wh='100%', pc)
              slot(name='light')
              a-position.bright.ui-normal-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__isSp__bright(wh='100%', :bg-i='lightImg')
            slot(v-if='$scopedSlots["prop"]', name='prop', v-bind='{ data: r }')
            ui-prop.ui-normal-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__prop(v-else, wh='100%', pc, :data='r', :extra='{ canPreview: false }')
            slot(name='tag', v-bind='{ data: r }')
            a-border-text1.ui-normal-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__unit(v-bind='{ ...commonSubscriptStyle, ...subscriptBaseStyle }', borderWidth='3', :borderColor='subscriptStyle.borderColor', :borderContent='r.unit')
            a-position.txt-c.ui-normal-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__reward-name(v-bind='styleConfig.rewardNameStyle', :fc='rewardNameStyle.color', pc='x', :style='rewardNameStyle', v-html='r.reward_name?.replace("（", "(")?.replace("）", ")")')
        slot(v-if='$scopedSlots["confirm"]', name='confirm', :data='scope.data')
        ui-button.ui-normal-reward-dialog__not-isA__dialog-bg__confirm-button(v-else, pc='x', :bg-i='image.confirmButton', bg-s='100%', v-bind='{ pb: 0.74, ...getCustomizeStyle("confirmButton") }', @a-tap='dialogConfirm')

  //- 阿语服
  a-dialog.ui-normal-reward-dialog__isA(v-if='isA', :name='dialogName', :cache='extra.cache', wh='6.54 8.86')
    template(slot-scope='scope', v-if='scope.data')
      //- 定义临时变量
      span(:data-var='(len = scope.data.rewardList.length)')
      span(:data-var='(styleConfig = rewardsStyleConfigA[len <= 6 ? len : 7])')
      span(:data-var='(wrapStyle = styleConfig.wrap(getRewardsWrapPt(len, rewardWrapStyle)))')
      //- 小于5
      a-position.ui-normal-reward-dialog__isA__dialog-bg(:bg-i='len < 4 ? image.dialogBg1 : image.dialogBg2', pc, v-bind='{ ...styleConfig.dialog, ...getCustomizeStyle("rewardSizeStyle") }')
        slot(:data='scope.data')
        a-position.ui-normal-reward-dialog__isA__dialog-bg__reward-list-wrapper(v-bind='getWrapStyle(wrapStyle)', :class='getClass(len)')
          a-section.ui-normal-reward-dialog__isA__dialog-bg__reward-list-wrapper__item(v-for='(r, i) in scope.data.rewardList', :key='i', v-bind='{ ...(len < 5 ? {} : calculateMargin(i)), ...styleConfig.reward }', :bg-i='image.rewardBg', bg-s='contain')
            a-position.ui-normal-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__isSp(v-if='r.isSp', wh='100%', pc)
              slot(name='light')
              a-position.bright.ui-normal-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__isSp__bright(wh='100%', :bg-i='lightImg')
            slot(v-if='$scopedSlots["prop"]', name='prop', v-bind='{ data: r }')
            ui-prop.ui-normal-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__prop(v-else, wh='100%', pc, :data='r', :extra='{ canPreview: extra.canPreview }')
            slot(name='tag', v-bind='{ data: r }')
            a-border-text1.ui-normal-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__unit(v-bind='{ ...commonSubscriptStyle, ...subscriptBaseStyle }', borderWidth='3', :borderColor='subscriptStyle.borderColor', :borderContent='r.unit')
            a-position.txt-c.ui-normal-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__reward-name(v-bind='styleConfig.rewardNameStyle', :fc='rewardNameStyle.color', pc='x', :style='rewardNameStyle', v-html='r.reward_name')
        slot(v-if='$scopedSlots["confirm"]', name='confirm')
        ui-button.ui-normal-reward-dialog__isA__dialog-bg__confirm-button(v-else, pc='x', :bg-i='image.confirmButton', bg-s='100%', v-bind='{ pb: len > 4 ? 0.54 : 0.74, ...getCustomizeStyle("confirmButton") }', @a-tap='dialogConfirm')

</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { rewardsStyleConfig, rewardsStyleConfigA, lightConfig, calculateMargin, commonSubscriptStyle, getRewardsWrapPt, getWrapStyle, getClass } from './utils/config'
import { isA, isQ } from '../utils/config'

export default {
  mixins: [attrs],

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      rewardsStyleConfig,
      rewardsStyleConfigA,
      commonSubscriptStyle
    }
  },

  computed: {
    isA() {
      return isQ || isA
    },
    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    rewardNameStyle({ uiMeta }) {
      return get(uiMeta, 'props.rewardNameStyle', {})
    },

    subscriptStyle({ uiMeta }) {
      return get(uiMeta, 'props.subscriptStyle', {})
    },

    subscriptBaseStyle({ uiMeta }) {
      let result = {}
      const style = get(uiMeta, 'props.subscriptStyle', {})
      if (style.color || style.fc) {
        result.fc = style.color || style.fc
      }
      if (style.bottom || style.pb) {
        result.pb = style.bottom || style.pb
      }
      if (style.right || style.pr) {
        result.pr = style.right || style.pr
      }
      if (style.left || style.pl) {
        result.pl = style.left || style.pl
      }
      return result
    },

    lightImg({ $route: { meta } }) {
      return meta.isInternational ? lightConfig.wp : lightConfig.hw
    },

    dialogName({ uiMeta: { key } }) {
      return key
    },

    extra({ uiMeta }) {
      return get(uiMeta, 'props.extra', { cache: false, showTag: false, canPreview: false })
    },

    rewardWrapStyle({ uiMeta }) {
      return get(uiMeta, 'props.rewardWrapStyle', {})
    },

    newStandard({ uiMeta }) {
      return get(uiMeta, 'props.newStandard', false)
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {})
    }
  },

  methods: {
    getClass,
    getWrapStyle,
    calculateMargin,
    getRewardsWrapPt,

    dialogConfirm() {
      this.$dialogs[this.dialogName].close()
      this.$emit('confirmCallback')
    },

    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.bright {
  animation: bright 3s linear infinite;
  transform: translate3d(0, 0, 0);

  @keyframes bright {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}
</style>
