<template lang="pug">
  a-position#activity-team.txt-c(:fc="textConfig.commonTextColor" fw='500' fs='.24')
    //- 首次进入弹窗
    a-dialog(name='uiTeamInviteFriends' :bg-i='dialogImage.inviteFriends' wh='6.54 6.24')
      a-position(v-bind="uiTeamInviteFriends.teamMembersWrap")
        a-avatar(v-bind="uiTeamInviteFriends.avatarStyle" :bg-i='teamMembers[0].head_img_url' :uid="teamMembers[0].uid" :style="avatarBorderStyle")
          //- a-position(wh='100%' pc :bg-i="avatarImage.avatarBorder")
        a-position.ellipsis(v-bind="uiTeamInviteFriends.nickname" :fc="textConfig.commonNicknameColor") {{ teamMembers[0].nickname }}
      a-button1(:bg-i='buttonImage.look' bg-s="contain" v-bind="uiTeamInviteFriends.lookBtn" @a-tap="$dialogs.uiTeamInviteFriends.close()")
      a-button1(:bg-i='buttonImage.inviteFriends' bg-s="contain" v-bind="uiTeamInviteFriends.inviteFriends" @a-tap='getInviteStatus')
    
    //- 邀请好友弹窗
    a-dialog(name='uiTeamSearchContacts' :bg-i='dialogImage.searchContacts')
      //- a-position(:bg-i='commonImage.dialogClose' v-bind="uiTeamSearchContacts.close" @a-tap="$dialogs.uiTeamSearchContacts.close()")
      //- 队伍成员信息
      a-position.fx-row.fx-m-between(v-bind="uiTeamSearchContacts.teamMembersStyle.wrap")
        a-section(v-for="(item,index) in teamMembers" :key="index" v-bind="uiTeamSearchContacts.teamMembersStyle.item")
          a-avatar(v-if="item.uid" v-bind="uiTeamSearchContacts.teamMembersStyle.avatar" :bg-i="item.head_img_url" :uid="item.uid" :style="avatarBorderStyle")
            //- a-position(wh='100%' pc :bg-i="avatarImage.avatarBorder")
          a-position(v-else v-bind="uiTeamSearchContacts.teamMembersStyle.avatar" :bg-i="avatarImage.defaultAvatar")
          a-position.ellipsis(v-bind="uiTeamSearchContacts.teamMembersStyle.nickname" :fc="textConfig.commonNicknameColor") {{ item.nickname }}
      //- 搜索框
      a-position(v-bind="uiTeamSearchContacts.searchInputStyle.wrap")
        input.input(v-model='searchVal' :style="{ ...uiTeamSearchContacts.searchInputStyle.input, '--color': textConfig.inputColor }" :placeholder="teamTextConfig.searchPlaceholder")
      //- 联系人列表
      a-position.of-h-x.of-a-y(v-bind="uiTeamSearchContacts.searchList.wrap")
        a-section(v-for='(item,index) in userInviteList' :key='index' v-bind="uiTeamSearchContacts.searchList.item")
          a-avatar(:bg-i='item.avatar' v-bind="uiTeamSearchContacts.searchList.avatarStyle" :uid="item.uid" :style="avatarBorderStyle")
            //- a-position(wh='100%' pc :bg-i="avatarImage.avatarBorder")
          a-position.ellipsis(v-bind="uiTeamSearchContacts.searchList.nickname" :fc="textConfig.commonNicknameColor") {{ item.nickname }}
          a-button1(v-if='item.status !== 2' v-bind="uiTeamSearchContacts.searchList.inviteBtn" :bg-i='buttonImage.invite' @a-tap='onInvite(item)')
          a-position(v-else v-bind="uiTeamSearchContacts.searchList.inviteBtn" :bg-i='buttonImage.invited' @a-tap='onInvite(item)')
          a-position(v-bind="uiTeamSearchContacts.searchList.line" :bg-i="teamImgConfig.line" v-if='index + 1 !== userInviteList.length')
        a-position(v-show='!userInviteList.length' v-bind="uiTeamSearchContacts.searchList.emptyText" v-html="teamTextConfig.searchListEmpty")

    //- 邀请确认弹窗
    a-dialog(name='uiTeamInviteConfirm' :bg-i='dialogImage.inviteConfirm')
      template(slot-scope="scope" v-if="scope.data")
        a-avatar(:bg-i='scope.data.avatar' v-bind="uiTeamInviteConfirm.userStyle.avatar" :uid="scope.data.uid" :style="avatarBorderStyle")
          //- a-position(wh='100%' pc :bg-i="avatarImage.avatarBorder")
        a-position(v-bind="uiTeamInviteConfirm.userStyle.nickname" :fc="textConfig.commonNicknameColor") {{ scope.data.nickname }}
        //- a-position.txt-c(fs='.22' w='5.3' pc='x' pt='3.8' fw='400' fc='#8E472E' lh='.3' v-html="$tf('team/t3')")
      a-button1(v-bind="uiTeamInviteConfirm.cancelBtn" slot="cancel" :bg-i="buttonImage.cancel" @a-tap="$dialogs.uiTeamInviteConfirm.close()")
      a-button1(v-bind="uiTeamInviteConfirm.inviteFriends" slot="confirm" :bg-i="buttonImage.confirmShort" @a-tap="$dialogs.uiTeamInviteConfirm.close()")
    
    //- 收到组队邀请
    a-dialog(name='uiTeamRecvInvitation' :bg-i="dialogImage.recvInvitation")
      template(slot-scope="scope" v-if="scope.data")
        //- 组队文案
        a-position(v-bind="uiTeamRecvInvitation.recvTextStyle" v-html="$tf(textConfig.recvInvitation.recvText || teamTextConfig.recvInvitationText, $ts(scope.data.inviteUserInfo.nickname))") 
        //- 队伍成员信息
        a-position.fx-row.fx-m-between(v-bind="uiTeamRecvInvitation.teamMembersStyle.wrap")
          a-section(v-for="(item,index) in scope.data.teamMembers" :key="index" v-bind="uiTeamRecvInvitation.teamMembersStyle.item")
            a-avatar(v-if="item.uid" v-bind="uiTeamRecvInvitation.teamMembersStyle.avatar" :bg-i="item.head_img_url" :uid="item.uid" :style="avatarBorderStyle")
              //- a-position(wh='100%' pc :bg-i="avatarImage.avatarBorder")
            a-position(v-else v-bind="uiTeamRecvInvitation.teamMembersStyle.avatar" :bg-i="avatarImage.defaultAvatar")
            a-position.ellipsis(v-bind="uiTeamRecvInvitation.teamMembersStyle.nickname" :fc="textConfig.commonNicknameColor") {{ item.nickname }}
        //- 按钮
        a-button1(:bg-i='buttonImage.reject' v-bind="uiTeamRecvInvitation.rejectBtn" @a-tap="onHandleInvite('reject')")
        a-button1(:bg-i='buttonImage.agree' v-bind="uiTeamRecvInvitation.agreeBtn" @a-tap="onHandleInvite('agree')")
    
    //- 组队确认，拒绝确认
    a-dialog(name='uiTeamConfirm' wh='6.54 4.9')
      template(slot-scope="scope" v-if="scope.data")
        a-position(:bg-i="scope.data.type === 'reject' ? dialogImage.rejectConfirm : dialogImage.agreeConfirm" center)
          a-position(v-bind="uiTeamConfirm.textWrap")
            a-position(v-if="textConfig.joinConfirm" w='100%' v-html="$tf(scope.data.type === 'reject' ? textConfig.joinConfirm.rejectText : textConfig.joinConfirm.agreeText, $ts(scope.data.inviteUserInfo.nickname))" pc)
            a-position(v-else w='100%' v-html="$tf(scope.data.type === 'reject' ? teamTextConfig.rejectConfirm : teamTextConfig.agreeConfirm, $ts(scope.data.inviteUserInfo.nickname))" pc)
      a-button1(v-bind="uiTeamConfirm.cancelBtn" slot="cancel" :bg-i='buttonImage.cancel' @a-tap="$dialogs.uiTeamConfirm.close()")
      a-button1(v-bind="uiTeamConfirm.confirmBtn" slot="confirm" :bg-i='buttonImage.confirmShort' @a-tap="$dialogs.uiTeamConfirm.close()")

    //- 加入成功
    a-dialog(name='uiTeamJoinSuccess' :bg-i='dialogImage.joinSuccess')
      template(slot-scope="scope" v-if="scope.data")
        //- 队伍名
        a-position.txt-c(v-bind="uiTeamJoinSuccess.teamNameWrap" :fc="textConfig.joinSuccess.teamNameColor") {{ teamState.teamName }}
        //- 队伍成员信息
        a-position.fx-row.fx-m-between(v-bind="uiTeamJoinSuccess.teamMembersStyle.wrap")
          a-section(v-for="(item,index) in teamMembers" :key="index" v-bind="uiTeamJoinSuccess.teamMembersStyle.item")
            a-avatar(v-if="item.uid" v-bind="uiTeamJoinSuccess.teamMembersStyle.avatar" :bg-i="item.head_img_url" :uid="item.uid" :style="avatarBorderStyle")
              //- a-position(wh='100%' pc :bg-i="avatarImage.avatarBorder")
            a-position(v-else v-bind="uiTeamJoinSuccess.teamMembersStyle.avatar" :bg-i="avatarImage.defaultAvatar")
            a-position.ellipsis(v-bind="uiTeamJoinSuccess.teamMembersStyle.nickname" :fc="textConfig.commonNicknameColor") {{ item.nickname }}
        //- 按钮
        a-button1(v-bind="uiTeamJoinSuccess.successBtn" :bg-i='buttonImage.success' @a-tap="$dialogs.uiTeamJoinSuccess.close({ all: true })")

    //- 加入团队失败弹窗
    a-dialog.join-fail(name='uiTeamInviteFail' :bg-i='dialogImage.inviteFail')
      template(slot-scope="scope" v-if="scope.data") 
        a-position(v-bind="uiTeamInviteFail.textWrap")
          span(v-if="scope.data.type === 'full'" v-html="$tf((textConfig.inviteFail && textConfig.inviteFail.otherHaveTeamText) || teamTextConfig.otherTeamFull, $ts(scope.data.inviteUserInfo.nickname))")
          span(v-else v-html="$tf((textConfig.inviteFail && textConfig.inviteFail.iHaveTeamText) || teamTextConfig.youHaveTeam, $ts(scope.data.inviteUserInfo.nickname))")
        a-avatar(:bg-i='scope.data.inviteUserInfo.head_img_url' v-bind="uiTeamInviteFail.userStyle.avatar" :uid="scope.data.inviteUserInfo.uid" :style="avatarBorderStyle")
          //- a-position(wh='100%' pc :bg-i="avatarImage.avatarBorder")
        a-position.ellipsis(v-bind="uiTeamInviteFail.userStyle.nickname" :fc="textConfig.commonNicknameColor") {{ scope.data.inviteUserInfo.nickname }}
        a-button1(v-bind="uiTeamInviteFail.okBtn" :bg-i='buttonImage.ok' @a-tap="$dialogs.uiTeamInviteFail.close()")
    
    //- 修改队伍名
    a-dialog(name='uiTeamEditTeamName' :bg-i='dialogImage.editTeamName')
      //- a-position(bg-i='icon/close.png' pt='.3' pr='.3' @a-tap='$dialogs.edit.close()')
      a-position(v-bind="uiTeamEditTeamName.input")
        input.input1(v-model='inputTeamName' :maxlength="teamRegionConfig.teamNameLength" :placeholder="teamTextConfig.editNamePlaceholder" @keydown="onInput" :style="{'--color': textConfig.inputColor}")
      a-button1(:bg-i='buttonImage.confirmLong' v-bind="uiTeamEditTeamName.confirmLong" @a-tap='onModify')
</template>

<script>
import localStorage from '@/utils/local-storage'
import get from 'lodash/get'
import { commonImage } from '@sub/ui-components/utils/config'
import { getters2Computed } from '@sub/store/utils'
import { teamStyleConfig, teamTextConfig, teamImgConfig, teamRegionConfig } from './utils/config-4-members.js'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      ...teamStyleConfig,
      teamImgConfig,
      commonImage,
      teamRegionConfig,
      storageInfo: {},
      searchVal: '',
      inputTeamName: ''
    }
  },

  computed: {
    ...getters2Computed('team'),

    teamTextConfig({ textConfig }) {
      return teamTextConfig(textConfig.commonImportantTextColor)
    },

    teamState() {
      return this.$store.state.team
    },

    contact() {
      return this.$store.state.contact
    },

    amBasic() {
      return this.$store.state.base.amBasic
    },

    storageKey() {
      return `team-${this.amBasic.user.uid}-${window.location.pathname}`
    },

    userInviteList({ teamState: { inviteList }, contact: { contact } }) {
      return inviteList
        .map((item) => ({ ...item, ...contact.find((u) => u.uid === item.uid) }))
        .filter((item) => item.uid !== this.amBasic.user.uid)
        .filter((item) => (this.searchVal ? item.nickname.indexOf(this.searchVal) !== -1 : item))
    },

    buttonImage({ uiMeta }) {
      return get(uiMeta, 'props.buttonImage', {})
    },

    avatarImage({ uiMeta }) {
      return get(uiMeta, 'props.avatarImage', {})
    },

    dialogImage({ uiMeta }) {
      return get(uiMeta, 'props.dialogImage', {})
    },

    textConfig({ uiMeta }) {
      return get(uiMeta, 'props.textConfig', 0)
    },

    otherStyle({ uiMeta }) {
      return get(uiMeta, 'props.otherStyle', {})
    },

    avatarBorderStyle({ otherStyle }) {
      return {
        border: `0.03rem solid ${otherStyle.avatarBorderColor}`
      }
    }
  },

  created() {
    this.$store.commit('team/initDefault', {
      teamNum: 4,
      normalTeamName: this.textConfig.normalTeamName,
      normalAvatar: this.avatarImage.defaultAvatar
    })

    this.$store.commit('team/setMethods', {
      openEdit: this.openEditDialog.bind(this),
      openSearchList: this.getInviteStatus.bind(this)
    })
  },

  async mounted() {
    this.initStorage()
    await this.$asyncAll([this.getTeamInfo(), this.getTeamName()])
    await this.initPopup()
  },

  methods: {
    initStorage() {
      const storageInfo = localStorage.get(this.storageKey) || { isFirstEntry: true, teamInfo: {}, rejectInfo: {}, enterInfo: {} }
      this.storageInfo = storageInfo
      localStorage.set(this.storageKey, this.storageInfo)
    },

    async initPopup() {
      const popupSteps = []
      let currentStep = 0
      const { isFirstEntry, rejectInfo } = this.storageInfo
      const {
        sourceUser,
        teamInfo,
        extra: { teamNum }
      } = this.teamState

      // 是否被邀请，邀请弹窗
      if (sourceUser) {
        popupSteps.push({
          handle: async () => {
            const TargetTeamInfo = await this.$store.dispatch('team/amGetOtherTeamInfo', sourceUser)
            const inviteUserInfo = TargetTeamInfo.details.find((item) => +item.uid === +sourceUser)
            this.inviteUserInfo = inviteUserInfo

            return new Promise((resolve) => {
              if (TargetTeamInfo.details.find((item) => item.uid === this.amBasic.user.uid)) {
                resolve()
                return
              }

              if (TargetTeamInfo.details.length === teamNum) {
                // 对方满员
                this.$dialogs.uiTeamInviteFail.open({ data: { inviteUserInfo, type: 'full' }, onclose: resolve })
              } else if (teamInfo.total_num > 1) {
                // 你已组队
                this.$dialogs.uiTeamInviteFail.open({ data: { inviteUserInfo, type: 'haveTeam' }, onclose: resolve })
              } else {
                if (rejectInfo && rejectInfo[sourceUser]) {
                  this.$toast(this.teamTextConfig.haveRejected)
                  resolve()
                } else {
                  this.$dialogs.uiTeamRecvInvitation.open({ data: { teamMembers: this.formatTeamMembers(TargetTeamInfo), inviteUserInfo }, onclose: resolve, options: { bgClose: true } })
                }
              }
            })
          }
        })
      }

      // 是否有新人加入队伍
      let { teamInfo: storageTeamInfo } = this.storageInfo
      if (teamInfo.total_num > 1 && teamInfo.total_num > (storageTeamInfo?.total_num || 1)) {
        // 更新本地队伍信息
        this.storageInfo.teamInfo = teamInfo
        localStorage.set(this.storageKey, this.storageInfo)

        let newMemberArr = []
        teamInfo.details.forEach((item) => {
          const target = !storageTeamInfo.details?.find(
            // eslint-disable-next-line
            (p) => p.uid === item.uid && item.uid !== this.amBasic.user.uid
          )
          if (!storageTeamInfo.details || target) {
            newMemberArr = [item]
          }
        })

        // 集结满弹窗
        if (newMemberArr.length) {
          popupSteps.push({
            handle: async () => {
              return new Promise((resolve) => {
                this.$dialogs.uiTeamJoinSuccess.open({ data: { teamInfo, newMemberArr }, onclose: resolve })
              })
            }
          })
        }
      }

      // 首次进入弹窗
      if (!sourceUser && isFirstEntry && teamInfo.total_num === 1) {
        popupSteps.push({
          handle: async () => {
            return new Promise((resolve) => {
              this.$dialogs.uiTeamInviteFriends.open({ options: { bgClose: true }, onclose: resolve })
            })
          }
        })
      }

      this.storageInfo.isFirstEntry = false
      localStorage.set(this.storageKey, this.storageInfo)
      while (popupSteps[currentStep]) {
        await popupSteps[currentStep].handle()
        currentStep++
      }
    },

    getTeamInfo() {
      return this.$store.dispatch('team/amGetTeamInfo')
    },

    getTeamName() {
      return this.$store.dispatch('team/amGetTeamName')
    },

    // 获取可邀请的联系人
    async getInviteStatus() {
      if (this.teamState.teamInfo.total_num === this.teamState.extra.teamNum) {
        return
      }

      await this.$store.dispatch('contact/amSetContact')
      this.$store.dispatch('team/amGetInviteStatus')
      this.$dialogs.uiTeamSearchContacts.open({ options: { bgClose: true } })
    },

    async onInvite(friend) {
      const res = await this.$dialogs.uiTeamInviteConfirm.confirm({ data: friend })
      if (!res) {
        return
      }

      await this.$store.dispatch('team/amInviteFriend', friend.uid)
      this.$toast(this.teamTextConfig.sendInvite)
    },

    async onConfirm(type) {
      const { sourceUser } = this.teamState
      if (type === 'reject') {
        await this.$store.dispatch('team/amRejectTeam')
        this.storageInfo.rejectInfo = {
          ...(this.storageInfo.rejectInfo || {}),
          [sourceUser]: true
        }
        this.$dialogs.uiTeamConfirm.close({ all: true })
      } else {
        await this.$store.dispatch('team/amJoinTeam')
        await this.getTeamInfo()
        this.$dialogs.uiTeamConfirm.close()

        this.$dialogs.uiTeamJoinSuccess.open({
          onclose: () => {
            document.body.style.overflow = null
          }
        })
        this.storageInfo.teamInfo = this.teamState.teamInfo
      }

      localStorage.set(this.storageKey, this.storageInfo)
    },

    async onHandleInvite(type) {
      const res = await this.$dialogs.uiTeamConfirm.confirm({ data: { inviteUserInfo: this.inviteUserInfo, type } })
      if (!res) {
        return
      }

      this.onConfirm(type)
    },

    openEditDialog() {
      this.$dialogs.uiTeamEditTeamName.open({
        options: { bgClose: true },
        onclose: () => {
          this.inputTeamName = ''
        }
      })
    },

    async onModify() {
      if (!this.inputTeamName) {
        return this.$toast(this.teamTextConfig.editEmptyTip)
      }

      const len = this.teamRegionConfig.teamNameLength
      await this.$store.dispatch('team/amModifyTeamName', this.inputTeamName.slice(0, len))
      this.$dialogs.uiTeamEditTeamName.close()
      this.getTeamName()
      this.inputTeamName = ''
    },

    formatTeamMembers(teamInfo) {
      const {
        extra: { teamNum, normalAvatar, normalNickname }
      } = this.teamState
      return new Array(teamNum).fill({}).map((item, index) => teamInfo.details[index] || { nickname: normalNickname, head_img_url: normalAvatar })
    },

    onInput() {
      const len = this.teamRegionConfig.teamNameLength
      this.inputTeamName = this.inputTeamName.slice(0, len)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  line-height: 0.68rem;
  font-size: 0.28rem;
  padding-left: 0.7rem;
  color: var(--color);

  &::placeholder {
    font-size: 0.28rem;
    line-height: 0.68rem;
    color: var(--color);
  }
}

.input1 {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  line-height: 0.72rem;
  font-size: 0.32rem;
  text-align: center;
  color: var(--color);

  &::placeholder {
    font-size: 0.32rem;
    line-height: 0.72rem;
    color: var(--color);
  }
}
</style>
