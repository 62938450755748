<template lang="pug">
a-section.ui-buy-chip(v-bind='attrs')
  //- 弹窗类型
  a-dialog.ui-buy-chip__dialog(v-if='type === "dialog"', :id='dialogName', :name='dialogName', wh='6.54 8.24', lh='.28', :bg-i='imageBuy.buyDialogBg')
    buy-content(:buy-style-config='buyStyleConfig', :ui-meta='uiMeta', type='dialog', @onBuy='onBuy')
  //- pull up 类型
  a-pull-up.ui-buy-chip__pull(v-else, :id='dialogName', :name='dialogName')
    a-section.ui-buy-chip__pull__bg(slot-scope='scope', lh='.28', :bg-i='imageBuy.buyDialogBg')
      buy-content(:buy-style-config='buyStyleConfig', :ui-meta='uiMeta', type='pull', @onBuy='onBuy')

  a-dialog.ui-buy-chip__dialog--confirm(v-if='!_isRuntime', :name='confirmDialogName', :bg-i='imageConfirm.confirmDialogBg')
    template(slot-scope='scope', v-if='scope.data')
      slot(name='confirmDialog')
      a-position.txt-c.ui-buy-chip__dialog--confirm__text(v-bind='{ ...confirmStyleConfig.text, ...getCustomizeStyle("textWrap") }', :fc='textStyle.confirmTextColor')
        a-section.txt-c.ui-buy-chip__dialog--confirm__text--inner(:fc='textConfig.confirmTextColor', v-html='buyConfirmText(textWords.confirmText || textConfig.buyConfirm, scope.data.confirmPrice || scope.data.price, scope.data.confirmNum || scope.data.num, textWords.lotteryCoinName)')

      a-position.txt-c.ui-buy-chip__dialog--confirm__noBuyConfirm(:bg-i='storageInfo[storageKey] ? imageConfirm.noBuyConfirm1 : imageConfirm.noBuyConfirm0', v-bind='{ ...confirmStyleConfig.tip, ...getCustomizeStyle("tipWrap") }', @a-tap='toggleTip()')

    a-button1.txt-c.ui-buy-chip__dialog--confirm__cancel(slot='cancel', v-bind='{ ...confirmStyleConfig.cancelButton, ...getCustomizeStyle("cancelBtn") }', :bg-i='imageConfirm.cancelButton', @a-tap='$dialogs[confirmDialogName].close()')
    a-button1.txt-c.ui-buy-chip__dialog--confirm__confirm(slot='confirm', v-bind='{ ...confirmStyleConfig.confirmButton, ...getCustomizeStyle("confirmBtn") }', :bg-i='imageConfirm.confirmButton', @a-tap='$dialogs[confirmDialogName].close()')
</template>

<script>
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { getNumUnit } from '@/utils/utils'
import { buildApi, concatCatch } from '@sub/utils/request'
import { useMutationObserver } from '@vueuse/core'
import { commonText } from '@sub/ui-components/utils/config'
import { buyStyleConfig, confirmStyleConfig, genBuyTaskTextConfig, getCoinPoorText } from './utils/config'
import { isA } from '../utils/config'
import { PAGE_REGION } from '@/utils/env'
import buyContent from './components/buy-content.vue'
import { logData } from '@sub/ui-runtime/core/utils/logData'

const buyCollectChip = buildApi('/activity_v2/collect_chip/buy_collect_chip')

export default {
  mixins: [attrs],

  components: { buyContent },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      commonText,
      buyStyleConfig,
      confirmStyleConfig,
      getCoinPoorText,
      isA,
      lastClassName: []
    }
  },

  computed: {
    type({ uiMeta }) {
      return get(uiMeta, 'props.type', 'dialog')
    },
    imageBuy({ uiMeta }) {
      return get(uiMeta, 'props.image.buy', {})
    },

    imageConfirm({ uiMeta }) {
      return get(uiMeta, 'props.image.confirm', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.text.style', {})
    },

    textWords({ uiMeta }) {
      return get(uiMeta, 'props.text.words', {})
    },

    dialogName({ uiMeta: { key } }) {
      return key
    },

    confirmDialogName({ dialogName }) {
      return `${dialogName}UiBuyChipConfirm`
    },

    buyConfirmDialogKey({ uiMeta: { props } }) {
      return props?.buyConfirmDialogKey
    },

    rewardDialogKey({ uiMeta: { rewardDialogKey, props } }) {
      return props?.rewardDialogKey || rewardDialogKey
    },

    buyConfig({ uiMeta }) {
      return get(uiMeta, 'props.buyConfig', [])
    },

    chipId({ uiMeta }) {
      return get(uiMeta, 'props.chipId', 1)
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {}) || {}
    },

    textConfig({ textStyle: { confirmTextImportantColor } }) {
      return genBuyTaskTextConfig(confirmTextImportantColor)
    },

    storageInfo() {
      return this.$storage.localStorage()
    },

    buyChipInfo({ chipId }) {
      return {
        coin: this.$store.state.base.coin,
        chipCount: this.$store.state.base.chipInfo[chipId]
      }
    },

    storageKey() {
      return `noBuyConfirm`
    }
  },

  created() {
    // this.$store.dispatch('storage/init', { [this.storageKey]: false })
    this.$uiComp.setUiCompConfig({
      compName: 'uiBuyChipDialog',
      compKey: this.dialogName,
      value: {
        // updateStorage: this.updateStorage.bind(this)
      }
    })
  },

  mounted() {
    this.openObserver()
  },

  destroyed() {
    this.removeObserver()
  },

  methods: {
    // 各区服语序不一样的返回
    buyConfirmText(text, price, num, name) {
      if (PAGE_REGION === 'K') {
        return this.$tf(text, price, name, num)
      } else {
        return this.$tf(text, price, num, name)
      }
    },
    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    },

    async onBuy(data) {
      if (this._isRuntime) {
        logData('购买按钮点击', '')
      }

      if (!this.storageInfo[this.storageKey]) {
        const confirmRes = await this.$dialogs[this.buyConfirmDialogKey || this.confirmDialogName].confirm({ data })
        if (!confirmRes) {
          return
        }
      }

      // 金币不足奖励弹窗
      if (this.buyChipInfo.coin < data.price) {
        return this.$toast(getCoinPoorText, 1000).then(() => {
          this.$we('pay')
        })
      }

      const [err, res] = await concatCatch(buyCollectChip({ chip_id: this.chipId, number: data.num }))
      if (err) {
        console.error('🐲 gwl ~ onBuy ~ err:', err)
        return
      }
      this.$dialogs[this.rewardDialogKey].open({
        data: {
          rewardList: [
            {
              img: get(this.$route.meta.assets, this.imageBuy.lotteryCoinIcon, this.imageBuy.lotteryCoinIcon) || this.imageBuy.lotteryCoinIcon,
              reward_name: this.textWords.lotteryCoinName,
              unit: getNumUnit(res)
            }
          ]
        }
      })

      this.updateChipInfo()
    },

    toggleTip() {
      this.$store.dispatch(`storage/toggle`, this.storageKey)
      this.$store.commit(`storage/update`, { restoreBuyConfirm: !this.storageInfo[this.storageKey] })
    },

    updateStorage(flag) {
      this.$store.commit(`storage/update`, { [this.storageKey]: !!flag })
    },

    updateChipInfo() {
      this.$base.getUserCoin()
      this.$base.getChipInfo()
    },

    openObserver() {
      if (this._isRuntime) return
      const dom = document.querySelector(`#${this.dialogName}`)
      this.observer = useMutationObserver(
        dom,
        throttle(([{ target }]) => {
          const classList = Array.from(target.classList) || []
          if (classList.includes('active') && !this.lastClassName.includes('active')) {
            this.updateChipInfo()
          }

          this.lastClassName = classList
        }, 300),
        {
          attributes: true
        }
      )
    },

    removeObserver() {
      if (this._isRuntime) return
      this.observer.stop()
    },

    doPoint(name, extra = {}) {
      this.$point.track('ClickH5', {
        act_id: this.$amApiParam().act_id,
        url: `${window.location.origin}${window.location.pathname}`,
        url_path: window.location.pathname,
        btn_name: name,
        title: this.$route.meta.title,
        activity: this.$base.amActivityInfo()?.activity_name || '',
        ...extra
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
