import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

const defaultGetText = {
  dateFormat: 'YYYY.M.DD',
  dateText: '{{}}'
}

const textConfig = {
  hw: () => ({
    dateFormat: 'YYYY年M月DD日',
    dateText: '{{}}获得'
  }),
  C: () => ({
    dateFormat: 'YYYY年M月DD日',
    dateText: '{{}}獲得'
  }),
  J: () => ({
    dateFormat: 'YYYY年M月DD日',
    dateText: '{{}}獲得'
  }),
  K: () => defaultGetText,
  A: () => defaultGetText,
  M: () => defaultGetText,
  T: () => defaultGetText,
  U: () => defaultGetText,
  P: defaultGetText,
  V: defaultGetText,
  Q: () => defaultGetText
}

const mockImageDataMap = proxyAssets({
  hw: {
    ringShareBg: 'https://fe-center.afunapp.com/page-center/assets/MT7CYG9E.png?x-oss-process=image%2Fquality%2Cq_80%2Fformat%2Cwebp&_w=750&_h=1334',
    avatarBorder: 'https://fe-center.afunapp.com/page-center/assets/DzRp61VF.png?x-oss-process=image%2Fquality%2Cq_80%2Fformat%2Cwebp&_w=138&_h=138'
  },
  wp: {
    ringShareBg: 'https://fe-center.weplayapp.com/page-center/assets/2TAKaX8N.png?x-oss-process=image%2Fquality%2Cq_80%2Fformat%2Cwebp&_w=750&_h=1334',
    avatarBorder: 'https://fe-center.weplayapp.com/page-center/assets/0mBMDgi0.png?x-oss-process=image%2Fquality%2Cq_80%2Fformat%2Cwebp&_w=138&_h=138'
  }
})

export const mockStyleData = {
  bg: {
    wh: '7.5 13.34',
    pt: '-1000'
  },
  avatar: {
    wh: 1.36,
    pc: 'x',
    pt: 0.57
  },
  avatarBorderStyle: {
    wh: 1.36,
    pc: 'xy'
  },
  nicknameWrap: {
    wh: '1.84 .49',
    pc: 'x',
    pt: '2.07',
    f: '.22 #fff 500',
    lh: '.49'
  },
  date: {
    wh: '',
    pl: '',
    pt: '',
    f: '',
    lh: ''
  }
}

export const getTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw
export const mockImageData = IS_WEPLAY ? mockImageDataMap.wp : mockImageDataMap.hw
