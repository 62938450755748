import EventEmitter from 'event-emitter'

const CONSTS = {
  ON_SHOW: 'onShow',
  ON_TEAM_INFO_CHANGE_UI_COMP: 'onTeamInfoChange',
  ON_CHIP_CHANGE: 'onChipChange',
  ON_LOTTERY_AGAIN: 'onLotteryAgain',
  ON_LOTTERY_COMPLETE: 'onLotteryComplete',
  ON_SWITCH_TAB: 'onSwitchTab'
}

const emitter = new EventEmitter()

class Event {
  onShow(listener) {
    emitter.on(CONSTS.ON_SHOW, listener)
  }

  emitOnShow() {
    emitter.emit(CONSTS.ON_SHOW)
  }

  offOnShow(listener) {
    emitter.off(CONSTS.ON_SHOW, listener)
  }

  emitOnTeamInfoChange() {
    emitter.emit(CONSTS.ON_TEAM_INFO_CHANGE_UI_COMP)
  }

  onTeamInfoChange(listener) {
    emitter.on(CONSTS.ON_TEAM_INFO_CHANGE_UI_COMP, listener)
  }

  offOnTeamInfoChange(listener) {
    emitter.off(CONSTS.ON_TEAM_INFO_CHANGE_UI_COMP, listener)
  }

  onChipChange(listener) {
    emitter.on(CONSTS.ON_CHIP_CHANGE, listener)
  }

  emitOnChipChange() {
    emitter.emit(CONSTS.ON_CHIP_CHANGE)
  }

  offOnChipChange(listener) {
    emitter.off(CONSTS.ON_CHIP_CHANGE, listener)
  }

  // 抽奖
  // 再抽一次
  onLotteryAgain(listener) {
    emitter.on(CONSTS.ON_LOTTERY_AGAIN, listener)
  }

  emitOnLotteryAgain(number) {
    emitter.emit(CONSTS.ON_LOTTERY_AGAIN, number)
  }

  offOnLotteryAgain(listener) {
    emitter.off(CONSTS.ON_LOTTERY_AGAIN, listener)
  }

  // 抽奖完成
  onLotteryComplete(listener) {
    emitter.on(CONSTS.ON_LOTTERY_COMPLETE, listener)
  }

  emitOnLotteryComplete() {
    emitter.emit(CONSTS.ON_LOTTERY_COMPLETE)
  }

  offLotteryComplete(listener) {
    emitter.off(CONSTS.ON_LOTTERY_COMPLETE, listener)
  }

  emitOnSwitchTab(tabIndex) {
    emitter.emit(CONSTS.ON_SWITCH_TAB, tabIndex)
  }

  onSwitchTab(listener) {
    emitter.on(CONSTS.ON_SWITCH_TAB, listener)
  }
}

const event = new Event()

export default event
