// 创建一个按钮，返回按钮对象
export function createBtn(className = 'btn', innerHTML = '按钮') {
  const btn = document.createElement('button')
  btn.classList.add(className)
  btn.style.margin = '10px 10px 10px 0'
  btn.innerHTML = innerHTML
  return btn
}

export function createBtnPlugin({ btnClassName, btnText, clickCallback, parent }) {
  const btn = createBtn(btnClassName, btnText)
  btn.onclick = () => {
    clickCallback(btn)
  }
  parent.appendChild(btn)
}
