import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import config from '@/config'
import { isNumber, get, isFunction } from 'lodash'
import { PAGE_REGION, IS_DEV } from '@/utils/env'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * 函数描述
 * NOTE: https://dayjs.fenxianglu.cn/category/timezone.html#%E8%AE%BE%E7%BD%AE%E9%BB%98%E8%AE%A4%E6%97%B6%E5%8C%BA
 * @param {string | Number} 时间戳或日期字符串
 * @return {dayjs Object} dayjs日期对象
 */

if (['C', 'M', 'P'].includes(PAGE_REGION)) {
  // UTC+8
  dayjs.tz.setDefault('Asia/Shanghai')
} else if (['A', 'Q', 'R'].includes(PAGE_REGION)) {
  // UTC+3
  dayjs.tz.setDefault('Asia/Riyadh')
} else if (['J', 'K'].includes(PAGE_REGION)) {
  // UTC+9
  dayjs.tz.setDefault('Asia/Tokyo')
} else if (PAGE_REGION === 'T') {
  // UTC+7
  dayjs.tz.setDefault('Asia/Bangkok')
} else if (PAGE_REGION === 'V') {
  // UTC+7
  dayjs.tz.setDefault('Asia/Ho_Chi_Minh')
} else if (PAGE_REGION === 'U') {
  // UTC-8
  dayjs.tz.setDefault('America/Los_Angeles')
} else if (PAGE_REGION === 'B') {
  // UTC-3
  dayjs.tz.setDefault('America/Sao_Paulo')
} else if (PAGE_REGION === 'I') {
  // UTC-5.5
  dayjs.tz.setDefault('Asia/Kolkata')
} else if (PAGE_REGION === 'S') {
  // UTC-6
  dayjs.tz.setDefault('America/Mexico_City')
}

export const $dayjs = (time) => {
  if (isNumber(time)) {
    return dayjs(time).utcOffset(config.utc)
  } else {
    return dayjs(`${dayjs(time).format('YYYY/MM/DD HH:mm:ss')} GMT${config.utc}`)
  }
}

export default dayjs

export const $dayjsTz = function (...args) {
  if (IS_DEV && !isNumber(args[0])) {
    console.warn('非预期的时间类型、建议改成时间戳 ')
  }

  if (isFunction(get(Intl, 'DateTimeFormat.prototype.formatToParts', null))) {
    return dayjs.tz(...args)
  } else {
    return $dayjs(...args)
  }
}
