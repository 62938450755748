export default [
  {
    path: '/20230212-receive-goods',
    component: () => import('./pages/goods/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      ignore: true,
      // expire: '2023/02/12 23:59:59 UTC+8',
      quickConfig: '5ee245f3db00575277072149e4c1e112'
    }
  }
]
