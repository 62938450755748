import Vue from 'vue'
import { isA } from '../../utils/config'
const commonAttr = isA ? 'pl' : 'pr'
const commonAttr1 = isA ? 'pr' : 'pl'
// 获取阿服为天数时的展示方式
export function getArUnit(day) {
  let unit = ''
  if (typeof day !== 'number') return unit
  if (day === 1) {
    unit = 'يوم'
  } else if (day === 2) {
    unit = 'يومين'
  } else if (day >= 3 && day <= 10) {
    unit = `${day} أيام`
  } else if (day >= 11) {
    unit = `${day} يوم`
  }
  return unit
}

// 获取各区服为天数时的展示方式
export function getDayUnit(day) {
  let unit = ''
  // if (typeof day !== 'number') return unit

  const { region } = Vue.prototype.$app.$route.query

  switch (region) {
    case 'C':
      unit = `${day}天`
      break
    case 'J':
      unit = `${day}日`
      break
    case 'K':
      unit = `${day}일`
      break
    case 'A':
      unit = getArUnit(day)
      break
    case 'M':
      unit = `${day} hari`
      break
    case 'T':
      unit = `${day} วัน`
      break
    case 'U':
      unit = `${day} ${day > 1 ? 'Days' : 'Day'}`
      break
    case 'P':
      unit = `${day} ${day > 1 ? 'Days' : 'Day'}`
      break
    default:
      unit = `${day}天`
  }

  return unit
}

// 获取各区服为数量时的展示方式
export function getNumUnit(num) {
  let unit = ''
  if (typeof num !== 'number') return num

  const { region } = Vue.prototype.$app.$route.query

  switch (region) {
    case 'A':
      unit = `${num}x`
      break
    default:
      unit = `x${num}`
  }

  return unit
}

export const defaultStyle = {
  tag: { pl: '-0.14', pt: '-0.08' },
  tagA: { pr: '-0.14', pt: '-0.08' },
  unit: { pr: '0', pb: '-0.04', lh: '.3' },
  unitA: { pl: '0', pb: '-0.04', lh: '.3' }
}

export function getTagStyle(style) {
  if (Object.keys(style).length > 0) {
    return style
  }
  const { region } = Vue.prototype.$app.$route.query
  switch (region) {
    case 'A':
      return defaultStyle.tagA
    default:
      return defaultStyle.tag
  }
}

//- 分类奖励组件（ui-category-reward-detail-dialog）配置
export const defaultStyleCategory = {
  close: () => ({ [commonAttr]: 0.1, pt: 0.1 }),
  tab: { pc: 'x', pt: '0.51' },
  list: () => ({ [commonAttr1]: '0.5', pt: '1.58', wh: '5.55 7' }),
  tag: () => ({ [commonAttr1]: '-0.11', pt: '-0.08' }),
  unit: () => ({ [commonAttr1]: '0', pb: '-0.04', lh: '.3' }),
  subScript: () => ({ fs: isA ? 0.32 : 0.28, fw: 700, pb: 0, [isA ? 'pl' : 'pr']: isA ? 0.08 : 0, fc: '#797979' }),
  rewardName: { fs: 0.2, fc: '#fff', fw: 500, ta: 'ta', lh: 'normal' }
}
