<template lang="pug">
#m-rank.ui-rank-single-top3
  a-section.ui-rank-single-top3__wrapper(v-bind='attrs', w='7.5')
    //- 榜单
    top3.ui-rank-single-top3__wrapper__top3(:top3='top1_3', :bg-i='image.head', w='7.5', :props='props', mt='-.01', :showVal='showVal')
    topCommon.ui-rank-single-top3__wrapper__top-common(:top4_n='top4_n', :props='props', mt='-.01', :h='1.46 * top4_n.length', :showVal='showVal')
    a-section.ui-rank-single-top3__wrapper__footer(v-if='isFixed', :bg-i='image.footer', mt='-.01')
    mine.ui-rank-single-top3__wrapper__mine--1(v-if='!isFixed', :mine='mine', :props='props', :bg-i='image.footer', mt='-.01')
    mineFixed.ui-rank-single-top3__wrapper__mine--2(v-else, :mine='mine', :props='props', :bg-i='image.fixedBg', p='fixed', pb='0')
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import amNewRank from '@sub/ui-runtime/core/activity-modules/newRank'
import get from 'lodash/get'
import { formatItem } from './utils/config'

import top3 from './components/top3.vue'
import topCommon from './components/top4-n.vue'
import mine from './components/mine.vue'
import mineFixed from './components/mine-fixed.vue'

export default {
  mixins: [
    attrs,
    amNewRank({
      format: {
        list: formatItem,
        mine: formatItem,
        luck: formatItem
      }
    })
  ],

  components: { top3, topCommon, mine, mineFixed },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    extraParams: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  watch: {
    'amApiParam.uid': {
      handler(val) {
        if (val) {
          this.init()
        }
      },
      immediate: true
    }
  },

  computed: {
    amApiParam() {
      return this.$base.amApiParam()
    },

    amBasic() {
      return this.$base.amBasic()
    },

    props() {
      return this.uiMeta?.props
    },

    act_id({ props }) {
      return props?.act_id || 0
    },

    isFixed({ props }) {
      return props?.isFixed
    },

    image({ props }) {
      return props?.image
    },

    targetList({ amNewRankUse }) {
      return amNewRankUse?.total || {}
    },

    mine({ targetList }) {
      return get(targetList, 'mine', { u1: {}, u2: {} })
    },
    // 有些区服有隐藏分数的需求
    showVal({ mine }) {
      return this.props?.showValLength ? !!(mine.rank && mine.rank <= this.props?.showValLength) : true
    },

    top1_3({ targetList }) {
      return [0, 1, 2].map((item) => get(targetList, `list.${item}`, { u1: {}, u2: {} }))
    },

    top4_n({ targetList }) {
      return new Array(this.props.rankConfig.rankLength - 3).fill(0).map((item, index) => get(targetList, `list.${index + 3}`, { u1: {}, u2: {} }))
    }
  },

  created() {
    this.$runtime.setEvent({ loadRankSingleTop3: this.loadRank })
  },

  methods: {
    init() {
      this.loadRank()
    },

    loadRank() {
      const { rankLength, rankType, periodType, extra = {} } = this.props?.rankConfig
      let opt = {
        cache: false,
        value: this.$dayjsTz(this.amBasic.time).format('YYYYMMDD'),
        params: { limit: rankLength, offset: 0, rank_type: rankType, period_type: periodType, ...extra, ...this.extraParams },
        ...this.amApiParam
      }
      this.act_id && (opt.act_id = this.act_id)
      this.amNewRankGet({
        period_type: 0,
        name: 'total',
        ...opt
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-border {
  border: 0.02px solid #fff;
}
</style>
