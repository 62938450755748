import Vue from 'vue'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { buildApi } from '@sub/utils/request'

const getLotteryConfig = buildApi('/activity_v2/common/get_act_lottery_rewards')
const getLotteryInfo = buildApi('/activity_v2/lottery/get_lottery_info')

export default {
  namespaced: true,

  state: {
    // 抽奖配置key为lottery_type
    lotteryConfig: {},
    rewardConfig: {},
    isReady: false,

    // type => lotteryInfo
    lotteryInfoMap: {
      // type: {
      // 包含奖励在当前轮次获得的次数
      //   rewardInfoList: [],
      // 还可以抽多少次
      //   curCanTimes: 0,
      // 已经抽了多少次
      //   alreadyTimes: 0
      // }
    }
  },

  mutations: {
    initDefault() {},

    setState(state, payload) {
      Object.assign(state, payload)
    }
  },

  actions: {
    getLotteryConfig: throttle(
      async function ({ commit }) {
        const lotteryConfig = await getLotteryConfig()
        commit('setState', formatLotteryConfig(lotteryConfig))
        commit('setState', { isReady: true })
      },
      100,
      { trailing: false }
    ),

    getLotteryInfo: throttle(
      async function ({ state, commit }, { type }) {
        const { rewards, can_lottery_count, has_lottery_count } = await getLotteryInfo({
          type
        })

        commit('setState', {
          lotteryInfoMap: {
            ...state.lotteryInfoMap,
            [type]: {
              rewardInfoList: rewards,
              curCanTimes: can_lottery_count,
              alreadyTimes: has_lottery_count
            }
          }
        })
      },
      0,
      { trailing: false }
    ),

    getAllLotteryInfo({ state, dispatch }) {
      const { lotteryInfoMap } = state
      const types = Object.keys(lotteryInfoMap)
      for (let type of types) {
        dispatch('getLotteryInfo', { type })
      }
    }
  }
}

function formatLotteryConfig({ lottery_reward_map, lottery_basic_info }) {
  const lotteryTypes = Object.keys(lottery_basic_info)
  const lotteryConfig = {}
  const rewardConfig = {}
  lotteryTypes.forEach((item) => {
    const chipIds = get(lottery_basic_info, `${item}.chip_ids`, []) || []
    const lotteryCoinNames = get(lottery_basic_info, `${item}.lottery_coin_names`, []) || []
    const lotteryMaps = get(lottery_basic_info, `${item}.lottery_map`, {}) || {}
    const sellConfig = get(lottery_basic_info, `${item}.sell_config`, []) || []
    const rewards = get(lottery_reward_map, item, []) || []
    const box_type = get(lottery_basic_info, `${item}.box_type`)

    // 抽奖信息
    lotteryConfig[item] = {
      chipIds,
      lotteryCoinNames,
      lotteryMaps,
      sellConfig,
      chipId: chipIds[0],
      lotteryCoinName: lotteryCoinNames[0],
      box_type
    }

    // 奖励提取
    rewards.forEach((r) => {
      rewardConfig[r.reward_id] = formatItem(r)
    })
  })

  return {
    lotteryConfig,
    rewardConfig
  }
}

function formatItem(itemReward) {
  const vm = Vue.prototype.$app
  const {
    reward_meta: { prop_type_name, prop_img, prop_type, animation_url },
    reward_id,
    reward_type,
    reward_val
  } = itemReward
  const type = reward_type === 'title' ? 999 : prop_type

  return {
    ...itemReward,
    type,
    name: prop_type_name,
    img: prop_img,
    svga: animation_url?.includes('.svga') ? animation_url : '',
    id: reward_id,
    unit: vm.$getPropUnit(reward_id, reward_val, type)
  }
}
