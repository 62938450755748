export const propMap = {
  5: [
    { pc: 'x', pt: '0', wh: '2' },
    { pr: '0', pt: '.89', wh: '1.75' },
    { pr: '.11', pt: '2.94', wh: '1.75' },
    { pl: '.11', pt: '2.94', wh: '1.75' },
    { pl: '0', pt: '.89', wh: '1.75' }
  ],
  6: [
    { pl: '1.39', pt: '0', wh: '1.65' },
    { pr: '1.39', pt: '0', wh: '1.65' },
    { pr: '0', pt: '1.45', wh: '1.65' },
    { pr: '.12', pt: '3.34', wh: '1.65' },
    { pl: '.12', pt: '3.34', wh: '1.65' },
    { pl: '0', pt: '1.45', wh: '1.65' }
  ],
  7: [
    { pc: 'x', pt: '0', wh: '1.65' },
    { pr: '.36', pt: '.72', wh: '1.65' },
    { pr: '0', pt: '2.96', wh: '1.65' },
    { pr: '.36', pt: '5.23', wh: '1.65' },
    { pl: '.36', pt: '5.23', wh: '1.65' },
    { pl: '0', pt: '2.96', wh: '1.65' },
    { pl: '.36', pt: '.72', wh: '1.65' }
  ],
  8: [
    { pc: 'x', pt: '0', wh: '1.65' },
    { pr: '.36', pt: '.72', wh: '1.65' },
    { pr: '0', pt: '3', wh: '1.65' },
    { pr: '.36', pt: '5.26', wh: '1.65' },
    { pc: 'x', pt: '5.97', wh: '1.65' },
    { pl: '.36', pt: '5.26', wh: '1.65' },
    { pl: '0', pt: '3', wh: '1.65' },
    { pl: '.36', pt: '.72', wh: '1.65' }
  ],
  9: [
    { pc: 'x', pt: '.13', wh: '2.24 1.79' },
    { pl: '4.37', pt: '.62', wh: '2 1.77' },
    { pl: '4.91', pt: '2.68', wh: '1.65 1.77' },
    { pl: '4.57', pt: '4.74', wh: '2 1.77' },
    { pl: '3.47', pt: '6.34', wh: '1.74 1.79' },
    { pl: '1.45', pt: '6.34', wh: '1.76 1.79' },
    { pl: '-.02', pt: '4.77', wh: '2 1.77' },
    { pl: '-.06', pt: '2.71', wh: '1.76 1.77' },
    { pl: '.3', pt: '.65', wh: '1.76 1.77' }
  ],
  10: [
    { pl: '2.02', pt: '1.98', wh: '1.77' },
    { pl: '3.87', pt: '2.01', wh: '1.92' },
    { pl: '5.81', pt: ' 2.95', wh: ' 1.93' },
    { pl: ' 5.45', pt: ' 4.7', wh: ' 1.93' },
    { pl: ' 5.51', pt: ' 6.55', wh: ' 1.93' },
    { pl: ' 3.72', pt: ' 6.94', wh: ' 1.93' },
    { pl: '1.87', pt: ' 6.94', wh: ' 1.93' },
    { pl: ' 0', pt: ' 6.44', wh: ' 1.93' },
    { pl: ' 0', pt: ' 4.48', wh: '1.93 ' },
    { pl: ' 0', pt: ' 2.6', wh: ' 1.93' }
  ],
  11: [
    { pl: '0.2', pt: '2.3', wh: '1.7' },
    { pl: '2.02', pt: '1.98', wh: '1.77' },
    { pl: '3.87', pt: '2.01', wh: '1.77' },
    { pl: '5.81', pt: '2.2', wh: '1.77' },
    { pl: '5.45', pt: '4.14', wh: '1.77' },
    { pl: '5.9', pt: '6', wh: '1.4' },
    { pl: '4.8', pt: '7.5', wh: '1.4' },
    { pl: '3.1', pt: '7.5', wh: '1.4' },
    { pl: '1.4', pt: '7.5', wh: '1.4' },
    { pl: '0', pt: '6', wh: '1.4' },
    { pl: '0.8', pt: '4.3', wh: '1.4' }
  ]
}

export const whMap = {
  5: '6 5.65',
  6: '6.44 5.71',
  7: '6.56 7.74',
  8: '6.56 7.74',
  9: '6.56 8.3',
  10: '7.5 9.3',
  11: '7.5 9.3'
}
