// 奖励资源-数据标准化：https://wepie.feishu.cn/wiki/CQiWwBQdPiciXckDXxwcoZfvnOd
import $store from '@/store'

const REWARD_CATE = {
  prop: 'prop',
  gift: 'gift',
  title: 'title',
  avatar_prop: 'avatar_prop',
  collect_chip: 'collect_chip'
}
/**
 * @module reward
 */
/**
 * @description 获取奖励资源数据
 * @param {object} rewardSlot 奖励插槽数据
 * @param {object} [extra] 额外参数
 *  @param {number} [extra.gender] 性别
 *  @param {object} [extra.rewardMeta] 奖励资源数据（store 中没有这个 id 才需要传）
 * @returns {object} 奖励资源数据
 */
export function getRewardMeta(rewardSlot, { gender, rewardMeta } = {}) {
  return formatRewardMeta(formatRewardSlot(rewardSlot, { gender }), { rewardMeta })
}

/**
 * @description 格式化奖励资源数据
 * @param {object} formattedRewardSlot 处理过的奖励插槽数据
 * @param {object} [extra] 额外参数
 *  @param {object} [extra.rewardMeta] 奖励资源数据（store 中没有这个 id 才需要传）
 * @returns {object} 可直接使用的奖励资源数据
 */
export function formatRewardMeta({ cate, id, animationScale, rename, replaceImg, show_svga, hidden }, { rewardMeta } = {}) {
  const reward = $store.state.reward
  const useRewardMeta = rewardMeta || reward[cate]?.[id]

  // if (!useRewardMeta) {
  //   console.warn(`🐲 gwl ~ reward.js ~ formatRewardMeta ~ cate or id not found: ${cate} - ${id}`)
  // }

  return {
    ...(useRewardMeta || {}),
    // 混入「奖励插槽」中的配置:
    animationScale,
    name: rename || useRewardMeta?.name,
    img: replaceImg || useRewardMeta?.img || getPlaceholderImg({ cate }),
    showSvga: show_svga,
    hidden
  }
}

/**
 * @description 格式化奖励插槽数据
 * @param {object} rewardSlot 奖励插槽数据
 * @param {object} [extra] 额外参数
 *  @param {object} [extra.gender] 性别
 * @returns {object} 可直接使用的奖励插槽
 */
export function formatRewardSlot(rewardSlot, { gender } = {}) {
  const user = $store.state.base.amBasic.user || {}
  const userGender = gender || (rewardSlot.cate === REWARD_CATE.avatar_prop ? user.avatarGender : user.gender)

  const genderSuffix = userGender === 1 ? '_male' : '_female'
  const id = rewardSlot[`id${genderSuffix}`]
  const animationScale = rewardSlot[`animation_scale${genderSuffix}`]
  const rename = rewardSlot[`rename${genderSuffix}`]
  const replaceImg = rewardSlot[`replace_img${genderSuffix}`]
  return {
    ...rewardSlot,
    // formatRewardMeta 需要的字段:
    cate: rewardSlot.cate,
    id,
    animationScale,
    rename,
    replaceImg
  }
}

/**
 * @description 获取对应奖励类型的占位图
 */
export function getPlaceholderImg({ cate } = {}) {
  if (cate === REWARD_CATE.collect_chip) return 'https://fe-center.weplayapp.com/page-center/assets/jv10eqft.png?_w=89&_h=89'
  return ''
}

/**
 * @description 格式化奖励 id，如碎片在前面用下划线拼接 act_id
 */
export function formatRewardId({ reward_type, act_id, reward_id }) {
  return act_id && reward_type === REWARD_CATE.collect_chip ? `${act_id}_${reward_id}` : reward_id
}
