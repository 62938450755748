<template lang="pug">
a-section(v-bind='$attrs', pz='2')
  a-position(v-if='!isA', wh='6.58 1.3', v-for='(item, index) in [mine]', :key='index', pc, pz='2')
    a-section(v-if='isShowLastRank && isInRank', :bg-i='image[item.rise]', pc='y', pl='.12')
    a-section.fx-row.fx-c-center.fx-m-center(v-if='isInRank', :bg-i='image.star', ta, pc='y', pl='.4', :f='`.24 ${textColor.star} 600`') {{ item.rank }}
    a-section(v-else, :bg-i='image.notInRank', pl='.12', pc='y', bg-s='100%')
    a-avatar(wh='.86', v-imgLazy='item.avatar', :data-default='image.empty', :uid='item.uid', pc='y', pl='1.22', :class='{ "white-border": item.uid }', :style='`border: .02rem solid ${borderColor.mine};`')
    a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', pl='2.32', :f='`.22 ${textColor.mineName || textColor.other} 500`') {{ $ts(item.nickname, 6) }}
    a-position.fx-row.fx-c-center(h='.34', pc='y', pl='5.19')
      a-section(:bg-i='image.scoreIconMine || image.scoreIconOther')
      a-section(:f='`.23 ${textColor.mineScore || textColor.score} 500`', h='.34', lh='.35') {{ props.notCompress ? item.value1 : item.value }}{{ props.unit || '' }}
    a-position(v-if='item.ringImg', wh='.8', pc='y', pr='1.55', :bg-i='item.ringImg')

  a-position(v-else, v-for='(item, index) in [mine]', :key='index', v-bind='{ wh: "6.58 1.3", pc: "xy", ...mineFixedStyle }')
    a-section(v-if='isShowLastRank && isInRank', :bg-i='image[item.rise]', pc='y', pr='.12')
    a-section.fx-row.fx-c-center.fx-m-center(v-if='isInRank', :bg-i='image.star', ta, pc='y', pr='.4', :f='`.24 ${textColor.star} 600`') {{ item.rank }}
    a-section(v-else, :bg-i='image.notInRank', pr='.12', pc='y', bg-s='100%')
    a-avatar(wh='.86', v-imgLazy='item.avatar', :data-default='image.empty', :uid='item.uid', pc='y', pr='1.22', :class='{ "white-border": item.uid }', :style='`border: .02rem solid ${borderColor.mine};`')
    a-position.txt-r(wh='1.5 .27', lh='.27', pc='y', pr='2.32', :f='`.22 ${textColor.mineName || textColor.other} 500`') {{ $ts(item.nickname, 8) }}
    a-position.fx-row.fx-c-center(h='.34', pc='y', pr='4.99')
      a-section(:bg-i='image.scoreIconMine || image.scoreIconOther')
      a-section(:f='`.23 ${textColor.mineScore || textColor.score} 500`', h='.34', lh='.35') {{ props.notCompress ? item.value1 : item.value }}
    a-position(v-if='item.ringImg', wh='.8', pc='y', pl='1.55', :bg-i='item.ringImg')
</template>
<script>
import { isA } from '@sub/ui-components/utils/config'
export default {
  props: {
    top3: {
      default: () => [],
      type: Array
    },

    props: {
      default: () => {},
      type: Object
    },

    mine: {
      default: () => {},
      type: Object
    }
  },

  data() {
    return {
      isA
    }
  },

  computed: {
    image({ props }) {
      return props?.image
    },

    isInRank() {
      return this.mine.rank && this.mine.rank <= this.props?.rankConfig?.rankLength
    },

    textColor() {
      return this.props?.textColor
    },

    borderColor() {
      return this.props?.borderColor || {}
    },
    mineFixedStyle() {
      return this.props?.mineFixedStyle || {}
    },

    isShowLastRank({ props }) {
      return props?.isShowLastRank
    }
  }
}
</script>

<style>
.test {
  background-color: rgba(0, 0, 0, 0.4);
}
.white-border {
  border: 0.02rem solid #fff;
}
</style>
