export default [
  {
    path: '/activity/v2/20240320/family-war-rank-c/index.html',
    component: () => import('./rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: 'sC8mO2hX'
    }
  },
  {
    path: '/activity/v2/20240320/family-war-reward-c/index.html',
    component: () => import('./reward.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: 'h7j7FmZe'
    }
  },
  {
    path: '/activity/v2/20240320/family-war-main-c/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1908,
      pageCenter: '2qoT3snT'
    }
  }
]
