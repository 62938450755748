import { createBtnPlugin } from '@sub/utils/eruda-plugins/utils'
import { pageJump2 } from '@/utils/utils'
import { getQuery1 } from '@/utils/utils'
import actInfo from '@sub/store/common/act-info'

const clickCallback = (query = {}) => {
  return () => {
    if (query.apiEncryption) {
      actInfo.setApiEncryption(query.apiEncryption)
    }
    pageJump2(window.location.pathname.replace('/', ''), { ...getQuery1(), ...query })
  }
}

// 一键服务器域名到dev
export const _switch2Dev = (parent) => createBtnPlugin({ parent, btnClassName: 'server-api-dev', btnText: 'server切dev', clickCallback: clickCallback({ debugEnv: 'dev' }) })

// 一键服务器域名到pre
export const _switch2Pre = (parent) => createBtnPlugin({ parent, btnClassName: 'server-api-pre', btnText: 'server切pre', clickCallback: clickCallback({ debugEnv: 'pre' }) })

// 一键服务器域名到prod
export const _switch2Prod = (parent) => createBtnPlugin({ parent, btnClassName: 'server-api-prod', btnText: 'server切prod', clickCallback: clickCallback({ debugEnv: 'prod' }) })

// 一键预发布pageCenter切dev
export const _switchPC2Dev = (parent) => createBtnPlugin({ parent, btnClassName: 'page-center-api-prod', btnText: 'pageCenter切dev', clickCallback: clickCallback({ debugPCEnv: 'dev' }) })

// 一键阻塞 vconsole
export const _switchSyncVConsole = (parent) => createBtnPlugin({ parent, btnClassName: 'page-center-api-prod', btnText: 'pageCenter切dev', clickCallback: clickCallback({ debug: 'true' }) })

// 一键切换加密
export const _switchEncryption = (parent, btnText, encryptionType) => {
  return createBtnPlugin({
    parent,
    btnClassName: 'server-api-encryption',
    btnText,
    clickCallback: clickCallback({ apiEncryption: encryptionType })
  })
}
