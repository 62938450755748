<template lang="pug">
#m-rank.ui-rank-2-members
  a-section.ui-rank-2-members__wrapper(v-if='list.length', v-bind='attrs', w='7.5')
    a-section.repeat.ui-rank-2-members__wrapper__head(:bg-i='image.head', bg-s='100%') 
    a-section.repeat.ui-rank-2-members__wrapper__top1-3(:bg-i='image.body', wh='7.5 7.64', bg-s='100%')
      //- 前一到三
      a-section(v-for='(item, index) in rank1_3', :key='`top${index}`', :bg-i='image[`top${index + 1}Item`]', m='0 auto', mb='.12', wh='6.58 2.4')
        a-avatar(wh='1.1', v-imgLazy='item.user1.avatar || image.defaultAvatar', :uid='item.user1.uid', pb='.77', v-bind='psConfig[1]', :style='`border: .025rem solid ${textStyle[`rank${index + 1}AvatarBgBorderColor`]};`')
        a-avatar(wh='1.1', v-imgLazy='item.user2.avatar || image.defaultAvatar', :uid='item.user2.uid', pb='.77', v-bind='psConfig[2]', :style='`border: .025rem solid ${textStyle[`rank${index + 1}AvatarBgBorderColor`]};`')
        //- 组队名称 默认组队
        a-position.fx-center.ws-nowrap(v-if='!extra.type', wh='2.48 .42', :f='`.24 ${textStyle[`rank${index + 1}TeamInfoColor`]} 600`', pc='x', lh='109%', v-html='tsFun(item.teamName, 6) || extra.teamName || defaultTeamName')
        //- 非组队 切传了爱心图片
        a-position(v-if='image[`heartIcon${index + 1}`]', :bg-i='image[`heartIcon${index + 1}`]', pc='x', pb='.91')
        //- 挂戒指的情况
        a-position(v-if='item.ringImg', wh='.82', :bg-i='item.ringImg', pc='x', pb='0.91')
        //- 积分展示框框
        a-position.fx-center.ws-nowrap(
          fs='.24',
          fw='500',
          :fc='textStyle[`rank${index + 1}ScoreColor`]',
          :style='`border: 0.02rem solid ${textStyle[`rank${index + 1}ScoreBgBorderColor`]};padding: .04rem .2rem`',
          bdr='.25',
          h='.38',
          pc='x',
          pb='.5',
          :bg-c='`${textStyle[`rank${index + 1}ScoreBgColor`]}`'
        )
          a-section(:bg-i='image[`scoreIcon${index + 1}`]', v-bind='psConfig[3]')
          a-section(lh='109%') {{ `${extra.scoreDesc ? extra.scoreDesc : ""}${props.notCompress ? item.value1 : item.value}` }}
        //- 前三名名称
        a-position.fx-center.ws-nowrap(wh='1.44 .25', fw='600', fs='.24', :fc='textStyle[`rank${index + 1}UserInfoColor`]', v-bind='psConfig[4]', , pb='.17') {{ tsFun(item.user1.nickname, 5) }}
        a-position.fx-center.ws-nowrap(wh='1.44 .25', fw='600', fs='.24', :fc='textStyle[`rank${index + 1}UserInfoColor`]', v-bind='psConfig[5]', pb='.17') {{ tsFun(item.user2.nickname, 5) }}
        slot(name='1-3', :data='item')

    a-section.repeat.ui-rank-2-members__wrapper__top4-n(:bg-i='image.body', w='7.5', :h='1.7 * rank4_n.length', bg-s='100%')
      a-section(wh='7.5 1.7', v-for='(item, index) in rank4_n', :key='`common${index}`')
        a-position(:bg-i='image.normalItem', pc, wh='6.58 1.5')
          a-position(:bg-i='image.rankIconOther', pc='y', v-bind='psConfig[6]')
            a-position(fs='.24', fw='600', :fc='textStyle.normalRankColor', pc) {{ index + 4 }}
          a-avatar(wh='.94', v-imgLazy='item.user1.avatar || image.defaultAvatar', :uid='item.user1.uid', v-bind='psConfig[7]', :style='`border: .02rem solid ${textStyle[`normalAvatarBorderColor`]};`')
          a-avatar(wh='.94', v-imgLazy='item.user2.avatar || image.defaultAvatar', :uid='item.user2.uid', v-bind='psConfig[8]', :style='`border: .02rem solid ${textStyle[`normalAvatarBorderColor`]};`')
          a-position.teamPd(v-if='!extra.type', :bg-c='textStyle.normalTeamInfoBgColor', :f='`.22 ${textStyle.normalTeamInfoColor}`', bdr='.19', v-bind='psConfig[9]', w='1.66', pt='.97', v-html='tsFun(item.teamName, 6) || extra.teamName || defaultTeamName')
          a-position(:bg-i='image.heartIcon', v-bind='psConfig[10]')
          a-position(v-if='item.ringImg', wh='.84', :bg-i='item.ringImg', v-bind='psConfig[10]')
          a-position(fs='.22', fw='500', :fc='textStyle.normalUserInfoColor', v-bind='psConfig[11]', pt='.44', lh='123.5%') {{ tsFun(item.user1.nickname, 5) }}
          a-position(fs='.22', fw='500', :fc='textStyle.normalUserInfoColor', v-bind='psConfig[11]', pt='.79', lh='123.5%') {{ tsFun(item.user2.nickname, 5) }}
          a-position.fx-center.ws-nowrap(h='.34', fw='500', fs='.22', :fc='textStyle.normalScoreColor', pc='y', v-bind='psConfig[12]')
            a-section(:bg-i='image.scoreIcon', v-bind='{ [_mrl]: "0.04" }')
            a-section(lh='123.5%') {{ `${props.notCompress ? item.value1 : item.value}` }}
          slot(name='4-n', :data='item')

    a-section.repeat.ui-rank-2-members__wrapper__bottom(:bg-i='image.body', w='7.5', :h='1.5', bg-s='100%')
    a-position.ui-rank-2-members__wrapper__mine(:bg-i='image.mine', pb='0', p='fixed', key='mine', pc='x')
      a-section(wh='7.5 1.5', v-for='(item, index) in [mine]', :key='`mine${index}`')
        a-position(wh='6.58 1.5', pc)
          a-position(v-if='isInRank', :bg-i='image.rankMineOther || image.rankIconOther', pc='y', v-bind='psConfig[6]')
            a-position(fs='.24', fw='600', :fc='textStyle.normalRankColor', pc) {{ item.rank }}
          a-position(v-else, :bg-i='image.notInRank', pc='y', v-bind='psConfig[13]')
          a-avatar(wh='.94', v-imgLazy='item.user1.avatar || image.defaultAvatar', :uid='item.user1.uid', v-bind='psConfig[7]', :style='`border: .02rem solid ${textStyle[`myAvatarBorderColor`]};`')
          a-avatar(v-if='item.hasMember', wh='.94', v-imgLazy='item.user2.avatar || image.defaultAvatar', :uid='item.user2.uid', v-bind='psConfig[8]', :style='`border: .02rem solid ${textStyle[`myAvatarBorderColor`]};`')
          a-avatar(v-else, wh='.94', v-imgLazy='image.emptyInvite', v-bind='psConfig[8]', , :style='`border: .02rem solid ${textStyle[`myAvatarBorderColor`]};`', @a-tap='$emit("invite")')
          a-position.teamPd(v-if='!extra.type', :bg-c='textStyle.myTeamInfoBgColor', :f='`.22 ${textStyle.myTeamInfoColor}`', bdr='.19', v-bind='psConfig[9]', w='1.66', pt='.97', v-html='tsFun(item.teamName, 6) || extra.teamName || defaultTeamName')
          a-position(:bg-i='image.heartIconMy', v-bind='psConfig[10]')
          a-position(v-if='item.ringImg', wh='.84', :bg-i='item.ringImg', v-bind='psConfig[10]')
          a-position(fs='.22', fw='500', :fc='textStyle.myUserInfoColor', v-bind='psConfig[11]', , pt='.44', lh='123.5%') {{ tsFun(item.user1.nickname, 5) }}
          a-position(fs='.22', fw='500', :fc='textStyle.myTeamUserInfoColor || textStyle.myUserInfoColor', v-bind='psConfig[11]', , pt='.79', lh='123.5%') {{ tsFun(item.user2.nickname, 5) }}

          //- a-button1(v-if='!item.hasMember', :bg-i='image.inviteFriends', @a-tap='$emit("invite")', pc='y', pr='-.08')
          a-position.fx-center.ws-nowrap(h='.34', fw='500', fs='.22', :fc='textStyle.myScoreColor', pc='y', v-bind='psConfig[12]')
            a-section(:bg-i='image.scoreIcon', v-bind='{ [_mrl]: "0.04" }')
            a-section(lh='123.5%') {{ `${props.notCompress ? item.value1 : item.value}` }}
          slot(name='mine', :data='item')
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import get from 'lodash/get'
import { formatItem, defaultRankConfig, defaultImage, defaultTextStyle, defaultTeamName } from './utils/config'
import { isA } from '../util'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isA,
      defaultTeamName
    }
  },

  computed: {
    psConfig() {
      const result = {
        1: { [this._plr]: this.props?.topDistance }, //前三名头像框距离边框的距离
        2: { [this._prl]: this.props?.topDistance },
        3: { [this._mrl]: '.04' }, //积分icon与积分之间的距离
        4: { [this._plr]: this.props?.topNameDistance }, //前三名名称距离边框的距离
        5: { [this._prl]: this.props?.topNameDistance }, //前三名名称距离边框的距离

        6: { [this._plr]: '.24' }, //iconOther 排名图标的定位(一般是星星)
        7: { [this._plr]: '1.1' }, // 4-10名第一人头像框的距离
        8: { [this._plr]: '2.36' }, // 4-10名第二人头像框的距离
        9: { [this._plr]: '1.37' }, //组队底板距离左边的距离
        10: { [this._plr]: '1.78' }, // 4-10爱心或者戒指距离
        11: { [this._plr]: '3.46' }, // 4-10名称距离左边的距离
        12: { [this._plr]: '5.19' }, //4-10和自己积分的距离
        13: { [this._plr]: '0.04' } //未上榜的距离
      }
      if (this.props && this.props.avatarTopDistance) {
        result[7].pt = this.props.avatarTopDistance
        result[8].pt = this.props.avatarTopDistance
      } else {
        result[7].pc = 'y'
        result[8].pc = 'y'
      }
      if (this.props && this.props.heartTopDistance) {
        result[10].pt = this.props.heartTopDistance
      } else {
        result[10].pc = 'y'
      }
      return result
    },

    amApiParam() {
      return this.$base.amApiParam()
    },

    amBasic() {
      return this.$base.amBasic()
    },

    props() {
      return this.uiMeta?.props
    },

    act_id({ props }) {
      return props?.act_id || 0
    },
    //页面使用多个不同类型的ui榜单组件时必传（值不可相同），同类型时可以忽略
    rankKey({ props }) {
      return props?.rankKey || 'total'
    },

    image({ props }) {
      return { ...defaultImage, ...props?.image }
    },

    textStyle({ props }) {
      return { ...defaultTextStyle, ...props?.textStyle }
    },

    rankConfig({ props }) {
      return { ...defaultRankConfig, ...props?.rankConfig }
    },

    extra({ props }) {
      return get(props, 'extra', {})
    },

    list() {
      if (this.$rank.rankUse()) {
        return this.$rank.rankUse()[this.rankKey]?.list || []
      }
      return []
    },

    // 对于state和getters可以像下面这样以computed接收，也可以直接用
    rank1_3() {
      if (this.$rank.rankUse()) {
        return this.$rank.rankUse()[this.rankKey]?.list1_3 || []
      }
      return []
    },
    rank4_n() {
      if (this.$rank.rankUse()) {
        return this.$rank.rankUse()[this.rankKey]?.list.slice(3) || []
      }
      return []
    },
    mine() {
      if (this.$rank.rankUse()) {
        return this.$rank.rankUse()[this.rankKey]?.mine || {}
      }
      return {}
    },

    isInRank({ props, mine }) {
      return mine.rank && mine.rank <= props?.rankConfig?.rankLength
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    tsFun(txt, num) {
      return this._isA ? this.$tsAr(txt, num) : this.$ts(txt, num)
    },
    async init() {
      // 相当于之前给mixins中传参那步
      this.$rank.initDefault({
        format: {
          list: formatItem,
          mine: formatItem
        },
        namespace: this.rankKey
      })
      // 加载榜单信息
      this.loadRank()
    },

    loadRank() {
      const { rankLength, rankType, periodType, extra = {} } = this.rankConfig
      // 相当于之前调用mixins中的方法
      let params = { limit: rankLength, rank_type: rankType, period_type: periodType, ...extra }
      this.act_id && (params.act_id = this.act_id)
      this.$rank.getRank({
        cache: false,
        params,
        name: this.rankKey
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.repeat {
  background-repeat: repeat-y;
}

.teamPd {
  padding: 0.04rem 0.12rem;
}
</style>
