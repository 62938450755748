export default [
  {
    path: '/activity-20221130-main-ar',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      fullScreen: true,
      // expire: '2022/12/14 23:59:59 UTC+5',
      quickConfig: 'ba8788de3d0c162af3555c71d7b7b033'
    }
  },
  {
    path: '/activity-20221130-detail-ar',
    component: () => import('./pages/main/detail.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      fullScreen: true,
      // expire: '2022/12/14 23:59:59 UTC+5',
      quickConfig: 'ba8788de3d0c162af3555c71d7b7b033'
    }
  }
]
