<template lang="pug">
a-section.fx-row.fx-c-center.fx-m-between.ui-slider(v-bind='$attrs')
  a-button1.ui-slider__min(v-if='showOption', :bg-i='assets[image.minus]', :throttle='1000', @a-tap='minus()', :class='{ grey: input <= min }' bg-s='100%')
  a-section.ui-slider__line-wrapper(v-bind='{ ...getImgParam("line"), ...style.line }')
    a-section.ui-slider__line-wrapper__line(:bg-i='assets[image.line]', bg-s='contain', pc, v-bind='{ ...getImgParam("line"), ...style.line }')
    a-section.ui-silder-line-active.ui-slider__line-wrapper__line-active(:bg-i='assets[image.active]', pc='y', v-bind='{ ...style.active }', :w='activeW', :class='{ "ui-silder-line-active-ar": isA }')
    a-section.ui-slider__line-wrapper__slider-wrapper(pb='-0.04', pl='0.1')
      VueSlider.slider.ui-slider__line-wrapper__slider-wrapper__slider(
        ref='slider',
        v-model='input',
        :width='`${activeBgStyle.w}rem`',
        :height='`${activeBgStyle.h}rem`',
        :min='min',
        :max='max',
        tooltip='none',
        @drag-end='dragEnd()',
        @dragging='isDragging = true',
        :direction='isA ? "rtl" : "ltr"',
        :processStyle='{ background: "transparent" }',
        :railStyle='{ background: "transparent" }'
      )
        template(v-slot:dot='{ value, focus }')
          a-section.ui-slider__line-wrapper__slider-wrapper__slider__pointer(:bg-i='assets[image.pointer]', bg-s='contain', v-bind='{ ...defaultStyle.pointer, ...style.pointer }')
  a-button1.ui-slider__add(v-if='showOption', :bg-i='assets[image.add]', :throttle='1000', @a-tap='add()', :class='{ grey: input >= max }', bg-s='contain')
  a-button1.ui-slider__max(v-if='showOption && image.max', :bg-i='assets[image.max]', :throttle='1000', @a-tap='setMax()', :class='{ grey: input >= max }', bg-s='contain')
</template>

<script>
import VueSlider from 'vue-slider-component'
import { defaultStyle, convertPxToRem } from './utils/config'
import get from 'lodash/get'
import { progressCalculate1 } from '@/utils/utils'
import { isA } from '../utils/config'

export default {
  components: { VueSlider },
  props: {
    max: {
      type: Number,
      default: 0
    },

    min: {
      type: Number,
      default: 0
    },

    resetVal: {
      type: Number,
      default: 0
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    activeW({ input, max, min }) {
      const processW = progressCalculate1({
        stages: [min, max],
        current: input,
        percent: [0, 1]
      })
      return (this.getImgParam('active').w || this.style.active.w) * processW
    },

    activeBgStyle() {
      const lineP = this.getImgParam('line')
      if (!this.getImgParam('line')) {
        return this.style.active
      }
      const pointerP = this.getImgParam('pointer')
      const activeP = this.getImgParam('active')
      return {
        h: activeP.h,
        w: lineP.w - pointerP.w / 2
      }
    },

    assets() {
      return this.$route.meta.assets
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    showOption({ uiMeta }) {
      return get(uiMeta, 'props.showOption', {})
    },

    style({ uiMeta }) {
      return get(uiMeta, 'props.style', {})
    }
  },

  watch: {
    input() {
      if (!this.isDragging) {
        this.dragEnd()
      }
    }
  },

  data() {
    return {
      defaultStyle,
      input: 0,
      isDragging: false,
      isA
    }
  },

  mounted() {},

  created() {
    this.reset(this.resetVal)
  },

  methods: {
    minus() {
      this.input = Math.max(this.min, this.input - 1)
      this.$emit('change', this.input)
    },

    add() {
      this.input = Math.min(this.max, this.input + 1)
      this.$emit('change', this.input)
    },
    setMax() {
      this.input = this.max
      this.$emit('change', this.input)
    },

    async dragEnd() {
      this.isDragging = false
      this.$emit('change', this.input)
    },

    reset(val) {
      this.input = val >= 1 ? val : 0
    },

    getImgParam(key) {
      let query = ''
      try {
        query = this.assets[this.image[key]].split('?')[1]
      } catch (error) {
        console.error(`图片 ${this.image[key]} 链接错误，请检查page-center`)
        return
      }
      if (!query) {
        return
      }
      const usp = new URLSearchParams(query)
      if (usp.get('_h') && usp.get('_w')) {
        return {
          h: convertPxToRem(usp.get('_h')),
          w: convertPxToRem(+usp.get('_w'))
        }
      }
    }
  }
}
</script>
<style lang="scss">
.ui-silder-line-active {
  background-position: left center !important;
}
.ui-silder-line-active-ar {
  background-position: right center !important;
}
</style>
