import get from 'lodash/get'
import { handleNumKW, region, regionMap } from '../../util'
import { genComponentCurRegionText } from '@sub/utils/page-center-snapshot'

export const formatItem = ({ type, item, index }) => {
  const rank = type === 'list' ? index + 1 : get(item, 'rank', 0)
  const lastRank = get(item, 'last_rank', 0)
  const value = handleNumKW(get(item, 'score', 0))
  const userInfos = get(item, 'user_infos', [{}]) || [{}]
  const extraInfo = get(item, 'member_extra_info', {})

  const text = genComponentCurRegionText('rank-single-top3', { region })
  const defaultNickname = text?.defaultNickname || regionMap[region]

  let rise = 'flat'
  if (rank < lastRank || lastRank === 0) {
    rise = 'up'
  } else if (rank > lastRank) {
    rise = 'down'
  }

  return {
    rank,
    value,
    rise,
    value1: get(item, 'score', 0),
    avatar: get(userInfos[0], 'head_img_url', ''),
    nickname: get(userInfos[0], 'nickname', defaultNickname) || defaultNickname,
    uid: get(userInfos[0], 'uid', ''),
    ringImg: get(extraInfo, 'ring_img', ''),
    avatarImg: get(userInfos[0], 'avatar_image', ''),
    singleAvatarImg: get(userInfos[0], 'single_avatar_image', '')
  }
}
