<template lang="pug">
a-section(v-bind='$attrs', :class='genBEMClass({ b: "ui-open-box-get-ring" })')
  a-section(wh='100%', :class='genBEMClass({ b: "ui-open-box-get-ring-main" })')
    a-section(:class='genBEMClass({ e: "chipNum" })', v-bind='{ ...defaultStyle.chipNumStyle, ...customizeStyle.chipNumStyle }', :fc='text.style.chipNumColor || "#fff"') {{ chipNum }}
    a-section(:class='genBEMClass({ e: "record" })', :bg-i='image.main.openRecordIcon', v-bind='{ ...defaultStyle.openRecordStyle, ...customizeStyle.openRecordStyle }', @a-tap='$record.openRecord()[openRecordKey]()')

    //- 宝箱动画
    a-position(:class='genBEMClass({ e: "bottom-svg" })', v-bind='{ ...defaultStyle.bottomSVGStyle, ...customizeStyle.bottomSVGStyle }', :bg-i='bottomSVG')
    a-section(:class='genBEMClass({ e: "box" })', :bg-i='image.main.box', v-bind='{ ...defaultStyle.boxStyle, ...customizeStyle.boxStyle }')
      ui-pag(v-if='isOpenEffectPAG', name='openAnim', :class='genBEMClass({ e: "open-pag" })', v-bind='{ ...defaultStyle.openPagStyle, ...customizeStyle.openPagStyle }', :autoPlay='false', :urls='[openPag]')

    //- 戒指奖励
    a-section(v-for='(ring, index) in ringIDsGroup', :key='index', :class='genBEMClass({ e: "ring", m: `${index + 1}` })', v-bind='{ ...defaultStyle.ringGroupConfigStyle[index], ...customizeStyle.ringGroupConfigStyle[index] }')
      ui-prop-wrapper.of-h(:id='ring.id', wh='100%', pc)
      a-section.pointer(v-if='ring.tag', :bg-i='ring.tag', :class='genBEMClass({ e: "tag" })', v-bind='{ ...defaultStyle.ringTagStyle, ...customizeStyle.ringTagStyle }')
      a-section.pointer(v-if='curRingNum(ring.id)', :bg-i='image.main.hadGetIcon', :class='genBEMClass({ e: "had-get-icon" })', v-bind='{ ...defaultStyle.getImgStyle, ...customizeStyle.getImgStyle }')

    // 按钮
    ui-button(v-show='isShowOpenBtn', :bg-i='image.main.openBoxBtn', :class='genBEMClass({ e: "open-box-btn" })', v-bind='{ ...defaultStyle.openBtnStyle, ...customizeStyle.openBtnStyle }', @a-tap='openBox')
    ui-button(v-show='isShowResetBtn', :bg-i='image.main.resetBtn', :class='genBEMClass({ e: "reset-btn" })', v-bind='{ ...defaultStyle.resetBtnStyle, ...customizeStyle.resetBtnStyle }', @a-tap='reset')
    a-section(v-show='isShowResetBtn', :bg-i='image.main.resetText', :class='genBEMClass({ e: "reset-text" })', v-bind='{ ...defaultStyle.resetTextStyle, ...customizeStyle.resetTextStyle }')

  // 开启宝箱二次确认
  a-dialog(name='openConfirm', :bg-i='image.dialog.openConfirmBg', :class='genBEMClass({ b: "open-confirm" })')
    ui-button(slot='cancel', :class='genBEMClass({ e: "cancel" })', :bg-i='image.dialog.cancelBtn', pl='.69', pb='.74', @a-tap='$dialogs.openConfirm.close()')
    ui-button(slot='confirm', :class='genBEMClass({ e: "confirm" })', :bg-i='image.dialog.confirmBtn', pr='.69', pb='.74', @a-tap='$dialogs.openConfirm.close()')

  // 重置二次确认
  a-dialog(name='resetConfirm', :bg-i='image.dialog.resetConfirmBg', :class='genBEMClass({ b: "reset-confirm" })')
    ui-button(slot='cancel', :class='genBEMClass({ e: "cancel" })', :bg-i='image.dialog.cancelBtn', pl='.69', pb='.74', @a-tap='$dialogs.resetConfirm.close()')
    ui-button(slot='confirm', :class='genBEMClass({ e: "confirm" })', :bg-i='image.dialog.confirmBtn', pr='.69', pb='.74', @a-tap='$dialogs.resetConfirm.close()')
</template>

<script>
import get from 'lodash/get'
import { buildApi, concatCatch } from '@sub/utils/request'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { defaultStyle, bottomDefaultSVG, openDefaultPag } from './utils/config'
import { IS_WEPLAY } from '@/utils/env'
import genBEMClassMixin from '@sub/ui-components/utils/genBEMClassMixin'

const getLotteryInfo = buildApi('/activity_v2/lottery/get_lottery_info')
const resetRound = buildApi('/activity_v2/lottery/reset_round')
const doLottery = buildApi('/activity_v2/lottery/do_lottery')
export default {
  mixins: [attrs, genBEMClassMixin],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      defaultStyle,
      ringRewards: []
    }
  },

  computed: {
    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    animation({ uiMeta }) {
      return get(uiMeta, 'props.animation', {})
    },

    bottomSVG({ animation }) {
      return animation.bottomSVG || bottomDefaultSVG[IS_WEPLAY ? 'weplay' : 'HW']
    },

    openPag({ animation }) {
      return animation.openPag || openDefaultPag[IS_WEPLAY ? 'weplay' : 'HW']
    },

    openBoxAudio({ uiMeta }) {
      return get(uiMeta, 'openBoxAudio', '')
    },

    text({ uiMeta }) {
      return get(uiMeta, 'props.text', {})
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {})
    },

    openRecordKey({ uiMeta }) {
      return get(uiMeta, 'openRecordKey', '')
    },

    chipId({ uiMeta }) {
      return get(uiMeta, 'props.chipId', 1)
    },

    chipNum({ chipId }) {
      return this.$base.chipInfo()[chipId] || 0
    },

    lotteryType({ uiMeta }) {
      return get(uiMeta, 'props.lotteryType', 1)
    },

    oneOpenNeedChipNum({ uiMeta }) {
      return get(uiMeta, 'props.oneOpenNeedChipNum', 10000)
    },

    ringIDsGroup({ uiMeta }) {
      return get(uiMeta, 'props.ringIDsGroup', [])
    },

    isShowResetBtn({ ringRewards }) {
      return ringRewards.findIndex((item) => item?.round_recv_num) > -1
    },

    isShowOpenBtn({ ringRewards }) {
      return !ringRewards.every((item) => item?.round_recv_num)
    },

    isOpenEffectPAG() {
      return this.$base.isOpenEffectPAG()
    }
  },

  onPageShow() {
    this.getLotteryInfo()
  },

  mounted() {
    this.getLotteryInfo()
  },

  methods: {
    async getLotteryInfo() {
      const { rewards } = await getLotteryInfo({ ...this.$amApiParam(), type: this.lotteryType })
      this.ringRewards = rewards
    },

    curRingNum(id) {
      return this.ringRewards.find((item) => item.reward_id === id)?.round_recv_num
    },

    async openBox() {
      if (this.chipNum < this.oneOpenNeedChipNum) {
        this.$toast(this.$tf(this.text.words.chipPoor))
        this.$emit('scroll-lottery')
        return
      }

      const confirm = await this.$dialogs.openConfirm.confirm()
      if (!confirm) return

      if (this.isOpenEffectPAG) {
        this.openBoxAudio && this.audioPlay(this.openBoxAudio)
        await this.$PAG?.openAnim?.playPAG({ key: this.openPag, repeatCount: 1, endFrame: 0 })
      }

      const [err, res] = await concatCatch(
        doLottery({
          type: this.lotteryType,
          number: 1,
          auto_purchase: true
        })
      )

      if (err) {
        console.log('lottery Error: ', err)
        return
      }

      const ringId = res[0].reward_id
      const ringDialogName = this.ringIDsGroup.find((item) => item.id === ringId).ringDialogName
      this.$dialogs[ringDialogName].open({ data: { ringId } })
      this.$base.getCoinChip()
      this.getLotteryInfo()
    },

    async reset() {
      const res = await this.$dialogs.resetConfirm.confirm()
      if (!res) return

      await resetRound({ ...this.$amApiParam(), type: this.lotteryType })
      this.$base.getCoinChip()
      this.getLotteryInfo()
    },

    audioPlay(ref) {
      this.$storage.audioControl().audioPlay(ref)
    },

    audioPause(ref) {
      this.$storage.audioControl().audioPause(ref)
    }
  }
}
</script>

<style lang="scss" scoped></style>
