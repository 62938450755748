<template lang="pug">
component(v-bind='$attrs', wh='0.68', bg-s='contain', @a-tap='openViewWorkDialog()', :is='isCanPlay ? "a-button1" : "a-position"', :class='{ grey: !isCanPlay }')
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    isCanPlay() {
      return this.item.id
    }
  },

  methods: {
    openViewWorkDialog() {
      if (!this.isCanPlay) {
        return
      }
      this.$dialogs.uiVoteViewWorkDialog.open({ data: this.item, options: { bgClose: true } })
    }
  }
}
</script>

<style></style>
