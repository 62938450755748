<template lang="pug">
a-section.ui-lottery-reward-dialog(v-bind='attrs')
  //- 非阿语服
  a-dialog.ui-lottery-reward-dialog__not-isA(v-if='!isA && !isQ', :name='dialogName', :cache='extra.cache', wh='6.54 8.96')
    template(slot-scope='scope', v-if='scope.data')
      //- 定义临时变量
      span(:data-var='(len = scope.data.rewardList.length)')
      span(:data-var='(styleConfig = rewardsStyleConfig[len < 5 ? len : 5])')
      span(:data-var='(wrapStyle = styleConfig.wrap(getRewardsWrapPt(len, rewardWrapStyle)))')
      //- 小于5
      a-position.ui-lottery-reward-dialog__not-isA__dialog-bg(:class='genBEMClass({ e: "bg" })', :bg-i='len < 5 ? image.dialogBg1 : image.dialogBg2', pc, bg-s='100%')
        slot(:data='scope.data')
        a-position.ui-lottery-reward-dialog__not-isA__dialog-bg__reward-list-wrapper(v-bind='getWrapStyle(wrapStyle)', :class='[...getClass(len), genBEMClass({ e: "scroll-wrapper" }), genBEMClass({ e: "scroll-wrapper", m: len < 5 ? len : 5 })]')
          a-section.ui-lottery-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item(v-for='(r, i) in scope.data.rewardList', :key='i', v-bind='{ ...(len < 5 ? {} : calculateMargin(i)), ...styleConfig.reward }', :bg-i='image.rewardBg', bg-s='contain', :class='genBEMClass({ e: "item" })')
            a-position.ui-lottery-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__isSp(v-if='r.isSp', wh='100%', pc, :class='genBEMClass({ e: "sp-item" })')
              slot(name='light')
              a-position.bright.ui-lottery-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__isSp__bright(wh='100%', :bg-i='lightImg')
            slot(v-if='$scopedSlots["prop"]', name='prop', v-bind='{ data: r }')
            ui-prop.ui-lottery-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__prop(v-else, wh='100%', pc, :data='r', :extra='{ canPreview: false }', :class='genBEMClass({ e: "prop-item" })')
            slot(name='tag', v-bind='{ data: r }')
            a-border-text1.ui-lottery-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__unit(v-bind='{ ...commonSubscriptStyle, fc: subscriptStyle.color }', borderWidth='3', :borderColor='subscriptStyle.borderColor', :borderContent='r.unit', :class='genBEMClass({ e: "reward-unit" })')
            a-position.txt-c.ui-lottery-reward-dialog__not-isA__dialog-bg__reward-list-wrapper__item__reward-name(v-bind='styleConfig.rewardNameStyle', :fc='rewardNameStyle.color', pc='x', :style='styleConfig.rewardNameStyle', :class='genBEMClass({ e: "prop-reward-name" })') {{ r.reward_name?.replace('（', '(')?.replace('）', ')') }}
        ui-button.ui-lottery-reward-dialog__not-isA__dialog-bg__cancel-button(pl='.54', pb='.74', :bg-i='image.confirmButton', @a-tap='cancelCallback(scope.data.lotteryNum)', :class='genBEMClass({ e: "btn-confirm" })')
        slot(v-if='$scopedSlots["lotteryAgain"]', name='lotteryAgain', v-bind='{ num: scope.data.lotteryNum }')
        ui-button.ui-lottery-reward-dialog__not-isA__dialog-bg__confirm-button(v-else, pr='.54', pb='.74', :bg-i='image[`lotteryAgain${scope.data.lotteryNum}`]', @a-tap='lotteryAgain(scope.data.lotteryNum)', :class='genBEMClass({ e: "btn-lottery-again" })')

  //- 阿语服
  a-dialog.ui-lottery-reward-dialog__isA(v-if='isA || isQ', :name='dialogName', :cache='extra.cache', wh='6.54 8.86')
    template(slot-scope='scope', v-if='scope.data')
      //- 定义临时变量
      span(:data-var='(len = scope.data.rewardList.length)')
      span(:data-var='(styleConfig = rewardsStyleConfigA[len <= 6 ? len : 7])')
      span(:data-var='(wrapStyle = styleConfig.wrap(getRewardsWrapPt(len, rewardWrapStyle)))')
      //- 小于5
      a-position.ui-lottery-reward-dialog__isA__dialog-bg(:bg-i='len < 4 ? image.dialogBg1 : image.dialogBg2', pc, v-bind='styleConfig.dialog')
        slot(:data='scope.data')
        a-position.ui-lottery-reward-dialog__isA__dialog-bg__reward-list-wrapper(v-bind='getWrapStyle(wrapStyle)', :class='[...getClass(len), genBEMClass({ e: "scroll-wrapper" }), genBEMClass({ e: "scroll-wrapper", m: len < 7 ? len : 7 })]')
          a-section.ui-lottery-reward-dialog__isA__dialog-bg__reward-list-wrapper__item(v-for='(r, i) in scope.data.rewardList', :key='i', v-bind='{ ...(len < 5 ? {} : calculateMargin(i)), ...styleConfig.reward }', :bg-i='image.rewardBg', bg-s='contain', :class='genBEMClass({ e: "item" })')
            a-position.ui-lottery-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__isSp(v-if='r.isSp', wh='100%', pc, :class='genBEMClass({ e: "sp-item" })')
              slot(name='light')
              a-position.bright.ui-lottery-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__isSp__bright(wh='100%', :bg-i='lightImg')
            slot(v-if='$scopedSlots["prop"]', name='prop', v-bind='{ data: r }')
            ui-prop.ui-lottery-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__prop(v-else, wh='100%', pc, :data='r', :extra='{ canPreview: false }', :class='genBEMClass({ e: "prop-item" })')
            slot(name='tag', v-bind='{ data: r }')
            a-border-text1.ui-lottery-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__unit(v-bind='{ ...commonSubscriptStyle, fc: subscriptStyle.color }', borderWidth='3', :borderColor='subscriptStyle.borderColor', :borderContent='r.unit', :class='genBEMClass({ e: "reward-unit" })')
            a-position.txt-c.ui-lottery-reward-dialog__isA__dialog-bg__reward-list-wrapper__item__reward-name(v-bind='styleConfig.rewardNameStyle', :fc='rewardNameStyle.color', pc='x', :class='genBEMClass({ e: "prop-reward-name" })') {{ r.reward_name }}
        ui-button.ui-lottery-reward-dialog__isA__dialog-bg__cancel-button(v-bind='{ pl: 0.54, pb: len > 4 ? 0.54 : 0.74, ...getCustomizeStyle("confirmButton") }', :bg-i='image.confirmButton', @a-tap='cancelCallback(scope.data.lotteryNum)', :class='genBEMClass({ e: "btn-confirm" })')
        slot(v-if='$scopedSlots["lotteryAgain"]', name='lotteryAgain', v-bind='{ num: scope.data.lotteryNum }')
        ui-button.ui-lottery-reward-dialog__isA__dialog-bg__confirm-button(v-else, v-bind='{ pr: 0.54, pb: len > 4 ? 0.54 : 0.74, ...getCustomizeStyle("lotteryAgainButton") }', :bg-i='image[`lotteryAgain${scope.data.lotteryNum}`]', @a-tap='lotteryAgain(scope.data.lotteryNum)')
</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import event from '@sub/event'
import genBEMClassMixin from '@sub/ui-components/utils/genBEMClassMixin'
import { rewardsStyleConfig, rewardsStyleConfigA, lightConfig, calculateMargin, commonSubscriptStyle, getRewardsWrapPt, getWrapStyle, getClass } from './utils/config'
import { isA, isQ } from '../utils/config'

export default {
  mixins: [attrs, genBEMClassMixin],

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      rewardsStyleConfig,
      rewardsStyleConfigA,
      commonSubscriptStyle,
      isA,
      isQ
    }
  },

  computed: {
    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    rewardNameStyle({ uiMeta }) {
      return get(uiMeta, 'props.rewardNameStyle', {})
    },

    subscriptStyle({ uiMeta }) {
      return get(uiMeta, 'props.subscriptStyle', {})
    },

    lightImg({ $route: { meta } }) {
      return meta.isInternational ? lightConfig.wp : lightConfig.hw
    },

    dialogName({ uiMeta: { key } }) {
      return key
    },

    extra({ uiMeta }) {
      return get(uiMeta, 'props.extra', { cache: false })
    },

    rewardWrapStyle({ uiMeta }) {
      return get(uiMeta, 'props.rewardWrapStyle', {})
    },

    newStandard({ uiMeta }) {
      return get(uiMeta, 'props.newStandard', false)
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {})
    }
  },

  methods: {
    getClass,
    getWrapStyle,
    calculateMargin,
    getRewardsWrapPt,

    lotteryAgain(num) {
      event.emitOnLotteryAgain(num)
      this.$emit('lotteryAgain', num)
    },

    cancelCallback(num) {
      this.$dialogs[this.dialogName].close()
      this.$emit('cancelCallback', num)
    },
    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.bright {
  animation: bright 3s linear infinite;
  transform: translate3d(0, 0, 0);

  @keyframes bright {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}
</style>
