export default function fontColor({ type, textStyle, index }) {
  if (type === 'rank') {
    return textStyle.rankColor
  }

  if (type === 'score') {
    if (index < 4) {
      return textStyle[`rank${index}ScoreColor`]
    }

    return textStyle.normalScoreColor
  }

  if (index < 4) {
    return textStyle[`rank${index}UserInfoColor`]
  }

  return textStyle.normalUserInfoColor
}
