<template lang="pug">
a-section
  a-dialog(:bg-i='props.guideBg' :name='guideDialogName')
    a-position.of-y-a(v-bind='finalGuideContentStyle')
      a-section(:bg-i='props.guideContent')
    a-position(v-bind='finalStartBtnStyle')
      ui-button(:bg-i='props.startBtn' @a-tap='onStart')
  a-dialog(:bg-i='props.mainBg' :name='mainDialogName')
    //- 题目区
    a-position.fx-h-center.of-y-a(v-bind='finalQuestionAreaStyle') {{ curQuestion?.desc }}
    //- 选项区
    a-position.fx-col.flex-gap.of-y-a(v-bind='finalOptionsAreaStyle')
      a-section.fx-v-center(v-for='option, index in (curQuestion?.option_list ?? [])' @a-tap='selectOption(index + 1)' :key='index')
        a-section.fx-shrink-0(v-if='index + 1 === curSelected' :bg-i='props.selectedIcon')
        a-section.fx-shrink-0(v-else :bg-i='props.unselectedIcon')
        a-section(v-bind='finalOptionStyle') {{ option }}
    //- 按钮区
    a-position(w='5.1' pb='0.7' pc='x')
      //- 第一题时
      a-section.fx-row.fx-m-center(v-show='questionIndex === 0' wh='100% 100%')
        a-section(v-show='curSelected' :bg-i='props.nextLeadingBtn' @a-tap='questionIndex++')
        a-section(v-show='!curSelected' :bg-i='props.nextDisabledLeadingBtn')
      //- 非第一题
      a-section.fx-row.fx-m-between(v-show='questionIndex !== 0' wh='100% 100%')
        a-section(:bg-i='props.previousBtn' @a-tap='questionIndex--')
        template(v-if='isLastQuestion')
          a-section(v-show='curSelected' :bg-i='props.submitBtn' @a-tap='submit()')
          a-section(v-show='!curSelected' :bg-i='props.submitDisabledBtn')
        template(v-else)
          a-section(v-show='curSelected' :bg-i='props.nextBtn' @a-tap='questionIndex++')
          a-section(v-show='!curSelected' :bg-i='props.nextDisabledBtn')
    //- 计数区
    a-position(v-bind='finalCountingAreaStyle') {{ `${questionIndex + 1}/${questions.length}` }}
</template>

<script>
import { buildApi } from '@sub/utils/request'
import get from 'lodash/get'

const fetchTopicList = buildApi('/activity_v2/dbf_topic/get_exam')
const submitAnswer = buildApi('/activity_v2/dbf_topic/submit')

export default {
  name: 'ui-quiz',
  props: {
    uiMeta: {
      type: Object
    },
    name: String,
    guideBg: String,
    guideContent: String,
    mainBg: String,
    startBtn: String,
    previousBtn: String,
    nextBtn: String,
    nextDisabledBtn: String,
    nextLeadingBtn: String,
    nextDisabledLeadingBtn: String,
    submitBtn: String,
    submitDisabledBtn: String,
    selectedIcon: String,
    unselectedIcon: String,
    questionAreaStyle: Object,
    optionsAreaStyle: Object,
    optionStyle: Object,
    countingAreaStyle: Object,
    guideContentStyle: Object,
    startBtnStyle: Object
  },
  created() {
    fetchTopicList().then((res) => {
      this.examId = res.exam_id
      this.questions = res.question_list ?? []
    })
  },
  data() {
    return {
      examId: undefined,
      questions: [],
      questionIndex: 0,
      selected: []
    }
  },
  computed: {
    props() {
      if (this.uiMeta) {
        return get(this.uiMeta, 'props', {})
      }
      return this.$props
    },
    finalQuestionAreaStyle() {
      return {
        wh: '5.28 1.32',
        pt: '1.58',
        pc: 'x',
        f: '0.24 #000 600',
        pd: '0.33',
        ...(this.props.questionAreaStyle ?? {})
      }
    },
    finalOptionsAreaStyle() {
      return {
        wh: '5.28 4.4',
        pt: '2.94',
        pc: 'x',
        pdl: '0.3',
        pdr: '0.3',
        pdt: '0.44',
        ...(this.props.optionsAreaStyle ?? {})
      }
    },
    finalOptionStyle() {
      return {
        f: '0.24 #000 400',
        pdl: '0.06',
        ...(this.optionStyle ?? {})
      }
    },
    finalCountingAreaStyle() {
      return {
        pb: '0.34',
        pc: 'x',
        f: '0.24 #000 600',
        ...(this.props.countingAreaStyle ?? {})
      }
    },
    finalGuideContentStyle() {
      return {
        wh: '5 4.15',
        pc: 'x y',
        ...(this.props.guideContentStyle ?? {})
      }
    },
    finalStartBtnStyle() {
      return {
        pb: '0.41',
        pc: 'x',
        ...(this.props.startBtnStyle ?? {})
      }
    },
    guideDialogName() {
      return `${this.props.name}-guide`
    },
    mainDialogName() {
      return `${this.props.name}-main`
    },
    curQuestion() {
      return this.questions[this.questionIndex]
    },
    curSelected() {
      return this.selected[this.questionIndex]
    },
    isLastQuestion() {
      return this.questionIndex === this.questions.length - 1
    }
  },
  methods: {
    open() {
      this.questionIndex = 0
      this.selected = []
      this.$dialogs[this.guideDialogName].open({ options: { bgClose: true } })
    },
    onStart() {
      this.$dialogs[this.guideDialogName].close()
      this.$dialogs[this.mainDialogName].open({ options: { bgClose: true } })
    },
    // start from 1
    selectOption(index) {
      this.selected = [...this.selected]
      this.selected[this.questionIndex] = index
    },
    async submit() {
      const res = await submitAnswer({
        exam_id: this.examId,
        answer: this.selected.join(',')
      })
      this.$emit('on-quiz-submit', {
        score: res,
        questions: [...this.questions],
        selected: [...this.selected]
      })
      this.$dialogs[this.mainDialogName].close()
    }
  }
}
</script>
<style lang="scss" scoped>
.flex-gap {
  display: flex;
  gap: 0.38rem;
}
</style>
