<template lang="pug">
a-section(v-bind='$attrs')
  //- 1～3
  a-section(:bg-i='useImages.topThreeBg', v-bind='defaultStyle.topThreeBg')
    top3-item(v-for='(item, index) in topThreeList', :key='index', :item='item', :images='images', :useStyle='useStyle')

  //- 4~n
  a-section(:bg-i='useImages.commonBg', v-bind='defaultStyle.commonBg', v-for='(item, index) in commonList', :key='index')
    top4-n-item(:item='item', :images='images', :useStyle='useStyle', type='common')

  //- mine
  a-section(:bg-i='useImages.mineBg', v-bind='defaultStyle.mineBg')
    top4-n-item(:item='useMine', :images='images', :useStyle='useStyle', type='mine')
</template>

<script>
import top3Item from '../components/rank-item/top3.vue'
import top4NItem from '../components/rank-item/top4-n.vue'

import { genUsePropsMixin } from '../mixins'
import { genDefaultRankItem, defaultStyle } from '../config'
import { RANK } from '../const'

export default {
  components: { top3Item, top4NItem },

  mixins: [genUsePropsMixin(RANK)],

  props: {},

  data() {
    return {
      defaultStyle: defaultStyle[RANK]
    }
  },

  computed: {
    rankParams() {
      return this._storeGetters('rankParams')
    },
    rankList() {
      return this._storeState('rankList')
    },
    mine() {
      return this._storeState('mine')
    },

    useRankList({ rankList }) {
      return new Array(this.rankParams.limit).fill({}).map((item, index) => {
        const rankItem = rankList[index] || genDefaultRankItem({ rank: index + 1 })
        return {
          ...rankItem,
          ...this.getVoteInfo(rankItem),
          ...this.getUserInfo(rankItem)
        }
      })
    },
    topThreeList({ useRankList }) {
      return useRankList.slice(0, 3)
    },
    commonList({ useRankList }) {
      return useRankList.slice(3)
    },
    useMine({ mine }) {
      return {
        ...genDefaultRankItem({ rank: 0 }),
        ...mine,
        ...this.getVoteInfo(mine),
        ...this.getUserInfo(mine)
      }
    }
  },

  created() {
    // this._storeDispatch('getRankList')
    // this._storeDispatch('getRankInfo')
  },

  methods: {
    getVoteInfo(item) {
      const { vote_count, id, video_cover_url, video_url, video_name, platform, youtube_video_id, tiktok_block_quote } = item.member_extra_info?.video_info || {}
      return {
        vote_count: vote_count || 0,
        id,
        video_cover_url,
        video_url,
        video_name,
        platform,
        youtube_video_id,
        tiktok_block_quote
      }
    },
    getUserInfo(item) {
      const userInfos = item.user_infos || [{}]
      return {
        ...(userInfos[0] || {})
      }
    }
  }
}
</script>

<style scoped></style>
