export const month = '09'
export const year = '2024'
export const actId = 2881

export const path = `${year}${month}`

// 测试服：

// 华语服
// 蛋糕工坊
// https://activity-dev.weplayapp.com/activity/v2/202409/workShop-c/index.html
// 生日会
// https://activity-dev.weplayapp.com/activity/v2/202409/birthdayParty-c/index.html

// 日服
// 蛋糕工坊
// https://activity-dev.weplayapp.com/activity/v2/202409/workShop-j/index.html
// 生日会
// https://activity-dev.weplayapp.com/activity/v2/202409/birthdayParty-j/index.html

// 阿语服
// 蛋糕工坊
// https://activity-dev.weplayapp.com/activity/v2/202409/workShop-a/index.html
// 生日会
// https://activity-dev.weplayapp.com/activity/v2/202409/birthdayParty-a/index.html

// 韩服服
// 蛋糕工坊
// https://activity-dev.weplayapp.com/activity/v2/202409/workShop-k/index.html
// 生日会
// https://activity-dev.weplayapp.com/activity/v2/202409/birthdayParty-k/index.html

// 泰服
// 蛋糕工坊
// https://activity-dev.weplayapp.com/activity/v2/202409/workShop-t/index.html
// 生日会
// https://activity-dev.weplayapp.com/activity/v2/202409/birthdayParty-t/index.html

// 美服
// 蛋糕工坊
// https://activity-dev.weplayapp.com/activity/v2/202409/workShop-u/index.html
// 生日会
// https://activity-dev.weplayapp.com/activity/v2/202409/birthdayParty-u/index.html

// -----------------------------------------------------------------------------------------------------------------------

// 线上
// 华语服
// 蛋糕工坊
// https://activity.weplayapp.com/activity/v2/202409/workShop-c/index.html
// 生日会
// https://activity.weplayapp.com/activity/v2/202409/birthdayParty-c/index.html

// 日服
// 蛋糕工坊
// https://activity.weplayapp.com/activity/v2/202409/workShop-j/index.html
// 生日会
// https://activity.weplayapp.com/activity/v2/202409/birthdayParty-j/index.html

// 阿语服
// 蛋糕工坊
// https://activity.weplayapp.com/activity/v2/202409/workShop-a/index.html
// 生日会
// https://activity.weplayapp.com/activity/v2/202409/birthdayParty-a/index.html

// 韩服服
// 蛋糕工坊
// https://activity.weplayapp.com/activity/v2/202409/workShop-k/index.html
// 生日会
// https://activity.weplayapp.com/activity/v2/202409/birthdayParty-k/index.html

// 泰服
// 蛋糕工坊
// https://activity.weplayapp.com/activity/v2/202409/workShop-t/index.html
// 生日会
// https://activity.weplayapp.com/activity/v2/202409/birthdayParty-t/index.html

// 美服
// 蛋糕工坊
// https://activity.weplayapp.com/activity/v2/202409/workShop-u/index.html
// 生日会
// https://activity.weplayapp.com/activity/v2/202409/birthdayParty-u/index.html
