<template lang="pug">
a-dialog(:name='uiMeta.key', :bg-i='images.bg')
  template(slot-scope='scope', v-if='scope.data')
    span(:data-var='(reward = scope.data.reward)')
    //- 奖励
    a-position(:bg-i='images.rewardBg', pc='x', pt='1.58')
      ui-prop(:data='reward', :extra='{ canPreview: false }', wh='100%')
      ui-text(pr='0', pb='0', fs='0.28', fw='600', :content='reward.unit', v-bind='{ strokeWidth: 3, ...props.unitStyle }', :style='props.unitStyle')
    //- 名称
    ui-text(pc='x', pt='3.6', fs='0.22', :content='reward.name', :style='props.rewardNameStyle')
    //- 永久
    template(v-if='reward.isPermanent')
      ui-text(w='5.2', pc='x', pt='4.25', fs='0.24', ta, :content='getTextContent(scope.data)', :style='props.confirmTextStyle')
    //- 非永久
    template(v-else)
      ui-input-control(pc='x', pt='4.15', :uiMeta='props["ui-input-control"]', :value='scope.data.num', :propLimit='getLimitNum(scope.data.price)', @change-value='scope.data.num = $event')
      ui-text(w='5.2', pc='x', pt='4.74', fs='0.24', ta, :content='getTextContent(scope.data)', :style='props.confirmTextStyle')

    ui-button(:bg-i='images.confirmBtn', pc='x', pb='0.74', @a-tap='scope.data.directBuy({ num: scope.data.num })')
</template>

<script>
import qRewards from '@sub/ui-runtime/core/components/base/q-rewards.vue'

export default {
  components: {
    qRewards
  },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    coinNum() {
      return this.$base.coin()
    },
    props() {
      return this.uiMeta.props || {}
    },
    images() {
      return this.props.images || {}
    },
    text() {
      return this.props.text
    }
  },

  methods: {
    getLimitNum(price) {
      return Math.floor(this.coinNum / price)
    },

    getTextContent({ price, reward, num }) {
      const { permanentBuy, confirmText } = this.text
      const { color } = this.props.focusConfirmTextStyle

      const rewardNameSuffix = reward.isPermanent ? `(${reward.unit})` : reward.unit
      let rewardName = reward.name + ' ' + rewardNameSuffix
      if (['A'].includes(this._region)) {
        rewardName = rewardNameSuffix + ' ' + reward.name
      }
      if (['HW', 'C', 'J', 'T'].includes(this._region)) {
        rewardName = reward.name + rewardNameSuffix
      }

      if (reward.isPermanent) {
        return this.$text(permanentBuy, { color, costNum: price, rewardName })
      } else {
        return this.$text(confirmText, { color, costNum: price * num, buyNum: num, rewardName })
      }
    }
  }
}
</script>

<style></style>
