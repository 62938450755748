export default [
  {
    path: '/activity-20230204-main-ar',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/12/04 23:59:59 UTC+9',
      quickConfig: 'c5adc95badafbc51dea6c39e77c7f2d9'
    }
  },
  {
    path: '/activity-20230204-rank-ar',
    component: () => import('./rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/12/04 23:59:59 UTC+9',
      quickConfig: 'c5adc95badafbc51dea6c39e77c7f2d9'
    }
  }
]
