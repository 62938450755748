import { buildApi } from '@sub/utils/request'

// 根据 cate 和 id 获取奖励标准化数据
// https://wepie.feishu.cn/wiki/CQiWwBQdPiciXckDXxwcoZfvnOd
export const getRewardMeta = buildApi('/activity_v2/common/get_reward_meta_common')

/**
 * 将标准化数据结构转化为 ui-prop 消费的 data 结构
 * @param {Object} rewardMeta - 奖励元数据对象。
 * @param {Object} [options={}] - 可选的参数对象。
 * @param {boolean} [options.avatarGenderIsMale=false] - 是否男性角色头像。
 * @returns {Object} 返回转换后的属性数据对象。
 */

export const rewardMetaToPropData = (rewardMeta, options = {}) => {
  const { avatarGenderIsMale = false } = options
  const isAvatar = rewardMeta.cate === 'avatar_prop'

  const ret = { ...rewardMeta }

  if (isAvatar) {
    Object.assign(ret, {
      img: avatarGenderIsMale ? ret.img : ret.img_female || ret.img,
      name: avatarGenderIsMale ? ret.name : ret.name_female || ret.name,
      imgBig: ret.extra?.suit_act_anim || ''
    })
  }

  Object.assign(ret, {
    type_str: ret.cate,
    svga: ret.animation
  })

  return ret
}
