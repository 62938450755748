import { isA } from '@sub/ui-components/utils/config'
import { PAGE_REGION, IS_WEPLAY } from '@/utils/env'

const ptConfig = {
  1: '-1.7',
  2: '0.6',
  3: '3'
}

const defaultConfig = {
  iconPosition: { pc: 'x', pb: '-.46' },
  textPosition: { pc: 'x', pb: '-.82' }
}

// 奖励展示每个盒子相关配置
const rechargeGiftList = []

// 第一行配置
const line1Config = {
  '1:1:1': [
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '.6', pt: ptConfig[1] }
    },
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '3.06', pt: ptConfig[1] }
    },
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pl: '.77', pt: ptConfig[1] }
    }
  ],
  '1:1:2': [
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '.6', pt: ptConfig[1] }
    },
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '2.18', pt: ptConfig[1] }
    },
    {
      rewardNum: 2,
      bgImg: 'rewardBg2',
      domPosition: { pl: '.77', pt: ptConfig[1] }
    }
  ],
  '1:2:1': [
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '.6', pt: ptConfig[1] }
    },
    {
      rewardNum: 2,
      bgImg: 'rewardBg2',
      domPosition: { pr: '2.18', pt: ptConfig[1] }
    },
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pl: '.77', pt: ptConfig[1] }
    }
  ],
  '1:2:2': [
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '.6', pt: ptConfig[1] }
    },
    {
      rewardNum: 2,
      bgImg: 'rewardBg2-s',
      domPosition: { pr: '2.02', pt: ptConfig[1] }
    },
    {
      rewardNum: 2,
      bgImg: 'rewardBg2-s',
      domPosition: { pl: '.56', pt: ptConfig[1] }
    }
  ],
  '2:1:1': [
    {
      rewardNum: 2,
      bgImg: 'rewardBg2',
      domPosition: { pr: '.62', pt: ptConfig[1] }
    },
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '3.87', pt: ptConfig[1] }
    },
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pl: '.77', pt: ptConfig[1] }
    }
  ],
  '2:1:2': [
    {
      rewardNum: 2,
      bgImg: 'rewardBg2-s',
      domPosition: { pr: '.6', pt: ptConfig[1] }
    },
    {
      rewardNum: 1,
      bgImg: 'rewardBg1',
      domPosition: { pr: '3.1', pt: ptConfig[1] }
    },
    {
      rewardNum: 2,
      bgImg: 'rewardBg2-s',
      domPosition: { pl: '.6', pt: ptConfig[1] }
    }
  ]
}

// 第2/3行配置
const line2Config = [
  {
    rewardNum: 2,
    bgImg: 'rewardBg2',
    domPosition: { pl: '.79', pt: ptConfig[2] }
  },
  {
    rewardNum: 2,
    bgImg: 'rewardBg2',
    domPosition: { pr: '.59', pt: ptConfig[2] }
  }
]

const line3Config = {
  2: {
    rewardNum: 2,
    wrapKey: '3-2',
    bgImg: 'rewardBg3',
    domPosition: { pl: '.82', pt: ptConfig[3] }
  },
  3: {
    rewardNum: 3,
    bgImg: 'rewardBg3',
    domPosition: { pl: '.82', pt: ptConfig[3] },
    textPosition: { pl: '3.2', pb: '-.44' }
  }
}

function getRechargeGiftList({ line1Type, line3Type, rewardConfig }) {
  let idx = 0
  return [...line1Config[line1Type], ...line2Config, line3Config[line3Type]].map((item) => {
    return {
      ...item,
      rewards: new Array(item.rewardNum).fill(0).map(() => rewardConfig[idx++])
    }
  })
}

export const rewardsPositionConfigA = {
  rewardsWrap: {
    1: { pc: 'x', pt: 0.09, wh: 0.89 },
    2: { pc: 'x', pt: 0.09, wh: '2.2 .89' },
    3: { pc: 'x', pt: 0.14, wh: '4.19 .89' },
    '3-2': { pc: 'x', pt: 0.14, wh: '2.5 .89' }
  },
  subscript: { [isA ? 'pl' : 'pr']: 0, pb: -0.02, fs: 0.22, fw: 600 },
  rewardNameCommonStyle: {
    letterSpacing: IS_WEPLAY ? (['A', 'C'].includes(PAGE_REGION) ? 0 : '-.01rem') : 0
  },
  rewardName: {
    1: { pc: 'x', pt: 0.9, wh: '1.2 .41', fs: 0.14, fw: 500 },
    2: { pc: 'x', pt: 0.9, wh: '1.14 .41', fs: 0.14, fw: 500 },
    3: { pc: 'x', pt: 0.9, wh: '1.5 .41', fs: 0.14, fw: 500 }
  }
}

export function genPositionConfigA() {
  return {
    defaultConfig,
    rechargeGiftList,
    getRechargeGiftList
  }
}

export default {
  defaultConfig,
  rechargeGiftList,
  getRechargeGiftList
}
