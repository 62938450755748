import { merge } from 'lodash'
import { configurableStyle } from './config'

/**
 * @description 生成每个组件内可直接消费的属性
 * @param {string} type search / rank / voteConfirmDialog / voteSuccessDialog
 * @returns {object} mixin 对象
 */
export function genUsePropsMixin(type) {
  return {
    computed: {
      uiMeta() {
        return this._storeState('uiMeta')
      },
      props() {
        return this.uiMeta.props || {}
      },
      images() {
        return this._storeGetters('images')
      },
      text() {
        return this._storeGetters('text')
      },

      useImages() {
        return this.images[type]
      },
      useStyle() {
        return merge(configurableStyle, this.props.specialStyle || {})[type]
      },
      useText() {
        return this.text[type]
      }
    }
  }
}
