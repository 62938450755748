import { render, staticRenderFns } from "./ui-reward.vue?vue&type=template&id=7db30f1e&scoped=true&lang=pug"
import script from "./ui-reward.vue?vue&type=script&lang=js"
export * from "./ui-reward.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db30f1e",
  null
  
)

export default component.exports