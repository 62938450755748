import { PAGE_REGION } from '@/utils/env'
import { isA } from '@sub/ui-components/utils/config'

const rewardNameFs = {
  J: 0.18
}

export const rewardSwiperStyle = {
  rewardNameStyle: { w: 1.5, pt: 1.52, pc: 'x', lh: 0.24, fw: 400, fs: rewardNameFs[PAGE_REGION] || 0.2, letterSpace: ['J', 'M', 'K', 'T', 'U', 'P'].includes(PAGE_REGION) ? '-.01rem' : 0 },
  subscriptStyle: { fs: 0.24, fw: 600, pb: 0.07, [isA ? 'pl' : 'pr']: 0, strokeWidth: 3 },
  labelIconStyle: { w: 1.96, h: 0.66, pt: -0.28, pc: 'x' }
}
