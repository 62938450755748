<template lang="pug">
a-section(w='7.5', v-bind='attrs')
  ui-vote-search

  ui-vote-rank

  ui-vote-confirm-dialog

  ui-vote-success-dialog

  ui-vote-view-work-dialog

  ui-vote-play-video
</template>

<script>
import './store'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    context: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  created() {
    this._storeCommit('setState', {
      uiMeta: this.uiMeta,
      context: this.context
    })

    this.init()
  },

  mounted() {
    this.initPageJump()
  },

  onShow() {
    this.$base.getChipInfo()
  },

  methods: {
    async init() {
      this.$storage.init({ noSuccessTip: false })

      this.$showLoading()
      await Promise.all([this._storeDispatch('getInfo'), this._storeDispatch('getRankList'), this._storeDispatch('getRankInfo')])
      this.$hideLoading()
    },

    async initPageJump() {
      // 作品编号
      const { id } = this.$route.query
      if (!id) {
        return
      }

      const resultList = await this._storeDispatch('doSearch', {
        key_word: id
      })
      const item = resultList?.[0]
      if (!item) {
        return
      }

      this._storeState('openVoteConfirmDialog')({ item })
    }
  }
}
</script>

<style scoped></style>
