let installed = false

async function install() {
  if (installed) {
    return window.linkedme
  }

  await import(/* webpackChunkName: "linkedme" */ './linkedme.min.js')

  installed = true

  return window.linkedme
}

export default install
