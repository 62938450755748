import event from '@sub/event'
import { nextTick } from 'vue'

export async function jumpToAnchor({ key = null } = {}) {
  const el = document.querySelector(`[componentkey='${key}']`)

  // 拿到所属 tab
  const tabIndex = el.getAttribute('tabIndex')

  // 切 tab eventbus
  if (tabIndex !== null) {
    event.emitOnSwitchTab(+tabIndex)
    await nextTick()
  }

  // 锚点组件有所属tabIndex时，当前落地的 tab 和 目标 tab 相等才跳
  const curTabIndex = window.runtime_curTabIndex
  console.log('gwl tabIndex', tabIndex, curTabIndex, key)
  if (tabIndex !== null && curTabIndex !== undefined && curTabIndex !== +tabIndex) {
    return
  }
  jumpToEl(el)

  return
}

export function jumpToEl(el) {
  if (!el) return

  window.scrollTo({
    top: getElementTop(el),
    behavior: 'smooth'
  })
}

// 获取元素距离页面顶部的距离（解决多层元素的问题）
export function getElementTop(el) {
  let realTop = el.offsetTop

  let parentEl = el.offsetParent

  while (parentEl !== null) {
    realTop += parentEl.offsetTop

    parentEl = parentEl.offsetParent
  }

  return realTop
}
