<template lang="pug">
a-position.ui-vote-wrapper-input-control(v-bind='{ ...inputWrapperStyle, ...$attrs }', :style='useStyle.inputControl')
  //- 减
  a-button1.ui-vote-wrapper__input-control--minus(pl='0.05', pc='y', wh='0.36', bg-s='contain', :bg-i='useImages.subBtn', @a-tap='sub()', :class='{ grey: !isCanSub }', pz='1')

  a-position.ui-vote-wrapper__input-control-num(pc, wh='100%', :style='useStyle.voteNum') {{ value }}

  //- 加
  a-button1.ui-vote-wrapper__input-control-add(pr='0.05', pc='y', wh='0.36', bg-s='contain', :bg-i='useImages.addBtn', @a-tap='add()', :class='{ grey: !isCanAdd }', pz='1')

  //- 最大
  a-button1.ui-vote-wrapper__input-control-max(:pl='inputWrapperStyle.w + 0.08', pc='y', :bg-i='useImages.maxBtn', @a-tap='setMax()')
</template>

<script>
const inputWrapperStyle = {
  w: 2.28,
  h: 0.44,
  lh: 0.44,
  ta: 'center',
  bdr: 0.5,
  bgC: '#7B5B3E'
}

export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: null
    },

    useImages: {
      type: Object,
      default: () => ({})
    },

    useStyle: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      inputWrapperStyle
    }
  },

  computed: {
    isCanSub() {
      return this.value > 1
    },

    isCanAdd() {
      return this.value < this.max
    }
  },

  methods: {
    sub() {
      if (!this.isCanSub) return
      this.$emit('input', this.value - 1)
    },

    add() {
      if (!this.isCanAdd) return
      this.$emit('input', this.value + 1)
    },

    setMax() {
      this.$emit('input', this.max)
    }
  }
}
</script>

<style></style>
