import { isA } from '@sub/ui-components/utils/config'

const prl = isA ? 'pr' : 'pl'

export const exchangeStyleConfig = {
  chipStyle: {
    wrap: { wh: '3.18 .56', pt: 0, [prl]: 0.38 },
    count: { [prl]: 0.8, pc: 'y', fs: '.26', fw: '500', wh: '1.61 .28', lh: 0.28, ta: isA ? 'r' : 'l' }
  },
  exchangeLogWrap: { pt: 0.14, pr: isA ? 5 : 0.6, wh: '2 .28' },
  rewardStyle: (total, colsCount) => {
    const row = Math.floor(total / colsCount)
    if (colsCount === 4) {
      return {
        wrap: { w: 6.28, h: 2.74 * row + (row - 1) * 0.34, pc: 'x', pt: 1 },
        item: { wh: '1.48 2.02' },
        reward: { wh: 1.28, pc: 'x', pt: 0.1 },
        btn: { wh: '1.5 .6', pc: 'x', pt: 2.14, fs: 0.22, fw: 600 }
      }
    }
    // 3 兜底
    else {
      return {
        wrap: { w: 6.2, h: 3.22 * row + (row - 1) * 0.34, pc: 'x', pt: 1 },
        item: { wh: '1.8 2.5' },
        reward: { wh: 1.54, pc: 'x', pt: 0.14 },
        btn: { wh: '1.5 .6', pc: 'x', pt: 2.62, fs: 0.22, fw: 600 }
      }
    }
  }
}

export const calculateMargin = (index, colsCount) => {
  // 是否是4个一行
  const m = isA ? 'ml' : 'mr'
  const marginLR = colsCount === 4 ? 0.12 : 0.4
  return {
    mb: 1.06,
    [m]: !((index + 1) % colsCount) ? 0 : marginLR
  }
}
