import multiLanguage from '@sub/utils/multi-language'

import { isA } from '../../utils/config'
import { PAGE_REGION } from '@/utils/env'

const commonAttr = isA ? 'pl' : 'pr'
const commonAttr1 = isA ? 'pr' : 'pl'

const spStyle = {
  M: { lh: 0.24, fs: 0.2, letterSpacing: '-0.01rem' },
  T: { lh: 0.24, fs: 0.2, letterSpacing: '-0.01rem' },
  J: { lh: 0.24, fs: 0.2, letterSpacing: '-0.01rem' },
  V: { lh: 0.24, fs: 0.18, letterSpacing: '-0.01rem' },
  U: { lh: 0.24, fs: 0.18, letterSpacing: '-0.01rem' },
  P: { lh: 0.24, fs: 0.18, letterSpacing: '-0.01rem' },
  Q: { lh: 0.24, fs: 0.18, letterSpacing: '-0.01rem' }
}

const spItemStyle = {
  J: { h: 1 },
  R: { h: 1.1 },
  B: { h: 1.1 }
}
const commonTextStyle = { fs: '.22', fw: 400, lh: '0.286', ...(spStyle[PAGE_REGION] || {}) }

const commonHeadStyle = {
  2: {
    head1: { pl: 0.67, w: 1.51, pc: 'y' },
    head2: { pl: 3.1, w: 1.81, pc: 'y' }
  },
  3: {
    head1: { pl: 0.19, w: 1.51, pc: 'y' },
    head3: { pl: 2.01, w: 1.1, pc: 'y' },
    head2: { pl: 3.54, w: 1.81, pc: 'y' }
  },
  4: {
    head1: { pl: 0.19, w: 1, pc: 'y' },
    head2: { pl: 1.2, w: 1.3, pc: 'y' },
    head3: { pl: 2.5, w: 1.6, pc: 'y' },
    head4: { pl: 4.1, w: 1.5, pc: 'y' }
  }
}

const ArabHeadStyle = {
  2: {
    head1: { pr: 0.92, w: 1.06, pc: 'y' },
    head2: { pr: 3.1, w: 1.81, pc: 'y' }
  },
  3: {
    head1: { pr: 0.42, w: 1.06, pc: 'y' },
    head3: { pr: 1.8, w: 1.52, pc: 'y' },
    head2: { pr: 3.56, w: 1.76, pc: 'y' }
  },
  4: {
    head1: { pr: 0.19, w: 1, pc: 'y' },
    head2: { pr: 1.2, w: 1.3, pc: 'y' },
    head3: { pr: 2.5, w: 1.6, pc: 'y' },
    head4: { pr: 4.1, w: 1.5, pc: 'y' }
  }
}

export const recordStyleConfig = ({ textColor, tabWrapStyle, listWrapStyle, lineStyle }) => {
  return {
    tabWrapStyle: { ...tabWrapStyle, pz: 2 },
    closeBtn: { [commonAttr]: 0.1, pt: 0.1, pz: 10 },
    recordWrap: { wh: '5.62 4.1', pc: 'x', pt: 2.33, fc: textColor, ...commonTextStyle, ...listWrapStyle },
    lineStyle: { pc: 'x', pb: '0', 'bg-s': '100%', ...lineStyle },
    recordItem: (headerNum) => {
      const headStyle = isA ? ArabHeadStyle : commonHeadStyle
      return { itemStyle: { w: '100%', h: 0.8, pdb: '1px', ...(spItemStyle[PAGE_REGION] || {}) }, ...headStyle[headerNum] }
    }
  }
}

const defaultGetText = () => ({
  loading: 'loading...',
  empty: ''
})

const textConfig = {
  hw: () => ({
    loading: '加载中...',
    empty: '暂无数据'
  }),
  U: () => ({
    loading: 'loading...',
    empty: 'no data'
  }),
  C: () => ({
    loading: '加載中...',
    empty: '暫無紀錄'
  }),
  J: () => ({
    loading: 'loading...',
    empty: 'まだデータはありません'
  }),
  K: () => ({
    loading: 'loading...',
    empty: '기록이 없습니다.'
  }),
  A: () => ({
    loading: 'loading...',
    empty: 'لا توجد بيانات'
  }),
  M: () => ({
    loading: 'Memuatkan...',
    empty: 'Tiada data'
  }),
  ms: () => ({
    loading: 'Memuatkan...',
    empty: 'Tiada data'
  }),
  id: () => ({
    loading: 'Sedang Memuat...',
    empty: 'Tidak ada data'
  }),
  T: () => ({
    loading: 'กำลังโหลด...',
    empty: 'ไม่มีข้อมูล'
  }),
  P: () => ({
    loading: 'loading...',
    empty: 'no data'
  }),
  V: () => ({
    loading: 'Đang tải...',
    empty: 'Chưa có bản ghi～'
  }),
  Q: () => ({
    loading: 'loading...',
    empty: 'Henüz kayıt yok'
  }),
  B: () => ({
    loading: 'Carregando...',
    empty: 'Sem dados no momento'
  }),
  R: () => ({
    loading: 'Загрузка...',
    empty: 'Нет данных'
  }),
  I: () => ({
    loading: 'loading...',
    empty: 'no data'
  }),
  S: () => ({
    loading: 'Cargando...',
    empty: 'No hay datos disponibles'
  })
}
// export const recordTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION]() : textConfig.hw()
export const genRecordTextConfig = multiLanguage.getMultiTextConfig(textConfig)
