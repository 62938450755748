// 曲线的部分配置
const default_config = {
  x: 0.85,
  a_x: 6.6,
  y: 0.2
}

// 画曲线的list
// l: 线段长度 d: 线段方向, percent: 节点进度百分比
const cList1 = [
  [{ l: 0.25, d: 'lr', percent: 5 }],
  [{ l: 2, d: 'lr', percent: 15 }],
  [{ l: 2.4, d: 'lr', percent: 20 }],
  [{ l: 0.8, d: 'lr', percent: 25 }],
  [
    { l: 0.5, d: 'ald', percent: 25 },
    { l: 1.3, d: 'ud', percent: 35 }
  ],
  [
    { l: 0.5, d: 'adr', percent: 35 },
    { l: 1, d: 'rl', percent: 40 }
  ],
  [{ l: 3.15, d: 'rl', percent: 60 }],
  [{ l: 1.1, d: 'rl', percent: 65 }],
  [
    { l: 0.5, d: 'ard', percent: 65 },
    { l: 1.35, d: 'ud', percent: 75 }
  ],
  [
    { l: 0.5, d: 'adl', percent: 75 },
    { l: 2.6, d: 'lr', percent: 100 }
  ]
]

const cList2 = [
  [{ l: 0.25, d: 'lr', percent: 5 }],
  [{ l: 2, d: 'lr', percent: 10 }],
  [{ l: 2.4, d: 'lr', percent: 15 }],
  [{ l: 0.8, d: 'lr', percent: 20 }],
  [
    { l: 0.5, d: 'ald', percent: 20 },
    { l: 1.2, d: 'ud', percent: 30 }
  ],
  [
    { l: 0.5, d: 'adr', percent: 30 },
    { l: 1, d: 'rl', percent: 35 }
  ],
  [{ l: 3.15, d: 'rl', percent: 50 }],
  [{ l: 1.1, d: 'rl', percent: 55 }],
  [
    { l: 0.5, d: 'ard', percent: 55 },
    { l: 1.35, d: 'ud', percent: 65 }
  ],
  [
    { l: 0.5, d: 'adl', percent: 65 },
    { l: 2.6, d: 'lr', percent: 75 }
  ],
  [{ l: 1.5, d: 'lr', percent: 80 }],
  [{ l: 1.15, d: 'lr', percent: 85 }],
  [
    { l: 0.5, d: 'ald', percent: 85 },
    { l: 1.3, d: 'ud', percent: 90 }
  ],
  [
    { l: 0.5, d: 'adr', percent: 90 },
    { l: 2.6, d: 'rl', percent: 100 }
  ]
]

const cList3 = [
  [{ l: 0.25, d: 'lr', percent: 5 }],
  [{ l: 2, d: 'lr', percent: 15 }],
  [{ l: 2.4, d: 'lr', percent: 25 }],
  [
    { l: 0.5, d: 'ald', percent: 25 },
    { l: 2.4, d: 'ud', percent: 35 }
  ],
  [
    { l: 0.5, d: 'adr', percent: 35 },
    { l: 0.8, d: 'rl', percent: 40 }
  ],
  [{ l: 1.7, d: 'rl', percent: 50 }],
  [{ l: 1.75, d: 'rl', percent: 60 }],
  [
    { l: 0.5, d: 'ard', percent: 60 },
    { l: 2.4, d: 'ud', percent: 75 }
  ],
  [
    { l: 0.5, d: 'adl', percent: 75 },
    { l: 0.6, d: 'lr', percent: 80 }
  ],
  [{ l: 1.7, d: 'lr', percent: 90 }],
  [{ l: 1.7, d: 'lr', percent: 100 }]
]

const cList4 = [
  [{ l: 0.25, d: 'lr', percent: 5 }],
  [{ l: 2, d: 'lr', percent: 15 }],
  [{ l: 2.4, d: 'lr', percent: 20 }],
  [{ l: 0.8, d: 'lr', percent: 25 }],
  [
    { l: 0.5, d: 'ald', percent: 25 },
    { l: 1.35, d: 'ud', percent: 35 }
  ],
  [
    { l: 0.5, d: 'adr', percent: 35 },
    { l: 1, d: 'rl', percent: 40 }
  ],
  [{ l: 3.15, d: 'rl', percent: 60 }],
  [{ l: 1.1, d: 'rl', percent: 65 }],
  [
    { l: 0.5, d: 'ard', percent: 65 },
    { l: 1.35, d: 'ud', percent: 75 }
  ],
  [
    { l: 0.5, d: 'adl', percent: 75 },
    { l: 2.6, d: 'lr', percent: 100 }
  ]
]

export default {
  default_config,
  cList1,
  cList2,
  cList3,
  cList4
}
