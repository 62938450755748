import '@/directive'
import libInstall from '@/lib'
import beforeAppMount from '@/utils/before-app-mount'
import '@/utils/components-register'
import setFontSize from '@/utils/set-font-size'
import setVersion from '@/utils/set-version'
import 'normalize.css/normalize.css'
import Vue from 'vue'
import router from './router'
import store from '@/store'
import './style/index.scss'
import { PAGE_REGION } from '@/utils/env'
import { pageSpyInit, pageSpyKey } from '@sub/lib/page-spy'
import { isProduction } from '@sub/utils/switchEnvRequest'

const isA = PAGE_REGION === 'A'

// import header from '@/components/header.vue'

window.__activity_main_has_run__ = true

async function run() {
  setVersion(20210328)
  setFontSize()
  // 测试服和少数有问题的线上会await
  // if (!isProduction || pageSpyKey) {
  //   await pageSpyInit()
  // }

  const { i18n } = await libInstall(Vue)

  await beforeAppMount({ router, store })

  Vue.config.productionTip = false

  // Vue.prototype.$app = new Vue({ router, store, i18n, render: (h) => h('router-view', { class: ['page-container', i18n.locale, getRegionClass()] }) }).$mount('#app')
  Vue.prototype.$app = new Vue({
    router,
    store,
    i18n,
    render(h) {
      const routerView = h('router-view')

      const container = this.$route.meta.keepAlive ? [h('keep-alive', null, [routerView])] : [h('keep-alive'), routerView]
      const portalTarget = h('portal-target', { props: { name: 'page-container', multiple: true } })

      return h('div', { class: ['page-container', i18n.locale, getRegionClass()] }, [...container, portalTarget])
    }
  }).$mount('#app')
  // Vue.prototype.$app = new Vue({
  //   router,
  //   store,
  //   i18n,
  //   render: (h) => {
  //     return h('div', null, [h(header), h('router-view', { class: ['page-container', i18n.locale] })])
  //   }
  // }).$mount('#app')

  try {
    if (PAGE_REGION === 'V') {
      addNewRegionStyle()
    }
  } catch (error) {
    console.warn(error)
  }
}

run()

function getRegionClass() {
  if (isA) {
    return 'ar'
  }

  if (PAGE_REGION === 'J') {
    return 'ja'
  }

  return ''
}
console.log(`ywtest start run main.js, time: ${performance.now()}`)

// 样式相关域名临时修复，后续需优化
function addNewRegionStyle() {
  var css = `.loading::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5001;
    background: url('https://fe-center.wejoysg.com/page-center/assets/E20Ly6E3/GiKfaIu0.svg') no-repeat center;
    background-size: 32px 32px;
  }
  .layer-finish-V {
    position: absolute;
    top: 1.8rem;
    left: 50%;
    transform: translateX(-50%);
    width: 5.26rem;
    height: 3.82rem;
    background-image: url('https://fe-center.wejoysg.com/page-center/assets/TpyyQTP8/S7qwi8XL.webp?_w=342&_h=225');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 99;
    pointer-events: none;
  }
  `,
    body = document.body || document.getElementsByTagName('body')[0],
    style = document.createElement('style')

  body.appendChild(style)

  style.type = 'text/css'
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }
}
