<template lang="pug">
a-section.of-h(v-bind='$attrs')
  video(
    ref='video',
    :poster='$route.meta.assets[`${image.poster}`]',
    playsinline='true',
    controls,
    webkit-playsinline='true',
    width='100%',
    height='100%',
    :style='videoStyle',
    :autoplay='autoplay',
    :src='$route.meta.assets[`${videoSrc}`]'
  )
  a-button1(v-show='showPlay', :bg-i='image.playBtn', pc='x', v-bind='playBtnPos', @a-tap='play()')
</template>
<script>
import get from 'lodash/get'
export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      video: null,
      showPlay: true
    }
  },

  computed: {
    videoSrc({ uiMeta }) {
      return get(uiMeta, 'props.videoSrc', '')
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    videoStyle({ uiMeta }) {
      const { borderRadius, width, height, border } = get(uiMeta, 'props.style.video', {})
      return {
        'border-radius': `${borderRadius}rem`,
        border,
        width: `${width}rem`,
        height: `${height}rem`
      }
    },
    playBtnPos({ uiMeta }) {
      return get(uiMeta, 'props.style.playBtnPos', {})
    }
  },

  mounted() {
    this.video = this.$refs.video

    this.video?.addEventListener('play', () => {
      this.showPlay = false
      this.$emit('start')
    })

    this.video?.addEventListener('pause', () => {
      this.showPlay = true
      this.$emit('pause')
    })

    this.video?.addEventListener('ended', () => {
      this.showPlay = true
      this.video.load()
      this.$emit('end')
    })
  },
  methods: {
    end() {
      this.video.pause()
    },

    play() {
      if (!this.video.paused) {
        return
      }
      let promise = this.video?.play()
      if (promise !== undefined) {
        promise.catch((error) => {
          console.log(error, '手动播放报错')
          this.isPlay = false
        })
      }
    }
  }
}
</script>
<style scoped></style>
