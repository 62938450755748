export default [
  {
    path: '/activity/v2/20231109/family-war-rank-a/index.html',
    component: () => import('./rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1359,
      pageCenter: 'J4jOunFx'
    }
  },
  {
    path: '/activity/v2/20231109/create-family-a/index.html',
    component: () => import('./create-family.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: '1H9TDjFr'
    }
  },
  {
    path: '/activity/v2/20231109/family-war-reward-a/index.html',
    component: () => import('./reward.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1359,
      pageCenter: 'fkT0FszY'
    }
  },
  {
    path: '/activity/v2/20231109/family-war-main-a/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1359,
      pageCenter: 'pNLW5aQU'
    }
  }
]
