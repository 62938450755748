import Vue from 'vue'
import Handlebars from 'handlebars'
import { get, set } from 'lodash'
/**
 * @module text
 */
export default {
  $text: textCompiler
}

export function textCompiler(str = '', data = {}) {
  if (!str) return ''

  const text = get(Vue.prototype.$app, `$route.meta.text.${str}`, str + '')

  // Handlebars.compile 会将「data 中没有的变量」直接替换为空字符串，这里期望是保持原样
  getVariables(text).forEach((item) => {
    const path = item.replace('{{', '').replace('}}', '')
    const value = get(data, path, null)
    if (value === null) {
      set(data, path, item)
    }
  })

  return Handlebars.compile(text)(data)
}

/**
 * @description 匹配字符串中所有的{{变量名}}，并返回数组
 * @param {string} str 字符串
 * @returns {string[]} 变量字符串数组，['{{color}}', '{{name}}']
 */
function getVariables(str) {
  const pattern = /\{\{(.+?)\}\}/g // 正则表达式，匹配 {{变量名}}

  const variables = str.match(pattern) // 查找所有变量名
  if (variables) {
    return variables
  } else {
    return []
  }
}
