import weSDK from '@wepie/we-sdk'
import { IS_APP } from '@/utils/env'
import Vue from 'vue'
import config from '@/config'
import qs from 'qs'
import quickConfig from '@/store/quick-config'
import get from 'lodash/get'
import localStorage from '@/utils/local-storage'

// 去掉阿服不可预见字符
export const filterInvisibleStrAr = (str) => {
  const { region } = Vue.prototype.$app.$route.query
  return region === 'A' && str ? str.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, '') : str
}

export const textSliceAr = (text, num = 5) => {
  text = text || ''
  let array = Array.from(filterInvisibleStrAr(text))
  return array.length > num ? `${array.splice(0, num).join('')}...` : text
}

// 计算进度
export const progressCalculate = (stages = [], current = 0, startVal = 0, scale = 1) => {
  if (!current) {
    return startVal
  }

  if (!stages.length || current >= Math.max(...stages)) {
    return 1
  }

  const avg = (1 * scale) / stages.length

  for (let i = 0; i < stages.length; i++) {
    const item = stages[i]
    if (current < item) {
      const lastItem = stages[i - 1] || 0
      return startVal + (avg * i + ((current - lastItem) * avg) / (item - lastItem))
    }
  }
}

/**
 * 函数描述 进度计算
 * @param { Object } option 参数描述
 * @param { Array } option.stages 阶梯数组，达到每一级所需的数值
 * @param { Number } option.current 当前值
 * @param { Number } [option.startVal] 默认初始值
 * @param { Array } [option.percent] 每一级在设计图上占总长度的百分比，默认是平均分，数值长度与stages一致 例: [0.1, 0.2, 0.2, 0.2, 0.2], 加起来应该小于等于1
 * @return { Number} 当前进度0-1
 */

export const progressCalculate1 = ({ stages = [], current = 0, startVal = 0, percent = [] }) => {
  if (!current) {
    return startVal
  }

  if (!stages.length || current >= Math.max(...stages)) {
    return 1
  }

  const avg = 1 / stages.length

  if (!percent.length) {
    percent = new Array(stages.length).fill(avg)
  }

  const getCurPercent = (i) => {
    let res = 0
    percent.forEach((item, index) => {
      if (index < i) {
        res += item
      }
    })
    return res
  }

  for (let i = 0; i < stages.length; i++) {
    const item = stages[i]
    if (current < item) {
      const lastItem = stages[i - 1] || 0
      return startVal + (getCurPercent(i) + ((current - lastItem) * percent[i]) / (item - lastItem))
    }
  }
}

/**
 * 函数描述 进度计算
 * @param { Array } stages 阶梯数组，达到每一级所需的数值
 * @param { Number } current 当前值
 * @return { Number} 距离下一个阶段还差多少
 */
// 距离下个阶段的差值计算
export const differenceCalculate = (stages = [], current = 0) => {
  if (current >= Math.max(...stages)) {
    return 0
  }

  for (let i = 0; i < stages.length; i++) {
    if (current < stages[i]) {
      return stages[i] - current
    }
  }
}

// 获取vip
export const getVipIcon = (vip) => {
  let vipImgMap = {
    1: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/XExLXAPc.png?_w=112&_h=84',
    2: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/ggg8xBZg.png?_w=112&_h=84',
    3: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/SHK57XKK.png?_w=112&_h=84',
    4: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/e22k2gh3.png?_w=112&_h=84',
    5: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/t2vCUyT5.png?_w=112&_h=84',
    6: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/CnjFtNkL.png?_w=112&_h=84',
    7: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/eIq3vnR6.png?_w=112&_h=84',
    8: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/iJca8Usb.png?_w=112&_h=84',
    9: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/tdIo998G.png?_w=216&_h=192',
    10: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/5NPFVTPt.png?_w=216&_h=192',
    11: 'https://fe-center.weplayapp.com/page-center/assets/LeiH2Zaj/XtLnSPSR.png?_w=112&_h=84'
  }
  return vipImgMap[vip]
  // return require(`@/assets/images/vip/${vip}.png`)
}
export const pageJump = (url) => {
  if (IS_APP) {
    weSDK('jumpDeepLink', `wespydeeplink://http?url=${encodeURIComponent(url)}`)
  } else {
    window.location.href = url
  }
}
const handleArgs = function (args) {
  const { region, lang, scene, head, u } = Vue.prototype.$app.$route.query

  const newArgs = {
    path: '',
    query: { region, lang, scene, head }
  }
  if (config.$env.LOCAL) {
    newArgs.query.u = u
  }
  if (typeof args === 'string') {
    newArgs.path = args
  } else {
    newArgs.path = args.path
    newArgs.query = { ...newArgs.query, ...args.query }
  }
  return newArgs
}

export const push = (args) => {
  Vue.prototype.$app.$router.push(handleArgs(args))
}

export const replace = (args) => {
  Vue.prototype.$app.$router.replace(handleArgs(args))
}

export const getQuery = (href, origin, pathname) => {
  return href.slice(origin.length + pathname.length)
}

export const getImgSrc = (name) => {
  return Vue.prototype.$app.$route.meta.assets[name]
}

export const pageJump2 = (path, query) => {
  const { region, lang, scene, head, u } = Vue.prototype.$app.$route.query
  let obj = { region, lang, scene, head, u }
  if (config.$env.PROD) {
    delete obj.u
  }
  let newObj = {}
  for (let key in obj) {
    if (obj[key] !== undefined) {
      newObj[key] = obj[key]
    }
  }
  query = { ...query, ...newObj }
  let resUrl = `${window.location.origin}/${path}`
  let idx = 0
  for (let key in query) {
    if (idx === 0) {
      resUrl += `?${key}=${query[key]}`
    } else {
      resUrl += `&${key}=${query[key]}`
    }
    idx++
  }
  window.history.replaceState(null, '', resUrl)
  window.history.go(0)
}

export function getQuery1() {
  return qs.parse(location.search, {
    ignoreQueryPrefix: true
  })
}

export function addClass(selector, classNameList) {
  document.querySelector(selector).classList.add(...classNameList)
}

export function formatPreview(id, propType = 0) {
  /**
   * 该方法只用于在quick-page上面添加过的道具。如果有需要直接通过id预览的道具直接用sdk方法预览即可
   *  3: '戒指', 4: '主頁特效', 6: '頭像框', 10: '語音房氣泡', 11: '聊天氣泡', 12: '玩友圈卡片', 13: '進場特效', 19: '語音房背景', 23: '禮物卡', 50: '座駕', 51: '紅包皮膚'
   */

  const runtime = window.location.pathname.startsWith('/runtime')

  const propsConfig = get(quickConfig.getQuickConfig(window.location.pathname), 'props', {})
  const canPreviewTypeArr = [1, 3, 4, 5, 6, 10, 11, 12, 13, 19, 23, 26, 30, 50, 51, 52, 54]
  const type = runtime ? +propType : propsConfig[id]?.type
  const canPreview = canPreviewTypeArr.includes(type)

  if (config.$env.DEV) {
    console.info('道具预览id:', id)

    if (!canPreview) {
      console.info('canPreview =', canPreview)
      console.info('prop type =', type)
      console.info('canPreviewTypeArr =', canPreviewTypeArr)
    }
  }

  canPreview && weSDK('previewProp', id)
}

export function formatPreview1(id) {
  /**
   * 该方法默认打印道具id，不受quick-page配置和道具类型限制
   */
  if (config.$env.DEV) {
    console.info('道具预览id:', id)
  }

  id && weSDK('previewProp', id)
}

/**
 * 函数描述 等待异步任务全部完成 Promise.all实现
 * @param { Array } promises 异步任务数组，每一项是一个promise
 */
export function asyncAll(promises) {
  return Promise.all(promises)
}

// 获取阿服为天数时的展示方式
export function getArUnit(day) {
  let unit = ''
  if (typeof day !== 'number') return unit
  if (day === 1) {
    unit = 'يوم'
  } else if (day === 2) {
    unit = 'يومين'
  } else if (day >= 3 && day <= 10) {
    unit = `${day} أيام`
  } else if (day >= 11) {
    unit = `${day} يوم`
  }
  return unit
}

// 获取各区服为天数时的展示方式
export function getDayUnit(day) {
  let unit = ''
  if (typeof day !== 'number') return unit

  const { region } = Vue.prototype.$app.$route.query

  switch (region) {
    case 'C':
      unit = `${day}天`
      break
    case 'J':
      unit = `${day}日`
      break
    case 'K':
      unit = `${day}일`
      break
    case 'A':
      unit = getArUnit(day)
      break
    case 'M':
      unit = `${day} hari`
      break
    case 'T':
      unit = `${day} วัน`
      break
    case 'Q':
      unit = `${day} Gün`
      break
    case 'U':
      unit = day > 1 ? `${day} days` : `${day} day`
      break
    default:
      unit = ''
  }

  return unit
}

// 获取各区服为数量时的展示方式
export function getNumUnit(num) {
  let unit = ''
  if (typeof num !== 'number') return unit

  const { region } = Vue.prototype.$app.$route.query

  switch (region) {
    case 'A':
      unit = `${num}x`
      break
    default:
      unit = `x${num}`
  }

  return unit
}

export function getUnit(item, num_key = 'reward_amount') {
  const propsConfig = get(quickConfig.getQuickConfig(window.location.pathname), 'props', {})
  const dayUnitList = [1, 4, 6, 10, 11, 13, 19, 30, 51, 999]
  const num = item[num_key] || item.reward_val

  if (dayUnitList.includes(propsConfig[item.reward_id]?.type)) {
    return getDayUnit(num)
  } else {
    return getNumUnit(num)
  }
}

/**
 * @description 获取道具的单位类型
 * @param { Number } type 道具类型
 * @return { Number } 道具的单位类型 1 => x1 2 => 1天
 */
export function getPropsUnitType(type) {
  // 礼物、戒指、紫晶币 =>xN
  const type1 = [3, 23, 5]
  // 定义单位类型  1或2 1 => x1 2 => 1天

  return type1.includes(+type) ? 1 : 2
}

/**
 * @description 获取道具的单位
 * @param { Number } num 数量
 * @param { Number } type 道具类型
 * @return { Number } 组合出来的道具单位 xn 或 n天
 */
export function getPropUnit(num, type) {
  return getPropsUnitType(type) === 1 ? getDayUnit(num) : getDayUnit(num)
}

export function supportsWebp() {
  return localStorage.get('__supports_webp__')
}

/**
 *
 * @param {*} blob 原始文件
 * @returns
 */
export function transformFile2Base64(blob) {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', () => {
      resolve(`${reader.result}`)
    })
    reader.addEventListener('error', (err) => {
      reject(err)
    })
  })
}
