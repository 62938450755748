<template lang="pug">
component(v-bind='$attrs', bg-s='contain', wh='1.2 0.44', @a-tap='openVoteConfirmDialog()', :is='isCanVote ? "a-button1" : "a-position"', :class='{ grey: !isCanVote }')
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    isCanVote() {
      return this.item.id
    }
  },

  methods: {
    openVoteConfirmDialog() {
      if (!this.isCanVote) {
        return
      }
      this._storeState('openVoteConfirmDialog')({ item: this.item })
    }
  }
}
</script>

<style></style>
