<template lang="pug">
a-button1.ui-lottery-button-wrapper(v-bind='attrs', :bg-i='images.button', bg-s='contain', :throttle='1000', @a-tap='doLottery()')
</template>

<script>
// uiMeta:
// "ui-lottery-button": {
//   "props": {
//     "lotteryConfig": {
//       "type": "",
//       "times": "",
//       // 源码参数
//       "noRequest": true, // 是否不请求 get_act_lottery_rewards 接口
//     },
//     "isOnce": false, // 是否一次性耗尽机会
//     "rewardDialogKey": "", // 奖励弹窗 key
//     "images": {
//       "button": "https://fe-center.weplayapp.com/page-center/assets/TC0dO55o/25KIBOW1.png?_w=242&_h=120"
//     },
//     "text": {
//       "chanceLack": "机会不足"
//     }
//   }
// },
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { standardTapAction } from '@sub/ui-runtime/core/utils/components/button'
import event from '@sub/event'
import { formatRewardId } from '@sub/utils/reward'

import { buildApi, concatCatch } from '@sub/utils/request'
const defaultDoLotteryUrl = '/activity_v2/lottery/do_lottery'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    // 非必传：
    // hooks
    // 在抽奖请求之前，接收参数为「抽奖按钮组件的 this」，可选返回值为「请求参数」
    beforeLotteryRequest: {
      type: Function,
      default: async () => {}
    },
    // 在抽奖返回之后，接收参数为「抽奖接口返回值」，可选返回值为「奖励列表」
    afterLotteryResponse: {
      type: Function,
      default: async () => {}
    },
    // 在奖励弹窗之后
    afterRewardDialog: {
      type: Function,
      default: async () => {}
    },

    // 搭建组件文案透传
    rootText: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isLotterying: false
    }
  },

  computed: {
    localStorage() {
      return this.$storage.localStorage()
    },
    rewardMetaMap() {
      return this.$reward.rewardMetaMap()
    },
    coin() {
      return this.$base.coin()
    },
    chipInfo() {
      return this.$base.chipInfo()
    },
    lotteryInfoMap() {
      return this.$lottery.lotteryInfoMap() || {}
    },
    // 当前可抽奖次数
    curCanTimes() {
      let { type } = this.props.lotteryConfig || {}
      return this.lotteryInfoMap[type]?.curCanTimes || 0
    },

    props() {
      return this.uiMeta.props || {}
    },
    images() {
      return this.props.images || {}
    },
    rewardDialogKey() {
      return this.props.rewardDialogKey
    },

    lotteryConfig() {
      const lotteryConfig = this.props.lotteryConfig || {}
      let { type, times } = lotteryConfig
      // 依赖 get_act_lottery_rewards 接口
      const { lotteryMaps } = this.$lottery.lotteryConfig()?.[type] || {}

      const defaultTimes = Object.keys(lotteryMaps || {})?.[0] || 1
      times = times || (this.props.isOnce && this.curCanTimes > 0 ? this.curCanTimes : defaultTimes)

      const configChipsMap = lotteryMaps?.[times]
      const defaultChipsMap = lotteryMaps?.[defaultTimes] || {}
      // FIXME: 当前只处理消耗一个碎片的情况
      const chipId = Object.keys(configChipsMap || defaultChipsMap)?.[0]
      const needChipNum = configChipsMap ? configChipsMap[chipId] : Math.ceil((defaultChipsMap[chipId] / defaultTimes) * times)

      return {
        type,
        ...lotteryConfig,
        times: Number(times),
        needChipNum: Number(needChipNum),
        chipId: Number(chipId)
      }
    },
    chipId() {
      return this.lotteryConfig.chipId
    },
    needBuyChipNum() {
      const { needChipNum } = this.lotteryConfig
      const diff = needChipNum - this.chipInfo[this.chipId]
      return diff > 0 ? diff : 0
    },

    // 文案
    text() {
      return { ...this.rootText, ...(this.props.text || {}) }
    },

    _doLottery() {
      return buildApi(this.lotteryConfig?.url || defaultDoLotteryUrl)
    },

    /**
     * 动作相关
     */
    tapActions() {
      return this.uiMeta.events?.tap || []
    },
    // 找到恭喜获得弹窗前的最后一个确认弹窗
    confirmDialogActionIndex() {
      return this.tapActions.slice(0, this.rewardDialogActionIndex).findLastIndex((i) => i.payload.subType === 'b-confirm-dialog-wrapper')
    },
    buyConfirmDialogActionIndex() {
      return this.tapActions.slice(0, this.rewardDialogActionIndex).findLastIndex((i) => i.payload.subType === 'b-buy-confirm-dialog-wrapper')
    },
    rewardDialogActionIndex() {
      return this.tapActions.findIndex((i) => i.payload.subType === 'ui-normal-reward-dialog')
    },
    hasConfirmDialog() {
      return this.confirmDialogActionIndex >= 0
    },
    hasBuyConfirmDialog() {
      return this.buyConfirmDialogActionIndex >= 0
    },
    endWithConfirmDialogIndex() {
      if (this.hasConfirmDialog || this.hasBuyConfirmDialog) {
        return Math.max(this.confirmDialogActionIndex, this.buyConfirmDialogActionIndex) + 1
      } else {
        return 0
      }
    },
    // 用于确认购买弹窗
    compState() {
      return {
        chipId: this.chipId,
        needBuyChipNum: this.needBuyChipNum,
        coin: this.coin,
        coinShorText: this.text.coinShort
      }
    }
  },

  onPageShow() {
    this.getCoinChip()
  },

  created() {
    this.getLotteryConfig()
    this.getLotteryInfo()
  },

  methods: {
    getLotteryConfig() {
      // 源码的时候传 noRequest
      if (this.lotteryConfig.noRequest) {
        this.$lottery.setState({ isReady: true })
        return
      }
      this.$lottery.getLotteryConfig()
    },
    getLotteryInfo() {
      const { type } = this.lotteryConfig
      this.$lottery.getLotteryInfo({ type })
    },
    getCoinChip() {
      this.$base.getUserCoin()
      this.$base.getChipInfo()
      this.$base.getChipTotalInfo()
    },

    async _beforeLotteryRequest() {
      const actionsRes = await standardTapAction(
        this,
        {},
        {
          tap: this.tapActions.slice(0, this.endWithConfirmDialogIndex),
          compState: this.compState
        }
      )
      if (actionsRes.some((i) => i?.break)) {
        return { break: true }
      } else {
        return {}
      }
    },

    async lotteryRequest(requestParams) {
      const { type, times } = this.lotteryConfig
      const [err, res] = await concatCatch(
        this._doLottery({
          type,
          number: times,
          auto_purchase: this.hasBuyConfirmDialog,
          ...(requestParams || {})
        })
      )
      if (err) {
        console.error('🐲 gwl ~ ui-lottery-button ~ doLottery ~ err:', err)
        return [err, null]
      }

      // update
      this.getCoinChip()
      this.$lottery.getAllLotteryInfo()
      event.emitOnLotteryComplete()

      return [null, res]
    },

    async doLottery() {
      if (!this.$lottery.isReady()) {
        console.warn('🐲 gwl ~ ui-lottery-button ~ doLottery ~ this.$lottery.isReady():', this.$lottery.isReady())
        return
      }
      if (this.isLotterying) {
        return
      }
      this.isLotterying = true

      const _beforeLotteryRequestRes = await this._beforeLotteryRequest()
      if (_beforeLotteryRequestRes?.break) {
        this.isLotterying = false
        return
      }
      // hook
      const requestParams = await this.beforeLotteryRequest(this)
      if (requestParams?.break) {
        this.isLotterying = false
        return
      }
      const [err, res] = await this.lotteryRequest(requestParams)
      if (err) {
        this.isLotterying = false
        return
      }
      // hook
      const customizeRewardList = await this.afterLotteryResponse(res)

      if (!res || !res?.length) {
        console.warn('🐲 gwl ~ ui-lottery-button ~ doLottery ~ res:', res)
        return
      }
      const rewardList =
        customizeRewardList ||
        res.map((item) => {
          const { reward_id, reward_merge_desc, reward_name, reward_amount, reward_val } = item
          const { type, img } = this.rewardMetaMap[formatRewardId(item)] || this.$propsConfig(reward_id) || {}
          return {
            img,
            type,
            // 抽奖玩法配置里需要加上 merge_in_full_name: true，即可使用 reward_merge_desc + reward_amount 模式
            reward_name: reward_merge_desc || reward_name,
            unit: this.$getNumUnit(reward_amount || reward_val)
          }
        })

      if (this.uiMeta.events?.tap) {
        try {
          await standardTapAction(
            this,
            {},
            {
              tap: this.tapActions.slice(this.endWithConfirmDialogIndex),
              openOptionsMap: {
                'ui-normal-reward-dialog': { data: { rewardList } }
              },
              compState: this.compState
            }
          )
        } catch (e) {
          console.log('🐲 gwl ~ doLottery ~ e:', e)
        } finally {
          this.isLotterying = false
        }

        // hook
        this.afterRewardDialog()
        return
      }

      if (this.rewardDialogKey) {
        await new Promise((resolve) => {
          this.$dialogs[this.rewardDialogKey]?.open({ data: { rewardList }, onclose: resolve })
        })
        this.isLotterying = false
        // hook
        this.afterRewardDialog()
        return
      }
    }
  }
}
</script>

<style></style>
