export default [
  {
    path: '/activity/v2/ambassador/main-k/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 571,
      // expire: '2024/07/01 23:59:59 UTC+8',
      pageCenter: 'w5xzfIiO'
    }
  }
]
