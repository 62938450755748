const { dynamicLinkInfo, ossHost } = require('./common')
const point = {
  appId: '17f9501e49264a04b87abed72d206898',
  serverUrl: 'https://think-client.weplayapp.com',
  enableLog: true,
  autoTrack: {
    appLaunch: true,
    appShow: true,
    appHide: true,
    pageShow: true,
    pageShare: true
  }
}

module.exports = {
  // 华语服
  C: {
    request: {
      h1: 'https://activity-dev-sgp.weplayapp.com',
      h2: 'https://admin-dev-api.weplayapp.com',
      h3: 'https://activity-dev-sgp.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+08:00',
    shareType: [9, 10, 11, 6],
    ossHost
  },

  // 美服
  U: {
    request: {
      h1: 'https://activity-dev-sv.weplayapp.com',
      h2: 'https://admin-dev-api-sv.weplayapp.com',
      h3: 'https://api-dev-sv.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['U'],
    point,
    utc: '-04:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 日服
  J: {
    request: {
      h1: 'https://activity-dev-tky.weplayapp.com',
      h2: 'https://admin-dev-api-tky.weplayapp.com',
      h3: 'https://api-dev-tky.weplayapp.com'
    },
    linkedme: '7844ae249178d2ab84f51304a39f735c',
    dynamicLink: dynamicLinkInfo['J'],
    point,
    utc: '+09:00',
    shareType: [10, 11, 6],
    ossHost
  },

  // 阿服
  A: {
    request: {
      h1: 'https://activity-dev-fra.weplayapp.com',
      h2: 'https://admin-dev-api-fra.weplayapp.com',
      h3: 'https://api-dev-fra.weplayapp.com'
    },
    linkedme: '70c3bf9fc46cdeb3e2f3515ae734c0f7',
    dynamicLink: dynamicLinkInfo['A'],
    point,
    utc: '+03:00',
    shareType: [9, 10, 11, 6],
    ossHost
  },

  // 韩服
  K: {
    request: {
      h1: 'https://activity-dev-sel.weplayapp.com',
      h2: 'https://admin-dev-api-sel.weplayapp.com',
      h3: 'https://api-dev-sel.weplayapp.com'
    },
    appsflyer: 'https://weplay-kr.onelink.me/XWAd',
    dynamicLink: dynamicLinkInfo['K'],
    point,
    utc: '+09:00',
    shareType: [9, 13, 11, 6],
    ossHost
  },
  // 泰服
  T: {
    request: {
      h1: 'https://activity-dev-bkk.weplayapp.com',
      h2: 'https://admin-dev-api-bkk.weplayapp.com',
      h3: 'https://api-dev-bkk.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+07:00',
    shareType: [9, 13, 11, 6],
    ossHost
  },

  // 马来服
  M: {
    request: {
      h1: 'https://activity-dev-mas.weplayapp.com',
      h2: 'https://admin-dev-api-mas.weplayapp.com',
      h3: 'https://api-dev-mas.weplayapp.com'
    },
    // appsflyer: 'https://weplay-kr.onelink.me/XWAd',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+08:00',
    shareType: [9, 13, 11, 6],
    ossHost
  },

  // 菲律宾服
  P: {
    request: {
      h1: 'https://activity-dev-phl.weplayapp.com',
      h2: 'https://admin-dev-api-phl.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+08:00',
    shareType: [9, 11, 6],
    ossHost
  },
  // 越南服
  V: {
    request: {
      h1: 'https://activity-dev-vnm.wejoysg.com',
      h2: 'https://admin-dev-api-vnm.wejoysg.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point: {
      ...point,
      serverUrl: 'https://think-client.wejoysg.com'
    },
    utc: '+07:00',
    shareType: [9, 11, 6],
    ossHost
  },
  // 土耳其服
  Q: {
    request: {
      h1: 'https://activity-dev-tur.weplayapp.com',
      h2: 'https://admin-dev-api-tur.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['A'],
    point,
    utc: '+03:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 葡语/巴西
  B: {
    request: {
      h1: 'https://activity-dev-br.weplayapp.com',
      h2: 'https://admin-dev-api-br.weplayapp.com',
      h3: 'https://api-dev-br.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['U'],
    point,
    utc: '-03:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 俄罗斯服
  R: {
    request: {
      h1: 'https://activity-dev-rus.weplayapp.com',
      h2: 'https://admin-dev-api-rus.weplayapp.com',
      h3: 'https://api-dev-rus.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['R'],
    point,
    utc: '+03:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 印度服
  I: {
    request: {
      h1: 'https://activity-dev-in.weplayapp.com',
      h2: 'https://admin-dev-api-in.weplayapp.com',
      h3: 'https://api-dev-in.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['I'],
    point,
    utc: '+05:30',
    shareType: [9, 11, 6],
    ossHost
  },

  // 西班牙语
  S: {
    request: {
      h1: 'https://activity-dev-va.weplayapp.com',
      h2: 'https://admin-dev-api-va.weplayapp.com',
      h3: 'https://api-dev-va.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    point,
    utc: '-03:00',
    shareType: [9, 11, 6],
    dynamicLink: dynamicLinkInfo['S']
  }
}
