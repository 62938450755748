const { dynamicLinkInfo, ossHost } = require('./common')
const point = {
  appId: 'fefb9ffb64ec4e4983cee9e30a896327',
  serverUrl: 'https://think-client.weplayapp.com',
  enableLog: false,
  autoTrack: {
    appLaunch: true,
    appShow: true,
    appHide: true,
    pageShow: true,
    pageShare: true
  }
}

module.exports = {
  // 华语服
  C: {
    request: {
      h1: 'https://activity-sgp.weplayapp.com',
      h2: 'https://admin-api.weplayapp.com',
      h3: 'https://api.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+08:00',
    shareType: [9, 10, 11, 6],
    ossHost
  },

  // 美服
  U: {
    request: {
      h1: 'https://activity-sv.weplayapp.com',
      h2: 'https://admin-api-sv.weplayapp.com',
      h3: 'https://api-sv.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['U'],
    point,
    utc: '-04:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 日服
  J: {
    request: {
      h1: 'https://activity-tky.weplayapp.com',
      h2: 'https://admin-api-tky.weplayapp.com',
      h3: 'https://api-tky.weplayapp.com'
    },
    linkedme: '7844ae249178d2ab84f51304a39f735c',
    dynamicLink: dynamicLinkInfo['J'],
    point,
    utc: '+09:00',
    shareType: [10, 11, 6],
    ossHost
  },

  // 阿服
  A: {
    request: {
      h1: 'https://activity-fra.weplayapp.com',
      h2: 'https://admin-api-fra.weplayapp.com',
      h3: 'https://api-fra.weplayapp.com'
    },
    linkedme: '70c3bf9fc46cdeb3e2f3515ae734c0f7',
    dynamicLink: dynamicLinkInfo['A'],
    point,
    utc: '+03:00',
    shareType: [9, 10, 11, 6],
    ossHost
  },

  // 韩服
  K: {
    request: {
      h1: 'https://activity-sel.weplayapp.com',
      h2: 'https://admin-api-sel.weplayapp.com',
      h3: 'https://api-sel.weplayapp.com'
    },
    appsflyer: 'https://weplay-kr.onelink.me/XWAd',
    dynamicLink: dynamicLinkInfo['K'],
    point,
    utc: '+09:00',
    shareType: [9, 13, 11, 6],
    ossHost
  },
  // 泰服
  T: {
    request: {
      h1: 'https://activity-bkk.weplayapp.com',
      h2: 'https://admin-api-bkk.weplayapp.com',
      h3: 'https://api-bkk.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+07:00',
    shareType: [9, 13, 11, 6],
    ossHost
  },
  // 马来服
  M: {
    request: {
      h1: 'https://activity-mas.weplayapp.com',
      h2: 'https://admin-api-mas.weplayapp.com',
      h3: 'https://api-mas.weplayapp.com'
    },
    // appsflyer: 'https://weplay-kr.onelink.me/XWAd',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+08:00',
    shareType: [9, 13, 11, 6],
    ossHost
  },

  // 菲律宾服
  P: {
    request: {
      h1: 'https://activity-phl.weplayapp.com',
      h2: 'https://admin-api-phl.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point,
    utc: '+08:00',
    shareType: [9, 11, 6],
    ossHost
  },
  // 越南服
  V: {
    request: {
      h1: 'https://activity-vnm.wejoysg.com',
      h2: 'https://admin-api-vnm.wejoysg.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['C'],
    point: {
      ...point,
      serverUrl: 'https://think-client.wejoysg.com'
    },
    utc: '+07:00',
    shareType: [9, 11, 6],
    ossHost
  },
  // 土耳其服
  Q: {
    request: {
      h1: 'https://activity-tur.weplayapp.com',
      h2: 'https://admin-api-tur.weplayapp.com'
    },
    linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
    dynamicLink: dynamicLinkInfo['A'],
    point,
    utc: '+03:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 葡语/巴西
  B: {
    request: {
      h1: 'https://activity-br.weplayapp.com',
      h2: 'https://admin-api-br.weplayapp.com',
      h3: 'https://api-br.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['U'],
    point,
    utc: '-03:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 俄罗斯服
  R: {
    request: {
      h1: 'https://activity-rus.weplayapp.com',
      h2: 'https://admin-api-rus.weplayapp.com',
      h3: 'https://api-rus.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['R'],
    point,
    utc: '+03:00',
    shareType: [9, 11, 6],
    ossHost
  },

  // 印度服
  I: {
    request: {
      h1: 'https://activity-in.weplayapp.com',
      h2: 'https://admin-api-in.weplayapp.com',
      h3: 'https://api-in.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    dynamicLink: dynamicLinkInfo['I'],
    point,
    utc: '+05:30',
    shareType: [9, 11, 6],
    ossHost
  },

  // 西班牙语
  S: {
    request: {
      h1: 'https://activity-va.weplayapp.com',
      h2: 'https://admin-api-va.weplayapp.com',
      h3: 'https://api-va.weplayapp.com'
    },
    linkedme: 'c1f7871271680dcfe1bda102277bc60b',
    point,
    utc: '-03:00',
    shareType: [9, 11, 6],
    dynamicLink: dynamicLinkInfo['S']
  }
}
