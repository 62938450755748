<template lang="pug">
a-section.ui-confirm-dialog-wrap
  a-dialog.ui-confirm-dialog(:name='dialogName', :bg-i='image.bg')
    a-button1.ui-confirm-dialog__close(:bg-i='image.closeBtn', slot='cancel', v-bind='{ ...uiConfirmDialog.closeBtn, ...specialStyle.closeBtn }', @a-tap='$dialogs[dialogName].close()', v-if='image.closeBtn')
    template(slot-scope='scope')
      //- 文案 具名插槽
      slot(v-if='$scopedSlots["text"]', name='text', :data='scope.data')
      template(v-else)
        a-position.ui-confirm-dialog__text(v-bind='{ ...uiConfirmDialog.text, ...specialStyle.text }', v-html='getText(scope.data)', :style='specialStyle.text')
      //- 勾选框 具名插槽
      slot(v-if='$scopedSlots["noTip"]', name='noTip', :data='scope.data')
      template(v-else)
        a-position.ui-confirm-dialog__checkbox(v-if='showNoTipCheckbox', v-bind='{ ...uiConfirmDialog.noTip, ...specialStyle.noTip }', @a-tap='toggle()', :style='specialStyle.noTip')
          a-section.ui-confirm-dialog__noConfirm(:bg-i='image[localStorage[noSecondConfirmKey] ? "noTip1" : "noTip0"]', bg-s='contain')

      //- 通用插槽
      slot(:data='scope.data')

    a-button1.ui-confirm-dialog__cancel(:bg-i='image.cancelBtn', slot='cancel', v-bind='{ ...uiConfirmDialog.cancelBtn, ...specialStyle.cancelBtn }', @a-tap='cancel()', v-if='image.cancelBtn')
    a-button1.ui-confirm-dialog__confirm(:bg-i='image.confirmBtn', slot='confirm', v-bind='{ ...uiConfirmDialog.confirmBtn, ...specialStyle.confirmBtn }', @a-tap='confirm()', v-if='image.confirmBtn')
</template>

<script>
import get from 'lodash/get'
import { styleConfig, defaultImage, defaultKey, defaultSpecialStyle } from './utils/config'
import { isInIframe } from '@sub/ui-runtime/core/iframe'
import { logData } from '@sub/ui-runtime/core/utils/logData'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      ...styleConfig
    }
  },

  computed: {
    dialogName({ uiMeta }) {
      return uiMeta?.key || 'confirmDialog'
    },
    // 图片
    image({ uiMeta }) {
      const image = get(uiMeta, 'props.image', {})
      return { ...defaultImage, ...image }
    },

    configText({ uiMeta }) {
      return get(uiMeta, 'props.text', '')
    },

    // 是否展示「不再提示此弹窗」的勾选框
    showNoTipCheckbox({ uiMeta }) {
      return get(uiMeta, 'props.showNoTipCheckbox', true)
    },
    // 勾选框缓存的key的配置
    storageKey({ uiMeta }) {
      const storageKey = get(uiMeta, 'props.storageKey', {})
      return { ...defaultKey, ...storageKey }
    },
    // 不再弹出弹窗的缓存的key
    noSecondConfirmKey() {
      return this.storageKey.noSecondConfirm
    },
    // 恢复显示弹窗的缓存的key
    restoreSecondConfirmKey() {
      return this.storageKey.restoreSecondConfirm
    },
    // 特化样式
    specialStyle({ uiMeta }) {
      const specialStyle = get(uiMeta, 'props.specialStyle', {})
      return { ...defaultSpecialStyle, ...specialStyle }
    },

    localStorage() {
      return this.$storage.localStorage()
    }
  },

  created() {
    if (isInIframe()) {
      if (this.noSecondConfirmKey === this.restoreSecondConfirmKey) {
        this.$storage.update({ [this.noSecondConfirmKey]: false })
      } else {
        this.$storage.update({ [this.noSecondConfirmKey]: false })
        this.$storage.update({ [this.restoreSecondConfirmKey]: true })
      }
    }
  },

  methods: {
    toggle() {
      this.$storage.toggle(this.noSecondConfirmKey)
      if (this.restoreSecondConfirmKey && this.noSecondConfirmKey !== this.restoreSecondConfirmKey) {
        this.$storage.update({ [this.restoreSecondConfirmKey]: !this.localStorage[this.noSecondConfirmKey] })
      }
    },

    doPoint(name, extra = {}) {
      this.$point.track('ClickH5', {
        act_id: this.$amApiParam().act_id,
        url: `${window.location.origin}${window.location.pathname}`,
        url_path: window.location.pathname,
        btn_name: name,
        title: this.$route.meta.title,
        activity: this.$base.amActivityInfo()?.activity_name || '',
        ...extra
      })
    },

    getText(scopeData = {}) {
      const textData = {
        ...(this.specialStyle.highlightText || {}),
        ...(scopeData || {})
      }
      return this.$text(scopeData?.text || this.configText, textData)
    },

    cancel() {
      this.$dialogs[this.dialogName].close()
      if (this._isRuntime) {
        logData('二次确认弹窗-取消', '')
      }
    },
    confirm() {
      this.$dialogs[this.dialogName].close()
      if (this._isRuntime) {
        logData('二次确认弹窗-确定', '')
      }
    }
  }
}
</script>

<style></style>
