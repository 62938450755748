export default [
  {
    path: '/activity/v2/progress/main-c/index.html',
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: 'He3jx2nN'
    }
  }
]
