import { isArray } from 'lodash'
import getPropUnit from './get-prop-unit'
import pageJumpMethods from './page-jump-methods'
import padFixedMethods from './pad-fixed-methods'
import pagePointReport from './page-point-report'
import propPreviewMethods from './prop-preview'
import avatarMethods from './avatar-methods'
import DOMMethods from './DOM-methods'
import textMethods from './text-methods'
import useDefer from './useDefer'

import { size } from '@/utils/set-font-size'
/**
 * @module global
 */

/**
 * 播放 SVGA 动画。
 * @param {Object} options - 播放 SVGA 动画的选项对象。
 * @param {string} options.ref - SVGA 动画组件的引用名称。
 * @param {number} [options.loop] - 指定动画播放的循环次数，默认为 1。
 * @returns {Promise<void>} 返回一个 Promise，在 SVGA 动画播放完成后解析。
 */
function playSvga({ ref, loop }) {
  return new Promise((reslove) => {
    const comp = isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref]
    if (!comp?.player) {
      reslove()
      return
    }

    comp.playSvga((player) => {
      player.set({ loop: loop || 1 })
      player._animator.onEnd = reslove
    })
  })
}
/**
 * 将 rem 单位转换为像素单位。
 * @param {number} rem - 要转换的 rem 值。
 * @param {number} size - 1 rem 对应的像素大小。
 * @returns {number} 返回转换后的像素值。
 */
function getRemToPx(rem) {
  return size * rem
}
const globalMethods = {
  $playSvga: playSvga,
  $getPropUnit: getPropUnit,
  ...propPreviewMethods,
  $getRemToPx: getRemToPx,
  ...pageJumpMethods,
  ...padFixedMethods,
  ...pagePointReport,
  ...avatarMethods,
  ...DOMMethods,
  ...textMethods,
  ...useDefer
}
/**
 * 将全局方法挂载到 Vue 实例上。
 * @param {Vue} vm - Vue 实例对象。
 * @param {Object} globalMethods - 包含全局方法的对象。
 * @returns {void}
 */
export const mountedMethods = (vm) => {
  Object.keys(globalMethods).forEach((key) => {
    vm[key] = globalMethods[key]
  })
}
