const ptConfig = {
  1: '-1.45',
  2: '0.74',
  3: '2.95'
}

const defaultConfig = {
  iconPosition: { pc: 'x', pb: '-.45' },
  textPosition: { pc: 'x', pb: '-.7' },
  default_wh: 0.89,
  icon_ps: { pr: '-.05', pb: '0' },
  a_icon_ps: { pl: '-.05', pb: '0' },
  name_ps: { pc: 'x', pt: '0.95' }
}

// 奖励展示每个盒子相关配置
const rechargeGiftList = [
  {
    bg_num: 1,
    domPosition: { pl: '.6', pt: ptConfig[1] }
  },
  {
    bg_num: 2,
    domPosition: { pl: '2', pt: ptConfig[1] }
  },
  {
    bg_num: 2,
    domPosition: { pl: '4.5', pt: ptConfig[1] }
  },
  {
    bg_num: 3,
    domPosition: { pl: '3.6', pt: ptConfig[2] }
  },
  {
    bg_num: 3,
    domPosition: { pl: '.5', pt: ptConfig[2] }
  },
  {
    bg_num: 4,
    domPosition: { pl: '.8', pt: ptConfig[3] }
  }
]

const pt = '.09'
// 奖励展示配置
// id_idx: 奖励id序号 wh: 奖励盒子宽高大小 bg_config icon_ps: 奖励角标定位 name_ps: 奖励名定位
const exhibitRewardList = [
  [{ list: [{ id_idx: 1 }], bg_config: { pc: 'x', pt } }],
  [
    { list: [{ id_idx: 2 }], bg_config: { pl: '.17', pt } },
    { list: [{ id_idx: 3 }], bg_config: { pr: '.18', pt } }
  ],
  [
    { list: [{ id_idx: 4 }], bg_config: { pl: '.17', pt } },
    { list: [{ id_idx: 5 }], bg_config: { pr: '.18', pt } }
  ],
  [
    {
      wh: 0.66,
      list: [{ id_idx: 6, wh: 1.5, pc: 'pc' }],
      bg_config: { pl: '.54', pt: 0.21, 'bg-c': '#F8F9FF', bdr: '50%' },
      icon_ps: { pr: '-.3', pb: '-.1' },
      name_ps: { pc: 'x', pt: '.83' }
    },
    { list: [{ id_idx: 7 }], bg_config: { pr: '.4', pt } }
  ],
  [
    {
      wh: 0.66,
      list: [{ id_idx: 8, wh: 1.5, pc: 'pc' }],
      bg_config: { pl: '.54', pt: 0.21, 'bg-c': '#F8F9FF', bdr: '50%' },
      icon_ps: { pr: '-.3', pb: '-.1' },
      name_ps: { pc: 'x', pt: '.83' }
    },
    { list: [{ id_idx: 9 }], bg_config: { pr: '.4', pt } }
  ],
  [
    { list: [{ id_idx: 10 }], bg_config: { pl: '.9', pt: '.14' } },
    { list: [{ id_idx: 11 }], bg_config: { pl: '2.55', pt: '.14' } },
    { list: [{ id_idx: 12 }], bg_config: { pr: '.85', pt: '.14' } }
  ]
]

export default {
  defaultConfig,
  rechargeGiftList,
  exhibitRewardList
}
