let bindResize = false
let size

import * as iframe from '@sub/ui-runtime/core/iframe'
import getQuery from '@sub/ui-runtime/core/utils/getQuery'
import { IS_MOBILE } from '@/utils/env'

export default function setFontSize(designWidth = 750, clientWidth = 0) {
  if (!bindResize) {
    bindResize = true
    window.addEventListener('resize', () => setFontSize(designWidth))
  }

  if (!clientWidth) {
    let match = window.matchMedia('(orientation:portrait)')
    //竖屏
    if (match.matches) {
      clientWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth || window.screen.width
    } else {
      //横屏
      let clientWidth1 = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth || window.screen.width
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight || window.screen.height
      clientWidth = Math.min(clientWidth1, clientHeight)
    }
  }

  size = (clientWidth / designWidth) * 100

  if (!IS_MOBILE) {
    size = 65
    document.body.style.width = designWidth / 100 + 'rem'
    document.body.style.margin = '0 auto'
    document.body.style.backgroundColor = '#fff'
    document.documentElement.style.backgroundColor = '#eee'
  } else if (clientWidth >= 744) {
    size = 65
    document.body.style.width = designWidth / 100 + 'rem'
    document.body.style.margin = '0 auto'
  }

  document.documentElement.style.fontSize = size + 'px'

  // 插入一个 div 来确定是否是期望大小，主要解决移动端字体大小修改的问题
  const div = document.createElement('div')
  div.style.width = '1rem'
  div.style.height = '0'
  document.body.appendChild(div)

  const rmd = div.clientWidth / size
  if (rmd > 1.05 || rmd < 0.95) {
    size = size / rmd
    document.documentElement.style.fontSize = size + 'px'
  }

  document.body.removeChild(div)

  if (iframe.isInIframe()) {
    size = 50
    if (getQuery().iframePageType === 'dialog') {
      document.body.style.width = '100%'
    }
    document.documentElement.style.fontSize = size + 'px'
  }
}

export { size }
