<template lang="pug">
a-position.fx-m-between.fx-c-center.fx-row.bar-contain.ui-navigation-bar-wrapper(:class='{ ltr: !_isAr }', wh='7.5 .88', p='fixed', :pz='pz || 1000', :pt='_isInVoice ? 0.24 : headerBarHeight', pdl='.32', pdr='.32')
  tip

  //- 返回按钮
  a-section.ui-navigation-bar-back-wrapper(v-if='showBack', :class='{ rotate: _isA && !_isAr }', wh='.6', @click='onBack', bg-s='contain')
    a-img.ui-navigation-bar__backImg(:src='backImg', pc, wh='100%')
    a-section(wh='.88')
  a-section(v-else, wh='.6')
  //- 音效控制
  a-position.ui-navigation-bar-audio-wrapper(v-if='$scopedSlots["audio"]', wh='.6', :pr='_isAr ? 5.69 : 1.24', pc='y')
    slot(name='audio')
  //- 刷新按钮
  a-section.ui-navigation-bar-refresh-wrapper(v-show='showRefresh', wh='.6', @a-tap='onRefresh', bg-s='contain')
    a-img.ui-navigation-bar__refreshBg(:src='assetsConfig[refreshBg] || defaultImage.refresh', pc, wh='100%')
  slot
</template>

<script>
import { headerBarHeightMixin } from '@/utils/mixins'
import { defaultImage, toastTxt } from './utils/config'
import tip from './tip.vue'

export default {
  components: { tip },

  mixins: [headerBarHeightMixin],

  props: {
    showRefresh: {
      type: Boolean,
      default: true
    },

    backBg: {
      type: String,
      default: ''
    },

    refreshBg: {
      type: String,
      default: ''
    },

    pz: {
      type: Number,
      default: 1000
    }
  },

  data() {
    return {
      defaultImage
    }
  },

  computed: {
    assetsConfig() {
      return this.$route.meta.assets
    },

    backImg({ backBg, assetsConfig }) {
      // weplay
      if (this._isWeplay) {
        return assetsConfig[backBg] || (this._isA ? defaultImage.backRight : defaultImage.backLeft)
        // 会玩
      } else {
        return assetsConfig[backBg] || defaultImage.backLeft
      }
    },

    showBack() {
      const { showBack } = this.$route.query
      // weplay
      if (this._isWeplay) {
        return this.$route.query.head === '0' && showBack !== '0'
        // 会玩
      } else {
        return this.$route.query.activity === '1' && (!this._isApp || this.$we.compareAppVersion('5.15.4') !== '<') && showBack !== '0'
      }
    }
  },

  async mounted() {
    if (!this._isWeplay && this.showBack) {
      this.$we('hideNavigationBar')
    }

    this.initBarHeight()
  },

  beforeDestroy() {
    if (!this._isWeplay) {
      this.$we('showNavigationBar')
    }
  },

  methods: {
    onBack() {
      this.$emit('back')
    },

    onRefresh() {
      this.$emit('refresh')
      toastTxt && this.$toast(toastTxt)
    }
  }
}
</script>

<style lang="scss" scoped>
.bar-contain {
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  .ltr {
    direction: ltr !important;
  }

  .rotate {
    transform: rotate(180deg);
  }
}
</style>
