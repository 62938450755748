import weSDK from '@wepie/we-sdk'
import { propPreviewPoint } from '@/utils/global-point'
import config from '@/config'
import toast from '@/lib/page-toast'
import * as iframe from '@sub/ui-runtime/core/iframe'

export function install(app) {
  app.use(weSDK, {
    version: 2,
    shortcut: {
      preview: (v) => ({ method: 'previewProp', params: v }), // 预览道具
      userinfo: (v) => ({ method: 'jumpUserInfo', params: v }), // 跳转用户信息
      minigame: (v) => ({ method: 'jumpGame', params: v }), // 跳转小游戏
      jump: (v) => ({ method: 'jumpDeepLink', params: `wespydeeplink://${v}` }), // app页面跳转
      pay: () => ({ method: 'jumpDeepLink', params: 'wespydeeplink://pay' }) // 打开充值页面
    },
    callBack: {
      previewProp: propPreviewPoint
    },
    onError: (e) => {
      if (iframe.isInIframe()) {
        return {}
      }

      if (config.$env.DEV) {
        toast({ 1001: '请在 App 内部打开', 1002: '当前版本过低，请升级 app 版本到最新版本' }[e.code])
      }
      return {}
    }
  })
}

export default weSDK
