import weSDK from '@/lib/we-sdk'
import { IS_APP, IS_WEPLAY, IS_DEV, PAGE_REGION } from '@/utils/env'
import { isProduction } from '@sub/utils/switchEnvRequest'
import { buildApi1 } from '@sub/utils/request'
import { getQuery1 } from '@/utils/utils'
// import oss from '@sub/utils/upload-file'

const checkDebugCode = buildApi1('/activity_v2/common/check_debug_code')
function loadScript(url) {
  let script = document.createElement('script')

  return new Promise((reslove) => {
    script.onload = function () {
      reslove()
    }

    document.getElementsByTagName('head')[0].appendChild(script)
    script.src = url
  })
}

export const pageSpyKey = getQuery1().pageSpyKey

const pageSpyUrl = IS_WEPLAY ? 'https://weplay-pagespy.weplayapp.com/page-spy/index.min.js' : 'https://huiwan-pagespy.wepieoa.com/page-spy/index.min.js'
// const dataHarborUrl = 'https://weplay-pagespy.weplayapp.com/plugin/data-harbor/index.min.js'
export async function pageSpyInit() {
  try {
    if (IS_APP) {
      // 线上环境校验
      if (isProduction) {
        const isLegal = await checkDynamicKey()
        if (!isLegal) {
          return
        }
      }

      // const [userInfo] = await Promise.all([weSDK('getUserInfo'), loadScript(pageSpyUrl), loadScript(dataHarborUrl)])
      const [userInfo] = await Promise.all([weSDK('getUserInfo'), loadScript(pageSpyUrl)])

      /* eslint-disable */
      // PageSpy.registerPlugin(
      //   new DataHarborPlugin({
      //     async onDownload(data) {
      //       const res = await oss.ossUpload(
      //         new Blob([JSON.stringify(data)], {
      //           type: 'application/json'
      //         })
      //       )
      //       console.warn('data :', res)
      //     }
      //   })
      // )

      // eslint-disable-next-line
      window.$pageSpy = new PageSpy({
        autoRender: Boolean(getQuery1().showPanel),
        project: window.location.pathname,
        title: `${PAGE_REGION}-${IS_DEV ? 'dev' : isProduction ? 'prod' : 'pre'}-${userInfo.uid}`
      })
    }
  } catch (error) {
    console.error(error)
  }
}

async function checkDynamicKey() {
  const userInfo = await weSDK('getUserInfo')
  const res = await checkDebugCode({ act_id: getQuery1().act_id, code: pageSpyKey, uid: userInfo.uid })
  return res
}
