import { IS_DEV, IS_APP } from '@/utils/env'
import config from '@/config'
import { pageLoadPointHandle } from '@/utils/global-point'

class MyWebVitals {
  meta = {}
  maxDuration = 0
  lastDuration = 0
  observerINPIns = null

  async init(meta) {
    this.meta = meta
    if (IS_DEV && IS_APP && !config.$env.LOCAL) {
      this.observerINP()
      this.reportINP()
    }
  }

  observerINP() {
    document.addEventListener('touchend', (event) => {
      const delay = performance.now() - event.timeStamp
      if (delay > this.maxDuration) {
        this.maxDuration = delay
      }
    })
  }

  reportINP() {
    setInterval(() => {
      if (this.maxDuration && this.maxDuration > this.lastDuration) {
        this.lastDuration = this.maxDuration
        pageLoadPointHandle(this.meta, this.maxDuration, 'INP')
      }
    }, 30000)
  }
}

export default new MyWebVitals()
