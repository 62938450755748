import { PAGE_REGION, IS_WEPLAY } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

const config = {
  broadcastItemStyle: {
    f: '.24 #fff 600',
    h: '0.38',
    lh: '0.38'
    // background: 'linear-gradient(90deg, #8e98f1 2.92%, #8e98f8 49.6%, #616ef3 97.26%)',
    // bdr: '.28',
    // pdr: '0.2',
    // pdl: '.3'
  },
  highlightColor: '#ffd233',
  nicknameSliceLen: '5',
  request: {
    api: '/activity_v1/common/get_broadcast_info',
    params: {}
  },
  event: 'jumpUserInfo',
  contents: [
    {
      type: 'text',
      event: '',
      style: {},
      // content: '恭喜{{nickname}}获得{{reward_name}}~'
      content: ''
    }
    // {
    //   type: 'avatar',
    //   style: { wh: 0.4 },
    //   url: '{{headimgurl}}',
    //   uid: '{{uid}}'
    // },
    // {
    //   type: 'image',
    //   event: '',
    //   style: { wh: 0.4 },
    //   src: '$headimgurl$'
    // }
  ]
}

const text = proxyAssets({
  hw: {
    broadcastIcon: 'https://fe-center.weplayapp.com/page-center/assets/5uxjz6CV/pkWd4WnS.png?_w=62&_h=38',
    text: '恭喜<span style="color: {{}}">{{}}</span>获得<span style="color: {{}}">{{}}</span>~'
  },
  C: {
    broadcastIcon: 'https://fe-center.weplayapp.com/page-center/assets/5uxjz6CV/pkWd4WnS.png?_w=62&_h=38',
    text: '恭喜<span style="color: rgb(255, 210, 51);">{{}}</span>獲得<span style="color: rgb(255, 210, 51);">{{}}</span>～'
  },
  J: {
    broadcastIcon: 'https://fe-center.weplayapp.com/page-center/assets/5uxjz6CV/pkWd4WnS.png?_w=62&_h=38',
    text: 'おめでとう！<span style="color: {{}}">{{}}</span>は<span style="color: {{}}">{{}}</span>を獲得！'
  },
  K: {
    broadcastIcon: 'https://fe-center.weplayapp.com/page-center/assets/5uxjz6CV/pkWd4WnS.png?_w=62&_h=38',
    text: 'おめでとう！<span style="color: rgb(255, 210, 51);">{{}}</span>は<span style="color: rgb(255, 210, 51);">{{}}</span>を獲得！'
  },
  A: {
    broadcastIcon: 'https://fe-center.weplayapp.com/page-center/assets/5uxjz6CV/pkWd4WnS.png?_w=62&_h=38',
    text: 'تهانينا لـ<span style="color: {{}}">{{}}</span> حصول على <span style="color: {{}}">{{}}</span>'
  },
  U: {
    text: 'Congrats <span style="color: {{}}">{{}}</span> on winning <span style="color: {{}}">{{}}</span>'
  }
})
export const defaultConfig = IS_WEPLAY ? text[PAGE_REGION] : text.hw

export default config
