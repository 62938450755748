import { isA } from '../../utils/config'
import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'

const prl = isA ? 'pr' : 'pl'

export const lotteryStyleConfig = {
  coinStyle: {
    wrap: { wh: '2.32 .56', pt: 0.2, [prl]: 0.48 },
    count: { [prl]: 0.55, pt: 0.14, fs: '.24', fw: '600', wh: '1.3 .27', lh: 0.27 }
  },
  lotteryChipStyle: {
    wrap: { wh: '2.42 .56', pt: 0.2, [prl]: 4.6 },
    count: { [prl]: 0.58, pt: 0.14, fs: '.24', fw: '600', wh: '1.3 .27', lh: 0.27 }
  },
  rewardWrapStyle: { wh: '6.2 7.4', pc: 'x', pt: 1.1 },
  rewardItem: { wh: 1.8 }
}

export const lotteryButtonStyleConfig = {
  wrap: { p: 'fixed', pb: 0 },
  lotteryLogWrap: { wh: '3 .31', pl: 0, pt: 0.1 },
  rewardDetailWrap: { wh: '3 .31', pr: 0, pt: 0.1 },
  recoverSelect: { wh: 0.28, pl: 1.016, pb: 0.396 },
  ignoreAnimeSelect: { wh: 0.28, pl: 4.736, pb: 0.386 },
  buttonWrap: (num) => {
    const style = { 2: { wh: '6.2 1.2', btnWh: ['2.74 1.2', '3.26 1.2'] }, 3: { wh: '7.38 1.2', btnWh: ['2.42 1.2', '2.42 1.2', '2.42 1.2'] } }
    return { ...style[num], pb: '.84', pc: 'x' }
  }
}

const commonBtnStyleLeft = { pb: 0.74, pl: 0.69, 'bg-s': 'contain' }
const commonBtnStyleRight = { pb: 0.74, pr: 0.69, 'bg-s': 'contain' }

export const confirmStyleConfig = {
  textWrap: { wh: '5.25 .39', pc: 'x', pt: 1.28, fs: 0.28, fw: 500, lh: 0.38 },
  tipWrap: { wh: '5 .36', pc: 'x', pt: 2.23, fs: 0.22, fw: 400 },
  cancelButton: commonBtnStyleLeft,
  confirmButton: commonBtnStyleRight
}

export const textConfig = {
  hw: ({ color, lotteryCoinName }) => ({
    // confirmText: `需要花费<span style="color:${color}">{{}}金币</span>购买<span style="color:${color}">{{}}个${lotteryCoinName}</span>继续抽奖吗？`,
    // goldLack: '金币不足',
    // chipLack: '碎片不足，无法继续抽奖。'
  }),
  C: ({ color, lotteryCoinName }) => ({
    // confirmText: `需要花费<span style="color:${color}">{{}}金币</span>购买<span style="color:${color}">{{}}个${lotteryCoinName}</span>继续抽奖吗？`,
    // goldLack: '金币不足',
    // chipLack: ''
  }),
  J: ({ color, lotteryCoinName }) => ({
    // confirmText: `需要花费<span style="color:${color}">{{}}金币</span>购买<span style="color:${color}">{{}}个${lotteryCoinName}</span>继续抽奖吗？`,
    // goldLack: '金币不足',
    // chipLack: ''
  }),
  K: ({ color, lotteryCoinName }) => ({
    // confirmText: `需要花费<span style="color:${color}">{{}}金币</span>购买<span style="color:${color}">{{}}个${lotteryCoinName}</span>继续抽奖吗？`,
    // goldLack: '金币不足',
    // chipLack: ''
  }),
  A: ({ color, lotteryCoinName }) => ({
    // confirmText: `需要花费<span style="color:${color}">{{}}金币</span>购买<span style="color:${color}">{{}}个${lotteryCoinName}</span>继续抽奖吗？`,
    // goldLack: '金币不足',
    // chipLack: ''
  }),
  M: ({ color, lotteryCoinName }) => ({
    confirmText: `Perlu membelanjakan <span style="color:${color}">{{}} syiling emas</span> membeli <span style="color:${color}">{{}} ${lotteryCoinName} </span> untuk teruskan cabutan?`,
    goldLack: 'Syiling emas tidak cukup',
    chipLack: ''
  }),
  ms: ({ color, lotteryCoinName }) => ({
    confirmText: `Membelajankan <span style="color:${color}">{{}} koin </span> membeli <span style="color:${color}">{{}} ${lotteryCoinName}</span> untuk terus loteri？`,
    goldLack: 'koin tidak cukup',
    chipLack: ''
  }),
  id: ({ color, lotteryCoinName }) => ({
    confirmText: `Membelajankan <span style="color:${color}">{{}} koin </span> membeli <span style="color:${color}">{{}} ${lotteryCoinName}</span> untuk terus lotre？`,
    goldLack: 'koin tidak cukup',
    chipLack: ''
  }),
  T: ({ color, lotteryCoinName }) => ({
    confirmText: `ยืนยันใช้ <span style="color:${color}">{{}} เหรียญทอง</span>เพื่อซื้อ <span style="color:${color}">{{}} ${lotteryCoinName} </span> และจับรางวัลต่อไหม? `,
    goldLack: 'เหรียญทองไม่เพียงพอ',
    chipLack: 'เพชรไม่พอแล้ว ไม่สามารถจับรางวัลต่อได้'
  }),
  U: ({ color, lotteryCoinName }) => ({
    confirmText: `Spend <span style="color:${color}">{{}} Gold </span> for <span style="color:${color}">{{}} ${lotteryCoinName}</span> to continue？`,
    goldLack: 'Low on Gold~',
    chipLack: 'Insufficient Pieces~'
  }),
  P: ({ color, lotteryCoinName }) => ({
    confirmText: `Spend <span style="color:${color}">{{}} Gold </span> for <span style="color:${color}">{{}} ${lotteryCoinName}</span> to continue？`,
    goldLack: 'Low on Gold~',
    chipLack: 'Insufficient Pieces~'
  }),
  Q: ({ color, lotteryCoinName }) => ({
    confirmText: `<span style="color:${color}">{{}} Altın</span> harcayarak, <span style="color:${color}">{{}} adet ${lotteryCoinName}</span> satın alıp, çekilişe devam etmek ister misiniz?`,
    goldLack: 'Yetersiz Altın',
    chipLack: ''
  }),
  V: ({ color, lotteryCoinName }) => ({
    confirmText: `Cần chi tiêu <span style="color:${color}">{{}} xu </span> để mua <span style="color:${color}">{{}} ${lotteryCoinName}</span> bạn có muốn tiếp tục bốc thăm？`,
    goldLack: 'Không đủ xu',
    chipLack: ''
  })
}

export const genLotteryTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw
