<template lang="pug">
a-section.ui-template-exchange(v-bind='attrs')
  //- 兑换列表展示
  a-section(:bg-i='image.exchangeBg')
    //- 兑换碎片
    a-position.ui-template-exchange__chip(v-bind='exchangeStyleConfig.chipStyle.wrap')
      a-position.ui-template-exchange__count.ws-nowrap(v-bind='exchangeStyleConfig.chipStyle.count', :fc='textStyle.exchangeChipColor') ：{{ chipInfo.chipCount || 0 }}
    //- 记录
    a-position.ui-template-exchange__record(v-bind='exchangeStyleConfig.exchangeLogWrap', @a-tap='openRecord')

    span(:data-var='(rewardStyle = exchangeStyleConfig.rewardStyle(rewardIds.length, colsCount))')
    //- 奖励wrap
    a-position.fx-row.fx-wrap(v-bind='rewardStyle.wrap')
      a-section(v-for='(r, i) in rewards', :key='i', v-bind='{ ...rewardStyle.item, ...calculateMargin(i, colsCount) }')
        a-section.ui-template-exchange__reward(v-bind='rewardStyle.reward', @a-tap='onPreview(r)')
          ui-prop(wh='105%', pc, :data='r', :extra='{ canPreview: false }')
        a-position(v-if='checkReceived && r.act_extra && r.act_extra.own_permanent', v-bind='rewardStyle.btn', :bg-i='image.receivedBtn')
        a-button1.ui-template-exchange__btn(v-else-if='chipInfo.chipCount >= r.cost', v-bind='rewardStyle.btn', :bg-i='image.exchangeBtn', @a-tap='onExchange(r)', :throttle='1000', :class='{ permanent: checkReceived && r.act_extra && r.act_extra.is_permanent }')
          a-position.fx-row.fx-c-center.fx-m-center(wh='100%', pc='x', pt='-.02')
            a-section.ui-template-exchange__icon(:bg-i='image.exchangeChipIcon', wh='.3', bg-s='100%', ml='.02', mr='.02')
            a-section(:fc='textStyle.exchangeButtonNumColor') {{ getNumUnit(r.cost) }}
        a-position.ui-template-exchange-grey.grey(v-else, v-bind='rewardStyle.btn', :bg-i='image.exchangeBtn', @a-tap='toastNotEnough')
          a-position.ui-template-exchange__wrap.fx-row.fx-c-center.fx-m-center(wh='100%', pc='x', pt='-.02')
            a-section.ui-template-exchange-chip__icon(:bg-i='image.exchangeChipIcon', wh='.3', bg-s='100%', ml='.02', mr='.02')
            a-section.ui-template-exchange__num--color(:fc='textStyle.exchangeButtonNumColor') {{ getNumUnit(r.cost) }}
</template>

<script>
import get from 'lodash/get'
import { getNumUnit } from '@/utils/utils'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { exchangeStyleConfig, calculateMargin } from './utils/exchangeConfig'

export default {
  mixins: [attrs],

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      exchangeStyleConfig
    }
  },

  computed: {
    checkReceived({ uiMeta }) {
      return get(uiMeta, 'props.checkReceived', false)
    },
    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    rewards({ uiMeta, rewardIds }) {
      const rewards = get(uiMeta, 'props.rewards', [])
      if (this._isWeplay) {
        return rewards
      }
      return rewardIds.map((id) => rewards.find((r) => r.reward_id === id) || {})
    },

    rewardIds({ uiMeta }) {
      return get(uiMeta, 'props.rewardIds', [])
    },

    // 列数
    colsCount({ uiMeta, rewardIds }) {
      return get(
        uiMeta,
        'props.colsCount',
        // 用 rewardIds.length 兜底存量
        rewardIds.length % 4 === 0 ? 4 : 3
      )
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.text.style', {})
    },

    textWords({ uiMeta }) {
      return get(uiMeta, 'props.text.words', {})
    },

    exchangeConfirmKey({ uiMeta }) {
      return get(uiMeta, 'exchangeConfirmKey', '')
    },

    exchangeLogKey({ uiMeta }) {
      return get(uiMeta, 'exchangeLogKey', '')
    },

    chipId({ uiMeta }) {
      return get(uiMeta, 'props.chipId', 1)
    },

    chipInfo() {
      return {
        chipCount: this.$store.state.base.chipInfo[this.chipId]
      }
    },

    storeName({ uiMeta }) {
      return get(uiMeta, 'props.storeName', '')
    }
  },

  created() {},

  mounted() {
    this.updateChipInfo()
  },

  methods: {
    getNumUnit,
    calculateMargin,
    updateChipInfo() {
      this.$base.getUserCoin()
      this.$base.getChipInfo()
    },

    toastNotEnough() {
      const { notEnough } = this.textWords
      if (notEnough) this.$toast(notEnough)
    },

    openRecord() {
      this.$record.openRecord()[this.exchangeLogKey]()
    },

    onExchange(data) {
      if (this.chipInfo.chipCount < data.cost) {
        return
      }

      this.$dialogs[this.exchangeConfirmKey].confirm({
        data: {
          ...data,
          // 角标文案
          // unit: !isNaN(+data.reward_amount_desc) ? getNumUnit(data.reward_amount_desc) : data.reward_amount_desc, // 阿语服123x
          // 奖励id
          id: data.reward_id,
          exchangeNum: 1,
          maxNum: Math.min(Math.floor(this.chipInfo.chipCount / data.cost), 999),
          storeName: this.storeName
        }
      })
    },

    onPreview(data) {
      this.$propPreview(data.reward_id, { data })
    }
  }
}
</script>

<style lang="scss" scoped>
.permanent::after {
  content: '';
  position: absolute;
  top: 0;
  right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: red;
}
</style>
