const pageCenter = {
  ms: 'o9RoKns4',
  id: 'EPbh5B6b'
}

export default [
  {
    path: '/activity/v2/voice-center/main-m/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[0]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/detail-m/index.html',
    component: () => import('./detail.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[1]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/preview-m/index.html',
    component: () => import('./preview.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[2]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/create-m/index.html',
    component: () => import('./create.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[3]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/task-m/index.html',
    component: () => import('./task.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[4]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/apply-m/index.html',
    component: () => import('./apply.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[5]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/raiders-m/index.html',
    component: () => import('./raiders.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[6]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/share-m/index.html',
    component: () => import('./share.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[7]
      },
      pageCenter,
      isExternalPage: true
    }
  },

  {
    path: '/activity/v2/voice-center/creator-m/index.html',
    component: () => import('./creator.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[8]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/rate-m/index.html',
    component: () => import('./rate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[9]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/illustrate-m/index.html',
    component: () => import('./illustrate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[10]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/explain-m/index.html',
    component: () => import('./explain.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[11]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/auxiliary-m/index.html',
    component: () => import('./auxiliary.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[12]
      },
      pageCenter
    }
  }
]
