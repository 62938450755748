<template lang="pug">
  //- a-position#activity-team-info.txt-c(v-bind="attrs" wh='7.5 3.35')
  a-position.txt-c(:bg-i="image.teamInfoBg" pc bg-s="contain")
    //- 编辑队名
    a-position.fx-row.fx-m-center.fx-c-center.of-h(v-bind="teamInfoStyleConfig.teamNameWrap" :fc="textConfig.teamNameColor" @a-tap="openEdit")
      a-section(mt=".04") {{ teamState.teamName }}
      a-section(:bg-i="image.editIcon")
    //- 队伍信息
    a-position.fx-row.fx-m-between(v-bind="teamInfoStyleConfig.teamMembers.wrap")
      a-section(v-for="(t, i) in teamMates" :key="i" v-bind="teamInfoStyleConfig.teamMembers.item")
        a-position.txt-no-wrap.fx-row.fx-c-center.fx-m-center.border-box(v-bind="teamInfoStyleConfig.teamMembers.roleWrap" :bg-c="roleWrapStyle.bgColor" :fc='textConfig.roleColor' :b="`.01rem solid ${roleWrapStyle.borderColor}`") {{ textConfig[`role${i+1}`] }}
        a-avatar(v-if="t.uid" :uid="t.uid" v-bind="teamInfoStyleConfig.teamMembers.avatar" :bg-i="t.head_img_url" :b="`.02rem solid ${avatarStyle.borderColor}`")
        a-position(v-else v-bind="teamInfoStyleConfig.teamMembers.avatar" :bg-i="image.inviteFriends" @a-tap="openSearchList")
        a-position.ellipsis(v-bind="teamInfoStyleConfig.teamMembers.nickname" :fc='textConfig.nicknameColor') {{ t.nickname }}
        a-position.fx-row.fx-m-center.fx-c-center(v-bind="teamInfoStyleConfig.teamMembers.scoreWrap")
          a-section(:fc='textConfig.scoreColor') {{ t.score }}
          a-section(:bg-i="image.scoreIcon")
</template>

<script>
import get from 'lodash/get'
import { getters2Computed } from '@sub/store/utils'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { teamInfoStyleConfig } from './utils/config'
import { buildApi } from '@/utils/request'
import event from '@sub/event'

const getMineInfo = buildApi('/activity_v2/rank/rank_list')

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      teamInfoStyleConfig,
      rankInfo: []
    }
  },

  computed: {
    ...getters2Computed('team'),

    teamState() {
      return this.$store.state.team
    },

    contact() {
      return this.$store.state.contact
    },

    amBasic() {
      return this.$store.state.base.amBasic
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    textConfig({ uiMeta }) {
      return get(uiMeta, 'props.textConfig', {})
    },

    roleWrapStyle({ uiMeta }) {
      return get(uiMeta, 'props.roleWrapStyle', {})
    },

    avatarStyle({ uiMeta }) {
      return get(uiMeta, 'props.avatarStyle', {})
    },

    teamMates({ teamMembers, rankInfo }) {
      const teamScoreList = teamMembers
        .map((item) => {
          return {
            ...item,
            score: rankInfo.find((u) => +u.member === +item.uid)?.score || 0
          }
        })
        .sort((a, b) => b.score - a.score)
      const cap = teamScoreList.filter((item) => item.is_leader)
      const other = teamScoreList.filter((item) => +cap[0]?.uid !== +item.uid)
      return [...cap, ...other]
    }
  },

  watch: {
    'teamState.teamInfo': {
      handler(val) {
        if (val?.team_id) {
          this.getTeamInfoScore()
        }
      },
      immediate: true
    }
  },

  created() {},

  async mounted() {
    event.onTeamInfoChange(this.getTeamInfoScore)
  },

  methods: {
    openEdit() {
      this.$store.state.team.openEdit()
    },

    openSearchList() {
      this.$store.state.team.openSearchList()
    },

    async getTeamInfoScore() {
      const amApiParam = this.$store.getters['base/amApiParam']
      const teamId = this.teamState.teamInfo.team_id
      const res = await getMineInfo({
        ...amApiParam,
        rank_type: 1,
        rank_type_extra: teamId,
        rank_extra_member_type: 0,
        period_type: 0,
        limit: 10
      })

      this.rankInfo = res.list
    }
  }
}
</script>

<style lang="scss" scoped>
.border-box {
  box-sizing: border-box;
}
</style>
