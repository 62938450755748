export default [
  {
    path: '/activity-20230301-main',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
        to.meta.expire = to.meta.params.expire
      },
      quickConfig: '4987d6c37ef54920601c8c5e0040a924'
    }
  }
]
