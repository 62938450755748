import { getCurrentIssue, year, pageCenter } from './utils/issueOrderMap'

export default [
  {
    path: `/k/${year}/${getCurrentIssue}`,
    component: () => import('./index.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter
    }
  }
]
