<template lang="pug">
a-position#activity-team.ui-team-2-members.txt-c(:fc='textConfig.commonTextColor', fw='500', fs='.24', :class='genBEMClass()')
  //- 首次进入弹窗
  a-dialog.ui-team-2-members-default-wrap.defaultWrapperStyle(name='uiTeamInviteFriends', :bg-i='dialogImage.inviteFriends', :fc='textConfig.commonTextColor')
    slot(name='uiTeamInviteFriends')

    a-position.ui-team-2-members-default__wrap-team(v-bind='uiTeamInviteFriends.teamMembersWrap', :class='genBEMClassInviteFriends({ e: "team-members-wrapper" })')
      a-avatar(v-bind='uiTeamInviteFriends.avatarStyle', :bg-i='teamMembers[0].head_img_url', :uid='teamMembers[0].uid', :class='genBEMClassInviteFriends({ e: "user-avatar" })')
        a-position.ui-team-2-members-default__wrap-avatar(wh='100%', pc, :bg-i='avatarImage.avatarBorder', :class='genBEMClassInviteFriends({ e: "avatar-border" })')
      a-position.ui-team-2-members-default__wrap-name(v-bind='uiTeamInviteFriends.nickname', :fc='textConfig.commonNicknameColor', :class='genBEMClassInviteFriends({ e: "user-nickname" })') {{ $ts(teamMembers[0].nickname, teamRegionConfig.nicknameLength) }}
    a-position(wh='1.5 2', pt='2.52', pr='1.33')
    ui-button.ui-team-2-members-default__wrap-cancel(:bg-i='buttonImage.look', bg-s='contain', v-bind='uiTeamInviteFriends.lookBtn', :class='genBEMClassInviteFriends({ e: "btn-look" })', @a-tap='$dialogs.uiTeamInviteFriends.close()')
    ui-button.ui-team-2-members-default__wrap-confirm(:bg-i='buttonImage.inviteFriends', bg-s='contain', v-bind='uiTeamInviteFriends.inviteFriends', :class='genBEMClassInviteFriends({ e: "btn-invite-friends" })', @a-tap='getInviteStatus')

  //- 邀请好友弹窗
  a-dialog.defaultWrapperStyle(name='uiTeamSearchContacts', :bg-i='dialogImage.searchContacts', :fc='textConfig.commonTextColor')
    slot(name='uiTeamSearchContacts')
    a-position(:bg-i='buttonImage.close', v-bind='{ ...uiTeamSearchContacts.closeBtn, ...getCustomizeStyle("closeStyle") }', @a-tap='$dialogs.uiTeamSearchContacts.close()')

    //- 搜索框
    a-position.ui-team-2-members__invite-search(v-bind='uiTeamSearchContacts.searchInputStyle.wrap', :class='genBEMClassSearchContacts({ e: "search-input-wrapper" })')
      input.ui-team-2-members__invite-input.input(v-model='searchVal', :class='genBEMClassSearchContacts({ e: "search-input" })', :style='{ ...uiTeamSearchContacts.searchInputStyle.input, "--color": textConfig.inputColor }', :placeholder='teamTextConfig.searchPlaceholder')
    //- 联系人列表
    a-position.ui-team-2-members__invite-list.of-h-x.of-a-y(v-bind='uiTeamSearchContacts.searchList.wrap')
      a-section.ui-team-2-members__invite-wrap(v-for='(item, index) in userInviteList', :key='index', v-bind='uiTeamSearchContacts.searchList.item')
        a-avatar(:bg-i='item.avatar', v-bind='uiTeamSearchContacts.searchList.avatarStyle', :uid='item.uid')
          a-position.ui-team-2-members__invite-border(wh='100%', pc, :bg-i='avatarImage.avatarBorder')
        a-position.ui-team-2-members__invite-name.ws-nowrap(v-bind='uiTeamSearchContacts.searchList.nickname', :fc='textConfig.commonNicknameColor') {{ $ts(item.nickname, teamRegionConfig.nicknameLength) }}
        ui-button.ui-team-2-members__invite-btn(v-if='item.status !== 2', v-bind='uiTeamSearchContacts.searchList.inviteBtn', :bg-i='buttonImage.invite', @a-tap='onInvite(item)')
        a-position.ui-team-2-members__invite-btn1(v-else, v-bind='uiTeamSearchContacts.searchList.inviteBtn', :bg-i='buttonImage.invited', @a-tap='onInvite(item)')
        a-position.ui-team-2-members__invite-line(v-bind='uiTeamSearchContacts.searchList.line', :bg-i='otherImage.line || teamImgConfig.line', v-if='index + 1 !== userInviteList.length')
      a-position.ui-team-2-members__invite-empty(v-show='!userInviteList.length', v-bind='uiTeamSearchContacts.searchList.emptyText', :fc='textConfig.teamSearchContacts.emptyTextColor', v-html='teamTextConfig.searchListEmpty')

  //- 邀请确认弹窗
  a-dialog.ui-team-2-members-invite-confirm.defaultWrapperStyle(name='uiTeamInviteConfirm', :bg-i='dialogImage.inviteConfirm', :fc='textConfig.commonTextColor')
    template(slot-scope='scope', v-if='scope.data')
      slot(name='uiTeamInviteConfirm')
      a-avatar(:bg-i='scope.data.avatar', v-bind='{ ...uiTeamInviteConfirm.userStyle.avatar, ...getCustomizeStyle("userStyleNickAvatar") }', :uid='scope.data.uid')
        a-position.ui-team-2-members-invite-confirm__norder(wh='100%', pc, :bg-i='avatarImage.avatarBorder')
      a-position.ui-team-2-members-invite-confirm__color.ws-nowrap(v-bind='{ ...uiTeamInviteConfirm.userStyle.nickname, ...getCustomizeStyle("userStyleNickName") }', :fc='textConfig.commonNicknameColor') {{ $ts(scope.data.nickname, teamRegionConfig.nicknameLength) }}
      //- a-position.txt-c(fs='.22' w='5.3' pc='x' pt='3.8' fw='400' fc='#8E472E' lh='.3' v-html="$tf('team/t3')")
    ui-button.ui-team-2-members-invite-confirm--cancel(v-bind='uiTeamInviteConfirm.cancelBtn', slot='cancel', :bg-i='buttonImage.cancel', @a-tap='$dialogs.uiTeamInviteConfirm.close()')
    ui-button.ui-team-2-members-invite-confirm--confirm(v-bind='uiTeamInviteConfirm.inviteFriends', slot='confirm', :bg-i='buttonImage.confirmShort', @a-tap='$dialogs.uiTeamInviteConfirm.close()')

  //- 收到组队邀请
  a-dialog.ui-team-2-members-receive-confirm.defaultWrapperStyle(name='uiTeamRecvInvitation', :bg-i='dialogImage.recvInvitation', :fc='textConfig.commonTextColor')
    template(slot-scope='scope', v-if='scope.data')
      slot(name='uiTeamRecvInvitation')
      //- 组队文案
      a-position.ui-team-2-members-receive-confirm__text(
        v-bind='{ ...uiTeamRecvInvitation.recvTextStyle, ...getCustomizeStyle("recvTextStyle") }',
        :fc='textConfig.recvInvitation.recvTextColor',
        v-html='$tf(textConfig.recvInvitation.recvText || teamTextConfig.recvInvitationText, $ts(scope.data.inviteUserInfo.nickname, teamRegionConfig.nicknameLength))'
      )
      a-avatar(:bg-i='scope.data.inviteUserInfo.head_img_url', v-bind='{ ...uiTeamRecvInvitation.userStyle.avatar, ...getCustomizeStyle("recvAvatarStyle") }', :uid='scope.data.inviteUserInfo.uid')
        a-position(wh='100%', pc, :bg-i='avatarImage.avatarBorder')
      a-position.ws-nowrap(v-bind='{ ...uiTeamRecvInvitation.userStyle.nickname, ...getCustomizeStyle("recvNicknameStyle") }', :fc='textConfig.commonNicknameColor') {{ $ts(scope.data.inviteUserInfo.nickname, teamRegionConfig.nicknameLength) }}
      //- 按钮
      ui-button.ui-team-2-members-receive-confirm--cancel(:bg-i='buttonImage.reject', v-bind='uiTeamRecvInvitation.rejectBtn', @a-tap='onHandleInvite("reject")')
      ui-button.ui-team-2-members-receive-confirm--confirm(:bg-i='buttonImage.agree', v-bind='uiTeamRecvInvitation.agreeBtn', @a-tap='onHandleInvite("agree")')

  //- 组队确认，拒绝确认
  a-dialog.ui-team-2-members-team-confirm.defaultWrapperStyle(name='uiTeamConfirm', wh='6.54 4.9', :fc='textConfig.commonTextColor')
    template(slot-scope='scope', v-if='scope.data')
      slot(name='uiTeamConfirm')
      a-position.ui-team-2-members-team-confirm--reject(:bg-i='scope.data.type === "reject" ? dialogImage.rejectConfirm : dialogImage.agreeConfirm', center)
        a-position(v-bind='{ ...uiTeamConfirm.textWrap, ...getCustomizeStyle("teamChooseStyle") }')
          a-position.ui-team-2-members-team-confirm__text(v-if='scope.data.type === "reject"', w='100%', v-html='$tf(textConfig.joinConfirm.rejectText || teamTextConfig.rejectConfirm, $ts(scope.data.inviteUserInfo.nickname, teamRegionConfig.nicknameLength))', pc)
          a-position.ui-team-2-members-team-confirm__name(v-else, w='100%', v-html='$tf(textConfig.joinConfirm.agreeText || teamTextConfig.agreeConfirm, $ts(scope.data.inviteUserInfo.nickname, teamRegionConfig.nicknameLength))', pc)
    ui-button.ui-team-2-members-team-confirm--cancel(v-bind='uiTeamConfirm.cancelBtn', slot='cancel', :bg-i='buttonImage.cancel', @a-tap='$dialogs.uiTeamConfirm.close()')
    ui-button.ui-team-2-members-team-confirm--confirm(v-bind='uiTeamConfirm.confirmBtn', slot='confirm', :bg-i='buttonImage.confirmShort', @a-tap='$dialogs.uiTeamConfirm.close()')

  //- 加入成功
  a-dialog.ui-team-2-members-add-suc.defaultWrapperStyle(name='uiTeamJoinSuccess', :bg-i='dialogImage.joinSuccess', :fc='textConfig.commonTextColor')
    template(slot-scope='scope', v-if='scope.data')
      slot(name='uiTeamJoinSuccess')
      //- 队伍名
      a-position.ui-team-2-members-add-suc__team.txt-c(v-bind='uiTeamJoinSuccess.teamNameWrap', :bg-i='otherImage.teamNameBg', :fc='textConfig.joinSuccess.teamNameColor') {{ teamState.teamName }}
      //- 队伍成员信息
      a-position.ui-team-2-members-add-suc__info.fx-row.fx-m-between(v-bind='uiTeamJoinSuccess.teamMembersStyle.wrap')
        a-section.ui-team-2-members-add-suc__wrap(v-for='(item, index) in teamMembers', :key='index', v-bind='uiTeamJoinSuccess.teamMembersStyle.item')
          a-avatar(v-if='item.uid', v-bind='uiTeamJoinSuccess.teamMembersStyle.avatar', :bg-i='item.head_img_url', :uid='item.uid')
            a-position.ui-team-2-members-add-suc__border(wh='100%', pc, :bg-i='avatarImage.avatarBorder')
          a-position.ui-team-2-members-add-suc__avatar(v-else, v-bind='uiTeamJoinSuccess.teamMembersStyle.avatar', :bg-i='avatarImage.defaultAvatar')
          a-position.ui-team-2-members-add-suc__nickname.ws-nowrap(v-bind='uiTeamJoinSuccess.teamMembersStyle.nickname', :fc='textConfig.commonNicknameColor') {{ $ts(item.nickname, teamRegionConfig.nicknameLength) }}
      //- 按钮
      ui-button.ui-team-2-members-add-suc__btn(v-bind='uiTeamJoinSuccess.successBtn', :bg-i='buttonImage.success', @a-tap='teamSuccess')

  //- 加入团队失败弹窗
  a-dialog.ui-team-2-members-join-fail.join-fail.defaultWrapperStyle(name='uiTeamInviteFail', :bg-i='dialogImage.inviteFail', :fc='textConfig.commonTextColor')
    template(slot-scope='scope', v-if='scope.data')
      slot(name='uiTeamInviteFail')
      a-position.ui-team-2-members-join-fail__text(v-bind='{ ...uiTeamInviteFail.textWrap, ...getCustomizeStyle("uiTeamInviteFailTextWrap") }')
        span.ui-team-2-members-join-fail__full(v-if='scope.data.type === "full"', v-html='$tf((textConfig.inviteFail && textConfig.inviteFail.otherHaveTeamText) || teamTextConfig.otherTeamFull, $ts(scope.data.inviteUserInfo.nickname, teamRegionConfig.nicknameLength))')
        span.ui-team-2-members-join-fail__name(v-else, v-html='$tf((textConfig.inviteFail && textConfig.inviteFail.iHaveTeamText) || teamTextConfig.youHaveTeam, $ts(scope.data.inviteUserInfo.nickname, teamRegionConfig.nicknameLength))')
      a-avatar(:bg-i='scope.data.inviteUserInfo.head_img_url', v-bind='{ ...uiTeamInviteFail.userStyle.avatar, ...getCustomizeStyle("uiTeamInviteFailAvatar") }', :uid='scope.data.inviteUserInfo.uid')
        a-position.ui-team-2-members-join-fail__avatar(wh='100%', pc, :bg-i='avatarImage.avatarBorder')
      a-position.ui-team-2-members-join-fail__length.ws-nowrap(v-bind='uiTeamInviteFail.userStyle.nickname', :fc='textConfig.commonNicknameColor') {{ $ts(scope.data.inviteUserInfo.nickname, teamRegionConfig.nicknameLength) }}
      ui-button.ui-team-2-members-join-fail__btn(v-bind='uiTeamInviteFail.okBtn', :bg-i='buttonImage.ok', @a-tap='$dialogs.uiTeamInviteFail.close()')

  //- 修改队伍名
  a-dialog.ui-team-2-members-edit-name.defaultWrapperStyle(name='uiTeamEditTeamName', :bg-i='dialogImage.editTeamName', :fc='textConfig.commonTextColor')
    slot(name='uiTeamEditTeamName')
    a-position(:bg-i='buttonImage.close', v-bind='{ ...uiTeamEditTeamName.closeBtn, ...getCustomizeStyle("closeStyle") }', @a-tap='$dialogs.uiTeamEditTeamName.close()')
    a-position(v-bind='uiTeamEditTeamName.input')
      input.ui-team-2-members-edit-name__input.input1(v-model='inputTeamName', :maxlength='teamNameLength', :placeholder='teamTextConfig.editNamePlaceholder', @keydown='onInput', :style='{ "--color": textConfig.inputColor }')
    ui-button.ui-team-2-members-edit-name__btn(:bg-i='buttonImage.confirmLong', v-bind='uiTeamEditTeamName.confirmLong', @a-tap='onModify')
</template>

<script>
import localStorage from '@/utils/local-storage'
import get from 'lodash/get'
import { commonImage } from '@sub/ui-components/utils/config'
import genBEMClassMixin from '@sub/ui-components/utils/genBEMClassMixin'
import { getters2Computed } from '@sub/store/utils'
import { teamStyleConfig, teamTextConfig, teamImgConfig, teamRegionConfig } from './utils/config-2-members.js'

export default {
  mixins: [genBEMClassMixin],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      ...teamStyleConfig,
      teamImgConfig,
      commonImage,
      teamRegionConfig,
      storageInfo: {},
      searchVal: '',
      inputTeamName: ''
    }
  },

  computed: {
    ...getters2Computed('team'),

    teamTextConfig({ textConfig }) {
      return teamTextConfig(textConfig.commonImportantTextColor)
    },

    teamState() {
      return this.$store.state.team
    },

    contact() {
      return this.$store.state.contact
    },

    amBasic() {
      return this.$store.state.base.amBasic
    },

    storageKey() {
      return `team-${this.amBasic.user.uid}-${window.location.pathname}`
    },

    userInviteList({ teamState: { inviteList }, contact: { contact } }) {
      return inviteList
        .map((item) => ({ ...item, ...contact.find((u) => u.uid === item.uid) }))
        .filter((item) => item.uid !== this.amBasic.user.uid)
        .filter((item) => (this.searchVal ? item.nickname.indexOf(this.searchVal) !== -1 : item))
        .filter((item) => item.status !== 1)
    },

    buttonImage({ uiMeta }) {
      return get(uiMeta, 'props.buttonImage', {})
    },

    avatarImage({ uiMeta }) {
      return get(uiMeta, 'props.avatarImage', {})
    },

    dialogImage({ uiMeta }) {
      return get(uiMeta, 'props.dialogImage', {})
    },

    otherImage({ uiMeta }) {
      return get(uiMeta, 'props.otherImage', {})
    },

    textConfig({ uiMeta }) {
      return get(uiMeta, 'props.textConfig', 0)
    },

    otherStyle({ uiMeta }) {
      return get(uiMeta, 'props.otherStyle', {})
    },

    extra({ uiMeta }) {
      return get(uiMeta, 'props.extra', { autoOpen: true })
    },
    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {}) || {}
    },

    teamNameLength() {
      return this.textConfig.teamNameLength || this.teamRegionConfig.teamNameLength
    }
  },

  created() {
    this.$store.commit('team/initDefault', {
      teamNum: 2,
      normalTeamName: this.textConfig.normalTeamName,
      normalAvatar: this.avatarImage.defaultAvatar
    })

    this.$store.commit('team/setMethods', {
      openEdit: this.openEditDialog.bind(this),
      openSearchList: this.getInviteStatus.bind(this),
      teamInit: this.teamInit.bind(this)
    })
  },

  async mounted() {
    if (this.extra.autoOpen) {
      this.teamInit()
    }
  },

  methods: {
    genBEMClassInviteFriends(bem = {}) {
      return this.genBEMClass({ ...bem, b: 'ui-team-invite-friends' })
    },

    genBEMClassSearchContacts(bem = {}) {
      return this.genBEMClass({ ...bem, b: 'ui-team-search-contacts' })
    },

    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    },
    initStorage() {
      const storageInfo = localStorage.get(this.storageKey) || { isFirstEntry: true, teamInfo: {}, rejectInfo: {}, enterInfo: {} }
      this.storageInfo = storageInfo
      localStorage.set(this.storageKey, this.storageInfo)
    },

    async teamInit() {
      this.initStorage()
      await this.getTeamData()
      await this.initPopup()
    },

    async getTeamData() {
      await this.$asyncAll([this.getTeamInfo(), this.getTeamName()])
    },

    async initPopup() {
      const popupSteps = []
      let currentStep = 0
      const { isFirstEntry, rejectInfo } = this.storageInfo
      const {
        sourceUser,
        teamInfo,
        extra: { teamNum }
      } = this.teamState

      // 是否被邀请，邀请弹窗
      if (sourceUser) {
        popupSteps.push({
          handle: async () => {
            const TargetTeamInfo = await this.$store.dispatch('team/amGetOtherTeamInfo', sourceUser)
            const inviteUserInfo = TargetTeamInfo.details.find((item) => +item.uid === +sourceUser) || {}
            this.inviteUserInfo = inviteUserInfo

            return new Promise((resolve) => {
              if (TargetTeamInfo.details.find((item) => item.uid === this.amBasic.user.uid)) {
                resolve()
                return
              }

              if (TargetTeamInfo.details.length === teamNum) {
                // 对方满员
                this.$dialogs.uiTeamInviteFail.open({ data: { inviteUserInfo, type: 'full' }, onclose: resolve })
              } else if (teamInfo.total_num > 1) {
                // 你已组队
                this.$dialogs.uiTeamInviteFail.open({ data: { inviteUserInfo, type: 'haveTeam' }, onclose: resolve })
              } else {
                if (rejectInfo && rejectInfo[sourceUser]) {
                  this.$toast(this.teamTextConfig.haveRejected)
                  resolve()
                } else {
                  this.$dialogs.uiTeamRecvInvitation.open({ data: { teamMembers: this.formatTeamMembers(TargetTeamInfo), inviteUserInfo }, onclose: resolve, options: { bgClose: true } })
                }
              }
            })
          }
        })
      }

      // 是否有新人加入队伍
      let { teamInfo: storageTeamInfo } = this.storageInfo
      if (teamInfo.total_num > 1 && teamInfo.total_num > (storageTeamInfo?.total_num || 1)) {
        // 更新本地队伍信息
        this.storageInfo.teamInfo = teamInfo
        localStorage.set(this.storageKey, this.storageInfo)

        let newMemberArr = []
        teamInfo.details.forEach((item) => {
          const target = !storageTeamInfo.details?.find(
            // eslint-disable-next-line
            (p) => p.uid === item.uid && item.uid !== this.amBasic.user.uid
          )
          if (!storageTeamInfo.details || target) {
            newMemberArr = [item]
          }
        })

        // 集结满弹窗
        if (newMemberArr.length) {
          popupSteps.push({
            handle: async () => {
              return new Promise((resolve) => {
                this.$storage.audioControl().audioPlay('team-success')
                this.$dialogs.uiTeamJoinSuccess.open({ data: { teamInfo, newMemberArr }, onclose: resolve })
              })
            }
          })
        }
      }

      // 首次进入弹窗
      if (this.extra.isOpenFirstInvite && !sourceUser && isFirstEntry && teamInfo.total_num === 1) {
        popupSteps.push({
          handle: async () => {
            return new Promise((resolve) => {
              this.$dialogs.uiTeamInviteFriends.open({ options: { bgClose: true }, onclose: resolve })
            })
          }
        })
      }

      this.storageInfo.isFirstEntry = false
      localStorage.set(this.storageKey, this.storageInfo)
      while (popupSteps[currentStep]) {
        await popupSteps[currentStep].handle()
        currentStep++
      }
    },

    getTeamInfo() {
      return this.$store.dispatch('team/amGetTeamInfo')
    },

    getTeamName() {
      return this.$store.dispatch('team/amGetTeamName')
    },

    // 获取可邀请的联系人
    async getInviteStatus() {
      if (this.teamState.teamInfo.total_num === this.teamState.extra.teamNum) {
        return
      }

      await this.$store.dispatch('contact/amSetContact')
      this.$store.dispatch('team/amGetInviteStatus')
      this.$dialogs.uiTeamSearchContacts.open({ options: { bgClose: true } })
    },

    async onInvite(friend) {
      const res = await this.$dialogs.uiTeamInviteConfirm.confirm({ data: friend })
      if (!res) {
        return
      }

      await this.$store.dispatch('team/amInviteFriend', friend.uid)
      this.$toast(this.teamTextConfig.sendInvite)
    },
    closeUiTeamAllDialog() {
      const names = Object.keys(this.$dialogs).filter((i) => this.$dialogs[i].active && i.startsWith('uiTeam'))
      names.forEach((name) => this.$dialogs[name].close())
    },

    async onConfirm(type) {
      const { sourceUser } = this.teamState
      if (type === 'reject') {
        await this.$store.dispatch('team/amRejectTeam')
        this.storageInfo.rejectInfo = {
          ...(this.storageInfo.rejectInfo || {}),
          [sourceUser]: true
        }
        // 关闭所有组队相关弹窗，组队confirm弹窗未关闭
        this.closeUiTeamAllDialog()
        this.$emit('teamReject')
      } else {
        await this.$store.dispatch('team/amJoinTeam')
        await this.getTeamInfo()
        this.$dialogs.uiTeamConfirm.close()

        this.$emit('teamAgree')
        // 新增组队成功音效
        this.$storage.audioControl().audioPlay('team-success')
        this.$dialogs.uiTeamJoinSuccess.open({
          onclose: () => {
            document.body.style.overflow = null
          }
        })
        this.storageInfo.teamInfo = this.teamState.teamInfo
      }

      localStorage.set(this.storageKey, this.storageInfo)
    },

    async onHandleInvite(type) {
      const res = await this.$dialogs.uiTeamConfirm.confirm({ data: { inviteUserInfo: this.inviteUserInfo, type } })
      if (!res) {
        return
      }

      this.onConfirm(type)
    },

    openEditDialog() {
      this.$dialogs.uiTeamEditTeamName.open({
        options: { bgClose: true },
        onclose: () => {
          this.inputTeamName = ''
        }
      })
    },

    async onModify() {
      if (!this.inputTeamName) {
        return this.$toast(this.teamTextConfig.editEmptyTip)
      }

      const len = this.teamNameLength
      await this.$store.dispatch('team/amModifyTeamName', this.inputTeamName.slice(0, len))
      this.$dialogs.uiTeamEditTeamName.close()
      this.getTeamName()
      this.inputTeamName = ''
      this.$emit('modifySuccess')
    },

    formatTeamMembers(teamInfo) {
      const {
        extra: { teamNum, normalAvatar, normalNickname }
      } = this.teamState
      return new Array(teamNum).fill({}).map((item, index) => teamInfo.details[index] || { nickname: normalNickname, head_img_url: normalAvatar })
    },

    onInput() {
      const len = this.teamNameLength
      this.inputTeamName = this.inputTeamName.slice(0, len)
    },

    teamSuccess() {
      // 关闭所有组队相关弹窗，组队confirm弹窗未关闭
      this.closeUiTeamAllDialog()
      this.$emit('teamSuccess')
    }
  }
}
</script>

<style lang="scss" scoped>
.defaultWrapperStyle {
  font-size: 0.26rem;
  font-weight: 500;
  text-align: center;
}

.input {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  line-height: 0.68rem;
  font-size: 0.28rem;
  padding-left: 0.7rem;
  color: var(--color);

  &::placeholder {
    font-size: 0.28rem;
    line-height: 0.68rem;
    color: var(--color);
  }
}

.input1 {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  line-height: 0.72rem;
  font-size: 0.32rem;
  text-align: center;
  color: var(--color);

  &::placeholder {
    font-size: 0.32rem;
    line-height: 0.72rem;
    color: var(--color);
  }
}
</style>
