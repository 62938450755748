import config from '@/config'

import { getPageTitle } from '@/lib/i18n/util'
import updateTitle from '@/utils/update-title'

export const setPageTitle = (route) => {
  updateTitle(getFullTitle(route))
}

export default function getFullTitle(route) {
  route.meta || (route.meta = {})

  const { meta, matched } = route

  if (!meta.title) {
    meta.title = getPageTitle([...matched].pop()) || '\u200e'
  }

  if (!config.titleSuffix || meta.noSuffix || meta.title === '\u200e') {
    return meta.title
  }

  return meta.title ? `${meta.title} - ${config.titleSuffix}` : config.titleSuffix
}
