import weSDK from '@/lib/we-sdk'

export default {
  namespaced: true,

  state: {
    deviceInfo: {}
  },

  mutations: {
    // 初始化一些参数
    setDeviceInfo(state, deviceInfo) {
      state.deviceInfo = deviceInfo
    }
  },

  actions: {
    async init({ dispatch }) {
      await Promise.all([dispatch('getDeviceInfo')])
    },

    async getDeviceInfo({ commit }) {
      const deviceInfo = await weSDK('getDeviceInfo')
      commit('setDeviceInfo', deviceInfo)
    }
  }
}
