export const rechargeDefaultConfig = {
  taskId: 2,
  uiRechargeWrapper: {
    // 累计充值文字配置
    line1: { pl: 0.79, pt: 3.31, tf: 'recharge/value', f: '.24 #30592F 500' },
    line2: { pl: 0.79, pt: 3.74, tf: 'recharge/diff', f: '.24 #30592F 500' },
    record: { bg: 'recharge/record', pr: '.66', pt: '3.42', key: 'recordMod3' },

    // 主页面展示累消的区域
    content: { wh: '6.34 8.2', pc: 'x', pt: '4.11' },
    // 主页面展示哪几个档位的配置， "button" 代表是展开按钮
    mainShowArr: [0, 1, 2, 'button', 5]
  },
  // 每一项的配置
  uiRechargeItem: {
    // 未达到背景
    itemBgLock: 'recharge/mask-lock',
    // 已达到背景
    itemBgUnlock: 'recharge/mask-unlock',
    // 未达到奖励背景
    rewardBgLock: 'recharge/reward-bg-lock',
    // 已达到奖励背景
    rewardBgUnlock: 'recharge/reward-bg-unlock',
    // 锁的三种状态图片
    lockIconState0: 'recharge/state0',
    lockIconState1: 'recharge/state1',
    lockIconState2: 'recharge/state2',

    // 每个配置在主页面展示的配置
    mainConfig: {
      // 锁的位置
      lock: { pr: '.31', pc: 'y' },
      // 奖励的位置
      rewardContent: { wh: '3.5 1.2', pr: '1.26', pt: '.3' },
      textContent: { wh: '1.1 .58', pc: 'y', pl: '.49', lh: '.34', tf: 'recharge/text' },
      itemWh: '6.34 1.74'
    },
    // 每个配置在弹窗中展示的配置
    dialogConfig: {
      // 锁的位置
      lock: { pr: '.31', pc: 'y' },
      // 奖励的位置
      rewardContent: { wh: '3.5 1.2', pr: '1.26', pc: 'y' },
      textContent: { wh: '1.1 .58', pc: 'y', pl: '.49', lh: '.34', tf: 'recharge/text' },
      itemWh: '5.62 1.527'
    },
    // 未达到奖励颜色
    color1: '#829981',
    // 已达到颜色
    color2: '#829981',
    subscriptStyle: {
      borderColor: '#007A0C',
      color: '#fff'
    }
  },
  dialog: {
    bg: 'recharge/dialog/bg',
    // 弹窗滑动的区域
    content: { wh: '5.5 6.3', pc: 'x', pt: '1.36' }
  },
  expand: { bg: 'recharge/look-all' }
}

export const rechargeDefaultConfigAr = {
  taskId: 2,
  uiRechargeWrapper: {
    // 累计充值文字配置
    line1: { pl: 0.79, pt: 3.31, tf: 'recharge/value', f: '.24 #30592F 500' },
    line2: { pl: 0.79, pt: 3.74, tf: 'recharge/diff', f: '.24 #30592F 500' },
    record: { bg: 'recharge/record', pr: '.66', pt: '3.42', key: 'recordMod3' },

    // 主页面展示累消的区域
    content: { wh: '6.34 8.2', pc: 'x', pt: '4.11' },
    // 主页面展示哪几个档位的配置， "button" 代表是展开按钮
    mainShowArr: [0, 1, 2, 'button', 5]
  },
  // 每一项的配置
  uiRechargeItem: {
    // 未达到背景
    itemBgLock: 'recharge/mask-lock',
    // 已达到背景
    itemBgUnlock: 'recharge/mask-unlock',
    // 未达到奖励背景
    rewardBgLock: 'recharge/reward-bg-lock',
    // 已达到奖励背景
    rewardBgUnlock: 'recharge/reward-bg-unlock',
    // 锁的三种状态图片
    lockIconState0: 'recharge/state0',
    lockIconState1: 'recharge/state1',
    lockIconState2: 'recharge/state2',

    // 每个配置在主页面展示的配置
    mainConfig: {
      // 锁的位置
      lock: { pr: '.31', pc: 'y' },
      // 奖励的位置
      rewardContent: { wh: '3.5 1.2', pr: '1.26', pt: '.3' },
      textContent: { wh: '1.1 .58', pc: 'y', pl: '.49', lh: '.34', tf: 'recharge/text' },
      // 每一项的宽高
      itemWh: '6.34 1.74'
    },
    // 每个配置在弹窗中展示的配置
    dialogConfig: {
      // 锁的位置
      lock: { pr: '.31', pc: 'y' },
      // 奖励的位置
      rewardContent: { wh: '3.5 1.2', pr: '1.26', pc: 'y' },
      textContent: { wh: '1.1 .58', pc: 'y', pl: '.49', lh: '.34', tf: 'recharge/text' },
      // 每一项的宽高
      itemWh: '5.62 1.527'
    },
    // 未达到奖励颜色
    color1: '#829981',
    // 已达到颜色
    color2: '#829981',
    subscriptStyle: {
      borderColor: '#007A0C',
      color: '#fff'
    }
  },
  dialog: {
    bg: 'recharge/dialog/bg',
    // 弹窗滑动的区域
    content: { wh: '5.5 6.3', pc: 'x', pt: '1.36' }
  },
  expand: { bg: 'recharge/look-all' }
}
