import axios from 'axios'
import get from 'lodash/get'

import config from '@/config'

import { genService } from '@/utils/request-next'
import { PAGE_LANG, PAGE_REGION } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

const DEV = config.$env.DEV

const request = genService({ host: 'https://quick-page-api.wepie.com', result: { code: 'code', msg: 'msg', data: 'data' } })

export async function getPageConfig(id) {
  if (DEV) {
    return await request.get('open/page', { params: { id, dev: 1 } })
  } else {
    const { data } = await axios(proxyAssets(`https://quick-page.weplayapp.com/page/${id}.json?v=${Date.now()}`))
    return proxyAssets(data)
  }
}

export async function getDirectory(id) {
  if (DEV) {
    return await request.get('open/directory', { params: { id, dev: 1 } })
  } else {
    const { data } = await axios(proxyAssets(`https://quick-page.weplayapp.com/directory/${id}.json?v=${Date.now()}`))
    return proxyAssets(data)
  }
}

export async function getConfig(id) {
  if (!id) {
    return null
  }

  let data

  try {
    data = await getPageConfig(id)
  } catch (e) {
    return null
  }

  if (!data.config) {
    return null
  }

  if (data.config.isMultiple) {
    const langs = data.config.langs.filter((i) => i.lang === PAGE_LANG)

    for (const item of langs) {
      if (item.region) {
        if (item.region.split(',').includes(PAGE_REGION)) {
          data = item
        }
        break
      } else {
        data = item
      }
    }
  }

  return {
    assets: arrs2obj(get(data, 'config.assetsArr', [])),
    params: arrs2obj(get(data, 'config.paramsArr', [])),
    props: arrs2obj(get(data, 'config.props.list', []), 'id', ''),
    avatar: arrs2obj(get(data, 'config.props.avatarList', []), 'id', ''),
    titles: arrs2obj(get(data, 'config.props.titleList', []), 'id', ''),
    text: arrs2obj(get(data, 'config.text.list', [])),
    json: get(data, 'config.json', {}),
    fsAssets: get(data, 'config.assetsArr', []).filter((item) => item.describe?.includes('fs'))
  }
}

// 将数组转对象
function arrs2obj(arr, k = 'key', v = 'value') {
  return arr.reduce((obj, item) => {
    obj[item[k]] = v ? item[v] : item
    return obj
  }, {})
}
