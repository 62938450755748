<template lang="pug">
a-section.ui-newuser-guide-wrapper
  a-dialog(name='teachGuide')
    a-section(v-if='bgArr.length', :bg-i='bgArr[nowStep - 1]', @a-tap='addTeachStep')
</template>
<script>
import get from 'lodash/get'
export default {
  data() {
    return { nowStep: 1 }
  },
  computed: {
    bgArr() {
      return get(this.uiMeta, 'props.bgArr', [])
    },
    showFirstIn() {
      return get(this.uiMeta, 'props.showFirstIn', false)
    },
    localStorage() {
      return this.$storage.localStorage()
    }
  },
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    addTeachStep() {
      if (this.nowStep >= this.bgArr.length) {
        this.$dialogs.teachGuide.close()
        this.nowStep = 1
      } else {
        this.nowStep++
      }
    }
  },
  mounted() {
    this.$storage.init({ 'newuser-guide': 0 })
    if (this.showFirstIn && !this.localStorage['newuser-guide']) {
      this.$storage.update({ 'newuser-guide': 1 })
      this.$dialogs.teachGuide.open()
    }
  }
}
</script>

<style lang="scss" scoped></style>
