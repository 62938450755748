export default [
  {
    path: `/activity/v2/20240325/avatar-c/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1825,
      expire: '2024/07/30 23:59:59 UTC+8',
      pageCenter: 'dPgOGtJd'
    }
  }
]
