import { actId, path } from '../config'

// birthdayPageid 生日会
// greetingCardId 蛋糕工坊

export default [
  {
    path: `/activity/v2/${path}/workShop-k/index.html`,
    component: () => import('./index.vue'),
    meta: {
      id: actId,
      pageCenter: 'zrtBM7Rd',
      setMeta(to) {
        to.meta.title = to.meta.json.title
      }
    }
  },
  {
    path: `/activity/v2/${path}/birthdayParty-k/index.html`,
    component: () => import('./birthday.vue'),
    meta: {
      id: actId,
      pageCenter: 'Y9vJlMjP',
      setMeta(to) {
        to.meta.title = to.meta.json.title
      }
    }
  },
  {
    path: `/activity/v2/${path}/share-k/index.html`,
    component: () => import('./share.vue'),
    meta: {
      id: actId,
      pageCenter: 'Y9vJlMjP',
      setMeta(to) {
        to.meta.title = to.meta.json.title
      }
    }
  },
  {
    path: `/activity/v2/${path}/greetingCard-k/index.html`,
    component: () => import('./greetingCard.vue'),
    meta: {
      id: actId,
      pageCenter: 'zrtBM7Rd',
      setMeta(to) {
        to.meta.title = to.meta.json.title
      }
    }
  }
]
