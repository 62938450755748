<template lang="pug">
a-position.ui-check-box(v-if='isShow', v-bind='$attrs', @a-tap='toggleTip()')
  a-position.ui-check-box__bg--checked(v-show='isChecked', :bg-i='checkedImg', pc)
  a-position.ui-check-box__bg--unchecked(v-show='!isChecked', :bg-i='unCheckImg', pc)
</template>

<script>
import { isInIframe } from '@sub/ui-runtime/core/iframe'

export default {
  props: {
    // 控制这个勾选框的 storage key
    controlKey: {
      type: String,
      default: ''
    },
    // 用于恢复勾选状态的 storage key（可以和 controlKey 相同）
    restoreKey: {
      type: String,
      default: ''
    },

    // 勾选状态的图片
    checkedImg: {
      type: String,
      default: ''
    },
    // 未勾选状态的图片
    unCheckImg: {
      type: String,
      default: ''
    },

    // 是否存在 localStorage 里
    useStorage: {
      type: Boolean,
      default: true
    },

    defaultVal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    localStorage() {
      return this.$storage.localStorage()
    },

    isChecked() {
      return this.localStorage[this.controlKey]
    },

    isOpenEffect() {
      return this.$base.isOpenEffect()
    },
    isOpenEffectPAG() {
      return this.$base.isOpenEffectPAG()
    },
    isShow() {
      // 「跳过动画」勾选框，只在用户可以展示动效的时候显示
      if (this.controlKey === 'noAnime') {
        return this.isOpenEffectPAG || this.isOpenEffect
      } else {
        return true
      }
    }
  },

  created() {
    if (!this.controlKey) {
      console.error('[ui-check-box] 请设置 controlKey')
      return
    }

    if (this.useStorage) {
      this.$storage.init({ [this.controlKey]: this.defaultVal })
    } else {
      this.$storage.update({ [this.controlKey]: this.defaultVal })
    }

    // iframe 预览
    if (isInIframe()) {
      if (this.controlKey === this.restoreKey) {
        this.$storage.update({ [this.controlKey]: this.defaultVal })
      } else {
        this.$storage.update({ [this.controlKey]: this.defaultVal })
        this.$storage.update({ [this.restoreKey]: !this.defaultVal })
      }
    }
  },

  methods: {
    toggleTip() {
      if (this.controlKey === this.restoreKey) {
        // 同一个 key 的情况
        this.$storage.toggle(this.controlKey)
      } else {
        // 不同 key 的情况
        this.$storage.toggle(this.controlKey)
        if (this.restoreKey) {
          this.$storage.update({ [this.restoreKey]: !this.isChecked })
        }
      }

      this.$emit('toggleTip')
    }
  }
}
</script>

<style></style>
