import { createBtnPlugin } from '@sub/utils/eruda-plugins/utils'
import getOutlineCss from './getOutlineCss.js'
import { insertCssToBody, insertClassNameToBody, removeClassNameToBody } from './utils'

const bodyClassName = 'eruda-outline-plugin-body-class'
const styleClassName = 'eruda-outline-plugin-style-class'

const clickCallback = () => {
  let status = false
  return (btn) => {
    if (status) {
      status = false
      removeClassNameToBody(bodyClassName)
    } else {
      insertCssToBody(styleClassName, getOutlineCss(`.${bodyClassName}`))
      status = true
      insertClassNameToBody(bodyClassName)
    }
    btn.innerText = status ? '移除边框' : '添加边框'
  }
}

// 一键添加outline
export default (parent) => createBtnPlugin({ parent, btnClassName: 'add-border', btnText: '添加边框', clickCallback: clickCallback() })
