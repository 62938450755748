import { PAGE_REGION, IS_WEPLAY } from '@/utils/env'

const textConfig = {
  hw: {
    normalNickname: '虚位以待'
  },
  C: {
    normalNickname: '空位'
  },
  J: {
    normalNickname: 'ニックネーム'
  },
  K: {
    normalNickname: '비어있음'
  },
  A: {
    normalNickname: 'فارغ'
  },
  T: {
    normalNickname: 'ว่าง'
  },
  M: {
    normalNickname: 'Kosong'
  },
  U: {
    normalNickname: 'Vacant'
  },
  Q: {
    normalNickname: 'Boş'
  },
  V: {
    normalNickname: 'Trống'
  },
  P: {
    normalNickname: 'Empty'
  }
}

export const text = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw
