export default [
  {
    path: `/activity/v2/monthly-valentine-day2407-k/main-k/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 2109,
      expire: '2024/07/31 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'aE44bGg2'
    }
  },

  {
    path: `/activity/v2/monthly-valentine-day2407-k/rank-k/index.html`,
    component: () => import('./rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 2109,
      expire: '2024/07/31 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'aE44bGg2',
      endStyleDelayTimestamp: 24 * 60 * 60 * 1000
    }
  }
]
