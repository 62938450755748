<template lang="pug">
  a-position.txt-no-wrap(v-if='_isDev && !_isLocal', f='.3 #fff 700'  pc, bg-c='#EE9774', pd='.1', bdr='.1') {{ _isBeta ? 'beta' : 'dev' }} environment! FPS: {{ FPS }}
</template>

<script>
export default {
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {
    FPS() {
      return this.$performance.frame()
    }
  },

  mounted() {},

  beforeDestroy() {},

  methods: {}
}
</script>

<style lang="scss" scoped></style>
