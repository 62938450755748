import localStorage from '@/utils/local-storage'

const APP_VERSION = 'APP_VERSION'

export default function setAppStorage(appVersion) {
  const storageVersion = Number(localStorage.get(APP_VERSION) || 0)

  if (storageVersion < appVersion) {
    localStorage.clear()
    localStorage.set(APP_VERSION, appVersion)
  }
}
