import { render, staticRenderFns } from "./ui-quiz.vue?vue&type=template&id=f1121f16&scoped=true&lang=pug"
import script from "./ui-quiz.vue?vue&type=script&lang=js"
export * from "./ui-quiz.vue?vue&type=script&lang=js"
import style0 from "./ui-quiz.vue?vue&type=style&index=0&id=f1121f16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1121f16",
  null
  
)

export default component.exports