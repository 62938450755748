export default [
  {
    path: `/activity/v2/20240410/recharge-j/index.html`, // 日服广场集合页
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 1820, // 服务器给到
      // TODO:
      pageCenter: 'Va7XpJBR'
    }
  }
]
