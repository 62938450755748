<template lang="pug">
a-section.outlined-text.ui-text(v-bind='$attrs')
  //- 宽度由 .text 撑开，.after的宽度为父元素的100%， 保证 .text 和 .after 的宽度一致
  a-section
    a-section.text.ui-text__content(v-html='content', :style='{ "--strokeWidth": useStrokeWidth, "--strokeColor": strokeColor }')
    a-position.after.ui-text__after(v-if='strokeWidth !== 0', w='100%', pt='0', pl='0', v-html='content')
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Number],
      default: ''
    },
    // rem 或 px 的带单位/不带单位的值
    strokeWidth: {
      type: [String, Number],
      default: 0
    },
    strokeColor: {
      type: String,
      default: '#FFFBEF'
    }
  },

  computed: {
    // 3px -> 0.06  3 -> 0.06  0.03rem -> 0.06  0.03 -> 0.06
    useStrokeWidth({ strokeWidth }) {
      const value = +(strokeWidth + '')?.replace('rem', '')?.replace('px', '') * 2
      // 描边最终值的单位是 rem，若 value > 1，描边一定异常，需要除以 100
      return (value > 1 ? value / 100 : value) + 'rem'
    }
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.outlined-text {
  display: inline-block;

  .text {
    -webkit-text-stroke: var(--strokeWidth) var(--strokeColor);
    text-stroke: var(--strokeWidth) var(--strokeColor);
  }

  .after {
    -webkit-text-stroke: 0;
    text-stroke: 0;
  }
}
</style>
