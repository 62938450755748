import getQuery from '@sub/ui-runtime/core/utils/getQuery'

const query = getQuery()

export const { region = 'HW' } = query
export const isA = region === 'A'
export const handleNum = (num = 0) => {
  let num2 = Number(num) / 10000
  let [num3, num4] = String(num2).split('.')
  let str = String(num).length > 4 ? `${num3}${num4 ? `.${String(num4).slice(0, 1)}` : ''}w` : num

  return str
}

export const handleNum1 = (num = 0) => {
  const isJ = region === 'J'
  let str = ''

  if (isJ && num >= 1000000) {
    let num2 = Number(num) / 1000000
    let [num3, num4] = String(num2).split('.')
    str = `${num3}${num4 ? `.${String(num4).slice(0, 1)}` : ''}M`
  } else {
    let num2 = Number(num) / 1000
    let [num3, num4] = String(num2).split('.')
    str = String(num).length > 3 ? `${num3}${num4 ? `.${String(num4).slice(0, 1)}` : ''}k` : num
  }

  return str
}

export const handleNumKW = (num = 0) => {
  const value = handleNum1(num)

  return value
}

export const regionMap = { HW: '虚位以待', C: '空位', U: 'Vacant', J: 'お楽しみに', A: 'فارغ', K: '비어있음', T: 'ว่าง', M: 'Kosong', P: 'Empty', V: 'Trống', Q: 'boş' }
export const teamNameMap = { HW: '虚位以待', C: '空位', U: 'Team', J: 'お楽しみに', A: 'فارغ', K: '팀이름팀이름팀', T: 'ว่าง', M: 'Kosong', P: 'Team', V: 'Trống', Q: 'boş' }
