import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'

import toast from '@/lib/page-toast'

import config from '@/config'
import { IS_DEV } from '@/utils/env'
import { requestCrypto } from '@sub/service/interceptors'
import { getCryptType, decrypt } from '@sub/service/crypto'
import proxyAssets from '@sub/utils/proxy-assets'
import * as iframe from '@sub/ui-runtime/core/iframe'

/**
 * 构建实例
 *  - host
 *  - options
 *  - result.code
 *  - result.msg
 *  - result.data
 *  - interceptors.request
 *  - interceptors.response
 */
export function genService({ host, options, result, interceptors } = {}, showToast) {
  const service = axios.create({ baseURL: host, withCredentials: false, timeout: 10000, ...options })

  service.interceptors.request.use(requestCrypto)
  service.interceptors.request.use(
    (config) => {
      interceptors && interceptors.request && interceptors.request(config)

      if (config.headers[config.method]['Content-Type'] === 'application/x-www-form-urlencoded' && Object.prototype.toString.call(config.data || {}) !== '[object FormData]') {
        config.data = qs.stringify(config.data)
      }
      return config
    },
    async (e) => requestError({ msg: e })
  )

  service.interceptors.response.use(
    async (response) => {
      let {
        data: res,
        config: { url }
      } = response

      if (getCryptType() !== -1) {
        res = await decrypt(res, response.headers['x-ca'] == '1')
        if (IS_DEV) {
          console.info('返回值：', url, res)
        }
      }

      interceptors && interceptors.response && interceptors.response(res)

      return res[result.code] === 200 ? proxyAssets(res[result.data]) : responseError({ code: res[result.code] || 403, msg: res[result.msg] || res }, showToast)
    },
    async (e) => responseError({ code: e?.response?.status, msg: e.message || e.msg }, showToast)
  )

  return service
}
async function requestError({ msg }, showToast) {
  showToast && toast(msg)
  return Promise.reject({ code: -1, msg })
}
async function responseError({ code, msg = 'Error' }, showToast) {
  if (iframe.isInIframe()) {
    return Promise.reject({ code, msg })
  }

  showToast && toast(msg)
  return Promise.reject({ code, msg })
}

// 错误处理
export const concatCatch = async (fn, loading = 'loading') => {
  loading && document.body.classList.add(loading)
  const result = await fn.then((data) => [null, data]).catch((error) => [error, null])
  loading && document.body.classList.remove(loading)
  return result
}
export const concatCatch2 = (fn) => concatCatch(fn, false)

/**
 * 由配置构建 service
 */
export function getGenApi(service, method, concatCatch = false) {
  const key = method === 'get' ? 'params' : 'data'

  if (concatCatch) {
    return (url, config = {}) =>
      (d, o = {}) =>
        service({ url, method, [key]: d, ...o, ...config })
          .then((d) => [0, d])
          .catch((e) => [e, 0])
  } else {
    return (url, config = {}) =>
      (d, o = {}) =>
        service({ url, method, [key]: d, ...o, ...config })
  }
}
function getConfigServices(request, options, showToast = true) {
  return Object.keys(request).reduce((obj, name) => {
    const service = genService({ host: config.request[name], ...options[name] }, showToast)

    service.genGetApi = getGenApi(service, 'get')
    service.genPostApi = getGenApi(service, 'post')
    service.genGetApiCatch = getGenApi(service, 'get', true)
    service.genPostApiCatch = getGenApi(service, 'post', true)

    obj[name] = service

    return obj
  }, {})
}

/**
 * 所有的 request 实例
 */
export const service = getConfigServices(config.request, {
  h1: {
    result: { code: 'code', msg: 'msg', data: 'result' },
    interceptors: {
      request(config) {
        const { locale } = Vue.prototype.$app.$i18n
        const langMap = { 'zh-hans': 'zh-Hans', 'zh-tw': 'zh-TW' }

        const key = config.method.toLowerCase() === 'post' ? 'data' : 'params'
        config[key] = { ...config[key], lang: langMap[locale] || locale }
      }
    }
  },
  h2: {
    result: { code: 'code', msg: 'msg', data: 'result' },
    interceptors: {
      request(config) {
        const { locale } = Vue.prototype.$app.$i18n
        const langMap = { 'zh-hans': 'zh-Hans', 'zh-tw': 'zh-TW' }

        const key = config.method.toLowerCase() === 'post' ? 'data' : 'params'
        config[key] = { ...config[key], lang: langMap[locale] || locale }
      }
    }
  },
  h3: {
    result: { code: 'code', msg: 'msg', data: 'result' },
    interceptors: {
      request(config) {
        const { locale } = Vue.prototype.$app.$i18n
        const langMap = { 'zh-hans': 'zh-Hans', 'zh-tw': 'zh-TW' }

        const key = config.method.toLowerCase() === 'post' ? 'data' : 'params'
        config[key] = { ...config[key], lang: langMap[locale] || locale }
      }
    }
  }
})

export const service1 = getConfigServices(
  config.request,
  {
    h1: {
      result: { code: 'code', msg: 'msg', data: 'result' },
      interceptors: {
        request(config) {
          const { locale } = Vue.prototype.$app.$i18n
          const langMap = { 'zh-hans': 'zh-Hans', 'zh-tw': 'zh-TW' }

          const key = config.method.toLowerCase() === 'post' ? 'data' : 'params'
          config[key] = { ...config[key], lang: langMap[locale] || locale }
        }
      }
    },
    h2: {
      result: { code: 'code', msg: 'msg', data: 'result' },
      interceptors: {
        request(config) {
          const { locale } = Vue.prototype.$app.$i18n
          const langMap = { 'zh-hans': 'zh-Hans', 'zh-tw': 'zh-TW' }

          const key = config.method.toLowerCase() === 'post' ? 'data' : 'params'
          config[key] = { ...config[key], lang: langMap[locale] || locale }
        }
      }
    },
    h3: {
      result: { code: 'code', msg: 'msg', data: 'result' },
      interceptors: {
        request(config) {
          const { locale } = Vue.prototype.$app.$i18n
          const langMap = { 'zh-hans': 'zh-Hans', 'zh-tw': 'zh-TW' }

          const key = config.method.toLowerCase() === 'post' ? 'data' : 'params'
          config[key] = { ...config[key], lang: langMap[locale] || locale }
        }
      }
    }
  },
  false
)

export default service.h1

export const buildApi = service.h1.genPostApi
export const buildApi1 = service1.h1.genPostApi
//华服迁移域名测试服测试
export const buildApi2 = (service.h3 && service.h3.genPostApi) || {}
export const buildApi3 = (service1.h3 && service1.h3.genPostApi) || {}
