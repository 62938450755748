// 虚位以待
export const NICKNAME_MAP = {
  HW: '虚位以待',
  C: '空位',
  U: 'Vacant',
  J: 'お楽しみに',
  A: 'فارغ',
  K: '비어있음',
  T: 'ว่าง',
  M: 'Kosong',
  P: 'Empty',
  V: 'Trống',
  Q: 'boş'
}

// 搜索框
export const SEARCH = 'search'
// 投票榜单
export const RANK = 'rank'
// 投票确认弹窗
export const VOTE_CONFIRM_DIALOG = 'voteConfirmDialog'
// 投票成功弹窗
export const VOTE_SUCCESS_DIALOG = 'voteSuccessDialog'
// 查看作品弹窗
export const VIEW_WORK_DIALOG = 'viewWorkDialog'
