import weSDK from '@/lib/we-sdk'
import Vue from 'vue'

import config from '@/config'
import * as iframe from '@sub/ui-runtime/core/iframe'

import dayjs from '@/utils/dayjs'
import { IS_APP, IS_DEV } from '@/utils/env'
import { buildApi, buildApi1 } from '@sub/utils/request'
import { getQuery1 as getQuery } from '@/utils/utils'

export const amConfig = {
  isDev: IS_DEV,
  isApp: IS_APP,

  lib: {
    dayjs,
    utc: config.utc,
    getQuery
  },

  modules: {
    base: {
      request: {
        getNowTime: (p) => buildApi1('/activity_v1/common/get_now_time', { isPointTrack: true })(p).then((t) => t * 1000),
        getUserInfo: (p) =>
          buildApi('/activity_v2/common/get_act_user_info', { isPointTrack: true })(p).then((r) => ({
            nickname: r.nickname,
            avatar: r.head_img_url,
            frame: r.picture_frame,
            gender: r.gender || 2,
            charmLevel: r.charm_level,
            charmIcon: r.charm_image_url,
            charm: r.charm
          })),
        setPageDot: buildApi('/activity_v1/common/handle_red_dot', { isPointTrack: true }),
        getActInfo: buildApi('/activity_v2/common/get_act_info', { isPointTrack: true }),
        getAvatarUserInfo: buildApi('/activity_v2/common/get_avatar_basic_info', { isPointTrack: true }),
        getEffectState: buildApi('/activity_v1/common/get_special_effects_closed', { isPointTrack: true }),
        getUserCoin: buildApi('/activity_v1/common/user/get_coin', { isPointTrack: true }),
        getCollectChip: buildApi('/activity_v2/collect_chip/get_collect_chip', { isPointTrack: true }),
        getChipTotalCount: buildApi('/activity_v2/collect_chip/get_chip_total_count', { isPointTrack: true })
      },
      native: {
        hideShareBtn: () => weSDK('hideShareBtn'),
        getUserInfo: () => weSDK('getUserInfo'),
        getDeviceInfo: () => weSDK('getDeviceInfo'),
        compareVersion: (v1, v2) => weSDK.compareVersion(v1, v2)
      },
      methods: {
        apiParam: (basic) => ({
          act_id: basic.id,
          uid: basic.user.uid,
          sid: basic.user.sid
        }),
        activityOver(meta) {
          if (iframe.isInIframe()) {
            return
          }

          const container = document.querySelector('body>.page-container')
          const el = document.createElement('div')

          if (meta.isInternational) {
            el.classList.add(`layer-finish-${getQuery().region || 'U'}`)
          } else {
            const useOldEndStyle = Vue.prototype.$app.$route?.meta?.useOldEndStyle || 0
            if (useOldEndStyle) {
              el.classList.add('layer-finish')
            } else {
              addFinishNode()
            }
          }

          container.insertBefore(el, container.firstChild)
        },
        initAfter(store) {
          const vm = store._vm
          const { amBasic } = store.state.base

          if (vm.$point) {
            amBasic.user.uid && vm.$point.login(amBasic.user.uid)
          }
        }
      }
    },
    contact: {
      native: {
        getContactData: () => weSDK('getRecentContact')
      }
    },
    gift: {
      request: {
        getGiftCount: (p) =>
          buildApi('/activity_v2/collect_gift/gift_count')({
            ...p,
            target_uid: p.uid
          }).then((r) => r.count)
      }
    },
    rank: {
      request: {
        getRankData: buildApi('/activity_v1/common/rank'),
        getRankJackpot: buildApi('activity_v1/common/get_day_pool'),
        getRankResults: (p) => Promise.all([buildApi('/activity_v1/common/get_day_money_reward')(p), buildApi('/activity_v1/common/get_day_lucky_user')(p)]).then(([list, luck]) => ({ list, luck })),
        getMineRankData: buildApi('/activity_v1/common/get_user_money_reward'),
        getRankList: buildApi('/activity_v2/rank/rank_list'),
        getRankInfo: buildApi('/activity_v2/rank/rank_info')
      }
    },
    task: {
      request: {
        getTaskGroupList: buildApi('/activity_v2/task_group/get_group_list'),
        getTaskGroupInfo: buildApi('/activity_v2/task_group/get_group_info'),
        getTaskInfo: buildApi('/activity_v2/task_group/get_task_info'),
        recvTaskReward: buildApi('/activity_v2/task_group/receive_task_reward'),
        getTaskConfig: buildApi('/activity_v2/task_group/config')
      }
    },
    checkin: {
      request: {
        getChargeStatus: buildApi('/activity_v2/sign/get_condition_sign_state'), //获取签到状态 0-不满足 1-满足
        doSign: buildApi('/activity_v2/sign/do_sign'), //进行签到
        getBroadcastList: buildApi('/activity_v2/sign/get_sign_broadcast'), //获取广播
        getSignLog: buildApi('/activity_v2/sign/get_sign_record'), //获取签到记录
        getRewardLog: buildApi('/activity_v2/sign/get_sign_record') //获取奖励记录
      }
    },
    team: {
      request: {
        getMultiTeaSStatus: buildApi('activity_v2/team/get_users_status'),
        getTeamInfo: buildApi('/activity_v2/team/get_team_info'),
        sendTeamInvite: buildApi('/activity_v2/team/send_team_invite'),
        acceptTeamInvite: buildApi('/activity_v2/team/accept_team_invite'),
        refuseTeamInvite: buildApi('/activity_v2/team/refuse_team_invite'),
        getPetName: buildApi('/activity_v2/team/get_team_name'),
        editPetName: buildApi('/activity_v2/team/edit_team_name')
      }
    },
    lottery: {
      request: {
        levelLottery: buildApi('/activity_v1/qi_xi/bridge_level_lottery'),
        getUserCoin: buildApi('/activity_v1/common/user/get_coin'),
        getLotteryLog: buildApi('/activity_v1/common/get_lottery_log'),
        getLotteryCount: buildApi('/activity_v1/common/get_lotteryChip_count'),
        buyLotteryProp: buildApi('/activity_v1/common/buy_lotteryChip'),
        getUserLuckyValue: (e) => e
      }
    },
    exchange: {
      request: {
        getExchangeConfig: buildApi('/activity_v2/exchange_store/get_config'),
        exchange: buildApi('/activity_v2/exchange_store/exchange'),
        multiExchange: buildApi('/activity_v2/exchange_store/multi_exchange'),
        getExchangeInfo: buildApi('/activity_v2/exchange_store/get_exchange_info'),
        getExchangeListInfo: buildApi('/activity_v2/exchange_store/get_exchange_list_info')
      }
    },
    storage: {
      request: {
        setAutoPurchase: buildApi('/activity_v2/lottery/set_auto_purchase')
      }
    },
    reward: {
      request: {
        getRewardMeta: buildApi('/activity_v2/common/get_reward_meta_common')
      }
    },
    purchase: {
      request: {
        getDirectStoreInfoList: buildApi('/activity_v2/direct_store/get_direct_store_info_list'),
        getDirectStoreInfo: buildApi('/activity_v2/direct_store/get_direct_store_info')
      }
    }
  }
}

let finishNodeNum = 0
function addFinishNode() {
  if (finishNodeNum) return
  let deeplink = ''
  if (weSDK.compareAppVersion('5.16.30') !== '<') {
    deeplink = 'wespydeeplink://activity_center_page'
  } else {
    deeplink = 'wespydeeplink://tab_main'
  }

  let VM = Vue.extend({
    template: `
    <a-section pl="0" pr="0" pt="0" pb="0" p="fixed" pz="100000" bg-c="rgba(0, 0, 0, 0.76)">
      <a-section class="fx-col fx-center" wh="100% 5" pc>
        <a-section bg-i="https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/OM2XHddH.png?_w=170&_h=182"></a-section>
        <a-section class="ws-nowrap" f=".3 #fff 500" mt=".5" mb=".62">活动已结束，去看看其他活动吧</a-section>
        <a-button1 bg-i="https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/yFW6fkQ8.png?_w=310&_h=85" @a-tap="$we('jumpDeepLink', '${deeplink}')"></a-button1>
      </a-section>
    </a-section>
    `
  })
  const div = document.createElement('div')
  div.id = 'activityEnd'
  const body = document.querySelector('body')
  body?.appendChild(div)
  new VM().$mount('#activityEnd')
  body.style.overflow = 'hidden'
  finishNodeNum++
}

// export function setConfig(obj) {
//   Object.keys(obj).forEach((key) => {
//     if (amConfig.hasOwnProperty(key)) {
//       amConfig[key] = obj[key]
//     }
//   })
//   return amConfig
// }

// export function getConfig(key) {
//   console.log(key)
//   console.log(amConfig)
//   return key ? (amConfig.hasOwnProperty(key) ? amConfig[key] : null) : amConfig
// }
