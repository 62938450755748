<template lang="pug">
component(v-bind='$attrs', bg-s='contain', @a-tap='doShare()', :is='isCanShare ? "a-button1" : "a-position"', :class='{ grey: !isCanShare }')
</template>

<script>
import DLInstall from '@/lib/dynamic-link'
import { getQueryObj, getQueryStr } from '@sub/utils/page-jump-methods'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      link: ''
    }
  },

  computed: {
    images() {
      return this._storeGetters('images')
    },
    text() {
      return this._storeGetters('text')
    },

    useUrl({ item }) {
      const query = getQueryObj(window.location.search)
      const extra = {
        id: item.id
      }

      return `${window.location.origin}${window.location.pathname}${getQueryStr({ ...query, ...extra })}`
    },

    useDeeplink() {
      return `wespydeeplink://http?url=${encodeURIComponent(this.useUrl)}`
    },

    isCanShare() {
      return this.item.id
    }
  },

  methods: {
    async initLink() {
      if (this.link) {
        return
      }
      const { getAsyncLink } = await DLInstall()
      this.link = await getAsyncLink({
        page: 'wespydeeplink',
        scene: 'weplay',
        payload: this.useDeeplink
      })
    },

    async doShare() {
      if (!this.isCanShare) {
        return
      }
      await this.initLink()

      this.$we(
        'setShareData',
        JSON.stringify({
          title: this.$text(this.text.shareTitle, this.item),
          desc: '',
          imgUrl: this.images.shareIconUrl,
          link: this.link,
          wespyDeeplink: this.useDeeplink,
          success: '',
          cancel: ''
        })
      )

      if (this.link) {
        const actId = this.$amApiParam().act_id
        const activity_id = ['U', 'Q'].includes(this._region) && this._isIos ? actId + '' : actId

        this.$we('doShare', JSON.stringify({ activity_id, share_type: [7, 9, 10, 11] }))
      } else {
        this.$we('shareDirect', JSON.stringify({ share_dest: 1, media_type: 2 }))
      }
    }
  }
}
</script>

<style></style>
