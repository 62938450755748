import { render, staticRenderFns } from "./ui-tab-change.vue?vue&type=template&id=86b8cc58&scoped=true&lang=pug"
import script from "./ui-tab-change.vue?vue&type=script&lang=js"
export * from "./ui-tab-change.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86b8cc58",
  null
  
)

export default component.exports