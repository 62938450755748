const pageCenter = 'jkllwccN'

export default [
  {
    path: '/activity/v2/voice-center2/main-j/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[0]
      },
      pageCenter,
      keepAlive: true
    }
  },

  {
    path: '/activity/v2/voice-center2/detail-j/index.html',
    component: () => import('./detail.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[1]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/preview-j/index.html',
    component: () => import('./preview.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[2]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/create-j/index.html',
    component: () => import('./create.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[3]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/task-j/index.html',
    component: () => import('./task.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[4]
      },
      pageCenter
    }
  },

  // {
  //   path: '/activity/v2/voice-center2/apply-j/index.html',
  //   component: () => import('./apply.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.json.id
  //       to.meta.title = to.meta.json.title[5]
  //     },
  //     pageCenter
  //   }
  // },

  {
    path: '/activity/v2/voice-center2/raiders-j/index.html',
    component: () => import('./raiders.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[5]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/share-j/index.html',
    component: () => import('./share.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[6]
      },
      pageCenter
    }
  },

  // {
  //   path: '/activity/v2/voice-center2/creator-j/index.html',
  //   component: () => import('./creator.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.json.id
  //       to.meta.title = to.meta.json.title[8]
  //     },
  //     pageCenter
  //   }
  // },

  {
    path: '/activity/v2/voice-center2/rate-j/index.html',
    component: () => import('./rate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[7]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/illustrate-j/index.html',
    component: () => import('./illustrate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[8]
      },
      pageCenter
    }
  },

  // {
  //   path: '/activity/v2/voice-center2/explain-j/index.html',
  //   component: () => import('./explain.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.json.id
  //       to.meta.title = to.meta.json.title[9]
  //     },
  //     pageCenter
  //   }
  // },

  {
    path: '/activity/v2/voice-center2/auxiliary-j/index.html',
    component: () => import('./auxiliary.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[10]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/rank-info-j/index.html',
    component: () => import('./rank-info.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[11]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/new-j/index.html',
    component: () => import('./new.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[12]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center2/new-apply-j/index.html',
    component: () => import('./new-apply.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[13]
      },
      pageCenter
    }
  }
]
