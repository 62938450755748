<template lang="pug">
a-position.ws-nowrap(:h='h', v-bind='$attrs', v-html='content', :class='getAlign', :lh='h')
</template>

<script>
import { isA } from '../utils/config'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },
    content: {
      type: String,
      default: ''
    },
    h: {
      type: [Number, String],
      default: '0.3'
    },
    align: {
      type: String,
      default: 'center'
    }
  },
  computed: {
    getAlign() {
      if (this.align === 'c' || this.align === 'center') {
        return 'txt-c'
      } else {
        return isA ? 'txt-r' : 'txt-l'
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
