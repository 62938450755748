<template lang="pug">
a-section(v-bind='$attrs')
  .rankCommon(v-if='!isA', :style='`background-image: url(${key2value(image.rowWrapper)})`')
    a-section(v-for='(item, index) in top4_n', :key='index', wh='7.5 1.46')
      a-position(:bg-i='image.commonBg', pc, bg-s='100%')
        a-section(v-if='isShowLastRank', :bg-i='image[item.rise]', pc='y', pl='.12')
        a-position.fx-row.fx-c-center.fx-m-center(:bg-i='image.star', ta, pc='y', pl='.4', :f='`.24 ${textColor.star} 600`') {{ index + 4 }}
        a-avatar.avatar-border(wh='.9', v-imgLazy='item.avatar || image.empty', :uid='item.uid', pc='y', pl='1.22', :style='item.uid ? `border: .02rem solid ${borderColor.other};` : ""')
        a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', pl='2.32', :f='`.22 ${textColor.other} 500`') {{ $ts(item.nickname, 6) }}
        a-position.fx-row.fx-c-center(v-if='showVal', h='.34', pc='y', pl='5.19')
          a-section(:bg-i='image.scoreIconOther')
          a-section(:f='`.23 ${textColor.score} 500`', h='.34', lh='.35') {{ props.notCompress ? item.value1 : item.value }}{{ props.unit || '' }}
        a-position(v-if='item.ringImg', wh='.8', pc='y', pr='1.55', :bg-i='item.ringImg')
  .rankCommon(v-else, :style='`background-image: url(${key2value(image.rowWrapper)})`')
    a-section(v-for='(item, index) in top4_n', :key='index', wh='7.5 1.46')
      a-position(:bg-i='image.commonBg', pc, bg-s='100%')
        a-section(v-if='isShowLastRank', :bg-i='image[item.rise]', pc='y', pr='.12')
        a-position.fx-row.fx-c-center.fx-m-center.txt-c.fx-shrink(:bg-i='image.star', ta, pc='y', pr='.4', :f='`.24 ${textColor.star} 600`') {{ index + 4 }}
        a-avatar.avatar-border(wh='.9', v-imgLazy='item.avatar || image.empty', :uid='item.uid', pc='y', pr='1.22', :style='item.uid ? `border: .02rem solid ${borderColor.other};` : ""')
        a-position.ws-nowrap.txt-r(wh='1.5 .27', lh='.27', pc='y', pr='2.32', :f='`.22 ${textColor.other} 500`') {{ $ts(item.nickname, 8) }}
        a-position.fx-row.fx-c-center(v-if='showVal', h='.34', pc='y', pr='4.99')
          a-section(:bg-i='image.scoreIconOther')
          a-section(:f='`.23 ${textColor.score} 500`', h='.34', lh='.35') {{ props.notCompress ? item.value1 : item.value }}
        a-position(v-if='item.ringImg', wh='.8', pc='y', pl='1.55', :bg-i='item.ringImg')
</template>
<script>
import { key2value } from '../utils/util'
import { isA } from '@sub/ui-components/utils/config'
export default {
  props: {
    top4_n: {
      default: () => [],
      type: Array
    },

    props: {
      default: () => {},
      type: Object
    },
    showVal: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isA
    }
  },

  computed: {
    length() {
      return this.top4_n?.length
    },

    image({ props }) {
      return props?.image
    },

    textColor({ props }) {
      return props?.textColor
    },

    borderColor() {
      return this.props?.borderColor || {}
    },

    isShowLastRank({ props }) {
      return props?.isShowLastRank
    }
  },

  methods: {
    key2value
  }
}
</script>

<style>
.red {
  border: 1px solid red;
}
.rankCommon {
  width: 7.5rem;
  height: 100%;
  background-size: 7.5rem 100%;
  background-position: 0% 0%;
}
</style>
