<template lang="pug">
  a-section#ui-audio-control.ui-audio-control.txt-c(v-bind='attrs', wh='.6')
    template(v-if='showBgmIcon')
      a-position.ui-audio-control__icon--open(v-show='storageInfo.uiOpenVoice', wh='100%', :bg-i='image.open || require("@/assets/images/header/music1.png")', bg-s='contain', pc, @a-tap='toggleVoice')
      a-position.ui-audio-control__icon--close(v-show='!storageInfo.uiOpenVoice', wh='100%', :bg-i='image.close || require("@/assets/images/header/music0.png")', bg-s='contain', pc, @a-tap='toggleVoice')

    audio(v-for='(item, index) in audioResources', :key='index', preload='auto', v-bind='item', :class="`ui-audio-control__audio--${item?.ref || ''}`")
</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import isArray from 'lodash/isArray'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },
    showBgmIcon: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {}
  },

  computed: {
    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    audioResources({ uiMeta }) {
      const getAssets = (key) => get(this.$route.meta.assets, key, '') || key
      return get(uiMeta, 'props.audioResources', []).map((item) => ({
        ...item,
        loop: item.loop || false,
        src: getAssets(item.url)
      }))
    },

    storageInfo() {
      return this.$store.state.storage.localStorage
    }
  },

  created() {
    this.setVoiceState(true)
    // 暂存方法
    this.$storage.setAudioControlMethods({
      audioPlay: this.audioPlay.bind(this),
      audioPause: this.audioPause.bind(this),
      audioLoad: this.audioLoad.bind(this),
      allAudioPause: this.audioPause.bind(this),
      allAudioReload: this.allAudioReload.bind(this),
      changeBgmUrl: this.changeBgmUrl.bind(this)
    })
  },

  destroyed() {
    document.body.removeEventListener('touchend', this.init, { once: true })
    document.querySelectorAll('audio').forEach((el) => {
      el.removeEventListener('error', this.handleAudioError)
    })
  },

  mounted() {
    document.body.addEventListener('touchend', this.init, { once: true })
    document.querySelectorAll('audio').forEach((el) => {
      el.addEventListener('error', this.handleAudioError, { once: true })
    })
  },

  onPageShow() {
    this.init()
  },

  onPageHide() {
    this.allAudioPause()
  },

  methods: {
    changeBgmUrl() {
      const bgm = this.audioResources.filter((item) => item.ref.includes('bgm'))[0]
      if (bgm) {
        this.$refs[bgm.ref].src = get(this.$route.meta.assets, bgm.url1, '') || bgm.url1
        this.audioLoad(bgm.ref)
        this.init()
      }
    },
    handleAudioError(e) {
      switch (e.target.error.code) {
        case e.target.error.MEDIA_ERR_ABORTED:
          console.warn(e.target.src, 'You aborted the video playback.')
          break
        case e.target.error.MEDIA_ERR_NETWORK:
          console.warn(e.target.src, 'A network error caused the audio download to fail.')
          break
        case e.target.error.MEDIA_ERR_DECODE:
          console.warn(e.target.src, 'The audio playback was aborted due to a corruption problem or because the video used features your browser did not support.')
          break
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          console.warn(e.target.src, 'The video audio not be loaded, either because the server or network failed or because the format is not supported.')
          break
        default:
          console.warn(e.target.src, 'An unknown error occurred.')
          break
      }
    },
    init() {
      if (this.storageInfo.uiOpenVoice && !this._isInVoice) {
        this.getRef('bgm')
          ?.play()
          .catch((e) => {
            this.updateVoiceState(false)
            console.log('ui-audio-control init:', e)
          })
      }
    },

    setVoiceState(state = true) {
      this.$store.dispatch('storage/init', { uiOpenVoice: state })
    },

    updateVoiceState(state = true) {
      this.$store.commit('storage/update', { uiOpenVoice: state })
    },

    toggleVoice() {
      this.$store.dispatch('storage/toggle', 'uiOpenVoice')
      if (this.storageInfo.uiOpenVoice) {
        this.init()
      } else {
        this.allAudioPause()
      }
    },

    allAudioPause(refs) {
      refs = refs || this.audioResources.map((item) => item.ref)
      refs.forEach((ref) => this.audioPause(ref))
      this.setVoiceState(false)
    },

    audioPause(ref) {
      this.getRef(ref)?.pause()
    },

    audioPlay(ref) {
      console.log('audioPlay ref=', ref, '声效开关=', this.storageInfo.uiOpenVoice)
      if (this.storageInfo.uiOpenVoice) {
        this.getRef(ref)?.play()
      }
    },

    audioLoad(ref) {
      this.getRef(ref)?.load()
    },

    allAudioReload(refs) {
      refs.map((r) => {
        this.getRef(r)?.load()
      })
    },

    getRef(ref) {
      const refs = this.$refs[ref]
      return isArray(refs) ? refs[0] : refs
    }
  }
}
</script>

<style lang="scss" scoped></style>
