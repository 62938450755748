<template lang="pug">
a-position.ui-ring-share(ref='newRing', v-bind='{ pt: -1000, ...$attrs, ...bgStyle }')
  //- 背景图
  a-img.ui-ring-share__img(:src='isUseMock ? mockImageData.ringShareBg : assetsConfig[image.ringShareBg]', wh='100%', pc)
  //- avatar相关
  slot(v-if='$scopedSlots["avatar"]', name='avatar', v-bind='{ data: userInfo }')
  a-position.ui-ring-share__avatar-wrapper(v-else, v-bind='avatarStyle')
    a-img.ball.object-fit.ui-ring-share__avatar-wrapper__avatar(:src='userInfo.avatar', wh='100%', pc)
    a-img.ball.object-fit.ui-ring-share__avatar-wrapper__border(v-if='image.avatarBorder', :src='isUseMock ? mockImageData.avatarBorder : assetsConfig[image.avatarBorder]', v-bind='avatarBorderStyle')
  //- 昵称相关
  slot(v-if='$scopedSlots["nickname"]', name='nickname', v-bind='{ data: userInfo }')
  a-position.txt-no-wrap.ui-ring-share__nickname(v-else, v-bind='nicknameWrapStyle', v-html='$tf($ts(userInfo.nickname, nicknameMaxSize))')
  //- 日期相关
  slot(v-if='$scopedSlots["date"]', name='date', v-bind='{ data: { date } }')
  a-position.txt-no-wrap.ui-ring-share__date(v-else, v-bind='dateStyle', v-html='$tf(dateText, date)')

  slot
</template>

<script>
import get from 'lodash/get'
import { shareMixin } from '@/utils/share-mixins'
import { getTextConfig, mockImageData, mockStyleData } from './utils/config'

export default {
  mixins: [shareMixin],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      mockImageData,
      textConfig: getTextConfig()
    }
  },

  computed: {
    assetsConfig() {
      return this.$route.meta.assets
    },

    key({ uiMeta }) {
      return get(uiMeta, 'key', '')
    },

    // 是否使用mock数据
    isUseMock({ uiMeta }) {
      return get(uiMeta, 'props.isUseMock', false)
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    useStyle({ uiMeta }) {
      return this.isUseMock ? mockStyleData : get(uiMeta, 'props.style', {})
    },

    bgStyle({ useStyle }) {
      return get(useStyle, 'bg', {})
    },

    avatarStyle({ useStyle }) {
      return get(useStyle, 'avatar', {})
    },

    avatarBorderStyle({ useStyle }) {
      return {
        wh: '100%',
        pc: 'xy',
        ...useStyle.avatarBorder
      }
    },

    nicknameWrapStyle({ useStyle }) {
      return get(useStyle, 'nicknameWrap', {})
    },

    dateStyle({ useStyle }) {
      return get(useStyle, 'date', {})
    },

    dateText({ uiMeta, textConfig }) {
      return get(uiMeta, 'props.dateText', textConfig.dateText) || textConfig.dateText
    },

    handleToast({ uiMeta }) {
      return get(uiMeta, 'props.toast', {})
    },

    dateFormat({ uiMeta, textConfig }) {
      return get(uiMeta, 'props.dateFormat', textConfig.dateFormat) || textConfig.dateFormat
    },

    nicknameMaxSize({ uiMeta }) {
      return get(uiMeta, 'props.nicknameMaxSize', 6)
    },

    date({ dateFormat }) {
      const time = get(this.$base.amBasic(), 'time', 0)
      return this.$dayjsTz(time).format(this.$tf(dateFormat))
    },

    userInfo() {
      return get(this.$base.amBasic(), 'user', {})
    }
  },

  created() {
    this.$uiComp.setUiCompConfig({
      compName: 'uiRingShare',
      compKey: this.key,
      value: {
        share2Circle: this.share2Circle.bind(this),
        save2Album: this.save2Album.bind(this),
        closeShareDialog: this.closeShareDialog.bind(this)
      }
    })
  },

  methods: {
    // cache是否缓存
    async share2Circle(id, extra, cache = true) {
      const el = this.$refs.newRing.$el

      this.$pointReport('ClickH5', { btn_name: '分享到玩友圈', ring_id: id })

      document.body.classList.add('loading')
      await this.shareImgToCircle({
        el,
        cache,
        extra
      })
      document.body.classList.remove('loading')

      // toast
      this.handleToast?.sharing && this.$toast(this.$tf(this.handleToast?.sharing))
    },

    async save2Album(id, cache = true) {
      const el = this.$refs.newRing.$el

      this.$pointReport('ClickH5', { btn_name: '保存图片', ring_id: id })

      document.body.classList.add('loading')
      await this.saveImage2Album({ el, cache })
      document.body.classList.remove('loading')

      // toast
      this.handleToast?.saving && this.$toast(this.$tf(this.handleToast?.saving))
    },

    closeShareDialog(id) {
      this.$pointReport('ClickH5', { btn_name: '放进背包', ring_id: id })
    }
  }
}
</script>

<style lang="scss" scoped>
.object-fit {
  object-fit: 100%;
}
</style>
