import get from 'lodash/get'
import { isA } from '../../utils/config'

export const bottomDefaultSVG = {
  HW: 'https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/gxb5msL0.svg?_w=225&_h=150',
  weplay: 'https://fe-center.weplayapp.com/page-center/assets/rio4D9OM/LvwQ7b57.svg?_w=225&_h=150'
}

export const openDefaultPag = {
  HW: 'https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/zpOisqpA.pag',
  weplay: 'https://fe-center.weplayapp.com/page-center/assets/rio4D9OM/BtcM0hBu.pag'
}

export const defaultStyle = {
  // 碎片数量的样式
  chipNumStyle: { wh: '.98 .28', pl: 2.51, pt: 1.87, fs: 0.26, fw: 600 },
  // 开启记录的样式
  openRecordStyle: { pr: 0.38, pt: 1.85 },
  // 戒指的定位,可3个，可4个
  ringGroupConfigStyle: [
    {
      wh: 1.76,
      pl: 0.4,
      pt: 4.29
    },
    {
      wh: 1.76,
      pl: 2.63,
      pt: 3.8
    },
    {
      wh: 1.76,
      pr: 0.4,
      pt: 4.29
    }
  ],
  // 戒指上的标签的位置
  ringTagStyle: { pl: -0.2, pt: 0.12 },
  // 已获得图片的位置
  getImgStyle: { pr: -0.1, pt: -0.2 },
  // 宝箱的位置
  boxStyle: { pc: 'x', pt: 7.5 },
  // 底部pag的样式
  bottomSVGStyle: { wh: 3.9, pc: 'x', pt: 6.36 },
  // 打开宝箱pag的样式
  openPagStyle: { wh: '150%', pc: 'pc' },
  // 重置按钮的位置
  resetBtnStyle: { pc: 'x', pt: 9.09 },
  // 重置文案的位置
  resetTextStyle: { pc: 'x', pt: 9.83 },
  // 开启宝箱按钮的位置
  openBtnStyle: { pc: 'x', pt: 10.32 }
}
