<template lang="pug">
a-section.ui-attack-wrap(v-bind='{ ...$attrs, ...position }')
  a-position.ui-attack-bullet(v-for='item in maxNum', :key='item', :bg-i='bulletImg || bulletImage', :wh='bulletWH || $attrs.wh', :class='classMap[item]', bg-s='contain') 
</template>

<script>
import anime from 'animejs'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { useMutationObserver } from '@vueuse/core'
import { size } from '@/utils/set-font-size'

const MAX_NUM = 10

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {
        return {
          props: {
            bullet: '',
            bulletWH: '',
            maxNum: MAX_NUM,
            isScale: true,
            scaleVal: 0
          }
        }
      }
    },

    bulletImg: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      observer: {},
      position: {}
    }
  },

  computed: {
    bulletImage({ uiMeta }) {
      return get(uiMeta, 'props.bullet', '')
    },

    maxNum({ uiMeta }) {
      return get(uiMeta, 'props.maxNum', MAX_NUM) || MAX_NUM
    },

    bulletWH({ uiMeta }) {
      return get(uiMeta, 'props.bulletWH', '') || ''
    },

    isScale({ uiMeta }) {
      return get(uiMeta, 'props.isScale', true)
    },

    scaleVal({ uiMeta }) {
      return get(uiMeta, 'props.scaleVal', 0)
    },

    classMap({ maxNum }) {
      let obj = {}
      for (let i = 1; i <= maxNum; i++) {
        obj[i] = this.genAnimeClass(i)
      }
      return obj
    }
  },

  mounted() {
    anime.set(`.ui-attack-bullet`, { translateY: 0, translateX: 0 })
    this.initObserver()
  },

  destroyed() {
    this.removeObserver()
  },

  methods: {
    // duration1 从起始点到终点的时间
    // duration2 在终点定格的时间
    attack({ beginId, endId, num, duration1 = 500, duration2 = 0, stagger = 100, rotate = 0, keyframes2 = [], endCallBack = () => {}, updateCallBack = () => {} }) {
      const beginDom = document.querySelector(beginId)
      const endDom = document.querySelector(endId)
      const begin = beginDom?.getBoundingClientRect()
      const end = endDom?.getBoundingClientRect()
      this.fixPosition(begin)

      return new Promise((res) => {
        this.$nextTick(() => {
          const translateX = end?.x - begin?.x + 'px'
          const translateY = end?.y - begin?.y + 'px'
          let keyframes = [
            { translateY: 0, translateX: 0, zIndex: 10, rotate },
            { translateY, translateX, scale: this.isScale ? this.scaleVal : 1, rotate }
          ]
          if (duration2) {
            if (keyframes2) {
              keyframes = keyframes.concat(keyframes2)
            } else {
              keyframes = keyframes.concat(new Array(Math.ceil(duration2 / duration1)).fill({ translateY, translateX, scale: this.isScale ? 0 : 1 }))
            }
          }
          const data = {
            targets: `.ui-attack-bullet${num}`,
            keyframes,
            loop: 1,
            easing: 'linear',
            duration: duration1 + duration2,
            delay: anime.stagger(stagger),
            update(a) {
              updateCallBack(a)
            }
          }

          const bulletAnime = anime(data)
          bulletAnime.finished.then(() => {
            this.animeReset(num)
            endCallBack()
            res()
          })
        })
      })
    },

    animeReset(num) {
      anime.set(`.ui-attack-bullet${num}`, { translateY: 0, translateX: 0, opacity: 0, scale: 1, zIndex: -1, rotate: 0 })
    },

    genAnimeClass(num) {
      let className = ''
      for (let i = 1; i <= num; i++) {
        className += `ui-attack-bullet${this.maxNum + 1 - i} `
      }
      return className.trim()
    },

    initObserver() {
      const domList = document.querySelectorAll('.ui-attack-bullet')
      for (let i = 1; i <= this.maxNum; i++) {
        this.observer[i] = useMutationObserver(
          domList[i - 1],
          throttle(([{ target }]) => {
            const { transform } = target.style
            if (!transform.includes('translateY(0') || !transform.includes('translateX(0')) {
              target.style.opacity = 1
            }
          }, 300),
          {
            attributes: true
          }
        )
      }
    },

    removeObserver() {
      Object.values(this.observer).forEach((item) => {
        item.stop()
      })
    },

    fixPosition(begin) {
      const endDom = document.querySelector('.ui-attack-wrap')
      const end = endDom?.getBoundingClientRect()
      const left = end?.x - begin?.x
      const top = end?.y - begin?.y

      this.position = {
        pl: ((this.getRemToPx(endDom.style.left) || 0) - left) / size,
        pt: ((this.getRemToPx(endDom.style.top) || 0) - top) / size
      }
    },

    getRemToPx(rem) {
      if (rem.includes('rem')) {
        return size * +rem.replace('rem', '')
      } else if (rem.includes('px')) {
        return +rem.replace('px', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-attack-bullet {
  opacity: 0;
}

.ui-attack-wrap {
  left: 0;
}
</style>
