const constellationList = {
  1: {
    name: 'aquarius',
    label: '水瓶座',
    startTime: '1/21',
    endTime: '2/18'
  },
  2: {
    name: 'pisces',
    label: '双鱼座',
    startTime: '2/19',
    endTime: '3/20'
  },
  3: {
    name: 'aries',
    label: '白羊座',
    startTime: '3/21',
    endTime: '4/20'
  },
  4: {
    name: 'taurus',
    label: '金牛座',
    startTime: '4/21',
    endTime: '5/20'
  },
  5: {
    name: 'gemini',
    label: '双子座',
    startTime: '5/21',
    endTime: '6/21'
  },
  6: {
    name: 'cancer',
    label: '巨蟹座',
    startTime: '6/26',
    endTime: '7/22'
  },
  7: {
    name: 'leo',
    label: '狮子座',
    startTime: '7/23',
    endTime: '8/22'
  },
  8: {
    name: 'virgo',
    label: '处女座',
    startTime: '8/23',
    endTime: '9/22'
  },
  9: {
    name: 'libra',
    label: '天秤座',
    startTime: '9/23',
    endTime: '10/22'
  },
  10: {
    name: 'scorpio',
    label: '天蝎座',
    startTime: '10/23',
    endTime: '11/21'
  },
  11: {
    name: 'sagittarius',
    label: '射手座',
    startTime: '11/22',
    endTime: '12/21'
  },
  12: {
    name: 'capricorn',
    label: '摩羯座',
    startTime: '12/22',
    endTime: '1/9'
  }
}
const currentIssue = 11

export const year = 2023

export const getCurrentIssue = constellationList[currentIssue].name

export const pageCenter = 'TYToQqdC'
