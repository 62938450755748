import Vue from 'vue'

export const uiComponents = requireComponents(require.context('../ui-components', true, /ui-[^/].*\.vue/))

function getModName(path) {
  const paths = path.split('/')
  const fileName = paths[paths.length - 1]

  return fileName.split('.')[0]
}

function requireComponents(context, keyFilter = '') {
  return context
    .keys()
    .filter((key) => key.match(keyFilter))
    .map((key) => ({
      ...context(key).default,
      key
    }))
    .reduce((acc, cur) => {
      acc[getModName(cur.key)] = cur
      return acc
    }, {})
}

Object.keys(uiComponents).forEach((item) => {
  Vue.component(item, uiComponents[item])
})
