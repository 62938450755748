// import html2canvas from 'html2canvas'

// 生成快照
export async function convertToImage(container, options = {}) {
  const { default: html2canvas } = await import('html2canvas')
  // 设置放大倍数
  const scale = window.devicePixelRatio
  // 传入节点原始宽高
  const _width = container.offsetWidth
  const _height = container.offsetHeight

  let { width, height } = options
  width = width || _width
  height = height || _height
  console.log(width, height)
  // html2canvas配置项
  const ops = {
    ...options,
    // scale: scale * 2,
    scale,
    dpi: 300,
    width,
    height,
    useCORS: true,
    allowTaint: false,
    backgroundColor: null
  }

  return html2canvas(container, ops).then((canvas) => {
    // 返回图片的二进制数据
    return canvas.toDataURL('image/png')
  })
}
