import get from 'lodash/get'
import { $dayjs, $dayjsTz } from '@/utils/dayjs'
import { progressCalculate1, differenceCalculate, pageJump, getQuery, push, replace, pageJump2, formatPreview, formatPreview1, asyncAll, filterInvisibleStrAr, getDayUnit, getNumUnit, getPropsUnitType, getUnit, getImgSrc } from '@/utils/utils'
import { reportTTI, pageLoadPointHandle } from '@/utils/global-point'
import localStorage from '@/utils/local-storage'
import sessionStorage from '@/utils/session-storage'
import { mountedProps } from '@sub/utils/global-props'
import { mountedMethods } from '@sub/utils/global-methods'

let installed = false

const handler = {
  install(ctx) {
    if (installed) {
      return
    }

    const textFormat = (text, ...args) => {
      text = get(ctx.prototype.$app, `$route.meta.text.${text}`, text)
      args.forEach((item) => (text = text.replace('{{}}', item)))
      return text
    }

    const textSlice = (text, num = 5) => {
      text = text || ''
      let array = Array.from(filterInvisibleStrAr(text))
      return array.length > num ? `${array.slice(0, num).join('')}...` : text
    }

    const textSliceAr = (text, num = 5) => {
      text = text || ''
      let array = Array.from(filterInvisibleStrAr(text))
      return array.length > num ? `${array.splice(0, num).join('')}...` : text
    }

    ctx.prototype['$tf'] = textFormat
    ctx.prototype['$ts'] = textSlice
    ctx.prototype['$tsAr'] = textSliceAr
    ctx.prototype['$dayjs'] = $dayjs
    ctx.prototype['$dayjsTz'] = $dayjsTz
    ctx.prototype['$progressCalculate'] = progressCalculate1
    ctx.prototype['$differenceCalculate'] = differenceCalculate
    ctx.prototype['$localStorage'] = localStorage
    ctx.prototype['$sessionStorage'] = sessionStorage
    ctx.prototype['$pageJump'] = pageJump
    ctx.prototype['$getQuery'] = getQuery
    ctx.prototype['$push'] = push
    ctx.prototype['$replace'] = replace
    ctx.prototype['$pageJump2'] = pageJump2
    ctx.prototype['$formatPreview'] = formatPreview
    ctx.prototype['$formatPreview1'] = formatPreview1
    ctx.prototype['$asyncAll'] = asyncAll
    ctx.prototype['$getDayUnit'] = getDayUnit
    ctx.prototype['$getNumUnit'] = getNumUnit
    ctx.prototype['$getUnit'] = getUnit
    ctx.prototype['$getPropsUnitType'] = getPropsUnitType
    ctx.prototype['$getImgSrc'] = getImgSrc
    ctx.prototype['$reportTTI'] = reportTTI
    ctx.prototype['$pageLoadPointHandle'] = pageLoadPointHandle
    mountedProps(ctx.prototype)
    mountedMethods(ctx.prototype)

    installed = true
  }
}

export function install(app) {
  app.use(handler)
}
