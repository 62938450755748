<template lang="pug">
a-section(v-bind='$attrs')
  svg(:width='computedWidth', :height='computedHeight', xmlns='http://www.w3.org/2000/svg' style="overflow: visible")
    // 描边文字
    text(:x='x', :y='strokeTextY', :font-size='fontSize', :font-family='fontFamily', :fill='strokeColor', :stroke='strokeColor', :stroke-width='strokeWidth', text-anchor='middle', dominant-baseline='middle', :style='{ textShadow: "none" }')
      tspan(v-html="strokeHtmlContent")
    // 实心文字
    text(:x='x', :y='fillTextY', :font-size='fontSize', :font-family='fontFamily', :fill='fillColor', text-anchor='middle', dominant-baseline='middle')
      tspan(v-html="fillHtmlContent")
</template>

<script>
export default {
  props: {
    // 文字内容
    content: {
      type: [String, Number],
      default: ''
    },
    // 字体属性
    f: {
      type: String,
      default: '.8 #fff 700' // 默认字体大小、颜色和粗细
    },
    // 描边宽度
    strokeWidth: {
      type: [String, Number],
      default: 5
    },
    // 画布宽度 传递的单位是rem
    width: {
      type: [String, Number]
    },
    // 画布高度 传递的单位是rem
    height: {
      type: [String, Number]
    },
    // 描边颜色
    strokeColor: {
      type: String,
      default: 'red'
    },
    // 字体
    fontFamily: {
      type: String,
      default: 'PingFang SC'
    },
    // 画布相对于计算出来的宽高的比例--几乎无用
    scale: {
      type: [String, Number],
      default: 1
    }
  },
  computed: {
    // 计算字体大小（像素）
    fontSize() {
      const size = parseFloat(this.f.split(' ')[0]) * parseFloat(getComputedStyle(document.documentElement).fontSize)
      return size
    },
    // 解析填充颜色
    fillColor() {
      return this.f.split(' ')[1]
    },
    /**
     * 计算最大行宽（像素）。
     *
     * @returns {number} 最大行宽（像素）。
     */
    computedWidth() {
      const width = this.getTextWidth(this.content + '').width * this.scale
      // 如果已指定固定宽度，则直接返回固定宽度；否则返回基于文本计算的宽度
      return this.width ? this.width * parseFloat(getComputedStyle(document.documentElement).fontSize) : width
    },

    /**
     * 计算总高度（像素）。
     *
     * @returns {number} 总高度（像素）。
     */
    computedHeight() {
      const height = this.getTextWidth(this.content + '').height * this.scale
      // 如果已指定固定高度，则直接返回固定高度；否则返回基于文本计算的高度
      return this.height ? this.height * parseFloat(getComputedStyle(document.documentElement).fontSize) : height
    },
    // 计算中心点的 x 坐标
    x() {
      return this.computedWidth / 2
    },
    // 计算描边文字的 y 坐标
    strokeTextY() {
      // 保证文字在容器中垂直居中
      return this.computedHeight / 2
    },
    // 计算实心文字的 y 坐标
    fillTextY() {
      // 保证文字在容器中垂直居中
      return this.computedHeight / 2
    },
    strokeHtmlContent() {
      return this.convertSpanToTspan(this.content)
    },
    fillHtmlContent() {
      return this.convertSpanToTspan(this.content)
    }
  },
  methods: {
    /**
     * 获取文本的宽度。
     *
     * @param {string} text - 要计算宽度的文本。
     * @returns {number} 文本的宽度。
     */
    getTextWidth(text) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      // 设置字体样式
      context.font = this.fontSize + 'px ' + this.fontFamily

      // 测量文本的 TextMetrics 对象
      const metrics = context.measureText(text)
      // 从 TextMetrics 中获取文本的高度
      const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent

      // 返回文本的宽度
      return {
        width: metrics.width,
        height: height
      }
    },
    /**
     * 将 span 标签替换为 tspan 标签 将color属性替换为fill属性
     *
     * @param {string} text - 输入的文本内容
     * @returns {string} - 替换后的文本内容
     */
    convertSpanToTspan(text) {
      text = text + ''
      return text.replace(/<span([^>]*)style="([^"]*)color:\s*([^;"]+)/g, '<tspan$1style="$2fill:$3;').replace(/<\/span>/g, '</tspan>')
    }
  }
}
</script>
<style lang="scss" scoped>
text {
  stroke-linejoin: round; /*路径转角为圆角*/
}
</style>
