import { size } from '@/utils/set-font-size'
/**
 * @module pad-fixed
 */

export default {
  $getFixedPl: getFixedX,
  $getFixedPr: getFixedX,
  $getFixedPb: getFixedPb
}

let diffX = null
let diffY = null
/**
 * 获取固定的 X 值，以适应页面宽度变化。
 * @param {number} remVal - 要设置的 rem 单位的 X 值。
 * @returns {number} 返回固定的 X 值。
 */
export function getFixedX(remVal) {
  if (diffX === null) {
    diffX = document.body?.getBoundingClientRect()?.x || 0
  }

  return +remVal + diffX / size
}
/**
 * 获取固定的 paddingBottom 值，以适应页面高度变化。
 * @param {number} remVal - 要设置的 rem 单位的 paddingBottom 值。
 * @returns {number} 返回固定的 paddingBottom 值。
 */
export function getFixedPb(remVal) {
  if (diffY === null) {
    const pageHeight = document.querySelector('.page-container')?.getBoundingClientRect()?.height || 0
    diffY = pageHeight === 0 ? null : window.innerHeight - pageHeight
  }

  if (diffY > 0) {
    return +remVal + diffY / size
  } else {
    return +remVal
  }
}
