<template lang="pug">
a-section.ui-vote-wrapper-top3(v-bind='itemStyle')
  //- 头像
  a-position.ui-vote-wrapper-top3__avatar(wh='1.68', bdr='50%', pc='x', pt='0.36', :bg-i='item.video_cover_url')
    a-position.ui-vote-wrapper-top3__img(pc, wh='100%', bg-s='contain', :bg-i='useImages[`headBg${item.rank}`]')
    a-position.ui-vote-wrapper-top3__num(pt='-.2', pl='-.08', :bg-i='useImages[`crown${item.rank}`]')
    //- 播放
    play-btn.ui-vote-wrapper-top3__play(pc, :bg-i='images.playBtn', :item='item')
    //- 作品编号
    a-position.ui-vote-wrapper-top3__id(pc='x', pb='0.04', wh='100% 0.36', lh='0.36', ta, :style='getStyle("workId")') {{ item.id }}
  //- 用户昵称
  a-position.ui-vote-wrapper-top3__nickname.ws-nowrap(pc='x', pt='2.06', wh='100% 0.34', lh='0.34', ta, :style='getStyle("nickname")') {{ $ts(item.nickname, 7) }}

  a-position.ui-vote-wrapper-top3-list.fx-row.fx-c-center.fx-m-between(pt='2.44', wh='100% 0.44')
    //- 占位
    a-section(wh='0.44')
    a-section.fx-row.fx-c-center
      a-section.ui-vote-wrapper-top3-list__icon.fx-shrink(:bg-i='images.scoreIcon', bg-s='contain', wh='0.34')
      //- 票数
      a-section.ui-vote-wrapper-top3-list__ticket.ws-nowrap(:style='getStyle("voteNumber")') {{ item.vote_count }}
    //- 分享
    a-section.ui-vote-wrapper-top3-list__ticket-share(w='0.54', h='0.44')
      share-btn(pc, :bg-i='useImages.shareBtn', wh='0.44', :item='item')

  //- 投票
  vote-btn(pt='3', pc='x', :bg-i='images.voteBtn', :item='item')
</template>

<script>
import { get } from 'lodash'
import { RANK } from '../../const'

import voteBtn from '../vote-btn.vue'
import playBtn from '../play-btn.vue'
import shareBtn from '../share-btn.vue'

const topThreeItem = {
  w: 2.2,
  h: 3.76
}
const topThreePositions = {
  1: {
    pt: 0,
    pc: 'x'
  },
  2: {
    pt: 0.9,
    pl: 0.31
  },
  3: {
    pt: 0.9,
    pr: 0.31
  }
}

export default {
  components: {
    voteBtn,
    playBtn,
    shareBtn
  },

  props: {
    // topThree
    type: {
      type: String,
      default: 'topThree'
    },
    item: {
      type: Object,
      default: () => ({})
    },
    images: {
      type: Object,
      default: () => ({})
    },
    useStyle: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {}
  },

  computed: {
    useImages({ images }) {
      return images[RANK]
    },

    itemStyle({ item }) {
      return { ...topThreeItem, ...(topThreePositions[item.rank] || {}) }
    }
  },

  methods: {
    getStyle(target) {
      return get(this.useStyle, `${this.type}.${target}`, {})
    }
  }
}
</script>

<style scoped></style>
