import { isA } from '@sub/ui-components/utils/config'

export const exchangeStyleConfig = {
  exchangeWrapperStyle: {
    pc: 'x',
    pt: '2.02',
    pb: '0.84',
    w: '6.54'
  },

  exchangeUnitStyle: {
    fs: '.22',
    fc: '#FFFFFF',
    fw: '600',
    lh: '0.3',
    borderWidth: '2',
    borderColor: '#5B32FF',
    [isA ? 'pl' : 'pr']: '0',
    pb: '0'
  },

  exchangeNameStyle: {
    w: '1.66',
    pc: 'x',
    pt: '1.58',
    ta: 'x',
    fs: '.2',
    fc: '#4240C9',
    fw: '400'
  },

  priceContentStyle: {
    f: '.18 #FFEF9C 500'
  },

  exchangeButtonStyle: {
    pc: 'x',
    pb: '0'
  }
}

export const exchangeConfig = {
  name: '兑换商店',
  type: 'ui-exchange-store',
  // 弹窗名
  key: 'exchangeStore',
  props: {
    image: {
      bg: 'bg/bg-1-0-2',
      rewardBg: 'exchange/exchange-item-bg',
      priceBg: 'exchange/price-bg',
      exchangeChipIcon: 'exchange/chip-icon',
      exchangeButton: 'btn/exchange'
    },

    exchangeList: [
      {
        id: 1704352,
        num: '1x',
        price: 260,
        tagImage: 'tag/new'
      },
      {
        id: 1704320,
        num: '1x',
        price: 260,
        tagImage: 'tag/1'
      },
      {
        id: 1704320,
        num: '1x',
        price: 260,
        tagImage: 'tag/new'
      },
      {
        id: 1704352,
        num: '1x',
        price: 260,
        tagImage: 'tag/1'
      }
    ],

    customizedStyle: {
      exchangeWrapperStyle: {
        pc: 'x',
        pt: '2.02',
        pb: '0.84',
        w: '6.54'
      },

      exchangeUnitStyle: {
        f: '.22 #FFFFFF 600',
        lh: '0.3',
        borderWidth: '2',
        borderColor: '#5B32FF',
        pl: '0',
        pb: '0'
      },

      exchangeNameStyle: {
        w: '1.66',
        pc: 'x',
        pt: '1.58',
        f: '0.2 #4240C9 400'
      },

      priceContentStyle: {
        f: '.18 #FFEF9C 500'
      },

      exchangeButtonStyle: {
        pc: 'x',
        pb: '0'
      }
    }
  }
}
