import get from 'lodash/get'

import { handleNumKW, region, regionMap, teamNameMap } from '../../util'

export const formatItem = ({ type, item, index }) => {
  const rank = type === 'list' ? index + 1 : get(item, 'rank', 0)
  const value = handleNumKW(get(item, 'score', 0))

  const userInfos = get(item, 'user_infos', [])
  let defaultUser = {
    avatar: '',
    nickname: regionMap[region] || '虚位以待',
    uid: ''
  }

  const user1 = { ...get(userInfos, '0', defaultUser) }
  const user2 = { ...get(userInfos, '1', defaultUser) }

  const teamInfo = get(item, 'member_extra_info', {})

  return {
    rank,
    value,
    value1: get(item, 'score', 0),
    user1: { ...user1, avatar: get(user1, 'head_img_url', '') },
    user2: { ...user2, avatar: get(user2, 'head_img_url', '') },
    teamInfo,
    ringImg: get(teamInfo, 'ring_img', ''),
    teamName: get(teamInfo, 'team_name', ''),
    hasMember: userInfos.length === 2
  }
}
export const defaultTeamName = teamNameMap[region]

export const defaultRankConfig = {
  rankLength: 10,
  rankType: 1,
  periodType: 0,
  extra: {
    rank_member_type: 4
  }
}

export const defaultImage = {
  head: 'rank/bg/header.png',
  body: 'rank/bg/row.png',
  top1Item: 'rank/bg/top1.png',
  top2Item: 'rank/bg/top2.png',
  top3Item: 'rank/bg/top3.png',
  normalItem: 'rank/bg/common.png',
  mine: 'rank/bg/mine.png',
  scoreIcon: '',
  rankIcon1: 'rank/icon/top1.png',
  rankIcon2: 'rank/icon/top2.png',
  rankIcon3: 'rank/icon/top3.png',
  rankIconOther: 'rank/icon/star.png',
  rankMineOther: '',
  notInRank: 'rank/icon/not-in-rank.png',
  defaultAvatar: 'rank/icon/empty.png',
  inviteFriends: '',
  heart: 'rank/icon/heart.png'
}

export const defaultTextStyle = {
  rank1TeamInfoColor: '',
  rank2TeamInfoColor: '',
  rank3TeamInfoColor: '',
  normalTeamInfoColor: '',
  myTeamInfoColor: '',
  rank1UserInfoColor: '#C86408',
  rank2UserInfoColor: '#767EFF',
  rank3UserInfoColor: '#FF5FBC',
  normalUserInfoColor: '#F07217',
  myUserInfoColor: '#ffffff',
  rank1ScoreColor: '#C86408',
  rank2ScoreColor: '#C86408',
  rank3ScoreColor: '#C86408',
  normalScoreColor: '#C95400',
  myScoreColor: '#ffffff',
  rank1AvatarBgBorderColor: '#FFFFFF',
  rank2AvatarBgBorderColor: '#7987FF',
  rank3AvatarBgBorderColor: '#FF6BB5',
  normalAvatarBorderColor: '#FFFFFF',
  myAvatarBorderColor: '#8785FF',
  rank1ScoreBgColor: '#FFFFFF',
  rank2ScoreBgColor: '#FFFFFF',
  rank3ScoreBgColor: '#FFFFFF',
  rank1ScoreBgBorderColor: '#F2BD89',
  rank2ScoreBgBorderColor: '#92B7FF',
  rank3ScoreBgBorderColor: '#FF92B2',
  normalTeamInfoBgColor: '',
  myTeamInfoBgColor: '',
  normalRankColor: '#FFE1B4',
  myRankColor: '#ffffff'
}
