import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import { isA } from '../../utils/config'
const commonTextStyle = { fs: '0.26', fw: '400', fc: '#FFFFFF' }
const plr = isA ? 'pl' : 'pr'

export const styleConfig = {
  uiConfirmDialog: {
    closeBtn: { pt: '-0.6', [plr]: 0 },
    text: { w: '5.25', lh: '138%', pc: 'x', pt: '1.26', ta: 'center', ...commonTextStyle },
    noTip: { pc: 'x', pt: '2.42' },
    cancelBtn: { pl: '0.69', pb: '0.74' },
    confirmBtn: { pr: '0.69', pb: '0.74' }
  }
}

/**
 * 默认值
 */
export const defaultImage = {
  bg: 'confirmDialog/bg', // 弹窗背景图
  noTip0: 'confirmDialog/noTip-0', // 不再弹出二次确认弹窗-空
  noTip1: 'confirmDialog/noTip-1', // 不再弹出二次确认弹窗-勾选
  confirmBtn: 'confirmDialog/confirmBtn', // 购买确认按钮
  cancelBtn: 'confirmDialog/cancelBtn' // 购买取消按钮
}

export const defaultKey = {
  noSecondConfirm: 'noSecondConfirm', // 控制是否勾选‘不再提示此弹窗’的storage的key
  restoreSecondConfirm: 'restoreSecondConfirm' // 控制是否勾选‘恢复确认弹窗’的storage的key
}

export const defaultSpecialStyle = {
  text: {},
  hightlightText: {},
  noTip: {},
  cancelBtn: {},
  confirmBtn: {}
}
