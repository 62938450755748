export default [
  {
    path: `/activity/v2/voiceRoomActivityRank/main-u/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
        to.meta.id = to.meta.json.id
      },
      // TODO:
      // id: 217,
      // TODO:
      // expire: '2023/xx/xx 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'uxLFrJ65'
    }
  },
  {
    path: `/activity/v2/voiceRoomActivityRank/reward-detail-u/index.html`,
    component: () => import('./rewardDetail.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.titleReward
        to.meta.id = to.meta.json.id
      },
      // TODO:
      // id: 217,
      // TODO:
      // expire: '2023/xx/xx 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'uxLFrJ65'
    }
  },
  {
    path: `/activity/v2/voiceRoomActivityRank/history-u/index.html`,
    component: () => import('./history.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.titleHistory
        to.meta.id = to.meta.json.id
      },
      // TODO:
      // id: 217,
      // TODO:
      // expire: '2023/xx/xx 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'uxLFrJ65'
    }
  }
]
