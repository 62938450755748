import merge from 'lodash/merge'
import isNumber from 'lodash/isNumber'
import weSDK from '@/lib/we-sdk'
import localStorage from '@/utils/local-storage'
import config from '@/config'
import store from '@/store'
import { IS_DEV, IS_APP, IS_WEPLAY } from '@/utils/env'
import { buildApi1 } from '@sub/utils/request'
import { pageLoadPointHandle } from '@/utils/global-point'
const { LOCAL } = config.$env

const getDeviceLevelInfo = buildApi1('/activity_v2/common/get_device_level')

const deviceLevelKey = '__device_level_key__'

export default {
  namespaced: true,

  state: {
    frame: 0,
    frames: [],
    avgFrame: 0,
    // 设备分级，0-5级 -1为未初始化
    deviceLevel: -1,
    // 上报间隔
    reportInterval: 30000,
    samplingFlag: false
  },

  mutations: {
    initDefault(state, payload = {}) {
      merge(state, payload)
    },

    updateState(state, payload = {}) {
      merge(state, payload)
    }
  },

  actions: {
    calculateFrame({ commit, state }) {
      // 会玩线上不计算帧率
      if (!IS_WEPLAY && !IS_DEV) {
        return
      }

      if (!state.samplingFlag) {
        return
      }

      const loop = genLoop((count) => {
        const frames = [...state.frames, count]
        commit('updateState', { frame: count })
        commit('updateState', { frames })
        commit('updateState', { avgFrame: calculateAvgFrame(frames) })
      })

      window.requestAnimationFrame(loop)
    },

    // 获取设备分级信息
    async getDeviceLevelInfo({ commit, dispatch, state }) {
      try {
        commit('updateState', { samplingFlag: getSamplingFlag() })

        // 暂时只有WP有
        if (!IS_WEPLAY) {
          return
        }

        // 非端内 return
        if (!IS_APP) {
          return
        }

        const deviceLevel = localStorage.get(deviceLevelKey)
        if (isNumber(deviceLevel) && deviceLevel > -1) {
          commit('updateState', { deviceLevel: deviceLevel })
        } else {
          const { mobileModel } = await weSDK('getDeviceInfo')
          const { level } = await getDeviceLevelInfo({ device_model: mobileModel })
          commit('updateState', { deviceLevel: level })
          localStorage.set(deviceLevelKey, level)
        }

        // 符合采样要求，才上报
        if (state.samplingFlag && state.deviceLevel > -1) {
          dispatch('doFramePoint')
        }
      } catch (error) {
        console.error('getDeviceLevelInfo', error)
      }
    },

    doFramePoint({ state, dispatch }) {
      setTimeout(() => {
        pageLoadPointHandle(store.state.base.meta, state.avgFrame, `运行时平均帧率-${state.deviceLevel}`)
        dispatch('doFramePoint')
      }, state.reportInterval)
    }
  }
}

function genLoop(callback) {
  let count = 0
  let prevTimestamp = 0

  return function loop(timestamp) {
    if (prevTimestamp) {
      count++
      if (timestamp - prevTimestamp >= 1000) {
        callback(count)
        prevTimestamp = timestamp
        count = 0
      }
    } else {
      prevTimestamp = timestamp
    }
    window.requestAnimationFrame(loop)
  }
}

function calculateAvgFrame(frames) {
  const total = frames.reduce((total, frame) => total + frame, 0)
  return total / frames.length
}

// 测试服全量，线上控制采样率
function getSamplingFlag() {
  if (IS_DEV || LOCAL) {
    return true
  } else {
    // 表示采样率 0.001
    const rate = 1000
    const randomNum = Math.ceil(Math.random() * rate)
    return randomNum === 1
  }
}
