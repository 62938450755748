<template lang="pug">
a-section.ui-static-dialogs
  a-dialog.ui-static-dialogs__item(v-for='(d, i) in dialogsList', :key='`static-dialog-${i}`', :name='d.key', :bg-i='d.dialogBg')
    a-position.ui-static-dialogs__item__close(:bg-i='closeImage', bg-s='100%', v-bind='getCloseStyle(d)', @a-tap='$dialogs[d.key].close()')
    a-position.scroll-y(v-if='d.dialogContent', v-bind='d.scrollWrapStyle')
      a-section(:bg-i='d.dialogContent', bg-s='100%')
        slot(:name='`${d.key}-content`')
        component(:is='getComp(e.tapWrapStyle.tapType)', v-for='(e, idx) in getEvents(d)', :key='idx', v-bind='e.tapWrapStyle', @a-tap='eventsTap({ ...e, key: d.key, index: idx })', :class='{ "light-area": showTapArea }')
    template(v-else)
      component(:is='getComp(e.tapWrapStyle.tapType)', v-for='(e, idx) in getEvents(d)', :key='idx', v-bind='e.tapWrapStyle', @a-tap='eventsTap({ ...e, key: d.key, index: idx })')

    slot(:name='d.key')
</template>

<script>
import get from 'lodash/get'
import { commonImage, isA } from '@sub/ui-components/utils/config'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    showTapArea: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {
    closeImage({ uiMeta }) {
      return get(uiMeta, 'props.close', commonImage.dialogClose) || commonImage.dialogClose
    },

    dialogsList({ uiMeta }) {
      return get(uiMeta, 'props.dialogs', [])
    }
  },

  methods: {
    // 静态弹窗关闭按钮适配阿服
    getCloseStyle(d) {
      let { closeStyle = {} } = d
      if (closeStyle.pl !== undefined && !isA) {
        closeStyle.pr = closeStyle.pl
        delete closeStyle.pl
      } else if (closeStyle.pr !== undefined && isA) {
        closeStyle.pl = closeStyle.pr
        delete closeStyle.pr
      }
      return d.closeStyle
    },
    getComp(tapType) {
      return tapType === 'btn' ? 'a-button1' : 'a-position'
    },

    getEvents(data) {
      return get(data, 'events', [])
    },

    // tap事件callback
    eventsTap({ type, params, key, index }) {
      if (type === 'jumpLink') {
        // 站外链接跳转
        window.location.href = params.link
      } else if (type === 'jumpActivity') {
        // 活动间跳转
        this.$pageJump2(params.path, params.query)
      } else if (type === 'closeDialog') {
        // 关闭弹窗
        this.$dialogs[key].close({ all: params.all || false })
      } else if (type === 'jumpPay') {
        // 跳转充值页面
        this.$we('pay')
      } else if (type === 'deeplink') {
        // 客户端deeplink
        this.$we('jumpDeepLink', params.path)
      } else {
        // 自定义事件
        this.$emit('tapCallback', { dialogKey: key, index, params })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
