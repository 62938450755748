export default {
  set(key, val) {
    return window.sessionStorage.setItem(key, JSON.stringify(val))
  },

  get(key) {
    const val = window.sessionStorage.getItem(key)
    if (val === null) return null
    try {
      return JSON.parse(val)
    } catch (e) {
      window.sessionStorage.removeItem(key)
      return null
    }
  },
  remove(key) {
    return window.sessionStorage.removeItem(key)
  },
  clear() {
    return window.sessionStorage.clear()
  }
}
