<template lang="pug">
  a-section.ui-avatar-preview
    a-dialog.ui-avatar-preview-dialog--gift(v-for='(item, index) in gifArr', :name='`ui-avatar-dialog-${item.id}`', :key='`gif-${index}`', :bg-i='item.frame || bg1')
      a-section.ui-avatar-preview__bg(:bg-i='assetConfig[item.src]', v-bind='getContentStyle1(gifStyle)')
      slot(name='gif-common')
      a-section.tap-through.ui-avatar-preview__frame(:bg-i='item.frame || bg1', pc)
        a-position.ws-nowrap.ui-avatar-preview__frame__name(ta, :pb='namePb', pc='x', :fc='nameColor', fs='.36', fw='700', lh='.46') {{ `${$getTag(item.id)}-${getName(item.id)}` }}
        slot(name='gif-desc')

    a-dialog.ui-avatar-preview-dialog--props(v-for='(item, index) in propsArr', :name='`ui-avatar-dialog-${item}`', :key='`static-${index}`', :bg-i='bg2')
      a-section.ui-avatar-preview__bg(:bg-i='getCover(item)', v-bind='getContentStyle2(staticStyle)')
      slot(name='static-common')
      a-position.ws-nowrap.ui-avatar-preview__frame__name(ta, :pb='namePb', pc='x', :fc='nameColor', fs='.36', fw='700', lh='.46') {{ `${$getTag(item)}-${getName(item)}` }}
      slot(name='static-desc')
</template>

<script>
import get from 'lodash/get'
import { getContentStyle1, getContentStyle2 } from './config'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    gifArr({ uiMeta }) {
      return get(uiMeta, 'props.gifConfig', [])
    },
    propsArr({ uiMeta }) {
      return get(uiMeta, 'props.staticProps', [])
    },

    gifStyle({ uiMeta }) {
      return get(uiMeta, 'props.extra.gifStyle', '')
    },
    staticStyle({ uiMeta }) {
      return get(uiMeta, 'props.extra.staticStyle', '')
    },

    nameColor({ uiMeta }) {
      return get(uiMeta, 'props.style.nameColor', '#fff')
    },
    namePb({ uiMeta }) {
      return get(uiMeta, 'props.style.namePb', '0.26')
    },

    bg1({ uiMeta }) {
      return get(uiMeta, 'props.image.frame', '')
    },
    bg2({ uiMeta }) {
      return get(uiMeta, 'props.image.staticBg', '')
    },

    assetConfig() {
      return this.$route.meta.assets
    },
    avatarGender() {
      return this.$base.amBasic().user.avatarGender || 2
    }
  },

  methods: {
    getContentStyle1,
    getContentStyle2,

    getCover(id) {
      return this.avatarGender === 1 ? this.$propsConfig(id).male_cover : this.$propsConfig(id).female_cover
    },

    getName(id) {
      return this.avatarGender === 1 ? this.$propsConfig(id).male_name : this.$propsConfig(id).female_name
    }
  }
}
</script>
