const cumulativeLine1Config = {
  '1:1:1': [[{ l: 0.25, d: 'lr', percent: 5 }], [{ l: 2, d: 'lr', percent: 15 }], [{ l: 2.4, d: 'lr', percent: 20 }]],
  '1:1:2': [[{ l: 0.25, d: 'lr', percent: 5 }], [{ l: 2, d: 'lr', percent: 15 }], [{ l: 2.4, d: 'lr', percent: 20 }]],
  '1:2:1': [[{ l: 0.25, d: 'lr', percent: 5 }], [{ l: 2, d: 'lr', percent: 15 }], [{ l: 2.4, d: 'lr', percent: 20 }]],
  '1:2:2': [[{ l: 0.25, d: 'lr', percent: 5 }], [{ l: 2, d: 'lr', percent: 15 }], [{ l: 2.4, d: 'lr', percent: 20 }]],
  '2:1:1': [[{ l: 0.25, d: 'lr', percent: 5 }], [{ l: 2, d: 'lr', percent: 15 }], [{ l: 2.4, d: 'lr', percent: 20 }]],
  '2:1:2': [[{ l: 0.25, d: 'lr', percent: 5 }], [{ l: 2, d: 'lr', percent: 15 }], [{ l: 2.4, d: 'lr', percent: 20 }]]
}

const cumulativeLine23Config = [
  [{ l: 0.8, d: 'lr', percent: 25 }],
  [
    { l: 0.5, d: 'ald', percent: 25 },
    { l: 1.3, d: 'ud', percent: 35 }
  ],
  [
    { l: 0.5, d: 'adr', percent: 35 },
    { l: 1, d: 'rl', percent: 40 }
  ],
  [{ l: 3.15, d: 'rl', percent: 60 }],
  [{ l: 1.05, d: 'rl', percent: 65 }],
  [
    { l: 0.5, d: 'ard', percent: 65 },
    { l: 1.38, d: 'ud', percent: 75 }
  ],
  [
    { l: 0.5, d: 'adl', percent: 75 },
    { l: 2.6, d: 'lr', percent: 100 }
  ]
]

export function getCumulativeConfig(line1Type) {
  return [...cumulativeLine1Config[line1Type], ...cumulativeLine23Config]
}

const nodeLine1Config = {
  '1:1:1': [
    { value: 600, percent: 5 },
    { value: 3000, percent: 16.2 },
    { value: 10000, percent: 22.8 }
  ],
  '1:1:2': [
    { value: 600, percent: 5 },
    { value: 3000, percent: 14 },
    { value: 10000, percent: 19.5 }
  ],
  '1:2:1': [
    { value: 600, percent: 5 },
    { value: 3000, percent: 16 },
    { value: 10000, percent: 22.5 }
  ],
  '2:1:1': [
    { value: 600, percent: 10 },
    { value: 3000, percent: 18 },
    { value: 10000, percent: 22.5 }
  ],
  '1:2:2': [
    { value: 600, percent: 5 },
    { value: 3000, percent: 14.5 },
    { value: 10000, percent: 20 }
  ],
  '2:1:2': [
    { value: 600, percent: 8 },
    { value: 3000, percent: 16.2 },
    { value: 10000, percent: 20.6 }
  ]
}

const nodeLine23Config = [
  { value: 50000, percent: 40 },
  { value: 250000, percent: 60 },
  { value: 850000, percent: 100 }
]

export function getNodeConfig(line1Type) {
  return [...nodeLine1Config[line1Type], ...nodeLine23Config]
}

export function genCumulativeConfig() {
  return {
    style: {
      width: 750,
      height: 482
    },
    // 曲线类名1
    c_class: 'progress1',
    // 任务id
    taskId: 0,
    cumulativeType: [],
    canvasConfig: {
      x: 0.9,
      a_x: 6.6,
      y: 0.1
    },
    bgColor: '#abe5fe',
    brightColor: '#ffbb54',
    nodeList: [],
    hideBgType: [6],
    showSvgaType: [999, 6],
    // hideBgType: [6, 10, 11, 13],
    extra: {
      canPreview: false,
      showGiftCardBgColor: true,
      stylesConfig: { 23: { wh: '100%' }, 999: { wh: '150%' }, 6: { wh: '120%' } }
    }
  }
}
