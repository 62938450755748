const ptConfig = {
  1: '-1.44',
  2: '0.78',
  3: '2.97'
}

const defaultConfig = {
  iconPosition: { pc: 'x', pb: '-.45' },
  textPosition: { pc: 'x', pb: '-.7' },
  default_wh: 0.89,
  icon_ps: { pr: '-.05', pb: '0' },
  a_icon_ps: { pl: '-.05', pb: '0' },
  name_ps: { pc: 'x', pt: '0.95' }
}

// 奖励展示每个盒子相关配置
const rechargeGiftList = [
  {
    bg_num: 1,
    domPosition: { pl: '.7', pt: ptConfig[1] }
  },
  {
    bg_num: 1,
    domPosition: { pl: '2.25', pt: ptConfig[1] }
  },
  {
    bg_num: 2,
    domPosition: { pr: '.7', pt: ptConfig[1] }
  },
  {
    bg_num: 2,
    domPosition: { pr: '.75', pt: ptConfig[2] }
  },
  {
    bg_num: 2,
    domPosition: { pl: '.65', pt: ptConfig[2] }
  },
  {
    bg_num: 3,
    domPosition: { pl: '.85', pt: ptConfig[3] },
    textPosition: { pl: '3.2', pb: '-.36' }
  }
]

const pt = '.09'
// 奖励展示配置
// id_idx: 奖励id序号 wh: 奖励盒子宽高大小 bg_config icon_ps: 奖励角标定位 name_ps: 奖励名定位
const exhibitRewardList = [
  [{ list: [{ id_idx: 1 }], bg_config: { pc: 'x', pt } }],
  [{ list: [{ id_idx: 2 }], bg_config: { pc: 'x', pt } }],
  [
    { list: [{ id_idx: 3 }], bg_config: { pl: '.42', pt } },
    { list: [{ id_idx: 4 }], bg_config: { pr: '.42', pt } }
  ],
  [
    {
      wh: 0.66,
      bg_config: { pl: '.54', pt: 0.21, 'bg-c': '#F8F9FF', bdr: '50%' },
      list: [{ id_idx: 5, wh: 1.5, pc: 'pc' }],
      icon_ps: { pr: '-.3', pb: '-.1' },
      name_ps: { pc: 'x', pt: '.83' }
    },
    { list: [{ id_idx: 6 }], bg_config: { pr: '.42', pt } }
  ],
  [
    {
      wh: 0.66,
      bg_config: { pl: '.54', pt: 0.21, 'bg-c': '#F8F9FF', bdr: '50%' },
      list: [{ id_idx: 7, wh: 1.5, pc: 'pc' }],
      icon_ps: { pr: '-.3', pb: '-.1' },
      name_ps: { pc: 'x', pt: '.83' }
    },
    { list: [{ id_idx: 8 }], bg_config: { pr: '.42', pt } }
  ],
  [
    { list: [{ id_idx: 9 }], bg_config: { pl: '.9', pt: '.14' } },
    { list: [{ id_idx: 10 }], bg_config: { pl: '2.55', pt: '.14' } },
    { list: [{ id_idx: 11 }], bg_config: { pr: '.85', pt: '.14' } }
  ]
]

export default {
  defaultConfig,
  rechargeGiftList,
  exhibitRewardList
}
