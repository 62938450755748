export default [
  {
    path: '/activity-20220929-main-th',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      quickConfig: '9ada293cf793df578027981f8498fd98'
    }
  }
]
