export default [
  {
    path: '/activity-20230309-main',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = 735
        to.meta.title = '櫻之神聖國度'
      },
      pageCenter: 'biKpRavw'
    }
  }
]
