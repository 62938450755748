import store from '@/store'
import point from '@/lib/page-point'
import { IS_APP } from '@/utils/env'
/**
 * @module point
 */
export function genPointMethods() {
  return {
    startTime: 0,

    /**
     * @description 记录一个事件开始的时间
     */
    setStartTime() {
      this.startTime = performance.now()
    },

    /**
     * @description 一个事件结束，并上报埋点
     * @param {string} eventName 事件名
     * @param {object} [properties] 属性
     * @param {number} [sampleRate] 采样率，默认为 0.1
     */
    endAndTrack(eventName, properties = {}, sampleRate = 0.1) {
      if (!this.startTime) {
        console.warn('[point-methods][endAndTrack] has Error: ', '请先调用 setStartTime 方法')
        return
      }
      const duration = performance.now() - this.startTime

      if (eventName === 'LoadPage') {
        this.loadPage(duration, properties.scene, sampleRate, properties)
      } else {
        this.track(
          eventName,
          {
            event_duration: Math.floor(duration),
            ...properties
          },
          sampleRate
        )
      }
    },

    /**
     * @description 通用埋点方法
     * @param {string} eventName 事件名 #event_name
     * @param {object} [properties] 参数
     * @param {number} [sampleRate] 采样率，默认为 1
     */
    track(eventName, properties = {}, sampleRate = 1) {
      if (Math.random() > sampleRate) {
        return
      }

      try {
        const { act_id, uid } = store.getters['base/amApiParam'] || {}
        const { name } = store.state.base.amActivityInfo || {}
        point.track(eventName, {
          act_id,
          uid,
          title: name || '',
          is_app: IS_APP,
          ...properties
        })
      } catch (e) {
        console.warn('[point-methods][track] has Error: ', e)
      }
    },

    /**
     * utils
     */
    /**
     * @description 上报 LoadPage 相关数据
     * @param {number} duration 耗时
     * @param {string} scene 场景
     * @param {number} [sampleRate] 采样率，默认为 0.1
     * @param {object} [extra] 额外参数
     *  @param {string} extra.request_url 接口URL
     */
    loadPage(duration, scene, sampleRate = 0.1, extra = {}) {
      this.track(
        'LoadPage',
        {
          event_duration: Math.floor(duration),
          scene,
          ...extra
        },
        sampleRate
      )

      setTimeout(() => {
        console.warn(duration, scene, extra?.request_url)
      }, 1000)
    }
  }
}

export default genPointMethods()
