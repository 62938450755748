export default [
  {
    path: '/activity-20221214-main-ar',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: 'b8fa6361854a7f1b1b6f224b9bbb40bd',
      language: 'ar'
    }
  },
  {
    path: '/activity-20221214-rank-ar',
    component: () => import('./pages/main/rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: 'b8fa6361854a7f1b1b6f224b9bbb40bd',
      language: 'ar'
    }
  },

  {
    path: '/activity-20221214-main2-ar',
    component: () => import('./pages/main2/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: 'd9c0fe6f4fe687ba665f151281fb0b9d',
      language: 'ar'
    }
  },
  {
    path: '/activity-20221214-rank2-ar',
    component: () => import('./pages/main2/rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: 'd9c0fe6f4fe687ba665f151281fb0b9d',
      language: 'ar'
    }
  },

  {
    path: '/activity-20221214-recharge-ar',
    component: () => import('./pages/recharge/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: '25b3295a10b168db6f0f823e8b301fce',
      language: 'ar'
    }
  }
]
