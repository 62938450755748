import { isMock } from '../env'

export default async function getMockMeta() {
  if (process.env.NODE_ENV === 'production') {
    return
  }
  if (!isMock()) {
    return
  }

  // 暂停支持 mock 功能，请自行搭建 or 使用「导入配置」功能来实现 mock 数据
}
