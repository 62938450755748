export const testContact = [
  { gender: 1, uid: 1, nickname: '%昵称已重置', remark_name: '%昵称已重置', avatar: 'http://wespypicuser.afunapp.com/wespy_default_avatar.png' },
  { gender: 2, uid: 2, nickname: 'AndyShawdev2', remark_name: 'AndyShawdev2', avatar: 'http://wespypicuser.afunapp.com/Fkifb8tT9rWps2Bp6ArBvjKyFBLl' },
  { gender: 2, uid: 3, nickname: '代双', remark_name: '代双', avatar: 'http://wespypicuser.afunapp.com/FsFXylIG85G02iEC5prG2xyPkVUL' },
  { gender: 1, uid: 4, nickname: 'AndyShaw', remark_name: 'AndyShaw', avatar: 'http://wespypicuser.afunapp.com/FjxobYCOtiyVTglZ9j4u0w6H7GnU' },
  { gender: 1, uid: 5, nickname: '%昵称已重置', remark_name: '%昵称已重置', avatar: 'http://wespypicuser.afunapp.com/wespy_default_avatar.png' },
  { gender: 2, uid: 120000380, nickname: 'AndyShawdev2', remark_name: 'AndyShawdev2', avatar: 'http://wespypicuser.afunapp.com/Fkifb8tT9rWps2Bp6ArBvjKyFBLl' },
  { gender: 2, uid: 120000379, nickname: '代双', remark_name: '代双', avatar: 'http://wespypicuser.afunapp.com/FsFXylIG85G02iEC5prG2xyPkVUL' },
  { gender: 1, uid: 120000378, nickname: 'AndyShaw', remark_name: 'AndyShaw', avatar: 'http://wespypicuser.afunapp.com/FjxobYCOtiyVTglZ9j4u0w6H7GnU' },
  { gender: 1, uid: 120000377, nickname: '%昵称已重置', remark_name: '%昵称已重置', avatar: 'http://wespypicuser.afunapp.com/wespy_default_avatar.png' },
  { gender: 2, uid: 120000376, nickname: 'AndyShawdev2', remark_name: 'AndyShawdev2', avatar: 'http://wespypicuser.afunapp.com/Fkifb8tT9rWps2Bp6ArBvjKyFBLl' },
  { gender: 2, uid: 120000375, nickname: '代双', remark_name: '代双', avatar: 'http://wespypicuser.afunapp.com/FsFXylIG85G02iEC5prG2xyPkVUL' },
  { gender: 1, uid: 120000370, nickname: 'AndyShaw', remark_name: 'AndyShaw', avatar: 'http://wespypicuser.afunapp.com/FjxobYCOtiyVTglZ9j4u0w6H7GnU' }
]
