import { amConfig } from '../config'
import { testContact } from './utils/config'
import { getQuery1 as getQuery } from '@/utils/utils'
import { IS_DEV, IS_APP } from '@/utils/env'

const {
  modules: {
    contact: { native }
  }
} = amConfig

export default {
  namespaced: true,

  state: {
    contact: []
  },

  mutations: {
    // 初始化一些参数
    setContact(state, contact) {
      state.contact = contact
    }
  },

  actions: {
    async amSetContact({ commit }) {
      let contactData = await native.getContactData()
      // FIX: 没有好友列表时返回的是{}
      if (JSON.stringify(contactData) === '{}') {
        contactData = []
      }
      if (IS_DEV && !IS_APP && !window.sessionStorage.getItem('firstContact')) {
        let url = new URL(window.location.href)
        url.searchParams.append('contact', '1')
        window.location.href = url.toString()
        window.sessionStorage.setItem('firstContact', '1')
      }
      let { contact: contactFlag } = getQuery()
      const contact = contactData.length > 0 ? contactData : contactFlag ? testContact : []
      commit('setContact', contact)
    }
  }
}
