import _ from 'lodash'
import get from 'lodash/get'

import getQuery from '@sub/ui-runtime/core/utils/getQuery'
import { handleNumKW } from '../../util'

const query = getQuery()

export const formatName = (str, maxlength) => {
  return str?.length > maxlength ? `${str.slice(0, maxlength)}...` : str
}

export const formatItem = ({ item }) => {
  const member_extra_info = get(item, 'member_extra_info', {})
  let userInfos = get(item, 'user_infos', [{}, {}, {}, {}])
  const value = handleNumKW(get(item, 'score', 0))

  if (userInfos === null) {
    userInfos = [{}, {}, {}, {}]
  }

  while (userInfos?.length < 4) {
    userInfos.push({})
  }

  // 'C', 'U', 'J', 'A', 'K', 'T', 'M'
  const regionMap = { C: '虚位以待', U: 'Empty', J: '', A: '', K: '', T: 'ว่าง', M: 'Kosong', P: 'Empty' }
  const teamMap = { C: '队名', U: '', J: '', A: '', K: '', T: 'ชื่อทีม', M: 'Nama Pasukan', P: '' }
  const { region = 'U' } = query

  const totalLength = userInfos.length

  let support_list = get(item, 'support_list')
  if (support_list) {
    userInfos = support_list.map((item) => item.user_infos[0])
  }

  if (totalLength > userInfos.length) {
    userInfos = _.uniqBy([...userInfos, ...get(item, 'user_infos', [])], (item) => item.uid)
  }

  return {
    value,
    value1: get(item, 'score') || 0,
    rank: get(item, 'rank', 0),
    teamName: get(member_extra_info, 'team_name', regionMap[region]) || teamMap[region],
    coin: get(item, 'coin', 0),
    user1: {
      uid: get(userInfos[0], 'uid', 0),
      avatar: get(userInfos[0], 'head_img_url'),
      nickname: formatName(get(userInfos[0], 'nickname', regionMap[region]), 6)
    },
    user2: {
      uid: get(userInfos[1], 'uid', 0),
      avatar: get(userInfos[1], 'head_img_url'),
      nickname: formatName(get(userInfos[1], 'nickname', regionMap[region]), 6)
    },
    user3: {
      uid: get(userInfos[2], 'uid', 0),
      avatar: get(userInfos[2], 'head_img_url'),
      nickname: formatName(get(userInfos[2], 'nickname', regionMap[region]), 6)
    },
    user4: {
      uid: get(userInfos[3], 'uid', 0),
      avatar: get(userInfos[3], 'head_img_url'),
      nickname: formatName(get(userInfos[3], 'nickname', regionMap[region]), 6)
    }
  }
}
