import Vue from 'vue'
import { methodsInit } from '../../utils'
import { amConfig } from '../config'
import _ from 'lodash'

const {
  modules: {
    task: { request }
  }
} = amConfig

const { getGetters: getBasicGetters } = methodsInit('base')

let hasInit = false

export default {
  namespaced: true,

  state: {
    levelTasksInfo: {},
    dailyTasksInfo: {},
    extra: {
      dailyTasks: [],
      levelTasks: []
    },
    // 帮忙存一下任务中台中是否可兑换等一些信息
    buyTaskInfo: {
      canExchange: false
    },
    // 任务相关的配置
    taskConfig: {},
    // 存储多个任务红点
    taskReddots: {}
  },

  getters: {
    dailyTaskListSort({ dailyTasksInfo, extra: { dailyTasks } }) {
      const map = { 0: 1, 1: 2, 2: 0 }
      return dailyTasks.map((item) => ({ ...item, ...dailyTasksInfo[item.id] })).sort((a, b) => map[b.state] - map[a.state])
    },

    dailyTaskList({ dailyTasksInfo, extra: { dailyTasks } }) {
      return dailyTasks.map((item) => ({ ...item, ...dailyTasksInfo[item.id] }))
    },

    dailyTaskRedDot({ dailyTasksInfo }) {
      return Object.values(dailyTasksInfo).some((item) => item.state === 1)
    },

    // 用于判断任务中台入口是否需要显示红点
    buyTaskRedDot({ buyTaskInfo, dailyTasksInfo }) {
      return buyTaskInfo.canExchange || Object.values(dailyTasksInfo).some((item) => item.state === 1)
    }
  },

  mutations: {
    // 初始化一些参数
    initDefault(state, { dailyTasks = [], levelTasks = [] }) {
      if (hasInit) {
        console.error('task/initDefault 被执行了两次！！！很可能有 bug，请统一在 page.vue 中仅调用一次')
      }

      state.extra = {
        dailyTasks: [...state.extra.dailyTasks, ...dailyTasks],
        levelTasks: [...state.extra.levelTasks, ...levelTasks]
      }

      const dailyTasksInfo = dailyTasks.reduce((obj, item) => {
        obj[item.id] = {
          ...item,
          state: 0,
          max: item.max || 1,
          process: 0
        }
        return obj
      }, {})

      state.dailyTasksInfo = {
        ...state.dailyTasksInfo,
        ...dailyTasksInfo
      }

      const levelTasksInfo = levelTasks.reduce((obj, item) => {
        obj[item.id] = {
          ...item,
          stageStatus: {},
          value: 0
        }
        return obj
      }, {})

      state.levelTasksInfo = {
        ...state.levelTasksInfo,
        ...levelTasksInfo
      }

      hasInit = true
    },

    initDailyTaskItemDefault(state, taskId) {
      if (state.extra.dailyTasks.find((i) => i.id === taskId)) {
        return
      }
      state.extra.dailyTasks = [...state.extra.dailyTasks, { id: taskId }]
      state.dailyTasksInfo[taskId] = {
        state: 0,
        max: 1,
        process: 0
      }
    },

    initLevelTaskItemDefault(state, taskId) {
      if (state.extra.levelTasks.find((i) => i.id === taskId)) {
        return
      }
      state.extra.levelTasks = [...state.extra.levelTasks, { id: taskId }]
      state.levelTasksInfo[taskId] = {
        state: 0,
        max: 1,
        process: 0
      }
    },

    setDailyTaskItem(state, { id, data }) {
      state.dailyTasksInfo = {
        ...state.dailyTasksInfo,
        [id]: data
      }
    },

    setLevelTaskItem(state, { id, data }) {
      state.levelTasksInfo = {
        ...state.levelTasksInfo,
        [id]: data
      }
    },

    // 设置任务中台的是否可兑换信息
    setBuyTaskInfo(state, payload) {
      state.buyTaskInfo = payload
    },

    setTaskConfig(state, payload) {
      state.taskConfig = payload
    },

    setTaskReddots(state, payload) {
      Object.assign(state.taskReddots, payload)
    }
  },

  actions: {
    getTasksGroupList: _.throttle(
      async function ({ state, commit }) {
        const { group_list: groupList } = await request.getTaskGroupList(getBasicGetters('amApiParam'))
        const { task_list: taskList } = groupList[0] || []

        const { dailyTasks, levelTasks } = state.extra

        taskList?.forEach((item) => {
          const targetDailyTask = dailyTasks.find((t) => t.id === item.id)
          const targetLevelTask = levelTasks.find((t) => t.id === item.id)

          if (targetDailyTask && targetLevelTask) {
            console.warn(`taskId:${item.id}, 被同时配置成累计任务和每日任务，请检查`)
          }
          if (targetDailyTask) {
            commit('setDailyTaskItem', { id: item.id, data: formatDailyTask(item, targetDailyTask) })
          } else if (targetLevelTask) {
            commit('setLevelTaskItem', { id: item.id, data: formatLevelTask(item, targetLevelTask) })
          }
        })
      },
      100,
      { trailing: false }
    ),

    async getTargetTaskInfo({ state, commit }, id) {
      const { task_info: taskInfo } = await request.getTaskInfo({ ...getBasicGetters('amApiParam'), task_id: id })

      const { dailyTasks, levelTasks } = state.extra

      const targetDailyTask = dailyTasks.find((t) => t.id === id)
      const targetLevelTask = levelTasks.find((t) => t.id === id)

      if (targetDailyTask) {
        commit('setDailyTaskItem', { id, data: formatDailyTask(taskInfo, targetDailyTask) })
      } else if (targetLevelTask) {
        commit('setLevelTaskItem', { id, data: formatLevelTask(taskInfo, targetLevelTask) })
      }
      return taskInfo
    },

    // stageId = 0 累计全部领取
    // eslint-disable-next-line
    recvTaskReward({}, data) {
      let taskId
      let stageId = 0
      if (_.isObject(data)) {
        taskId = data.taskId
        stageId = data.stageId
      } else {
        taskId = data
      }
      return request.recvTaskReward({ ...getBasicGetters('amApiParam'), task_id: taskId, stage_id: stageId })
    },

    // 获取任务配置
    async getTaskConfig({ commit }) {
      const { list } = await request.getTaskConfig(getBasicGetters('amApiParam'))
      const task = {}
      list.forEach((item) => {
        task[item.id] = item
      })

      commit('setTaskConfig', formatRewards(task))
    }
  }
}

function formatDailyTask(data, targetTask) {
  const { condition_value, stages } = data
  const { complete_times, receive_times, repeat_times, state } = stages[0]

  return {
    ...targetTask,
    ...data,
    state: state,
    canReceiveNum: repeat_times,
    receiveNum: receive_times,
    max: repeat_times,
    process: receive_times,
    completeTimes: complete_times,
    value: condition_value[1] || 0
  }
}

function formatLevelTask(data, targetTask) {
  const { condition_value, stages } = data

  let canReceiveNum = 0
  let receiveNum = 0
  data.stages = stages.map((item) => {
    const { complete_times, receive_times, repeat_times } = item
    let state = 0
    if (complete_times > receive_times) {
      state = 1
    } else if (receive_times === complete_times && receive_times) {
      state = 2
    }

    canReceiveNum += repeat_times
    receiveNum += receive_times
    return {
      state,
      ...item
    }
  })

  let totalState = 0
  if (data.stages.some((item) => item.state === 1)) {
    totalState = 1
  } else if (data.stages.every((item) => item.state === 2)) {
    totalState = 2
  }

  return {
    ...targetTask,
    ...data,
    canReceiveNum,
    receiveNum,
    totalState,
    value: condition_value[1] || 0
  }
}

function formatRewards(taskConfig) {
  const vm = Vue.prototype.$app
  const userGender = vm.$base.amBasic().user.gender
  Object.keys(taskConfig).forEach((tId) => {
    const rewards = []
    taskConfig[tId].stages.forEach(({ rewards: itemRewards }) => {
      rewards.push(
        ...itemRewards.map((r) => {
          // 大于2个奖励则分性别
          const itemReward = r.length > 1 ? (userGender === 1 ? r[0] : r[1]) : r[0]
          return itemReward
        })
      )
      taskConfig[tId].rewards = rewards
    })
  })

  return taskConfig
}
