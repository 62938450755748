import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import { isA } from '../../utils/config'
import proxyAssets from '@sub/utils/proxy-assets'

const commonBtnStyleLeft = { pb: 0.74, [isA ? 'pr' : 'pl']: 0.69, 'bg-s': 'contain' }
const commonBtnStyleRight = { pb: 0.74, [isA ? 'pl' : 'pr']: 0.69, 'bg-s': 'contain' }

export const teamStyleConfig = {
  uiTeamInviteFriends: {
    teamMembersWrap: { wh: '1.2 1.7', pt: 2.52, [isA ? 'pr' : 'pl']: 0.57 },
    avatarStyle: { wh: 1.2, pt: 0 },
    nickname: { w: '100%', pt: 1.36 },
    lookBtn: commonBtnStyleLeft,
    inviteFriends: commonBtnStyleRight
  },

  uiTeamSearchContacts: {
    teamMembersStyle: {
      wrap: { wh: '5.4 1.7', pt: 1.5, pc: 'x' },
      item: { wh: '1.2 1.7' },
      avatar: { wh: 1.2, pt: 0 },
      nickname: { w: '100%', pt: 1.36 }
    },
    searchInputStyle: {
      wrap: { wh: '5 .68', pc: 'x', pt: 3.48 },
      input: { [isA ? 'paddingRight' : 'paddingLeft']: 0.72 }
    },
    searchList: {
      wrap: { wh: '5.4 2.8', pc: 'x', pt: 4.8 },
      item: { wh: '100% 1.06' },
      avatarStyle: { wh: '.84', [isA ? 'pr' : 'pl']: 0.2, pt: 0.08 },
      nickname: { w: 1.6, [isA ? 'pr' : 'pl']: 1.14, pt: 0.32, ta: isA ? 'r' : 'l' },
      line: { pc: 'x', pb: 0 },
      inviteBtn: { wh: '1.14 .5', [isA ? 'pr' : 'pl']: 4.06, pt: 0.25 },
      emptyText: { w: 4, pc: '1' }
    },
    close: { pt: 0, [isA ? 'pl' : 'pr']: 0.3 }
  },

  uiTeamInviteConfirm: {
    userStyle: {
      avatar: { wh: 1.2, pc: 'x', pt: '1.73' },
      nickname: { pt: 3.05, w: 2, pc: 'x' }
    },
    cancelBtn: commonBtnStyleLeft,
    inviteFriends: commonBtnStyleRight
  },

  uiTeamRecvInvitation: {
    recvTextStyle: { w: 5.6, pb: 4.83, fs: PAGE_REGION === 'M' ? 0.28 : 0.32, fw: 600, pc: 'x', ta: 'c' },
    teamMembersStyle: {
      wrap: { wh: '5.4 1.7', pt: 2.15, pc: 'x' },
      item: { wh: '1.2 1.7' },
      avatar: { wh: 1.2, pt: 0 },
      nickname: { w: '100%', pt: 1.36 }
    },
    rejectBtn: commonBtnStyleLeft,
    agreeBtn: commonBtnStyleRight
  },

  uiTeamConfirm: {
    textWrap: { wh: '5.6 .76', pc: 'x', pt: 1.7, fs: 0.28, lh: 0.38 },
    cancelBtn: commonBtnStyleLeft,
    confirmBtn: commonBtnStyleRight
  },

  uiTeamJoinSuccess: {
    teamNameWrap: { wh: '5, .58', lh: 0.58, fw: 600, pt: 2.61, pc: 'x' },
    teamMembersStyle: {
      wrap: { wh: '5.4 1.7', pt: 3.43, pc: 'x' },
      item: { wh: '1.2 1.7' },
      avatar: { wh: 1.2, pt: 0 },
      nickname: { w: '100%', pt: 1.36 }
    },
    successBtn: { pc: 'x', pb: 0.74 }
  },

  uiTeamInviteFail: {
    textWrap: { w: 5.5, pb: 4.02, pc: 'x', lh: PAGE_REGION === 'M' ? 0.28 : 0.35 },
    userStyle: {
      avatar: { wh: 1.2, pc: 'x', pt: '2.08' },
      nickname: { pt: 3.4, w: 2, pc: 'x' }
    },
    okBtn: { pc: 'x', pb: 0.74 }
  },

  uiTeamEditTeamName: {
    input: { wh: '4.5 1.21', pc: 'x', pt: 1.8 },
    confirmLong: { pc: 'x', pb: 0.74 }
  }
}

const textConfig = {
  hw: (color) => ({
    searchPlaceholder: '搜索最近联系人',
    searchListEmpty: '暂无最近联系人',
    sendInvite: '组队邀请已发出～',
    recvInvitationText: '“{{}}”邀你一起组队',
    agreeConfirm: `确认和“<span style="color:${color}">{{}}</span>”组队吗？<br/>确认组队后，队伍<span style="color:${color}">不可更改</span>`,
    rejectConfirm: `真的要狠心拒绝“<span style="color:${color}">{{}}</span>”的组队邀请吗？<br/>拒绝后<span style="color:${color}">不可更改</span>`,
    otherTeamFull: `玩家“<span style="color:${color}">{{}}”</span>邀请你组队由于对方队伍满员，<span style="color:${color}">该请求自动失效</span>`,
    youHaveTeam: `玩家“<span style="color:${color}">{{}}”</span>邀请你组队由于您已组队，<span style="color:${color}">该请求自动失效</span>`,
    editNamePlaceholder: '请输入队伍名',
    editEmptyTip: '队名不能为空',
    haveRejected: '您已经拒绝过他了'
  }),
  C: {
    searchPlaceholder: ''
  },
  J: {
    searchPlaceholder: ''
  },
  K: {
    searchPlaceholder: ''
  },
  A: {
    searchPlaceholder: ''
  },
  // <span style="color:${color}"></span>
  M: (color) => ({
    searchPlaceholder: 'Cari kawan',
    searchListEmpty: 'Tidak ada kawan',
    sendInvite: 'Permintaan untuk pasukan telah hantar',
    recvInvitationText: `<span style="color:${color}">“{{}}”</span>jemput anda untuk berpasukan`,
    agreeConfirm: `Adakah anda mahu berpasukan dengan "<span style="color:${color}">{{}}</span>"? <br/>Selepas mengesahkan pembentukan pasukan, pasukan <span style="color:${color}">tidak boleh ditukar</span>`,
    rejectConfirm: `Adakah anda mahu menolak jemputan pasukan "<span style="color:${color}">{{}}</span>"? <br/>Selepas penolakan, <span style="color:${color}">tidak boleh ditukar</span>`,
    otherTeamFull: `Pemain <span style="color:${color}">"{{}}"</span> menjemput anda untuk membentuk pasukan kerana pasukan ini sudah penuh, <span style="color:${color}" > permintaan itu secara automatik tidak sah </span>`,
    youHaveTeam: `Pemain "<span style="color:${color}">{{}}"</span> menjemput anda untuk membentuk pasukan. Tapi anda telah membentuk pasukan, <span style="color:${color}"> permintaan ini secara automatik tidak sah</span>`,
    editNamePlaceholder: 'Sila masukan nama pasuakn',
    editEmptyTip: 'Nama pasukan tidak boleh kosong',
    haveRejected: 'Anda telah menolak dia'
  }),

  T: (color) => ({
    searchPlaceholder: 'ค้นหาผู้ติดต่อล่าสุด',
    searchListEmpty: 'ไม่มีผู้ติดต่อล่าสุด',
    sendInvite: 'ส่งคำเชิญเข้าทีมแล้ว～',
    recvInvitationText: `<span style="color:${color}">“{{}}”</span>เชิญคุณเข้าทีม`,
    agreeConfirm: `ยืนยันทีจะเข้าทีมกับ“<span style="color:${color}">{{}}</span>”ไหม？<br/>หลังจากยืนยัน <span style="color:${color}">ไม่สามารถเปลี่ยนทีมได้นะ</span>`,
    rejectConfirm: `จะปฏิเสธคำเชิญเข้าทีมของ“<span style="color:${color}">{{}}</span>”จริงเหรอ？<br/>หลังปฏิเสธ<span style="color:${color}">ไม่สามารถเปลี่ยนได้นะ</span>`,
    otherTeamFull: `ผู้เล่น“<span style="color:${color}">{{}}”</span>ได้เชิญคุณเข้าทีม แต่ทีมเต็มแล้ว<span style="color:${color}">คำเชิญนี้จึงหมดอายุอัตโนมัติ</span>`,
    youHaveTeam: `ผู้เล่น“<span style="color:${color}">{{}}”</span>ได้เชิญคุณเข้าทีม แต่เนื่องจากคุณจัดทีมเรียบร้อยแล้ว<span style="color:${color}">คำเชิญนี้จึงหมดอายุอัตโนมัติ</span>`,
    editNamePlaceholder: 'โปรดใส่ชื่อทีม',
    editEmptyTip: 'ชื่อทีมไม่ควรว่าง',
    haveRejected: 'คุณเคยปฏิเสธเขาไปแล้ว'
  })
}

export const teamTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw

const imgConfig = proxyAssets({
  hw: {
    line: 'https://quick-page.afunapp.com/assets/A9016250-0851-4E9F-B61D-B86E325C4BEB.png'
  },
  wp: {
    line: 'https://quick-page.weplayapp.com/assets/4A91F52E-AAA9-4631-82CF-E9BD2C990BAD.png?_w=500&_h=2'
  }
})

export const teamImgConfig = IS_WEPLAY ? imgConfig.wp : imgConfig.hw

const regionConfig = {
  hw: {
    teamNameLength: 5
  },
  C: {
    teamNameLength: 5
  },
  J: {
    teamNameLength: 5
  },
  K: {
    teamNameLength: 5
  },
  A: {
    teamNameLength: 16
  },
  M: {
    teamNameLength: 15
  },
  T: {
    teamNameLength: 15
  }
}

export const teamRegionConfig = IS_WEPLAY ? regionConfig[PAGE_REGION] : regionConfig.hw
