export default [
  {
    path: `/activity/v2/20231015-a/shop-a/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
        to.meta.id = to.meta.json.id
      },
      expire: '2023/11/18 23:59:59 GTM+3',
      pageCenter: '14ipxRtG'
    },
    id: 1282
  }
]
