export default [
  {
    path: '/h5-module-ambassador',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      quickConfig: '15ff104fa5d85d5d989094818f758425'
    }
  }
]
