<template lang="pug">
a-section(v-bind='$attrs', bdr='0.18rem 0.18rem 0 0')
  canvas(:id='id', style='width: 100%; height: 100%; border-radius: 0.18rem 0.18rem 0 0')
  slot
</template>

<script>
import AlphaVideo from '../../utils/alphaVideo'
export default {
  props: {
    src: {
      type: String,
      default: ''
    },

    loop: {
      type: Boolean,
      default: true
    },

    _wh: {
      type: Array,
      default: () => [6.06, 6.06]
    },

    autoplay: {
      type: Boolean,
      default: true
    },

    onError: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      id: '',
      lotteryVideo: {},
      fontSize: 100,
      mp4Visible: false
    }
  },

  mounted() {
    this.initId()
    this.$nextTick(() => {
      this.initAlphaVideo()
    })
  },

  methods: {
    initId() {
      String.prototype.hashCode = function () {
        var hash = 0,
          i,
          chr
        if (this.length === 0) return hash
        for (i = 0; i < this.length; i++) {
          chr = this.charCodeAt(i)
          hash = (hash << 5) - hash + chr
          hash |= 0 // Convert to 32bit integer
        }
        return hash
      }

      this.id = `canvas${Math.random().toString().hashCode()}`
    },

    initAlphaVideo() {
      const size = document.documentElement.style.fontSize
      this.fontSize = size.slice(0, size.length - 2)

      const [w, h] = this._wh

      this.lotteryVideo = new AlphaVideo({
        src: this.src,
        width: w * this.fontSize,
        height: h * this.fontSize,
        loop: this.loop, // 是否循环播放
        canvas: document.getElementById(this.id),
        autoplay: this.autoplay
      })
    },

    async playAnima() {
      return new Promise((resolve) => {
        if (!this.lotteryVideo.video) {
          resolve()
          return
        }

        let promise = this.lotteryVideo?.video?.play()

        if (promise !== undefined) {
          promise.catch((error) => {
            console.log(error, '手动播放报错')
            this.onError()
            this.pause()
            this.lotteryVideo.autoPlayError = true
            resolve()
          })
        }

        this.lotteryVideo.video.addEventListener('timeupdate', () => {
          if (this.autoplay) {
            resolve()
            return
          }

          if (this.lotteryVideo.video.currentTime >= this.lotteryVideo.video.duration) {
            this.lotteryVideo.video?.pause()
            resolve()
          }
        })
      })
    },

    pause() {
      this.lotteryVideo.video?.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
.red {
  background-color: rgba($color: #000000, $alpha: 0.3);
}
</style>
