<template lang="pug">
a-dialog(name='uiVoteSuccessDialog', :bg-i='useImages.bg')
  template(slot-scope='{ data }', v-if='data')
    a-position(pt='2.5', pc='x', @a-tap='toggle()')
      a-section(v-show='localStorage.noSuccessTip', :bg-i='useImages.noTipChecked')
      a-section(v-show='!localStorage.noSuccessTip', :bg-i='useImages.noTipCancel')

    a-button1(pl='0.59', pb='0.74', v-bind='btnStyle', :bg-i='useImages.cancelBtn', @a-tap='close()')
    share-btn(pr='0.59', pb='0.74', v-bind='btnStyle', :bg-i='useImages.toShareBtn', :item='data.item')
</template>

<script>
import { genUsePropsMixin } from '../mixins'
import { VOTE_SUCCESS_DIALOG } from '../const'
import shareBtn from '../components/share-btn.vue'

const btnStyle = {
  w: '2.5',
  h: '0.9',
  bgS: 'contain'
}

export default {
  components: {
    shareBtn
  },

  mixins: [genUsePropsMixin(VOTE_SUCCESS_DIALOG)],

  data() {
    return {
      btnStyle
    }
  },

  computed: {
    localStorage() {
      return this.$storage.localStorage()
    }
  },

  mounted() {},

  methods: {
    toggle() {
      this.$storage.toggle('noSuccessTip')
    },
    close() {
      this.$dialogs.uiVoteSuccessDialog.close()
    }
  }
}
</script>

<style></style>
