import Vue from 'vue'
import Vuex from 'vuex'

import activityModule from '@sub/store/activity-module'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { user, ...activityModule }
})
