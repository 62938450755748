export default [
  {
    path: '/activity/v2/20221110/main-c/index.html',
    component: () => import('./main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: '724ee27a09f9c089904807db33514b8a',
      language: 'zh-tw'
    }
  },
  {
    path: '/activity/v2/20221110/promotion-c/index.html',
    component: () => import('./promotion.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: '1215ccda23632a41ec7980e878aa0c61',
      language: 'zh-tw'
    }
  }
]
