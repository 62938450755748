<template lang="pug">
a-section.ui-new-reward
  ui-cumulative.ui-new-reward__cumulative(v-if='isShow', v-bind='$attrs', :nodeList='nodeList', :val='recharge_amount')

  //- box
  a-position.ui-new-reward__box-list(v-for='(item, i) in exhibitRewardGiftList', :key='i', :bg-i='`progress/bg-${getTypeByStatus(stageStatus[i]?.state)}${item.box.bg_num}`', bg-s='contain', v-bind='item.box.domPosition')
    //- 底部的点状态icon
    a-position.ui-new-reward__box-list__icon(:bg-i='`progress/${getTypeByStatus(stageStatus[i]?.state)}`', bg-s='contain', v-bind='item.box.iconPosition || defaultConfig.iconPosition')

    //- 累计数值展示
    a-position.ws-nowrap.ui-new-reward__box-list__value(:fs='defaultConfig.val_fs', fw='500', :fc='getColor(colorConfig.val_color_map, stageStatus[i]?.state)', v-html='$tf(pointNumTxt || "cumulative/value", nodeList[i]?.special_val || nodeList[i]?.value)', v-bind='item.box.textPosition || defaultConfig.textPosition')

    //- list
    a-position.ui-new-reward__box-list__gift-list(v-for='(gift, idx) in item.list', :key='idx', :wh='gift.bg_config.wh || defaultConfig.default_wh', v-bind='{ ...defaultConfig.reward_bg, ...gift.bg_config }')
      //- 奖励
      ui-prop-wrapper.ui-new-reward__box-list__gift-list__prop(:canPreview='true', v-bind='gift.reward')

      //- or
      a-section.ws-nowrap.ui-new-reward__box-list__gift-list__or1(v-if='gift.orConfig', v-bind='gift.orConfig', v-html='gift.orText')
      a-border-text1.ws-nowrap.ui-new-reward__box-list__gift-list__or2(v-if='gift.orConfig2', v-bind='gift.orConfig2', :borderContent='gift.orText')

      //- 数量icon
      a-border-text1.ws-nowrap.ui-new-reward__box-list__gift-list__icon(:f='defaultConfig.icon_font', borderWidth='2', :borderColor='getColor(colorConfig.text_border_color_map, stageStatus[i]?.state)', :borderContent='$getPropUnit(gift.reward.id, gift.reward.icon_num) || gift.reward.icon_text', v-bind='gift.icon_ps || icon_ps')

      //- 奖励名称
      a-position.ui-new-reward__box-list__gift-list__name(:fs='gift.fs || 0.16', :fc='getColor(colorConfig.name_color_map, stageStatus[i]?.state)', fw='500', :class='{ "ws-nowrap": !gift.width }', v-bind='gift.name_ps || defaultConfig.name_ps', :style='getStyle(gift)')
        span(v-html='propsConfig[gift.reward?.id]?.[defaultConfig.reward_name_key]')

      //- 特殊的icon
      a-position.ui-new-reward__box-list__gift-list__special-icon(v-if='gift.special_icon', v-bind='gift.special_icon')

  slot
</template>

<script>
import compact from 'lodash/compact'
import positionConfigNew1 from './positionConfigNew1'

export default {
  props: {
    // 累计任务id
    taskId: {
      type: Number,
      default: 1
    },

    // 奖励定位类型
    // 传入String选择已有的奖励定位配置
    // 传入Object使用新的奖励定位配置
    positionType: {
      type: [String, Object],
      default: ''
    },

    // 节点列表
    nodeList: {
      type: Array,
      default: () => []
    },

    // 颜色配置
    colorConfig: {
      type: Object,
      default: () => {}
    },

    // 礼物配置
    giftConfig: {
      type: Object,
      default: () => {}
    },

    pointNumTxt: {
      type: String,
      default: ''
    },

    isUserPropRechargeValue: {
      type: Boolean,
      default: false
    },

    propRechargeValue: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      positionConfigNew1,
      isShow: 0
    }
  },

  computed: {
    propsConfig() {
      return this.$propsConfig()
    },

    gender() {
      return this.$base?.amBasic()?.user?.gender || null
    },

    isAr() {
      return this.$route.query.region === 'A'
    },

    exhibitRewardList() {
      return this.positionConfig.exhibitRewardList
    },

    exhibitRewardGiftList({ gender }) {
      return this.exhibitRewardList.map((item) => {
        const list = compact(
          item.list.map((gift) => {
            if (gift.gender) {
              return gender === gift.gender ? { ...gift, reward: { ...gift.reward, ...this.giftConfig[gift.reward.id_idx] } } : null
            }
            return { ...gift, reward: { ...gift.reward, ...this.giftConfig[gift.reward.id_idx] } }
          })
        )
        return { box: item.box, list }
      })
    },

    positionConfig() {
      return typeof this.positionType === 'string' ? this[this.positionType] : this.positionType
    },

    defaultConfig() {
      return this.positionConfig.defaultConfig
    },

    icon_ps() {
      return this.isAr ? this.defaultConfig.a_icon_ps : this.defaultConfig.icon_ps
    },

    rechargeGiftList() {
      return this.positionConfig.rechargeGiftList
    },

    levelTaskInfo() {
      return this.$store.state.task.levelTasksInfo[this.taskId]
    },

    // 值
    recharge_amount() {
      return this.isUserPropRechargeValue ? this.propRechargeValue : this.levelTaskInfo.value || 0
    },

    // 累充状态
    stageStatus() {
      return this.levelTaskInfo.stages ? this.levelTaskInfo.stages : new Array(this.nodeList.length).fill({ state: 0 })
    },

    // 获取累计按钮状态
    recharge_button_status() {
      return this.levelTaskInfo.totalState || 0
    }
  },

  onPageShow() {
    this.init()
  },

  created() {
    this.$store.commit('task/initLevelTaskItemDefault', this.taskId)
  },

  async mounted() {
    this.init()
  },

  beforeDestroy() {},

  methods: {
    async init() {
      await this.getTaskStatus()
      this.isShow = 1
    },

    getStyle(gift) {
      const newStyle = gift.width ? { width: `${gift.width}rem`, 'text-align': 'center' } : {}
      return { ...gift.style, ...newStyle }
    },

    // 累计状态得到展示的图片类型
    getTypeByStatus(status = 0) {
      return this.positionConfig.defaultTypeMap[status]
    },

    // 通过累计状态获取颜色值
    getColor(map, status = 0) {
      return map[status]
    },

    // 获取任务状态
    async getTaskStatus() {
      await this.$store.dispatch('task/getTasksGroupList')
    }
  }
}
</script>

<style lang="scss" scoped></style>
