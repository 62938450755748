<template lang="pug">
#m-rank.ui-rank-single-top3-col
  a-section.ui-rank-single-top3-col__wrapper(v-bind='attrs', w='7.5')
    //- 1-3
    a-section.ui-rank-single-top3-col__wrapper__top1_3-wrapper(:bg-i='image.head')
      a-position.fx-col(wh='6.58 4.14', pc='x', pt='0.39')
        a-section(:bg-i='image.top1Bg', bg-s='100%')
          a-section(:bg-i='image.rank1Icon', v-bind='commonConfig[1]', pc='y')
          a-avatar(wh='.9', v-imgLazy='top3[0].avatar || image.empty', :uid='top3[0].uid', pc='y', v-bind='commonConfig[2]', :style='top3[0].uid ? `border: .02rem solid ${borderColor.top1};` : ""', bg-s='100%')
          a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.top1name} 500`', :class='{ "txt-r": _isA, "txt-l": !_isA }') {{ $ts(top3[0].nickname, 6) }}
          a-position.fx-row.fx-c-center(h='.34', pc='y', v-bind='commonConfig[4]')
            a-section(:bg-i='image.scoreIcon1')
            a-section(:f='`.23 ${textColor.score1} 500`') {{ props.notCompress ? top3[0].value1 : top3[0].value }}
          a-position(v-if='top3[0].ringImg', wh='.8', pc='y', v-bind='commonConfig[5]', :bg-i='top3[0].ringImg')

        a-section(:bg-i='image.top2Bg', bg-s='100%', mt='0.12')
          a-section(:bg-i='image.rank2Icon', v-bind='commonConfig[1]', pc='y')
          a-avatar(wh='.9', v-imgLazy='top3[1].avatar || image.empty', :uid='top3[1].uid', pc='y', v-bind='commonConfig[2]', :style='top3[1].uid ? `border: .02rem solid ${borderColor.top2};` : ""', bg-s='100%')
          a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.top2name} 500`', :class='{ "txt-r": _isA, "txt-l": !_isA }') {{ $ts(top3[1].nickname, 6) }}
          a-position.fx-row.fx-c-center(h='.34', pc='y', v-bind='commonConfig[4]')
            a-section(:bg-i='image.scoreIcon2')
            a-section(:f='`.23 ${textColor.score2} 500`') {{ props.notCompress ? top3[1].value1 : top3[1].value }}
          a-position(v-if='top3[1].ringImg', wh='.8', pc='y', v-bind='commonConfig[5]', :bg-i='top3[1].ringImg')

        a-section(:bg-i='image.top3Bg', bg-s='100%', mt='0.12')
          a-section(:bg-i='image.rank3Icon', v-bind='commonConfig[1]', pc='y')
          a-avatar(wh='.9', v-imgLazy='top3[2].avatar || image.empty', :uid='top3[2].uid', pc='y', v-bind='commonConfig[2]', :style='top3[2].uid ? `border: .02rem solid ${borderColor.top3};` : ""', bg-s='100%')
          a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.top3name} 500`', :class='{ "txt-r": _isA, "txt-l": !_isA }') {{ $ts(top3[2].nickname, 6) }}
          a-position.fx-row.fx-c-center(h='.34', pc='y', v-bind='commonConfig[4]')
            a-section(:bg-i='image.scoreIcon3')
            a-section(:f='`.23 ${textColor.score3} 500`') {{ props.notCompress ? top3[2].value1 : top3[2].value }}
          a-position(v-if='top3[2].ringImg', wh='.8', pc='y', v-bind='commonConfig[5]', :bg-i='top3[2].ringImg')

    //- 4-10
    a-section.repeat.ui-rank-single-top3-col__wrapper__top4_n-wrapper(:h='1.3 * top4_n.length', bg-s='100%', :bg-i='image.rowWrapper')
      a-section(v-for='(item, index) in top4_n', :key='index', wh='7.5 1.3')
        a-position(wh='6.58 1.3', pc, bg-s='100%')
          a-position(v-show='index!== top4_n.length - 1', :bg-i='image.commonline', pb=0, pc='x')
          a-position.fx-row.fx-c-center.fx-m-center(:bg-i='image.star', ta, pc='y', v-bind='commonConfig[1]', :f='`.24 ${textColor.star} 600`') {{ index + 4 }}
          a-avatar(wh='.9', v-imgLazy='item.avatar || image.empty', :uid='item.uid', pc='y', v-bind='commonConfig[2]', :style='item.uid ? `border: .02rem solid ${borderColor.other};` : ""', bg-s='100%')
          a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.other} 500`', :class='{ "txt-r": _isA, "txt-l": !_isA }') {{ $ts(item.nickname, 6) }}
          a-position.fx-row.fx-c-center(h='.34', pc='y', v-bind='commonConfig[4]')
            a-section(:bg-i='image.scoreIconOther')
            a-section(:f='`.23 ${textColor.scoreOther} 500`') {{ props.notCompress ? item.value1 : item.value }}
          a-position(v-if='item.ringImg', wh='.8', pc='y', v-bind='commonConfig[5]', :bg-i='item.ringImg')

    //- 自己的一栏
    a-section.ui-rank-single-top3-col__wrapper__footer(v-if='isFixed', bg-s='100%', :bg-i='image.footer', mt='-.01')
    a-section.ui-rank-single-top3-col__wrapper__mine(v-bind='mineConfig')
      a-position(v-bind='mineConfig2', v-for='(item, index) in [mine]', :key='index')
        a-section.fx-row.fx-c-center.fx-m-center(v-if='isInRank', :bg-i='image.star', ta, pc='y', v-bind='commonConfig[1]', :f='`.24 ${textColor.star} 600`') {{ item.rank }}
        a-section(v-else, :bg-i='image.notInRank', v-bind='commonConfig[6]', pc='y', bg-s='100%')
        a-avatar(wh='.86', v-imgLazy='item.avatar', :data-default='image.empty', :uid='item.uid', pc='y', v-bind='commonConfig[2]', :class='{ "white-border": item.uid }', :style='`border: .02rem solid ${borderColor.mine};`', bg-s='100%')
        a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.mineName || textColor.other} 500`', :class='{ "txt-r": _isA, "txt-l": !_isA }') {{ $ts(item.nickname, 6) }}
        a-position.fx-row.fx-c-center(h='.34', pc='y', v-bind='commonConfig[4]')
          a-section(:bg-i='image.scoreIconMine || image.scoreIconOther')
          a-section(:f='`.23 ${textColor.mineScore || textColor.score} 500`') {{ props.notCompress ? item.value1 : item.value }}
        a-position(v-if='item.ringImg', wh='.8', pc='y', v-bind='commonConfig[5]', :bg-i='item.ringImg')
        slot(name='mine')
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import proxyAssets from '@sub/utils/proxy-assets'
import { formatItem } from './utils/config'

import topCommon from './components/top4-n.vue'
import mineFixed from './components/mine-fixed.vue'
import { isA } from '@sub/ui-components/utils/config'
import { IS_WEPLAY } from '@/utils/env'

export default {
  mixins: [attrs],

  components: { topCommon, mineFixed },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    'amApiParam.uid': {
      handler(val) {
        if (val) {
          this.init()
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      isA,
      crownMap: proxyAssets({
        1: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/kMiCb0gQ.png?_w=74&_h=74' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/7v0lQTp9.png?_w=74&_h=74',
        2: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/8bqSkRqH.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/OfrodgbS.png?_w=66&_h=66',
        3: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/c3KYzrd3.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/elemls2N.png?_w=66&_h=66'
      })
    }
  },

  computed: {
    commonConfig() {
      return {
        1: { [this._plr]: '.4' }, //星星的位置
        2: { [this._plr]: '1.22' }, //头像框的位置
        3: { [this._plr]: '2.32' }, //名字的位置
        4: { [this._plr]: '5.19' }, //戒指的位置
        5: { [this._prl]: '1.55' }, // 分数的位置
        6: { [this._plr]: '.12' } //未上榜icon的位置
      }
    },

    mineConfig({ isFixed, image }) {
      return isFixed ? { 'bg-i': image.fixedBg, p: 'fixed', pb: '0', pc: 'x' } : { 'bg-i': image.footer }
    },

    mineConfig2({ isFixed, image }) {
      return isFixed ? { wh: '6.58 1.3', pc: 'pc' } : { wh: '6.58 1.3', pc: 'x', pt: '0', 'bg-i': image.mineBg }
    },

    amApiParam() {
      return this.$base.amApiParam()
    },

    amBasic() {
      return this.$base.amBasic()
    },

    props() {
      return this.uiMeta?.props
    },

    act_id({ props }) {
      return props?.act_id || 0
    },
    //页面使用多个不同类型的ui榜单组件时必传（值不可相同），同类型时可以忽略
    rankKey({ props }) {
      return props?.rankKey || 'total'
    },

    isFixed({ props }) {
      return props?.isFixed
    },

    image({ props }) {
      return { ...props?.image }
    },

    textColor({ props }) {
      return props?.textColor
    },

    borderColor() {
      return this.props?.borderColor || {}
    },

    ringImgBgColor({ props }) {
      return props?.ringImgBgColor || {}
    },

    // 对于state和getters可以像下面这样以computed接收，也可以直接用
    top1_3({ rankKey }) {
      return this.$rank.rankUse()?.[rankKey]?.list1_3 || []
    },

    top3() {
      return this.top1_3
    },
    top4_n({ rankKey }) {
      return this.$rank.rankUse()?.[rankKey]?.list.slice(3) || []
    },
    mine({ rankKey }) {
      return this.$rank.rankUse()?.[rankKey]?.mine || {}
    },

    isInRank() {
      return this.mine.rank && this.mine.rank <= this.props?.rankConfig?.rankLength
    },

    rankConfig({ props }) {
      return { ...props?.rankConfig }
    }
  },

  methods: {
    async init() {
      this.$rank.initDefault({
        format: {
          list: formatItem,
          mine: formatItem
        },
        namespace: this.rankKey
      })
      // 相当于之前给mixins中传参那步
      const { rankLength } = this.rankConfig
      this.$store.commit('rank/setRank', [this.rankKey, { bonus: 0, list: [], mine: {} }])
      this.$store.commit('rank/setRankAttr', [this.rankKey, 'length', rankLength])
      // 加载榜单信息
      this.loadRank()
    },

    loadRank() {
      const { rankLength, rankType, periodType, extra = {} } = this.rankConfig
      // 相当于之前调用mixins中的方法
      let params = { limit: rankLength, rank_type: rankType, period_type: periodType, ...extra, ...this.amApiParam }
      this.act_id && (params.act_id = this.act_id)
      this.$rank.getRankList([this.rankKey, params])
      this.$rank.getRankInfo([this.rankKey, params])
    }
  }
}
</script>

<style lang="scss" scoped>
.repeat {
  background-repeat: repeat-y;
}
</style>
