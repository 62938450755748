/* eslint-disable */
import Crypto from 'crypto-js'
import pako from 'pako'
import {IS_WEPLAY, PAGE_REGION} from '@/utils/env'
import {isProduction} from '@sub/utils/switchEnvRequest'
import {getQuery1} from '@/utils/utils'
import actInfo from '@sub/store/common/act-info'

// 支持的区服
const openEncryptionRegions = ['A', 'HW', 'C', 'J', 'K', 'I', "Q"]
const isRegionSupport = openEncryptionRegions.includes(PAGE_REGION)

function parse(raw) {
  return Crypto.enc.Utf8.parse(raw)
}

const k = parse(IS_WEPLAY ? 'dvrugkq9amuwpact' : 'dvrugkq9amuhwact')

// -1: 全关闭，1: 仅开启加密，2: 开启加密 & 压缩
let pageCenterCryptType = 0
let cryptTypes = [-1, 1, 2]
let {apiEncryption} = getQuery1()
apiEncryption = cryptTypes.includes(+apiEncryption) ? +apiEncryption : 0
const localApiEncryption = actInfo.getApiEncryption()

// 控制是否开启加解密能力
export function getCryptType() {
  const isWhite = actInfo.getState('isWhite')
  // return -1 // 强制关闭加密
  const defaultType = isRegionSupport ? Number(pageCenterCryptType) || -1 : -1
  // 线上或者区服不支持
  if ((isProduction && !isWhite) || !isRegionSupport) {
    return defaultType
  } else {
    // 支持的区服非线上环境
    if (!isProduction && localApiEncryption) {
      return localApiEncryption
    }
    return apiEncryption || defaultType
  }
}

export function setCryptType(type) {
  pageCenterCryptType = type
}

export function encrypt(raw) {
  const d = Crypto.AES.encrypt(parse(raw), k, {
    iv: k,
    mode: Crypto.mode.CBC,
    padding: Crypto.pad.Pkcs7
  })

  return d.toString()
}

export async function decrypt(res, gzip) {
  let d = ''

  if (gzip) {
    d = Crypto.AES.decrypt(res, k, {
      iv: k,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7
    }).toString()
    d = unzip(d)
  } else {
    d = Crypto.AES.decrypt(res, k, {
      iv: k,
      mode: Crypto.mode.CBC,
      padding: Crypto.pad.Pkcs7
    }).toString(Crypto.enc.Utf8)
  }

  const r = JSON.parse(d)

  return r
}

/**
 * Gzip 解压
 */
const unzip = (apiData) => {
  let len = 0
  const charData = []
  while (len < apiData.length) {
    const hexStr = apiData.slice(len, len + 2)
    len += 2
    charData.push(parseInt(hexStr, 16))
  }
  const binData = new Uint8Array(charData)
  const data = pako.inflate(binData)
  return Utf8ArrayToStr(data)
}

/**
 * utf-8 Array 转化字符串
 * @param array Utf8Array
 * @returns {string}
 * @constructor
 */
function Utf8ArrayToStr(utf8Array) {
  let result = ''
  let i = 0

  while (i < utf8Array.length) {
    const byte = utf8Array[i++]

    if (byte < 0x80) {
      // 1 字节字符 (0x00 - 0x7F)
      result += String.fromCharCode(byte)
    } else if (byte >= 0xc0 && byte < 0xe0) {
      // 2 字节字符 (0xC0 - 0xDF)
      const byte2 = utf8Array[i++]
      result += String.fromCharCode(((byte & 0x1f) << 6) | (byte2 & 0x3f))
    } else if (byte >= 0xe0 && byte < 0xf0) {
      // 3 字节字符 (0xE0 - 0xEF)
      const byte2 = utf8Array[i++]
      const byte3 = utf8Array[i++]
      result += String.fromCharCode(((byte & 0x0f) << 12) | ((byte2 & 0x3f) << 6) | (byte3 & 0x3f))
    } else if (byte >= 0xf0 && byte < 0xf8) {
      // 4 字节字符 (0xF0 - 0xF7)
      const byte2 = utf8Array[i++]
      const byte3 = utf8Array[i++]
      const byte4 = utf8Array[i++]
      const codepoint = ((byte & 0x07) << 18) | ((byte2 & 0x3f) << 12) | ((byte3 & 0x3f) << 6) | (byte4 & 0x3f)
      result += String.fromCharCode(0xd800 + ((codepoint - 0x10000) >> 10), 0xdc00 + (codepoint & 0x3ff))
    }
  }
  return result
}
