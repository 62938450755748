class QuickConfig {
  quickConfig = {}

  getQuickConfig(key) {
    return this.quickConfig[key]
  }

  setQuickConfig(key, data = {}) {
    this.quickConfig[key] = data
  }
}

export default new QuickConfig()
