import base from './base/index'
import task from './task/index'
import team from './team/index'
import contact from './contact'
import rank from './rank/index'
import checkin from './checkin/index'
import storage from './storage'
import record from './record'
import uiComp from './uiComp'
import jsBridgeInfo from './jsBridgeInfo'
import lottery from './lottery'
import defer from './defer'
import exchange from './exchange/index'
import performance from './performance'
import reward from './reward'
import runtime from './runtime'
import purchase from './purchase'

export default {
  base,
  task,
  team,
  contact,
  rank,
  checkin,
  storage,
  record,
  uiComp,
  jsBridgeInfo,
  lottery,
  defer,
  exchange,
  performance,
  reward,
  runtime,
  purchase
}

// 用于方便调试
try {
  setTimeout(() => {
    if ((window.__build_inject_var__ || {}).buildInfo) {
      console.log(window.__build_inject_var__)
    }
  }, 3000)
} catch (error) {
  console.error(error)
}
