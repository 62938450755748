export default [
  {
    path: '/activity-test',
    component: () => import('./test.vue'),
    meta: {
      quickConfig: '93486e19367ae7875cf865d909eab65e',
      vuex: ['basic', 'task']
    }
  }
]
