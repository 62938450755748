import { PAGE_REGION } from '@/utils/env'
import Vue from 'vue'

export const consts = {
  // 1累充 2累消 3送礼
  totalCoin: {
    1: 'totalCoinRecharge',
    2: 'totalCoinCost',
    3: 'totalSendGift'
  },

  nextReward: {
    1: 'nextRewardRecharge',
    2: 'nextRewardCost',
    3: 'nextRewardSendGift'
  }
}
export const stateMap = {
  0: 'undone',
  1: 'complete',
  2: 'received'
}

export const rewardDialogDefaultConfig = {
  // 新标准(大弹窗大小相对之前有区别 高832px ）（不用传)
  newStandard: true,

  // 奖励容器样式（不用传）
  rewardWrapStyle: {
    // 普通的上边距
    pt: {
      // 1个奖励
      reward1: 1.9,
      // 2-3个奖励
      reward2_3: 2.12,
      // 4个奖励
      reward4: 2.25,
      // 大于4个奖励
      reward5: 2
    },
    // 阿语服的上边距
    ptA: {
      // 1个奖励
      reward1: 1.98,
      // 2-3个奖励
      reward2_3: 2.3,
      // 4个奖励
      reward4: 2.11,
      // 5-6个奖励
      reward5_6: 2.32,
      // 大于6个奖励
      reward7: 1.88
    }
  }
}

export const rechargeTextFontSizeMap = {
  U: 0.22,
  P: 0.22,
  V: 0.2
}
export const rechargeTextStyle = {
  fs: rechargeTextFontSizeMap[PAGE_REGION] || 0.24
}

export function formatItem(itemReward) {
  const vm = Vue.prototype.$app
  const {
    reward_meta: { prop_type_name, prop_img, prop_type, animation_url },
    reward_id,
    reward_type,
    reward_val,
    reward_name,
    svgaScale
  } = itemReward
  const type = reward_type === 'title' ? 999 : prop_type

  return {
    type,
    svgaScale,
    reward_name: reward_name,
    name: prop_type_name,
    img: prop_img,
    svga: animation_url?.includes('.svga') ? animation_url : '',
    id: reward_id,
    unit: vm.$getPropUnit(reward_id, reward_val, type)
  }
}
