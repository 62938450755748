<template lang="pug">
a-section.ui-purchase-button-wrapper(v-bind='attrs')
  a-button1.ui-purchase-button__isCanBuy(v-if='isCanBuy', wh='100%', bg-s='contain', :throttle='1000', :bg-i='images.buyBtn', @a-tap='openConfirmDialog()')
    a-position(wh='100%')
      ui-text(ml='0.94', mt='0.1', h='0.45', lh='0.45', v-bind='props.priceTextStyle', :style='props.priceTextStyle', :content='purchaseInfo.current_price')
  a-position.ui-purchase-button__cantBuy(v-else, wh='100%', bg-s='contain', :bg-i='images.buyBtnDisabled')
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { standardTapAction } from '@sub/ui-runtime/core/utils/components/button'

import { buildApi, concatCatch } from '@sub/utils/request'
const directBuy = buildApi('/activity_v2/direct_store/buy')

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },
    formattedReward: {
      type: Object,
      default: () => {}
    },

    // hooks
    afterPurchase: {
      type: Function,
      default: async () => {}
    }
  },

  computed: {
    storeConfig() {
      return this.$purchase.storeConfig()
    },
    props() {
      return this.uiMeta.props || {}
    },
    images() {
      return this.props.images || {}
    },
    text() {
      return this.props.text || {}
    },

    // { store_id: 1, item_id: 1 }
    purchaseConfig() {
      return this.props.purchaseConfig || {}
    },
    confrimDialogKey() {
      return this.props.confrimDialogKey
    },
    rewardDialogKey() {
      return this.props.rewardDialogKey
    },

    // 购买的信息
    purchaseInfo() {
      const { store_id, item_id } = this.purchaseConfig

      return this.storeConfig[store_id]?.find((item) => item.item_id === item_id) || {}
    },

    // 奖励的信息
    reward() {
      const { reward_val, reward_id } = this.purchaseInfo.reward || {}
      const reward = this.$propsConfig(reward_id) || this.formattedReward || {}

      reward.unit = reward_val ? this.$getPropUnit(reward_id, reward_val, reward.type) : ''
      if (reward?.unitType === 4 || reward?.act_extra?.is_permanent) {
        reward.unit = this.text.permanent
        reward.isPermanent = true
      }

      return reward
    },

    isCanBuy() {
      const { owned, buyable } = this.purchaseInfo
      return !owned && buyable
    }
  },

  onPageShow() {
    this.$base.getUserCoin()
    this.getStoreInfoList()
  },

  created() {
    if (!this.storeConfig[this.purchaseConfig.store_id]) {
      this.getStoreInfo()
    }
  },

  methods: {
    getStoreInfo() {
      this.$purchase.getStoreInfo({ store_id: this.purchaseConfig.store_id })
    },
    getStoreInfoList() {
      this.$purchase.getStoreInfoList()
    },

    openConfirmDialog() {
      if (this.confrimDialogKey) {
        const { current_price } = this.purchaseInfo

        this.$dialogs[this.confrimDialogKey].open({
          data: { num: 1, price: current_price, reward: this.reward, directBuy: this.directBuy },
          options: { bgClose: true }
        })
      } else {
        this.directBuy({ num: 1 })
      }
    },

    async directBuy({ num }) {
      if (this.confrimDialogKey && this.$dialogs[this.confrimDialogKey].active) {
        this.$dialogs[this.confrimDialogKey].close()
      }

      const { store_id, item_id, current_price } = this.purchaseInfo

      const [err, res] = await concatCatch(
        directBuy({
          store_id,
          item_id,
          num,
          price: current_price * num
        })
      )
      if (err) {
        console.error('🐲 gwl ~ directBuy ~ err:', err)
        return
      }

      // update
      // this.getStoreInfo()
      this.getStoreInfoList()
      this.$base.getUserCoin()
      // hooks
      this.afterPurchase(res)

      const { gained_reward } = res
      const rewardList = [gained_reward]?.map((item) => {
        const { reward_name, reward_amount } = item
        const { type, img } = this.reward
        return {
          img,
          type,
          reward_name,
          unit: this.$getNumUnit(reward_amount)
        }
      })

      if (this.rewardDialogKey) {
        this.$dialogs[this.rewardDialogKey]?.open({ data: { rewardList } })
        return
      }
      standardTapAction(
        this,
        {},
        {
          openOptionsMap: {
            'ui-normal-reward-dialog': { data: { rewardList } }
          }
        }
      )
    }
  }
}
</script>

<style></style>
