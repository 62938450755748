<template lang="pug">
a-dialog(name='uiVotePlayVideo', wh='6.05 10')
  template(slot-scope='{ data }', v-if='data')
    a-position(wh='6.05 10', pc)
      //- youtube
      iframe(v-if='data.platform === 1', :src='genYouTubeUrl(data)', frameborder='0', allowfullscreen, width='100%', height='100%', style='border-radius: 0.3rem') 
      //- tiktok，打开前会 appendTiktokScript
      .tiktok(v-else-if='data.platform === 2', wh='100%', v-html='data.tiktok_block_quote')
</template>

<script>
export default {
  methods: {
    genYouTubeUrl({ youtube_video_id }) {
      return `https://www.youtube.com/embed/${youtube_video_id}?html5=1`
    }
  }
}
</script>

<style scoped>
.tiktok {
  height: 10rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}
</style>
