<template lang="pug">
#m-rank.ui-avatar-rank-single-top3
  a-section.ui-avatar-rank-single-top3__wrapper(v-bind='attrs', w='7.5')
    //- 1-3
    a-section.ui-avatar-rank-single-top3__wrapper__top1_3-wrapper(:bg-i='image.head')
      a-section(wh='100%', v-bind='specialStyle.top3 || { pt: 0 }')
        a-section.of-h-x(wh='2.5 4.8', pc='x', pt='0.49')
          a-section(:bg-i='image.top1Bg', pc)
            a-position(:bg-i='getAvatarImg(top3[0])', wh='2 4.33', bg-s='150%', pc='x', pt='-0.2')
            a-position.fx-row.fx-center(pt='0.33', pr='0.37')
              a-section(:bg-i='image.scoreIconOther', wh='0.28', bg-s='100%')
              a-section.ws-nowrap(:f='`.16 ${textColor.firstScoreDesc} 500`', h='.16', lh='0.2', ml='0.02') {{ $tf("rank/value") }}
            a-position(:bg-i='image.top1Wrapper', pc='x', pt='0')
            a-avatar(wh='0.76', v-imgLazy='top3[0].avatar || image.empty', bg-s='100%', :uid='top3[0].uid', pb='1.1', pc='x', pz='10', :style='top3[0].uid ? `border: .03rem solid ${borderColor.top1};` : ""')
            a-position(wh='1.8 .28', lh='normal', ta, pc='x', pb='0.7', :f='`.2 ${textColor.firstNickname} 500`') {{ $ts(top3[0].nickname, 6) }}
            a-section.ws-nowrap(:f='`.2 ${textColor.firstScore} 500`', pt='0.63', pr='0.28', ta, wh='.87 .22') {{ showOtherScore ? (props.notCompress ? top3[0].value1 : top3[0].value) : "" }}

        a-section.of-h-x(wh='2.22 4.24', pl='0.38', pt='0.8')
          a-section(:bg-i='isA ? image.top3Bg : image.top2Bg', pc)
            span(:data-var='(topIndex = isA ? 2 : 1)')
            a-position(:bg-i='getAvatarImg(top3[topIndex])', wh='1.72 3.77', bg-s='130%', pc='x', pt='0')

            a-position.fx-row.fx-center(pt='0.33', pr='0.37')
              a-section(:bg-i='image.scoreIconOther', wh='0.28', bg-s='100%')
              a-section.ws-nowrap(:f='`.16 ${textColor[`${isA ? "thirdScoreDesc" : "secondScoreDesc"}`]} 500`', h='.16', lh='0.2', ml='0.02') {{ $tf("rank/value") }}
            a-position(:bg-i='image[`top${topIndex + 1}Wrapper`]', pc='x', pt='0')
            a-avatar(wh='0.76', v-imgLazy='top3[topIndex].avatar || image.empty', bg-s='100%', :uid='top3[topIndex].uid', pb='1.1', pc='x', pz='10', :style='top3[topIndex].uid ? `border: .03rem solid ${borderColor.top2};` : ""')
            a-position(wh='1.8 .28', lh='normal', ta, pc='x', pb='0.7', :f='`.2 ${textColor.secondNickname} 500`') {{ $ts(top3[topIndex].nickname, 6) }}
            a-section.ws-nowrap(:f='`.18 ${textColor.secondScore} 500`', pt='0.63', pr='0.28', ta, wh='.87 .22') {{ showOtherScore ? (props.notCompress ? top3[topIndex].value1 : top3[topIndex].value) : "" }}

        a-section.of-h-x(wh='2.22 4.24', pr='0.4', pt='0.8')
          a-section(:bg-i='isA ? image.top2Bg : image.top3Bg', pc)
            span(:data-var='(topIndex = isA ? 1 : 2)')
            a-position(:bg-i='getAvatarImg(top3[topIndex])', wh='1.72 3.77', bg-s='130%', pc='x', pt='0')

            a-position.fx-row.fx-center(pt='0.33', pr='0.37')
              a-section(:bg-i='image.scoreIconOther', wh='0.28', bg-s='100%')
              a-section.ws-nowrap(:f='`.16 ${textColor[`${isA ? "secondScoreDesc" : "thirdScoreDesc"}`]} 500`', h='.16', lh='0.2', ml='0.02') {{ $tf("rank/value") }}

            a-position(:bg-i='image[`top${topIndex + 1}Wrapper`]', pc='x', pt='0')
            a-avatar(wh='0.76', v-imgLazy='top3[topIndex].avatar || image.empty', bg-s='100%', :uid='top3[topIndex].uid', pb='1.1', pc='x', pz='10', :style='top3[topIndex].uid ? `border: .03rem solid ${borderColor.top3};` : ""')
            a-position(wh='1.8 .28', lh='normal', ta, pc='x', pb='0.7', :f='`.2 ${textColor.thirdNickname} 500`') {{ $ts(top3[topIndex].nickname, 6) }}
            a-section.ws-nowrap(:f='`.18 ${textColor.thirdScore} 500`', pt='0.63', pr='0.28', ta, wh='.87 .22') {{ showOtherScore ? (props.notCompress ? top3[topIndex].value1 : top3[topIndex].value) : "" }}

    //- 4-10
    a-section.repeat.ui-avatar-rank-single-top3__wrapper__top4_n-wrapper(:h='1.56 * top4_n.length', bg-s='100%', :bg-i='image.rowWrapper')
      a-section(v-for='(item, index) in top4_n', :key='index', wh='7.5 1.56')
        a-position(:bg-i='image.commonBg', pc, bg-s='100%')
          a-position.fx-row.fx-c-center.fx-m-center(:bg-i='image.star', ta, pc='y', v-bind='commonConfig[1]', :f='`.24 ${textColor.star} 600`') {{ index + 4 }}
          a-avatar(wh='.9', v-imgLazy='item.avatar || image.empty', :uid='item.uid', pc='y', v-bind='commonConfig[2]', :style='item.uid ? `border: .02rem solid ${borderColor.other};` : ""', bg-s='100%')
          a-position.fx-col(w='1.5', :ta='_isA ? "r" : "l"', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.otherNickname} 500`')
            a-section.ws-nowrap {{ $ts(item.nickname, 6) }}
        a-section.of-h-x.tap-through(wh='6.58 1.56', pc)
          a-position.row-avatar-img(:bg-i='getAvatarImg(item)', wh='2.8 1.9', pr='-0.2', pt='-0.5')
          a-position.fx-row.fx-center(:bg-i='image.scoreBgOther', h='0.38', pb='0.12', bg-s='100%', pr='0.02')
            a-section(:bg-i='image.scoreIconOther')
            a-section.ws-nowrap(:f='`.2 ${textColor.otherScoreDesc} 500`', lh='0.38', ml='0.02') {{ $tf("rank/value") }}
            a-section.ws-nowrap(:f='`.2 ${textColor.otherScore} 500`', lh='0.38', ml='0.02') {{ showOtherScore ? (props.notCompress ? item.value1 : item.value) : "" }}

    //- 自己的一栏
    a-section.ui-avatar-rank-single-top3__wrapper__footer(v-if='isFixed', bg-s='100%', :bg-i='image.footer', mt='-.01')
    a-section.ui-avatar-rank-single-top3__wrapper__mine(v-bind='mineConfig')
      a-position(v-bind='mineConfig2')
        a-section.fx-row.fx-c-center.fx-m-center(v-if='isInRank', :bg-i='image.starMine || image.star', ta, pc='y', v-bind='commonConfig[1]', :f='`.24 ${textColor.star} 600`') {{ mine.rank }}
        a-section(v-else, :bg-i='image.notInRank', v-bind='commonConfig[6]', pc='y', bg-s='100%')
        a-avatar(wh='.86', v-imgLazy='mine.avatar', :data-default='image.empty', :uid='mine.uid', pc='y', v-bind='commonConfig[2]', :class='{ "white-border": mine.uid }', :style='`border: .02rem solid ${borderColor.mine};`', bg-s='100%')
        a-position.fx-col(w='1.5', lh='.27', ta='l', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.mineName || textColor.other} 500`')
          a-section.ws-nowrap {{ $ts(mine.nickname, 6) }}

      a-section.of-h-x.tap-through(wh='6.58 1.4', pc)
        a-position.row-avatar-img(:bg-i='getAvatarImg(mine)', wh='2.8 2', pr='-0.2', pt='-0.7')
        a-position.fx-row.fx-center(:bg-i='image.scoreBgMine', pb='0.06', bg-s='100%', pl='4.3')
          a-section(:bg-i='image.scoreIconMine')
          a-section.ws-nowrap(:f='`.2 ${textColor.mineScoreDesc} 500`', ml='0.02') {{ $tf("rank/value") }}
          a-section.ws-nowrap(:f='`.2 ${textColor.mineScore} 500`', ml='0.02') {{ props.notCompress ? mine.value1 : mine.value }}
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { formatItem } from './utils/config'

import { isA } from '@sub/ui-components/utils/config'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    'amApiParam.uid': {
      handler(val) {
        if (val) {
          this.init()
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      isA
    }
  },

  computed: {
    commonConfig() {
      return {
        1: { [this._plr]: '.4' }, //星星的位置
        2: { [this._plr]: '1.22' }, //头像框的位置
        3: { [this._plr]: '2.32' }, //名字的位置
        4: { [this._plr]: '4.6' }, // 分数的位置
        6: { [this._plr]: '.12' } //未上榜icon的位置
      }
    },

    mineConfig({ isFixed, image }) {
      return isFixed ? { 'bg-i': image.fixedBg, p: 'fixed', pb: '0' } : { 'bg-i': image.footer }
    },

    mineConfig2({ isFixed, image }) {
      return isFixed ? { wh: '6.58 1.3', pc: 'pc' } : { wh: '6.58 1.3', pc: 'x', pt: '.04', 'bg-i': image.mineBg }
    },

    amApiParam() {
      return this.$base.amApiParam()
    },

    amBasic() {
      return this.$base.amBasic()
    },

    props() {
      return this.uiMeta?.props
    },

    //页面使用多个不同类型的ui榜单组件时必传（值不可相同），同类型时可以忽略
    rankKey({ props }) {
      return props?.rankKey || 'total'
    },

    act_id({ props }) {
      return props?.act_id || 0
    },

    isFixed({ props }) {
      return props?.isFixed
    },

    isShowRefresh({ props }) {
      return props?.isShowRefresh
    },

    image({ props }) {
      return { ...props?.image }
    },

    textColor({ props }) {
      return props?.textColor
    },

    borderColor() {
      return this.props?.borderColor || {}
    },

    ringImgBgColor({ props }) {
      return props?.ringImgBgColor || {}
    },

    // 对于state和getters可以像下面这样以computed接收，也可以直接用
    top1_3() {
      if (this.$rank.rankUse()) {
        return this.$rank.rankUse()[this.rankKey]?.list1_3
      }
      return []
    },

    top3() {
      return this.top1_3
    },

    top4_n() {
      if (this.$rank.rankUse()) {
        return this.$rank.rankUse()[this.rankKey]?.list.slice(3)
      }
      return []
    },
    mine() {
      if (this.$rank.rankUse()) {
        return this.$rank.rankUse()[this.rankKey]?.mine
      }
      return {}
    },

    isInRank() {
      return this.mine.rank && this.mine.rank <= this.props?.rankConfig?.rankLength
    },

    showOtherScore({ mine }) {
      return this.rankConfig?.visibleRank ? mine.rank && mine.rank <= +this.rankConfig.visibleRank : true
    },

    rankConfig({ props }) {
      return { ...props?.rankConfig }
    },

    specialStyle({ props }) {
      return props?.specialStyle || {}
    }
  },

  onPageShow() {
    this.isShowRefresh && this.init()
  },

  methods: {
    async init() {
      this.$rank.initDefault({
        format: {
          list: formatItem,
          mine: formatItem
        },
        namespace: this.rankKey
      })
      const { rankLength } = this.rankConfig
      this.$store.commit('rank/setRank', [this.rankKey, { bonus: 0, list: [], mine: {} }])
      this.$store.commit('rank/setRankAttr', [this.rankKey, 'length', rankLength])
      // 加载榜单信息
      this.loadRank()
    },

    loadRank() {
      const { rankLength, rankType, periodType, fields = 'avatar', extra = {} } = this.rankConfig
      let params = { limit: rankLength, rank_type: rankType, period_type: periodType, fields, ...extra, ...this.amApiParam }
      this.act_id && (params.act_id = this.act_id)
      this.$rank.getRankList([this.rankKey, params])
      this.$rank.getRankInfo([this.rankKey, params])
    },

    getAvatarImg(item) {
      return this.rankConfig?.showCpAvatar ? item.avatarImg : item.singleAvatarImg
    }
  }
}
</script>

<style lang="scss" scoped>
.repeat {
  background-repeat: repeat-y;
}
.row-avatar-img {
  background-position: top center !important;
}
</style>
