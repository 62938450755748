<template lang="pug">
a-section.ui-buy-chip__content
  slot(name='buyDialog')
  //- 关闭按钮
  a-position.ui-buy-chip__content__close(v-bind='{ ...buyStyleConfig.closeStyle, ...getCustomizeStyle("closeStyle") }', :bg-i='imageBuy.closeIcon', @a-tap='close')
  //- 金币展示
  a-position.ui-buy-chip__content__add(v-bind='{ ...buyStyleConfig.coinWrap, ...getCustomizeStyle("addCoin") }', @a-tap='toPay()')
  a-position.txt-c.txt-no-wrap.ui-buy-chip__content__coin(v-bind='{ ...buyStyleConfig.coinStyle, ...getCustomizeStyle("coinStyle") }', :fc='textStyle.coinColor') {{ buyChipInfo.coin }}
  //- 抽奖币展示
  a-position.ui-buy-chip__content__chip(v-bind='{ ...buyStyleConfig.lotteryChipStyle, ...getCustomizeStyle("lotteryChipStyle") }', :fc='textStyle.lotteryCoinColor') {{ buyChipInfo.chipCount }}
  //- 滑动区域
  a-position.of-h-x.of-y-a.fx-row.fx-m-center.ui-buy-chip__content__buyWrap(v-bind='{ ...buyStyleConfig.scrollWrap, ...getCustomizeStyle("scrollWrap") }')
    a-section.ui-buy-chip__content__buyWrap__bg(:bg-i='imageBuy.goodsBg', bg-s='100%')
      a-section.ui-buy-chip__content__buyWrap__item(v-for='(item, index) in buyConfig', :key='index', v-bind='{ ...buyStyleConfig.buyItem.wrap, ...getCustomizeStyle("buyItem") }')
        //- 按钮
        a-button1.ui-buy-chip__content__buyWrap__item__buy(v-bind='{ ...buyStyleConfig.buyItem.button, ...getCustomizeStyle("buyBtn") }', :bg-i='imageBuy.buyButton', :throttle='1000', @a-tap='$emit("onBuy", item)', bg-s='100%')
          //- 标签
          a-position.ui-buy-chip__content__buyWrap__item__buy__tag(v-if='item.discountTag', v-bind='{ ...buyStyleConfig.buyItem.tag, ...getCustomizeStyle("buyTag") }', :bg-i='item.discountTag', bg-s='100%')
</template>

<script>
import get from 'lodash/get'
import { genBuyChipTextConfig } from '../utils/config'
import { logData } from '@sub/ui-runtime/core/utils/logData'

export default {
  props: {
    buyStyleConfig: {
      type: Object,
      required: true
    },
    uiMeta: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'dialog'
    }
  },

  computed: {
    imageBuy({ uiMeta }) {
      return get(uiMeta, 'props.image.buy', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.text.style', {})
    },

    textWords({ uiMeta }) {
      return get(uiMeta, 'props.text.words', {})
    },

    dialogName({ uiMeta: { key } }) {
      return key
    },

    buyConfig({ uiMeta }) {
      return get(uiMeta, 'props.buyConfig', []).filter((i) => !i.hidden)
    },

    chipId({ uiMeta }) {
      return get(uiMeta, 'props.chipId', 1)
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {}) || {}
    },

    textConfig({ textWords, textStyle: { confirmTextImportantColor, confirmTextColor } }) {
      return genBuyChipTextConfig({
        color: confirmTextImportantColor || confirmTextColor,
        lotteryCoinName: textWords.lotteryCoinName
      })
    },

    buyChipInfo({ chipId }) {
      return {
        coin: this.$store.state.base.coin,
        chipCount: this.$store.state.base.chipInfo[chipId]
      }
    }
  },

  methods: {
    close() {
      if (this.type === 'dialog') {
        this.$dialogs[this.dialogName].close()
      } else {
        this.$pullUps[this.dialogName].close()
      }
    },
    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    },

    toPay() {
      this.$we('pay')
      if (this._isRuntime) {
        logData('金币充值按钮点击', '')
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
