// type 1：访客记录本 3：戒指 4：主页特效 6：头像框 10：语音房公屏气泡 11：聊天气泡 12：玩友圈装扮 13：进场特效 19：语音房背景 23：礼物卡 50：座驾 51：皮肤红包 999:称号
import proxyAssets from '@sub/utils/proxy-assets'

export const getStyleConfig = ({ propsData, showGiftCardBgColor, stylesConfig }) => {
  return {
    1: { wh: '90%' },
    3: { wh: '90%' },
    4: { wh: '70%' },
    6: { wh: '90%' },
    // 10比较特殊，新的100% 老的80%，weplay暂时没有新的字段
    10: { wh: '100%' },
    11: { wh: '100%' },
    12: { wh: '90%' },
    13: { wh: '110%' },
    19: { wh: '90%' },
    23: { wh: '100%', 'bg-c': showGiftCardBgColor ? propsData.bgColor || propsData.bg_color : '' },
    50: { wh: '90%' },
    51: { wh: '90%' },
    999: { w: '100%' },
    ...stylesConfig
  }
}

export const getStyleConfigAr = ({ propsData, showGiftCardBgColor, stylesConfig }) => {
  return {
    1: { wh: '90%' },
    3: { wh: '90%' },
    4: { wh: '70%' },
    6: { wh: '90%' },
    // 10比较特殊，新的100% 老的80%，weplay暂时没有新的字段
    10: { wh: '100%' },
    11: { wh: '100%' },
    12: { wh: '90%' },
    13: { wh: '110%' },
    19: { wh: '90%' },
    23: { wh: '90%', 'bg-c': showGiftCardBgColor ? propsData.bgColor || propsData.bg_color : '' },
    50: { wh: '90%' },
    51: { wh: '90%' },
    999: { w: '100%' },
    ...stylesConfig
  }
}

// avatar类型说明：https://wepie.feishu.cn/wiki/XteHwKdLkiWePCk4RWPctelWn3g?table=tbljuWAEIsxfBR6A&view=vewGIwby2f
export const getAvatarStyleConfig = ({ stylesConfig }) => {
  return {
    9: { wh: '90%' },
    ...stylesConfig
  }
}

export const getAvatarStyleConfigAr = ({ stylesConfig }) => {
  return {
    9: { wh: '90%' },
    ...stylesConfig
  }
}

export const modelImgWP = proxyAssets({
  male: 'https://fe-center.weplayapp.com/page-center/assets/VhJmuMcc/VPBmNVJm.png?x-oss-process=image/resize,m_fixed,h_200,w_200',
  female: 'https://fe-center.weplayapp.com/page-center/assets/VhJmuMcc/eukFSxyN.png?x-oss-process=image/resize,m_fixed,h_200,w_200'
})

export const modelImgHW = proxyAssets({
  male: 'https://fe-center.afunapp.com/page-center/assets/zcEdJNBd/0Exllpfe.png?x-oss-process=image/resize,m_fixed,h_200,w_200',
  female: 'https://fe-center.afunapp.com/page-center/assets/zcEdJNBd/8naDzgB2.png?x-oss-process=image/resize,m_fixed,h_200,w_200'
})
// const map = {
//   1: '访客记录本',
//   3: '戒指',
//   4: '主页特效',
//   6: '头像框',
//   10: '语音房公屏气泡',
//   11: '聊天气泡',
//   12: '玩友圈装扮',
//   13: '进场特效',
//   19: '语音房背景',
//   23: '礼物卡',
//   50: '座驾',
//   51: '皮肤红包',
//   999: '称号'
// }

// function test() {
//   const normal = getStyleConfig({ stylesConfig: {} })
//   const ar = getStyleConfigAr({ stylesConfig: {} })
//   Object.keys(normal).forEach((id) => {
//     console.log(`
//       ${map[id]}
//       阿语=>${ar[id].wh} 其他=>${normal[id].wh}
//     `)
//   })
// }
// test()
