<template lang="pug">
a-section.ui-category-reward-detail-dialog(v-bind='attrs')
  //- 非阿语服
  a-dialog.ui-category-reward-detail-dialog__not-isA(v-if='!isA', :name='dialogName', :bg-i='image.dialogBg', :cache='extra.cache')
    a-section.ui-category-reward-detail-dialog__not-isA__close(:bg-i='closeImage', bg-s='100%', v-bind='{ ...defaultStyleCategory.close(), ...customizeStyle.closeStyle }', @a-tap='$dialogs[dialogName].close()')
    
    a-section.fx-row.ui-category-reward-detail-dialog__not-isA__tabs-wrapper(v-if='tabsNum > 1 && image.tabs.length', :bg-i='image.tabs[+tab - 1]', bg-s='100%', v-bind='{ ...defaultStyleCategory.tab, ...customizeStyle.tabStyle }')
      a-section.ui-category-reward-detail-dialog__not-isA__tabs-wrapper__item(v-for='(tb, tbIdx) in tabsNum', :key='tbIdx', :wh='`${100 / tabsNum}% 100%`', @a-tap='changeActiveTab(tb)')
    
    a-section.of-y-a.of-h-x.ui-category-reward-detail-dialog__not-isA__list-wrapper(v-bind='{ ...defaultStyleCategory.list(), ...customizeStyle.listWrapStyle }')
      a-section.fx-col.fx-center(v-for='cg in categoryNum[tab - 1]', :key='cg')
        a-section(:bg-i='curTabConfig[`category${cg}`].titleIcon', mb='0.32')
        a-section.fx-row.fx-wrap.fx-cl-start(w='100%', pdt='0.08')
          a-section.reward-item(v-for='(r, index) in curTabConfig[`category${cg}`].rewards', :key='index', mb='0.15')
            a-section(:bg-i='image.rewardBg', mb='0.04', wh='1.54', bg-s='100%')
              ui-prop(wh='100%', pc, :data='r', bg-s='100%')
              a-section(v-if='r.tag', :bg-i='r.tag', v-bind='{ ...defaultStyleCategory.tag(), ...customizeStyle.tagStyle }')
              a-border-text1(
                v-bind='{ ...defaultStyleCategory.subScript(), fc: customizeStyle.textStyle.subTextColor }',
                borderWidth='3',
                :borderColor='customizeStyle.textStyle.subTextBorderColor',
                :borderContent='r.unit'
              )
            a-section(v-bind='{ ...defaultStyleCategory.rewardName, fc: customizeStyle.textStyle.rewardNameColor }', w='1.54', v-html='r.name1 || r.name')

  //- 阿语服
  a-dialog.ui-category-reward-detail-dialog__isA(v-else, :name='dialogName', :bg-i='image.dialogBg', :cache='extra.cache')
    a-section.ui-category-reward-detail-dialog__isA__close(:bg-i='closeImage', bg-s='100%', v-bind='{ ...defaultStyleCategory.close(), ...customizeStyle.closeStyle }', @a-tap='$dialogs[dialogName].close()')
    
    a-section.fx-row.ui-category-reward-detail-dialog__isA__tabs-wrapper(v-if='tabsNum > 1 && image.tabs.length', :bg-i='image.tabs[+tab - 1]', bg-s='100%', v-bind='{ ...defaultStyleCategory.tab, ...customizeStyle.tabStyle }')
      a-section.ui-category-reward-detail-dialog__isA__tabs-wrapper__item(v-for='(tb, tbIdx) in tabsNum', :key='tbIdx', :wh='`${100 / tabsNum}% 100%`', @a-tap='changeActiveTab(tb)')
    
    a-section.of-y-a.of-h-x.ui-category-reward-detail-dialog__isA__list-wrapper(v-bind='{ ...defaultStyleCategory.list(), ...customizeStyle.listWrapStyle }')
      a-section.fx-col.fx-center(v-for='cg in categoryNum[tab - 1]', :key='cg')
        a-section(:bg-i='curTabConfig[`category${cg}`].titleIcon', mb='0.32')
        a-section.fx-row.fx-wrap.fx-cl-start(w='100%', pdt='0.08')
          a-section.reward-item-ar(v-for='(r, index) in curTabConfig[`category${cg}`].rewards', :key='index', mb='0.15')
            a-section(:bg-i='image.rewardBg', mb='0.04', wh='1.54', bg-s='100%')
              ui-prop(wh='100%', pc, :data='r', bg-s='100%')
              a-section(v-if='r.tag', :bg-i='r.tag', v-bind='{ ...defaultStyleCategory.tag(), ...customizeStyle.tagStyle }')
              a-border-text1(
                v-bind='{ ...defaultStyleCategory.subScript(), fc: customizeStyle.textStyle.subTextColor }',
                borderWidth='3',
                :borderColor='customizeStyle.textStyle.subTextBorderColor',
                :borderContent='r.unit'
              )
            a-section(v-bind='{ ...defaultStyleCategory.rewardName, fc: customizeStyle.textStyle.rewardNameColor }', w='1.54', v-html='r.name1 || r.name')
</template>

<script>
import { get, set, cloneDeep } from 'lodash'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { defaultStyleCategory, commonSubscriptStyle } from './utils/config'
import { commonImage } from '@sub/ui-components/utils/config'

import { isA } from '../utils/config'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    tabConfig: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      defaultStyleCategory,
      commonSubscriptStyle,
      isA,
      tab: 1
    }
  },

  computed: {
    closeImage({ uiMeta }) {
      return get(uiMeta, 'props.image.close', commonImage.dialogClose) || commonImage.dialogClose
    },
    isRuntime() {
      return this.$base.extra().isRuntime
    },

    dialogName({ uiMeta }) {
      return get(uiMeta, 'key', 'rewardDetail')
    },

    tabsNum({ uiMeta }) {
      return get(uiMeta, 'props.tabsNum', 1)
    },

    categoryNum({ uiMeta }) {
      return get(uiMeta, 'props.categoryNum', [1])
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {})
    },

    config({ uiMeta }) {
      if (this.tabConfig && Object.keys(this.tabConfig).length) {
        return this.tabConfig
      }
      const config = cloneDeep(get(uiMeta, 'props.config', {}))
      this.categoryNum.map((len, tb) => {
        const tbConfig = config[`tab${tb + 1}`]
        for (let i = 0; i < len; i++) {
          const newRewards = tbConfig[`category${i + 1}`].rewards.map((r) => {
            return {
              ...this.$propsConfig(r.id),
              tag: r.tag,
              // tag: r.tag || this.$getPropTag(r.id),
              unit: typeof r.unit === 'string' ? r.unit : this.$getPropUnit(r.id, r.unit)
            }
          })
          set(config, `tab${tb + 1}.category${i + 1}.rewards`, newRewards)
        }
      })
      return config
    },

    extra({ uiMeta }) {
      return get(uiMeta, 'props.extra', { cache: false })
    },

    curTabConfig() {
      return this.config[`tab${this.tab}`]
    }
  },

  created() {},

  mounted() {},

  methods: {
    open({ tab = 'tab1' } = {}) {
      const tabNum = tab.replace(/[^0-9]/gi, '')
      this.changeActiveTab(+tabNum)
      this.$dialogs[this.dialogName].open({ options: { bgClose: true } })
    },

    changeActiveTab(tab) {
      if (this.tab === tab) return
      this.tab = tab
    }
  }
}
</script>

<style lang="scss" scoped>
.reward-item {
  margin-right: 0.32rem;

  &:nth-of-type(3n + 1) {
    margin-left: 0.11rem;
  }

  &:nth-of-type(3n) {
    margin-right: 0.11rem;
  }
}

.reward-item-ar {
  margin-left: 0.32rem;

  &:nth-of-type(3n + 1) {
    margin-right: 0.11rem;
  }

  &:nth-of-type(3n) {
    margin-left: 0.11rem;
  }
}
</style>
