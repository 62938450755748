import { IS_WEPLAY } from '@/utils/env'
import { isProduction as isProd } from '@sub/utils/env'

const devConfig = require('@/../config/development')
const preConfig = require('@/../config/staging')
const prodConfig = require('@/../config/production')

const PARAMS = new URLSearchParams(window.location.search)

// 是否是线上域名
export const isProduction = isProd

export const debugEnv = PARAMS.get('debugEnv')
export const localApiHost = PARAMS.get('localApiHost')

export default function switchEnvRequest(app) {
  try {
    if (isProduction) {
      return app
    }

    const region = PARAMS.get('region')

    const dev = IS_WEPLAY ? devConfig[region] : devConfig
    const pre = IS_WEPLAY ? preConfig[region] : preConfig
    const prod = IS_WEPLAY ? prodConfig[region] : prodConfig
    if (localApiHost && checkIP(localApiHost)) {
      let request = {
        h1: localApiHost,
        h2: localApiHost
      }
      console.log('🚀 ~ 正在使用本地服务器ip: localApiHost', localApiHost)
      return { ...app, request }
    }

    if (debugEnv === 'dev') {
      return { ...app, request: dev.request }
    } else if (debugEnv === 'pre') {
      return { ...app, request: pre.requestBackup || pre.request }
    } else if (debugEnv === 'prod') {
      return { ...app, request: prod.request }
    }

    return app
  } catch {
    return app
  }
}

const checkIP = (URL) => {
  let str = URL
  //判断URL地址的正则表达式为:http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&=]*)?
  //下面的代码中应用了转义字符"\"输出一个字符"/"
  let Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/ // eslint-disable-line
  let objExp = new RegExp(Expression)
  if (objExp.test(str) == true) {
    console.log('本地服务host格式正常')
    return true
  } else {
    console.log('本地服务host格式不正常')
    return false
  }
}
