<template lang="pug">
a-section(v-bind='attrs', w='7.5')
  topic(:topics='topics', :uiMeta='uiMeta', :context='context')
</template>

<script>
import get from 'lodash/get'

import { attrs } from '@sub/ui-runtime/core/mixins/attrs'

import topic from './components/topic.vue'
import { buildApi1 } from '@/utils/request'
import { IS_DEV } from '@/utils/env'
import { IS_WEPLAY } from '@/utils/env'

const weplayCommonGetTopicList = buildApi1('/activity_v2/act_discover/get_topic_list')
const wespyCommonGetTopicList = buildApi1('/activity_v2/common/get_topic_list')

export default {
  mixins: [attrs],

  components: {
    topic
  },

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    },

    context: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      topicList: [],
      topics: []
    }
  },

  created() {
    this.initTopicList()
  },

  methods: {
    async initTopicList() {
      const { getTopicListApi, extraApiParams, configType, topicId, newsIdList, topicDevId } = this.uiMeta.props
      if (configType !== 'id') {
        const getTopicList = buildApi1(getTopicListApi)
        const res = await getTopicList({ ...this.amApiParam, ...extraApiParams })
        this.topicList = Array.isArray(res) ? res : res.recommend_discovers
      } else {
        let params = { ...this.amApiParam, ...extraApiParams, topic_id: IS_DEV ? topicDevId : topicId, discover_ids: newsIdList.join(','), page_size: 15 }

        const { discover_list } = await (IS_WEPLAY ? weplayCommonGetTopicList(params) : wespyCommonGetTopicList(params))
        this.topicList = discover_list
      }

      this.initTopics()
    },

    initTopics() {
      this.$set(
        this,
        'topics',
        this.topicList.map((topic) => {
          let frame = get(topic, 'picture_frame_info.extra', '')
          if (frame) {
            try {
              frame = JSON.parse(frame).animation_url
            } catch (e) {
              frame = ''
            }
          }

          return {
            user: {
              uid: get(topic, 'discover_owner_uid', 0),
              nickname: get(topic, 'discover_owner_name', ''),
              avatar: get(topic, 'discover_owner_headimgurl', ''),
              vip: get(topic, 'discover_owner_vip', ''),
              frame
            },
            topic: {
              id: get(topic, 'content.topic_id', ''),
              titleList: get(topic, 'content.topic_list', '')
            },
            dynamic: {
              id: get(topic, 'discover_id', 0),
              time: get(topic, 'time', 0),
              textarea: get(topic, 'content.text_content', ''),
              images: (get(topic, 'content.media_urls', '') || get(topic, 'content.draw_image_url', '')).split(',')
            },
            footer: {
              first_comment: (get(topic, 'content.comment_list') || [])[0] || {},
              comment_count: get(topic, 'content.comment_count', ''),
              like_count: get(topic, 'content.like_count', '')
            }
          }
        })
      )
    }
  }
}
</script>

<style></style>
