import Vue from 'vue'
import merge from 'lodash/merge'

class PAG {
  // 挂载 PAG 的全局对象属性名
  PAGProperty = '$PAG'
  // PAG实例
  pagIns = null
  pagFiles = {}
  retryTimes = 0

  // 初始化promise
  initPromise = null
  initPromiseReslove = null
  initPromiseReject = null
  constructor() {
    this.init()
  }

  async init() {
    if (!this.pagIns && !this.initPromise) {
      console.info('PAG.js PAG.init调用')
      // 挂载全局
      Vue.prototype[this.PAGProperty] = {}

      this.initPromise = new Promise((resolve, reject) => {
        this.initPromiseReslove = resolve
        this.initPromiseReject = reject
      })
      try {
        const { PAGInit } = await import('libpag')
        this.pagIns = await PAGInit()
        this.initPromiseReslove()
      } catch (e) {
        if (this.retryTimes < 3) {
          console.info('init出错,重新init')
          this.init()
          this.initPromise = null
          this.pagIns = null
          this.retryTimes++
        } else {
          this.initPromiseReject(e)
        }
      }
    } else {
      await this.initPromise
    }
  }

  setPagFiles(payload) {
    merge(this.pagFiles, payload)
  }
}

export default new PAG()
