import Vue from 'vue'
import point from '@/lib/page-point'
import debounce from 'lodash/debounce'
import { buildApi1, concatCatch2 } from '@/utils/request-next'
import { IS_APP } from '@/utils/env'
import { getQuery1 } from '@/utils/utils'
import store from '@/store'

let curPonitInfo = {
  handle: null,
  actInfo: {},
  percent: { 0.25: false, 0.5: false, 0.75: false, 1: false } // 25% 50% 75% 100%
}

// 页面滚动埋点
export function initScrollPoint(to) {
  try {
    curPonitInfo.actInfo = to
    curPonitInfo.percent = { 0.25: false, 0.5: false, 0.75: false, 1: false }

    // 对于不能滑动的页面默认打点
    setTimeout(() => {
      const p = document.documentElement.clientHeight / document.body.clientHeight
      handlePoint(p)
    }, 3000)

    if (curPonitInfo.handle) {
      return
    }

    const scrollHandle = () => {
      const percent = (window.scrollY + document.documentElement.clientHeight) / document.body.clientHeight
      handlePoint(percent)
    }

    curPonitInfo.handle = scrollHandle
    window.addEventListener('scroll', debounce(scrollHandle, 300))
    // eslint-disable-next-line
  } catch {}
}

function handlePoint(percent = 0) {
  // 一屏展示下只打100%
  if (percent >= 0.95 && !curPonitInfo.percent[1]) {
    doPoint({ title: curPonitInfo.actInfo.meta.title, percent: '100%', act_id: curPonitInfo.actInfo.meta.id })
    curPonitInfo.percent = { 0.25: true, 0.5: true, 0.75: true, 1: true }
    return
  }

  Object.keys(curPonitInfo.percent).forEach((item) => {
    if (item - 0.05 <= percent && !curPonitInfo.percent[item]) {
      doPoint({ title: curPonitInfo.actInfo.meta.title, percent: `${item * 100}%`, act_id: curPonitInfo.actInfo.meta.id })
      curPonitInfo.percent[item] = true
    }
  })
}

function doPoint({ title = '', percent, act_id = 0 }) {
  point.track('BrowsePageView', {
    url: window.location.href,
    url_path: window.location.pathname,
    title,
    percent,
    act_id,
    is_act: 1
  })
}

// 活动规则埋点
export function rulePoint(meta = {}) {
  point.track('ClickH5', {
    url: window.location.href,
    url_path: window.location.pathname,
    title: meta?.title || '',
    activity: meta?.title || '',
    act_id: meta?.id || 0,
    btn_name: '活动规则'
  })
}

// 道具预览埋点
export function propPreviewPoint(vm, id = 0) {
  try {
    vm = Vue.prototype.$app

    point.track('ClickActivityProp', {
      url: window.location.href,
      url_path: window.location.pathname,
      title: vm.$route?.meta.title || '',
      activity: vm.$route?.meta.title || '',
      act_id: vm.$route?.meta.id || 0,
      goods_id: id + ''
    })
  } catch (e) {
    console.log(e)
  }
}

//页面浏览时长埋点
const viewPage = buildApi1('/activity_v2/common/track_view_page')

const viewPointInfo = {
  timer: null,
  actInfo: {}
}

export async function initViewPoint(to) {
  try {
    const { uid, sid } = store.state.user
    if (!IS_APP || !uid) {
      return
    }
    viewPointInfo.actInfo = to
    clearInterval(viewPointInfo.timer)
    handleViewPage({ uid, sid })
    viewPointInfo.timer = setInterval(async () => {
      handleViewPage({ uid, sid })
    }, 10000)
  } catch (e) {
    clearInterval(viewPointInfo.timer)
    console.error(e)
  }

  async function handleViewPage({ uid, sid }) {
    const [err] = await concatCatch2(viewPage({ uid, sid, url: window.location.href, url_path: window.location.pathname, title: to.meta?.title, act_id: to.meta?.id }))
    if (err) {
      clearInterval(viewPointInfo.timer)
    }
  }
}

// 页面加载时长埋点（源码）
export async function pageLoadTimePoint(to) {
  if (!to.meta?.id || !to.meta.assets) {
    return
  }

  try {
    pageLoadPointHandle(to.meta, performance.now(), '配置拉取完成时间')

    await waitImageLoad(observeImageLoad(to.meta))

    pageLoadPointHandle(to.meta, performance.now(), '首屏')
    reportTTI(to.meta)

    await waitImageLoad(observeImageLoad(to.meta, true))

    pageLoadPointHandle(to.meta, performance.now(), '底图全部加载完成')
  } catch (error) {
    console.error(error)
  }
}

export function pageLoadPointHandle(meta, duration, scene) {
  point.track('LoadPage', {
    scene,
    act_id: meta?.id || 0,
    event_duration: Math.floor(duration),
    url: window.location.href,
    url_path: window.location.pathname,
    title: meta?.title || ''
  })

  console.warn(duration, scene)
}

const observeImageLoad = (meta, isAllBg) => {
  const { assets, fsAssets, p0Assets = [] } = meta
  if (isAllBg) {
    return Object.keys(assets)
      .filter((item) => item.includes('.png') && item.includes('bg/'))
      .map((item) => setImageImportance(assets[item], 'auto'))
  } else if (fsAssets.length > 0 || p0Assets.length > 0) {
    return [...fsAssets, ...p0Assets].map((item) => setImageImportance(item.value))
  } else if (p0Assets.length === 0) {
    // 强制 p0
    console.error('缺少 p0 图片!!!请在 page-center 给首屏图片加上 p0 的描述，详情请见「分级策略的介绍」：https://wepie.yuque.com/io0a9h/ky6ogo/mobizv5e5b9f8fwa#rfIFp')
  }

  return []
}

function setImageImportance(url, importance = 'high') {
  return new Promise((resolve) => {
    let image = new Image()
    image.fetchPriority = importance
    image.src = url
    image.onload = resolve
    image.importance = importance
  })
}

const waitImageLoad = (promises) => {
  return Promise.all(promises)
}

// TTI 计算方式 = Max(首屏底图加载时间，首屏接口数据加载完成时间)
// 实现方式比较简单，就是计数（底图加载完成 +1，接口完成 +1，不过需要确保页面只会调用一次）
let reportTTITimes = 0
export function reportTTI(meta) {
  meta = meta || Vue.prototype.$app.$route.meta
  reportTTITimes++
  if (reportTTITimes === 2) {
    console.log('TTI:', performance.now())
    pageLoadPointHandle(meta, performance.now(), '可交互时间（TTI）')
  }
}
