<template lang="pug">
a-button1.ui-level-task-single-button-state0(v-if='!taskInfo.state', v-bind='attrs', :bg-i='buttonImage.toFinish', bg-s='contain', @a-tap='onTap("deepLink")')
a-button1.ui-level-task-single-button-state1(v-else-if='taskInfo.state === 1', v-bind='attrs', :bg-i='buttonImage.receive', bg-s='contain', @a-tap='recvReward(receiveEvents)')
a-position.grey.ui-level-task-single-button-state2(v-else, v-bind='attrs', :bg-i='buttonImage.completed', bg-s='contain')
</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { standardTapAction } from '@sub/ui-runtime/core/utils/components/button'
import { methodsInit } from '@sub/store/utils'

const { getState: getTaskState } = methodsInit('task')

export default {
  mixins: [attrs],

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  mounted() {
    this.init()
  },

  computed: {
    buttonImage({ uiMeta }) {
      return get(uiMeta, 'props.buttonImage', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.textStyle', {})
    },

    taskId({ uiMeta }) {
      return get(uiMeta, 'props.taskId', 0)
    },

    stageId({ uiMeta }) {
      return get(uiMeta, 'props.stageId', 0)
    },

    taskInfo({ taskId }) {
      if (!this.stageId) {
        return get(getTaskState().levelTasksInfo, taskId) || {}
      }

      const stages = get(getTaskState().levelTasksInfo, `[${taskId}].stages`)

      return (stages || []).find((item) => item.id === this.stageId) || {}
    },

    receiveEvents({ uiMeta }) {
      return get(uiMeta, 'events.receiveTap[0]', {})
    }
  },

  methods: {
    init() {
      this.$store.commit('task/initLevelTaskItemDefault', this.taskId)
      this.getTaskInfo()
    },

    getTaskInfo() {
      this.$store.dispatch('task/getTargetTaskInfo', this.taskId)
    },

    onTap() {
      standardTapAction(this, {
        openPage: this.recvReward
      })
    },

    async recvReward(item) {
      const { rewards } = await this.$store.dispatch('task/recvTaskReward', { taskId: this.taskId, stageId: this.stageId })
      this.getTaskInfo()

      const { type, key } = item.payload

      if (type === 'dialog-common-receive') {
        return this.$dialogs[key].open({
          data: {
            rewardList: rewards.map((item) => {
              const { reward_meta } = item
              return {
                ...item,
                type: reward_meta?.prop_type,
                img: reward_meta?.prop_img
              }
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
