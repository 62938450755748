<template lang="pug">
a-position(v-if='rewardMeta && rewardMeta.id', v-bind='attrs', @a-tap='preview')
  a-position(v-if='!hidden', v-bind='wrapperStyle', pc)
    template(v-if='showSvga')
      a-position(v-if='isSvg', :style='getSvgStyle(rewardMeta)', pc)
      a-svga(v-else, :url='propData.animation', :style='getSvgaStyle(rewardMeta)', pc)
    a-section.center(v-else, :style='getSvgaCoverStyle({ props: propData })', :bdr='bdr')
    //- num
    div(v-if='props.showNum', :style='numStyle()') {{ props.num }}
    //- name
    div(v-if='props.showName', :style='nameStyle()') {{ propData.name }}
</template>

<script>
import { attrs } from '../../mixins/attrs'
import { getSvgStyle, getSvgaStyle, getSvgaCoverStyle, getIsSvg } from '@sub/ui-runtime/core/utils/components/svga'
import { rewardMetaToPropData } from '@sub/utils/reward-meta-adpter'
import { getRewardMeta } from '@sub/utils/reward'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => ({})
    },

    showAvatar: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isOpenEffect() {
      return this.$base.isOpenEffect()
    },
    isOpenEffectPAG() {
      return this.$base.isOpenEffectPAG()
    },
    avatarGenderIsMale() {
      return this.$base.amBasic().user.avatarGender === 1
    },
    props() {
      return this.uiMeta.props || {}
    },
    rewardSlot() {
      return this.props.rewardSlot || {}
    },
    rewardMeta() {
      // FIXME: 后续可以干掉
      if (!this.props.rewardSlot) {
        const { svgaScale } = this.uiMeta.props
        return {
          ...this.props.rewardMeta,
          svgaScale,
          animationScale: svgaScale
        }
      }

      return getRewardMeta(this.rewardSlot)
    },
    wrapperStyle() {
      const defaultStyle = { wh: '100%' }
      const { cate, type } = this.rewardMeta
      return { ...defaultStyle, ...(wrapperStyleMap[cate]?.[type] || {}), ...(this.props.wrapperStyleMap?.[cate]?.[type] || {}) }
    },
    bdr() {
      if (this.rewardMeta.cate === 'title') return undefined
      return '15%'
    },
    propData() {
      return rewardMetaToPropData(this.rewardMeta, { avatarGenderIsMale: this.avatarGenderIsMale })
    },
    showSvga() {
      const showSvga = this.rewardSlot.show_svga !== undefined ? this.rewardSlot.show_svga : this.props.showSvga
      const isShow = showSvga || this.rewardMeta.cate !== 'prop'

      if (this.isSvg) {
        return isShow && this.isOpenEffectPAG
      } else {
        return this.propData.animation && isShow && this.isOpenEffect
      }
    },
    hidden() {
      return this.rewardSlot.hidden !== undefined ? this.rewardSlot.hidden : this.props.hidden
    },
    isSvg() {
      return getIsSvg(this.propData.animation)
    }
  },

  methods: {
    getIsSvg,
    getSvgStyle,
    getSvgaStyle,
    getSvgaCoverStyle,
    preview() {
      this.$propPreview(this.propData.id, { data: this.propData })
    },
    numStyle() {
      return {
        color: this.props.numColor,
        fontSize: this.props.numFontSize,
        bottom: this.props.numY,
        right: this.props.numX,
        position: 'absolute',
        lineHeight: 1
      }
    },

    remAdd(x, y) {
      return Number(x.replace('rem', '')) + Number(y.replace('rem', ''))
    },

    nameStyle() {
      const { nameY, nameFontSize } = this.props
      return {
        position: 'absolute',
        bottom: `-${this.remAdd(nameY, nameFontSize) + 0.1}rem`,

        color: this.props.nameColor,
        fontSize: this.props.nameFontSize,
        whiteSpace: 'nowrap'
      }
    }
  }
}

const wrapperStyleMap = {
  // type 1：访客记录本 3：戒指 4：主页特效 6：头像框 10：语音房公屏气泡 11：聊天气泡 12：玩友圈装扮 13：进场特效 19：语音房背景 23：礼物卡 30：梦幻名片 50：座驾 51：皮肤红包
  prop: {
    1: { wh: '90%' },
    3: { wh: '90%' },
    4: { wh: '70%' },
    6: { wh: '90%' },
    // 10比较特殊，新的100% 老的80%，weplay暂时没有新的字段
    10: { wh: '100%' },
    11: { wh: '100%' },
    12: { wh: '90%' },
    13: { wh: '110%' },
    19: { wh: '90%' },
    23: { wh: '100%' },
    30: { wh: '100%' },
    50: { wh: '90%' },
    51: { wh: '90%' }
  },
  avatar_prop: {
    19: { wh: '90%' }
  }
}
</script>

<style lang="scss" scoped></style>
