import { PAGE_REGION } from '@/utils/env'
import store from '@/store'
import { commonText } from '@sub/ui-components/utils/config'
/**
 * @module propUnit
 */
/**
 * @description 获取道具的角标
 * @param {number} propId 道具id
 * @param {number} value 天数或者个数
 * @param {number} [propType] 道具类型（运行时用）
 * @return {string} x1 or 1天
 */
export default function getPropUnit(propId, value, propType) {
  const unitType = getUnitType(propId, propType)
  // 直接传非数字如：'1/3天':
  if (isNaN(+value) && typeof value === 'string') {
    return value
  }

  // unitType 1 => x1 2 => 1天 3 => 空 4 => 永久
  if (unitType === 1) {
    return getNumUnit(value)
  }

  if (unitType === 2) {
    return getDayUnit(value)
  }

  if (unitType === 4) {
    return commonText.permanentText
  }

  return ''
}

/**
 * @description: 获取道具的单位类型
 * @param {number} propId 道具id
 * @param {number} [propType] 道具类型（运行时用）
 * @return {number} 1: 个数 2: 天数
 */
function getUnitType(propId, propType) {
  const propsConfig = store.getters['base/propsConfig'] || {}
  const isRuntime = store.getters['base/isRuntime']

  const rewardMetaMap = store.getters['reward/rewardMetaMap'] || {}
  if (rewardMetaMap[propId]?.unit_type) {
    return rewardMetaMap[propId]?.unit_type
  }

  if (propId && !propsConfig[propId] && !isRuntime) {
    console.error(`page-center道具配置不存在，propId: ${propId}`)
  }
  if (propsConfig[propId]?.unitType) {
    return propsConfig[propId]?.unitType
  }
  // 兜底逻辑：
  // 个数：戒指、资源货币、红包、礼物卡、礼物卡(限时)、家族宝箱
  return [0, 3, 5, 17, 22, 23, 14].includes(propType) ? 1 : 2
}

/**
 * @description: 获取各区服为数量时的展示方式
 * @param {number} num 个数数量
 * @return {string} x1 or 1x
 */
function getNumUnit(num) {
  let unit = ''
  if (typeof num !== 'number') return unit

  switch (PAGE_REGION) {
    case 'A':
      unit = `${num}x`
      break
    default:
      unit = `x${num}`
  }

  return unit
}

/**
 * @description: 获取各区服为天数时的展示方式
 * @param {number} day
 * @return {string} 1天 or 1日 or 1일 or 1 hari or 1 วัน
 */
function getDayUnit(day) {
  let unit = ''
  if (typeof day !== 'number') return unit

  switch (PAGE_REGION) {
    case 'HW':
      unit = `${day}天`
      break
    case 'C':
      unit = `${day}天`
      break
    case 'J':
      unit = `${day}日`
      break
    case 'K':
      unit = `${day}일`
      break
    case 'A':
      unit = getArUnit(day)
      break
    case 'M':
      unit = `${day} hari`
      break
    case 'T':
      unit = `${day} วัน`
      break
    case 'U':
      unit = `${day} ${day > 1 ? 'Days' : 'Day'}`
      break
    case 'P':
      unit = `${day} ${day > 1 ? 'Days' : 'Day'}`
      break
    case 'V':
      unit = `${day} ngày`
      break
    case 'Q':
      unit = `${day} Gün`
      break
    case 'B':
      unit = `${day} ${day > 1 ? 'dias' : 'dia'}`
      break
    case 'R':
      unit = `${day} дн.`
      break
    case 'I':
      unit = `${day} ${day > 1 ? 'Days' : 'Day'}`
      break
    case 'S':
      unit = `${day} días`
      break
    default:
      unit = ''
  }

  return unit
}

/**
 * @description: 获取阿服为天数时的展示方式
 * @param {number} day
 * @return {string} 1 يوم or 2 يومين or 3 أيام or 11 يوم
 */
function getArUnit(day) {
  let unit = ''
  if (typeof day !== 'number') return unit
  if (day === 1) {
    unit = 'يوم'
  } else if (day === 2) {
    unit = 'يومين'
  } else if (day >= 3 && day <= 10) {
    unit = `${day} أيام`
  } else if (day >= 11) {
    unit = `${day} يوما`
  }
  return unit
}
