// 提供一些vuex工具函数，在组件中快速访问/调用vuex的状态/方法
import allModules from '@sub/store/activity-module'
import store from '@/store'

const moduleNameList = Object.keys(allModules)

export function propsConfig(id) {
  if (!id) {
    return store.getters['base/propsConfig']
  } else {
    if (!store.getters['base/propsConfig'][id] && !store.state.base.extra?.isRuntime) {
      console.error(`道具id：${id} 未配置`)
    }
    return store.getters['base/propsConfig'][id]
  }
}

export function amApiParam() {
  return store.getters['base/amApiParam']
}

export function install(app) {
  /**
   * 提供两个常用的快速访问
   * this.$propsConfig(id) 返回这个id的道具信息, id不传则返回所有propsConfig对象
   * this.$amApiParam() 返回{ act_id, uid, sid }
   */
  app.prototype['$propsConfig'] = propsConfig
  app.prototype['$amApiParam'] = amApiParam

  /**
   * this.$模块名 包含这个模块下所有的state、getters、mutations、actions
   *
   * 以 base 模块举例
   * state:
   *    this.$store.state.base.amBasic
   * => this.$base.amBasic()
   * getters:
   *    this.$store.getters['base/propsConfig']
   * => this.$base.propsConfig()
   * mutations:
   *    this.$store.commit('base/initDefault', payload)
   * => this.$base.initDefault(可传参)
   * actions:
   *    this.$store.dispatch('base/amInit', payload)
   * => this.$base.amInit(可传参)
   */
  moduleNameList.forEach((moduleName) => {
    const { state = {}, getters = {}, mutations = {}, actions = {} } = allModules[moduleName]

    const stateObj = Object.keys(state).reduce((total, cur) => {
      total[cur] = () => store.state[moduleName][cur]
      return total
    }, {})

    const gettersObj = Object.keys(getters).reduce((total, cur) => {
      total[cur] = () => store.getters[`${moduleName}/${cur}`]
      return total
    }, {})

    const mutationsObj = Object.keys(mutations).reduce((total, cur) => {
      total[cur] = (payload) => store.commit(`${moduleName}/${cur}`, payload)
      return total
    }, {})

    const actionsObj = Object.keys(actions).reduce((total, cur) => {
      total[cur] = (payload) => store.dispatch(`${moduleName}/${cur}`, payload)
      return total
    }, {})

    app.prototype[`$${moduleName}`] = {
      ...stateObj,
      ...gettersObj,
      ...mutationsObj,
      ...actionsObj
    }
  })

  return null
}
