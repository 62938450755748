import Vue from 'vue'
import store from '@/store'
import { buildApi, concatCatch } from '@sub/utils/request'
const getInfo = buildApi('/activity_v2/vote/get_info')
const getRankList = buildApi('/activity_v2/rank/rank_list')
const getRankInfo = buildApi('/activity_v2/rank/rank_info')
const search = buildApi('/activity_v2/vote/search')

import { merge } from 'lodash'
import { defaultRankParams, defaultImages } from './config'
import { genComponentCurRegionText } from '@sub/utils/page-center-snapshot'

const MODULE_NAME = 'uiVoteWrapper'

store.registerModule(MODULE_NAME, {
  namespaced: true,

  state: () => ({
    uiMeta: {},
    context: {},

    // 剩余票数碎片id
    ticketChipId: 0,

    // 榜单数据列表
    rankList: [],
    // 我的数据
    mine: {},

    searchVal: '',
    // 搜索结果
    searchResultList: null,

    // 打开投票确认弹窗，在 ui-vote-confirm-dialog.vue 里设置
    openVoteConfirmDialog: () => {}
  }),

  getters: {
    // 图片
    images({ uiMeta }) {
      return merge(defaultImages, uiMeta?.props?.images || {})
    },
    // 文案
    text({ uiMeta, context }) {
      return genComponentCurRegionText(uiMeta.type, context)
    },
    // 榜单参数
    rankParams({ uiMeta }) {
      return merge(defaultRankParams, uiMeta?.props?.rankParams || {})
    }
  },

  mutations: {
    setState(state, payload) {
      Object.assign(state, payload)
    }
  },

  actions: {
    async getInfo({ commit }) {
      const { ticket_chip_id } = await getInfo()
      commit('setState', { ticketChipId: ticket_chip_id })
    },
    async getRankList({ commit, getters }) {
      const { list } = await getRankList(getters.rankParams)
      commit('setState', { rankList: list })
    },
    async getRankInfo({ commit, getters }) {
      const { info } = await getRankInfo(getters.rankParams)
      commit('setState', { mine: info })
    },

    // 初始化跳转时、页面搜索框时用到
    async doSearch({ state, commit }, { key_word } = {}) {
      const [err, res] = await concatCatch(
        search({
          key_word: key_word || state.searchVal
        })
      )
      if (err) {
        console.warn('search has Error', err)
        return
      }

      const resultList = res.list || []
      !key_word && commit('setState', { searchResultList: resultList })
      console.log('[ui-vote-wrapper][state]: ', state)
      return resultList
    }
  }
})

function storeState(string) {
  return store.state[MODULE_NAME][string]
}
function storeGetters(string) {
  return store.getters[`${MODULE_NAME}/${string}`]
}
function storeCommit(string, payload) {
  return store.commit(`${MODULE_NAME}/${string}`, payload)
}
function storeDispatch(actionName, payload) {
  return store.dispatch(`${MODULE_NAME}/${actionName}`, payload)
}
Vue.prototype._storeState = storeState
Vue.prototype._storeGetters = storeGetters
Vue.prototype._storeCommit = storeCommit
Vue.prototype._storeDispatch = storeDispatch
