<template lang="pug">
a-position.ui-prop(v-bind='$attrs', @a-tap='preview')
  template(v-if='!isA')
    template(v-if='lazy')
      a-position(v-if='+propsData.type === 999', wh='100%', pc)
        a-svga.pointer(v-if='!propsData.img || (isOpenEffect && propsData.svga)', v-bind='propsStyle', :url='propsData.svga', pc)
        a-position(v-else, pc, v-bind='propsStyle', bg-s='contain', :bg-i='propsData.img')
      a-position(v-else-if='propsData.type === 6', v-bind='propsStyle', pc)
        a-img.ball(v-if='extra.showUserAvatar', v-imgLazy='$store.state.user.avatar', wh='75%', pc)
        a-img(v-imgLazy='propsData.img', wh='100%', pc, bdr='15%')
      a-position(v-else-if='propsData.type === 23', v-bind='propsStyle', pc, bdr='15%')
        a-img(w='100%', v-imgLazy='propsData.img', pc)
      a-position(v-else-if='propsData.type === 19 && propsData.sub_type', w='100%', pc)
        a-img(w='100%', v-imgLazy='propsData.img', pc)
      a-img(v-else, v-bind='propsStyle', v-imgLazy='propsData.img', pc, bdr='15%', style='object-fit: contain')
    template(v-else)
      a-position(v-if='+propsData.type === 999', wh='100%', pc)
        a-svga.pointer(v-if='!propsData.img || (isOpenEffect && propsData.svga)', v-bind='propsStyle', :url='propsData.svga', pc)
        a-position(v-else, pc, v-bind='propsStyle', bg-s='contain', :bg-i='propsData.img')
      a-position(v-else-if='propsData.type === 6', v-bind='propsStyle', pc)
        a-img.ball(v-if='extra.showUserAvatar', :src='$store.state.user.avatar', wh='75%', pc)
        a-img(:src='propsData.img', wh='100%', pc, bdr='15%')
      a-position(v-else-if='propsData.type === 23', v-bind='propsStyle', pc, bdr='15%')
        a-img(w='100%', :src='propsData.img', pc)
      a-position(v-else-if='propsData.type === 19 && propsData.sub_type', v-bind='avatarPropsStyle', pc)
        a-img(w='100%', :src='propsData.img', pc)
      a-img(v-else, v-bind='propsStyle', :src='propsData.img', pc, bdr='15%', style='object-fit: contain')
  template(v-else)
    //- 阿服
    template(v-if='lazy')
      a-position(v-if='+propsData.type === 999', wh='100%', pc)
        a-svga.pointer(v-if='!propsData.img || (isOpenEffect && propsData.svga)', v-bind='propsStyle', :url='propsData.svga', pc)
        a-position(v-else, pc, v-bind='propsStyle', bg-s='contain', :bg-i='propsData.img')
      a-position(v-else-if='propsData.type === 6', v-bind='propsStyle', pc)
        a-img.ball(v-if='extra.showUserAvatar', v-imgLazy='$store.state.user.avatar', wh='75%', pc)
        a-img(v-imgLazy='propsData.img', wh='100%', pc, bdr='10%')
      a-position(v-else-if='propsData.type === 23', v-bind='propsStyle', pc, bdr='10%')
        a-img(w='100%', v-imgLazy='propsData.img', pc)
      a-position(v-else-if='propsData.type === 19 && propsData.sub_type', w='100%', pc)
        a-img(w='100%', v-imgLazy='propsData.img', pc)
      a-img(v-else, v-bind='propsStyle', v-imgLazy='propsData.img', pc, bdr='10%', style='object-fit: contain')
    template(v-else)
      a-position(v-if='+propsData.type === 999', wh='100%', pc)
        a-svga.pointer(v-if='!propsData.img || (isOpenEffect && propsData.svga)', v-bind='propsStyle', :url='propsData.svga', pc)
        a-position(v-else, pc, v-bind='propsStyle', bg-s='contain', :bg-i='propsData.img')
      a-position(v-else-if='propsData.type === 6', v-bind='propsStyle', pc)
        a-img.ball(v-if='extra.showUserAvatar', :src='$store.state.user.avatar', wh='75%', pc)
        a-img(:src='propsData.img', wh='100%', pc, bdr='10%')
      a-position(v-else-if='propsData.type === 23', v-bind='propsStyle', pc, bdr='10%')
        a-img(w='100%', :src='propsData.img', pc)
      a-position(v-else-if='propsData.type === 19 && propsData.sub_type', v-bind='avatarPropsStyle', pc)
        a-img(w='100%', :src='propsData.img', pc)
      a-img(v-else, v-bind='propsStyle', :src='propsData.img', pc, bdr='10%', style='object-fit: contain')
</template>

<script>
import { getStyleConfig, modelImgWP, modelImgHW, getStyleConfigAr, getAvatarStyleConfig, getAvatarStyleConfigAr } from './utils/config'
import { isA } from '../utils/config'
import { PAGE_REGION, IS_WEPLAY } from '@/utils/env'

export default {
  name: 'ui-prop',

  props: {
    uiMeta: {
      type: Object,
      default: () => {
        return {
          props: {
            data: null
          }
        }
      }
    },
    // propsConfig的item
    data: {
      type: Object,
      default: () => ({})
    },
    lazy: {
      type: Boolean,
      default: false
    },

    extra: {
      type: Object,
      default: () => {
        return {
          // 礼物卡是否展示背景颜色，默认不展示
          showGiftCardBgColor: false,
          // 类型 normal 正方形 circle 圆形，默认normal
          type: 'normal',
          // 特化道具宽高比例
          stylesConfig: {},
          // 是否能预览，默认true,
          canPreview: true,
          // 是否在阿语服头像框下展示用户头像
          showUserAvatar: false
        }
      }
    }
  },

  data() {
    return {
      isA,
      region: this.$route.query.region
    }
  },

  computed: {
    propsData() {
      return this.uiMeta.props.data || this.data
    },
    // type 3：戒指 4：主页特效 6：头像框 10：语音房公屏气泡 11：聊天气泡 12：玩友圈装扮 13：进场特效 19：语音房背景 23：礼物卡 50：座驾 51：皮肤红包
    propsStyle({ propsData, extra: { showGiftCardBgColor, type, stylesConfig } }) {
      let configMap = this.isA ? getStyleConfigAr : getStyleConfig
      const styleConfig = configMap({ propsData, showGiftCardBgColor, type, stylesConfig })
      return styleConfig[propsData.type] || { wh: '90%' }
    },

    showAvatar() {
      return PAGE_REGION !== 'A'
    },

    avatarPropsStyle({ propsData, extra: { type, stylesConfig } }) {
      let configMap = this.isA ? getAvatarStyleConfigAr : getAvatarStyleConfig
      const styleConfig = configMap({ propsData, type, stylesConfig })
      return styleConfig[propsData.sub_type] || { wh: '100%' }
    },

    modelImg() {
      return IS_WEPLAY ? modelImgWP : modelImgHW
    },

    isOpenEffect() {
      return this.$base.isOpenEffect()
    },

    isRuntime() {
      return this.$base.extra().isRuntime
    }
  },

  methods: {
    preview() {
      if (this.extra?.canPreview) {
        this.$propPreview(
          this.data.id,
          this.isRuntime
            ? {
                data: this.data
              }
            : {}
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.center {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 0.08rem;
}

.pointer {
  pointer-events: none;
}
</style>
