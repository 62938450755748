import _ from 'lodash'

import store from '@/store'

export function getStateByModuleName(moduleName) {
  return () => _.get(store.state, moduleName)
}

export function commitByModuleName(moduleName) {
  return (string, payload) => store.commit(`${moduleName}/${string}`, payload)
}

export function dispatchByModuleName(moduleName) {
  return (actionName, payload) => store.dispatch(`${moduleName}/${actionName}`, payload)
}

export function getGettersByModuleName(moduleName) {
  return (getterName) => store.getters[`${moduleName}/${getterName}`]
}

export function methodsInit(moduleName) {
  return {
    getState: getStateByModuleName(moduleName),
    commit: commitByModuleName(moduleName),
    dispatch: dispatchByModuleName(moduleName),
    getGetters: getGettersByModuleName(moduleName)
  }
}

export function getters2Computed(moduleName) {
  const computed = {}

  Object.keys(store.getters).forEach((item) => {
    if (item.includes(`${moduleName}/`)) {
      const key = item.split('/')[1]
      computed[key] = () => store.getters[item]
    }
  })

  return computed
}
