export default {
  namespaced: true,

  state: {
    uid: 0,
    sid: '',
    nickname: '',
    avatar: '',
    deviceId: ''
  },

  mutations: {
    set(state, data) {
      Object.assign(state, data)
    }
  }
}
