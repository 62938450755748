import Vue from 'vue'
import VueRouter from 'vue-router'

import config from '@/config'

import routes from './routes'
import guardRegister from './guardRegister'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: config.$env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
})

guardRegister(router)

export default router
