import Clipboard from 'clipboard'

import toast from '@/lib/page-toast'
import { getQuery1 as getQuery } from '@/utils/utils'
const { region } = getQuery()

let installed = false

const toastMsg = {
  C: {
    success: '複製成功',
    fail: '複製失敗'
  },
  T: {
    success: 'คัดลอกสำเร็จ',
    fail: 'คัดลอกไม่สำเร็จ'
  },
  P: {
    success: 'Copy successful',
    fail: 'Copy failed'
  },
  U: {
    success: 'Copy successful',
    fail: 'Copy failed'
  },
  V: {
    success: 'Sao chép thành công',
    fail: 'Sap chép thất bại'
  }
}

const handler = {
  install(ctx, options) {
    if (installed) {
      return
    }

    const el = new Clipboard(options.el)

    const msg = toastMsg[region] || {}
    el.on('success', (e) => {
      toast(e.trigger.dataset[options.successDataset] || msg?.success)
    })
    el.on('error', (e) => {
      toast(e.trigger.dataset[options.errorDataset] || msg?.fail)
    })

    ctx.directive(options.directive, {
      bind(el, binding) {
        el.classList.add('clipboard-btn')
        el.setAttribute('data-clipboard-text', binding.value)
      }
    })

    installed = true
  }
}

export function install(app) {
  app.use(handler, {
    directive: 'copy',
    el: '.clipboard-btn',
    successDataset: 'copySuccess',
    errorDataset: 'copyError'
  })
}
