export default function updateTitle(title) {
  document.title = title

  let iframe = document.createElement('iframe')
  iframe.src = '/favicon.ico'
  iframe.style.display = 'none'
  let fn = function () {
    setTimeout(function () {
      iframe.removeEventListener('load', fn)
      document.body.removeChild(iframe)
    }, 0)
  }
  iframe.addEventListener('load', fn)
  document.body.appendChild(iframe)
}
