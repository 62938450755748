import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import { NICKNAME_MAP } from './const'
import proxyAssets from '@sub/utils/proxy-assets'

export const defaultRankParams = {
  rank_type: 1,
  period_type: 0,
  offset: 0,
  limit: 10
}

// 可配置的样式（默认值）
export const configurableStyle = {
  // 搜索框
  search: {
    // 默认提示文案
    inputPlaceholder: {
      fontSize: '0.24rem',
      fontWeight: 500,
      color: '#9d815a'
    },
    // 搜索结果框文本
    result: {
      // 作品编号
      workId: {
        fontSize: '0.24rem',
        fontWeight: 500,
        color: '#ffffff'
      },
      // 用户昵称
      nickname: {
        fontSize: '0.22rem',
        fontWeight: 500,
        color: '#FFE0A7'
      },
      // 票数
      voteNumber: {
        fontSize: '0.22rem',
        fontWeight: 400,
        color: '#FFE0A7'
      },
      // 无结果
      none: {
        fontSize: '0.24rem',
        fontWeight: 500,
        color: '#FFE0A7'
      }
    }
  },

  // 投票榜单
  rank: {
    topThree: {
      // 作品编号
      workId: {
        fontSize: '0.24rem',
        fontWeight: 600,
        color: '#000000'
      },
      // 用户昵称
      nickname: {
        fontSize: '0.24rem',
        fontWeight: 600,
        color: '#ffffff'
      },
      // 票数
      voteNumber: {
        fontSize: '0.24rem',
        fontWeight: 400,
        color: '#FFF6BF'
      }
    },
    common: {
      // 排名数字
      rankNum: {
        fontSize: '0.24rem',
        fontWeight: 600,
        color: '#FFDA99'
      },
      // 作品编号
      workId: {
        fontSize: '0.2rem',
        fontWeight: 600,
        color: '#000000'
      },
      // 用户昵称
      nickname: {
        fontSize: '0.22rem',
        fontWeight: 600,
        color: '#FFDA99'
      },
      // 票数
      voteNumber: {
        fontSize: '0.24rem',
        fontWeight: 400,
        color: '#FFDA99'
      }
    },
    mine: {
      // 排名数字
      rankNum: {
        fontSize: '0.24rem',
        fontWeight: 600,
        color: '#593A0F'
      },
      // 作品编号
      workId: {
        fontSize: '0.2rem',
        fontWeight: 600,
        color: '#000000'
      },
      // 用户昵称
      nickname: {
        fontSize: '0.22rem',
        fontWeight: 600,
        color: '#593A0F'
      },
      // 票数
      voteNumber: {
        fontSize: '0.24rem',
        fontWeight: 400,
        color: '#593A0F'
      }
    }
  },

  // 投票确认弹窗
  voteConfirmDialog: {
    // 作品名称
    workName: {
      fontSize: '0.24rem',
      fontWeight: 500,
      color: '#593A0F'
    },
    // 剩余票数
    remainVoteNum: {
      fontSize: '0.28rem',
      fontWeight: 500,
      color: '#000000'
    },
    // 投出票数
    voteNum: {
      fontSize: '0.24rem',
      fontWeight: 500,
      color: '#FFFFFF'
    },
    // 加减控制底板
    inputControl: {
      backgroundColor: '#7B5B3E'
    }
  },

  // 投票成功弹窗
  voteSuccessDialog: {},

  // 查看作品弹窗
  viewWorkDialog: {
    // 作品名称
    workName: {
      fontSize: '0.36rem',
      fontWeight: 500,
      color: '#000000'
    },
    // 用户名称
    userName: {
      fontSize: '0.28rem',
      fontWeight: 500,
      color: '#593A0F'
    }
  }
}

// 榜单每条数据的默认值
export function genDefaultRankItem({ rank, defaultHead = '' }) {
  return {
    rank,
    user_infos: [
      {
        uid: 0,
        nickname: NICKNAME_MAP[PAGE_REGION],
        head_img_url: defaultHead
      }
    ],
    member_extra_info: {
      video_info: {
        vote_count: 0,
        id: '',
        video_cover_url: '',
        video_url: '',
        video_name: '',
        platform: null,
        youtube_video_id: null,
        tiktok_block_quote: ''
      }
    }
  }
}

// 固定的样式
export const defaultStyle = {
  search: {
    bg: {
      w: 6.14,
      h: 0.68,
      bgS: 'contain',
      mr: 'auto',
      ml: 'auto',
      mb: 0.54
    },
    input: {
      w: 5.46,
      h: '100%',
      fs: '0.24rem',
      fw: 500,
      fc: '#ffffff'
    },
    btn: {
      wh: 0.68
    }
  },
  rank: {
    topThreeBg: {
      w: 7.5,
      h: 4.66,
      bgS: 'contain',
      mb: 0.16
    },
    commonBg: {
      w: 6.86,
      h: 1.36,
      bgS: 'contain',
      mr: 'auto',
      ml: 'auto',
      mb: 0.16
    },
    mineBg: {
      w: 6.86,
      h: 1.36,
      bgS: 'contain',
      mr: 'auto',
      ml: 'auto'
    }
  }
}

// 固定的图
export const defaultImages = proxyAssets({
  rank: {
    crown1: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/kMiCb0gQ.png?_w=74&_h=74' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/7v0lQTp9.png?_w=74&_h=74',
    crown2: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/8bqSkRqH.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/OfrodgbS.png?_w=66&_h=66',
    crown3: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/c3KYzrd3.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/elemls2N.png?_w=66&_h=66'
  }
})
