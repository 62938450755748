<template lang="pug">
a-section(v-bind='$attrs')
  a-position(v-bind='{ wh: "6.58 2.32", pc: "x", pb: "0", ...top3Style }')
    a-section(wh='3.04 100%', pc='x', pt='0')
      a-avatar(wh='1.65', v-imgLazy='top3[0].avatar || image.empty', :uid='top3[0].uid', pt='-1.48', pc='x', pz='10', :style='top3[0].uid ? `border: .03rem solid ${borderColor.top1};` : ""')
        a-position(v-if='!isA', :bg-i='image.crown1 || crownMap[1]', pt='-.28', pl='-.08')
        a-position.mirror(v-else, :bg-i='image.crown1 || crownMap[1]', pt='-.28', pr='-.08')
      a-position.ws-nowrap(wh='1.84 .27', lh='.32', ta, pc='x', pt='1.26', :f='`.23 ${textColor.first} 500`') {{ $ts(top3[0].nickname, 6) }}
      a-position.fx-row.fx-center(v-if='showVal', wh='2 .34', pc='x', :pt='isA ? 1.72 : 1.69')
        a-section(:bg-i='image.scoreIcon1')
        a-section(:f='`.23 ${textColor.first} 500`') {{ props.notCompress ? top3[0].value1 : top3[0].value }}{{ props.unit || '' }}
      a-position(v-if='top3[0].ringImg', wh='.8', pc='x', pz='11', pt='-.2')
        a-section(v-if='ringImgBgColor.first', wh='100%', :bg-c='ringImgBgColor.first', bdr='50%', b='.01rem solid #fff')
        a-position(:bg-i='top3[0].ringImg', wh='100%', pc)

    a-section(wh='2.1 100%', pl='0', pt='0')
      a-avatar(wh='1.4', v-imgLazy='top3[`${isA ? "2" : "1"}`].avatar || image.empty', :uid='top3[`${isA ? "2" : "1"}`].uid', pt='-0.95', pc='x', pz='10', :style='top3[`${isA ? "2" : "1"}`].uid ? `border: .03rem solid ${isA ? borderColor.top2 : borderColor.top3};` : ""')
        a-position(v-if='!isA', :bg-i='image.crown2 || crownMap[2]', pt='-.2', pl='-.08')
        a-position.mirror(v-else, :bg-i='image.crown3 || crownMap[3]', pt='-.2', pr='-.08')
      a-position.ws-nowrap(wh='100% .27', lh='.27', ta, pc='x', pt='1.4', :f='`.19 ${isA ? textColor.third : textColor.second} 500`') {{ $ts(top3[`${isA ? '2' : '1'}`].nickname, 6) }}
      a-position.fx-row.fx-center(v-if='showVal', wh='100% .34', pc='x', :pt='isA ? 1.75 : 1.69')
        a-section(:bg-i='isA ? image.scoreIcon3 : image.scoreIcon2')
        a-section(:f='`.23 ${isA ? textColor.third : textColor.second} 500`') {{ top3[isA ? '2' : '1'][props.notCompress ? `value1` : `value`] }}{{ props.unit || '' }}
      a-position(v-if='top3[`${isA ? "2" : "1"}`].ringImg', wh='.6', pc='x', pz='11', pt='0.2')
        a-section(v-if='ringImgBgColor.second', wh='100%', :bg-c='ringImgBgColor.second', bdr='50%', b='.01rem solid #fff')
        a-position(:bg-i='top3[`${isA ? "2" : "1"}`].ringImg', wh='100%', pc)

    a-section(wh='2.1 100%', pr='0', pt='0')
      a-avatar(wh='1.4', v-imgLazy='top3[`${isA ? "1" : "2"}`].avatar || image.empty', :uid='top3[`${isA ? "1" : "2"}`].uid', pt='-0.95', pc='x', pz='10', :style='top3[`${isA ? "1" : "2"}`].uid ? `border: .03rem solid ${borderColor.top3};` : ""')
        a-position(v-if='!isA', :bg-i='image.crown3 || crownMap[3]', pt='-.2', pl='-.08')
        a-position.mirror(v-else, :bg-i='image.crown2 || crownMap[2]', pt='-.2', pr='-.08')
      a-position(wh='100% .27', lh='.27', ta, pc='x', pt='1.4', :f='`.19 ${isA ? textColor.second : textColor.third} 500`') {{ $ts(top3[`${isA ? '1' : '2'}`].nickname, 6) }}
      a-position.fx-row.fx-center(v-if='showVal', wh='100% .34', pc='x', :pt='isA ? 1.75 : 1.69')
        a-section(:bg-i='isA ? image.scoreIcon2 : image.scoreIcon3')
        a-section(:f='`.23 ${isA ? textColor.second : textColor.third} 500`') {{ top3[`${isA ? '1' : '2'}`][props.notCompress ? `value1` : `value`] }}{{ props.unit || '' }}
      a-position(v-if='top3[`${isA ? "1" : "2"}`].ringImg', wh='.6', pc='x', pz='11', pt='0.2')
        a-section(v-if='ringImgBgColor.third', wh='100%', :bg-c='ringImgBgColor.third', bdr='50%', b='.01rem solid #fff')
        a-position(:bg-i='top3[`${isA ? "1" : "2"}`].ringImg', wh='100%', pc)
</template>
<script>
import { isA } from '@sub/ui-components/utils/config'
import { IS_WEPLAY } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

export default {
  props: {
    top3: {
      default: () => [],
      type: Array
    },

    props: {
      default: () => {},
      type: Object
    },
    showVal: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isA,
      crownMap: proxyAssets({
        1: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/kMiCb0gQ.png?_w=74&_h=74' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/7v0lQTp9.png?_w=74&_h=74',
        2: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/8bqSkRqH.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/OfrodgbS.png?_w=66&_h=66',
        3: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/c3KYzrd3.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/elemls2N.png?_w=66&_h=66'
      })
    }
  },

  computed: {
    image({ props }) {
      return props?.image
    },

    textColor({ props }) {
      return props?.textColor
    },

    borderColor() {
      return this.props?.borderColor || {}
    },

    ringImgBgColor({ props }) {
      return props?.ringImgBgColor || {}
    },
    top3Style() {
      return this.props?.top3Style || {}
    }
  }
}
</script>

<style>
.test {
  background-color: rgba(0, 0, 0, 0.4);
}

.mirror {
  transform: scaleX(-1);
}
</style>
