// 预发布和 production 完全保持一致
module.exports = require('./production')

// module.exports = {
//   // 华语服
//   C: {
//     request: {
//       h1: 'https://api.weplayapp.com',
//       h2: 'https://admin-api.weplayapp.com',
//       h3: 'https://api.weplayapp.com'
//     },
//     linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
//     dynamicLink: dynamicLinkInfo['C'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: false,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+08:00',
//     shareType: [9, 10, 11, 6]
//   },

//   // 美服
//   U: {
//     request: {
//       h1: 'https://api-sv.weplayapp.com',
//       h2: 'https://admin-api-sv.weplayapp.com',
//       h3: 'https://api-sv.weplayapp.com'
//     },
//     linkedme: 'c1f7871271680dcfe1bda102277bc60b',
//     dynamicLink: dynamicLinkInfo['U'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: false,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '-04:00',
//     shareType: [9, 11, 6]
//   },

//   // 日服
//   J: {
//     request: {
//       h1: 'https://api-tky.weplayapp.com',
//       h2: 'https://admin-api-tky.weplayapp.com',
//       h3: 'https://api-tky.weplayapp.com'
//     },
//     linkedme: '7844ae249178d2ab84f51304a39f735c',
//     dynamicLink: dynamicLinkInfo['J'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: false,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+09:00',
//     shareType: [10, 11, 6]
//   },

//   // 阿服
//   A: {
//     request: {
//       h1: 'https://api-fra.weplayapp.com',
//       h2: 'https://admin-api-fra.weplayapp.com',
//       h3: 'https://api-fra.weplayapp.com'
//     },
//     linkedme: '70c3bf9fc46cdeb3e2f3515ae734c0f7',
//     dynamicLink: dynamicLinkInfo['A'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: false,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+03:00',
//     shareType: [9, 10, 11, 6]
//   },

//   // 韩服
//   K: {
//     request: {
//       h1: 'https://api-sel.weplayapp.com',
//       h2: 'https://admin-api-sel.weplayapp.com',
//       h3: 'https://api-sel.weplayapp.com'
//     },
//     appsflyer: 'https://weplay-kr.onelink.me/XWAd',
//     dynamicLink: dynamicLinkInfo['K'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: false,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+09:00',
//     shareType: [9, 13, 11, 6]
//   },
//   // 泰服
//   T: {
//     request: {
//       h1: 'https://api-bkk.weplayapp.com',
//       h2: 'https://admin-api-bkk.weplayapp.com',
//       h3: 'https://api-bkk.weplayapp.com'
//     },
//     linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
//     dynamicLink: dynamicLinkInfo['C'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: true,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+07:00',
//     shareType: [9, 13, 11, 6]
//   },
//   // 马来服
//   M: {
//     request: {
//       h1: 'https://api-mas.weplayapp.com',
//       h2: 'https://admin-api-mas.weplayapp.com',
//       h3: 'https://api-mas.weplayapp.com'
//     },
//     // appsflyer: 'https://weplay-kr.onelink.me/XWAd',
//     dynamicLink: dynamicLinkInfo['C'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: true,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+08:00',
//     shareType: [9, 13, 11, 6]
//   },
//   // 菲律宾服
//   P: {
//     request: {
//       h1: 'https://api-phl.weplayapp.com',
//       h2: 'https://admin-api-phl.weplayapp.com'
//     },
//     linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
//     dynamicLink: dynamicLinkInfo['C'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: true,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+08:00',
//     shareType: [9, 11, 6]
//   },
//   // 越南服
//   V: {
//     request: {
//       h1: 'https://api-vnm.weplayapp.com',
//       h2: 'https://admin-api-vnm.weplayapp.com'
//     },
//     linkedme: '960307eb8eca988d0a97cb2cdfb1263f',
//     dynamicLink: dynamicLinkInfo['C'],
//     point: {
//       appId: 'fefb9ffb64ec4e4983cee9e30a896327',
//       serverUrl: 'https://think-client.weplayapp.com',
//       enableLog: true,
//       autoTrack: {
//         appLaunch: true,
//         appShow: true,
//         appHide: true,
//         pageShow: true,
//         pageShare: true
//       }
//     },
//     utc: '+07:00',
//     shareType: [9, 11, 6]
//   }
// }
