<template lang="pug">
  a-section(v-bind="$attrs" :bg-i='BGI' z-index="10")
    a-position(wh='6.9 1.7' pc)
      template(v-if='isInRank')
        //- 排名 icon
        a-position(v-if='index < 4' :bg-i='image[`scoreIcon${(index)}`]' pc="y" pl=".11") 
        a-position(v-else='index >= 4' :f='`.22 ${fontColor("rank")} 600`' pc="y" pl="0") {{index}}
        //- 队伍名
        a-position(:f='`.24 ${fontColor()} 600`' pc='y' pl='.88') {{itemObj.teamName}}

      a-position(v-else pt='0.47' pl='0.8' w='1.1')
        a-section.txt-c(:f='`.22 ${fontColor()} 600`' w='1.1') {{itemObj.teamName || ' '}}
        a-section(:bg-i='image.notInRank' pc='x')
  
      //- 队伍头像
      a-avatar(v-if="itemObj.user1.uid" :bg-i='itemObj.user1.avatar || image.inviteFriends' :uid="itemObj.user1.uid" pt='0.27' pl='2' wh='.94')
        a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user1.nickname}}
        a-position(v-if="index < 4" :bg-i='image[`crown${(index)}`]' pt='-.2' pl='0')
      a-position(v-else :bg-i='image.inviteFriends' pt='0.27' pl='2' wh='.94' @a-tap="handleClickInvite()")

      a-avatar(v-if="itemObj.user2.uid" :bg-i='itemObj.user2.avatar || image.inviteFriends' :uid="itemObj.user2.uid" pt='0.27' pl='2.77' wh='.94' )
        a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user2.nickname}}
      a-position(v-else :bg-i='image.inviteFriends' pt='0.27' pl='2.77' wh='.94' @a-tap="handleClickInvite()")

      a-avatar(v-if="itemObj.user3.uid" :bg-i='itemObj.user3.avatar || image.inviteFriends' :uid="itemObj.user3.uid" pt='0.27' pl='3.47' wh='.94' )
        a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user3.nickname}}
      a-position(v-else :bg-i='image.inviteFriends' pt='0.27' pl='3.47' wh='.94' @a-tap="handleClickInvite()")

      a-avatar(v-if="itemObj.user4.uid" :bg-i='itemObj.user4.avatar || image.inviteFriends' :uid="itemObj.user4.uid" pt='0.27' pl='4.22' wh='.94' )
        a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user4.nickname}}
      a-position(v-else :bg-i='image.inviteFriends' pt='0.27' pl='4.22' wh='.94' @a-tap="handleClickInvite()")
  
      //- 值
      a-position(:bg-i="image.rankIconOther" pc="y" pl="5.55")
      a-position(pt=".71" pl="5.93" :f='`.24 ${fontColor("value")} 600`') {{props.notCompress ? itemObj.value1 : itemObj.value}}
     
  </template>

<script>
const DEFAULT_ITEM = {
  value: 0,
  user1: {},
  user2: {},
  user3: {},
  user4: {},
  teamName: ''
}

export default {
  props: {
    itemObj: {
      type: Object,
      default: () => DEFAULT_ITEM
    },
    image: {
      type: Object,
      default: () => {}
    },
    textStyle: {
      type: Object,
      default: () => {}
    },
    rankConfig: {
      type: Object,
      default: () => {}
    },
    index: {
      type: [Number, String],
      default: 1
    },

    mineBG: {
      type: String,
      default: ''
    },
    props: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    BGI() {
      return this.mineBG
    },

    isInRank() {
      return this.itemObj.rank && this.itemObj.rank <= this.rankConfig?.rankLength
    }
  },

  mounted() {},

  methods: {
    handleClickInvite() {
      this.$store.state.team.openSearchList()
    },

    getRankIcon() {
      return this.image.rankIconOther
    },

    fontColor(type) {
      if (type === 'score') {
        return this.textStyle.myScoreColor
      }
      if (type === 'rank') {
        return this.textStyle.rankColor
      }

      return this.textStyle.myUserInfoColor
    }
  }
}
</script>

<style lang="scss"></style>
