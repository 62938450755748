<template lang="pug">
a-section.fx-row.fx-c-center.ui-input-control-wrapper(v-bind='attrs')
  a-section.fx-row.fx-c-center.ui-input-control__inputBg(:bg-i='uiMeta.props.images.inputBg', bg-s='contain')
    a-position.fx-row.fx-c-center(wh='100%', pc='x', :style='getStyle("inputBg")', :pdl='isMiddleEast ? 0 : 0.02', :pdr='isMiddleEast ? 0 : 0.02')
      a-button1.ui-input-control__minusImg(:bg-i='minusImg', :class='{ grey: value <= 1 }', @a-tap='changeNum("-")', bg-s='contain')
      input.ui-input-control__input(v-if='uiMeta.props.type === 1', :style='style', inputmode='numeric', v-model.number='value', @blur='checkNumberBlur($event.target.value)', @input='checkNumber($event.target.value)')
      a-section.ws-nowrap(v-else-if='uiMeta.props.type === 2', w='100%', fs='.24', :fc='uiMeta.props.textColor', :fw='500', ta) {{ value }}/{{ realLimit }}
      a-section.ws-nowrap(v-else, w='100%', fs='.24', :fc='uiMeta.props.textColor', :fw='500', ta) {{ value }}
      a-button1.ui-input-control__addImg(:bg-i='addImg', :class='{ grey: value >= realLimit }', @a-tap='changeNum("+")', bg-s='contain')
  a-button1.ui-input-control__maxImg(v-if='maxImg', :bg-i='maxImg', :class='{ grey: value >= realLimit }', @a-tap='changeNum("max")', v-bind='{ [_mlr]: isA ? 0.03 : 0.08 }')
</template>

<script>
import get from 'lodash/get'
import { isA, isQ } from '@sub/ui-components/utils/config'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'

export default {
  mixins: [attrs],

  components: {},

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    propLimit: {
      type: Number,
      default: -1
    },

    // 最小值限制
    propMin: {
      type: Number,
      default: 1
    },

    propValue: {
      type: [Number, String],
      default: 1
    },

    inputKey: {
      type: Number,
      default: 0
    }
  },

  computed: {
    minusImg({ value, uiMeta }) {
      return value > 1 ? get(uiMeta, 'props.images.minusImg', '') : get(uiMeta, 'props.images.minusImgGrey', '') || get(uiMeta, 'props.images.minusImg', '')
    },

    addImg({ value, uiMeta, realLimit }) {
      return value < realLimit ? get(uiMeta, 'props.images.addImg', '') : get(uiMeta, 'props.images.addImgGrey', '') || get(uiMeta, 'props.images.addImg', '')
    },

    maxImg({ value, uiMeta, realLimit }) {
      return value < realLimit ? get(uiMeta, 'props.images.maxImg', '') : get(uiMeta, 'props.images.maxImgGrey', '') || get(uiMeta, 'props.images.maxImg', '')
    },

    realLimit() {
      return this.propLimit !== -1 ? this.propLimit : this.limit
    },

    limit({ uiMeta }) {
      return get(uiMeta, 'props.limit', 1)
    },
    isMiddleEast() {
      return this.isA || this.isQ
    }
  },

  data() {
    return {
      style: {},
      value: 1,
      isA,
      isQ
    }
  },

  created() {
    this.style = {
      border: 'none',
      background: 'transparent',
      width: `100%`,
      height: `100%`,
      'font-weight': 500,
      'font-size': '0.28rem',
      'text-align': 'center',
      color: get(this.uiMeta, 'props.textColor', '#fffff')
    }
  },

  watch: {
    propValue: {
      handler(val) {
        this.value = val
        if (isNaN(val)) {
          this.value = this.propMin
        }
      },
      immediate: true
    },

    value: {
      handler(val) {
        this.$emit('change-value', val, this.inputKey)
      },
      immediate: true
    }
  },
  mounted() {
    this.value = this.propValue
  },

  methods: {
    changeNum(type) {
      switch (type) {
        case '-':
          this.$emit('onMinus')
          if (this.value === this.propMin || !this.value) {
            return this.$toast(this.$tf(this.uiMeta.props.leastToast))
          }
          --this.value

          break
        case '+':
          this.$emit('onAdd')
          if (this.value >= this.realLimit) {
            return this.$toast(this.$tf(this.uiMeta.props.overToast))
          }
          ++this.value

          break
        case 'max':
          this.value = this.realLimit
          break
      }
    },

    checkNumber(val) {
      if (String(val).indexOf('.') !== -1 || isNaN(parseInt(val))) {
        // 输入时可以清空
        this.value = parseInt(val) || ''
        return
      }
      this.value = Math.max(1, Math.min(val, this.realLimit))
    },

    checkNumberBlur(val) {
      if (String(val).indexOf('.') !== -1 || isNaN(parseInt(val))) {
        this.value = parseInt(val) || 1
        return
      }
      this.value = Math.max(1, Math.min(val, this.realLimit)) || 1
    },

    getStyle(name) {
      return get(this.uiMeta, `props.specialStyle.${name}`, {})
    }
  }
}
</script>
