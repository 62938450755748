// iframe 的整体方案见 submodule/activity-builder/BuilderManager/IframeHelper.ts 中的注释和 ts 类型

// 本文件用于，对外暴露一些跟 iframe 有关的 api，从而保证逻辑的统一收敛和解耦（运行时和 builder 的 iframe 通信相关逻辑）

import EventEmitter from 'event-emitter'

export const EVENT = {
  ON_TABS_SWITCH: 'ON_TABS_SWITCH'
  // ON_UI_META: 'ON_UI_META',
  // ON_REWARD_DEPENDENCIES: 'ON_REWARD_DEPENDENCIES'
}

export const eventEmitter = new EventEmitter()

import getQuery from '@sub/ui-runtime/core/utils/getQuery'
import { setUiMetaPageCenterSnapshot } from '@sub/utils/page-center-snapshot'

const MessageType = {
  action: 'action',
  heartbeat: 'heartbeat'
}

const ActionType = {
  init: 'init',
  tabsSwitch: 'tabs-switch',
  uiMeta: 'uiMeta',
  layout: 'layout',
  uid: 'uid',
  rewardDependencies: 'rewardDependencies'
}

export function isInIframe() {
  const { iframeParentOrigin } = getQuery()
  return !!iframeParentOrigin && iframeParentOrigin !== 'false'
}

// 活动后台的环境：prod：正式服和测试服，dev：beta和本地
export function getActivityBuilderEnv() {
  const { iframeActivityBuilderEnv } = getQuery()
  return iframeActivityBuilderEnv
}

export function init($store) {
  // 初始化 watch update 逻辑
  watchBuilderMessage($store)

  emitEventToBuilder({
    type: MessageType.action,
    action: ActionType.init
  })
  console.debug('iframe init emitEventToBuilder')

  // 心跳逻辑
  initHeartbeat()
}

export function watchBuilderMessage($store) {
  window.addEventListener('message', (event) => {
    const domainWhiteList = ['localhost', 'weplayapp.com', '17zjh.com', 'wepieoa.com']
    if (domainWhiteList.every((domain) => !event.origin.includes(domain))) {
      return
    }

    if (event.data.type === 'webpackProgress' || !event.data.action) {
      return
    }

    handleBuilderMessage(event, $store)
  })
}

function handleBuilderMessage(event, $store) {
  console.log('ywtest: ~ handleBuilderMessage ~ event.data:', event.data)

  const { action, payload } = event.data

  if (action === ActionType.uiMeta) {
    const { uiMeta } = payload

    setUiMetaPageCenterSnapshot({ ui_meta: uiMeta })

    $store.dispatch('base/updateUiMeta', uiMeta)
  }

  if (action === ActionType.tabsSwitch) {
    eventEmitter.emit(EVENT.ON_TABS_SWITCH, payload)
  }

  if (action === ActionType.rewardDependencies) {
    $store.commit('reward/setState', payload.rewardDependencies || {})
  }
}

function initHeartbeat() {
  sendHeartbeat()
  setInterval(() => {
    sendHeartbeat()
  }, 30 * 1000)

  function sendHeartbeat() {
    emitEventToBuilder({ type: MessageType.heartbeat, payload: Date.now() })
  }
}

function emitEventToBuilder({ type, action, payload }) {
  const { iframeParentOrigin } = getQuery()
  window.parent.postMessage(
    {
      type,
      action,
      payload
    },
    iframeParentOrigin
  )
}
