<template lang="pug">
a-section.ui-prop-fc-preview
  a-dialog.txt-c.ui-prop-fc-preview__dialog(ref='uiPropFcPreview', name='uiPropFcPreview', wh='6.06 7.65')
    template(slot-scope='scope', v-if='scope.data')
      a-section.fx-col.fx-center(wh='6.06 7.65', @a-tap='$dialogs.uiPropFcPreview.close()')
        a-section.fx-col.fx-center(@a-tap='tapContent($event)')
          a-section(bdr='.18rem .18rem 0 0', :bg-c='scope.data.propBgColor || propBgColor')
            a-section(v-if='scope.data.chairUrl', wh='6.06')
              ui-prop(:data='scope.data', wh='2.5', pc, :extra='{ canPreview: false }')
              //- 注意wh属性名冲突
              alpha-video(:src='scope.data.chairUrl', wh='6.06', :_wh='[6.06, 6.06]', :autoplay='true', :loop='true')
            a-section(v-else-if='!scope.data.imgBig', wh='6.06 2.88')
              ui-prop(:data='scope.data', wh='2.5', pc, :extra='{ canPreview: false }')
            a-section(v-else, wh='6.06')
              a-img(:src='scope.data.imgBig', wh='100%', pc)
          a-section.fx-col.fx-cl-center.fx-m-around.fx-c-center(wh='6.06 1.6', pdt='.2', pdb='.2', bdr='0 0 .18rem .18rem', bg-c='#fff')
            a-section.txt-c(w='5', f='.32 #333333 500') {{ scope.data.name }}
            a-section(w='5', f='.24 #979797 500') {{ scope.data.desc }}
</template>

<script>
import get from 'lodash/get'
import alphaVideo from './components/alpha-video.vue'
import { rewardMetaToPropData } from '@sub/utils/reward-meta-adpter'

export default {
  components: { alphaVideo },
  props: {
    uiMeta: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {}
  },

  mounted() {
    this.$uiComp.setUiCompConfig({
      compName: 'uiPropFcPreview',
      value: {
        openPreview: this.openPreview.bind(this)
      }
    })
  },

  computed: {
    isRuntime() {
      return this.$base.extra().isRuntime
    },

    key({ uiMeta }) {
      return get(uiMeta, 'key', '')
    },

    propBgColor({ uiMeta }) {
      return get(uiMeta, 'props.propBgColor', '#B5D3FF') || '#B5D3FF'
    },

    previewList({ uiMeta }) {
      const avatarProps = []
      //- 源码、mdc模式下，自动导入page-center配置的所有avatar道具
      const propsConfig = this.$propsConfig() || {}
      if (Object.keys(propsConfig).length) {
        const allProps = propsConfig
        for (const key in allProps) {
          if (Object.hasOwnProperty.call(allProps, key)) {
            const it = allProps[key]
            if (this.$isAvatarProp(key)) {
              avatarProps.push({ ...it, propBgColor: it.bg_color })
            }
          }
        }
      }

      return avatarProps.concat(
        get(uiMeta, 'props.previewList', []).map((item) => {
          if (!this.isRuntime) {
            item = { ...item, ...(this.$propsConfig(item.id) || {}) }
          }
          return this.dataAdapter(item)
        })
      )
    },

    preViewConfig({ previewList }) {
      return Object.fromEntries(previewList.map((item) => [item.id, item]))
    },

    avatarGenderIsMale() {
      return this.$base.amBasic().user.avatarGender === 1
    }
  },

  methods: {
    dataAdapter(rawData) {
      let ret = { ...rawData }
      if (this.$isAvatarProp(ret.id)) {
        ret = {
          ...ret,
          img: this.avatarGenderIsMale ? ret.male_cover : ret.female_cover,
          name: this.avatarGenderIsMale ? ret.male_name : ret.female_name
        }
      }
      return ret
    },

    tapContent(e) {
      e.cancelBubble = true
    },

    openPreview(id, extra) {
      // 直接传入 extra.data 为 Runtime 场景，源码活动从 previewList 中取 data
      const data = extra?.data ? rewardMetaToPropData(extra.data, { avatarGenderIsMale: this.avatarGenderIsMale }) : this.preViewConfig[id]
      data && this.$dialogs.uiPropFcPreview.open({ data, options: { bgClose: true } })
    }
  }
}
</script>
