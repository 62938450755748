<template lang="pug">
a-section.txt-c
  a-dialog.txt-c(:id='dialogName', :name='dialogName', :bg-i='imageExchange.dialogBg')
    template(slot-scope='scope', v-if='scope.data')
      slot(name='exchangeConfirm')
      //- 奖励展示
      span(:data-var='(rewardInfo = scope.data)')
      a-position(v-bind='exchangeConfirmStyleConfig.reward.wrap', :bg-i='imageExchange.rewardBg', bg-s='contain')
        //- 道具
        ui-prop(:data='rewardInfo', wh='100%', pc)
        //- 角标
        a-border-text1(v-bind='{ ...exchangeConfirmStyleConfig.reward.subscript, fc: textStyle.subscriptColor }', borderWidth='3', :borderColor='textStyle.subscriptBorderColor', :borderContent='rewardInfo.unit')
        //- 奖励名称
        a-position.txt-no-wrap(v-bind='exchangeConfirmStyleConfig.reward.rewardName', :fc='textStyle.propsNameColor') {{ rewardInfo.rewardNameWithoutUnit }}

      template(v-if='isPermanent')
        //- 永久道具兑换提示
        a-position(v-bind='exchangeConfirmStyleConfig.permanentExchange', :fc='textStyle.propsNameColor', v-html='getPermanentExchangeText(rewardInfo)')
      template(v-else)
        //- input
        a-position(v-bind='exchangeConfirmStyleConfig.input.inputBg', :bg-i='imageExchange.inputBg')
          //- 减
          a-position(v-bind='exchangeConfirmStyleConfig.input.reduce')
            a-button1(v-show='rewardInfo.exchangeNum > 1', :bg-i='imageExchange.reduce', pc, bg-s='100%', @a-tap='onReduce')
            a-position.grey(v-show='rewardInfo.exchangeNum <= 1', :bg-i='imageExchange.reduce', pc, bg-s='100%', @a-tap='onReduce')
          //- 加
          a-position(v-bind='exchangeConfirmStyleConfig.input.add')
            a-button1(v-show='rewardInfo.exchangeNum < rewardInfo.maxNum', :bg-i='imageExchange.add', pc, bg-s='100%', @a-tap='onAdd')
            a-position.grey(v-show='rewardInfo.exchangeNum >= rewardInfo.maxNum', :bg-i='imageExchange.add', pc, bg-s='100%', @a-tap='onAdd')
          //- num
          a-position(v-bind='exchangeConfirmStyleConfig.input.num', :fc='textStyle.inputNumColor') {{ rewardInfo.exchangeNum }}

        //- max
        a-button1(:bg-i='imageExchange.max', bg-s='100%', v-bind='exchangeConfirmStyleConfig.max', @a-tap='onMax(rewardInfo.maxNum)')

        //- 可兑换数量
        a-position(v-bind='exchangeConfirmStyleConfig.canExchangeNum', :fc='textStyle.maxNumColor', v-html='$text(textWords.maxText || textConfig.maxText, { maxNum: rewardInfo.maxNum })')

      //- 确认、取消
      a-button1(:bg-i='imageExchange.cancel', v-bind='exchangeConfirmStyleConfig.cancelBtn', @a-tap='$dialogs[dialogName].close()')
      a-button1(:bg-i='imageExchange.confirm', v-bind='exchangeConfirmStyleConfig.confirmBtn', @a-tap='onExchange(rewardInfo)')

  //- a-dialog.txt-c(:name='confirmDialogName', :bg-i='imageConfirm.confirmDialogBg')
  //-   template(slot-scope='{ data }', v-if='data')
  //-     slot(name='confirmDialog')
  //-     a-position(v-bind='{ ...confirmStyleConfig.textWrap, ...getCustomizeStyle("textWrap") }', :fc='textStyle.confirmTextColor')
  //-       a-position(w='100%', v-html='exchangeConfirmText(textWords.confirmText || textConfig.confirmText, data)', pc)
  //-     a-position.fx-row.fx-m-center.fx-c-center(v-bind='{ ...confirmStyleConfig.tipWrap, ...getCustomizeStyle("tipWrap") }', @a-tap='toggleTip')
  //-       a-section(:bg-i='imageConfirm.selectBorder', bg-s='contain')
  //-         a-position(v-show='!storageInfo[storageKey]', :bg-i='imageConfirm.selectIcon', pc, bg-s='contain')
  //-       a-section(w='.1')
  //-       a-section(v-html='commonText.ignoreTip', :fc='textStyle.confirmTipColor')
  //-   a-button1(slot='cancel', v-bind='confirmStyleConfig.cancelButton', :bg-i='imageConfirm.cancelButton', @a-tap='$dialogs[confirmDialogName].close()')
  //-   a-button1(slot='confirm', v-bind='confirmStyleConfig.confirmButton', :bg-i='imageConfirm.confirmButton', @a-tap='$dialogs[confirmDialogName].close()')
</template>
<script>
import { getNumUnit } from '@/utils/utils'
import { buildApi, concatCatch } from '@sub/utils/request'
// import { commonText } from '@sub/ui-components/utils/config'
import { confirmStyleConfig, exchangeConfirmStyleConfig, textConfig } from './utils/config'
import { isA } from '../utils/config'
import { genComponentCurRegionText } from '@sub/utils/page-center-snapshot'
import { get, isEmpty } from 'lodash'

const doExchange = buildApi('/activity_v2/exchange_store/exchange')

export default {
  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    },

    context: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      exchangeConfirmStyleConfig,
      // commonText,
      confirmStyleConfig,
      isA,
      lastClassName: []
    }
  },

  computed: {
    imageExchange({ uiMeta }) {
      return get(uiMeta, 'props.image.exchange', {})
    },

    imageConfirm({ uiMeta }) {
      return get(uiMeta, 'props.image.confirm', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.text.style', {})
    },

    textWords({ uiMeta }) {
      return get(uiMeta, 'props.text.words', {})
    },

    textConfig() {
      let textMap = genComponentCurRegionText('b-basic-exchange-wrapper', this.context)

      if (!this._isRuntime || isEmpty(textMap)) {
        textMap = textConfig[this.context.region]
      }

      return textMap
    },

    dialogName({ uiMeta: { key } }) {
      return key
    },

    // confirmDialogName({ dialogName }) {
    //   return `${dialogName}UiExchangeConfirm`
    // },

    rewardDialogKey({ uiMeta: { rewardDialogKey } }) {
      return rewardDialogKey
    },

    buyConfig({ uiMeta }) {
      return get(uiMeta, 'props.buyConfig', [])
    },

    chipId({ uiMeta }) {
      return get(uiMeta, 'props.chipId', 1)
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {}) || {}
    },

    // storageInfo() {
    //   return this.$store.state.storage.localStorage
    // },

    buyChipInfo({ chipId }) {
      return {
        coin: this.$store.state.base.coin,
        chipCount: this.$store.state.base.chipInfo[chipId]
      }
    },

    storageKey({ dialogName }) {
      return `${dialogName}-uiExchangeConfirmTip`
    },

    isPermanent() {
      return get(this.$dialogs[this.dialogName].data, 'act_extra.is_permanent')
    }
  },

  created() {
    this.$store.dispatch('storage/init', { [this.storageKey]: true })
    this.$uiComp.setUiCompConfig({
      compName: 'uiBuyChipDialog',
      compKey: this.dialogName,
      value: {
        updateStorage: this.updateStorage.bind(this)
      }
    })
  },

  methods: {
    // getCustomizeStyle(name) {
    //   return get(this.customizeStyle, name, {}) || {}
    // },

    // toggleTip() {
    //   this.$store.dispatch('storage/toggle', this.storageKey)
    // },

    updateStorage(flag) {
      this.$store.commit('storage/update', { [this.storageKey]: !!flag })
    },

    updateChipInfo() {
      this.$base.getChipInfo()
      this.$emit('exchange')
    },

    onReduce() {
      const oldData = this.$dialogs[this.dialogName].data
      const { exchangeNum } = oldData
      if (exchangeNum === 1) {
        this.$toast(this.textConfig.leastToast)
        return
      }

      this.$dialogs[this.dialogName].update({ ...oldData, exchangeNum: exchangeNum - 1 })
    },

    onAdd() {
      const oldData = this.$dialogs[this.dialogName].data
      const { exchangeNum, maxNum } = oldData
      if (exchangeNum === maxNum) {
        this.$toast(this.textConfig.overToast)
        return
      }

      this.$dialogs[this.dialogName].update({ ...oldData, exchangeNum: exchangeNum + 1 })
    },

    onMax(maxNum) {
      this.$dialogs[this.dialogName].update({ ...this.$dialogs[this.dialogName].data, exchangeNum: maxNum })
    },

    async onExchange(data) {
      const { exchangeNum } = data
      // if (this.storageInfo[this.storageKey]) {
      //   const res = await this.$dialogs[this.confirmDialogName].confirm({ data: { totalCost: data.cost * exchangeNum, exchangeNum, rewardName: data.reward_name } })
      //   if (!res) {
      //     return
      //   }
      // }

      this.$dialogs[this.dialogName].close()

      const [err, res] = await concatCatch(doExchange({ target_uid: this.$base.amApiParam().uid, store_name: data.storeName, exchange_count: exchangeNum, exchange_type: data.exchange_type }))
      if (err) {
        console.error('🐲 gwl ~ onExchange ~ err:', err)
        return
      }

      const { rewards } = res
      this.$dialogs[this.rewardDialogKey].open({
        data: {
          rewardList: [{ ...data, unit: getNumUnit(rewards[0].reward_num) }]
        }
      })

      this.updateChipInfo()
    },

    // exchangeConfirmText(key, data) {
    //   return this.$text(key, {
    //     costNum: data.totalCost,
    //     chipName: this.textWords.chipName,
    //     rewardName: data.rewardName,
    //     exchangeNum: data.exchangeNum
    //   })
    // },

    getPermanentExchangeText(rewardInfo) {
      return this.$text(this.textWords.permanentExchange || this.textConfig.permanentExchange, {
        costNum: rewardInfo.cost,
        chipName: this.textWords.chipName,
        rewardName: rewardInfo.reward_name
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
