<template lang="pug">
  a-section.ui-recharge-discount(v-bind="$attrs")
    a-position.ui-recharge-discount__num(v-html="$tf('discount/num', discount.used_coupon, discount.quota)")

    a-position.ui-recharge-discount__coin(v-html="isStandard ? $tf('discount/coinEnough') : $tf('discount/coin', discount.coin_cost, discount.need_coin_cost)")

    a-position.ui-recharge-discount__unlocked(:bg-i="isStandard ? unlockedImg1 : unlockedImg0")
    
    ui-button.ui-recharge-discount__to-pay(:bg-i="toPayImg" pc="x" @a-tap="toPay(toPayEvent)")

</template>

<script>
import get from 'lodash/get'
import { buildApi } from '@sub/utils/request'

const getUserDiscountInfo = buildApi('/activity_v2/charge_coupon/get_user_discount_info')

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      discount: {}
    }
  },

  computed: {
    defaultDiscount({ uiMeta }) {
      return get(uiMeta, 'props.defaultDiscount', {})
    },

    unlockedImg0({ uiMeta }) {
      return get(uiMeta, 'props.unlockedImg0', '')
    },

    unlockedImg1({ uiMeta }) {
      return get(uiMeta, 'props.unlockedImg1', '')
    },

    toPayImg({ uiMeta }) {
      return get(uiMeta, 'props.toPayImg', '')
    },

    toPayEvent({ uiMeta }) {
      return get(uiMeta, 'props.toPayEvent', {})
    },

    isStandard() {
      return this.discount.need_coin_cost && this.discount.coin_cost >= this.discount.need_coin_cost
    }
  },

  created() {
    this.discount = this.defaultDiscount
  },

  mounted() {
    this.getUserDiscountInfo()
  },

  onPageShow() {
    this.getUserDiscountInfo()
  },

  methods: {
    async getUserDiscountInfo() {
      this.discount = await getUserDiscountInfo()
    },

    toPay({ type, params }) {
      if (type === 'roomList') {
        this.$we('jumpDeepLink', 'wespydeeplink://voice_room_list')
      } else if (type === 'customize') {
        this.$emit('toPayCallback', params)
      } else {
        this.$we('pay')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-recharge-discount {
  width: 100%;
  height: 100%;
}

.ui-recharge-discount__num {
  width: 2.25rem;
  height: 0.37rem;
  line-height: 0.37rem;
  left: 1.14rem;
  top: 11.83rem;
  text-align: center;
  white-space: nowrap;
  font-size: 0.2rem;
  color: #a94f33;
  font-weight: 600;
}

.ui-recharge-discount__coin {
  width: 3.18rem;
  height: 0.37rem;
  line-height: 0.37rem;
  right: 0.78rem;
  top: 11.83rem;
  text-align: center;
  white-space: nowrap;
  font-size: 0.2rem;
  color: #a94f33;
  font-weight: 600;
}

.ui-recharge-discount__unlocked {
  right: 0.33rem;
  top: 12.33rem;
}

.ui-recharge-discount__to-pay {
  bottom: 0.3rem;
}
</style>
