<template>
  <input id="input" v-model="value" v-bind="inputStyle" :maxlength="maxlength" :type="type" :placeholder="placeholder" class="input" @input="forbidInput" :inputmode="isA ? 'numeric' : ''" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },

    placeholder: {
      type: String,
      default: ''
    },

    maxlength: {
      type: String,
      default: '5'
    },

    inputStyle: {
      type: Object,
      default: () => {}
    },

    stylePlaceholder: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    isA() {
      return this.$route.query.region === 'A'
    }
  },

  data() {
    return {
      value: ''
    }
  },

  mounted() {
    this.initInput()
  },

  methods: {
    initInput() {
      const dom = document.getElementById('input')
      Object.keys(this.inputStyle).forEach((key) => {
        let value = this.inputStyle[key]
        dom.style[key] = value
      })

      let content = Object.keys(this.stylePlaceholder)?.reduce((acc, key) => {
        let value = this.stylePlaceholder[key]
        acc += `${key}:${value};`
        return acc
      }, '')

      console.log(`.input::placeholder {${content}}`, 'contentcontent')
      document.styleSheets[0].insertRule(`.input::placeholder {${content}}`, 0)
    },

    forbidInput(e) {
      let value = e.target.value
      let regStr =
        // eslint-disable-next-line no-misleading-character-class, no-useless-escape
        /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi
      if (value.match(regStr)) {
        e.target.value = e.target.value.replace(regStr, '')
        this.value = this.value.replace(regStr, '')
        return
      }

      this.$emit('getValue', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
}
</style>
