import isFunction from 'lodash/isFunction'
import localStorage from '@/utils/local-storage'
import { pointReport } from '@sub/utils/page-point-report'
import { methodsInit } from '../../utils'
import { amConfig } from '../config'
import { IS_WEPLAY } from '@/utils/env'

const {
  lib: { dayjs, utc },
  modules: {
    storage: { request }
  }
} = amConfig

const { getState: getBaseState } = methodsInit('base')
const { getGetters: getStorageGetters } = methodsInit('storage')

export default {
  namespaced: true,

  state: {
    localStorage: {},
    sessionStorage: {}, // 每次启动活动值重置
    todayLocalStorage: {}, // 每日缓存
    utcOffset: utc || '+08:00',
    extra: {
      // 自定义storageKey
      storageKey: ''
    },

    // 音效控制config
    audioControl: {
      // 音效控制uiMeta（仅活动后台运行时使用）
      uiMeta: null,
      // 音效控制方法
      audioPlay: () => {},
      audioPause: () => {},
      allAudioPause: () => {},
      audioLoad: () => {}
    },

    _initBuyConfirmReported: false
  },

  getters: {
    storageKey({ extra: { storageKey } }) {
      if (storageKey) {
        return storageKey
      } else {
        const { amBasic } = getBaseState() || {}
        return `vuex-${window.location.pathname}-uid=${amBasic.user.uid}`
      }
    },

    // 每日缓存的key
    todayStorageKey({ utcOffset }) {
      const { amBasic } = getBaseState() || {}
      const today = dayjs().utcOffset(utcOffset).format('YYYYMMDD')
      return `vuex-${window.location.pathname}-uid=${amBasic.user.uid}-day=${today}`
    },

    sessionStorageKey({ extra: { storageKey } }) {
      if (storageKey) {
        return storageKey
      } else {
        const { amBasic } = getBaseState() || {}
        return `vuex-${window.location.pathname}-uid=${amBasic.user.uid}-type=session`
      }
    }
  },

  mutations: {
    setState(state, payload) {
      Object.assign(state, payload)
    },
    // 初始化传一些默认值，非必调
    initDefault(state, { storageKey = '' }) {
      state.extra = {
        storageKey
      }
    },
    // 统一走这个方法更新localStorage的值
    update(state, payload = {}) {
      state.localStorage = { ...state.localStorage, ...payload }

      const storageKey = getStorageGetters('storageKey')
      localStorage.set(storageKey, state.localStorage)
    },

    setAudioControlMethods(state, payload) {
      state.audioControl = {
        ...state.audioControl,
        ...payload
      }
    },

    // 更新每日缓存
    updateToday(state, payload = {}) {
      state.todayLocalStorage = { ...state.todayLocalStorage, ...payload }

      const todayStorageKey = getStorageGetters('todayStorageKey')
      localStorage.set(todayStorageKey, state.todayLocalStorage)
    },

    updateSession(state, payload = {}) {
      state.sessionStorage = { ...state.sessionStorage, ...payload }

      const sessionStorageKey = getStorageGetters('sessionStorageKey')
      sessionStorage.setItem(sessionStorageKey, state.sessionStorage)
    }
  },

  actions: {
    // 初始化localStorage的值
    init({ getters, commit, dispatch, rootState }, payload = {}) {
      const localStorageVal = localStorage.get(getters.storageKey) || {}
      commit('update', { ...payload, ...localStorageVal })

      // 会玩和avatar活动购买确认重置
      const { avatar } = rootState.base.extra || {}
      if ((avatar || !IS_WEPLAY) && Object.keys(payload).some((key) => ['noBuyConfirm', 'restoreBuyConfirm'].includes(key))) {
        commit('update', { noBuyConfirm: false, restoreBuyConfirm: true })
        dispatch('doDefaultPoint', 'initBuyConfirm')
      }
    },

    initSession({ state, getters, commit, dispatch }, payload = {}) {
      const sessionStorageVal = sessionStorage.getItem(getters.sessionStorageKey) || {}
      commit('updateSession', { ...payload, ...sessionStorageVal })

      if (state.sessionStorage.restoreBuyConfirm) {
        dispatch('doDefaultPoint', 'initBuyConfirm')
      }
    },

    initToday({ getters, commit }, payload = {}) {
      const localStorageVal = localStorage.get(getters.todayStorageKey) || {}
      commit('updateToday', { ...payload, ...localStorageVal })
    },

    // utils
    // 切换localStorage中某个key的value
    toggle({ state, commit, dispatch }, key) {
      commit('update', { [key]: !state.localStorage[key] })
      dispatch('doDefaultPoint', key)
    },

    toggleSession({ state, commit, dispatch }, key) {
      commit('updateSession', { [key]: !state.sessionStorage[key] })
      dispatch('doDefaultPoint', key)
    },

    toggleToday({ state, commit }, key) {
      commit('updateToday', { [key]: !state.todayLocalStorage[key] })
    },

    doDefaultPoint({ state, dispatch, commit }, key) {
      const {
        localStorage: { noAnime, restoreBuyConfirm, noBuyConfirm }
      } = state
      const pointMap = {
        // 跳过动画 => noAnime
        noAnime: () => pointReport('ClickH5', { btn_name: noAnime ? '勾选跳过动画' : '取消跳过动画' }),
        // 抽奖勾选提示弹窗 => restoreBuyConfirm
        restoreBuyConfirm: () => {
          pointReport('ClickH5', { btn_name: '勾选提示弹窗', status: restoreBuyConfirm ? 1 : 0 })
          dispatch('setAutoPurchase', { auto_purchase: !restoreBuyConfirm })
        },
        // 购买碎片勾选提示弹窗 => noBuyConfirm
        noBuyConfirm: () => {
          pointReport('ClickH5', { btn_name: '勾选提示弹窗', status: noBuyConfirm ? 0 : 1 })
          dispatch('setAutoPurchase', { auto_purchase: noBuyConfirm })
        },
        // 初始化埋点，业务开发无需关注
        initBuyConfirm: () => {
          if (state._initBuyConfirmReported) return
          else commit('setState', { _initBuyConfirmReported: true })

          pointReport('ClickH5', { btn_name: '勾选提示弹窗', status: 2 })
          dispatch('setAutoPurchase', { auto_purchase: false, scene: 'init' })
        }
      }

      if (isFunction(pointMap[key])) {
        pointMap[key]()
      }
    },

    // 告诉服务端是否能自动购买
    setAutoPurchase({ rootState }, { auto_purchase, scene }) {
      const { avatar } = rootState.base.extra || {}
      if (!avatar && IS_WEPLAY) return

      request.setAutoPurchase({ auto_purchase, scene })
    }
  }
}
