export default [
  {
    path: '/activity/v2/long/internetCelebrity-u/index.html',
    component: () => import('./index.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
        to.meta.expire = to.meta.json.expire
        to.meta.id = to.meta.json.id
      },
      pageCenter: 'pj6m93Vb' // TODO
    }
  }
]
