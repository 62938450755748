<template lang="pug">
a-position.ui-team-info-2-members(v-bind='{ ...compStyle.teamInfoWrapStyle, ...$attrs }')
  //- 队友列表
  slot(v-if='$scopedSlots["teamMembers"]', name='teamMembers', v-bind='{ data: myTeamMembers }')
  a-section.ui-team-info-2-members__wrap.fx-row.fx-m-between(v-else, v-bind='compStyle.teamMateWrapStyle')
    a-avatar(v-for='(item, index) in myTeamMembers', :key='index', :bg-i='item.head_img_url', :uid='item.uid', v-bind='{ ...compStyle.avatarStyle, b: item.uid ? compStyle.avatarStyle.b : "" }')
      slot(name='teamMate', v-bind='{ data: item }')
      a-position.ui-team-info-2-members__avatar(v-if='item.uid && image.avatarBorder', :bg-i='image.avatarBorder', pc, bg-s='100%')
      a-position.ui-team-info-2-members__search(v-if='!item.uid', wh='100%', @a-tap='openSearch', pc)
      a-position.ui-team-info-2-members__name.txt-no-wrap(w='100%', v-bind='{ pc: "x", ...compStyle.nicknameStyle }') {{ $ts(item.nickname, nicknameMaxSize) }}

  //- 队名
  slot(v-if='$scopedSlots["teamName"]', name='teamName', v-bind='{ data: teamName }')
  a-position.ui-team-info-2-members__team(v-else, v-bind='compStyle.teamNameBgWrapStyle', :bg-i='image.teamNameBg', @a-tap='openEdit')
    a-position.fx-row.fx-c-center.fx-m-center.fx-cl-center(v-bind='compStyle.teamNameWrapStyle')
      a-section.ui-team-info-2-members-position(v-if='editIconPosition === "left"', :bg-i='image.editIcon', bg-s='100%')
      a-section.ui-team-info-2-members__name.txt-no-wrap(ml='.05', mr='.05') {{ teamName }}
      a-section.ui-team-info-2-members__icon(v-if='editIconPosition === "right"', :bg-i='image.editIcon', bg-s='100%')

  //- 总榜数值
  slot(name='totalValue', v-bind='{ data: totalValue }')
  slot
</template>

<script>
import { buildApi } from '@sub/utils/request'
import get from 'lodash/get'

const getRankInfo = buildApi('/activity_v2/rank/rank_info')

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      totalValue: 0
    }
  },

  mounted() {
    this.$store.commit('team/setMethods', {
      updateTotalValue: this.getTotalValue.bind(this)
    })

    this.$team.setTeamInviteIcon(this.image.inviteFriends)
    if (this.needRankValue) {
      this.getTotalValue()
    }
  },

  computed: {
    myTeamMembers() {
      return this.$team.teamInfoMembers()
    },

    teamName() {
      return this.$team.teamName()
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    rankConfig({ uiMeta }) {
      return get(uiMeta, 'props.rankConfig', {})
    },

    needRankValue({ uiMeta }) {
      return get(uiMeta, 'props.needRankValue', false)
    },

    nicknameMaxSize({ uiMeta }) {
      return get(uiMeta, 'props.nicknameMaxSize', 0)
    },

    editIconPosition({ uiMeta }) {
      return get(uiMeta, 'props.editIconPosition', 'left')
    },

    compStyle({ uiMeta }) {
      return get(uiMeta, 'props.style', {})
    }
  },

  methods: {
    async getTotalValue() {
      const { info } = await getRankInfo(this.rankConfig)
      this.totalValue = info.score
    },

    openEdit() {
      this.$team.openEdit()()
    },

    openSearch() {
      this.$team.openSearchList()()
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-border {
  border: 0.04rem solid #ffd1f2;
}
</style>
