export default {
  path: '/runtime/v3/:region/:actId/index.html',
  component: () => import('./core/page/main.vue'),
  meta: {
    setMeta(to) {
      to.meta.id = to.meta.params.id
      to.meta.title = to.meta.params.title
    }
  }
}
