<template lang="pug">
a-section.txt-c.fx-row.ui-grid-wrapper(v-bind='attrs', :class='{ [`scroll-${getProp("scrollDirection")}`]: true, "fx-wrap": getProp("scrollDirection") !== "x" }', :h='getProp("wrapperH") + 2 * getProp("wrapperPadding")', :w='wrapW', :pd='getProp("wrapperPadding")')
  slot
  a-section.fx-shrink(:wh='getProp("itemWh")', v-for='(item, index) in getProp("totalNum")', :key='index', v-bind='calculateMargin(index)')
    slot(name='item', v-bind='{ index }')
</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    // 行边距(单位rem)
    rowMargin: {
      type: [Number, String],
      default: 0
    },
    // 列边距(单位rem)
    colMargin: {
      type: [Number, String],
      default: 0
    },
    // 栅格总个数
    totalNum: {
      type: [Number, String],
      default: 0
    },
    // 行个数
    rowNum: {
      type: [Number, String],
      default: 0
    },
    // 外框padding
    wrapperPadding: {
      type: [Number, String],
      default: 0
    },
    // 外框宽
    wrapperW: {
      type: [Number, String],
      default: 0
    },
    // 外框高
    wrapperH: {
      type: [Number, String],
      default: 0
    },
    // item宽高
    itemWh: {
      type: String,
      default: ''
    },
    // 滑动方向 x（横行）、y（纵向）、none（不滑动）
    scrollDirection: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  computed: {
    gridProps({ uiMeta }) {
      return get(uiMeta, 'props', {})
    },

    rowCount() {
      return Math.min(this.getProp('rowNum'), this.getProp('totalNum'))
    },

    wrapW() {
      const itemW = this.getProp('itemWh').split(' ')[0] || 0
      const rowNum = this.rowCount
      return this.getProp('scrollDirection') === 'x' ? this.getProp('wrapperW') : itemW * rowNum + (rowNum - 1) * this.getProp('rowMargin') + 2 * this.getProp('wrapperPadding')
    }
  },

  methods: {
    getProp(key) {
      return this[key] || this.gridProps[key]
    },

    calculateMargin(index) {
      const m = this._isA ? 'ml' : 'mr'
      return {
        mb: this.getProp('colMargin'),
        [m]: !((index + 1) % this.rowCount) ? 0 : this.getProp('rowMargin')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
