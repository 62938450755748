// 三种状态的映射表，跟page-center切图命名对应
// 0: 未达到 1: 可领取 2: 已领取
const defaultTypeMap = {
  0: 'not-reached',
  1: 'get',
  2: 'geted'
}

// 各种默认配置
const defaultConfig = {
  // box
  // 每个节点icon定位，相对于每个box
  iconPosition: { pc: 'x', pb: '-.45' },
  // 每个节点val_text(例如:3000金币)的font-size
  val_fs: 0.2,
  // 每个节点val_text(例如:3000金币)的位置，相对于每个box
  textPosition: { pc: 'x', pb: '-.7' },

  // list
  // 每个奖励块大小
  default_wh: 0.89,
  // 每个奖励块背景
  reward_bg: { 'bg-c': '#fff', bdr: 0.17 },
  // 使用的奖励名称字段
  reward_name_key: 'name',
  // 每个奖励块名称定位
  name_ps: { pc: 'x', pt: '0.95' },
  // 每个奖励块默认数量icon的font
  icon_font: '.22 #fff 600',
  // 每个奖励块默认数量icon的定位
  icon_ps: { pr: '-.05', pb: '0' },
  // 每个奖励块默认数量icon的定位(阿服)
  a_icon_ps: { pl: '-.05', pb: '0' }
}

// 每一层盒子(box)对应的pt
const ptConfig = {
  1: '-1.4',
  2: '0.92',
  3: '3.1'
}

// bg_config的默认pt
const pt = '.09'

// 奖励展示配置
const exhibitRewardList = [
  {
    box: {
      // 每个盒子对应的序号，一般按照盒子的大小1，2，3...命名
      bg_num: 1,
      // 每个盒子相对于整个外层盒子的定位，一般外层盒子的宽度设置为750
      domPosition: { pl: '.7', pt: ptConfig[1] }
    },
    // 奖励列表
    list: [
      {
        // 每个奖励对应的背景配置，一般主要使用(wh, bg-c, bdr, 定位)
        bg_config: { pc: 'x', pt },
        // 每个奖励对应的配置，一般主要使用(wh, id_idx 定位)
        // id_idx跟giftConfig一一对应
        reward: { id_idx: 1 }
      }
    ]
  },
  {
    box: {
      bg_num: 1,
      domPosition: { pl: '2.25', pt: ptConfig[1] }
    },
    list: [{ bg_config: { pc: 'x', pt }, reward: { id_idx: 2 } }]
  },
  {
    box: {
      bg_num: 2,
      domPosition: { pr: '.7', pt: ptConfig[1] }
    },
    list: [
      { bg_config: { pl: '.42', pt }, reward: { id_idx: 3 } },
      { bg_config: { pr: '.42', pt }, reward: { id_idx: 4 } }
    ]
  },
  {
    box: {
      bg_num: 2,
      domPosition: { pr: '.75', pt: ptConfig[2] }
    },
    list: [
      {
        bg_config: { wh: 0.66, pl: '.54', pt: 0.21, 'bg-c': '#F8F9FF', bdr: '50%' },
        reward: { id_idx: 5, wh: 1.5, pc: 'pc' },
        // 同defaultConfig里面的icon_ps
        icon_ps: { pr: '-.3', pb: '-.1' },
        // 同defaultConfig里面的name_ps
        name_ps: { pc: 'x', pt: '.83' }
      },
      { bg_config: { pr: '.42', pt }, reward: { id_idx: 6 } }
    ]
  },
  {
    box: {
      bg_num: 2,
      domPosition: { pl: '.65', pt: ptConfig[2] }
    },
    list: [
      {
        bg_config: { wh: 0.66, pl: '.54', pt: 0.21, 'bg-c': '#F8F9FF', bdr: '50%' },
        reward: { id_idx: 5, wh: 1, pc: 'x', pt: -0.3 },
        icon_ps: { pr: '-.3', pb: '-.2' },
        name_ps: { pc: 'x', pt: '.83' }
      },
      {
        bg_config: { wh: 0.66, pl: '.54', pt: 0.21, 'bg-c': '', bdr: '' },
        reward: { id_idx: 7, wh: 1, pc: 'x', pt: 0 }
      },
      { bg_config: { pr: '.42', pt }, reward: { id_idx: 8 } }
    ]
  },
  {
    box: {
      bg_num: 3,
      domPosition: { pl: '.85', pt: ptConfig[3] },
      // 同defaultConfig里面的textPosition
      textPosition: { pl: '3.2', pb: '-.36' }
    },
    list: [
      {
        bg_config: { 'bg-c': '', pl: '.7', pt: '.14' },
        reward: { id_idx: 9 },
        // 或标签的text
        orText: 'or',
        // 或标签的配置
        // orConfig使用a-section渲染
        // orConfig2使用a-border-text1渲染
        orConfig2: { f: '.16 #fff 400', borderWidth: 2, borderColor: 'green', pl: 1.02, pc: 'y' },
        name_ps: { pl: '.2', pt: '.95' }
      },
      { bg_config: { 'bg-c': '', pl: '2', pt: '.14' }, reward: { id_idx: 10 } },
      { bg_config: { pr: '.85', pt: '.14' }, reward: { id_idx: 11 } }
    ]
  }
]

export default {
  defaultTypeMap,
  defaultConfig,
  exhibitRewardList
}
