<template lang="pug">
a-section.ui-topic-list-banner-topic-footer(w='4.74', ml='0.3')
  a-section.topic-footer__wrap(v-if='imgLength > 1', :f='`.24 ${bottomTextColor} 500`', pr='0', pt='0', lh='0.36') {{ lookMoreImgText }}
  a-section.topic-footer__operate.operate.fx-row(v-if='props.showLike || props.showComment', w='100%', h='0.36', f='.19 #AAABB3 400', mb='.1')
    a-section.like_count.fx-row.fx-c-center(v-if='props.showLike', h='100%', mr='.54')
      a-img.topic-footer__like(:src='commonImage.likeIcon', wh='.36', mr='0.05')
      | {{ handleNum(data.like_count) }}
    a-section.topic-footer__comment-count.comment_count.fx-row.fx-c-center(v-if='props.showComment', h='100%')
      a-img(:src='commonImage.commentIcon', wh='.36', mr='0.05')
      | {{ handleNum(data.comment_count) }}

  a-section.topic-footer__comment.comment.ellipsis(v-if='props.showCommentContent && data.first_comment.comment_id', w='100%', h='.31', lh='.31', mb='.12')
    span(f='.22 #576B95 500') {{ data.first_comment.nickname }}:
    span(f='.22 #1B1D38 400') {{ data.first_comment.content }}

  a-section.topic-footer__see-more.see-more(v-if='props.showCommentContent && data.comment_count > 1', f='.2 #AAABB3 400', lh='.28') {{ seeMoreText }} >
</template>

<script>
import { commonImage, seeMoreText, handleNum } from '../utils/config'
import { PAGE_REGION } from '@/utils/env'

export default {
  components: {},

  data() {
    return {
      commonImage,
      seeMoreText
    }
  },

  props: {
    item: {
      type: Object,
      default: null
    },

    data: {
      type: Object,
      default: () => {}
    },

    bottomTextColor: {
      type: String,
      default: ''
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    props({ uiMeta }) {
      return uiMeta.props
    },

    imgLength() {
      return this.item.dynamic.images.length
    },

    lookMoreImgText({ imgLength }) {
      let text = ''
      switch (PAGE_REGION) {
        case 'HW':
          text = `查看全部${imgLength}图`
          break
        case 'J':
          text = `全部の画像を表示`
          break
        case 'K':
          text = `전체 사진 ${imgLength}장 보기`
          break
      }
      return text
    }
  },

  created() {},

  methods: {
    handleNum
  }
}
</script>

<style lang="scss" scoped>
.operate {
}

.comment {
}
.see-more {
}
</style>
