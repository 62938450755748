export default [
  {
    path: '/activity-20221229-main',
    component: () => import('./main.vue'),
    meta: { id: 10002, expire: '2100/01/05 23:59:59 UTC+8', title: '元旦賀卡', ignore: true }
  },
  {
    path: '/activity-20221229-main2',
    component: () => import('./main2.vue'),
    meta: { expire: '2100/01/05 23:59:59 UTC+8', title: '', ignore: true }
  }
]
