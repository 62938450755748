<template lang="pug">
  a-section.ui-animation(v-bind='$attrs', @a-tap='$emit("a-tap")')
    template(v-if='effectInfo.isEffectStateReadying')
      slot(v-if='isOpenEffect', name='animation')
      slot(v-else, name='normal')
</template>

<script>
export default {
  props: {
    isOpenEffectProp: {
      type: Boolean,
      default: undefined
    },

    // svga和pag pag要额外传
    type: {
      type: String,
      default: 'svga'
    }
  },

  computed: {
    isOpenEffect() {
      return this.type === 'svga' ? (this.isOpenEffectProp !== undefined ? this.isOpenEffectProp : this.$base.isOpenEffect()) : this.$base.isOpenEffectPAG()
    },

    effectInfo() {
      return this.$base.effectInfo()
    }
  }
}
</script>

<style lang="scss" scoped></style>
