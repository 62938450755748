<template lang="pug">
a-dialog.ws-nowrap(name='uiVoteViewWorkDialog', :bg-i='useImages.bg')
  template(slot-scope='{ data }', v-if='data')
    a-position(pc='x', pt='1.55', :style='useStyle.workName') {{ data.video_name }}
    a-position(pc='x', pt='2.12', :style='useStyle.userName') {{ data.nickname }}

    //- 作品
    a-position(pc='x', pb='0.74', v-bind='videoStyle')
      a-position(pc, :bg-i='data.video_cover_url', v-bind='videoStyle')
      a-position(pc, bg-c='rgba(0,0,0,0.4)', v-bind='videoStyle')
      a-button1(pc, :bg-i='images.playBtn', @a-tap='play(data)')
</template>

<script>
import { genUsePropsMixin } from '../mixins'
import { VIEW_WORK_DIALOG } from '../const'

const videoStyle = {
  w: '5.5',
  h: '3.08',
  bdr: '0.18'
}

export default {
  mixins: [genUsePropsMixin(VIEW_WORK_DIALOG)],

  data() {
    return {
      videoStyle
    }
  },

  computed: {},

  created() {},

  methods: {
    appendTiktokScript() {
      var newScript = document.createElement('script')
      newScript.async = true
      newScript.src = 'https://www.tiktok.com/embed.js'
      newScript.classList.add('tiktok-script')
      document.body.appendChild(newScript)
    },

    play(data) {
      if (data.platform === 2) {
        this.appendTiktokScript()
      }
      this.$dialogs.uiVotePlayVideo.open({
        data,
        options: {
          bgClose: true
        }
      })
    }
  }
}
</script>

<style></style>
