<template lang="pug">
a-position.ui-tab-change.fx-row.fx-c-center(v-bind='{ wh: getProp("tabWh"), ...$attrs }')
  //- tab切图展示
  a-position.ui-tab-change__img(v-show='activeTabIdx === index', v-for='(item, index) in tabProps.tabImgList', :key='"tab" + index', :bg-i='item', bg-s='100%', pc)
  a-section.ui-tab-change__item(:w='`${100 / tabProps.tabImgList.length}%`', h='100%', v-for='(item, index) in tabProps.tabImgList', :key='"tap" + index', @a-tap='changeTab(index)')
  slot
</template>

<script>
import get from 'lodash/get'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },
    // 是否禁止tab点击
    disable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    key({ uiMeta }) {
      return get(uiMeta, 'key', '')
    },

    tabProps({ uiMeta }) {
      return get(uiMeta, 'props', {})
    }
  },

  data() {
    return {
      activeTabIdx: 0
    }
  },

  created() {
    this.$uiComp.setUiCompConfig({
      compName: 'uiTabChange',
      compKey: this.key,
      value: {
        tabChange: this.changeTab.bind(this)
      }
    })
  },

  methods: {
    getProp(key) {
      return this[key] || this.tabProps[key]
    },

    changeTab(tabIndex) {
      if (this.disable) {
        const { disableToast } = this.tabProps
        return disableToast && this.$toast(this.$tf(disableToast))
      }

      if (this.activeTabIdx === tabIndex) {
        return
      }

      this.activeTabIdx = tabIndex
      this.$emit('tabChange', this.activeTabIdx)
    }
  }
}
</script>

<style lang="scss" scoped></style>
