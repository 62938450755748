import Vue from 'vue'
/**
 * @module point
 */
/**
 * @description 添加埋点
 * @param { string } type 埋点类型
 * @param { object } [extra] 埋点额外字段
 */
export function pointReport(type, extra) {
  Vue.prototype.$app.$point.track(type, {
    url: `${window.location.origin}${window.location.pathname}`,
    url_path: window.location.pathname,
    act_id: Vue.prototype.$app.$amApiParam().act_id,
    ...extra
  })
}

export default {
  $pointReport: pointReport
}
