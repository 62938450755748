export default [
  {
    path: '/activity/v2/20230921/family-war-rank-c/index.html',
    component: () => import('./rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: 'dQzTCwmS'
    }
  },
  {
    path: '/activity/v2/20230921/family-war-reward-c/index.html',
    component: () => import('./reward.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: 'CZbzAEvv'
    }
  },
  {
    path: '/activity/v2/20230921/family-war-main-c/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1184,
      pageCenter: 'RFO6MbsT'
    }
  }
]
