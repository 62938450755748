import { isA } from '@sub/ui-components/utils/config'

const prl = isA ? 'pr' : 'pl'
const plr = isA ? 'pl' : 'pr'

const commonBtnStyleLeft = { pb: 0.74, pl: 0.69, 'bg-s': '100%' }
const commonBtnStyleRight = { pb: 0.74, pr: 0.69, 'bg-s': '100%' }

export const exchangeConfirmStyleConfig = {
  reward: {
    wrap: { wh: 1.9, pc: 'x', pt: 2.59, 'bg-s': '100%' },
    subscript: { pb: 0, [plr]: 0, fs: 0.3, fw: 700 },
    rewardName: { w: 5, fs: 0.2, fw: 500, lh: 0.27, pc: 'x', pt: 2.02 }
  },

  input: {
    inputBg: { 'bg-s': '100%', pt: 4.17, [prl]: isA ? 2.5 : 1.47 },
    reduce: { pc: 'y', pl: 0.04, 'bg-s': '100%', wh: 0.44 },
    add: { pc: 'y', pr: 0.04, 'bg-s': '100%', wh: 0.44 },
    num: { wh: '1.2 .39', pc: 'pc', fs: 0.28, fw: 500, lh: 0.39 }
  },

  max: { pt: 4.19, pl: 4.13, 'bg-s': '100%' },
  permanentExchange: { fs: 0.28, fw: 500, lh: 0.3, w: 5, pt: 4.3, pc: 'x' },
  canExchangeNum: { fs: 0.26, fw: 700, lh: 0.35, w: 5, pt: 1.84, pc: 'x' },
  totalExchangeNum: { fs: 0.24, fw: 500, lh: 0.35, w: 5, pt: 6.17, pc: 'x' },
  cancelBtn: commonBtnStyleLeft,
  confirmBtn: commonBtnStyleRight
}

export const confirmStyleConfig = {
  textWrap: { wh: '5.25 .39', pc: 'x', pt: 1.28, fs: 0.28, fw: 500, lh: 0.38 },
  tipWrap: { wh: '5 .36', pc: 'x', pt: 2.23, fs: 0.22, fw: 400 },
  cancelButton: commonBtnStyleLeft,
  confirmButton: commonBtnStyleRight
}

export const textConfig = (color) => ({
  HW: {
    maxText: '可兑换数量：{{}}',
    overToast: '不能再多了哦~',
    leastToast: '最少为1哦~'
  },
  C: {
    maxText: '可兌換數量：{{}}',
    overToast: '',
    leastToast: ''
  },
  J: {
    maxText: '交換できる数：{{}}',
    overToast: '',
    leastToast: ''
  },
  K: {
    maxText: '교환 가능 수량：{{}}',
    overToast: '증가 불가!',
    leastToast: '최소 1!'
  },
  A: {
    maxText: `يمكن استبداله: <span style="color: ${color}">{{}}</span>`,
    overToast: 'لا يمكن أكثر من ذلك~',
    leastToast: 'على الأقل واحدة~',
    totalExpensesText: `سيتم استهلاك <span style="color: ${color}">{{}}</span> كوبون استبدال `
  },
  M: {
    maxText: 'Boleh tukar: {{}}',
    overToast: 'Tidak boleh tambah lagi',
    leastToast: 'Sekurang-kurangnya 1~ '
  },
  id: {
    maxText: 'Dapat ditukar: {{}}',
    overToast: 'Tidak dapat menambahkan lebih banyak',
    leastToast: 'Setidaknya 1~'
  },
  ms: {
    maxText: 'Boleh tukar: {{}}',
    overToast: 'Tidak boleh tambah lagi',
    leastToast: 'Sekurang-kurangnya 1~ '
  },
  T: {
    maxText: 'จำนวนที่แลกได้：{{}}',
    overToast: 'มากกว่านี้ไม่ได้แล้ว~',
    leastToast: 'น้อยสุดที่ 1 ชิ้นนะ~'
  },

  V: {
    maxText: 'Số lượng có thể đổi：{{}}',
    overToast: 'Không thể thêm nữa đâu~',
    leastToast: 'Tối thiểu là 1 nha~'
  },
  P: {
    maxText: 'Convertible quantity：{{}}',
    overToast: "Can't be more~",
    leastToast: 'At least one~'
  },
  U: {
    maxText: 'Convertible quantity：{{}}',
    overToast: "Can't be more~",
    leastToast: 'At least one~'
  },
  Q: {
    maxText: 'Geriye kalan ：{{}}',
    overToast: 'Limitinize ulaştı~',
    leastToast: 'En az 1~',
    totalExpensesText: `<span style="color: ${color}"></span>{{}} onur parası harcanacak.`
  },
  R: {
    maxText: 'Можно обменять: {{}}',
    overToast: 'Нельзя больше~',
    leastToast: 'Не менее 1~'
  },
  B: {
    maxText: 'Disponível para troca: {{}}',
    overToast: 'Excedeu o máximo',
    leastToast: ' O mínimo é 1~'
  },
  I: {
    maxText: 'इक्स्चेंज मात्रा: {{}}',
    overToast: 'और नहीं बढ़ा सकते ~',
    leastToast: 'कम से कम 1~'
  }
})
// export const genExchangeTextConfig = multiLanguage.getMultiTextConfig(textConfig)
