<template lang="pug">
a-section.ui-task-group.fx-col(v-bind='specialStyle.containerStyle')
  a-section.ui-task-group__title(v-bind='uiTitle', :bg-i='titleImage')
  a-section.ui-task-group__list(v-for='(item, index) in curDailyTaskListSort', :key='"taskList" + index', v-bind='{ ...uiTask.taskItemContainer, ...taskStyle.taskItemContainer }')
    a-section.ui-task-group__list-task(:bg-i='taskImageMap[item.id]')
      a-section.ui-task-group__list-task-prop(v-bind='{ ...uiTask.propPosition, ...taskStyle.propPosition }', bg-s='contain', @a-tap='$propPreview(getPropId(item))')

    ui-daily-task-repeat(v-bind='{ ...uiTask.dailyTaskButton, ...taskStyle.dailyTaskButton }', :uiMeta='genDailyTaskUiMeta(item.id)', :dailyTaskEvent='dailyTaskEvents[item.id]', @close-task='$emit("close-task")')
      a-position.red-dot.ui-task-group-wrap-red-dot(v-if='item.state === 1', v-bind='{ ...uiTask.receiveRedDot, ...taskStyle.receiveRedDot }')
</template>

<script>
// 文档： https://wepie.feishu.cn/wiki/GG2ewW6yGiyqNAkiQFTcCCdDndd

import get from 'lodash/get'
import { groupStyleConfig, defaultSpecialStyle, defaultTextColor } from './utils/config'
import event from '@sub/event'
export default {
  data() {
    return {
      ...groupStyleConfig
    }
  },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    dailyTaskEvents: {
      type: Object,
      default: () => {
        return {
          1: {
            completeTap: null,
            receiveTap: null
          }
        }
      }
    }
  },

  computed: {
    dailyTaskListSort() {
      return this.$store.getters['task/dailyTaskListSort']
    },

    curDailyTaskListSort({ dailyTaskListSort, taskList }) {
      return dailyTaskListSort.filter((task) => taskList.find((i) => i.id == task.id))
    },

    taskList({ uiMeta }) {
      return get(uiMeta, 'props.taskList', [])
    },

    titleImage({ uiMeta }) {
      return get(uiMeta, 'props.titleImage', '')
    },

    // 特化样式
    specialStyle({ uiMeta }) {
      const specialStyle = get(uiMeta, 'props.specialStyle', {})
      return { ...defaultSpecialStyle, ...specialStyle }
    },

    taskStyle({ specialStyle }) {
      return specialStyle.task
    },

    taskImageMap() {
      return this.taskList.reduce((total, cur) => {
        total[cur.id] = cur?.image || `buyTask/task${cur.id}`
        return total
      }, {})
    },

    buttonImage({ uiMeta }) {
      return get(uiMeta, 'props.buttonImage', {})
    },

    textColor({ uiMeta }) {
      const textColor = get(uiMeta, 'props.textColor', {})
      return { ...defaultTextColor, ...textColor }
    }
  },

  afterInit() {
    this.initDailyTask()
  },

  watch: {
    curDailyTaskListSort() {
      if (this.uiMeta.key) {
        this.$task.setTaskReddots({ [this.uiMeta.key]: Object.values(this.curDailyTaskListSort).some((item) => item.state === 1) })
      }
      this.$emit('task-change')
    }
  },
  onPageShow() {
    this.onShow()
  },

  mounted() {
    event.onChipChange(this.updateCoinChip.bind(this))
  },

  methods: {
    onShow() {
      this.updateCoinChip()
      this.$store.dispatch('task/getTasksGroupList')
    },
    updateCoinChip() {
      this.$store.dispatch('base/getUserCoin')
      this.$store.dispatch('base/getChipInfo')
    },
    initDailyTask() {
      this.taskList.forEach((i) => {
        this.$store.commit('task/initDailyTaskItemDefault', i.id)
      })
      this.$store.dispatch('task/getTasksGroupList')
    },

    genDailyTaskUiMeta(taskId) {
      const task = this.taskList.find((i) => i.id === taskId)
      const { toFinish, receive, completed } = this.buttonImage
      const { dailyTask } = this.textColor
      return {
        props: {
          taskId,
          buttonImage: {
            toFinish,
            receive,
            completed
          },
          hideText: this.taskStyle.hideText,
          textStyle: {
            color: dailyTask
          }
        },
        events: task.events
      }
    },

    getPropId(item) {
      const r_id = item?.stages[0]?.reward_config?.rewards[0]?.reward_id || ''
      return r_id
    }
  }
}
</script>

<style scoped></style>
