<template lang="pug">
  a-position.ui-cumulative(pl="0")
    serpentine.ui-cumulative__serpentine(
      v-bind="$attrs"
      :x="x"
      :y="y"
      :cList="cList"
      :s_l="gearList"
    )
</template>

<script>
import serpentine from './serpentine'
import cumulativeConfig from './cumulativeConfig'

export default {
  components: { serpentine },

  props: {
    // 曲线布局类型
    // 传入String选择已有的曲线类型
    // 传入Array使用新的特殊曲线类型
    cumulativeType: {
      type: [String, Array],
      default: ''
    },

    // 曲线配置
    canvasConfig: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      ...cumulativeConfig
    }
  },

  computed: {
    isAr() {
      return this.$route.query?.region === 'A'
    },

    list() {
      return typeof this.cumulativeType === 'string' ? cumulativeConfig[this.cumulativeType] : this.cumulativeType
    },

    cList() {
      let list = this.list
      if (this.isAr) {
        const reverse_map = {
          lr: 'rl',
          rl: 'lr',
          ald: 'ard',
          ard: 'ald',
          adl: 'adr',
          adr: 'adl',
          ud: 'ud'
        }
        list = list.map((arr) => {
          return arr.map((item) => {
            return {
              ...item,
              d: reverse_map[item.d]
            }
          })
        })
      }
      return list
    },

    newConfig() {
      return this.canvasConfig || this.default_config
    },

    x() {
      if (this.isAr) {
        return this.newConfig.a_x
      } else {
        return this.newConfig.x
      }
    },

    y() {
      return this.newConfig.y
    },

    gearList() {
      const cList2 = this.cList.reduce((pre, cur) => pre.concat(cur), [])

      // 有效的线段
      const efficientList = ['lr', 'rl', 'ud', 'du', 'sud']
      return [0].concat(cList2.filter((item) => efficientList.includes(item.d)).map((i) => i.percent))
    }
  },

  async mounted() {},

  beforeDestroy() {},

  methods: {}
}
</script>

<style lang="scss" scoped></style>
