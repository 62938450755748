<template lang="pug">
a-section.ui-daily-task-repeat-wrapper(v-bind='attrs', :wh='wh')
  ui-button.ui-daily-task-repeat__button0(v-if='!taskInfo.state', :bg-i='buttonImage.toFinish', bg-s='contain', pc, @a-tap='jumpDeepLink')
  ui-button.ui-daily-task-repeat__button1(v-else-if='taskInfo.state === 1', :throttleTime='3000', :bg-i='buttonImage.receive', bg-s='contain', pc, @a-tap='recvReward')
  a-position.ui-daily-task-repeat__button2(v-else, :bg-i='buttonImage.completed', bg-s='contain', pc)
  a-position.txt-no-wrap.ui-daily-task-repeat__hideText(v-if='!uiMeta.props.hideText', h='.38', pc='x', pt='100%', mt='.03', :f='`${recvFontSize[_region] || 0.2} ${textStyle.color} 500`', v-html='$text(recvText || textConfig.recvText, { recvTimes: taskInfo.process, totalTimes: taskInfo.max })')
  slot(:data='taskInfo')
</template>

<script>
import qs from 'qs'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import event from '@sub/event'
import { genDailyTaskTextConfig } from './utils/config'
import { shareMixin } from '@/utils/share-mixins'
import { IS_IOS, IS_DEV } from '@/utils/env'
import { standardTapAction } from '@sub/ui-runtime/core/utils/components/button'
import { getJumpNewUrl } from '@sub/utils/page-jump-methods'
import { genComponentCurRegionText } from '@sub/utils/page-center-snapshot'
import { get, isEmpty } from 'lodash'
import { formatRewardId } from '@sub/utils/reward'

const recvFontSize = {
  U: 0.18
}

export default {
  mixins: [attrs, shareMixin],

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    },

    context: {
      type: Object,
      default: () => {}
    },

    // 源码开发时，自己传递的事件
    dailyTaskEvent: {
      type: Object,
      default: () => {
        return {
          completeTap: null,
          receiveTap: null
        }
      }
    }
  },

  data() {
    return {
      recvFontSize
    }
  },

  mounted() {
    event.onShow(this.getTaskInfo)
    event.onLotteryComplete(this.getTaskInfo)
    this.init()
  },

  destroyed() {
    event.offOnShow(this.getTaskInfo)
    event.offLotteryComplete(this.getTaskInfo)
  },

  computed: {
    wh() {
      const w = get(this.uiMeta, 'style.width', '1.62')
      const h = get(this.uiMeta, 'style.height', '.66')
      return `${w} ${h}`
    },
    buttonImage({ uiMeta }) {
      return get(uiMeta, 'props.buttonImage', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.textStyle', {})
    },

    taskId({ uiMeta }) {
      return get(uiMeta, 'props.taskId', 0)
    },

    recvText({ uiMeta }) {
      return get(uiMeta, 'props.recvText', '')
    },

    taskInfo({ taskId }) {
      return get(this.$store.state, `task.dailyTasksInfo[${taskId}]`, {
        process: 0,
        max: 1
      })
    },

    textConfig() {
      let textMap = genComponentCurRegionText(this.uiMeta.type, this.context)
      if (!this._isRuntime || isEmpty(textMap)) {
        textMap = genDailyTaskTextConfig(this)()
      }

      return textMap
    },

    isRuntime() {
      return this.$base.extra().isRuntime
    },

    reward() {
      return this.$store.state.reward || {}
    }
  },

  methods: {
    init() {
      this.$store.commit('task/initDailyTaskItemDefault', this.taskId)
      this.getTaskInfo()
    },

    getTaskInfo() {
      this.$store.dispatch('task/getTasksGroupList', this.taskId)
    },

    async recvReward() {
      if (this.taskInfo.state !== 1) {
        return
      }
      const { rewards } = await this.$store.dispatch('task/recvTaskReward', this.taskId)
      this.getTaskInfo()

      // 碎片更新
      event.emitOnChipChange()

      // 源码开发使用时，可以直接调用自己传递的事件
      if (this.dailyTaskEvent.receiveTap) {
        this.dailyTaskEvent.receiveTap(rewards)
        return
      }

      // 兼容源码使用时组件的配置 toast 的情况
      const { type, payload } = get(this.uiMeta.events, 'receiveTap.0', {})
      if (type === 'openPage' && payload.type === 'toast') {
        return this.$toast(this.textConfig.recvSuccess)
      }

      const rewardList = rewards.map((item) => {
        const count = item.reward_amount || item.reward_val
        const rewardMeta = this.reward[item.reward_type]?.[formatRewardId(item)] || {}
        return {
          ...item,
          type: rewardMeta.type,
          img: rewardMeta.img || this.$propsConfig(item.reward_id)?.img,
          unit: this.$getPropUnit(item.reward_id, count, rewardMeta.type),
          reward_name: item.reward_type_name
        }
      })

      standardTapAction(
        this,
        {},
        {
          tap: get(this.uiMeta.events, 'receiveTap', []),
          // 业务弹窗打开传入的参数
          openOptionsMap: {
            'ui-normal-reward-dialog': { data: { rewardList } }
          },
          dialogOpenOptions: { data: { rewardList } }
        }
      )
      return
    },

    // 去完成任务
    jumpDeepLink() {
      if (this.isRuntime) {
        standardTapAction(
          this,
          {},
          {
            tap: get(this.uiMeta.events, 'completeTap', {})
          }
        )
        return
      }

      // 源码开发使用时，可以直接调用自己传递的事件
      if (this.dailyTaskEvent.completeTap) {
        this.dailyTaskEvent.completeTap()
        return
      }

      const completeTap = get(this.uiMeta.events, 'completeTap.0', {})
      const type = get(completeTap, 'payload.type', '')
      const link = get(completeTap, 'payload.link', '')
      const shareData = get(completeTap, 'payload.shareData', '')

      if (type === 'http') {
        this.jumpTypeHttp(link)
        return
      }

      if (type === 'closeTask') {
        this.$emit('close-task')
        return
      }

      if (type === 'shareToCircle') {
        if (IS_IOS && this.$store.getters['base/isInVoiceRoom']) {
          this.$toast(this.textConfig.cantShareDirect)
          return
        }
        this.jumpTypeShareToCircle(shareData)
        return
      }

      if (type === 'goNeverland') {
        this.$jumpNeverland()
        return
      }

      this.$we('jumpDeepLink', link)
    },

    async jumpTypeShareToCircle({ title = '', desc = '', imgUrl = '', topics_dev = [], topics_prod = [], imgBase64 = '', extra = {} }) {
      const shareData = {
        title,
        desc,
        imgUrl,
        imgBase64,
        success: '',
        cancel: '',
        wespyDeeplink: `wespydeeplink://http?url=${encodeURIComponent(`${window.location.origin}${window.location.pathname}?head=0&is_act=1&activity=1&act_id=${this.$amApiParam().act_id}`)}`,
        textConfig: {
          titleColor: '#333',
          descColor: '#666'
        },
        topics: IS_DEV ? topics_dev : topics_prod,
        ...extra
      }

      this.$showLoading()
      await this.sharePageToCircle(shareData)
      this.$hideLoading()
    },

    jumpTypeHttp(link) {
      // deepLink 跳转页面坑比较多，所以使用浏览器原生跳转
      const originUrl = decodeURIComponent(link).split('url=')[1]
      const query = qs.parse(originUrl.split('?')[1])
      const urlWithoutQuery = originUrl.split('?')[0]

      location.href = getJumpNewUrl(urlWithoutQuery, query)
    }
  }
}
</script>

<style lang="scss" scoped></style>
