<template lang="pug">
a-section.topic-item(v-if='item', v-bind='$attrs')
  a-section.fx-row
    a-avatar(:bg-i='item.user.avatar', :frame='item.user.frame', :uid='item.user.uid', wh='0.7')
    a-section(style='padding: 0 0.12rem')
      a-section.fx-row.fx-v-center
        a-section.ellipsis(f='0.24 #fc7077 600') {{ item.user.nickname }}
        a-section(v-if='item.user.vip', :bg-i='getVipIcon(item.user.vip)', wh='.45', style='margin-left: 0.1rem')
      a-section(f='0.18 #999 400', mt='.15') {{ date }}

  //- a-section.fx-row.fx-wrap(w='100%')
  //-   a-section.title(v-for='(t, i) in item.topic.titleList', :key='i', h='.36', lh='.36', f='.22 #3EB83D 600', style='margin: 0.05rem 0') \#{{ t.topic_name }}#

  a-section.three-ellipsis(f='0.24 #5A5A5A 400', style='line-height: 0.3rem; white-space: pre-wrap', wh='4.74 0.9', m='0 auto', mt='.12') {{ item.dynamic.textarea }}

  .topic-image(v-if='imgLength', :style='imageStyle')
</template>

<script>
import dayjs from '@/utils/dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getVipIcon } from '@/utils/utils'
import { lang, image_wh } from '../utils/config'

import 'dayjs/locale/zh-cn'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'

dayjs.extend(relativeTime)

export default {
  props: {
    item: {
      type: Object,
      default: null
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      image_wh
    }
  },

  computed: {
    props({ uiMeta }) {
      return uiMeta.props
    },

    footerData({ item }) {
      return item.footer
    },

    imgLength() {
      return this.item.dynamic.images.length
    },

    imgWhType({ props, footerData }) {
      const isShow1 = props.showLike || props.showComment
      const isShow2 = props.showCommentContent && footerData.comment_count
      const isShow3 = props.showCommentContent && footerData.comment_count > 1

      if (isShow1 && isShow2 && isShow3) {
        return 3
      } else if ((!isShow1 && !isShow2 && !isShow3) || (isShow1 && !isShow2 && !isShow3) || (!isShow1 && isShow2 && !isShow3) || (!isShow1 && !isShow2 && isShow3)) {
        return 1
      } else {
        return 2
      }
    },

    firstImg() {
      if (!this.imgLength) return ''

      const img1 = this.item.dynamic.images[0]
      const img1Arr = img1.split('?')
      const img1Before = img1Arr[0]
      const domain = img1Before.split('/')[0]
      return domain === 'http://wespypicuser.afunapp.com' ? img1Before + '?imageView/3/w/696/h/696/q/100' : img1
    },

    date() {
      return dayjs(this.item.dynamic.time).locale(lang).fromNow()
    },

    imageStyle({ firstImg, imgWhType }) {
      const height = image_wh[imgWhType].split(' ')[1]

      return {
        width: '4.74rem',
        height: `${height}rem`,
        'background-image': `url(${firstImg})`
      }
    }
  },

  methods: {
    getVipIcon
  }
}
</script>

<style lang="scss" scoped>
.topic-item {
  overflow-y: hidden;
  box-sizing: border-box;
  // background: #fff;
  border-radius: 0.07rem;
  padding: 0.15rem 0.2rem;

  .ellipsis {
    max-width: 2.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .three-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .title {
    // border: 0.01rem solid #bdbdbd;
    // border-radius: 0.19rem;
    // margin-top: 0.2rem;
    // padding: 0 0.1rem 0 0.1rem;
    margin-right: 0.1rem;
  }

  .topic-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    border-radius: 0.1rem;
  }
}
</style>
