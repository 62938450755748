export default [
  {
    path: '/activity-20221108-main-th',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/11/13 23:59:59 UTC+8',
      quickConfig: '277943f74182ca0b0d04152094303407'
    },
    language: 'th'
  },
  {
    path: '/activity-20221108-recharge-th',
    component: () => import('./pages/recharge/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/11/13 23:59:59 UTC+8',
      quickConfig: '27c60685cfed3979aa05735759960ba9',
      language: 'th'
    }
  },
  {
    path: '/activity-20221108-loveEmail-receive-th',
    component: () => import('./pages/main/mods/loveEmail/receive.vue'), //1
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/11/13 23:59:59 UTC+8',
      quickConfig: '277943f74182ca0b0d04152094303407'
    },
    language: 'th'
  },
  {
    path: '/activity-20221108-loveEmail-sendout-th',
    component: () => import('./pages/main/mods/loveEmail/sendout.vue'), //1
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/11/13 23:59:59 UTC+8',
      quickConfig: '277943f74182ca0b0d04152094303407'
    },
    language: 'th'
  },
  {
    path: '/activity-20221108-topicCircle-topicDetail-th',
    component: () => import('./pages/main/mods/topicCircle/topicDetail.vue'), //1
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/11/13 23:59:59 UTC+8',
      quickConfig: '277943f74182ca0b0d04152094303407'
    },
    language: 'th'
  },
  {
    path: '/activity-20221108-topicCircle-sendTopic-th',
    component: () => import('./pages/main/mods/topicCircle/sendTopic.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/11/13 23:59:59 UTC+8',
      quickConfig: '277943f74182ca0b0d04152094303407'
    },
    language: 'th'
  }
]
