const pageCenter = 'MgB2tTxZ'

export default [
  {
    path: '/activity/v2/voice-center/main-u/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[0]
      },
      pageCenter,
      keepAlive: true
    }
  },

  {
    path: '/activity/v2/voice-center/detail-u/index.html',
    component: () => import('./detail.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[1]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/preview-u/index.html',
    component: () => import('./preview.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[2]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/create-u/index.html',
    component: () => import('./create.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[3]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/task-u/index.html',
    component: () => import('./task.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[4]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/apply-u/index.html',
    component: () => import('./apply.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[5]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/raiders-u/index.html',
    component: () => import('./raiders.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[6]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/share-u/index.html',
    component: () => import('./share.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[7]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/creator-u/index.html',
    component: () => import('./creator.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[8]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/rate-u/index.html',
    component: () => import('./rate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[9]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/illustrate-u/index.html',
    component: () => import('./illustrate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[10]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/explain-u/index.html',
    component: () => import('./explain.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[11]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/auxiliary-u/index.html',
    component: () => import('./auxiliary.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[12]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/guide-u/index.html',
    component: () => import('./guide.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[13]
      },
      pageCenter
    }
  }
]
