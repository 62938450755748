import localStorage from '@/utils/local-storage'
class ActInfo {
  // 是否是vconsole白名单
  isWhite = false
  // 是否开启加密，本地缓存（非线上）
  apiEncryption = 0

  setApiEncryption(apiEncryption = 0) {
    localStorage.set('__api_encryption__', apiEncryption)
    this.apiEncryption = apiEncryption
  }

  getApiEncryption() {
    if (!this.apiEncryption) {
      this.apiEncryption = localStorage.get('__api_encryption__')
    }
    return this.apiEncryption
  }

  setState(key, value) {
    this[key] = value
  }

  getState(key) {
    return this[key]
  }
}

export default new ActInfo()
