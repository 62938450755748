export default [
  {
    path: '/activity/v2/20230524/main-a/index.html',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      expire: '2023/05/26 23:59:59 UTC+8',
      pageCenter: '4cuOhQz8'
    }
  },
  {
    path: '/activity/v2/20230524/share-a/index.html',
    component: () => import('./pages/share.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      ignore: true,
      // expire: '2023/05/26 23:59:59 UTC+8',
      pageCenter: '4cuOhQz8'
    }
  }
]
