export default function getPropsMetaMap() {
  return {
    width: 'w',
    height: 'h',
    widthHeight: 'wh',
    font: 'f',
    fontSize: 'fs',
    color: 'fc',
    fontWeight: 'fw',
    border: 'b',
    borderWidth: 'bw',
    borderStyle: 'bs',
    borderColor: 'bc',
    background: 'bgC',
    backgroundColor: 'bgC',
    backgroundImage: 'bgI',
    backgroundSize: 'bgS',
    backgroundPosition: 'bgP',
    lineHeight: 'lh',
    borderRadius: 'bdr',
    marginTop: 'mt',
    marginLeft: 'ml',
    marginRight: 'mr',
    marginBottom: 'mb',
    margin: 'm',
    paddingTop: 'pdt',
    paddingLeft: 'pdl',
    paddingRight: 'pdr',
    paddingBottom: 'pdb',
    padding: 'pd',
    textAlign: 'ta',
    boxShadow: 'bsw',

    position: 'p',
    zIndex: 'pz',
    positionSet: 'ps',
    top: 'pt',
    bottom: 'pb',
    left: 'pl',
    right: 'pr',
    center: 'pc'
  }
}
