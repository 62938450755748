import { amConfig } from '../config'
import get from 'lodash/get'

import config from '@/config'

import { IS_IOS, IS_APP } from '@/utils/env'

import { isDebug, isLocal } from '@sub/ui-runtime/core/utils/env'
import { IS_WEPLAY } from '@/utils/env'
import { reportTTI } from '@/utils/global-point'
import { genPointMethods } from '@sub/utils/point-methods'
import getMockMeta from '@sub/ui-runtime/core/utils/meta/getMockMeta'
import lifeCycleCreator from '@sub/lib/life-cycle-creator'
import _ from 'lodash'
import * as iframe from '@sub/ui-runtime/core/iframe'
import { setUiMetaPageCenterSnapshot } from '@sub/utils/page-center-snapshot'
import WebVitals from '@sub/lib/web-vitals'

const hideEffectVersion = {
  android: IS_WEPLAY ? 28 : 29,
  iOS: IS_WEPLAY ? '13.0.0' : '14.0.0'
}

const pointMethods = genPointMethods()

const {
  isDev,
  isApp,
  lib: { dayjs, getQuery },
  modules: {
    base: { request, native, methods }
  }
} = amConfig

let timer = null

const state = {
  amBasic: {
    id: 0,
    time: 0,
    over: false,
    timer: null,
    user: {
      uid: isDev ? Number(getQuery().u || 0) : 0,
      sid: '',
      nickname: '',
      avatar: '',
      deviceId: '',
      frame: '',
      gender: 2,
      charm: 0,
      charmLevel: 1,
      charmIcon: '',
      avatarGender: 2,
      isAdult: false,
      isRealNameAuthed: false
    }
  },
  amActivityInfo: {},
  meta: {
    pageCenterReady: null // 运行时中如果有 pageCenterId，先赋值为 false，配置拉完后赋值为 true；没有则一直为 null
  },
  effectInfo: {
    // 动效开关状态是否请求到
    isEffectStateReadying: false,
    // 0 打开道具特效  1 关闭道具特效
    effectConfig: 0,
    androidInfo: {},
    // 是否是低版本
    isLowVersion: false,
    // pag控制版本
    isPAGLowVersion: false
  },
  userIOSVersion: getUserIOSVersion(),
  coin: 0, // 金币数
  chipInfo: {}, // 碎片数量信息
  chipTotalInfo: {}, // 碎片的累计获得数量信息
  extra: {
    // 是否打开客户的的分享
    anyShare: false,
    // 是否请求用户信息
    requestUserInfo: true,
    // 是否是avatar活动（需请求性别）
    avatar: false,
    // 是否是活动后台运行时
    isRuntime: window.location.pathname.startsWith('/runtime'),
    // 是否请求effect状态
    requestUserEffect: false,
    // 安卓版本
    androidVersion: hideEffectVersion.android,
    // ios版本
    iosVersion: hideEffectVersion.iOS,
    // 是否需要金币和碎片信息
    useCoinChip: false,

    // 安卓版本
    pagAndroidVersion: hideEffectVersion.android,
    // ios版本
    pagIosVersion: hideEffectVersion.iOS
  },

  // 用于eruda
  forceIsOpenEffect: isDev ? (getQuery().forceIsOpenEffect === 'false' ? false : getQuery().forceIsOpenEffect === 'true' ? true : null) : null
}

const getters = {
  amApiParam(state) {
    return methods.apiParam(state.amBasic)
  },
  isRuntime(state) {
    return state.extra.isRuntime
  },

  propsConfig({ amBasic: { user }, meta: { props } }) {
    if (!props) return {}
    const config = {}
    Object.values(props).forEach((item) => {
      config[item.id] = item
      if (+item.type === 19 && item.sub_type) {
        const name = user.avatarGender === 1 ? item.male_name : item.female_name
        config[item.id] = {
          ...config[item.id],
          img: user.avatarGender === 1 ? item.male_cover : item.female_cover,
          chairUrl: user.avatarGender === 1 ? item.male_seat_anim : item.female_seat_anim,
          imgBig: item.suit_act_anim || '',
          name,
          name1: user.avatarGender === 1 ? item.male_name1 : item.female_name1 || name,
          name2: user.avatarGender === 1 ? item.male_name2 : item.female_name2 || name,
          name3: user.avatarGender === 1 ? item.male_name3 : item.female_name3 || name,
          name4: user.avatarGender === 1 ? item.male_name4 : item.female_name4 || name
        }
      }
    })

    return config
  },
  isInVoiceRoom() {
    const { scene } = getQuery()
    return scene === 'voice_room'
  },

  pageConfig(state) {
    return state.amActivityInfo?.ui_meta
  },

  isOpenEffect({ effectInfo: { effectConfig, isLowVersion, isEffectStateReadying }, forceIsOpenEffect, userIOSVersion, meta: { json } }) {
    if (forceIsOpenEffect !== null) {
      return forceIsOpenEffect
    } else {
      const closeEffectIOSVersions = json?.__close_effect_ios_versions || []
      console.log('__close_effect_ios_versions', closeEffectIOSVersions)
      return isEffectStateReadying && effectConfig !== 1 && !isLowVersion && !isCloseEffectIOSVersions(userIOSVersion, closeEffectIOSVersions) && !isGrandIOS17(userIOSVersion)
    }
  },

  isOpenEffectPAG({ effectInfo: { effectConfig, isPAGLowVersion, isEffectStateReadying }, forceIsOpenEffect }) {
    if (forceIsOpenEffect !== null) {
      return forceIsOpenEffect
    } else {
      return isEffectStateReadying && effectConfig !== 1 && !isPAGLowVersion
    }
  }
}

const mutations = {
  // 初始化一些参数，不需要则不用调用
  initDefault(
    state,
    payload = {
      anyShare: false,
      requestUserInfo: true,
      avatar: false,
      isRuntime: false,
      requestUserEffect: false,
      androidVersion: hideEffectVersion.android,
      iosVersion: hideEffectVersion.iOS,
      useCoinChip: false
    }
  ) {
    state.extra = {
      ...state.extra,
      ...payload
    }
  },

  // 进入页面前同步设置meta
  setMeta(state, meta = {}) {
    state.meta = {
      ...state.meta,
      ...meta
    }

    if (state.extra.isRuntime) {
      state.amBasic.id = +getQuery().act_id
    } else {
      state.amBasic.id = meta?.id || 0
    }
  },

  setUserId(state, { uid, sid }) {
    state.amBasic.user.uid = Number(uid)
    state.amBasic.user.sid = sid
  },

  setUserInfo(state, userInfo) {
    state.amBasic.user = {
      ...state.amBasic.user,
      ...userInfo
    }
  },

  setAvatarUserInfo(state, info) {
    state.amBasic.user.avatarGender = info.avatar_gender
    state.amBasic.user.isAdult = info.is_adult
    state.amBasic.user.isRealNameAuthed = info.is_real_name_authed
  },

  setActInfo(state, actInfo) {
    state.amActivityInfo = actInfo
    state.amActivityInfo.time = dayjs(actInfo.end_time * 1000).format('YYYY/MM/DD HH:mm:ss')

    // 延迟展示活动结束的时间
    const { endStyleDelayTimestamp = 0 } = state.meta

    if (state.amBasic.time >= actInfo.end_time * 1000 + endStyleDelayTimestamp) {
      methods.activityOver(state.meta)
      state.amBasic.over = true
    }
  },

  setUiMeta(state, uiMeta) {
    state.amActivityInfo = state.amActivityInfo || {}
    state.amActivityInfo.ui_meta = uiMeta
  },

  setTime(state, time) {
    state.amBasic.time = time
  },

  timeAdd(state) {
    state.amBasic.time += 1000
  },

  setActOver(state) {
    if (state.amBasic.over) {
      return
    }

    const { end_time } = state.amActivityInfo
    if (!end_time) {
      return
    }

    const { endStyleDelayTimestamp = 0 } = state.meta
    if (end_time && state.amBasic.time > end_time * 1000 + endStyleDelayTimestamp) {
      methods.activityOver(state.meta)
      state.amBasic.over = true
    }
  },

  setEffectInfo(state, payload) {
    state.effectInfo = { ...state.effectInfo, ...payload }
  },

  setUserCoin(state, payload) {
    state.coin = payload
  },

  setChipInfo(state, payload) {
    state.chipInfo = payload
  },

  setChipTotalInfo(state, payload) {
    state.chipTotalInfo = payload
  },

  // 用于eruda
  setForceIsOpenEffect(state, payload) {
    state.forceIsOpenEffect = payload
  }
}

const actions = {
  async amInit({ dispatch, state, getters }, payload = {}) {
    const { loading = true, blockLoading = async () => {} } = payload
    const isRuntime = state.extra.isRuntime

    if (isApp) {
      const { anyShare = false } = state.extra
      anyShare || native.hideShareBtn()
    }

    const now = performance.now()
    setTimeout(() => {
      console.warn('ywtest in amInit', now)
    }, 1000)

    // 分帧渲染逻辑，运行时需单独处理
    let blockFramePromise
    if (!isRuntime) {
      blockFramePromise = dispatch('defer/updateFrame', {}, { root: true })
    }
    dispatch('getActUserInfo')
    dispatch('setPageDot')

    loading && document.body.classList.add('loading')
    pointMethods.setStartTime()

    // 一些阻塞 TTI 的异步逻辑
    await Promise.all([
      dispatch('amTimerInit'),
      dispatch(isRuntime ? 'amGetRuntimeActInfo' : 'amGetActInfo'),
      dispatch('getEffectState'),
      dispatch('getCoinChip'),
      dispatch('performance/getDeviceLevelInfo', {}, { root: true }),
      blockLoading()
      // 强制换行
    ])
    pointMethods.endAndTrack('LoadPage', { scene: '阻塞loading的接口总耗时' })
    loading && document.body.classList.remove('loading')

    if (!config.$env.PROD) {
      console.log('vuex base state:', state)
      console.log('vuex base getters:', getters)
    }

    reportTTI()
    methods.initAfter && methods.initAfter(this)

    if (!isRuntime) {
      // 保证分帧渲染的组件的 afterInit 都能执行
      await blockFramePromise

      lifeCycleCreator.emitLifeCycles('afterInit')
      console.warn('afterInit hook', performance.now())
    }

    WebVitals.init(state.meta)
    dispatch('performance/calculateFrame', {}, { root: true })
  },

  async getNativeUserInfo({ commit, state, dispatch, rootState }) {
    if ((!isDev || !state.amBasic.user.uid) && native.getUserInfo && isApp) {
      const userInfo = rootState.user
      const { uid, sid } = userInfo.uid && userInfo.sid ? userInfo : await native.getUserInfo()
      if (!uid || !sid) {
        await dispatch('getNativeUserInfo')
        return
      }

      commit('setUserId', { uid, sid })
    }
  },

  async getActUserInfo({ state, getters, commit, dispatch }) {
    const { avatar = false, requestUserInfo = true } = state.extra

    if (state.amBasic.user.uid && requestUserInfo) {
      const userinfo = await request.getUserInfo(getters.amApiParam)
      commit('setUserInfo', userinfo)

      if (avatar) {
        dispatch('getAvatarUserInfo')
      }
    }
  },

  async getAvatarUserInfo({ commit, getters }) {
    const res = await request.getAvatarUserInfo({
      ...getters.amApiParam
    })
    commit('setAvatarUserInfo', res)
  },

  async amGetActInfo({ state, commit, getters }) {
    if (state.meta.ignore || !getters.amApiParam.act_id) {
      return
    }
    const res = await request.getActInfo(getters.amApiParam)

    if (res) {
      genNotStartMask(res)
      commit('setActInfo', res)
    }
  },

  async amGetRuntimeActInfo({ commit, getters, dispatch }) {
    if (state.meta.ignore || !getters.amApiParam.act_id) {
      return
    }

    const res = await request.getActInfo(getters.amApiParam)
    if (!res) {
      console.error('actInfoApi not return data')
    }

    genNotStartMask(res)

    if (iframe.isInIframe()) {
      res.ui_meta = '{}'
    }

    let actInfo
    try {
      actInfo = { ...res, ui_meta: JSON.parse(res.ui_meta) }
    } catch (error) {
      console.error('parse ui_meta error:', error)
    }

    // 本地环境可使用 mock 的 meta
    if (isLocal()) {
      const mockMeta = await getMockMeta()
      if (mockMeta) {
        actInfo = { ...res, ui_meta: mockMeta }
      }
    }

    commit('setActInfo', actInfo)

    // 组件多语言文案等配置
    setUiMetaPageCenterSnapshot(actInfo)

    // 奖励资源
    if (!iframe.isInIframe()) {
      // runtime 依赖 base 中的 state.amActivityInfo.ui_meta?.config?.rewardDependencies
      dispatch('reward/init', {}, { root: true })
    }

    if (isDebug()) {
      console.debug('ui meta:', state.amActivityInfo.ui_meta)
    }

    // document.title = getters.pageConfig?.pages[0]?.title
  },

  async amTimerInit({ state, dispatch }) {
    if (isDev && getQuery().day) {
      await dispatch('listenNowTime', dayjs(getQuery().day).valueOf())
    } else if (state.amBasic.user.uid) {
      await dispatch('listenNowTime')
    }
  },

  async listenNowTime({ commit, getters, dispatch, state }, time) {
    commit('setTime', time || (await request.getNowTime(getters.amApiParam)))
    commit('setActOver')
    let num = 0
    timer = setInterval(() => {
      num = num + 1
      if (num < 60) {
        commit('timeAdd')
        commit('setActOver')
      } else if (num == 60) {
        dispatch('amClearTimer')
        dispatch('listenNowTime', time ? state.amBasic.time : null)
      } else {
        dispatch('amClearTimer')
      }
    }, 1000)
  },

  amClearTimer() {
    clearInterval(timer)
    timer = null
  },

  async setPageDot({ getters }) {
    if (isApp && request.setPageDot) {
      await request.setPageDot(getters.amApiParam)
    }
  },

  async getEffectState({ commit, getters, state }) {
    const effectConfig = await request.getEffectState(getters.amApiParam)
    let androidInfo = {}
    if (!IS_IOS) {
      androidInfo = (await native.getDeviceInfo()) || {}
    }

    commit('setEffectInfo', {
      effectConfig,
      androidInfo,
      isEffectStateReadying: true
    })

    commit('setEffectInfo', {
      isLowVersion: isLowVersion(state),
      isPAGLowVersion: isLowVersion(state, 'pag')
    })

    // this.isEffectStateReadying = true
  },

  // 需要刷新数量时可以调用此函数，如果要获取其他活动的金币，可以在params中传入act_id等参数
  getUserCoin: _.throttle(
    async function ({ commit, getters }, params = {}) {
      const res = await request.getUserCoin({ ...getters.amApiParam, ...params })
      commit('setUserCoin', res)
    },
    200,
    { trailing: false }
  ),

  // 需要刷新数量时可以调用此函数，如果要获取其他活动的碎片，可以在params中传入act_id等参数
  getChipInfo: _.throttle(
    async function ({ commit, getters }, params = {}) {
      // 解构防止影响入参，产生副作用
      const _params = {
        ...params
      }
      if (state.extra.chipActId) {
        _params.act_id = state.extra.chipActId
      }
      const res = await request.getCollectChip({ ...getters.amApiParam, ..._params })
      commit('setChipInfo', res)
    },
    200,
    { trailing: false }
  ),

  getChipTotalInfo: _.throttle(
    async function ({ commit, getters }, params = {}) {
      const res = await request.getChipTotalCount({ ...getters.amApiParam, ...params })
      commit('setChipTotalInfo', res)
    },
    200,
    { trailing: false }
  ),

  updateUiMeta({ commit }, params = {}) {
    commit('setUiMeta', params)
  },

  getCoinChip: _.throttle(
    function ({ state, dispatch }) {
      if (!state.extra.useCoinChip) return
      return Promise.all([dispatch('getUserCoin'), dispatch('getChipInfo')])
    },
    200,
    { trailing: false }
  )
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

function isLowVersion(state, type = 'svga') {
  if (!IS_APP) {
    return false
  }

  let {
    extra: { androidVersion, iosVersion, pagAndroidVersion, pagIosVersion },
    effectInfo: { androidInfo }
  } = state

  let res = false

  if (type === 'pag') {
    androidVersion = pagAndroidVersion
    iosVersion = pagIosVersion
  }

  try {
    if (IS_IOS) {
      const version = navigator.userAgent.split('OS')[1].split('like')[0].trim().replace(/_/g, '.')
      res = native.compareVersion(iosVersion, version) === '>' ? true : false
    } else {
      res = (androidInfo.android_version || androidVersion) < androidVersion
    }
  } catch (e) {
    return true
  }

  return res
}

function getUserIOSVersion() {
  try {
    if (!IS_IOS || !IS_APP) {
      return null
    }
    const version = navigator.userAgent.split('OS')[1]?.split('like')?.[0]?.trim()?.replace(/_/g, '.') || null
    console.log('IOS_VERSION: ', version)
    return version
  } catch (e) {
    console.warn('base getUserIOSVersion has error: ', e)
    return null
  }
}

// IOS16.4机型svga显示多会白屏
function isCloseEffectIOSVersions(version, extra = []) {
  if (version && ['16.4', '16.4.0', '16.4.1', ...extra].includes(version)) {
    return true
  } else {
    return false
  }
}

// IOS17.0以上机型svga显示多会白屏，后续好了去掉
function isGrandIOS17(version) {
  if (version && native.compareVersion(version, '17.0') !== '<') {
    return true
  } else {
    return false
  }
}

function genNotStartMask(data) {
  if (IS_WEPLAY && _.isBoolean(data.can_participate) && !data.can_participate) {
    document.body.classList.add('activity-not-start-mask')
    const el = document.createElement('div')
    el.style.zIndex = 1000

    el.classList.add('activity-not-start-common')
    el.classList.add(`activity-not-start-${getQuery().region || 'U'}`)

    document.body.insertBefore(el, document.body.firstChild)
  }
}
