<template lang="pug">
a-section.ui-async-mod-wrapper(v-bind='$attrs')
  template(v-if='$useDefer(curShowFrame)')
    slot
</template>

<script>
export default {
  props: {
    order: {
      type: [String, Number],
      default: 0
    }
  },

  data() {
    return {}
  },

  computed: {
    // 延时加载最大帧
    maxFrame() {
      return this.$store.state.defer?.maxFrame
    },

    // 当前多少帧才显示
    curShowFrame({ order }) {
      if (order) {
        order = Number(order)
      }

      // 间隔帧数(最大帧数 20 时)大概是10，5，2.5，1.25....然后小数向上取整
      const curShowFrame = Math.ceil(this.maxFrame - this.maxFrame / Math.pow(2, order))

      return curShowFrame
    }
  }
}
</script>

<style></style>
