const firebaseKey = 'AIzaSyDtb_D_GufJ6AMPi4UhLuNRDHuaG7zZ2mI'
module.exports = {
  dynamicLinkInfo: {
    // 华语服的包通用 （华、菲律宾、越南、马来、泰服）
    C: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay',
          iosIpadBundleId: 'com.wejoy.weplay',
          iosAppStoreId: '1557823712'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay'
        },
        domainUriPrefix: 'https://weplayasia.page.link'
      }
    },

    // 美
    U: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay.us',
          iosIpadBundleId: 'com.wejoy.weplay.us',
          iosAppStoreId: '1580330718'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay.us'
        },
        domainUriPrefix: 'https://weplay1.page.link'
      }
    },

    // 日
    J: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay.jp',
          iosIpadBundleId: 'com.wejoy.weplay.jp',
          iosAppStoreId: '1583719227'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay.jp'
        },
        domainUriPrefix: 'https://weplayja.page.link'
      }
    },

    // 阿
    A: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay.ar',
          iosIpadBundleId: 'com.wejoy.weplay.ar',
          iosAppStoreId: '1589399089'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay.ar'
        },
        domainUriPrefix: 'https://weplayar.page.link'
      }
    },

    // 韩
    K: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay.kr',
          iosIpadBundleId: 'com.wejoy.weplay.kr',
          iosAppStoreId: '1619262637'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay.kr'
        },
        domainUriPrefix: 'https://weplaykr.page.link'
      }
    },

    // 俄罗斯
    R: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay.ru',
          iosIpadBundleId: 'com.wejoy.weplay.ru',
          iosAppStoreId: '6471480071'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay.ru'
        },
        domainUriPrefix: 'https://weplayru.page.link'
      }
    },

    // 印度
    I: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay.ar',
          iosIpadBundleId: 'com.wejoy.weplay.ar',
          iosAppStoreId: '1589399089'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay.ar'
        },
        domainUriPrefix: 'https://weplayar.page.link'
      }
    },

    // 西班牙语
    S: {
      type: 'firebase',
      key: firebaseKey,
      data: {
        iosInfo: {
          iosBundleId: 'com.wejoy.weplay.us',
          iosIpadBundleId: 'com.wejoy.weplay.us',
          iosAppStoreId: '1580330718'
        },
        androidInfo: {
          androidPackageName: 'com.wejoy.weplay.us'
        },
        domainUriPrefix: 'https://weplay1.page.link'
      }
    }
  },

  ossHost: [
    // 代理域名
    'https://quick-page.weplayapp.com',
    'https://fe-center.weplayapp.com'
  ]
}
