import point from '@/lib/page-point'
import quickConfig from '@/store/quick-config'
import store from '@/store'
import { getQuery1 as getQuery } from '@/utils/utils'
import multiLanguage from '@sub/utils/multi-language'

import { setPageTitle } from '@/utils/get-full-title'
import { getConfig as getQuickPage } from '@/utils/quick-page'
import { getConfig as getPageCenter } from '@sub/utils/page-center'
import { initScrollPoint, initViewPoint, pageLoadTimePoint } from '@/utils/global-point'
import { setCryptType } from '@sub/service/crypto'
import { isLocal } from '@sub/ui-runtime/core/utils/env'
import { genPointMethods } from '@sub/utils/point-methods'

const pointMethods = genPointMethods()

export default function guardRegister(router) {
  router.beforeEach(async (to, from, next) => {
    to.meta || (to.meta = {})

    to.meta.notLoading || document.body.classList.add('loading')

    const qp = to.params.quickConfig || to.meta.quickConfig
    const pc = to.params.pageCenter || to.meta.pageCenter

    if (getQuery().region === 'M') {
      // 马尼服需支持多语言，需提前加载多语信息
      try {
        await multiLanguage.getUserLang(to)
      } catch (err) {
        console.error('multiLanguage.getUserLang catch err:', err)
      }
    }

    if (qp || pc) {
      pointMethods.setStartTime()
      const obj = qp ? await getQuickPage(qp) : await getPageCenter(pc)
      pointMethods.endAndTrack(
        'LoadPage',
        {
          scene: 'page-center接口耗时',
          act_id: to.meta.id,
          uid: store.state.user?.uid
        },
        0.1
      )

      if (obj) {
        to.meta.assets = { ...to.meta.assets, ...obj.assets }
        to.meta.params = { ...to.meta.params, ...obj.params }
        to.meta.json = { ...to.meta.json, ...obj.json }
        to.meta.props = { ...to.meta.props, ...obj.props, ...obj.avatar, ...obj.titles, ...obj.gifts }

        to.meta.text = { ...to.meta.text, ...obj.text }
        to.meta.fsAssets = obj.fsAssets
        to.meta.p0Assets = obj.p0Assets
        to.meta.components = obj.components || {}
        to.meta.setMeta && (await to.meta.setMeta(to))
      }
    }
    // 写入加密开关值 __crypt_type
    if (isLocal()) {
      setCryptType(-1)
    } else {
      setCryptType(to.meta.json?.__crypt_type || 2)
    }

    to.meta.isInternational = true

    pageLoadTimePoint(to)
    initScrollPoint(to)
    initViewPoint(to)
    quickConfig.setQuickConfig(to.path, to.meta)

    store.commit('base/setMeta', to.meta)
    await store.dispatch('base/getNativeUserInfo')

    next()
  })

  router.afterEach((to, from) => {
    document.body.classList.remove('loading')

    try {
      setPageTitle(to)
      if (to.meta.id && !to.query.act_id) {
        to.query.act_id = to.meta.id || 0
        const join = to.fullPath.indexOf('?') > -1 ? '&' : '?'
        history.replaceState(null, null, `${to.fullPath}${join}act_id=${to.query.act_id}`)
      }
    } catch (e) {
      console.error(e)
    }

    point.setSuperProperties({
      url: window.location.href,
      url_path: window.location.pathname
    })

    if (to.path !== from.path) {
      point.routeUpdate()
    }

    try {
      const actUrlStack = JSON.parse(window.sessionStorage.getItem('actUrlStack')) || []
      const curUrl = window.location.href

      if (actUrlStack.length === 0) {
        // 首次必入栈
        if (to.query.notActUrlStackUrl) {
          // 来自不同域的首次入栈
          actUrlStack.push(decodeURIComponent(to.query.notActUrlStackUrl))
          actUrlStack.push(curUrl)
        } else {
          actUrlStack.push(curUrl)
        }
      } else if (actUrlStack[actUrlStack.length - 1] !== curUrl) {
        if (to.query.isPushActUrlStack === 'true') {
          actUrlStack.push(curUrl)
        } else {
          actUrlStack.splice(0, actUrlStack.length, curUrl)
        }
      }

      window.sessionStorage.setItem('actUrlStack', JSON.stringify(actUrlStack))
    } catch (error) {
      console.error('in guardRegister, has an caught error', error)
    }
  })
}
