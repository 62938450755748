import merge from 'lodash/merge'

export default {
  namespaced: true,

  state: {
    // 当前帧,超过最大值后不计算
    curFrame: 0,
    // 延时加载最大帧
    maxFrame: 20
  },

  mutations: {
    initDefault(state, payload = {}) {
      merge(state, payload)
    },

    updateState(state, payload = {}) {
      merge(state, payload)
    }
  },

  actions: {
    updateFrame({ commit, state }) {
      return new Promise((resolve) => {
        calculateFrame({
          updateFrame: (frame) => commit('updateState', { curFrame: frame }),
          maxFrame: state.maxFrame,
          resolve
        })()
      })
    }
  }
}

function calculateFrame({ updateFrame = () => {}, maxFrame, resolve = () => {} }) {
  let rafId = 0
  let frame = 0

  function update() {
    rafId = requestAnimationFrame(() => {
      frame++
      updateFrame(frame)
      if (frame === maxFrame) {
        cancelAnimationFrame(rafId)
        resolve()
      } else {
        update()
      }

      if (frame % 10 === 0 && frame <= 30) {
        console.warn(performance.now(), 'frame=', frame)
      }
    })
  }
  return update
}
