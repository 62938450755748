export default [
  {
    path: `/activity/v2/20240329/consume-q/index.html`,
    component: () => import('./index.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 510,
      // TODO:
      // TODO:
      pageCenter: 'NveCN7Zc'
    }
  }
]
