export default [
  {
    path: `/activity/v2/20240223/sign-q/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1813,
      expire: '2024/08/30 23:59:59 UTC+8', // TODO
      pageCenter: 'bT7bwaB5' // TODO
    }
  }
]
