import { isEmpty, merge } from 'lodash'
import { amConfig } from '../config'
const {
  modules: {
    reward: { request }
  }
} = amConfig

const supportRewardMap = {
  prop: {},
  title: {},
  avatar_prop: {},
  gift: {},
  collect_chip: {}
}

export default {
  namespaced: true,

  state: {
    // 留个口子，未来给源码用
    rewardDep: {},

    // 奖励资源
    ...supportRewardMap
  },

  getters: {
    _rewardDep(state, _getters, _rootState, rootGetters) {
      // runtime
      const rewardDep = rootGetters['base/pageConfig']?.config?.rewardDependencies || {}

      return merge(rewardDep, state.rewardDep)
    },

    rewardMetaMap(state) {
      return Object.keys(supportRewardMap).reduce((acc, cur) => {
        return Object.assign(acc, state[cur])
      }, {})
    }
  },

  mutations: {
    setState(state, payload = {}) {
      Object.assign(state, payload)
    }
  },

  actions: {
    // 依赖 base/amInit 中 get_act_info 的结果
    async init({ getters, dispatch }) {
      const rewardDep = getters._rewardDep
      if (isEmpty(rewardDep)) {
        console.warn('🐲 gwl ~ reward/index.js ~ init ~ rewardDep is Empty:', rewardDep)
        return
      }

      const params = rewardDepToParams(rewardDep)

      await dispatch('getRewardMeta', params)
    },

    async getRewardMeta({ commit }, payload) {
      const { reward_meta_map } = await request.getRewardMeta(payload)
      const ret = { ...(reward_meta_map || {}) }

      // 将碎片的不包含 act_id 拼接的 id 也存一份，方便在 90% 的场景下直接取用
      if (ret.collect_chip) {
        Object.keys(ret.collect_chip).forEach((actId_id) => {
          const id = actId_id?.split('_')?.[1]
          id && (ret.collect_chip[id] = ret.collect_chip[actId_id])
        })
      }

      commit('setState', ret)
      return ret
    }
  }
}

export function rewardDepToParams(rewardDep) {
  return Object.keys(rewardDep).reduce((acc, cate) => {
    acc[cate] = rewardDep[cate].join(',')
    return acc
  }, {})
}
