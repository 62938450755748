<template lang="pug">
component.ui-fixed-wrapper(:is='curComponent', v-bind='fixedStyle', @a-tap='$emit("a-tap")')
  slot
</template>

<script>
const componentsMap = {
  position: 'a-position',
  button: 'a-button1'
}

export default {
  name: 'ui-fixed',

  props: {
    // 源码开发暂时用不到
    uiMeta: {
      type: Object,
      default: () => {
        return {}
      }
    },

    /**
     * 组件类型
     * @values position, button
     * @default position
     */
    type: {
      type: String,
      default: 'position'
    }
  },

  computed: {
    curComponent({ type }) {
      return componentsMap[type] || type
    },

    fixedStyle() {
      const { pl, pr, pb } = this.$attrs
      let obj = { ...this.$attrs, p: 'fixed' }
      pl && (obj.pl = this.$getFixedPl(pl))
      pr && (obj.pr = this.$getFixedPr(pr))
      pb && (obj.pb = this.$getFixedPb(pb))
      return obj
    }
  }
}
</script>

<style></style>
