import getPropsMetaMap from '../map/getPropsMetaMap'

const propsMetaMap = getPropsMetaMap()

export const attrs = {
  computed: {
    attrs() {
      if (!this.$attrs.isRuntime) {
        return this.$attrs
      }

      const keys = Object.values(propsMetaMap)
      const attrs = {}
      for (let k in this.$attrs) {
        if (keys.includes(k)) {
          attrs[k] = this.$attrs[k]
        }
      }

      // 统一处理字体描边相关属性
      const { strokeWidth, strokeColor } = this.uiMeta?.props || {}
      strokeWidth && (attrs.strokeWidth = strokeWidth)
      strokeColor && (attrs.strokeColor = strokeColor)

      return attrs
    }
  }
}
