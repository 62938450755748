export default [
  {
    path: '/activity/v2/20240105/main-c/index.html',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = 1554
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      id: 1554,
      expire: '2024/12/17 23:59:59 UTC+8',
      pageCenter: 'ELkcGsVr'
      // ignore: true
    }
  },
  {
    path: '/activity/v2/20240105/recharge-c/index.html',
    component: () => import('./pages/recharge/recharge.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.params.title
      },
      id: 1551,
      expire: '2024/12/17 23:59:59 UTC+8',
      pageCenter: 'fHnlGZR2'
    }
  },
  {
    path: '/activity/v2/20240105/cp-c/index.html',
    component: () => import('./pages/cp/page.vue'),
    meta: {
      setMeta(to) {
        // to.meta.id = to.meta.params.id
        // to.meta.expire = to.meta.params.expire
        to.meta.id = 1552
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      id: 1552,
      expire: '2024/12/17 23:59:59 UTC+8',
      pageCenter: 'B1FGsXAI'
    }
  }
]
