import config from '@/config'
import runtimeV3 from '@sub/ui-runtime/router'

function routesFlat(routes = []) {
  return routes.map((v) => v.keys().reduce((routes, key) => [].concat(routes, v(key).default), [])).flat(1)
}

// 与@/modules重复
// const longTerm = routesFlat([require.context('@/modules/long-term-activities', true, /router\.js/)])
// const shortTerm = routesFlat([require.context('@/modules/short-term-activities', true, /router\.js/)])
const oldTerm = routesFlat([require.context('@/modules', true, /router\.js/)])

const routeNoFound = { path: '/', component: config.$env.LOCAL ? () => import('./404.vue') : { render: () => '' } }
const routeJsBridge = { path: '/js-bridge-sample', component: config.$env.LOCAL || config.$env.DEV ? () => import('./js-bridge-sample/index.vue') : { render: () => '' } }

export default [
  // ...
  ...oldTerm,
  routeNoFound,
  routeJsBridge,
  runtimeV3
]
