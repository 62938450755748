import VueI18n from 'vue-i18n'

import routes from '@/router/routes'

import { PAGE_LANG, DEFAULT_LANG } from '@/utils/env'

import { getMessages, proxyT, quickT } from './util'

export function install(app) {
  app.use(VueI18n)

  const messages = getMessages(routes)

  const i18n = new VueI18n({
    locale: PAGE_LANG,
    fallbackLocale: DEFAULT_LANG,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    warnHtmlInMessage: 'off',
    messages
  })

  app.prototype.$t = proxyT(app.prototype.$t)
  i18n.t = proxyT(i18n.t)

  // quick-page 多语言函数
  app.prototype.$qt = quickT

  return i18n
}
