import Vue from 'vue'
import get from 'lodash/get'
import { commonText } from '@sub/ui-components/utils/config'
import quickConfig from '@/store/quick-config'
import proxyAssets from '@sub/utils/proxy-assets'
/**
 * @module avatar
 */
/**
 * 获取 Toast 相关信息，根据当前区域从映射中获取实名认证和成人认证的提示文本和图片链接。
 * @returns {Object} 返回一个包含实名认证和成人认证提示信息的对象。
 * @property {string} realNameToast - 实名认证的提示文本。
 * @property {string} adultToast - 成人认证的提示文本。
 */
function getToastInfo() {
  const { region = 'HW' } = Vue.prototype.$app.$route.query

  const map1 = {
    HW: '请先完成实名认证',
    C: '請先完成實名認證'
  }
  const map2 = proxyAssets({
    HW: 'https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/9uWdcP9h.png?_w=654&_h=480',
    C: 'https://fe-center.weplayapp.com/page-center/assets/rio4D9OM/IPqB8UlV.png?_w=654&_h=480'
  })

  return {
    realNameToast: map1[region],
    adultToast: map2[region]
  }
}

function addNode() {
  let Profile = Vue.extend({
    template: `
  <a-dialog name='adult' bg-i=''>
    <template slot-scope='scope' v-if='scope.data'>
      <a-position :bg-i='scope.data' pc>
      </a-position>
    </template>
  </a-dialog>
  `
  })
  const div = document.createElement('div')
  div.id = 'Profile'
  document.querySelector('body')?.appendChild(div)
  new Profile().$mount('#Profile')
}
/**
 * 跳转梦幻岛。
 * @returns {void}
 */
export async function jumpNeverland() {
  const { region = 'HW' } = Vue.prototype.$app.$route.query
  switch (region) {
    case 'C':
      if (this.$we.compareAppVersion('4.0.0') === '<') {
        this.$toast('版本過低，無法打開夢幻島，請及時更新！')
        return
      }
      break
    case 'K':
      if (this.$we.compareAppVersion('4.1.7') === '<') {
        this.$toast('위플랜드에 입장하기에는 버전이 너무 낮습니다. 제때에 업데이트해 주세요!')
        return
      }
      break
    case 'J':
      if (this.$we.compareAppVersion('4.1.7') === '<') {
        this.$toast('バージョンが低すぎて、ネバーランドを開けません。早急にアップデートしてください！')
        return
      }
      break
    default:
      break
  }

  if (region === 'HW') {
    // 仅会玩需要实名和成年的判断
    const { isAdult = false, isRealNameAuthed = false } = this.$base.amBasic().user

    if (!isRealNameAuthed) {
      this.$toast(getToastInfo().realNameToast)
      return
    }

    if (!isAdult) {
      addNode()
      await this.$nextTick()
      this.$dialogs.adult.open({ data: getToastInfo().adultToast, options: { bgClose: true } })
      return
    }
  }

  this.$we('jumpDeepLink', `wespydeeplink://neverland`)
}
/**
 * 检查给定的属性是否为avatar道具。
 * @param {string} id - 要检查的属性标识符。
 * @returns {boolean} 如果给定的属性为avatar道具，是则返回 true，否则返回 false。
 */
export function isAvatarProp(id) {
  const propsConfig = get(quickConfig.getQuickConfig(window.location.pathname), 'props', {})
  const prop = propsConfig[id]
  return prop?.type === 19 && !!prop?.sub_type
}

export function avatarPreview(id) {
  this.$dialogs[`ui-avatar-dialog-${id}`].open({ options: { bgClose: true } })
}
/**
 * 根据给定的属性标识符获取相应的标签。
 * @param {string} id - 要获取标签的属性标识符。
 * @returns {string} 返回与给定属性标识符对应的标签。
 */
export function getTag(id) {
  const propsConfig = get(quickConfig.getQuickConfig(window.location.pathname), 'props', {})

  return isAvatarProp(id) ? get(commonText.avatarTagMap, propsConfig[id]?.sub_type, '') : propsConfig[id].name2
}

export default {
  $isAvatarProp: isAvatarProp,
  $getTag: getTag,
  $avatarPreview: avatarPreview,
  $jumpNeverland: jumpNeverland
}
