<template lang="pug">
  a-section.ui-box-preview-gender(v-if='propList.length > 0', :wh='wh', v-bind='$attrs')
    a-position.ui-box-preview-gender__item(v-for='(id, i) in list', v-show='id', :key='i', v-bind='propList[i]', @a-tap='id[1] && id[2] ? $propPreview(id[gender]) : $propPreview(id)')
    slot
</template>

<script>
import { propMap, whMap } from './config'

export default {
  props: {
    // id列表
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {}
  },

  computed: {
    gender() {
      // 性别，1-男，2-女
      return this.$base.amBasic().user.gender
    },
    wh() {
      return whMap[this.list.length]
    },

    propList() {
      return propMap[this.list.length] || []
    }
  }
}
</script>

<style lang="scss" scoped></style>
