import Vue from 'vue'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { buildApi } from '@/utils/request'
import { $dayjsTz } from '@/utils/dayjs'
import { commonText } from '@sub/ui-components/utils/config'

export default {
  namespaced: true,

  state: {
    // 是否正在加载数据
    isLoading: false,
    // 记录信息 { record: { tab1:{},tab2:{} } }
    recordInfos: {},
    // 打开弹窗回调 { record: () => {} }
    openRecord: {},
    // 格式化方法 { record: { tab1:() => {}, tab2:() => {} } }
    formatMethods: {}
  },

  mutations: {
    initDefault(state, { recordName, requests, openRecord, format }) {
      const obj = {}
      Object.keys(requests).forEach((k) => {
        const { url, params } = requests[k]
        obj[k] = { list: [], page: 1, loading: false, finished: false, request: buildApi(url), params }
      })
      Vue.set(state.recordInfos, recordName, obj)
      Vue.set(state.openRecord, recordName, openRecord)
      Vue.set(state.formatMethods, recordName, format)
    },

    updateRecordInfo(state, { recordName, tab, value }) {
      Vue.set(state.recordInfos[recordName], tab, {
        ...state.recordInfos[recordName][tab],
        ...value
      })
    },

    resetRecordInfo(state, recordName) {
      Object.keys(state.recordInfos[recordName]).forEach((tab) => {
        Vue.set(state.recordInfos[recordName], tab, {
          ...state.recordInfos[recordName][tab],
          ...{ list: [], page: 1, loading: false, finished: false }
        })
      })
    },

    setLoading(state, isLoading) {
      state.isLoading = isLoading
    }
  },

  actions: {
    amRecordLoad: throttle(
      async function ({ state, commit, rootGetters }, { recordName, tab }) {
        if (state.isLoading) {
          return
        }

        const { request, params, page, list } = state.recordInfos[recordName][tab]
        const format = get(state.formatMethods, `${recordName}.${tab}`, defaultFormat) || defaultFormat
        const amApiParam = rootGetters['base/amApiParam']

        commit('setLoading', true)
        const res = await request({ ...amApiParam, page: page, page_size: 10, offset: list.length, limit: 10, ...(params || {}) })
        commit('setLoading', false)

        const tmpRes = { ...res }
        delete tmpRes.has_more
        delete tmpRes.list

        const value = { ...tmpRes, page: page + 1, loading: false }
        if (res.list || res.exchange_logs || res.records) {
          let temList = list
          temList.push(...(res.list || res.exchange_logs || res.records || []))
          // 格式化列表
          if (format) {
            temList = temList.map((item, index) => format({ item, index, tab, recordName }))
          }
          // 更新列表
          value.list = temList
        }

        // 更新完成状态
        if (res.has_more === false || res.total <= (value.list?.length || 0) || res.total_count <= (value.list?.length || 0)) {
          value.finished = true
        }

        // 批量更新状态
        commit('updateRecordInfo', { recordName, tab, value })
      },
      20,
      { trailing: false }
    ),

    amRecordDialogClose({ commit, state }, recordName) {
      Object.keys(state.recordInfos[recordName]).forEach((key) => {
        const resetInfo = { list: [], page: 1, loading: false, finished: false }
        commit('updateRecordInfo', { recordName, tab: key, value: resetInfo })
      })
    }
  }
}

const defaultFormat = ({ item }) => {
  const { dateFormat } = commonText
  const { create_time, reward_name, create_time_unix, create_unix_time, buy_time, exchange_extra } = item
  let exchangeExtra = exchangeExtraHandle(exchange_extra)

  return {
    ...item,
    exchangeExtra,
    head1: $dayjsTz(create_time_unix * 1000 || create_unix_time * 1000 || create_time * 1000 || buy_time).format(dateFormat),
    head2: reward_name,
    createTime: create_time_unix * 1000 || create_unix_time * 1000 || create_time * 1000 || buy_time
  }
}

// 处理兑换的extra信息
function exchangeExtraHandle(extra) {
  let exchangeExtra = {}
  if (extra) {
    try {
      exchangeExtra = JSON.parse(extra)
    } catch (error) {
      console.error('record exchange_extra JSON parse error', error)
    }
  }

  return exchangeExtra
}
