import { isA } from '@sub/ui-components/utils/config'

export const groupStyleConfig = {
  uiTitle: { ml: '0.13' },
  uiTask: {
    taskItemContainer: { wh: '100% 1.46', mb: '0.2' },
    dailyTaskButton: { pt: '0.4', [isA ? 'pr' : 'pl']: '4.69' },
    receiveRedDot: { wh: '0.15', bgC: '#FF0000', bdr: '50%', pt: '0', [isA ? 'pl' : 'pr']: '0.12' },
    propPosition: { pc: 'y', wh: '1.12', [isA ? 'pr' : 'pl']: '0.53' }
  }
}

export const defaultTextColor = {
  dailyTask: '#E07A96'
}

export const defaultSpecialStyle = {
  containerStyle: {
    w: 6.7
  },
  task: {
    taskItemContainer: {},
    receiveRedDot: {},
    propPosition: {},
    dailyTaskButton: {}
  }
}
