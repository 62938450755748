import config from '@/config'
import { install as activityComponent } from './activity-component'
import { install as activityModule } from './activity-module'
import { install as clipboard } from './clipboard'
import { install as i18n } from './i18n'
import { install as pagePonit } from './page-point'
import { install as pageToast } from './page-toast'
import { install as pageToggleHook } from './page-toggle-hook'
import { install as utils } from './utils'
import { install as vconsole } from '@sub/utils/vconsole'
import { install as weSdk } from './we-sdk'
import { install as vuexUtils } from '@sub/store/lib/vuex-utils'
import getQuery from '@sub/ui-runtime/core/utils/getQuery'
// import { install as sentry } from './sentry'
import { install as portalVue } from './portal-vue'

export default async function libInstall(app) {
  if (!config.$env.PROD || getQuery().debug === 'true') {
    await vconsole()
  } else {
    vconsole()
  }

  pagePonit(app)
  pageToast(app)
  pageToggleHook(app)
  weSdk(app)
  clipboard(app)
  utils(app)
  vuexUtils(app)
  // sentry()
  const i18nInstance = i18n(app)

  await activityComponent(app)
  activityModule(app)
  portalVue(app)

  return { i18n: i18nInstance }
}
