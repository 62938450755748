export default [
  {
    path: '/activity/v2/20230612/family-war-rank-a/index.html',
    component: () => import('./rank.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      quickConfig: 'f7cfe0ab903f0fc07ea6dca8f6a2becb'
    }
  },
  {
    path: '/activity/v2/20230612/family-war-reward-a/index.html',
    component: () => import('./reward.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      quickConfig: 'fbdfe5f9752507e160fd669bb4bdd082'
    }
  },
  {
    path: '/activity/v2/20230612/family-war-main-a/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: 'UOvkk1iC'
    }
  }
]
