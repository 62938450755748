import { buildApi1 } from '@/utils/request'
import store from '@/store'
import localStorage from '@/utils/local-storage'

import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'

const getUserLang = buildApi1('/activity_v2/common/get_act_lang')
const setUserLang = buildApi1('/activity_v2/common/choose_act_lang')
/**
 * @class MultiLanguage
 */
/**
 * 表示一个多语言实用工具类。
 */
class MultiLanguage {
  /**
   * 用于在本地存储中存储语言设置的键名。
   * @type {string}
   */
  storageKey = ''

  /**
   * 用户ID。
   * @type {number}
   */
  uid = 0
  /**
   * 会话ID。
   * @type {number}
   */
  sid = 0
  /**
   * 当前语言。
   * @type {string}
   */
  lang = ''
  /**
   * 根据当前语言检索多文本配置。
   * @param {Object} textConfig - 包含多语言文本的配置对象。
   * @returns {Object} 当前语言的文本配置。
   */
  getMultiTextConfig(textConfig) {
    return IS_WEPLAY ? textConfig[this.lang || PAGE_REGION] : textConfig.hw
  }
  /**
   * 异步获取用户的语言设置。
   * @param {Object} to - 导航目标对象。
   * @returns {Promise<string>} 一个带有用户语言的解决的承诺。
   */
  async getUserLang(to) {
    if (!this.uid) {
      this.uid = store.state.user.uid
      this.sid = store.state.user.sid
    }

    if (!this.storageKey) {
      this.storageKey = `__page_lang_${this.uid}__`
    }

    let lang = localStorage.get(this.storageKey) || ''

    if (!lang) {
      const meta = to?.meta || { isExternalPage: false }
      if (meta?.isExternalPage) {
        lang = to.query.lang
        localStorage.set(this.storageKey, lang)
      } else {
        const { user_lang } = await getUserLang({ uid: this.uid, sid: this.sid })
        lang = user_lang
        this.setUserLang(user_lang)
      }
    }

    this.lang = lang
    return Promise.resolve(lang)
  }
  /**
   * 异步设置用户的语言。
   * @param {string} lang - 要设置的语言。
   * @returns {Promise<void>} 当语言设置完成时解决的承诺。
   */

  async setUserLang(lang) {
    await setUserLang({ page_lang: lang, uid: this.uid, sid: this.sid })
    localStorage.set(this.storageKey, lang)
  }

  /**
   * 异步切换马来语和印尼语，并重新加载页面。
   * @returns {Promise<void>} 当语言更改并重新加载页面时解决的承诺。
   */
  async changeMLang() {
    await this.setUserLang(this.lang === 'ms' ? 'id' : 'ms')
    window.location.reload()
  }
}

export default new MultiLanguage()
