<template lang="pug">
a-section.top4-n(wh='100%')
  a-position.top4-n__item(pc='y', pl='0.22', :style='getStyle("rankNum")') {{ getRankStr(item) }}
  //- 头像
  a-position.top4-n__url(pc='y', pl='0.94', wh='1.2', bdr='50%', :bg-i='item.video_cover_url')
    a-position.top4-n__img(pc, wh='100%', bg-s='contain', :bg-i='useImages[isMine ? "headBgMine" : "headBgCommon"]')
    //- 播放
    play-btn.top4-n__play(pc, :bg-i='images.playBtn', :item='item')
    //- 作品编号
    a-position(pc='x', pb='0.04', wh='100% 0.25', lh='0.25', ta, :style='getStyle("workId")') {{ item.id }}
  //- 用户昵称
  a-position.top4-n__name.ws-nowrap(pl='2.42', pt='0.34', h='0.31', lh='0.31', :style='getStyle("nickname")') {{ $ts(item.nickname, 7) }}

  a-position(pl='2.38', pt='0.71', h='0.34')
    a-position.top4-n__score(pl='0', pc='y', :bg-i='images.scoreIcon', bg-s='contain', wh='0.34')
    //- 票数
    a-position.top4-n__count.ws-nowrap(pl='0.36', pc='y', :style='getStyle("voteNumber")') {{ item.vote_count }}

  template(v-if='isMine')
    //- 分享
    share-btn(pc='y', pl='4.66', :bg-i='useImages.shareBtnMine', wh='1.2 0.44', :item='item')
  template(v-else)
    //- 投票
    vote-btn(pc='y', pl='4.66', :bg-i='images.voteBtn', :item='item')
    //- 分享
    share-btn(pc='y', pl='6.06', :bg-i='useImages.shareBtn', wh='0.44', :item='item')
</template>

<script>
import { get } from 'lodash'
import { RANK } from '../../const'

import voteBtn from '../vote-btn.vue'
import playBtn from '../play-btn.vue'
import shareBtn from '../share-btn.vue'

export default {
  components: {
    voteBtn,
    playBtn,
    shareBtn
  },

  props: {
    // common / mine
    type: {
      type: String,
      default: 'common'
    },
    item: {
      type: Object,
      default: () => ({})
    },
    images: {
      type: Object,
      default: () => ({})
    },
    useStyle: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {}
  },

  computed: {
    useImages({ images }) {
      return images[RANK]
    },

    isMine() {
      return this.type === 'mine'
    }
  },

  methods: {
    getStyle(target) {
      return get(this.useStyle, `${this.type}.${target}`, {})
    },

    getRankStr({ rank, member_extra_info }) {
      if (!rank) {
        return member_extra_info?.video_info ? '100+' : '-'
      }
      return rank < 10 ? `0${rank}` : rank <= 100 ? rank : '100+'
    }
  }
}
</script>

<style scoped></style>
