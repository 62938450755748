export default [
  // {
  //   path: '/activity-20221202-main-ar',
  //   component: () => import('./pages/main/page.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.params.id
  //       to.meta.expire = to.meta.params.expire
  //       to.meta.title = to.meta.params.title
  //       to.meta.language = to.meta.params.language
  //     },
  //     quickConfig: '',
  //     language: 'ar'
  //   }
  // }
]
