import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'

import config from '@/config'
import { buildApi as ba, buildApi1 as ba1, buildApi2 as ba2, buildApi3 as ba3 } from './request-next'

/**
 * 构建 axios 实例相关
 * genService     构建 axios 实例并进行处理
 * requestError   请求错误处理
 * responseError  相应错误处理
 */
export function genService(host, [code, msg, data]) {
  const service = axios.create({
    baseURL: host,
    withCredentials: false,
    timeout: 10000
  })

  service.interceptors.request.use(
    (config) => {
      if (config.headers[config.method]['Content-Type'] === 'application/x-www-form-urlencoded' && Object.prototype.toString.call(config.data || {}) !== '[object FormData]') {
        config.data = qs.stringify(config.data)
      }

      return config
    },
    async (e) => requestError({ msg: e })
  )

  service.interceptors.response.use(
    async ({ data: res }) => (res[code] === 200 ? res[data] : await responseError({ code: res[code] || 403, msg: res[msg] || res })),
    async (e) => await responseError({ code: e.response.status, msg: e.message })
  )

  return service
}
async function requestError({ msg }) {
  return Promise.reject({ msg })
}
async function responseError({ code, msg = 'Error' }) {
  Vue.prototype.$toast(msg)
  return Promise.reject({ code, msg })
}

export default genService(config.request.h1, ['code', 'msg', 'result'])

/**
 * 辅助函数
 */
// export const concatCatch = (fn) => fn.then((data) => [null, data]).catch((error) => [error, null])
export const concatCatch = async (fn, bodyClass = 'loading') => {
  bodyClass && document.body.classList.add(bodyClass)
  const result = await fn.then((data) => [null, data]).catch((error) => [error, null])
  bodyClass && document.body.classList.remove(bodyClass)
  return result
}
export const genGetApi = (service, url) => (d, o) => service({ url, method: 'get', params: d, ...o })
export const genPostApi = (service, url) => (d, o) => service({ url, method: 'post', data: d, ...o })
export const concatCatch1 = async (fn) => await fn.then((data) => [null, data]).catch((error) => [error, null])
export const buildApi = ba
export const buildApi1 = ba1

export const buildApi2 = ba2
export const buildApi3 = ba3
