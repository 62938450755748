export default [
  {
    path: '/activity-20220915-main',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/12/09 23:59:59 UTC+8',
      quickConfig: 'fa4c4ccbe464a02bdd5dc02a97bbd45d'
    }
  },
  {
    path: '/activity-20220915-otherTree',
    component: () => import('./pages/main/other-tree.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/12/09 23:59:59 UTC+8',
      quickConfig: 'fa4c4ccbe464a02bdd5dc02a97bbd45d'
    }
  },
  {
    path: '/activity-20220915-rank',
    component: () => import('./pages/rank/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2022/12/09 23:59:59 UTC+8',
      quickConfig: 'fa4c4ccbe464a02bdd5dc02a97bbd45d'
    }
  }
]
