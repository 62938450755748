import toast from '@wepie/page-toast'

import { IS_MOBILE } from '@/utils/env'
import { addClass, getQuery1 } from '@/utils/utils'

export function install(app) {
  app.use(toast, { version: 2, size: IS_MOBILE ? '0.28rem' : '14px' })
  const { region } = getQuery1()
  if (region === 'A') {
    addClass('#global-page-toast', ['ar', 'txt-c'])
  }

  if (region === 'U') {
    addClass('#global-page-toast', ['txt-c'])
  }
}

export default toast
