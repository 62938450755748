const defaultGifStyle = {
  w: '100%',
  h: '82%',
  pc: 'x',
  pt: '0'
}
const defaultStaticStyle = {
  wh: '3.78',
  pc: 'x',
  pt: '.47'
}
export function getContentStyle1(style) {
  if (!style || style === '') {
    return defaultGifStyle
  }
  return style
}

export function getContentStyle2(style) {
  if (!style || style === '') {
    return defaultStaticStyle
  }
  return style
}
