import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

export const defaultImage = proxyAssets(
  IS_WEPLAY
    ? {
        backLeft: 'https://fe-center.weplayapp.com/page-center/assets/lWPi4jtw/LaC9pund.png?_w=180&_h=180',
        backRight: 'https://fe-center.weplayapp.com/page-center/assets/lWPi4jtw/e9gXfjlK.png?_w=180&_h=180',
        refresh: 'https://fe-center.weplayapp.com/page-center/assets/lWPi4jtw/qmhK3zl2.png?_w=180&_h=180'
      }
    : {
        backLeft: 'https://fe-center.afunapp.com/page-center/assets/lWPi4jtw/COetlEQ8.png?_w=180&_h=180',
        backRight: 'https://fe-center.afunapp.com/page-center/assets/lWPi4jtw/PNFCqxJL.png?_w=180&_h=180',
        refresh: 'https://fe-center.afunapp.com/page-center/assets/lWPi4jtw/mnm4dNxh.png?_w=180&_h=180'
      }
)

const toastTxtConfig = {
  hw: '已刷新',
  C: '已刷新',
  J: '更新済み',
  K: '새로고침 완료',
  A: 'جاري التحديث'
}

export const toastTxt = IS_WEPLAY ? toastTxtConfig[PAGE_REGION] || '' : toastTxtConfig.hw
