export default [
  {
    path: `/activity/v2/20240708/recharge-q/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 2430,
      // TODO:
      expire: '2024/08/15 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'UT21C2fJ'
    }
  }
]
