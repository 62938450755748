export default {
  methods: {
    /**
     * @description 生成BEM规范的class
     * @param {object} bem 自定义bem对象
     * @param {string} [bem.b] 块名、默认为当前组件的组件名
     * @param {string} [bem.e] 元素名 默认为空字符串
     * @param {string} [bem.m] 修饰器 表状态 默认为空字符串
     * @return {string} 返回类名
     */
    genBEMClass(bem = { b: '', e: '', m: '' }) {
      let { b = '', e = '', m = '' } = bem
      b = b || this.$vnode?.componentOptions?.tag
      return `${b}${e ? '__' + e : ''}${m ? '--' + m : ''}`
    }
  }
}
