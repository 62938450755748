import { isArray } from 'lodash'
import { size } from '@/utils/set-font-size'

/**
 * @module dom
 */

/**
 * 根据引用名称获取对应的 DOM 元素或组件实例。
 * @param {string} ref - 要获取的引用名称。
 * @returns {HTMLElement|VueComponent|null} 返回与引用名称对应的 DOM 元素或 Vue 组件实例，如果引用不存在则返回 null。
 */
export function getRef(ref) {
  return isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref]
}

/**
 * 将页面滚动到指定的目标引用处。
 * @param {string} ref - 要滚动到的目标引用的名称。
 * @param {string} [block='start'] - 定义如何对齐目标元素的滚动位置。可选值为 'start'、'center'、'end' 或 'nearest'。
 * @param {string} [behavior='smooth'] - 定义滚动的行为。可选值为 'auto'、'smooth'、'instant' 或 'if-needed'。
 */
export function toTargetRef(ref, block, behavior = 'smooth') {
  const el = this.$getRef(ref)?.$el
  el && el.scrollIntoView({ block, behavior })
}

/**
 * 在页面中显示加载中状态。
 * @param {string} [className='loading'] - 加载中状态的类名，默认为 'loading'。
 */
export function showLoading(className = 'loading') {
  document.body.classList.add(className)
}

/**
 * 从页面中移除加载中状态。
 * @param {string} [className='loading'] - 加载中状态的类名，默认为 'loading'。
 */
export function hideLoading(className = 'loading') {
  document.body.classList.remove(className)
}

/**
 * 获取图像资源的路径
 * @param {string} imgKey - 图像资源的键名
 * @returns {string|undefined} - 返回与给定键关联的图像资源路径，如果找不到对应的路径则返回 undefined
 */
export function getSrc(imgKey) {
  return this.$route.meta.assets[imgKey]
}
/**
 * 修正对话框的位置，将其添加到页面容器中。
 * @param {HTMLElement} dialog - 要修正位置的对话框元素。
 */
export function fixDialogPosition(dialog) {
  const page = document.querySelector('.page-container')
  page.appendChild(dialog)
}

/**
 * @description 获取元素距离页面顶部的距离（解决多层元素的问题）
 * @param {Element} el
 * @returns {Number} 元素距离页面顶部的距离（rem）
 */
export function getElementTop(el) {
  let realTop = el.offsetTop

  let parentEl = el.offsetParent

  while (parentEl !== null) {
    realTop += parentEl.offsetTop

    parentEl = parentEl.offsetParent
  }

  return realTop / size
}

export default {
  $getRef: getRef,
  $toTargetRef: toTargetRef,
  $showLoading: showLoading,
  $hideLoading: hideLoading,
  $getSrc: getSrc,
  $fixDialogPosition: fixDialogPosition,
  $getElementTop: getElementTop
}
