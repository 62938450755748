import { isA } from '../../utils/config'

export const teamInfoStyleConfig = {
  teamNameWrap: {
    wh: '2.64 .5',
    fs: '.24',
    fw: 500,
    pc: 'x',
    pt: 0
  },
  teamMembers: {
    wrap: { wh: '5.7 1.9', pt: 0.62, [isA ? 'pr' : 'pl']: 1.02 },
    item: { wh: '1.2 1.9' },
    roleWrap: { h: 0.32, bdr: 0.45, pdt: 0.02, pdl: 0.2, pdr: 0.2, fs: 0.2, fw: 500, pc: 'x', pz: 1, pt: 0 },
    avatar: { wh: 1.2, pc: 'x', pt: 0.2 },
    nickname: { w: 1.2, pt: 1.56, fs: 0.24, fw: 500, lh: 0.34 },
    scoreWrap: { wh: '1.28 .5', pt: 2, pc: 'x', fs: '.2', fw: 500 }
  }
}
