export function getPAGProps({ props, style }) {
  function getScaleRem(val, scale) {
    return Number(val?.replace('rem', '')) * Number(scale)
  }
  const w = getScaleRem(style.width, props.animationScale)
  const h = getScaleRem(style.height, props.animationScale)

  return { canvasWidth: w, canvasHeight: h, style: getCommonStyle() }
}

export function getSvgStyle({ animationScale, animation }) {
  const wh = Number(animationScale) * 100 + '%'
  return {
    'background-image': `url(${animation})`,
    'background-size': '100%',
    'background-position': 'center center',
    height: wh,
    width: wh,
    ...getCommonStyle()
  }
}

export function getSvgaStyle({ animationScale }) {
  // 不能使用 transform: scale 不然在 IOS 下很模糊的 bug
  return { width: Number(animationScale) * 100 + '%', ...getCommonStyle() }
}

export function getSvgaCoverStyle(uiMeta) {
  const { cover, image, img } = uiMeta.props
  return {
    'background-image': `url(${cover || image || img})`,
    'background-size': 'contain',
    'background-position': 'center center',
    height: '100%',
    width: '100%',
    ...getCommonStyle()
  }
}

function getCommonStyle() {
  return {
    'flex-shrink': 0,
    'pointer-events': 'none'
  }
}

export function getIsSuffix(url, suffix) {
  if (!url) {
    return false
  }
  return url && url.split('?')[0]?.endsWith(suffix)
}

export function getIsSvg(url) {
  return getIsSuffix(url, '.svg')
}
export function getIsSvga(url) {
  return getIsSuffix(url, '.svga')
}
export function getIsPAG(url) {
  return getIsSuffix(url, '.pag')
}
