import { getConfig } from './config'

export default function (
  {
    begin,
    end,
    format = {
      list: (i) => i,
      mine: (i) => i,
      luck: (i) => i,
      reward: (i) => i
    },
    utcOffset = 480,
    labelFormat = 'M月D日',
    today = '今日'
  } = {},
  l = '',
  t = ''
) {
  const {
    lib: { dayjs, utc },
    modules: {
      newRank: { request }
    }
  } = getConfig()

  utcOffset = utc || '+08:00'
  // 兼容老代码
  labelFormat = l || labelFormat
  today = t || today

  const mixinOpt = {
    data() {
      return {
        amNewRankReward: null,
        amNewRankPublicity: null,
        amNewRank: {}
      }
    },

    computed: {
      toDayString() {
        return dayjs(this.amBasic.time).utcOffset(utcOffset).format('YYYYMMDD')
      },

      amNewRankDays() {
        let dayOptions = []

        let beginDate = dayjs(begin).utcOffset(utcOffset).valueOf()
        let endDate = dayjs(end).utcOffset(utcOffset).valueOf()
        let prevStatus = -1

        while (beginDate <= endDate) {
          const push = {
            label: dayjs(beginDate).utcOffset(utcOffset).format(labelFormat),
            date: dayjs(beginDate).utcOffset(utcOffset).format(labelFormat),
            value: dayjs(beginDate).utcOffset(utcOffset).format('YYYYMMDD')
          }
          if (prevStatus === 0) {
            prevStatus = 1
          } else if (push.value === this.toDayString) {
            push.label = today
            prevStatus = 0
          }
          push.status = prevStatus
          dayOptions.push(push)
          beginDate += 24 * 60 * 60 * 1000
        }

        if (utcOffset === '+03:00') {
          dayOptions = dayOptions.map((i) => {
            return { ...i, label: this.getTimeDesc(i.label) }
          })
        }

        return dayOptions
      },

      amRankUse() {
        return this.amNewRankUse
      },

      amNewRankUse() {
        return Object.keys(this.amNewRank).reduce((obj, name) => {
          const { bonus = 0, list, luck, mine, length } = this.amNewRank[name]
          const rank = [...Array(length)].map((n, i) => format.list({ name, type: 'list', index: i, item: list[i], bonus }, this.uiMeta))
          obj[name] = {
            bonus,
            list: rank,
            list1_3: rank.slice(0, 3),
            list4_10: rank.slice(3),
            luck: [...Array(2)].map((n, i) => format.mine({ name, type: 'luck', item: luck[i], bonus }, this.uiMeta)),
            mine: format.luck({ name, type: 'mine', item: mine, bonus }, this.uiMeta)
          }
          return obj
        }, {})
      }
    },

    methods: {
      async amNewRankListenBonus({ name = '', type = 'day' } = {}, time = 1000) {
        const toDayValue = dayjs(this.amBasic.time).utcOffset(utcOffset).format('YYYYMMDD')
        const toDayOpt = this.amNewRankDays.find((i) => i.value === toDayValue)

        if (!toDayOpt) {
          return
        }

        name = name || type + toDayOpt.value

        let i = 0

        while (i++ < 1000) {
          await new Promise((resolve) => {
            request.getRankJackpot({ ...this.amApiParam, day: toDayOpt.value }).then((n) => {
              this.amNewRank[name].bonus = n
              setTimeout(resolve, time)
            })
          })
        }
      },

      async amNewRankGetMineyReward() {
        if (this.amNewRankReward) {
          return
        }

        this.$set(
          this,
          'amNewRankReward',
          (await request.getMineRankData(this.amApiParam)).map((i) => format.reward(i))
        )
      },

      async amNewRankGetPublicity() {
        if (this.amNewRankPublicity) {
          return
        }

        const publicity = []

        for (let { label, value } of this.amNewRankDays.filter((i) => i.status === -1)) {
          const { list, luck } = await request.getRankResults({
            ...this.amApiParam,
            day: value
          })

          publicity.push({
            date: label,
            list: list.map((i, index) => format.list({ index, name: 'day', type: 'list', item: i }, this.uiMeta)),
            luck: luck.map((i) => format.luck({ name: 'day', type: 'luck', item: i }, this.uiMeta))
          })
        }

        this.$set(
          this,
          'amNewRankPublicity',
          publicity.filter(({ list, luck }) => list.length && luck.length)
        )
      },

      async amNewRankGet({ cache = true, name = '', value = '', bonus = false, params = {}, fields = '', period_type = '0', rank_type = 'send_gift' }) {
        value = value || dayjs(this.amBasic.time).utcOffset(utcOffset).format('YYYYMMDD')
        name = name || (period_type ? 'day' + value : 'total')

        if (this.amNewRank[name] && cache) {
          return
        }

        if (!this.amNewRank[name]) {
          this.$set(this.amNewRank, name, {
            bonus: 0,
            list: [],
            luck: [],
            mine: {}
          })
        }

        const opt = { ...this.amApiParam, datetime: value }
        const dayOpt = this.amNewRankDays.find((i) => i.value === value)
        const status = dayOpt ? dayOpt.status : -1

        this.amNewRank[name].bonus = bonus && status !== 1 ? await request.getRankJackpot(opt) : 0
        this.amNewRank[name].length = params.limit || 10

        const [{ info }, rankData] = await Promise.all([
          request.getRankInfo({
            ...opt,
            period_type,
            fields,
            rank_type,
            ...params
          }),
          request.getRankList({
            ...opt,
            period_type,
            fields,
            rank_type,
            ...params
          })
        ])

        this.$set(this.amNewRank[name], 'mine', info)
        this.$set(this.amNewRank[name], 'list', rankData?.list)
      },

      getTimeDesc(label) {
        let desc = ''
        // replaceAll的顺序最好不要乱动，因为有可能前面的匹配把后面的匹配弄乱了
        desc = label
          .slice(0)
          .replaceAll('今日', 'اليوم')
          .replaceAll('11月', 'نوفمبر|')
          .replaceAll('12月', 'ديسمبر|')
          .replaceAll('1月', 'يناير|')
          .replaceAll('2月', 'فبراير|')
          .replaceAll('3月', 'مارس|')
          .replaceAll('4月', 'أبريل|')
          .replaceAll('5月', 'مايو|')
          .replaceAll('6月', 'يونيو|')
          .replaceAll('7月', 'يوليو|')
          .replaceAll('8月', 'أغسطس|')
          .replaceAll('9月', 'سبتمبر|')
          .replaceAll('10月', 'أكتوبر|')
          .replaceAll('日', '')
          .split('|')
          .reverse()
          .join(' ')
        return desc
      }
    }
  }

  return mixinOpt
}
