import { getQuery1 as getQuery } from '@/utils/utils'
import get from 'lodash/get'
import { methodsInit } from '../../utils'
import { amConfig } from '../config'
import { text } from './utils/config'
import event from '@sub/event'

const {
  modules: {
    team: { request }
  }
} = amConfig

const { getGetters: getBasicGetters } = methodsInit('base')
const { getState: getContactState } = methodsInit('contact')

export default {
  namespaced: true,

  state: {
    // 队伍信息
    teamInfo: { details: [] },
    // 队伍名
    teamName: '',
    // 是队名否修改
    isModify: false,
    // 编辑队名绑定
    inputTeamName: '',
    // 邀请人列表
    inviteList: [],
    // 邀请人信息
    inviteUserInfo: {},
    // 邀请人Uid
    sourceUser: 0,
    extra: {
      // 队伍人数
      teamNum: 2,
      // 默认值
      normalTeamName: '',
      normalNickname: '',
      normalAvatar: '',
      teamInviteIcon: ''
    },

    // 打开编辑弹窗回调
    openEdit: () => {},
    // 打开最近联系人弹窗回调
    openSearchList: () => {},
    // team初始化
    teamInit: () => {},
    // 更新teamInfo totalValue
    updateTotalValue: () => {}
  },

  getters: {
    // userInviteList() {
    //   return this.inviteList
    //     .map((item) => ({ ...item, ...this.amContact.list.find((u) => u.uid === item.uid) }))
    //     .filter((item) => item.uid !== this.amBasic.user.uid)
    //     .filter((item) => (this.searchVal ? item.nickname.indexOf(this.searchVal) !== -1 : item))
    // },

    teamMembers(state) {
      const {
        teamInfo,
        extra: { teamNum, normalAvatar }
      } = state
      return new Array(teamNum).fill({}).map((item, index) => teamInfo.details[index] || { nickname: text.normalNickname, head_img_url: normalAvatar })
    },

    teamInfoMembers(state) {
      const {
        teamInfo,
        extra: { teamNum, teamInviteIcon }
      } = state
      return new Array(teamNum).fill({}).map((item, index) => teamInfo.details[index] || { nickname: text.normalNickname, head_img_url: teamInviteIcon })
    }
  },

  mutations: {
    // 初始化一些参数
    initDefault(state, { teamNum = 2, normalTeamName, normalAvatar }) {
      state.sourceUser = get(getQuery(), 'send_uid', 0)
      Object.assign(state.extra, {
        teamNum,
        normalTeamName,
        normalAvatar,
        normalNickname: text.normalNickname
      })
    },

    setNomalAvatar(state, normalAvatar) {
      state.extra = {
        ...state.extra,
        normalAvatar
      }
    },

    setTeamInviteIcon(state, teamInviteIcon) {
      state.extra.teamInviteIcon = teamInviteIcon
    },

    setTeamInfo(state, teamInfo) {
      state.teamInfo = teamInfo
    },

    setTeamName(state, teamName) {
      const { normalTeamName } = state.extra
      state.teamName = teamName || normalTeamName

      state.isModify = !!teamName
    },

    setInviteUserList(state, inviteList) {
      state.inviteList = [...inviteList]
    },

    setMethods(state, methods) {
      Object.assign(state, methods)
    }
  },

  actions: {
    async amGetTeamInfo({ state, commit }) {
      const amApiParam = getBasicGetters('amApiParam')
      const { teamNum } = state.extra

      const teamInfo = await request.getTeamInfo({ ...amApiParam, target_uid: amApiParam.uid, team_num: teamNum })
      teamInfo.total_num = teamInfo.details?.length

      commit('setTeamInfo', teamInfo)
    },

    amGetOtherTeamInfo({ state }, uid) {
      const amApiParam = getBasicGetters('amApiParam')
      const { teamNum } = state.extra
      return request.getTeamInfo({ ...amApiParam, target_uid: uid, team_num: teamNum })
    },

    // 修改队伍名
    async amGetTeamName({ commit }) {
      const amApiParam = getBasicGetters('amApiParam')
      const teamName = await request.getPetName(amApiParam)

      commit('setTeamName', teamName)
    },

    // 修改队伍名
    async amModifyTeamName({ commit }, name) {
      const amApiParam = getBasicGetters('amApiParam')
      await request.editPetName({ ...amApiParam, name })
      event.emitOnTeamInfoChange()
      commit('setTeamName', name)
    },

    // 获取可邀请的联系人
    async amGetInviteStatus({ state, commit }) {
      const amApiParam = getBasicGetters('amApiParam')
      const { contact } = getContactState('contact')
      const { teamNum } = state.extra
      const inviteList = await request.getMultiTeaSStatus({ ...amApiParam, uids: contact.map((item) => item.uid).join(','), team_num: teamNum })

      commit('setInviteUserList', inviteList)
    },

    // 邀请好友
    async amInviteFriend({ state, commit }, uid) {
      const amApiParam = getBasicGetters('amApiParam')
      const {
        extra: { teamNum },
        inviteList
      } = state
      await request.sendTeamInvite({ ...amApiParam, send_uid: amApiParam.uid, recv_uid: uid, team_num: teamNum })
      const idx = inviteList.findIndex((u) => u.uid === uid)
      inviteList[idx] = { ...inviteList[idx], status: 2 }

      commit('setInviteUserList', inviteList)
    },

    // 加入队伍
    async amJoinTeam({ dispatch, state }) {
      const amApiParam = getBasicGetters('amApiParam')
      const {
        extra: { teamNum },
        sourceUser
      } = state
      await request.acceptTeamInvite({ ...amApiParam, send_uid: sourceUser, recv_uid: amApiParam.uid, team_num: teamNum })
      dispatch('amGetTeamInfo')
      dispatch('amGetTeamName')
      event.emitOnTeamInfoChange()
    },

    // 拒绝队伍
    async amRejectTeam({ state }) {
      const amApiParam = getBasicGetters('amApiParam')
      const { sourceUser } = state
      await request.refuseTeamInvite({ ...amApiParam, send_uid: sourceUser })
    }
  }
}
