import { convertToImage } from './html2canvas'
import { PAGE_REGION } from '@/utils/env'

const err1 = { code: 500, msg: '缺失分享数据参数' }
const err2 = { code: 500, msg: '获取图片数据失败' }

export const shareMixin = {
  data() {
    return {
      initShareNum: 0,
      imgBlobData: ''
    }
  },

  methods: {
    // 分享打点
    doPoint(params) {
      this.$point.track('ClickH5', {
        act_id: this.amApiParam.act_id,
        url: `${window.location.origin}${window.location.pathname}`,
        url_path: window.location.pathname,
        ...params
      })
    },

    // 字段：title, desc, imgUrl, imgBase64, wespyDeeplink, link, success, cancel, topics, textConfig
    // 调用的doShare时候一定要传入link字段
    initShare(params) {
      this.initShareNum++
      const topics = this.formatTopicData(params.topics)
      const newParams = this.formatShareParams(params)
      return this.$we('setShareData', JSON.stringify({ ...newParams, ...topics }))
    },

    // 根据不同区服，删除topics，因为日韩的topic_ids和topics字段不能共存，日韩只保留topic_ids字段
    formatShareParams(params) {
      if (!params.topics) {
        return params
      }

      switch (PAGE_REGION) {
        case 'HW':
          break

        default:
          delete params.topics
          break
      }

      return params
    },

    // 根据不同区服的入参要求，调整topic信息
    formatTopicData(topics) {
      if (!topics) {
        return
      }

      let topicPrama = {}
      switch (PAGE_REGION) {
        case 'HW':
          topicPrama = topics
          break

        default:
          topicPrama = { topic_ids: topics.map((it) => it.topicId).join(',') }
          break
      }
      return topicPrama
    },

    // 分享页面，可以选择分享路径
    // share_type 0无意义 1微信朋友圈 2微信 3qq空间 4qq 5微博 6复制链接 7玩友圈 8最近联系人 9facebook 10line 11更多应用 12保存图片
    async doShare(share_data, { share_type, activity_id = String(this.amApiParam.act_id) } = {}, point_params, cache = false) {
      if (!share_data) {
        return Promise.resolve(err1)
      }

      if (!cache || this.initShareNum <= 0) {
        await this.initShare(share_data)
      }

      if (point_params) {
        this.doPoint(point_params)
      }

      return this.$we('doShare', JSON.stringify({ share_type, activity_id }))
    },

    // 分享页面，直接分享到玩友圈
    async sharePageToCircle(share_data, point_params, cache = false) {
      if (!share_data) {
        return Promise.resolve(err1)
      }

      if (!cache || this.initShareNum <= 0) {
        await this.initShare(share_data)
      }

      if (point_params) {
        this.doPoint(point_params)
      }

      return this.$we('shareDirect', JSON.stringify({ share_dest: 1, media_type: 2 }))
    },

    // 分享话题到广场（限日韩）
    async sharePageToSquare(share_data, point_params, cache = false) {
      if (!share_data) {
        return Promise.resolve(err1)
      }

      if (!cache || this.initShareNum <= 0) {
        await this.initShare(share_data)
      }

      if (point_params) {
        this.doPoint(point_params)
      }

      return this.$we('shareDirect', JSON.stringify({ share_dest: 1, media_type: 3 }))
    },

    // 获取图片base64数据
    // 注意：模糊图片需要把bg-i的组件都换成a-img(src="")的方式
    async getImgBlobData(el, cache, imgBlobData) {
      if (!el && !imgBlobData) {
        return Promise.resolve()
      }

      if (imgBlobData) {
        this.imgBlobData = imgBlobData
      } else if (!cache || !this.imgBlobData) {
        console.time()
        this.imgBlobData = await convertToImage(el)
        console.timeEnd()
      }

      return Promise.resolve(this.imgBlobData)
    },

    // 分享图片，可以选择分享路径
    async shareImage({ el, point_params, cache = false, imgBlobData = '' }) {
      const data = await this.getImgBlobData(el, cache, imgBlobData)
      if (!data) return Promise.resolve(err2)

      if (point_params) {
        this.doPoint(point_params)
      }

      return this.$we('shareImage', this.imgBlobData)
    },

    // 分享图片，直接分享到玩友圈
    async shareImgToCircle({ el, point_params, cache = false, imgBlobData = '', extra = {} }) {
      console.log('el', el)
      const data = await this.getImgBlobData(el, cache, imgBlobData)
      console.timeEnd('生成图片需要的时间')
      if (!data) return Promise.resolve(err2)

      if (!cache || this.initShareNum <= 0) {
        await this.initShare({ imgBase64: this.imgBlobData, ...extra })
      }

      if (point_params) {
        this.doPoint(point_params)
      }

      return this.$we('shareDirect', JSON.stringify({ share_dest: 1, media_type: 1 }))
    },

    // 保存图片到相册
    async saveImage2Album({ el, cache = false, imgBlobData = '' }) {
      const data = await this.getImgBlobData(el, cache, imgBlobData)
      if (!data) return Promise.resolve(err2)

      return this.$we('saveImage2Album', { base64: this.imgBlobData })
    }
  }
}
