import getQuery from './getQuery'
import point from '@/lib/page-point'

/**
 *
 * @param {string} type 触发的事件类型
 * @param {string} value 触发的事件的具体对象值
 * @param {object} extra 额外参数
 *  @param {string} extra.btn_name_alias 触发的事件类型的别名
 *  @param {string} extra.screen_name
 */
export function logData(type, value, extra = {}) {
  try {
    const { btn_name_alias, screen_name } = extra
    const { act_id } = getQuery()

    point.track('ClickH5', {
      url: getUrl(),
      act_id,
      activity_id: act_id,
      btn_name: type,
      btn_value: value,
      btn_name_alias,
      screen_name
    })
  } catch (e) {
    console.warn('[ui-runtime/core/utils/logData][logData] has Error: ', e)
  }
}

function getUrl() {
  const { pageKey } = getQuery()
  return `${window.location.origin}${window.location.pathname}?pageKey=${pageKey}`
}
