<template lang="pug">
a-section
  swiper.topic(v-if='topics.length', :options='swiperOptions', :style='`height: ${swiperHeight}rem`')
    .swiper-pagination(slot='pagination')
    swiper-slide(v-for='(topic, i) in topics', :key='`topic${i}`', style='width: 5.38rem; height: 6.84rem')
      topic-item(:item='topic', :uiMeta='uiMeta', w='100%', bdr='0.18')
      topic-footer(:item='topic', :data='topic.footer', :uiMeta='uiMeta', :bottomTextColor='bottomTextColor')

  a-section(v-else, :bg-i='emptyImage', pc='x', pt='3.75')
  a-section.prev(wh='.5 .68', pl='.42', pt='5.63')
  a-section.next(wh='.5 .68', pr='.42', pt='5.63')
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import getQuery from '@sub/ui-runtime/core/utils/getQuery'
import { IS_WEPLAY } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'
import { showPaginationNum } from '../utils/config'

// 话题组件
import topicItem from './topic-item'
import topicFooter from './topic-footer'

const emptyMap = proxyAssets({
  HW: 'https://fe-center.afunapp.com/page-center/assets/nXBRMq1s/qfVNMSp6.png?_w=431&_h=430',
  J: 'https://fe-center.weplayapp.com/page-center/assets/DTB9u8Qd.webp?_w=430&_h=430'
})

export default {
  components: { topicItem, topicFooter, Swiper, SwiperSlide },

  props: {
    topics: {
      type: Array,
      default: () => []
    },

    uiMeta: {
      type: Object,
      default: () => {}
    },

    context: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      mySwiper: null,
      flag: true
    }
  },

  computed: {
    emptyImage() {
      const region = this.context?.region

      return IS_WEPLAY ? emptyMap[region] : emptyMap.HW
    },

    swiperHeight() {
      const indicatorType = this.uiMeta?.props?.indicatorType || 'default'

      return indicatorType === 'custom' ? 7.44 : 7.24
    },

    swiperOptions() {
      const that = this

      return {
        autoplay: { disableOnInteraction: false },
        loop: this.topics.length !== 1,
        navigation: {
          nextEl: '.next',
          prevEl: '.prev'
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',

          renderCustom: function (swiper, current, total) {
            const indicatorType = that.uiMeta?.props?.indicatorType || 'default'

            that.mySwiper = swiper

            var customPaginationHtml = ''
            const { minIndex, maxIndex } = that.getShowPaginationIndex(total, current)
            for (var i = minIndex; i < maxIndex; i++) {
              // 判断哪个分页器此刻应该被激活
              if (i == current - 1) {
                customPaginationHtml +=
                  indicatorType === 'custom'
                    ? `<span class="swiper-pagination-customs swiper-pagination-customs-active" index="${i + 1}" style="background-image: url(
                      ${that.indicatorPicActive})"></span>`
                    : `<span class="swiper-pagination-bullet swiper-pagination-bullet-active" index="${i + 1}" style="background: ${that.indicatorColorActive}"></span>`
              } else {
                customPaginationHtml +=
                  // eslint-disable-next-line prettier/prettier
                  indicatorType === 'custom' ? `<span class="swiper-pagination-customs" index="${i + 1}" style="background-image: url(${that.indicatorPic})"></span>` : `<span class="swiper-pagination-bullet" index="${i + 1}" style="background: ${that.indicatorColor}"></span>`
              }
            }
            return customPaginationHtml
          },
          clickable: true
        },

        on: {
          tap: function () {
            if (that.flag) {
              setTimeout(() => {
                that.clickTopicItem(that.topics[this.realIndex])
                that.flag = true
              }, 400)
            }
            that.flag = false
          }
        }
      }
    },

    indicatorColor({ uiMeta }) {
      return uiMeta?.props?.indicatorColor
    },

    indicatorColorActive({ uiMeta }) {
      return uiMeta.props.indicatorColorActive
    },

    indicatorPic({ uiMeta }) {
      return uiMeta?.props?.indicatorPic
    },

    indicatorPicActive({ uiMeta }) {
      return uiMeta?.props?.indicatorPicActive
    },

    bottomTextColor({ uiMeta }) {
      return uiMeta?.props?.bottomTextColor || '#6FA8FF'
    }
  },

  mounted() {
    setTimeout(() => {
      const dom = document.getElementsByClassName('swiper-pagination')[0]

      if (!dom) {
        return
      }

      dom.addEventListener('click', this.handleClick, false)
    }, 2000)
  },

  destroyed() {
    const dom = document.getElementsByClassName('swiper-pagination')[0]
    if (!dom) {
      return
    }

    dom.removeEventListener('click', this.handleClick, false)
  },

  methods: {
    getShowPaginationIndex(total, current) {
      let minIndex
      let maxIndex
      if (total <= showPaginationNum) {
        minIndex = 0
        maxIndex = total
      } else {
        const half = Math.floor(showPaginationNum / 2)
        if (current <= half) {
          minIndex = 0
          maxIndex = showPaginationNum
        } else if (current >= total - half) {
          minIndex = total - showPaginationNum
          maxIndex = total
        } else {
          minIndex = current - half - 1
          maxIndex = current + half
        }
      }

      return { minIndex, maxIndex }
    },

    handleClick(e) {
      // 获取目标元素，拿到目标元素上的 index 值
      const current = e.target
      const toCount = current.attributes['index']?.value || ''
      // 跳转到指定的 swiper 页面
      if (toCount) {
        this.mySwiper.slideTo(toCount)
      }
    },

    clickTopicItem(item) {
      const { act_id } = getQuery()

      this.$point.track('ClickH5', {
        url: `${window.location.origin}${window.location.pathname}`,
        act_id,
        btn_name: 'click_topic_item',
        topic_id_list: item.topic.titleList.map((item) => item.topic_id)
      })

      this.$we('jumpDeepLink', `wespydeeplink://discover_post_detail?post_id=${item.dynamic.id}&uid=${item.user.uid}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.topic {
  width: 5.38rem;
  position: absolute;
  left: 50%;
  top: 2.69rem;
  transform: translateX(-50%);
  border-radius: 0.18rem;
  overflow: hidden;
}
.swiper-pagination {
  bottom: 0rem;
}
.prev,
.next:focus {
  outline: none;
}
</style>

<style lang="scss">
.swiper-pagination-bullet {
  display: inline-block;
  width: 0.14rem;
  height: 0.14rem;
  opacity: 1;
  margin-right: 0.16rem;
}

.swiper-pagination-bullet-active {
}

.swiper-pagination-customs {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.16rem;
}

.swiper-pagination-customs-active {
  width: 0.34rem;
  height: 0.34rem;
}
</style>
