<template lang="pug">
a-section.ui-recharge-progress
  a-section.ui-recharge-progress__wrapper(v-bind='$attrs', wh='7.5 4.82')
    ui-cumulative.ui-recharge-progress__wrapper__cumulative(v-bind='$attrs', :nodeList='nodeList', :val='rechargeAmount', :height='4.86')

    //- 奖励展示部分
    a-position.ui-recharge-progress__wrapper__box-list(v-for='(item, i) in rechargeGiftList', :key='i', :bg-i='images[getStateStr(i)][item.bgImg]', bg-s='100%', v-bind='item.domPosition')
      //- 底部的点状态icon
      a-position.ui-recharge-progress__wrapper__box-list__icon(:bg-i='images[getStateStr(i)].node', bg-s='100%', v-bind='item.iconPosition || defaultConfig.iconPosition')
      //- 累计数值展示
      a-position.ws-nowrap.ui-recharge-progress__wrapper__box-list__value(v-bind='item.textPosition || defaultConfig.textPosition', :fc='customizeStyle.stageValueFontColor', :fs='customizeStyle.stageValueFontSize || 0.2', h='0.42', lh='0.42', pz='1', v-html='getStageValueStr(i)')

      //- 奖励展示
      a-position.fx-row.fx-m-between.ui-recharge-progress__wrapper__box-list__gift-list-wrapper(v-bind='rewardsPositionConfig.rewardsWrap[item.wrapKey || item.rewardNum]')
        a-section.ui-recharge-progress__wrapper__box-list__gift-list-wrapper__item(v-for='(r, idx) in item.rewards', :key='idx', wh='.89')
          template(v-if='r')
            //- 称号背景
            a-position(v-if='+r.type === 999', bg-c='#F8F9FFD9', wh='.66', bdr='50%', pc)
            //- 常规奖励背景
            a-position(v-else-if='!hideBgType.includes(+r.type)', wh='100%', bg-c='#fff', bdr='.17', pc)
            //- 奖励展示
            q-rewards(:uiMeta='genQRewardsUiMeta(r)', wh='100%', pc)
            //- 角标
            span(:data-var='(textConfig = textStyle[getStateStr(i)])')
            a-border-text1.ws-nowrap(v-bind='rewardsPositionConfig.subscript', :fc='textConfig.subscriptColor', :borderColor='textConfig.subscriptBorderColor', :borderContent='r.unit', borderWidth='2')
            //- 奖励名称
            a-position.txt-c(v-bind='{ ...rewardsPositionConfig.rewardName[item.rewardNum], fs: customizeStyle.rewardNameFontSize }', :fc='textConfig.rewardNameColor', :style='rewardsPositionConfig.rewardNameCommonStyle')
              a-position(v-bind='genRewardNameStyle()', pc, v-html='getRewardName(r)')
</template>

<script>
import get from 'lodash/get'
import proxyAssets from '@sub/utils/proxy-assets'

import { stateMap } from './utils/rechargeWrapperConfig'
import { rewardsPositionConfig } from './utils/rechargePositionConfig'
import { rewardsPositionConfigA } from './utils/rechargePositionConfigA'
import qRewards from '@sub/ui-runtime/core/components/base/q-rewards.vue'

export default {
  components: {
    qRewards
  },

  props: {
    // 累计任务id
    taskId: {
      type: Number,
      default: 1
    },

    uiMeta: {
      type: Object,
      default: () => {}
    },

    // 奖励定位类型
    // 传入String选择已有的奖励定位配置
    // 传入Object使用新的奖励定位配置
    positionType: {
      type: [String, Object],
      default: ''
    },

    // 节点列表
    nodeList: {
      type: Array,
      default: () => []
    },

    // 颜色配置
    colorConfig: {
      type: Object,
      default: () => {}
    },

    // 等同于ui-prop里面的extra
    extra: {
      type: Object
    },

    hideBgType: {
      type: Array,
      default: () => []
    },

    showSvgaType: {
      type: Array,
      default: () => []
    },

    allText: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    rewardsPositionConfig() {
      return this.isAr ? rewardsPositionConfigA : rewardsPositionConfig
    },

    images({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.textStyle', {})
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {})
    },

    amApiParam() {
      return this.$amApiParam()
    },

    isAr() {
      return this.$route.query?.region === 'A'
    },

    positionConfig() {
      return typeof this.positionType === 'string' ? this[this.positionType] : this.positionType
    },

    defaultConfig() {
      return this.positionConfig.defaultConfig
    },

    rechargeGiftList() {
      return this.positionConfig.rechargeGiftList
    },

    levelTaskInfo() {
      return this.$store.state.task.levelTasksInfo[this.taskId]
    },

    // 值
    rechargeAmount() {
      return this.levelTaskInfo.value || 0
    },

    // 累充状态
    stageStatus() {
      if (this.levelTaskInfo.stages) {
        return this.levelTaskInfo.stages
      } else {
        return new Array(this.nodeList.length).fill({ state: 0 })
      }
    },

    levelTaskConfig() {
      return this.$task.taskConfig()[this.taskId]
    },

    triggerType() {
      return this.levelTaskConfig?.condition_config?.condition_list?.[0]?.trigger?.type
    }
  },

  created() {
    this.$store.commit('task/initLevelTaskItemDefault', this.taskId)
  },

  methods: {
    getStateStr(index) {
      return stateMap[this.stageStatus[index]?.state || 0]
    },
    genQRewardsUiMeta({ rewardSlot }) {
      return {
        props: {
          rewardSlot,
          hidden: false,
          showSvga: true
        }
      }
    },

    getStageValueStr(index) {
      // 数值处理：
      let value = Number(this.stageStatus[index]?.condition_map?.[1] || 0)
      // 数值每三位加一个英文逗号
      if (['K'].includes(this._region)) {
        value = value.toLocaleString('en-US')
      }
      // 数值以 k 为单位
      if (['A'].includes(this._region)) {
        value = value < 1000 ? value : value / 1000 + 'k'
      }

      // 后缀处理：
      // 后缀是金币 icon
      const suffixIsCoin = ['cost_coin', 'charge'].includes(this.triggerType)
      if (suffixIsCoin && ['J', 'M', 'P', 'U', 'B'].includes(this._region)) {
        return getCoinHtml(value)
      }
      // 后缀是金币文案
      if (suffixIsCoin && ['HW', 'C', 'Q', 'K'].includes(this._region)) {
        return `${value}${this.allText.coin || ''}`
      }

      return value
    },

    getRewardName({ name, type_name = '', type, cate }) {
      let nameWithoutType = name?.replace(type_name, '') || ''

      // 碎片类型直接展示 name
      if (cate === 'collect_chip') {
        return name
      }
      // 金币、红包类型直接展示 name
      if ([5, 22].includes(type)) {
        return name
      }

      // 奖励全称
      if (['Q'].includes(this._region)) {
        return nameWithoutType
      }

      // 上奖励全称，下奖励类型
      if (['J'].includes(this._region)) {
        // 称号只展示类型
        if (type === 999) {
          return type_name
        }
        return name
      }

      // 奖励名+类型
      if (['HW', 'C'].includes(this._region)) {
        return nameWithoutType + type_name
      }

      // rewardMeta 中的 type_name
      return type_name
    },

    genRewardNameStyle() {
      // if (['J'].includes(this._region)) {
      //   return {}
      // }

      return {
        w: '100%'
      }
    }
  }
}

function getCoinHtml(value) {
  const coinImg = proxyAssets('https://fe-center.weplayapp.com/page-center/assets/NQwULu9R.png?_w=49&_h=48')
  return `<div class='fx-row fx-c-center'><div>${value}</div><img src=${coinImg} style='width: 0.24rem; height: 0.24rem; margin: 0 0.02rem;'></img></div>`
}
</script>

<style lang="scss" scoped></style>
