import Vue from 'vue'
import { isFunction, get } from 'lodash'
/**
 * 自定义生命周期类
 */

class LifeCycleCreator {
  // 自定义生命周期名称
  static lifeCycles = {
    // vuex base模块初始化后触发
    afterInit: 'afterInit',
    // 页面显示
    onPageShow: 'onPageShow',
    // 页面隐藏
    onPageHide: 'onPageHide'
  }

  // 触发单个生命周期
  static emitLifeCycle(vm, lifeCycleName, payLoad) {
    lifeCycleName = LifeCycleCreator.lifeCycles[lifeCycleName]
    const callback = get(vm, `$options.${lifeCycleName}`, null)
    isFunction(callback) && callback.call(vm, payLoad)
  }

  // 遍历Vue
  static traverseVue(vm, lifeCycleName, payLoad) {
    vm.forEach((item) => {
      LifeCycleCreator.emitLifeCycle(item, lifeCycleName, payLoad)
      if (item?.$children?.length) {
        LifeCycleCreator.traverseVue(item.$children, lifeCycleName, payLoad)
      }
    })
  }

  constructor() {
    this.bindVisibilityChange()
  }

  /**
   * @description 触发所有组件生命周期
   * @param { String } lifeCycleName 生命周期名称
   * @param { Object } payLoad 传给生命周期的参数
   */
  emitLifeCycles(lifeCycleName, payLoad = {}) {
    const vm = Vue.prototype.$app
    LifeCycleCreator.traverseVue([vm], lifeCycleName, payLoad)
  }

  // 页面onPageShow、onPageHide事件监听
  bindVisibilityChange() {
    let prevIsHide = false
    document.addEventListener('visibilitychange', () => {
      const isHide = document.hidden
      if (prevIsHide && !isHide) {
        this.emitLifeCycles('onPageShow')
      } else if (!prevIsHide && isHide) {
        this.emitLifeCycles('onPageHide')
      }

      prevIsHide = isHide
    })
  }
}

export default new LifeCycleCreator()
