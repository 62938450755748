<template lang="pug">
a-section(v-if='propList.length > 0', :wh='wh', v-bind='$attrs')
  a-position(v-for='(id, i) in list', v-show='id', :key='i', v-bind='propList[i]', @a-tap='$propPreview(id)')
  slot
</template>

<script>
import { propMap, whMap } from './config'

export default {
  props: {
    // id列表
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {}
  },

  computed: {
    wh() {
      return whMap[this.list.length]
    },

    propList() {
      return propMap[this.list.length] || []
    }
  }
}
</script>

<style lang="scss" scoped></style>
