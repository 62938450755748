import point from '@wepie/page-point/lib/ta'
import { getQuery1 } from '@/utils/utils'
import * as iframe from '@sub/ui-runtime/core/iframe'

import config from '@/config'

import { APP_VERSION, PAGE_REGION, PAGE_LANG } from '@/utils/env'

export function install(app) {
  if (config.point) {
    const { debug } = getQuery1()
    if (debug === 'true') {
      config.point.showLog = true
    } else {
      config.point.showLog = false
    }

    app.use(point, {
      version: 2,
      config: {
        ...config.point,
        batch: {
          size: 5, //当次批量发送数据的最大值，默认5条
          interval: 5000, //间隔多少毫秒，立即发送，默认5S
          storageLimit: 200 //本地最多缓存的数据条数，默认为200条
        }
      }
    })

    point.setSuperProperties({
      url: window.location.href,
      url_path: window.location.pathname,
      app_version: APP_VERSION,
      region: PAGE_REGION,
      lang: PAGE_LANG
    })
  }
}

export default point
