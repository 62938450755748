<template lang="pug">
a-section(:bg-i='image.bg')
  //- 兑换记录按钮
  slot(name='record')
  //- 兑换币数量
  slot(name='chipNum')
  //- 兑换物品容器
  a-position.fx-row.fx-wrap.fx-m-start.fx-cl-start(v-bind='{ ...exchangeWrapperStyle, ...customizedStyle.exchangeWrapperStyle }')
    a-section(v-for='(item, index) in exchangeList', :key='`exchangeItem${index}`', wh='2.18 2.9', mb='0.34')
      //- 奖励背景图&脚标
      a-position(:bg-i='image.rewardBg', pc='x', wh='1.54')
        ui-prop(:data='$propsConfig(item.id)', wh='1.32', pc)
        a-position(:bg-i='item.tagImage', pt='-0.17', pl='-0.15', bg-s='100%')
        a-border-text1(:borderContent='$getPropUnit(item.id, item.num)', v-bind='{ ...exchangeUnitStyle, ...customizedStyle.exchangeUnitStyle }')
      //- 奖励名称
      a-position(v-bind='{ ...exchangeNameStyle, ...customizedStyle.exchangeNameStyle }', v-html='isA ? $propsConfig(item.id)?.name4 : $propsConfig(item.id)?.name')
      //- 奖励价格&背景图
      a-position.fx-row.fx-m-center.fx-c-center(:bg-i='image.priceBg', pc='x', pt='2.12', bg-s='100%')
        a-section(:bg-i='image.exchangeChipIcon', bg-s='100%')
        a-section(v-bind='{ ...priceContentStyle, ...customizedStyle.priceContentStyle }', v-html='isA ? `${item.price}x` : `x${item.price}`')
      //- 兑换按钮
      a-button1(:bg-i='image.exchangeButton', v-bind='{ ...exchangeButtonStyle, ...customizedStyle.exchangeButtonStyle }', bg-s='100%', :class='{ grey: !canExchange(item) }', @a-tap='handleExchange(item)')
</template>

<script>
import { isA } from '@sub/ui-components/utils/config'

import get from 'lodash/get'
import { exchangeStyleConfig } from './utils/config'

export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      ...exchangeStyleConfig,
      isA
    }
  },

  computed: {
    chipInfo() {
      return this.$base.chipInfo()
    },

    // 图片
    image({ uiMeta }) {
      const image = get(uiMeta, 'props.image', {})
      return { ...image }
    },

    // 兑换商品列表
    exchangeList({ uiMeta }) {
      return get(uiMeta, 'props.exchangeList', {})
    },

    // 自定义样式
    customizedStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizedStyle', {})
    },

    //- 兑换配置
    exchangeConfig({ uiMeta }) {
      return get(uiMeta, 'props.exchangeConfig', {})
    },

    // 兑换券数量
    exchangeChip({ uiMeta }) {
      const exchangeChipId = get(uiMeta, 'props.exchangeConfig.chipId', 0)
      return this.chipInfo[exchangeChipId] || 0
    },

    // 是否能兑换
    canExchange() {
      return function (item) {
        return this.exchangeChip >= item.price
      }
    }
  },

  methods: {
    handleExchange(item) {
      if (this.exchangeChip < item.price) {
        return this.$toast(this.exchangeConfig.chipLackText)
      }
      this.$emit('exchange', item)
    }
  }
}
</script>
