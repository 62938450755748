import { amConfig } from '../config'
import throttle from 'lodash/throttle'

const {
  modules: {
    purchase: { request }
  }
} = amConfig

export default {
  namespaced: true,

  state: {
    // store_id => item_list
    storeConfig: {}
  },

  getters: {},

  mutations: {
    setState(state, payload = {}) {
      Object.assign(state, payload)
    }
  },

  actions: {
    getStoreInfoList: throttle(
      async function ({ commit, state }) {
        const res = await request.getDirectStoreInfoList()
        Object.keys(res).forEach((store_id) => {
          commit('setState', { storeConfig: { ...state.storeConfig, [store_id]: res[store_id]?.item_list } })
        })
      },
      100,
      { trailing: false }
    ),

    getStoreInfo: throttle(
      async function ({ commit, state }, { store_id }) {
        const { item_list } = await request.getDirectStoreInfo({ store_id })
        commit('setState', { storeConfig: { ...state.storeConfig, [store_id]: item_list } })
      },
      0,
      { trailing: false }
    )
  }
}
