<template lang="pug">
a-section#ui-record.txt-c.ui-record(v-bind='attrs', :style='styleVar')
  a-dialog.ui-record__dialog(:name='dialogName', v-bind='{ wh: "6.54 7.16", ...getCustomizeStyle("recordStyle") }')
    template(slot-scope='scope', v-if='scope.data')
      slot(v-if='$scopedSlots["closeBtn"]', name='closeBtn')
      a-position.ui-record__dialog__close(v-else, :bg-i='image.close', v-bind='{ ...recordStyleConfig.closeBtn, ...getCustomizeStyle("closeStyle") }', @a-tap='$dialogs[dialogName].close()')
      a-position.fx-row.ui-record__dialog__close__tab-wrapper(v-if='tabNum > 1', v-bind='recordStyleConfig.tabWrapStyle')
        a-section.ui-record__dialog__close__tab-wrapper__item(v-for='item in tabsList', :key='item', :wh='`${100 / tabNum}% 100%`', @a-tap='onTabChange(item)')
      slot(name='btn')
      a-tabs.of-h.ui-record__dialog__close__content-wrapper(:items='tabsList', :active='tab', pc, v-bind='{ wh: "6.54 7.16", ...getCustomizeStyle("recordStyle") }')
        a-section.ui-record__dialog__close__content-wrapper__item(v-for='(tab, index) in tabsList', :key='tab', :slot='tab', :bg-i='image.dialogBgs[index]')
          //- 变量定义
          span(:data-var='(recordInfo = recordInfos[tab])')
          span(:data-var='(headerCount = headerNum[index])')
          span(:data-var='(itemStyle = recordStyleConfig.recordItem(headerCount))')
          slot(:name='tab', v-bind='{ data: recordInfo }')
          //- 记录列表
          slot(name='extra', v-bind='{ data: recordInfo }')
          a-list(v-bind='{ ...recordStyleConfig.recordWrap, ...getCustomizeStyle("recordWrap") }', ta)
            v-list(v-model='recordInfo.loading', :finished='recordInfo.finished', :loading-text='recordTextConfig.loading', :offset='10', @load='recordLoad', :immediate-check='false')
              template(v-if='!recordInfos[tab].list.length')
                a-position(w='5', v-if='!image.empty', pc, fs='.3') {{ recordTextConfig.empty }}
                a-position(pc, :bg-i='image.empty', bg-s='contain')
              a-section(v-for='(r, i) in recordInfos[tab].list', :key='i', v-bind='{ ...itemStyle.itemStyle, ...getCustomizeStyle("itemStyle") }')
                slot(v-if='$scopedSlots["listCustome"]', name='listCustome', v-bind='{ data: { data: r, tab } }')
                a-position(v-for='h in headerCount', :key='h', v-bind='{ ...itemStyle[`head${h}`], ...getCustomizeStyle(`customizeHead${h}`) }', :class='{ "txt-no-wrap": h === 1 }', v-else)
                  span(:data-var='(slotName = `${tab}-head${h}`)')
                  //- 具名插槽
                  slot(v-if='$scopedSlots[slotName]', :name='slotName', v-bind='{ data: r }')
                  a-position(v-else, w='100%', pc) {{ r[`head${h}`] }}
                slot(v-if='$scopedSlots["line"]', name='line')
                a-position(:bg-i='image.splitLine', v-bind='recordStyleConfig.lineStyle')
</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import VList from 'vant/lib/list'
import 'vant/lib/index.css'
import { commonImage, commonText } from '@sub/ui-components/utils/config'
import { recordStyleConfig, genRecordTextConfig } from './utils/config'

export default {
  mixins: [attrs],
  components: { VList },

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    },

    extra: {
      type: Object,
      default: () => {
        return {
          // 请求地址、参数 依据tabNum数量定
          requests: {
            tab1: { url: '', params: {} },
            tab2: { url: '', params: {} },
            tab3: { url: '', params: {} }
          }
        }
      }
    }
  },

  data() {
    return {
      commonImage,
      commonText,
      tab: 'tab1'
    }
  },

  computed: {
    recordTextConfig() {
      return genRecordTextConfig()
    },

    recordStyleConfig({ textStyle: { textColor }, tabWrapStyle, listWrapStyle, lineStyle }) {
      return recordStyleConfig({ textColor, tabWrapStyle, listWrapStyle, lineStyle })
    },

    tabsList({ tabNum }) {
      return new Array(tabNum).fill('').map((item, idx) => `tab${idx + 1}`)
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    tabNum({ uiMeta }) {
      return get(uiMeta, 'props.tabNum', 1)
    },

    headerNum({ uiMeta }) {
      return get(uiMeta, 'props.headerNum', [])
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.textStyle', {})
    },

    tabWrapStyle({ uiMeta }) {
      return get(uiMeta, 'props.tabWrapStyle', {})
    },

    listWrapStyle({ uiMeta }) {
      return get(uiMeta, 'props.listWrapStyle', {})
    },

    lineStyle({ uiMeta }) {
      return get(uiMeta, 'props.lineStyle', {})
    },

    subscriptStyle({ uiMeta }) {
      return get(uiMeta, 'props.subscriptStyle', {})
    },

    dialogName({ uiMeta: { key } }) {
      return key
    },

    requests({ extra }) {
      return get(extra, 'requests', {})
    },

    format({ extra }) {
      return get(extra, 'format', {})
    },

    recordInfos() {
      return this.$record.recordInfos()[this.dialogName]
    },

    styleVar({ textStyle }) {
      return {
        '--loadingColor': textStyle.loadingColor || '#c8c9cc'
      }
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {}) || {}
    }
  },

  created() {
    this.initDefault()
  },

  watch: {
    extra() {
      this.initDefault()
    }
  },

  methods: {
    initDefault() {
      this.$store.commit('record/initDefault', {
        recordName: this.dialogName,
        requests: this.requests,
        openRecord: this.openRecordDialog.bind(this),
        format: this.format
      })
    },

    async onTabChange(tab, refresh = false) {
      if (this.tab === tab && !refresh) {
        return
      }

      if (!this.recordInfos[tab].list?.length) {
        await this.recordLoad(tab)
      }
      this.tab = tab
      this.$record.updateRecordInfo({ recordName: this.dialogName, tab, value: { loading: false } })
    },

    recordLoad(tab) {
      return this.$store.dispatch('record/amRecordLoad', { recordName: this.dialogName, tab: tab || this.tab })
    },

    async openRecordDialog(tab) {
      tab = tab || this.tab
      document.body.classList.add('loading')
      try {
        await this.onTabChange(tab, true)
        document.body.classList.remove('loading')
        this.$dialogs[this.dialogName].open({
          options: { bgClose: true },
          onclose: this.closeRecord
        })
      } catch (error) {
        console.error(error)
        document.body.classList.remove('loading')
      }
    },

    async closeRecord() {
      this.$store.commit('record/resetRecordInfo', this.dialogName)
      this.tab = 'tab1'
    },

    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    }
  }
}
</script>

<style lang="scss">
#ui-record {
  .van-loading,
  .van-loading__text {
    color: var(--loadingColor) !important;
  }
}
</style>
