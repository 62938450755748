import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import localStorage from '@/utils/local-storage'

const textConfig = {
  HW: () => ({
    recvText: '领取次数: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: '领取成功',
    cantShareDirect: '请前往活动页面完成分享任务~'
  }),
  C: () => ({
    recvText: '領取獎勵: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: '領取成功',
    cantShareDirect: '請前往活動頁面完成分享任務~'
  }),
  J: () => ({
    recvText: '報酬受取: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: '受け取りました',
    cantShareDirect: 'イベントページへこのミッションをクリアしてください〜'
  }),
  K: () => ({
    recvText: '보상 수령: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: '수령 성공',
    cantShareDirect: '이벤트 페이지로 공유 미션을 완성하세요.'
  }),
  A: () => ({
    recvText: 'عدد الاستلام: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'تم الاستلام بنجاح',
    cantShareDirect: 'يرجى الانتقال إلى صفحة النشاط لإكمال مهمة المشاركة ~'
  }),
  ms: () => ({
    recvText: 'Menerima: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'Berjaya Diterima',
    cantShareDirect: 'Sila pergi ke laman event untuk menyelesaikan tugas kongsi~'
  }),
  id: () => ({
    recvText: 'Menerima: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'Berhasil Diterima',
    cantShareDirect: 'Silakan pergi ke halaman event untuk menyelesaikan tugas~'
  }),
  T: () => ({
    recvText: 'รับรางวัล: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'รับสำเร็จ',
    cantShareDirect: 'โปรดไปที่หน้ากิจกรรมเพื่อเสร็จภารกิจการแบ่งปัน~'
  }),
  U: () => ({
    recvText: 'Claimed times: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'Successful collection',
    cantShareDirect: 'Please go to the event page to complete the sharing task~'
  }),
  P: () => ({
    recvText: 'Collect: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'Successful collection',
    cantShareDirect: 'Please go to the event page to complete the sharing task~'
  }),
  Q: () => ({
    recvText: 'Alım fırsat: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'Ödül alındı',
    cantShareDirect: 'Lütfen etkinlik sayfasına giderek paylaşım görevini tamamlayınız~'
  }),
  V: () => ({
    recvText: 'Nhận thưởng: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'Đã nhận',
    cantShareDirect: 'Vui lòng vào trang sự kiện để hoàn thành nhiệm vụ chia sẻ'
  }),
  I: () => ({
    recvText: 'Claimed times: {{recvTimes}}/{{totalTimes}}',
    recvSuccess: 'Successful collection',
    cantShareDirect: 'Please go to the event page to complete the sharing task~'
  })
}

export const genDailyTaskTextConfig = (vueThis) => {
  if (IS_WEPLAY) {
    if (PAGE_REGION === 'M') {
      const storageKey = `__page_lang_${vueThis.$amApiParam().uid}__`
      const lang = localStorage.get(storageKey) || ''
      return textConfig[lang]
    } else {
      return textConfig[PAGE_REGION]
    }
  } else {
    return textConfig.HW
  }
}

// 华语服：領取獎勵（0/1）
// 英语：Claim the reward (0/1)
// 日服：報酬受取（0/1）
// 韩语服：보상 수령 (0/1)
// 阿语服：استلام المكافآت ({{recvTimes}}/{{totalTimes}})
// 泰语服：รับรางวัล (0/1)
// 马来语：Menerima (0/1)
// 印尼语：Menerima hadiah (0/1)
