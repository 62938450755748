<template lang="pug">
a-section(v-bind="$attrs" :bg-i='BGI')
  a-position(wh='6.9 1.7' pc)
    //- 排名 icon
    a-position(v-if='index<4' :bg-i='image[`scoreIcon${(index)}`]' pc="y" pl=".11")
    a-position(v-else pc="y" pl=".4" :f='`.22 ${fontColor("rank")} 600`') {{index}}

    //- 队伍名
    a-position.ellipsis(:f='`.24 ${fontColor()} 600`' pc='y' pl='.88' w='1.1') {{itemObj.teamName}}

    //- 队伍头像
    a-avatar(v-if="itemObj.user1" :bg-i='itemObj.user1.avatar || image.avatar1' :uid="itemObj.user1.uid" pt='0.27' pl='2' wh='.94')
      a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user1.nickname}}
      a-position(v-if="index < 4" :bg-i='image[`crown${(index)}`]' pt='-.2' pl='0')
    a-avatar(v-if="itemObj.user2" :bg-i='itemObj.user2.avatar || image.avatar2' :uid="itemObj.user2.uid" pt='0.27' pl='2.77' wh='.94')
      a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user2.nickname}}
    a-avatar(v-if="itemObj.user3" :bg-i='itemObj.user3.avatar || image.avatar1' :uid="itemObj.user3.uid" pt='0.27' pl='3.47' wh='.94')
      a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user3.nickname}}
    a-avatar(v-if="itemObj.user4" :bg-i='itemObj.user4.avatar || image.avatar2' :uid="itemObj.user4.uid" pt='0.27' pl='4.22' wh='.94')
      a-position.txt-c(:f='`.16 ${fontColor()} 600`' w='90%' pc="x" pt='1') {{itemObj.user4.nickname}}

    //- 值
    a-position(:bg-i="getRankIcon()" pc="y" pl="5.55")
    a-position(pt=".71" pl="5.93" :f='`.24 ${fontColor("value")} 600`') {{props.notCompress ? itemObj.value1 : itemObj.value}}
   
</template>

<script>
const DEFAULT_ITEM = {
  value: 0,
  user1: {},
  user2: {},
  user3: {},
  user4: {},
  teamName: ''
}

import fontColor from '../utils/fontColor'

export default {
  props: {
    itemObj: {
      type: Object,
      default: () => DEFAULT_ITEM
    },
    image: {
      type: Object,
      default: () => {}
    },
    textStyle: {
      type: Object,
      default: () => {}
    },
    index: {
      type: [Number, String],
      default: 1
    },
    props: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    BGI() {
      if (this.index < 4) {
        return this.image[`top${this.index}Item`]
      }
      return this.image.normalItem
    }
  },

  mounted() {},

  methods: {
    getRankIcon() {
      if (this.index < 4) {
        return this.image[`rankIcon${this.index}`]
      } else {
        return this.image.rankIconOther
      }
    },
    fontColor(type) {
      const { textStyle, index } = this
      return fontColor({ textStyle, index, type })
    }
  }
}
</script>

<style lang="scss"></style>
