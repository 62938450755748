import Vue from 'vue'

import { USE_LANGS } from '@/utils/env'

const PAGE_TITLE = '$title'
const PAGE_IMG = '$img'

const replaceT = (s, t) => [].concat(t).reduce((str, v, i) => str.replace(new RegExp(`\\[T${i + 1}\\]`, 'g'), v), s)
const replaceP = (path) => path.replaceAll('/', '@')
const replaceI = (path) => {
  const arrs = path.split('/')

  if (arrs[0] === '.') {
    arrs[0] = PAGE_IMG
  } else {
    arrs.unshift(PAGE_IMG)
  }

  arrs.push(arrs.pop().replace(/\./g, '_'))

  return arrs.join('_')
}

// getLangSettings 从配置文件获取当前页面的多语言配置对象
function getLangSettings(files) {
  return files.keys().reduce((langs, key) => {
    const [title = '\u200e', imageFiles, text = {}] = files(key).default
    const [locale] = key.split('/').pop().split('.')

    langs[locale] = {
      [PAGE_TITLE]: title,
      ...text,
      ...(imageFiles
        ? imageFiles.keys().reduce((images, key) => {
            images[replaceI(key)] = imageFiles(key)
            return images
          }, {})
        : {})
    }

    return langs
  }, {})
}

/**
 * 辅助方法
 * getMessages 从 routes 获取全部的多语言配置对象
 * getPageTitle 获取页面 title
 * proxyT 代理 i18n 的 t 函数
 */
export const getMessages = (routes) =>
  routes
    .filter((route) => route.langs)
    .reduce((messages, route) => {
      const { path, meta } = route
      const langs = getLangSettings(route.langs)
      const fallback = meta?.lang || USE_LANGS[0]

      for (const lang of USE_LANGS) {
        messages[lang] || (messages[lang] = {})
        messages[lang][replaceP(path)] = langs[lang] || langs[fallback] || langs[USE_LANGS.find((i) => !!langs[i])]
      }

      return messages
    }, {})
export const getPageTitle = (component) => (Vue.prototype.$app ? Vue.prototype.$app.$i18n.t({ v: PAGE_TITLE, component }) : '\u200e')
export const proxyT = (oldT) =>
  function (options, ...r) {
    const router = Vue.prototype.$app.$router

    let v, component
    if (typeof options === 'string') {
      v = options
      component = [...router.currentRoute.matched].pop()
    } else {
      v = options.v
      component = options.component || (component = [...router.currentRoute.matched].pop())
    }

    if (!component) {
      return ''
    }

    const path = replaceP(component.path || '/')
    const v1 = `${path}.${v}`
    const t1 = oldT.call(this, v1)
    if (t1 === v1) {
      const v2 = `${path}.${replaceI(v)}`
      const t2 = oldT.call(this, v2)
      return t2 === v2 ? '' : replaceT(t2, r)
    } else {
      return replaceT(t1, r)
    }
  }

/**
 * 添加 quick-page 多语言函数
 */
export const quickT = function (value, ...r) {
  const cur = this.$route.meta.assets[value] || this.$route.meta.params[value] || this.$route.meta.json[value]
  if (cur) {
    return cur
  }

  const keys = value.split('.')
  const first = keys.shift()

  const v1 = this.$route.meta.json[first]
  const v2 = keys.reduce((v, k) => {
    return v ? v[k] || '' : ''
  }, v1)

  return replaceT(v2, r)
}
