import Vue from 'vue'
import quickConfig from '@/store/quick-config'
import get from 'lodash/get'
import weSDK from '@wepie/we-sdk'
import config from '@/config'
import { IS_WEPLAY } from '@/utils/env'
/**
 * @module preview
 */
/**
 * @description 通用道具预览
 * @param {number} id 道具id
 * @param {Object} extra 额外的字段
 * @param {object} extra.data 运行时传完整的奖励资源
 */
export function propPreview(id, extra = {}) {
  /**
   * 该方法只用于在page-center上面添加过的道具。如果有需要直接通过id预览的道具直接用sdk方法预览即可
   *  1: 访客记录本 3: '戒指', 4: '主頁特效', 6: '頭像框', 10: '語音房氣泡', 11: '聊天氣泡', 12: '玩友圈卡片', 13: '進場特效', 19: '語音房背景', 23: '禮物卡', 30: '梦幻名片', 50: '座駕(会玩 31)', 51: '紅包皮膚', 999：称号, 5: "金币"
   */

  if (!id) {
    return
  }
  const vm = Vue.prototype.$app

  const propsConfig = get(quickConfig.getQuickConfig(window.location.pathname), 'props', {})
  const rewardData = extra?.data || propsConfig[id] || {}
  const canPreviewTypeArrCommon = [1, 2, 3, 4, 6, 10, 11, 12, 13, 17, 19, 23, 26, 30, 50, 51]
  const canPreviewTypeArr = IS_WEPLAY ? canPreviewTypeArrCommon : [...canPreviewTypeArrCommon, 31]
  const cannotPreviewTypeArr = [5, 999, 1000]
  const cannotPreviewCateArr = ['avatar_exp', 'gift', 'title'] // 标准化 rewardMeta 的 cate 字段
  const { type, sub_type, cate } = rewardData
  const isAvatarProp = type === 19 && Boolean(sub_type)
  const canPreview = canPreviewTypeArr.includes(type) && !isAvatarProp

  if (cannotPreviewTypeArr.includes(type) || cannotPreviewCateArr.includes(cate)) {
    return console.warn(`无法预览 type:${type}, cate:${cate}, id: ${id}`)
  }

  if (config.$env.DEV) {
    console.info('道具预览id:', id, canPreview ? '客户端预览' : '前端自定义预览')
    console.info(`道具类型 type:${type}, cate:${cate}`)
  }

  if (canPreview) {
    return weSDK('previewProp', id)
  }

  try {
    vm.$uiComp.uiPropFcPreview().openPreview(id, extra)
  } catch (error) {
    console.error(error)
    console.warn(`未配置弹窗，请配置ui-prop-fc-preview预览组件以及对应的id`)
  }
}

export default {
  $propPreview: propPreview
}
