<template lang="pug">
a-section.ui-marquee-lottery-wrapper(v-bind='attrs')
  //- 9宫格展示
  a-section.ui-marquee-lottery__nineBlockWrapper(ref='uiMarqueeLottery', :bg-i='imageLottery.lotteryBg')
    //- 金币显示
    a-position.ui-marquee-lottery__isSupportBuyChips(v-show='isSupportBuyChips', v-bind='lotteryStyleConfig.coinStyle.wrap', @a-tap='$we("pay")')
      a-position.txt-no-wrap.ui-marquee-lottery__coin(v-bind='lotteryStyleConfig.coinStyle.count', :fc='textStyle.lotteryCoinColor') {{ chipInfo.coin }}
    //- 抽奖币显示
    a-position.ui-marquee-lottery__chipCountWrapper(v-bind='lotteryStyleConfig.lotteryChipStyle.wrap', @a-tap='onOpenBuyChip')
      a-position.txt-no-wrap.ui-marquee-lottery__chipCount(v-bind='lotteryStyleConfig.lotteryChipStyle.count', :fc='textStyle.lotteryCoinColor') {{ chipInfo.chipCount }}

    //- 奖励显示
    a-position.fx-row.fx-m-between.fx-cl-between.fx-wrap.ui-marquee-lottery__rewardWrapper(v-bind='lotteryStyleConfig.rewardWrapStyle')
      a-section(v-bind='lotteryStyleConfig.rewardItem', v-for='item in 9', :key='item')
        a-position(v-show='item === lotterySelectIndex', :bg-i='imageLottery.lotterySelect', pc, bg-s='contain', pz='1')
        slot(:name='`prop${item}`')
  //- 吸底按钮
  a-position.ui-marquee-lottery__lotteryButtonWrapper(:bg-i='imageLottery.lotteryButtonBg', v-bind='lotteryButtonStyleConfig.wrap')
    //- 抽奖记录
    a-position.ui-marquee-lottery__lotteryLogWrapper(v-bind='lotteryButtonStyleConfig.lotteryLogWrap', @a-tap='openRecord')
    //- 奖励详情
    a-position.ui-marquee-lottery__rewardDetailWrap(v-bind='lotteryButtonStyleConfig.rewardDetailWrap', @a-tap='openRewardDetail')
    //- 恢复购买确认
    a-position.ui-marquee-lottery__recoverSelect(v-show='isSupportBuyChips', v-bind='lotteryButtonStyleConfig.recoverSelect', @a-tap='toggleTip("autoBuyTip")')
      a-position.ui-marquee-lottery__autoBuyTip(v-if='storageInfo.autoBuyTip', :bg-i='imageLottery.selectIcon', pc)
      a-position(wh='.5', pc)
    //- 跳过动画
    a-position.ui-marquee-lottery__ignoreAnimeSelect(v-bind='lotteryButtonStyleConfig.ignoreAnimeSelect', @a-tap='toggleTip("ignoreAnima")')
      a-position.ui-marquee-lottery__ignoreAnima(v-if='storageInfo.ignoreAnima', :bg-i='imageLottery.selectIcon', pc)
      a-position(wh='.5', pc)
    //- 抽奖按钮
    span(:data-var='(buttonStyle = lotteryButtonStyleConfig.buttonWrap(lotteryCount.length))')
    a-position.fx-row.fx-m-between(v-bind='buttonStyle')
      a-section(v-for='(item, index) in lotteryCount', :key='index', :wh='buttonStyle.btnWh[index]')
        ui-button(:bg-i='imageLottery.lotteryButtons[index]', pc='x', pb='0', @a-tap='doLottery(item)')

  //- 自动购买二次确认
  a-dialog.txt-c.ui-marquee-lottery__buyConfirm(ref='marqueeLotteryBuyConfirm', :name='confirmDialogName', :bg-i='imageConfirm.confirmDialogBg')
    template(slot-scope='scope', v-if='scope.data')
      a-position.ui-marquee-lottery__textWrap(v-bind='confirmStyleConfig.textWrap', :fc='textStyle.confirmTextColor')
        a-position.ui-marquee-lottery__confirmText(w='100%', v-html='$tf(textConfig.confirmText, scope.data.coin, scope.data.num)', pc)
      a-position.fx-row.fx-m-center.fx-c-center.ui-marquee-lottery__tipWrap(v-bind='confirmStyleConfig.tipWrap', @a-tap='toggleTip("autoBuyTip")')
        a-section.ui-marquee-lottery__selectBorder(:bg-i='imageConfirm.selectBorder', bg-s='contain')
          a-position(v-show='!storageInfo.autoBuyTip', :bg-i='imageConfirm.selectIcon', pc, bg-s='contain')
        a-section(w='.1')
        a-section(v-html='commonText.ignoreTip', :fc='textStyle.confirmTipColor')
    a-button1.ui-marquee-lottery__cancelButton(slot='cancel', v-bind='confirmStyleConfig.cancelButton', :bg-i='imageConfirm.cancelButton', @a-tap='$dialogs[confirmDialogName].close()')
    a-button1.ui-marquee-lottery__confirmButton(slot='confirm', v-bind='confirmStyleConfig.confirmButton', :bg-i='imageConfirm.confirmButton', @a-tap='$dialogs[confirmDialogName].close()')
</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
// import { getNumUnit } from '@/utils/utils'
import { buildApi, concatCatch1 } from '@sub/utils/request'
import { commonText } from '@sub/ui-components/utils/config'
import event from '@sub/event'
import { lotteryButtonStyleConfig, lotteryStyleConfig, confirmStyleConfig, genLotteryTextConfig } from './utils/config'
import { isA } from '../utils/config'

const getAutoBuyInfo = buildApi('/activity_v2/collect_chip/get_buy_chip_cost')
const doLottery = buildApi('/activity_v2/lottery/do_lottery')

export default {
  mixins: [attrs],

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      commonText,
      lotteryButtonStyleConfig,
      lotteryStyleConfig,
      confirmStyleConfig,
      isA,
      // 跑马灯选中的index 从1开始 1-9
      lotterySelectIndex: 0
    }
  },

  computed: {
    isSupportBuyChips({ uiMeta }) {
      return get(uiMeta, 'props.isSupportBuyChips', false)
    },

    imageLottery({ uiMeta }) {
      return get(uiMeta, 'props.image.lottery', {})
    },

    imageConfirm({ uiMeta }) {
      return get(uiMeta, 'props.image.confirm', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.text.style', {})
    },

    textWords({ uiMeta }) {
      return get(uiMeta, 'props.text.words', {})
    },

    confirmDialogName() {
      return `${window.location.pathname}UiAutoBuyConfirm`
    },

    rewardDialogKey({ uiMeta: { rewardDialogKey } }) {
      return rewardDialogKey
    },

    rewardDetailKey({ uiMeta: { rewardDetailKey } }) {
      return rewardDetailKey
    },

    lotteryLogKey({ uiMeta: { lotteryLogKey } }) {
      return lotteryLogKey
    },

    buyChipKey({ uiMeta: { buyChipKey } }) {
      return buyChipKey
    },

    rewardIds({ uiMeta }) {
      return get(uiMeta, 'props.rewardIds', [])
    },

    chipId({ uiMeta }) {
      return get(uiMeta, 'props.chipId', 1)
    },

    lotteryCount({ lotteryCostInfo }) {
      return Object.keys(lotteryCostInfo)
        .sort((a, b) => a - b)
        .map((item) => +item)
    },

    lotteryCostInfo({ uiMeta }) {
      return get(uiMeta, 'props.lotteryCostInfo', {})
    },

    rewards({ uiMeta }) {
      return get(uiMeta, 'props.rewards', [])
    },

    rewardsConfig({ uiMeta }) {
      const list = get(uiMeta, 'props.rewards', [])
      const config = {}
      list.forEach((item) => {
        config[item.id] = item
      })
      return config
    },

    textConfig({ textStyle: { confirmImportantColor, confirmTextColor }, textWords: { lotteryCoinName } }) {
      return genLotteryTextConfig({ color: confirmImportantColor || confirmTextColor, lotteryCoinName })
    },

    storageInfo() {
      return this.$store.state.storage.localStorage
    },

    chipInfo({ chipId }) {
      return {
        coin: this.$store.state.base.coin,
        chipCount: this.$store.state.base.chipInfo[chipId]
      }
    }
  },

  created() {
    this.$store.dispatch('storage/init', { autoBuyTip: true, ignoreAnima: false })
  },

  mounted() {
    event.onLotteryAgain(this.doLottery)
    event.onChipChange(this.updateChipInfo)
    // this.fixDialog('marqueeLotteryBuyConfirm')
  },

  destroyed() {
    event.offOnLotteryAgain(this.doLottery)
    event.offOnChipChange(this.updateChipInfo)
  },

  afterInit() {
    this.updateChipInfo()
  },

  methods: {
    toggleTip(key) {
      this.$store.dispatch('storage/toggle', key)
    },

    updateChipInfo() {
      this.$base.getUserCoin()
      this.$base.getChipInfo()
    },

    onOpenBuyChip() {
      if (!this.isSupportBuyChips) {
        console.warn('this.isSupportBuyChips is false, so click no dialog open')
        return
      }

      this.$dialogs[this.buyChipKey].open({ options: { bgClose: true } })
    },

    fixPosition() {
      this.$refs.uiMarqueeLottery?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    },

    async doLottery(num) {
      this.$storage.audioControl().audioPlay('tap')
      this.fixPosition()
      const autoBuyRes = await this.autoBuy(this.lotteryCostInfo[num][this.chipId])
      if (!autoBuyRes) {
        return
      }

      // 再抽一次的情况，有奖励弹窗，先关闭再打开
      if (this.$dialogs[this.rewardDialogKey].active) {
        await this.$dialogs[this.rewardDialogKey].close()
      }

      document.body.classList.add('page-mask')
      const [err, res] = await concatCatch1(doLottery({ number: num, type: get(this.uiMeta, 'props.relatedLotteryType', 1), auto_purchase: true }))
      if (err) {
        document.body.classList.remove('page-mask')
        return
      }

      // 更新金币/碎片信息
      this.updateChipInfo()
      event.emitOnLotteryComplete()

      if (!this.storageInfo.ignoreAnima) {
        this.$storage.audioControl().audioPlay('lottery')
        const idx = this.rewardIds.findIndex((item) => item === res[0]?.reward_id)
        await this.marqueeAnima(idx === -1 ? 9 : idx + 1)
      }

      document.body.classList.remove('page-mask')
      this.lotterySelectIndex = 0

      this.$storage.audioControl().audioPlay('rewardDialog')
      this.$dialogs[this.rewardDialogKey].open({
        data: {
          rewardList: res.map((item) => {
            const { img, type } = this.rewardsConfig[item.reward_id]
            const unitType = this.$getPropsUnitType(type)
            const unit = unitType === 1 ? '' : item.reward_amount_desc
            return {
              ...item,
              img,
              type,
              unit: this.$getNumUnit(item.reward_amount),
              reward_name: item.reward_type_name.trim() + ' ' + unit
            }
          }),
          lotteryNum: num
        }
      })
    },

    // 自动购买的情况
    async autoBuy(num) {
      const chipCount = this.chipInfo.chipCount
      // 自动购买   当前抽奖币 < 需要的
      if (chipCount < num) {
        if (!this.isSupportBuyChips) {
          this.$toast(this.$tf(this.textConfig.chipLack))
          return Promise.resolve(false)
        }
        // 算出差值
        const diff = num - chipCount
        const { price, number, extra_number } = await getAutoBuyInfo({ chip_id: this.chipId, number: diff })

        //  金币不足
        if (this.chipInfo.coin < price) {
          this.$toast(this.$tf(this.textConfig.goldLack))
        }

        if (this.storageInfo.autoBuyTip) {
          return this.$dialogs[this.confirmDialogName].confirm({ data: { coin: price, num: number + extra_number } })
        }

        return Promise.resolve(true)
      }
      return Promise.resolve(true)
    },

    marqueeAnima(targetIdx) {
      return new Promise((reslove) => {
        const maxCycle = 3
        // 3圈+抽到奖励的idx
        const totalCount = 27 + targetIdx
        // 6176.8 => 抽奖音效的总时间
        const deylay = 6176.8 / totalCount

        let count = 0
        const timer = setInterval(() => {
          if (this.lotterySelectIndex < 9) {
            this.lotterySelectIndex += 1
            if (count === maxCycle && this.lotterySelectIndex === targetIdx) {
              clearInterval(timer)
              setTimeout(() => {
                reslove()
              }, 300)
            }
          } else {
            count++
            this.lotterySelectIndex = 0
          }
        }, deylay)
      })
    },

    openRecord() {
      this.$record.openRecord()[this.lotteryLogKey]()
    },

    openRewardDetail() {
      this.$dialogs[this.rewardDetailKey].open({ options: { bgClose: true } })
    },

    fixDialog(ref) {
      const page = document.querySelector('.page-container')
      page.appendChild(this.$getRef(ref).$el)
    }
  }
}
</script>

<style lang="scss" scoped></style>
