export default [
  {
    path: '/activity-20230325-main',
    component: () => import('./bigShot/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      expire: '2023/04/10 23:59:59 UTC+8',
      pageCenter: 'X5PmIwMf'
    }
  },
  {
    path: '/activity-20230325-share',
    component: () => import('./share/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // ignore: true,
      // expire: '2023/04/10 23:59:59 UTC+8',
      pageCenter: 'wflMxQL8'
    }
  }
]
