import weSDK from '@/lib/we-sdk'
class StatusBarHeight {
  statusBarHeight = 0

  async getHeight() {
    return this.statusBarHeight
  }

  async setHeight() {
    const { statusBarHeight = 0 } = await weSDK('getStatusBarHeight')

    this.statusBarHeight = statusBarHeight
  }
}

export default new StatusBarHeight()
