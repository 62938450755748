import { PAGE_REGION, IS_WEPLAY } from '@/utils/env'
import proxyAssets from '@sub/utils/proxy-assets'

export const isA = PAGE_REGION === 'A'
export const isQ = PAGE_REGION === 'Q'

const commonWpImage = proxyAssets({
  dialogClose: 'https://quick-page.weplayapp.com/assets/0F94A53D-44B6-4888-85AF-928ECEC0ED2F.png?_w=60&_h=60'
})

const commonHwImage = proxyAssets({
  dialogClose: 'https://quick-page.afunapp.com/assets/90B6B681-AC2F-496B-B05C-281E3464D3C3.png?_w=60&_h=60'
})

export const commonImage = IS_WEPLAY ? commonWpImage : commonHwImage

const allCommonText = {
  hw: {
    dateFormat: 'M月DD日 HH:mm',
    ignoreTip: '不再提示此弹窗',
    permanentText: '永久',
    avatarTagMap: {
      1: '套装',
      2: '上衣',
      3: '下装',
      4: '鞋子',
      5: '美瞳',
      6: '妆容',
      7: '眼镜',
      8: '耳饰',
      9: '发型',
      10: '面部',
      11: '眉毛',
      12: '眼睛',
      13: '眼皮',
      14: '睫毛',
      15: '鼻子',
      16: '嘴巴',
      17: '胡子',
      18: '帽子',
      19: '头饰',
      20: '手持物',
      21: '背饰',
      22: '拖尾',
      23: '座椅',
      24: '美颜'
    }
  },
  C: {
    dateFormat: 'M月DD日 HH:mm',
    ignoreTip: '不再提示此彈窗',
    permanentText: '永久',
    // 已享优惠
    coupon: ' 優惠次數：<span style="color: {{}}">{{}}</span>/{{}}次',
    // 累计消费
    consume: '金幣消耗：<span style="color: {{}}">{{}}</span>/{{}}',
    // 消费达标展示文案
    standard: '金幣消耗已達標',
    cumulativeTotal: '累計儲值：',
    cumulativeNext: '距離下次還需要儲',
    cumulativeValue: '{{}}金幣',
    // 保底
    guaranteed: '距離保底還剩：<span style="color: {{}}">{{}}</span>次',
    sendGiftNum: '已送出：<span style="color: {{}}">{{}}</span>個',
    avatarTagMap: {
      1: '套装',
      2: '上衣',
      3: '下裝',
      4: '鞋子',
      5: '美瞳',
      6: '妝容',
      7: '眼鏡',
      8: '耳飾',
      9: '髮型',
      10: '面部',
      11: '眉毛',
      12: '眼睛',
      13: '眼皮',
      14: '睫毛',
      15: '鼻子',
      16: '嘴巴',
      17: '鬍子',
      18: '帽子',
      19: '頭飾',
      20: '手持物',
      21: '背飾',
      22: '拖尾',
      23: '座椅',
      24: '美顏'
    }
  },
  J: {
    dateFormat: 'M月DD日 HH:mm',
    ignoreTip: '次回から表示しない',
    // 已享优惠
    coupon: '使用済み：<span style="color: {{}}">{{}}</span>/{{}}回',
    // 累计消费
    consume: '累計消費：<span style="color: {{}}">{{}}</span>/{{}}',
    // 消费达标展示文案
    standard: '達成済み',
    // 保底
    guaranteed: '天井まで：<span style="color: {{}}">{{}}個</span>',
    // 不能再多了
    overToast: 'もうこれ以上にできません',
    // 不能再少了
    leastToast: 'もうこれ以下にできません'
  },
  K: {
    permanentText: '무제한',
    dateFormat: 'M월 DD일 HH:mm',
    ignoreTip: '다시 보지 않기',
    // 已享优惠
    coupon: '보너스 횟수: <span style="color: {{}}">{{}}</span>/{{}}',
    // 累计消费
    consume: '코인 소비: <span style="color: {{}}">{{}}</span>/{{}}',
    // 消费达标展示文案
    standard: '조건에 도달했음',
    // 累计收费
    cumulativeTotal: '누적 충전 금액이: ',
    // 累计消费
    cumulativeTotal1: '누적 소비: ',
    cumulativeNext: '다음 단계까지: ',
    cumulativeValue: '{{}}코인',
    // 保底
    guaranteed: '확정 채용까지: <span style="color: {{}}">{{}}</span>개',
    sendGiftNum: '증정함:  <span style="color: {{}}">{{}}</span>개',
    // 不能再多了
    overToast: '증가 불가',
    // 不能再少了
    leastToast: '감소 불가'
  },
  A: {
    dateFormat: 'HH:mm M/D',
    ignoreTip: 'لا تظهر نافذة التأكيد في المرة القادمة',
    //不能再少啦
    canNotLess: 'لا يمكن التقليل أكثر',
    //不能再多啦
    canNotMore: 'لا يمكن إضافة المزيد',
    //保存成功
    saveSucc: 'حفظ بنجاح',
    //保存失败
    saveFail: 'فشل عملية الحفظ',
    // 兑换失败
    exchangeFail: 'فشل الاستبدال',
    // 已享优惠
    coupon: 'فرص الخصم: <span style="color: {{}}">{{}}</span>/{{}}',
    // 累计消费
    consume: 'استهلاك الذهب: <span style="color: {{}}">{{}}</span>/{{}}',
    // 消费达标展示文案
    standard: 'بلغ استهلاك العملات الذهبية',
    cumulativeTotal: 'الشحن المتراكم <span style="color: {{}}">{{}} ذهب</span>',
    cumulativeNext: 'المكافأة التالية بعد شحن <span style="color: {{}}">{{}} ذهب</span>',
    // 送出礼物数量
    sendGiftNum: 'تم إرسال: <span style="color: {{}};display:inline-block;transform:translateY(1px);">{{}}</span>',
    coinShort: 'العملات الذهبية غير كافية'
  },
  T: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'ปิดการแจ้งเตือน'
  },
  M: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'Tidak ada pop timbul'
  },
  U: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'Hide this popup'
  },
  P: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'Hide this popup'
  },
  V: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'Không nhắc lại'
  },
  Q: {
    dateFormat: 'DD/M HH:mm',
    ignoreTip: 'Bu iletiyi bir daha gösterme',
    //不能再少啦
    canNotLess: 'Daha az olamaz',
    //不能再多啦
    canNotMore: 'Daha çok olamaz'
  },
  R: {
    dateFormat: 'DD/M HH:mm',
    ignoreTip: 'Не показывать снова'
  },
  B: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'Não mostrar mais a Pop-up'
  },
  I: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'Hide this popup'
  },
  S: {
    dateFormat: 'M/DD HH:mm',
    ignoreTip: 'No volver a mostrar este pop-up'
  }
}

export const commonText = IS_WEPLAY ? allCommonText[PAGE_REGION] : allCommonText.hw
