import store from '@/store'

function useDefer(showFrame = 0) {
  const { curFrame, maxFrame } = store.state.defer

  return curFrame >= Math.min(showFrame, maxFrame)
}

export default {
  $useDefer: useDefer
}
