const pageCenter = 'Eek7gbUL'

export default [
  {
    path: '/activity/v2/voice-center/main-k/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[0]
      },
      pageCenter,
      keepAlive: true
    }
  },

  {
    path: '/activity/v2/voice-center/detail-k/index.html',
    component: () => import('./detail.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[1]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/preview-k/index.html',
    component: () => import('./preview.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[2]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/create-k/index.html',
    component: () => import('./create.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[3]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/task-k/index.html',
    component: () => import('./task.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[4]
      },
      pageCenter
    }
  },

  // {
  //   path: '/activity/v2/voice-center/apply-k/index.html',
  //   component: () => import('./apply.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.json.id
  //       to.meta.title = to.meta.json.title[5]
  //     },
  //     pageCenter
  //   }
  // },

  {
    path: '/activity/v2/voice-center/raiders-k/index.html',
    component: () => import('./raiders.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[5]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/share-k/index.html',
    component: () => import('./share.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[6]
      },
      pageCenter
    }
  },

  // {
  //   path: '/activity/v2/voice-center/creator-k/index.html',
  //   component: () => import('./creator.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.json.id
  //       to.meta.title = to.meta.json.title[8]
  //     },
  //     pageCenter
  //   }
  // },

  {
    path: '/activity/v2/voice-center/rate-k/index.html',
    component: () => import('./rate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[7]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/illustrate-k/index.html',
    component: () => import('./illustrate.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[8]
      },
      pageCenter
    }
  },

  // {
  //   path: '/activity/v2/voice-center/explain-k/index.html',
  //   component: () => import('./explain.vue'),
  //   meta: {
  //     setMeta(to) {
  //       to.meta.id = to.meta.json.id
  //       to.meta.title = to.meta.json.title[9]
  //     },
  //     pageCenter
  //   }
  // },

  {
    path: '/activity/v2/voice-center/auxiliary-k/index.html',
    component: () => import('./auxiliary.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[10]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/rank-info-k/index.html',
    component: () => import('./rank-info.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[11]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/new-k/index.html',
    component: () => import('./new.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[12]
      },
      pageCenter
    }
  },

  {
    path: '/activity/v2/voice-center/new-apply-k/index.html',
    component: () => import('./new-apply.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title[13]
      },
      pageCenter
    }
  }
]
