export default [
  {
    path: `/activity/v2/avatar-discount/recharge-c/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 1946,
      pageCenter: '7yEOAqqu'
    }
  }
]
