import qs from 'qs'
import config from '@/config'
import point from '@/lib/page-point'
import { IS_APP, IS_WEPLAY, IS_ANDROID } from '@/utils/env'

export default {
  $pageBack: pageBack,
  $pageRefresh: pageRefresh,
  $pageJumpNew: pageJumpNew,
  $getJumpNewUrl: getJumpNewUrl,
  $getReferActUrl: getReferActUrl,
  $getReferNotActUrl: getReferNotActUrl
}
/**
 * @module pageJump
 */

/**
 * @description 返回上一页
 */
export function pageBack() {
  const actUrlStack = JSON.parse(window.sessionStorage.getItem('actUrlStack')) || []

  if (getPageUrl(actUrlStack[0]) === getPageUrl(window.location.href)) {
    this.$we('closeWindow')
  } else {
    const backUrl = actUrlStack[actUrlStack.length - 2]

    if (!backUrl) {
      doPoint()
      this.$we('closeWindow')
      return
    }

    window.sessionStorage.setItem('actUrlStack', JSON.stringify(actUrlStack.slice(0, actUrlStack.length - 2)))

    window.location.href = backUrl
  }
}

/**
 * @description 刷新当前页
 */
export function pageRefresh() {
  // 刷新时，应该禁止记录到 actUrlStack 中
  const actUrlStack = JSON.parse(window.sessionStorage.getItem('actUrlStack')) || []
  window.sessionStorage.setItem('actUrlStack', JSON.stringify(actUrlStack.slice(0, actUrlStack.length - 1)))

  window.location.reload()
}

/**
 * @description 跳转到新页面
 * @param { string } path 跳转的路径
 * @param { object } [query] 跳转的参数
 * @param { object } [extra] 运行时 standardTapAction 中的 extra / 带一些标识，例如是否是全链接
 * @param { string } [method] 跳转类型 打开一个新 webview / 正常入栈 / 替换当前 / 清空之前栈
 */
export function pageJumpNew(path, query = {}, extra = {}, method = 'push') {
  const newUrl = getJumpNewUrl(path, query, extra)
  console.warn('[page-jump-methods][pageJumpNew] newUrl: ', newUrl)

  if (method === 'openNew') {
    const clientIsSupport = IS_WEPLAY && IS_ANDROID

    if (IS_APP && !isInVoiceRoom() && clientIsSupport) {
      this.$we('jumpDeepLink', `wespydeeplink://http?url=${encodeURIComponent(newUrl)}/`)
      return
    } else {
      method = 'push'
    }
  }

  if (method === 'push') {
    window.location.href = newUrl
    return
  }

  if (method === 'replace') {
    const actUrlStack = JSON.parse(window.sessionStorage.getItem('actUrlStack')) || []
    window.sessionStorage.setItem('actUrlStack', JSON.stringify(actUrlStack.slice(0, actUrlStack.length - 1)))
    window.history.replaceState(null, '', newUrl)
    window.history.go(0)
    return
  }

  // 关闭之前页面并打开新页面(A clearBefore 方式跳转 B，按一次则返回到 A ，再按返回退出 webview)
  if (method === 'clearBefore') {
    window.sessionStorage.setItem('actUrlStack', JSON.stringify([window.location.href]))
    window.history.replaceState(null, '', newUrl)
    window.history.go(0)
    return
  }
}

/**
 * @description 获取跳转到的新页面的URL
 * @param { string } path 跳转的路径
 * @param { object } [query] 跳转的参数
 *  @param { boolean } query.isPushActUrlStack 是否入活动链接栈，默认为true
 * @param { object } [extra] 运行时 standardTapAction 中的 extra / 带一些标识，例如是否是全链接
 *  @param { object } extra.query - 参数extra的query属性
 *  @param { boolean } extra.isFullUrl - 参数extra的isFullUrl属性
 *  @param { boolean } extra.isWithReferActUrl - 是否需要携带 referActUrl
 * @return { string } 跳转目标URL
 */
export function getJumpNewUrl(path, query = {}, extra = {}) {
  const defaultExtra = {
    isFullUrl: hasDomain(path)
  }
  extra = { ...defaultExtra, ...extra }
  // 新的URL
  const newUrl = extra.isFullUrl ? path : `${window.location.origin}${path.startsWith('/') ? '' : '/'}${path}`

  // 默认参数
  const defaultQuery = {
    isPushActUrlStack: true
  }

  // 获取自带参数
  const commonQuery = getCommonQuery(path)

  // newUrl 里有的参数
  const newUrlQuery = getQueryObj(newUrl.split('?')[1])

  // 开发者传参
  if (extra && extra.query) {
    query = { ...query, ...extra.query }
  }

  const referActUrl = extra.isWithReferActUrl ? encodeURIComponent(window.location.href) : undefined

  return `${newUrl.split('?')[0]}${getQueryStr({ ...defaultQuery, ...commonQuery, ...newUrlQuery, ...query, referActUrl })}`
}

/**
 * 获取参考活动链接。
 * @returns {string|null} 返回参考活动链接，如果不存在则返回 null。
 */
export function getReferActUrl() {
  const { referActUrl } = getQueryObj(window.location.search)
  if (!referActUrl) {
    return null
  }
  return decodeURIComponent(referActUrl)
}

/**
 * @description 获取来自哪个不同源的链接
 */
export function getReferNotActUrl() {
  const { notActUrlStackUrl } = getQueryObj(window.location.search)
  if (!notActUrlStackUrl) {
    return null
  }
  return decodeURIComponent(notActUrlStackUrl)
}

/**
 * @description 获取只剩下参数 pageKey 的活动链接，没有则为 http://xxxx/index.html?
 * @param { string } url 页面的 url
 * @return { string } 只剩下参数 pageKey 的活动链接
 */
function getPageUrl(url) {
  const [urlWithoutQuery, urlQueryStr] = url.split('?')

  const query = getQueryObj(urlQueryStr)

  const pageKeyQueryStr = query.pageKey ? `pageKey=${query.pageKey}` : ''

  return `${urlWithoutQuery}?${pageKeyQueryStr}`
}

/**
 * @description 判断链接是否带有域名
 * @param { string } link 链接
 * @return { boolean }  true/false
 */
function hasDomain(link) {
  try {
    const url = new URL(link)
    return url.hostname !== ''
  } catch (error) {
    return false
  }
}

/**
 * @description 获取通用参数
 * @return { object } 通用参数对象
 */
function getCommonQuery(path) {
  const { region, lang, scene, activity, head, is_act, u, memo } = getQueryObj(window.location.search)
  let obj
  if (IS_WEPLAY) {
    obj = { region, lang, scene, head, is_act, u, memo }
  } else {
    obj = { region, lang, scene, activity, is_act, u, wespyRefreshTime: Date.now() }
  }
  if (config.$env.PROD) {
    delete obj.u
  }

  for (let key in obj) {
    // 删除 path 中已有的通用参数
    deleteQuery(obj, path, key)
  }

  return filterObj(obj)
}

export function getQueryObj(urlQueryStr) {
  return qs.parse(urlQueryStr || '', {
    ignoreQueryPrefix: true
  })
}

export function getQueryStr(urlQueryObj) {
  return qs.stringify(urlQueryObj, {
    addQueryPrefix: true
  })
}
/**
 * 删除指定查询参数键值对。
 * @param {Object} queryObj - 包含查询参数的对象。
 * @param {string} path - 包含查询参数的路径或 URL。
 * @param {string} queryKey - 要删除的查询参数键名。
 */
function deleteQuery(queryObj, path, queryKey) {
  if (path.includes(`${queryKey}=`)) {
    delete queryObj[queryKey]
  }
}
/**
 * 将 URL 查询参数对象转换为查询字符串。
 * @param {Object} urlQueryObj - 包含 URL 查询参数的对象。
 * @returns {string} 返回转换后的查询字符串。
 * @example
 * // 示例：
 * // 将 URL 查询参数对象转换为查询字符串
 * const urlQueryObj = { page: 1, limit: 10, sort: 'desc' };
 * const queryString = getQueryStr(urlQueryObj);
 * console.log(queryString); // "?page=1&limit=10&sort=desc"
 */
function filterObj(obj) {
  return Object.keys(obj).reduce((acc, cur) => {
    if (obj[cur] !== undefined) {
      acc[cur] = obj[cur]
    }
    return acc
  }, {})
}
/**
 * 检查当前页面是否位于语音房场景中。
 * @returns {boolean} 如果当前页面位于语音房场景中，则返回 true，否则返回 false。
 */
function isInVoiceRoom() {
  const { scene } = getQueryObj(window.location.search)
  return scene === 'voice_room'
}

function doPoint() {
  point.track('LoadPage', {
    scene: 'page_back',
    act_id: getQueryObj(window.location.search)?.act_id,
    event_duration: 0,
    url: window.location.href,
    url_path: window.location.pathname,
    title: 'back_url 为 undefined'
  })
}
