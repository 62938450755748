import config from '@/config'
import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import { get, isString } from 'lodash'
/**
 * @module string
 */

/**
 * 递归地代理对象或数组中的每个属性值，或者代理给定的字符串。
 * 如果给定的数据是字符串，则调用 proxyAssets 函数代理该字符串。
 * 如果给定的数据是数组，则递归地代理数组中的每个元素。
 * 如果给定的数据是对象，则递归地代理对象的每个属性值。
 * @param {Object|Array|string} data - 要代理的对象、数组或字符串。
 * @param {string} type - 处理类型 proxy => 本地资源代理 replace => 域名替换
 * @returns {Object|Array|string} 返回代理后的对象、数组或字符串。
 * @example
 * // 示例：
 * // 代理对象的每个属性值
 * const obj = { a: 'foo', b: { c: 'bar' } };
 * const proxiedObj = proxyDeep(obj);
 * console.log(proxiedObj); // { a: 'foo', b: { c: 'bar' } }
 * // 代理数组的每个元素
 * const arr = [1, '2', { foo: 'bar' }];
 * const proxiedArr = proxyDeep(arr);
 * console.log(proxiedArr); // [1, '2', { foo: 'bar' }]
 * // 代理字符串
 * const str = 'https://example.com/image.jpg';
 * const proxiedStr = proxyDeep(str);
 * console.log(proxiedStr); // 'https://example.com/image.jpg'
 */
function proxyDeep(data, type = 'proxy') {
  const funcMap = {
    proxy: proxyAssets,
    replace: domainReplace
  }
  if (typeof data === 'string') {
    return funcMap[type](data)
  }

  if (!data || typeof data !== 'object') {
    return data
  }

  if (Array.isArray(data)) {
    return data.map((i) => proxyDeep(i, type))
  }

  Object.keys(data).forEach((key) => {
    data[key] = proxyDeep(data[key], type)
  })

  return data
}
/**
 * 代理给定的字符串，将其转换为符合要求的格式。
 * 如果给定的字符串包含 'wespydeeplink://'，则直接返回原始字符串。
 * 如果给定的字符串是合法的 URL，则根据配置代理 URL，并返回代理后的字符串。
 * 否则，直接返回原始字符串。
 * @param {string} str - 要代理的字符串。
 * @returns {string} 返回代理后的字符串。
 * @example
 * // 示例：
 * // 代理给定的 URL 字符串
 * const urlStr = 'https://example.com/image.jpg';
 * const proxiedStr = proxyAssets(urlStr);
 * console.log(proxiedStr); // "/example_com/image.jpg"
 */
function proxyAssets(str) {
  let params = {}

  if (str.includes('wespydeeplink://')) {
    return str
  }

  try {
    params = new URL(str)
  } catch (e) {
    return str
  }

  if (!config.ossHost.find((i) => i.indexOf(params.hostname) > -1)) {
    return str
  }

  return `/${params.hostname.replace(/\./g, '_')}${params.pathname}${params.search}`
}

function domainReplace(str) {
  try {
    if (!isUrl(str) && !isWespydeeplinkUrl(str)) {
      return str
    }

    for (let i = 0; i < DOMAIN_LIST.length; i++) {
      const curDomain = DOMAIN_LIST[i]
      const pattern = curDomain.origin
      if (pattern.test(str)) {
        return str.replace(pattern, curDomain.replace)
      }
    }
  } catch (e) {
    console.error(e)
    return str
  }

  return str
}

export default function (data) {
  if (DOMAIN_LIST.length && !config.$env.LOCAL) {
    return proxyDeep(data, 'replace')
  }

  if (IS_WEPLAY) {
    return data
  }

  return config.$env.LOCAL ? proxyDeep(data) : data
}

// JSON字符串场景，只会替换匹配到的第一个域名
const DOMAIN_CONFIG = {
  V: [
    // { origin: 'picuser-vnm.weplayapp.com', replace: 'picuser-vnm.wejoysg.com' },
    // { origin: 'picuser-sgp.weplayapp.com', replace: 'picuser-sgp.wejoysg.com' },
    // { origin: 'pic.weplayapp.com', replace: 'pic.wejoysg.com' },
    // { origin: 'res.weplayapp.com', replace: 'res.wejoysg.com' },
    // { origin: 'fe-center.weplayapp.com', replace: 'fe-center.wejoysg.com' },
    // { origin: 'resource.weplayapp.com', replace: 'resource.wejoysg.com' },
    // { origin: 'quick-page.weplayapp.com', replace: 'quick-page.wejoysg.com' },
    // { origin: 'activity-dev.weplayapp.com', replace: 'activity-dev.wejoysg.com' },
    // { origin: 'activity.weplayapp.com', replace: 'activity.wejoysg.com' },
    { origin: 'weplayapp.com', replace: 'wejoysg.com' }
  ]
}

const DOMAIN_LIST = (get(DOMAIN_CONFIG, PAGE_REGION, []) || []).map((item) => ({ ...item, origin: new RegExp(item.origin, 'g') }))

function isUrl(url) {
  return /^https?:\/\/.+/.test(url)
}

function isWespydeeplinkUrl(url) {
  if (isString(url)) {
    return /^(?=.*wespydeeplink)(?=.*weplayapp.com).+$/.test(url)
  }
  return false
}
