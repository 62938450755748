<template lang="pug">
ui-fixed.ui-fixed-auto-wrapper(v-if='fixedShow', :bg-i='fixedBg', pb='0', style='zindex: 3')
  a-position.fx-center(v-bind='clickWrapStyle')
    a-section(v-for='(item, index) in bgArr.length', @a-tap='scrollToSp(scrollPosition[index] + 1)', v-bind='clickBoxStyle')
      slot(:data='{ index, selectedIndex }')
</template>

<script>
import { get } from 'lodash'
import throttle from 'lodash/throttle'

export default {
  data() {
    return { fixedShow: false, fixedBg: '', selectedIndex: 0 }
  },
  props: {
    scrollPosition: {
      type: Array,
      default: () => []
    },
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    bgArr() {
      return get(this.uiMeta, 'props.bgArr', [])
    },
    clickWrapStyle() {
      return get(this.uiMeta, 'props.clickWrapStyle', {
        wh: '6.53 1.67',
        pb: '.65',
        pc: 'x'
      })
    },
    clickBoxStyle() {
      return get(this.uiMeta, 'props.clickBoxStyle', {
        wh: '1.52 1.67'
      })
    },
    initfixedShow() {
      return get(this.uiMeta, 'props.initfixedShow', false)
    }
  },
  async mounted() {
    this.initScroll()
  },
  async onPageShow() {
    window.addEventListener('scroll', this.scrollHandle)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandle)
  },
  methods: {
    initScroll() {
      window.addEventListener('scroll', this.scrollHandle)
    },
    scrollToSp(diff) {
      window.scrollTo({
        top: diff,
        behavior: 'smooth'
      })
    },
    scrollHandle: throttle(function () {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.fixedShow = this.initfixedShow || scrollTop > 100 //脱离首屏幕
      for (let i = this.scrollPosition.length - 1; i >= 0; i--) {
        if (scrollTop >= this.scrollPosition[i]) {
          this.fixedBg = this.bgArr[i]
          this.selectedIndex = i
          break
        }
      }
    }, 33.3)
  }
}
</script>
