/* eslint-disable */

import { amConfig } from '../config'
import { $dayjsTz } from '@/utils/dayjs'
import Vue from 'vue'

const {
  modules: {
    checkin: { request }
  }
} = amConfig

export default {
  namespaced: true,

  getters: {
    //相当于computed
    // 0 未开启 1 可以签到 2 今日已签 3已经签完
    totalState({ total, isRecharge, status, totalDay }) {
      let state
      if (!isRecharge) {
        state = 0
      } else if (isRecharge && !status && total < totalDay) {
        state = 1
      } else if (isRecharge && status && total < totalDay) {
        state = 2
      } else if (isRecharge && total >= totalDay) {
        state = 3
      }

      return state
    }
  },

  state: {
    //相当于data
    // 是否充值开启签到活动
    isRecharge: 0,
    // 用户签到信息 例如：{ 1: 0, 2: 1, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 }
    detail: {},
    // 当天是否签到
    status: 0,
    // 累计签到天数
    total: 0,
    // 今天签到的第几天 如1
    today: '',
    // 弹幕
    broadcast: [],
    totalDay: 7
  },

  actions: {
    async amCheckinInit({ state, rootState, rootGetters }, payload = {}) {
      const amApiParam = rootGetters['base/amApiParam']
      const amBasic = rootState.base.amBasic
      const { totalDay = 7 } = payload

      state.totalDay = totalDay

      for (let i = 1; i <= totalDay; i++) {
        // state.detail[i] = 0
        Vue.set(state.detail, i, 0)
      }

      const isRecharge = await request.getChargeStatus(amApiParam)
      state.isRecharge = isRecharge

      if (!isRecharge) {
        return
      }

      const { total, status } = await request.getSignLog(amApiParam)

      for (let i = 0; i < total; i++) {
        // state.detail[i + 1] = 1
        Vue.set(state.detail, i+1, 1)
      }

      state.total = total
      state.today = total
      state.status = status

      if (!state.detail[$dayjsTz(amBasic.time).format('YYYYMMDD')]) {
        state.today++
      }

      if (state.today > totalDay) {
        state.today = totalDay
      }
    },

    async userCheckin({ state, rootGetters }) {
      return new Promise(async (resolve, reject) => {
        const amApiParam = rootGetters['base/amApiParam']
        const { rewards } = await request.doSign(amApiParam)
        // state.detail[state.today] = 1
        Vue.set(state.detail, state.today, 1)

        console.log(rewards, 'rewards')

        resolve(rewards)
      })
    },

    async initBullet({ state, rootGetters }) {
      const amApiParam = rootGetters['base/amApiParam']
      const broadcast = await request.getBroadcastList(amApiParam)
      state.broadcast = broadcast
    }
  }
}
