<template lang="pug">
a-section.ui-vip-discount(v-bind='attrs')
  //- 购买会员
  a-position.ui-vip-discount__wrap.fx-row.fx-m-between(v-bind='getStyle("chooseVipWrapper")')
    a-section.ui-vip-discount__item(v-for='(item, index) in vipList', :key='index', @a-tap='vipType = index')
      a-section.ui-vip-discount-item--active(v-show='vipType !== index', :bg-i='item.vip', v-bind='getStyle("chooseVipItem")')
      a-section.ui-vip-discount-item--notactive(v-show='vipType === index', :bg-i='item.vipActive', v-bind='getStyle("chooseVipItem")')
  a-button1.ui-vip-discount__button(:bg-i='images.buyBtn', v-bind='getStyle("buyBtn")', @a-tap='buyVip()')
  slot(:data='{ vipType }')
</template>

<script>
import { get } from 'lodash'

import { attrs } from '@sub/ui-runtime/core/mixins/attrs'

import { buildApi } from '@sub/utils/request'
const getGoodsInfos = buildApi('/activity_v2/common/get_goods_infos')
const hasBroughtVip = buildApi('/activity_v2/vip_discount/has_brought_vip')

const defaultStyle = {
  chooseVipItem: { w: 2, h: 2.36, bgS: 'contain' },
  chooseVipWrapper: { w: 6.08, h: 2.36, pt: 0, pc: 'x' },
  buyBtn: { pt: 2.96, pc: 'x' }
}
const defaultGoodsIdsMap = {
  1: 139,
  2: 140,
  3: 141
}

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      defaultStyle,
      defaultGoodsIdsMap,
      vipType: 0,
      hasRecharge: true
    }
  },

  computed: {
    amBasic() {
      return this.$base.amBasic()
    },

    props() {
      return (
        this.uiMeta?.props || {
          images: {
            firstVip: '',
            vip1: '',
            vip2: '',
            vip3: '',
            firstVipActive: '',
            vip1Active: '',
            vip2Active: '',
            vip3Active: '',
            buyBtn: ''
          },
          goodsIdsMap: {},
          specialStyle: {
            chooseVipWrapper: {},
            buyBtn: {}
          },
          buySuccessToast: 'buy success'
        }
      )
    },

    images() {
      return this.props.images || {}
    },

    vipList({ hasRecharge, goodsIdsMap, images }) {
      return new Array(3).fill(null).map((item, index) => {
        if (!index && !hasRecharge) {
          return { id: goodsIdsMap[0], vip: images['firstVip'], vipActive: images['firstVipActive'] }
        } else {
          return { id: goodsIdsMap[index + 1], vip: images[`vip${index + 1}`], vipActive: images[`vip${index + 1}Active`] }
        }
      })
    },

    openFirstRecharge({ goodsIdsMap }) {
      return goodsIdsMap[0]
    },

    goodsIdsMap() {
      return this.props.goodsIdsMap || defaultGoodsIdsMap
    },

    specialStyle() {
      return this.props.specialStyle || {}
    },
    buySuccessToast() {
      return this.props.buySuccessToast || ''
    }
  },

  created() {
    if (this.openFirstRecharge) {
      this.hasBroughtVip()
    }
  },

  methods: {
    async hasBroughtVip() {
      this.hasRecharge = await hasBroughtVip()
    },

    getStyle(target) {
      const defaultVal = get(this.defaultStyle, target, {})
      const specailVal = get(this.specialStyle, target, {})
      return { ...defaultVal, ...specailVal }
    },

    // 购买 VIP
    async buyVip() {
      if (this.amBasic.over) {
        return
      }

      const goods_ids = this.vipList[this.vipType].id
      if (!goods_ids) {
        console.warn(`[ui-vip-discount][buyVip] has warn: 请去 props.goodsIdMap 配置${this.vipType}对应的礼包 id`)
        return
      }

      const res = await getGoodsInfos({ goods_ids })
      const goodsInfo = res[goods_ids] || {}

      try {
        const { code } = await this.$we('purchase', JSON.stringify({ ...goodsInfo }), 1000000)
        if (code === 200) {
          this.$toast(this.$tf(this.buySuccessToast))
          this.$emit('buy-success')
        }
      } catch {
        console.warn('[ui-vip-discount][buyVip] has info: vip用户放弃支付')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
