import _, { get, isObject } from 'lodash'
import axios from 'axios'
import json5 from 'json5'

import config from '@/config'
import { isProduction } from '@sub/utils/switchEnvRequest'
import { getQuery1, supportsWebp } from '@/utils/utils'
import multiLanguage from '@sub/utils/multi-language'
import proxyAssets from '@sub/utils/proxy-assets'
import { IS_DEV, IS_WEPLAY, PAGE_LANG, PAGE_REGION } from '@/utils/env'

const LANG_MAP = { 'zh-CN': 'zh-hans', 'zh-TW': 'zh-tw' }

const { LOCAL } = config.$env
const { debugPCEnv, region, disablePref } = getQuery1()

export async function getRelease(id) {
  const cdnUrl = `https://fe-center.${IS_WEPLAY ? 'weplayapp' : 'afunapp'}.com/page-center/release/${id}${IS_DEV || (debugPCEnv === 'dev' && !isProduction) ? '_dev' : ''}.json?v=${Date.now()}`
  const url = LOCAL ? `https://page-center-api-activity.wepieoa.com/open/release?id=${id}` : cdnUrl

  const { data } = await axios(proxyAssets(url))

  return proxyAssets(data)
}

export async function getConfig(id) {
  if (!id) {
    return null
  }

  id = getMultiLanguageId(id)

  let data

  try {
    const pageCenterDataInHtml = _.get(window, '__build_inject_var__.pageCenterData') || {}

    const now = performance.now()
    setTimeout(() => {
      console.warn('ywtest in page-center.js', now)
    }, 1000)

    if (id === pageCenterDataInHtml.id && checkPageCenterData(pageCenterDataInHtml) && disablePref !== 'true') {
      data = proxyAssets(pageCenterDataInHtml)
      console.warn('window.__build_inject_var__.pageCenterData has data, so use pageCenterDataInHtml')
    } else {
      data = await getRelease(id)
    }
  } catch (e) {
    console.warn(e)
    return null
  }

  if (!data.config) {
    return null
  }

  if (data.format === 'langs') {
    const langs = data.config.langs.filter((i) => (IS_WEPLAY ? PAGE_LANG === (LANG_MAP[i.lang] || i.lang) : i.lang === 'zh-hans'))

    for (const item of langs) {
      if (item.key) {
        if (item.key.split(',').includes(IS_WEPLAY ? PAGE_REGION : 'C')) {
          data = item
          break
        }
      } else {
        data = item
      }
    }
  }

  return {
    assets: assets2obj(get(data, 'config.assets', [])),
    props: arrs2obj(get(data, 'config.props.base', []), 'id', ''),
    avatar: arrs2obj(get(data, 'config.props.avatar', []), 'id', ''),
    titles: arrs2obj(get(data, 'config.props.title', []), 'id', ''),
    gifts: arrs2obj(get(data, 'config.props.gift', []), 'id', ''),
    text: arrs2obj(handlerText(get(data, 'config.text', []))),
    json: get(data, 'config.json', {}),
    fsAssets: get(data, 'config.assets', []).filter((item) => item.describe?.includes('fs')),
    p0Assets: get(data, 'config.assets', []).filter((item) => item.describe?.includes('p0')),
    components: json5Parse(get(data, 'config.components', {}))
  }
}

function handlerText(arr) {
  arr.forEach((item) => {
    if (item.needEncode) {
      item.value = decodeURIComponent(item.value)
    }
  })
  return arr
}

// assets format
function assets2obj(arr, k = 'key', v = 'value') {
  const supportWebp = supportsWebp()
  return arr.reduce((obj, item) => {
    const url = item[v]
    const [path, query] = url.split('?')
    if (/\.webp$/.test(path) || query?.includes('webp')) {
      obj[item[k]] = supportWebp ? url : item['valuePng'] || url
    } else {
      obj[item[k]] = url
    }
    return obj
  }, {})
}

// 将数组转对象
function arrs2obj(arr, k = 'key', v = 'value') {
  return arr.reduce((obj, item) => {
    obj[item[k]] = v ? item[v] : item
    return obj
  }, {})
}

function json5Parse(object) {
  const obj = {}
  for (let key in object) {
    obj[key] = json5.parse(object[key])
  }

  return obj
}

export async function getActivityConfig() {
  const url = IS_WEPLAY ? `https://fe-center.weplayapp.com/page-center/release/DaovkKq0.json?v=${Date.now()}` : `https://fe-center.afunapp.com/page-center/release/VB5m7mfB.json?v=${Date.now()}`

  return await axios(proxyAssets(url))
}

function getMultiLanguageId(id) {
  let pcId = id

  if (isObject(id)) {
    if (region === 'M') {
      const lang = multiLanguage.lang || 'ms'
      pcId = id[lang]
    } else {
      pcId = id[region]
    }
  }

  return pcId
}

function checkPageCenterData(data) {
  if (!data) {
    return false
  }

  // 如果字段少了，则不使用 ssg 的数据
  const pageCenterDataKeys = ['id', 'type', 'name', 'sort', 'attribute', 'config', 'format']
  const checkResult = _.difference(pageCenterDataKeys, Object.keys(data)).length === 0

  if (!checkResult) {
    console.warn('ywtest ~ checkPageCenterData ~ checkResult:', checkResult)

    console.log('ywtest ~ checkPageCenterData ~ Object.keys(data):', Object.keys(data))

    console.log('ywtest ~ checkPageCenterData ~ pageCenterDataKeys:', pageCenterDataKeys)
  }

  return checkResult
}
