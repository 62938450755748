import config from '@/config'
import { IS_APP } from '@/utils/env'

import point from '@/lib/page-point'
import weSDK from '@/lib/we-sdk'
import { getQuery1 } from '@/utils/utils'
import StatusBarHeight from '@/store/get-native-height'

export default async function beforeAppMount({ store }) {
  StatusBarHeight.setHeight()
  const { region = 'C', u } = getQuery1()
  if (IS_APP) {
    if (!['/rocket-gift-rule'].includes(location.pathname)) {
      const [user] = await Promise.all([weSDK('getUserInfo'), store.dispatch('jsBridgeInfo/init')])
      store.commit('user/set', { ...user, region })
      point.login(user.uid)
    }
  } else if (config.$env.LOCAL || config.$env.DEV || config.$env.BETA) {
    const user = {
      avatar: 'http://wespynextpic.afunapp.com/o_1d8iju3n31uj11k7lgvotvc1pc338.jpg',
      deviceId: 'D4C3EE17-77A7-40EE-A259-9A6F36FF89EE-8451-000003A44656BA09',
      nickname: '偏爱%',
      sid: '',
      uid: u || 75224550
    }
    store.commit('user/set', { ...user, region })
    point.login(user.uid)
  }
  if (store.state.base.isIOS16_4) {
    window.OffscreenCanvas = undefined
  }
}
