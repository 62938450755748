import qs from 'qs'

import get from 'lodash/get'

import getQuery from '../getQuery'
import { getJumpNewUrl, pageJumpNew } from '../../../../utils/page-jump-methods'
import { jumpToAnchor } from './anchor'
import { IS_DEV } from '@/utils/env'
import { logData } from '../logData'
import { isInIframe } from '@sub/ui-runtime/core/iframe'

import { buildApi, concatCatch } from '@sub/utils/request'
const getHeatRoomInfo = buildApi('/activity_v2/common/get_heat_room_info')
const getAutoBuyInfo = buildApi('/activity_v2/collect_chip/get_buy_chip_cost')

const bizDialogOpenFnMap = {
  'ui-normal-reward-dialog': 'open',
  'b-confirm-dialog-wrapper': 'confirm',
  'b-buy-confirm-dialog-wrapper': 'confirm',
  'b-buy-chip-dialog-wrapper': 'open'
  // 'b-record-dialog-wrapper': 'open'
}
const bizDialogOpenOptionsMap = {
  'ui-normal-reward-dialog': {},
  'b-confirm-dialog-wrapper': {},
  'b-buy-confirm-dialog-wrapper': {},
  'b-buy-chip-dialog-wrapper': {
    options: { bgClose: true }
  }
  // 'b-record-dialog-wrapper': {
  //   options: { bgClose: true }
  // }
}

/**
 * @param {object} vueThis 组件的 this
 * @param {object} [tapFuncMap] 自定义方法
 * @param {object} [extra] 额外配置
 *   @param {object[]} [extra.tap] 取代组件的 events.tap 的动作列表
 *   @param {object} [extra.dialogOpenOptions] 普通弹窗参数配置
 *   @param {object} [extra.openOptionsMap] 业务弹窗参数配置
 *   @param {object} [extra.compState] 组件的状态，用于确认购买弹窗
 *     @param {object} [extra.compState.chipId] 抽奖所需碎片 id
 *     @param {object} [extra.compState.needBuyChipNum] 还需购买多少个碎片才能抽奖
 *     @param {object} [extra.compState.coin] 用户的金币数量
 *     @param {object} [extra.compState.coinShorText] 金币不足文案
 *  @returns 返回每个动作执行完后的返回值
 */
export async function standardTapAction(vueThis, tapFuncMap = {}, extra = {}) {
  const _tapFuncMap = Object.assign(
    {
      deepLink: jumpDeepLink,
      openPage: openPage,
      // 分享到玩友圈
      share: share,
      toast: toast,
      anchor: anchor,
      playAnimation: playAnimation
    },
    tapFuncMap
  )

  const actions = extra.tap || get(vueThis.uiMeta.events, 'tap', [])
  console.log('🐲 gwl ~ standardTapAction ~ actions:', actions)
  let actionsRes = []
  for (let action of actions) {
    const func = _tapFuncMap[action.type]
    const actionRes = (await func(action)) || {}
    actionsRes.push(actionRes)
    if (actionRes.break) {
      break
    }
  }
  return actionsRes

  function jumpDeepLink(item) {
    const type = get(item, 'payload.type', '')
    const link = get(item, 'payload.value', '') || get(item, 'payload.link', '')
    const point = get(item, 'point', {})
    const extra = get(item, 'payload.extra', {})

    if (type === '__topic__') {
      return goTopic(extra, point)
    }

    if (type === '__myself__') {
      return goMySelf(point)
    }

    if (type === '__voice_room__') {
      return goVoiceRoom(extra, point)
    }

    logData(type, link.split('?')[1], point)

    if (type !== 'http' || type === '__custom__') {
      return vueThis.$we('jumpDeepLink', link)
    }
    // deepLink 跳转页面坑比较多，所以使用浏览器原生跳转
    const originUrl = decodeURIComponent(link).split('url=')[1]
    const query = qs.parse(originUrl.split('?')[1])
    const urlWithoutQuery = originUrl.split('?')[0]

    location.href = getJumpNewUrl(urlWithoutQuery, query, { ...extra, isFullUrl: true })
  }

  async function openPage(item) {
    const { type, key, method, subType, openOptions = {} } = item.payload
    const point = get(item, 'point', {})

    logData(`openPage-${type}`, key, point)

    if (type === 'dialog') {
      return vueThis.$dialogs[key]?.['open'](extra.dialogOpenOptions || {})
    }

    if (type === 'page') {
      const { act_id } = getQuery()
      pageJumpNew(
        location.pathname.substring(1),
        {
          pageKey: key,
          act_id
        },
        extra,
        method
      )
      return
    }

    if (type === 'biz-dialog') {
      // 默认的 openOptions
      const defaultOpenOptions = bizDialogOpenOptionsMap[subType] || {}
      // 各组件调用时传入的
      const extraOpenOptions = extra.openOptionsMap?.[subType] || {}
      // 弹窗打开的方式
      const openFn = bizDialogOpenFnMap[subType] || extraOpenOptions.openFn || 'open'

      if (isInIframe()) {
        return vueThis.$dialogs[key]?.[openFn]({ ...defaultOpenOptions, ...extraOpenOptions, ...openOptions })
      }

      // FIXME: 各类特化业务弹窗打开逻辑收敛到各自组件内
      // 二次确认弹窗
      if (subType === 'b-confirm-dialog-wrapper') {
        if (vueThis.$storage.localStorage().noSecondConfirm) {
          return {}
        }
        const isConfirm = await vueThis.$dialogs[key]?.[openFn]({ ...defaultOpenOptions, ...extraOpenOptions, ...openOptions })
        return { break: !isConfirm }
      }
      // 购买确认弹窗
      if (subType === 'b-buy-confirm-dialog-wrapper') {
        const _compState = extra.compState || {}
        if (_compState.needBuyChipNum <= 0) {
          return {}
        }
        if (vueThis.$storage.localStorage().noBuyConfirm) {
          return {}
        }
        const [err, res] = await concatCatch(getAutoBuyInfo({ chip_id: _compState.chipId, number: _compState.needBuyChipNum }))
        if (err) {
          console.log('🐲 gwl ~ openPage ~ err:', err)
          return { break: true }
        }
        const { price, number, extra_number } = res || {}
        const isBuy = await vueThis.$dialogs[key]?.[openFn]({ data: { price, num: number + extra_number }, ...defaultOpenOptions, ...extraOpenOptions, ...openOptions })
        if (!isBuy) {
          return { break: true }
        }
        // 金币不足
        if (_compState.coin < price) {
          vueThis.$toast(_compState.coinShorText)
          return { break: true }
        }
        return {}
      }
      // 记录弹窗
      if (subType === 'b-record-dialog-wrapper') {
        vueThis.$bRecordWrapper[key]?.open({ ...defaultOpenOptions, ...extraOpenOptions, ...openOptions })
        return {}
      }

      // 其他
      return new Promise((resolve) => {
        vueThis.$dialogs[key]?.[openFn]({ ...defaultOpenOptions, ...extraOpenOptions, ...openOptions, onclose: resolve })
      })
    }
  }

  async function share(item) {
    const { image, title, desc, topics } = item.payload
    const point = get(item, 'point', {})
    const { btn_name_alias, screen_name } = point
    const { act_id } = getQuery()
    await vueThis.$we(
      'setShareData',
      JSON.stringify({
        title,
        desc,
        imgUrl: image,
        link: location.href,
        success: '',
        cancel: '',
        wespyDeeplink: `wespydeeplink://http?url=${encodeURIComponent(`${location.href}`)}`,
        topics,
        lockTopic: '',
        imgBase64: '',
        textConfig: {}
      })
    )

    vueThis.$we('doShare', JSON.stringify({ share_type: [7], activity_id: String(act_id) }))

    logData('share-square', '', { btn_name_alias, screen_name })
  }

  function goTopic(extra, point) {
    const { devTopicId, prodTopicId } = extra
    const topicId = IS_DEV ? devTopicId : prodTopicId
    logData('discover_topic', `topic_id=${topicId}`, point)
    vueThis.$we('jumpDeepLink', `wespydeeplink://discover_topic?topic_id=${topicId}`)
  }

  function goMySelf(point) {
    logData('user_info', `uid=${vueThis.$amApiParam().uid}`, point)
    vueThis.$we('jumpDeepLink', `wespydeeplink://user_info?uid=${vueThis.$amApiParam().uid}`)
  }

  async function goVoiceRoom({ jumpType = 'normal', rid }, point) {
    if (jumpType === 'normal') {
      logData('voice_room', `rid=${rid}`, point)
    }
    if (jumpType === 'hot') {
      const [err, res] = await concatCatch(getHeatRoomInfo())
      if (err) {
        console.log('🐲 gwl ~ goVoiceRoom ~ err:', err)
        return
      }
      rid = res.rid
      logData('voice_room', `rid=${rid}`, point)
    }
    vueThis.$we('jumpDeepLink', `wespydeeplink://voice_room?rid=${rid}`)
  }

  function toast(item) {
    const message = get(item, 'payload.message', '')
    vueThis.$toast(message)
  }

  function anchor(item) {
    const key = get(item, 'payload.key', '')
    jumpToAnchor({ key })
  }

  async function playAnimation(item) {
    if (vueThis.$storage.localStorage().noAnime) {
      return
    }
    const key = get(item, 'payload.key', '')
    await vueThis.$qAnimation[key].play()
  }
}
