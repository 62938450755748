<template lang="pug">
a-section(:wh='wh', v-bind='$attrs', @a-tap='$formatPreview(id)')
  template(v-if='effectInfo.isEffectStateReadying')
    a-position(v-if='propsConfig[id].svga && isOpenEffect', wh='100%', pc)
      //- a-img(v-if="showAvatar" wh="75%" :src="+propsConfig[id].gender === 1 ? modelImg.male : modelImg.female" pc)
      a-svga(:url='propsConfig[id].svga', wh='100%', pc)
    ui-prop(v-else, wh='100%', :data='propsConfig[id]', :extra='extra', pc)
</template>

<script>
import proxyAssets from '@sub/utils/proxy-assets'

const modelImgWP = proxyAssets({
  male: 'https://fe-center.weplayapp.com/page-center/assets/VhJmuMcc/VPBmNVJm.png?x-oss-process=image/resize,m_fixed,h_200,w_200',
  female: 'https://fe-center.weplayapp.com/page-center/assets/VhJmuMcc/eukFSxyN.png?x-oss-process=image/resize,m_fixed,h_200,w_200'
})

const modelImgHW = proxyAssets({
  male: 'https://fe-center.afunapp.com/page-center/assets/zcEdJNBd/0Exllpfe.png?x-oss-process=image/resize,m_fixed,h_200,w_200',
  female: 'https://fe-center.afunapp.com/page-center/assets/zcEdJNBd/8naDzgB2.png?x-oss-process=image/resize,m_fixed,h_200,w_200'
})

export default {
  props: {
    id: {
      type: Number
    },

    wh: {
      type: [String, Number],
      default: '100%'
    },

    extra: {
      type: Object,
      default: () => {
        return {
          canPreview: false,
          showGiftCardBgColor: true,
          stylesConfig: { 23: { wh: '100%', 'bg-c': '#fff' } }
        }
      }
    },

    isInternational: {
      type: Boolean,
      default: true
    },

    showAvatar: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {
    isOpenEffect() {
      return this.$base.isOpenEffect()
    },

    propsConfig() {
      return this.$propsConfig()
    },

    effectInfo() {
      return this.$base.effectInfo()
    },

    modelImg() {
      return this.isInternational ? modelImgWP : modelImgHW
    }
  },

  methods: {}
}
</script>

<style lang="scss" scoped></style>
