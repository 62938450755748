<template lang="pug">
  a-button1.ui-button(v-if="!disabled" v-bind='$attrs', :throttle='throttleTime', :bg-s='bgS', @a-tap='handleTap')
    slot
  a-position.ui-button--disable(v-else v-bind='$attrs', :bg-s='bgS' @a-tap="disableTap")
    slot
</template>

<script>
export default {
  props: {
    throttleTime: {
      type: Number,
      default: 1000
    },
    bgS: {
      type: String,
      default: '100%'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  data() {
    return {}
  },

  afterInit() {},

  methods: {
    handleTap(e) {
      this.$emit('tap', e)
      this.$emit('a-tap', e)
    },

    disableTap(e) {
      this.$emit('disable-tap', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-button--disable {
  filter: grayscale(100%);
}
</style>
