export default [
  {
    path: '/h5-module-ambassador-th',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.json.id
        to.meta.title = to.meta.json.title
      },
      pageCenter: 'wYYr1tnr'
    }
  }
]
