<template lang="pug">
a-section.ui-reward-detail-dialog(v-bind='attrs')
  //- 非阿语服
  a-dialog.ui-reward-detail-dialog__not-isA(v-if='!isA', :name='dialogName', :bg-i='image.dialogBg', :cache='extra.cache')
    slot(:data='{ tab }')
    a-section.fx-row.ui-reward-detail-dialog__not-isA__tabs-wrapper(v-if='tabsNum > 1 && image.tabs.length', :bg-i='image.tabs[+tab - 1]', bg-s='contain', :pl='tabStyle.pl', :pt='tabStyle.pt')
      a-section.ui-reward-detail-dialog__not-isA__tabs-wrapper__item(v-for='(item, index) in tabsNum', :key='index', :wh='`${100 / tabsNum}% 100%`', @a-tap='changeActiveTab(item + "")')
    a-section.of-y-a.fx-cl-start.of-h-x.ui-reward-detail-dialog__not-isA__list-wrapper(:w='listWrapStyle.w || 5.45', :h='listWrapStyle.h || 6.83', pl='0.5', :pt='listWrapStyle.pt || 1.56')
      a-section.fx-row.fx-wrap(w='100%', pdt='0.08')
        a-section.reward-item(w='1.54', v-for='(item, index) in curRewards', :key='index', mb='.15')
          a-section(:bg-i='item.rewardBg || image.rewardBg', mb='0.04', wh='1.54', bg-s='contain')
            slot(v-if='$scopedSlots["index-slot"]', name='index-slot', v-bind='{ data: index }')
            slot(v-if='$scopedSlots["prop"]', name='prop', v-bind='{ data: item }')
            ui-prop(v-else, :wh='propWh || "100%"', pc, :data='item', bg-s='contain')
            a-section(v-if='item.tag', :bg-i='item.tag', v-bind='getTagStyle(tagStyle)')
            slot(v-if='$scopedSlots["unit"]', name='unit', v-bind='{ data: item }')
            a-border-text1(v-else, :f='`${textStyle.subTextFs || 0.24} ${textStyle.subTextColor} 700`', borderWidth='3', :borderColor='textStyle.subTextBorderColor', :borderContent='getUnit(item)', v-bind='{ ...defaultStyle.unit, ...unitStyle }')
          a-section(:f='`.2 ${textStyle.rewardNameColor} 500`', w='1.54', ta, lh='.28', m='0 auto', v-html='isRuntime ? item.name : item[activeNameType]')
        span(:data-var='(slotName = `extra-${tab}`)')
        slot(v-if='$scopedSlots[slotName]', :name='slotName')
      slot(name='end', :data='{ tab }')

  //- 阿语服
  a-dialog.ui-reward-detail-dialog__isA(v-if='isA', :name='dialogName', :bg-i='image.dialogBg', :cache='extra.cache')
    slot(:data='{ tab }')
    a-section.fx-row.ui-reward-detail-dialog__isA__tabs-wrapper(v-if='tabsNum > 1 && image.tabs.length', :bg-i='image.tabs[+tab - 1]', bg-s='contain', :pl='tabStyle.pl', :pt='tabStyle.pt')
      a-section.ui-reward-detail-dialog__isA__tabs-wrapper__item(v-for='(item, index) in tabsNum', :key='index', :wh='`${100 / tabsNum}% 100%`', @a-tap='changeActiveTab(item + "")')
    a-section.of-y-a.fx-cl-start.of-h-x.ui-reward-detail-dialog__isA__list-wrapper(:w='listWrapStyle.w || 5.45', :h='listWrapStyle.h || 6.83', pr='0.5', :pt='listWrapStyle.pt || 1.56', pdt='0.08')
      a-section.fx-row.fx-wrap(w='100%', pdt='0.08')
        a-section.reward-item-ar(w='1.54', v-for='(item, index) in curRewards', :key='index', mb='.15')
          a-section(:bg-i='item.rewardBg || image.rewardBg', mb='0.04', wh='1.54', bg-s='contain')
            slot(v-if='$scopedSlots["prop"]', name='prop', v-bind='{ data: item }')
            ui-prop(v-else, :wh='propWh || "100%"', pc, :data='item', bg-s='contain')
            slot(name='tag', v-bind='{ data: { tab, item } }')
            a-section(v-if='item.tag', :bg-i='item.tag', v-bind='getTagStyle(tagStyle)')
            slot(v-if='$scopedSlots["unit"]', name='unit', v-bind='{ data: item }')
            a-border-text1(v-else, :f='`${textStyle.subTextFs || 0.24} ${textStyle.subTextColor} 700`', borderWidth='2.5', :borderColor='textStyle.subTextBorderColor', :borderContent='getUnit(item)', v-bind='{ ...defaultStyle.unitA, ...unitStyle }')
          a-section(:f='`.2 ${textStyle.rewardNameColor} 500`', w='1.54', ta, lh='.28', m='0 auto', v-html='isRuntime ? item.name : item.name4')
        span(:data-var='(slotName = `extra-${tab}`)')
        slot(v-if='$scopedSlots[slotName]', :name='slotName')
      slot(name='end', :data='{ tab }')
</template>

<script>
import get from 'lodash/get'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { getTagStyle, defaultStyle } from './utils/config'
import { isA } from '../utils/config'

export default {
  mixins: [attrs],

  props: {
    isMergeProp: {
      type: Boolean,
      default: true
    },
    uiMeta: {
      type: Object,
      default: () => {}
    },

    propsConfig: {
      type: Object,
      default: () => ({})
    },

    rewards: {
      type: Object,
      default: () => {}
    },

    propWh: {
      type: String,
      default: '100%'
    }
  },

  data() {
    return {
      defaultStyle,
      isA,
      tab: '1'
    }
  },

  computed: {
    isRuntime() {
      return this.$base.extra().isRuntime
    },

    gender() {
      return this.$base.amBasic().user.gender || 2
    },

    dialogName({ uiMeta }) {
      return get(uiMeta, 'key', 'rewardDetail')
    },

    tabsNum({ uiMeta }) {
      return get(uiMeta, 'props.tabsNum', 1)
    },

    image({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    tabStyle({ uiMeta }) {
      return get(uiMeta, 'props.tabStyle', {})
    },

    tagStyle({ uiMeta }) {
      return get(uiMeta, 'props.tagStyle', {})
    },

    unitStyle({ uiMeta }) {
      return get(uiMeta, 'props.unitStyle', {})
    },

    listWrapStyle({ uiMeta }) {
      return get(uiMeta, 'props.listWrapStyle', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.textStyle', {})
    },

    rewardsList({ uiMeta }) {
      return this.rewards || get(uiMeta, 'props.rewardsList', {})
    },

    extra({ uiMeta }) {
      return get(uiMeta, 'props.extra', { cache: false })
    },

    curRewards() {
      const rewardArr = this.rewardsList['tab' + this.tab]
      if (!rewardArr) return []
      const rewards = this.isMergeProp ? this.mergeSameProps(rewardArr) : rewardArr
      return rewards.map((item) => {
        const type = item.type || this.propsConfig[item.id]?.type || this.$propsConfig(item.id)?.type
        const unitType = [3, 23].includes(type) ? 'number' : 'string'
        const showId = [6, 999].includes(type) ? (this.gender === 1 ? item.maleId || item.id : item.id) : item.id
        return {
          ...(this.propsConfig[showId] || this.$propsConfig(showId) || {}),
          unitType,
          ...item
        }
      })
    },
    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {})
    },
    activeNameType({ uiMeta }) {
      return get(uiMeta, 'props.activeNameType', 'name1')
    }
  },

  created() {
    this.$uiComp.setUiCompConfig({
      compName: 'uiRewardDetailDialog',
      compKey: this.dialogName,
      value: {
        open: this.openRewardDetail.bind(this)
      }
    })
  },

  methods: {
    getTagStyle,
    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    },

    openRewardDetail(tab = '1') {
      this.changeActiveTab(tab)
      this.$dialogs[this.dialogName].open({ options: { bgClose: true } })
    },

    getUnit(item) {
      const { unit, id, type } = item
      if (!unit) {
        return ''
      }
      if (typeof unit === 'string') return unit

      return this.$getPropUnit(id, unit, type)
    },

    changeActiveTab(tab) {
      if (this.tab === tab) return

      this.tab = tab
    },

    mergeSameProps(list) {
      const hash = {}
      list.forEach((item) => {
        !hash[item.id] ? (hash[item.id] = [item]) : hash[item.id].push(item)
      })

      const res = []
      list.forEach((item) => {
        let unit = ''
        if (hash[item.id].length > 1) {
          hash[item.id]
            .sort((a, b) => a.unit - b.unit)
            .forEach((r) => {
              unit += `${r.unit} / `
            })
        }

        if (!(res.findIndex((f) => f.id === item.id) > -1)) {
          res.push({
            ...item,
            unit: unit ? unit.substring(0, unit.length - 3) : item.unit
          })
        }
      })

      return res
    }
  }
}
</script>

<style lang="scss" scoped>
.reward-item {
  margin-right: 0.32rem;

  &:nth-of-type(3n + 1) {
    margin-left: 0.14rem;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }
}

.reward-item-ar {
  margin-left: 0.32rem;

  &:nth-of-type(3n + 1) {
    margin-right: 0.14rem;
  }

  &:nth-of-type(3n) {
    margin-left: 0;
  }
}
</style>
