import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import { isA } from '@sub/ui-components/utils/config'
const commonTextStyle = { fs: '0.26', fw: '400', fc: '#FFFFFF' }
// 确认购买弹窗中Tip距离顶部的距离
const buyConfirmTipPtMap = {
  J: 2.63,
  K: 2.63,
  A: 2.63
}

export const buyTaskStyleConfig = {
  uiOuterOperation: {
    closeIcon: { [isA ? 'pl' : 'pr']: '0.3', pt: '0', pz: '3' },
    tab1Button: { wh: '2.6 1', pt: '1.75', [isA ? 'pr' : 'pl']: '1', pz: '3' },
    tab2Button: { wh: '2.6 1', pt: '1.75', [isA ? 'pl' : 'pr']: '1', pz: '3' },
    redDot: { wh: '0.15', bgC: '#FF0000', bdr: '50%', pt: '-0.05', [isA ? 'pl' : 'pr']: isA ? '0.25' : '0.1' } // TODO：需要对齐标准
  },
  uiBuy: {
    chip1: { pt: '2.85', [isA ? 'pr' : 'pl']: isA ? '5.66' : '2', h: '0.28', lh: '0.28', ...commonTextStyle },
    coin: { pt: '2.84', pl: isA ? '5.1' : '5.13', wh: isA ? '1.19 0.27' : '1.3 0.27', lh: '0.27', ta: 'center', ...commonTextStyle },
    addIcon: { wh: '2.32 0.56', pt: '2.69', pl: '4.58' },
    buyListContainer: { wh: '6.53 5.49', [isA ? 'pr' : 'pl']: '0.56', pb: '0.3' },
    buyItemContainer: { wh: '100% 1.12', mb: isA ? '0.16' : '0.16' },
    buyButton: { pc: 'y', [isA ? 'pr' : 'pl']: '4.71' },
    buyTag: { pt: '-0.32', [isA ? 'pl' : 'pr']: '-0.2', 'bg-s': '100%' }
  },
  uiTask: {
    chip1: { pt: '3.24', [isA ? 'pr' : 'pl']: isA ? '5.51' : '2', h: '0.28', lh: '0.28', ...commonTextStyle },
    chip2: { pt: '4.1', [isA ? 'pr' : 'pl']: isA ? '1.28' : '1.32', h: '0.28', lh: '0.28', ...commonTextStyle },
    coin: { pt: '3.23', pl: isA ? '5.25' : '5.13', wh: isA ? '1.19 0.27' : '1.3 0.27', lh: '0.27', ta: 'center', ...commonTextStyle },
    addIcon: { wh: '2.32 0.56', pt: '3.08', pl: isA ? '4.73' : '4.58' },
    exchangeButton: { pt: '3.88', [isA ? 'pr' : 'pl']: isA ? '4.78' : '4.74' },
    exchangeRedDot: { wh: '0.15', bgC: '#FF0000', bdr: '50%', pt: '0', [isA ? 'pl' : 'pr']: '0.12' },
    taskListContainer: { wh: '6.38 4.2', pb: '0.3', pc: 'x' },
    taskItemContainer: { wh: '100% 1.46', mb: '0.12' },
    dailyTaskButton: { pt: '0.28', [isA ? 'pr' : 'pl']: '4.66' }
  },
  uiCoinShortDialog: {
    closeIcon: { [isA ? 'pl' : 'pr']: '0', pt: '0', 'bg-s': '100%' },
    toPayBtn: { pc: 'x', pb: '0.74' }
  },
  uiBuyConfirmDialog: {
    text: { wh: '5.25 0.36', lh: '0.36', pc: 'x', pt: '1.28', ta: 'center', ...commonTextStyle, fw: '500' },
    tip: { pc: 'x', pt: buyConfirmTipPtMap[PAGE_REGION] || '2.42' },
    cancelButton: { pl: '0.69', pb: '0.74' },
    confirmButton: { pr: '0.69', pb: '0.74' }
  }
}

const textConfig = {
  Q: (color) => ({
    buyConfirm: `<span style="color: ${color}">{{}} Altın</span> harcayarak <span style="color: ${color}">{{}} Oyun Jetonu</span> satın almayı onaylıyor musunuz？`,
    buySuccess: `Satın alma başarılı！`,
    exchangeSuccess: `Ödeme başarılı！`,
    coinShort: 'Altın yetersiz'
  }),
  hw: (color) => ({
    buyConfirm: `确认花费<span style="color: ${color}">{{}}金币购买{{}}个{{}}</span>吗？`,
    buySuccess: `购买成功！`,
    exchangeSuccess: `兑换成功！`,
    coinShort: '金币不足'
  }),
  C: (color) => ({
    buyConfirm: `確認花費<span style="color: ${color}">{{}}金幣購買{{}}個{{}}</span>嗎？`,
    buySuccess: `購買成功！`,
    exchangeSuccess: `兌換成功！`,
    coinShort: '金幣不足'
  }),
  J: (color) => ({
    buyConfirm: `<span style="color: ${color}">{{}}ゴールド</span>を消費して<span style="color: ${color}">{{}}個の<br>{{}}</span>を購入しますか?`,
    buySuccess: `購入しました`,
    exchangeSuccess: `引き換えました`,
    coinShort: '' // TODO
  }),
  K: (color) => ({
    buyConfirm: `<span style="color: ${color}">{{}}코인</span>을 소모해 <span style="color: ${color}">{{}} {{}}개</span>를<br>구매하시겠습니까?`,
    buySuccess: `구매 성공`,
    exchangeSuccess: `교환 성공`,
    coinShort: '코인 부족! 먼저 충전하세요~'
  }),
  A: (color) => ({
    // 新标准
    buyConfirm: `هل أنت متأكد من استهلاك&nbsp;<span style="color: ${color}">{{}} عملة ذهبية</span>&nbsp;لشراء&nbsp;<span style="color: ${color}">{{}} {{}}</span>؟`,
    // buyConfirm: `هل أنت متأكد من <span style="color: ${color}">استخدام {{}} عملة ذهبية<br>لشراء {{}} {{}}</span>؟`,
    buySuccess: `تم الشراء بنجاح!`,
    exchangeSuccess: `تم الاستبدال بنجاح!`,
    coinShort: 'العملات الذهبية غير كافية'
  }),
  M: () => {},
  T: () => {},
  U: (color) => ({
    buyConfirm: `Spend <span style="color: ${color}">{{}} Gold</span> for <span style="color: ${color}">{{}} {{}}</span>?`,
    buySuccess: `Buy Success`,
    exchangeSuccess: `Exchange Success`,
    coinShort: 'Your Gold is not enough, go recharge it~'
  })
}
export const genBuyTaskTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw

export const defaultImage = {
  // closeBtn: 'icon/close.png', // 关闭按钮

  bg1: 'buyTask/bg1.png', // 购买碎片tab的背景
  bg2: 'buyTask/bg2.png', // 每日任务tab的背景

  buyItemsBg: 'buyTask/buyItemsBg.png', // 多种购买碎片一整块的背景
  buyBtn: 'buyTask/buyBtn.png', // 购买碎片按钮

  buyConfirmDialog: 'buyTask/buyConfirmDialog.png', // 购买确认弹窗
  noBuyConfirm0: 'buyTask/noBuyConfirm-0.png', // 不再弹出确认购买弹窗-空
  noBuyConfirm1: 'buyTask/noBuyConfirm-1.png', // 不再弹出确认购买弹窗-勾选
  buyConfirmBtn: 'buyTask/buyConfirmBtn.png', // 购买确认按钮
  buyCancelBtn: 'buyTask/buyCancelBtn.png', // 购买取消按钮

  // 如果不需要金币不足弹窗，则不要传这两个
  coinShortDialog: 'buyTask/coinShortDialog', // 金币不足弹窗背景图
  toPayBtn: 'buyTask/toPayBtn', // 去充值按钮

  // 没有兑换相关的功能，则不需要传这两个
  exchange0Btn: 'buyTask/exchange-0.png', // 立即兑换按钮
  exchange1Btn: 'buyTask/exchange-1.png', // 立即兑换置灰按钮

  toFinish: 'buyTask/toFinish.png', // 去完成按钮
  receive: 'buyTask/receive.png', // 领取按钮
  completed: 'buyTask/completed.png' // 已完成按钮

  // task1~n不需要在此传递，会根据任务id去取
  // 'task1': 'buyTask/task1.png',
  // 'taskn': 'buyTask/taskn.png'
}

export const defaultTextColor = {
  coin: '#FFFFFF',
  chip1: '#FFFFFF',
  chip2: '#FFFFFF',
  buyConfirm: '#FFC283',
  buyHighlight: '#FFE03D',
  dailyTask: '#E07A96'
}

export const defaultSpecialStyle = {
  closeIcon: {},
  tab1Btn: {},
  tab2Btn: {},
  tab2RedDot: {},
  buy: {
    chip1: {},
    coin: {},
    addIcon: {},
    buyTag: {},
    buyListContainer: {}
  },
  buyConfirm: {
    text: {},
    tip: {}
  },
  coinShort: {
    toPayBtn: {}
  },
  task: {
    chip1: {},
    coin: {},
    addIcon: {},
    chip2: {},
    exchangeBtn: {},
    taskListContainer: {},
    taskItemContainer: {}
  }
}
