export default {
  namespaced: true,

  state: {
    // 奖励详情dialog
    uiRewardDetailDialog: {
      // 示例 rewardDetail代表对应组件的key
      rewardDetail: {
        // 打开弹窗
        open: () => {}
      }
    },

    // 戒指分享
    uiRingShare: {
      // 示例 ringShare代表对应组件的key
      ringShare: {
        // 保存到相册
        save2Album: () => {},
        // 分享到玩友圈
        share2Circle: () => {}
      }
    },

    // 碎片购买
    uiBuyChipDialog: {
      // 示例 buyChip代表对应组件的key
      buyChip: {
        // 更新二次确认弹窗状态
        updateStorage: () => {}
      }
    },

    // 任务中台
    uiBuyTask: {
      // 示例 buyTask代表对应组件的key
      buyTask: {
        // 打开任务中台
        open: () => {},
        close: () => {}
      }
    },

    // tab切换
    uiTabChange: {
      // 示例 lotteryTab代表对应组件的key
      lotteryTab: {
        // 打开任务中台
        tabChange: () => {}
      }
    },

    // 通用道具预览
    uiPropFcPreview: {
      openPreview: () => {}
    }
  },

  mutations: {
    // compName: state中的组件名， value => 对应compName的compKey的value 和原对象合并
    setUiCompConfig(state, { compName, compKey, value }) {
      if (compKey) {
        state[compName][compKey] = {
          ...state[compName][compKey],
          ...value
        }
      } else {
        state[compName] = {
          ...state[compName],
          ...value
        }
      }
    }
  },

  actions: {}
}
