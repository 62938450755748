import { methodsInit } from '../../utils'
import { amConfig } from '../config'

const {
  modules: {
    exchange: { request }
  }
} = amConfig

const { getGetters: getBasicGetters } = methodsInit('base')
export default {
  namespaced: true,

  state: {
    storeName: '',
    exchangeGoods: [] // 兑换的配置
  },

  getters: {
    amApiParam() {
      return getBasicGetters('amApiParam')
    },

    exchangeConfig({ exchangeGoods }) {
      return exchangeGoods.map((item) => {
        const { exchange_type, rewards, remain_exchange_count, is_own_all_avatar_prop } = item

        return {
          type: exchange_type,
          rewards: rewards,
          isCanExchange: !is_own_all_avatar_prop && remain_exchange_count
        }
      })
    }
  },

  mutations: {
    initExchangeConfig(state, storeName) {
      state.storeName = storeName
    },

    updateExchangeGoods(state, value) {
      state.exchangeGoods = value
    }
  },

  actions: {
    // 获取兑换商店配置
    async getExchangeConfig({ state, commit }) {
      const { store_infos } = await request.getExchangeConfig({ ...this.amApiParam, store_name: state.storeName })
      const exchange_goods = store_infos?.[0].exchange_goods || []
      commit('updateExchangeGoods', exchange_goods)
    },

    // 兑换
    async doExchange({ state, dispatch }, { type, count, targetUid = this.amApiParam.uid, ...extra }) {
      const { rewards } = await request.exchange({ ...this.amApiParam, store_name: state.storeName, exchange_type: type, exchange_count: count, target_uid: targetUid, ...extra })
      dispatch('getExchangeConfig')
      return rewards
    },

    // 批量兑换
    async doMultiExchange({ state, dispatch }, { targetUid = this.amApiParam.uid }) {
      const { rewards } = await request.multiExchange({ ...this.amApiParam, store_name: state.storeName, target_uid: targetUid })
      dispatch('getExchangeConfig')
      return rewards
    },

    // 查询兑换信息
    async getExchangeInfo({ state }, { type }) {
      const info = await request.getExchangeInfo({ ...this.amApiParam, store_name: state.storeName, exchange_type: type })
      return info
    },

    // 批量查询兑换信息
    // type string字符串 '1,2,3'
    async getExchangeListInfo({ state }, { type }) {
      const { list } = await request.getExchangeListInfo({ ...this.amApiParam, store_name: state.storeName, exchange_type: type })
      return list
    }
  }
}
