<template lang="pug">
canvas(:class='c_class', :width='useWidth', :height='useHeight', :style='`width: ${useWidth / mul}px; height: ${useHeight / mul}px;`')
</template>

<script>
const mul = 2

import { W, H } from '@/utils/env'
import { size } from '@/utils/set-font-size'

function getRemToPx(rem) {
  return size * rem * mul
}

export default {
  props: {
    // 类名
    c_class: {
      type: String,
      default: 'progress'
    },

    // 画曲线的list
    cList: {
      type: Array,
      default: () => []
    },

    // 进度条节点列表
    s_l: {
      type: Array,
      default: () => []
    },

    // 进度条底色
    bgColor: {
      type: String,
      default: ''
    },

    // 进度条亮色
    brightColor: {
      type: String,
      default: ''
    },

    // 起始点横轴坐标
    x: {
      type: Number,
      default: 0
    },

    // 起始点纵轴坐标
    y: {
      type: Number,
      default: 0
    },

    // 累计值
    val: {
      type: Number,
      default: 0
    },

    // 节点列表
    nodeList: {
      type: Array,
      default: () => []
    },

    width: {
      type: Number
    },

    height: {
      type: Number
    },

    // 末尾多加一段
    endLen: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      mul,
      ctx: null
    }
  },

  computed: {
    isAr() {
      return this.$route.query?.region === 'A'
    },

    useWidth() {
      return getRemToPx(this.width || 0) || W * mul
    },

    useHeight() {
      return getRemToPx(this.height || 0) || H * mul
    }
  },

  watch: {
    val() {
      this.setBright()
    },
    nodeList() {
      this.setBright()
    }
  },

  async mounted() {
    this.initCanvas()
  },

  methods: {
    initCanvas() {
      const canvas = document.getElementsByClassName(this.c_class)[0]
      if (canvas.getContext) {
        this.ctx = canvas.getContext('2d')
        this.createCurveAndPoint()
      }
    },

    // 获取每一段实际画的长度
    getLen(val, next, pre, l) {
      return val >= next ? l : ((val - pre) * l) / (next - pre)
    },

    // 获取当前累计值对应进度条的百分比
    getPercent() {
      let percent = 0
      let pre = { value: 0, percent: 0 }
      for (let i = 0; i < this.nodeList.length; i++) {
        const cur = this.nodeList[i]
        if (this.val >= cur.value) {
          percent = cur.percent
        } else {
          percent += ((cur.percent - pre.percent) * (this.val - pre.value)) / (cur.value - pre.value)
          break
        }
        pre = cur
      }

      return percent
    },

    // 设置已累计部分线段
    setBright() {
      this.getDrawLength(this.getPercent(), this.brightColor)
    },

    // 创建两个曲线和节点
    createCurveAndPoint() {
      this.getDrawLength(this.s_l[this.s_l.length - 1], this.bgColor)
      this.setBright()
    },

    // 画其中一段
    /**
     * 九个方向
     * lr: 从左到右 rl: 从右到左 ud: 从上到下 du: 从下到上(用不到, 未实现)
     * ald: 曲线从左到下 adr: 曲线从下到右 ard: 曲线从右到下 adl: 曲线从下到左
     * sud: 斜线从右上到左下(需要提供两个线段值)
     */
    drawPart(i, val, x, y) {
      const arr = this.cList[i]
      arr.forEach((item) => {
        const len = this.getLen(val, this.s_l[i + 1], this.s_l[i], item.l)
        const len2 = this.getLen(val, this.s_l[i + 1], this.s_l[i], item.l2)

        const map = {
          lr: () => {
            this.ctx.lineTo(getRemToPx(x + len), getRemToPx(y))
            x = x + len
          },
          rl: () => {
            this.ctx.lineTo(getRemToPx(x - len), getRemToPx(y))
            x = x - len
          },
          ud: () => {
            this.ctx.lineTo(getRemToPx(x), getRemToPx(y + len))
            y = y + len
          },
          ald: () => {
            this.ctx.arcTo(getRemToPx(x + item.l), getRemToPx(y), getRemToPx(x + item.l), getRemToPx(y + item.l), getRemToPx(item.l))
            x = x + item.l
            y = y + item.l
          },
          // 写错了，这里实际是adl
          adr: () => {
            this.ctx.arcTo(getRemToPx(x), getRemToPx(y + item.l), getRemToPx(x - item.l), getRemToPx(y + item.l), getRemToPx(item.l))
            x = x - item.l
            y = y + item.l
          },
          ard: () => {
            this.ctx.arcTo(getRemToPx(x - item.l), getRemToPx(y), getRemToPx(x - item.l), getRemToPx(y + item.l), getRemToPx(item.l))
            x = x - item.l
            y = y + item.l
          },
          // 写错了，这里实际是adr
          adl: () => {
            this.ctx.arcTo(getRemToPx(x), getRemToPx(y + item.l), getRemToPx(x + item.l), getRemToPx(y + item.l), getRemToPx(item.l))
            x = x + item.l
            y = y + item.l
          },
          sud: () => {
            this.ctx.lineTo(getRemToPx(x - len), getRemToPx(y + len2))
            x = x - len
            y = y + len2
          }
        }
        map[item.d]()
      })
      return [x, y]
    },

    getDrawLength(val, color) {
      let x = this.x
      let y = this.y
      if (val > this.s_l[0]) {
        // 画个半圆
        this.ctx.beginPath()
        this.ctx.strokeStyle = color
        this.ctx.fillStyle = color
        this.ctx.lineWidth = getRemToPx(0.05)
        this.ctx.arc(getRemToPx(x - (this.isAr ? 0.01 : 0)), getRemToPx(y), getRemToPx(0.05), (this.isAr ? 1.5 : 0.5) * Math.PI, (this.isAr ? 0.5 : 1.5) * Math.PI)
        this.ctx.fill()
        this.ctx.stroke()
        this.ctx.closePath()

        this.ctx.beginPath()
        this.ctx.strokeStyle = color
        this.ctx.lineWidth = getRemToPx(0.15) // 线段的粗细
        this.ctx.moveTo(getRemToPx(x), getRemToPx(y))
      }

      // 中间曲线部分
      for (let i = 0; i <= this.cList.length - 1; i++) {
        if (val > this.s_l[i]) {
          let [newX, newY] = this.drawPart(i, val, x, y)
          x = newX
          y = newY
        }
      }
      this.ctx.stroke()

      // 结尾多加一段
      if (this.endLen && val >= this.s_l[this.s_l.length - 1]) {
        this.ctx.closePath()
        this.ctx.beginPath()
        this.ctx.moveTo(getRemToPx(x), getRemToPx(y))
        this.ctx.strokeStyle = color
        this.ctx.lineWidth = getRemToPx(0.15)
        this.ctx.lineTo(getRemToPx(x + this.endLen), getRemToPx(y))
        x = x + this.endLen
        this.ctx.stroke()
        this.ctx.closePath()

        this.ctx.beginPath()
        this.ctx.lineWidth = getRemToPx(0.05)
        this.ctx.arc(getRemToPx(x), getRemToPx(y), getRemToPx(0.05), (this.isAr ? 1.5 : 0.5) * Math.PI, (this.isAr ? 0.5 : 1.5) * Math.PI)
        this.ctx.fill()
        this.ctx.stroke()
        this.ctx.closePath()
      }
    }
  }
}
</script>

<style lang="scss"></style>
