import { isA } from '../../utils/config'
import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'

const prl = isA ? 'pr' : 'pl'
const plr = isA ? 'pl' : 'pr'

const commonTextStyle = { fs: '0.26', fw: '400', fc: '#FFFFFF' }
const buyConfirmTipPtMap = {
  J: 2.63,
  K: 2.63,
  A: 2.63
}

export const buyStyleConfig = {
  closeStyle: { pt: -0.6, [plr]: 0 },
  coinStyle: { wh: '.87 .28', pt: 1.66, [prl]: 4.26, fs: 0.24 },
  coinWrap: { wh: '2.32 .56', pt: 1.5, [prl]: 3.59 },
  lotteryChipStyle: { wh: '1.5 .28', pt: 1.66, [prl]: 2.01, fs: 0.26, ta: isA ? 'r' : 'l' },
  scrollWrap: { pc: 'x', pt: 2.33, wh: '5.52 5.2' },
  buyItem: {
    wrap: { wh: '100% 1.12', mb: 0.18 },
    button: { [plr]: 0, pt: 0.25 },
    tag: { [plr]: 0, pt: -0.33, pz: 2 }
  }
}

const commonBtnStyleLeft = { pb: 0.74, pl: 0.69, 'bg-s': 'contain' }
const commonBtnStyleRight = { pb: 0.74, pr: 0.69, 'bg-s': 'contain' }

export const confirmStyleConfig = {
  text: { wh: '5.25 0.36', lh: '0.36', pc: 'x', pt: '1.28', ta: 'center', ...commonTextStyle, fw: '500' },
  tip: { pc: 'x', pt: buyConfirmTipPtMap[PAGE_REGION] || '2.42' },
  cancelButton: commonBtnStyleLeft,
  confirmButton: commonBtnStyleRight
}

export const textConfig = {
  hw: (color) => ({
    buyConfirm: `确认花费<span style="color: ${color}">{{}}金币购买{{}}个{{}}</span>吗？`,
    buySuccess: `购买成功！`,
    coinShort: '金币不足'
  }),
  C: (color) => ({
    buyConfirm: `確認花費<span style="color: ${color}">{{}}金幣購買{{}}個{{}}</span>嗎？`,
    buySuccess: `購買成功！`,
    coinShort: '金幣不足'
  }),
  J: (color) => ({
    buyConfirm: `<span style="color: ${color}">{{}}ゴールド</span>を消費して<span style="color: ${color}">{{}}個の<br>{{}}</span>を購入しますか?`,
    buySuccess: `購入しました`,
    coinShort: 'ゴールドが不十分'
  }),
  K: (color) => ({
    buyConfirm: `<span style="color: ${color}">{{}}코인</span>을 소모해 <span style="color: ${color}">{{}} {{}}개</span>를<br>구매하시겠습니까?`,
    buySuccess: `구매 성공`,
    coinShort: '코인 부족! 먼저 충전하세요~'
  }),
  A: (color) => ({
    // 新标准
    buyConfirm: `هل أنت متأكد من استهلاك&nbsp;<span style="color: ${color}">{{}} عملة ذهبية</span>&nbsp;لشراء&nbsp;<span style="color: ${color}">{{}} {{}}</span>؟`,
    // buyConfirm: `هل أنت متأكد من <span style="color: ${color}">استخدام {{}} عملة ذهبية<br>لشراء {{}} {{}}</span>؟`,
    buySuccess: `تم الشراء بنجاح!`,
    coinShort: 'العملات الذهبية غير كافية'
  }),
  M: () => {},
  T: () => {},
  U: (color) => ({
    buyConfirm: `Spend <span style="color: ${color}">{{}} Gold</span> for <span style="color: ${color}">{{}} {{}}</span>?`,
    buySuccess: `Buy Success`,
    coinShort: 'Your Gold is not enough, go recharge it~'
  }),
  P: (color) => ({
    buyConfirm: `Spend <span style="color: ${color}">{{}} Gold</span> for <span style="color: ${color}">{{}} {{}}</span>?`,
    buySuccess: `Buy Success`,
    coinShort: 'Your Gold is not enough, go recharge it~'
  })
}

export const genBuyTaskTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw

export const coinPoor = {
  hw: '金币不足',
  C: '金幣不足,請先購買金幣',
  J: 'ゴールドが不足です。先にゴールドを購入しましょう~',
  K: '코인 부족, 먼저 코인을 구매하세요',
  A: 'العملات الذهبية غير كافية، الرجاء شراء العملات الذهبية أولا',
  T: 'เหรียญทองไม่พอ โปรดซื้อเหรียญทองก่อน',
  M: 'Koin tidak mencukupi',
  V: 'Không đủ xu vàng, vui lòng nạp thêm',
  Q: 'Yetersiz altın, Lütfen önce altın satın alın.',
  R: 'Недостаточное кол-во монет, сначала пополните баланс',
  B: 'Moedas insuficientes, recarrege mais antes.',
  I: 'Insufficient Gold. Please purchase more Gold first'
}

export const genBuyChipTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw

export const getCoinPoorText = IS_WEPLAY ? coinPoor[PAGE_REGION] : coinPoor.hw
