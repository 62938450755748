<template lang="pug">
a-section.ui-recharge-wrapper(wh='7.5 9.34', v-bind='attrs')
  a-section.ui-recharge-wrapper__section(wh='6.36 9.34', pc='x')
    //- 进度条
    ui-recharge-progress.ui-recharge-wrapper__section__progress(v-bind='cumulativeConfig', :positionType='positionConfig', pt='2.77', pc='x', :uiMeta='uiMeta', :allText='allText')

    //- 进度文案
    a-section.txt-no-wrap.ui-recharge-wrapper__section__text(
      v-if='rechargeTextShowState.line1',
      :class='{ "txt-r": isAr }',
      :fs='customizeStyle.rechargeFontSize || rechargeTextStyle.fs',
      fw='600',
      :fc='customizeStyle.rechargeColor',
      h='.34',
      lh='.24',
      ta='l',
      pt='0',
      v-bind='isAr ? { pr: 0.13 } : { pl: 0.13 }',
      v-html='$text(text.cur, { coinNum: levelTaskInfo.value || 0 })'
    )
    a-section.ui-recharge-wrapper__section__text2(
      v-if='rechargeTextShowState.line2',
      :class='{ "txt-r": isAr }',
      w='4.2',
      :fs='customizeStyle.rechargeFontSize || rechargeTextStyle.fs',
      fw='600',
      :fc='customizeStyle.rechargeColor',
      h='.34',
      lh='.24',
      ta='l',
      pt='.44',
      v-bind='isAr ? { pr: 0.13 } : { pl: 0.13 }',
      v-html='$text(text.next, { coinNum: nextNeedValue })'
    )
    //- 领取按钮 state：0-未完成，1-可领取，2-已领完
    a-position.ui-recharge-wrapper__section__button(v-if='showReceive', v-bind='isAr ? { pl: 0.17 } : { pr: 0.17 }', pt='0', wh='1.66 .72', @a-tap='doPoint()')
      a-button1.ui-recharge-wrapper__section__button--1(v-show='levelTaskInfo.state === 1', :bg-i='images[taskState]?.receiveBtn', bg-s='100%', :throttle='1000', @a-tap='getReward()')
      a-position.ui-recharge-wrapper__section__button--2(v-show='!levelTaskInfo.state', :bg-i='images[taskState]?.receiveBtn', bg-s='100%')
      a-position.ui-recharge-wrapper__section__button--3(v-show='levelTaskInfo.state === 2', :bg-i='images[taskState]?.receiveBtn', bg-s='100%')
    //- 去充值按钮
    a-button1.ui-recharge-wrapper__section__pay(v-if='showRecharge', :bg-i='images.toRecharge', bg-s='100%', pc='x', pb='0', @a-tap='btnJump')

    ui-normal-reward-dialog.ui-recharge-wrapper__section__reward-dialog(ref='reward-dialog', :uiMeta='rewardDialogConfig')
</template>

<script>
import get from 'lodash/get'

import { PAGE_REGION } from '@/utils/env'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { consts, stateMap, rewardDialogDefaultConfig, rechargeTextStyle } from './utils/rechargeWrapperConfig'
import { getCumulativeConfig, getNodeConfig, genCumulativeConfig } from './utils/rechargeCumulativeConfig'
import { genPositionConfig } from './utils/rechargePositionConfig'
import { genPositionConfigA } from './utils/rechargePositionConfigA'
import { genComponentCurRegionText } from '@sub/utils/page-center-snapshot'
import { standardTapAction } from '@sub/ui-runtime/core/utils/components/button'

import { logData } from '@sub/ui-runtime/core/utils/logData'
import { getRewardMeta, formatRewardId } from '@sub/utils/reward'

export default {
  mixins: [attrs],

  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    },

    isOpenEffect: {
      type: Boolean,
      default: false
    },

    context: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      rechargeTextStyle,
      cumulativeConfig: genCumulativeConfig(),
      consts,
      hidden: true,
      rewardDialogConfig: {},
      taskType: '',
      positionConfig: PAGE_REGION === 'A' ? genPositionConfigA() : genPositionConfig()
    }
  },

  async created() {
    if (!this.taskId) return
    this.$store.commit('task/initLevelTaskItemDefault', this.taskId)
    this.setConfig()
    this.init()
  },

  onPageShow() {
    this.getTaskStatus()
  },

  computed: {
    isAr() {
      return this.$route.query?.region === 'A'
    },

    allText() {
      return genComponentCurRegionText(this.uiMeta.type, this.context, { color: this.customizeStyle.rechargeCoinColor, fontSize: this.customizeStyle.rechargeCoinFontSize })
    },

    text({ allText, taskType }) {
      let region = this.$route.query.region || 'U'

      if (region === 'M') {
        region = this.context.lang
      }

      const map = {
        charge: 1,
        cost_coin: 2,
        send_gift: 3
      }
      const { curTotal, nextNeed } = this.uiMeta.props?.text || {}
      const { rechargeCoinColor: color, rechargeCoinFontSize: fontSize } = this.customizeStyle

      return {
        cur: this.$text(curTotal, { color, fontSize }) || get(allText, consts.totalCoin[map[taskType]], ''),
        next: this.$text(nextNeed, { color, fontSize }) || get(allText, consts.nextReward[map[taskType]], '')
      }
    },

    events({ uiMeta }) {
      return get(uiMeta, 'events', {
        tap: [
          {
            type: 'deepLink',
            payload: { link: 'wespydeeplink://pay', type: 'pay' }
          }
        ]
      })
    },

    rewardDialogKey() {
      return `${this.uiMeta.type}-reward-dialog-${this.uiMeta.key}`
    },

    images({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {})
    },

    propsConfig({ uiMeta }) {
      return get(uiMeta, 'props.propsConfig', [])
    },

    taskId({ uiMeta }) {
      return get(uiMeta, 'props.taskId', 0)
    },

    showReceive({ uiMeta }) {
      return get(uiMeta, 'props.showReceive', true)
    },

    showRecharge({ uiMeta }) {
      return get(uiMeta, 'props.showRecharge', true)
    },

    isRuntime() {
      return this.$base.extra().isRuntime
    },

    levelTaskInfo() {
      return this.$task.levelTasksInfo()[this.taskId] || {}
    },

    levelTaskConfig() {
      return this.$task.taskConfig()[this.taskId] || { rewards: [] }
    },

    taskState() {
      return stateMap[this.levelTaskInfo.state]
    },

    userGender() {
      return this.$base.amBasic().user.gender
    },

    levelTaskValues({ levelTaskConfig }) {
      return levelTaskConfig?.stages ? levelTaskConfig.stages.map((item) => item.condition_map[1]) : []
    },

    nextNeedValue() {
      return this.$differenceCalculate(this.levelTaskValues, this.levelTaskInfo.value)
    },

    line1Type({ uiMeta }) {
      return get(uiMeta, 'props.propsConfig.row1Config', '')
    },

    line3Type({ uiMeta }) {
      return get(uiMeta, 'props.propsConfig.row3Config', '')
    },

    rechargeTextShowState({ levelTaskInfo }) {
      const lastIdx = levelTaskInfo.stages?.length - 1
      const totalState = levelTaskInfo.name && !levelTaskInfo.stages[lastIdx].state

      // 达标后则隐藏
      const defaultConfig = { line1: totalState, line2: totalState }
      return defaultConfig

      // const regionConfig = {
      //   HW: { line1: totalState, line2: totalState },
      //   K: { line1: totalState, line2: totalState }
      // }
      // return get(regionConfig, this._region, defaultConfig) || defaultConfig
    },

    logParams({ uiMeta }) {
      return get(uiMeta, 'props.logParams', {}) || {}
    },

    rewardSlotList({ uiMeta }) {
      return get(uiMeta, 'props.propsConfig.rewardSlotList', []).flat()
    },
    rewardMetaList() {
      return this.rewardSlotList.map((rewardSlot, index) => {
        const rewardMeta = getRewardMeta(rewardSlot)
        const rewardItem = this.levelTaskConfig.rewards?.[index] || {}

        return {
          rewardSlot,
          ...rewardMeta,
          // 这个套件的特化字段
          unit: this.$getPropUnit(formatRewardId(rewardItem), rewardItem.reward_val, rewardItem.reward_type)
        }
      })
    },
    rewardMetaMap() {
      return this.$reward.rewardMetaMap()
    }
  },

  watch: {
    rewardMetaList: {
      handler() {
        if (!this.taskId) return
        this.setRechargeGiftList()
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    async init() {
      await Promise.all([this.$task.getTaskConfig(), this.getTaskStatus()])

      // 节点
      this.setNodeList()
      this.taskType = get(this.levelTaskConfig, 'condition_config.condition_list[0].trigger.type', '')
    },

    // 获取任务状态
    async getTaskStatus() {
      await this.$store.dispatch('task/getTasksGroupList')
    },

    // 领取奖励
    async getReward() {
      try {
        this.$showLoading()
        const { rewards } = await this.$store.dispatch('task/recvTaskReward', this.taskId)
        this.$hideLoading()

        this.$dialogs[this.rewardDialogKey].open({
          data: {
            rewardList: rewards.map((item) => {
              return {
                ...item,
                ...this.rewardMetaMap[formatRewardId(item)],
                reward_name: item.reward_type_name,
                unit: this.$getPropUnit(formatRewardId(item), item.reward_val, this.rewardMetaMap[item.reward_id]?.type)
              }
            })
          }
        })
        this.getTaskStatus()
        if (rewards.some((item) => item.reward_type === 'collect_chip')) {
          this.$base.getChipInfo()
          this.$base.getChipTotalInfo()
        }
      } catch (err) {
        this.$hideLoading()
        console.error('[ui-recharge-wrapper][getReward] has Error', err)
        logData('getReward has Error', err.message, { btn_name_alias: '累计任务-全部领取有报错' })
      }
    },

    setConfig() {
      // 曲线
      this.setCumulativeType()
      // 节点
      // this.setNodeList()

      this.$set(this.cumulativeConfig, 'c_class', `progress-canvas-${this.uiMeta.key}`)
      this.$set(this.cumulativeConfig, 'taskId', this.taskId)
      this.$set(this.cumulativeConfig, 'bgColor', this.customizeStyle.progressBgColor)
      this.$set(this.cumulativeConfig, 'brightColor', this.customizeStyle.progressColor)

      // 奖励弹窗
      this.rewardDialogConfig = get(this.uiMeta.props, 'rewardsDialog', {})
      this.$set(this.rewardDialogConfig, 'key', this.rewardDialogKey)
      this.$set(this.rewardDialogConfig, 'props', { ...this.rewardDialogConfig.props, ...rewardDialogDefaultConfig })
    },

    setCumulativeType() {
      try {
        this.$set(this.cumulativeConfig, 'cumulativeType', getCumulativeConfig(this.line1Type))
      } catch (e) {
        console.error(`可能是 line1Type=${this.line1Type} 暂未支持`, '\n', '[ui-recharge-wrapper][setCumulativeType] has error: ', e)
      }
    },

    setNodeList() {
      try {
        this.$set(
          this.cumulativeConfig,
          'nodeList',
          getNodeConfig(this.line1Type).map((item, index) => {
            return {
              ...item,
              // 依赖接口 task_group/config
              value: this.levelTaskValues[index]
            }
          })
        )
      } catch (e) {
        console.error(`可能是 line1Type=${this.line1Type} 暂未支持`, '\n', '[ui-recharge-wrapper][setNodeList] has error: ', e)
      }
    },

    setRechargeGiftList() {
      try {
        const { getRechargeGiftList } = this.positionConfig
        this.$set(
          this.positionConfig,
          'rechargeGiftList',
          getRechargeGiftList({
            line1Type: this.line1Type,
            line3Type: this.line3Type,
            rewardConfig: this.rewardMetaList
          })
        )
      } catch (e) {
        console.error(`可能是 line1Type=${this.line1Type} 或 line3Type=${this.line3Type} 暂未支持`, '\n', '[ui-recharge-wrapper][setRechargeGiftList] has error: ', e)
      }
    },

    btnJump() {
      standardTapAction(
        this,
        {},
        {
          tap: this.events.tap
        }
      )
    },

    doPoint() {
      const btnNameMap = {
        0: '未达到',
        1: '全部领取',
        2: '已领取'
      }

      logData(btnNameMap[this.levelTaskInfo.state], this.levelTaskInfo.value, { btn_name_alias: '领取按钮', screen_name: '累计套件' })
    }
  }
}
</script>
<style lang="scss" scoped>
.ui-recharge-wrapper {
  overflow: hidden;
}
</style>
