export default [
  {
    path: '/activity/v2/ambassador/main-j/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      quickConfig: '347732eee22134dd9273f918fff6a2c9'
    }
  }
]
