export default {
  namespaced: true,

  state: {
    curTabIndex: 0,
    // FIXME: 临时解决方案，用于复写 tab 的样式 { [tabIndex]: [tabPageStyle] }
    tabPageStyleMap: {
      0: {},
      1: {}
    },

    event: {
      loadRankSingleTop3: () => {} // 刷新单人榜单数据（ui-rank-single-top3）
    },

    uiMetaPageCenterSnapshot: {}
  },

  getters: {},

  mutations: {
    setState(state, payload = {}) {
      Object.assign(state, payload)
    },

    setEvent(state, payload = {}) {
      state.event = { ...state.event, ...payload }
    }
  },

  actions: {}
}
