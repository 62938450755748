export default [
  {
    path: '/task',
    component: () => import('./task/index.vue'),
    meta: {
      id: 771,
      quickConfig: '0e921d7370726824aa73431fb37637ba'
    }
  },

  {
    path: '/reward-dialog',
    component: () => import('./reward-dialog/index.vue'),
    meta: {
      quickConfig: '0e921d7370726824aa73431fb37637ba'
    }
  },

  {
    path: '/team4',
    component: () => import('./team/members-4.vue'),
    meta: {
      id: 774,
      ignore: true,
      quickConfig: '2e48241f857edea7a3441b43029dbb63'
    }
  },

  {
    path: '/team2',
    component: () => import('./team/members-2.vue'),
    meta: {
      id: 909,
      ignore: true,
      pageCenter: '0hEYdLQA'
    }
  },
  {
    path: '/reward-detail-dialog',
    component: () => import('./reward-detail-dialog/index.vue'),
    meta: {
      ignore: true,
      pageCenter: 'x9SIU3dn'
    }
  },
  {
    path: '/record',
    component: () => import('./record/index.vue'),
    meta: {
      id: 827,
      ignore: true,
      pageCenter: 'riYsU3Vf'
    }
  },
  {
    path: '/attack-animation',
    component: () => import('./animation/attack.vue'),
    meta: {
      id: 827,
      ignore: true,
      pageCenter: 'riYsU3Vf'
    }
  },

  {
    path: '/reward-detail-dialog',
    component: () => import('./reward-detail-dialog/index.vue'),
    meta: {
      ignore: true,
      pageCenter: 'x9SIU3dn'
    }
  },
  {
    path: '/pag-animation',
    component: () => import('./animation/pag.vue'),
    meta: {
      id: 1400,
      ignore: true,
      pageCenter: 'riYsU3Vf'
    }
  },
  {
    path: '/buy-chip',
    component: () => import('./buy/buy-chip.vue'),
    meta: {
      id: 827,
      ignore: true,
      pageCenter: 'TC0dO55o'
    }
  },
  {
    path: '/lottery',
    component: () => import('./lottery/marquee-lottery.vue'),
    meta: {
      id: 916,
      ignore: true,
      pageCenter: 'TC0dO55o'
    }
  },
  {
    path: '/tab',
    component: () => import('./tab'),
    meta: {
      id: 916,
      ignore: true,
      pageCenter: '2xZL26Yc'
    }
  },
  {
    path: '/ring-share',
    component: () => import('./ring-share'),
    meta: {
      id: 916,
      ignore: true,
      pageCenter: 'aBa2A8Ud'
    }
  },
  {
    path: '/static-dialogs',
    component: () => import('./static-dialog'),
    meta: {
      id: 916,
      ignore: true,
      pageCenter: 'aIi3UQbS'
    }
  },
  {
    path: '/template-recharge',
    component: () => import('./recharge/template-recharge.vue'),
    meta: {
      id: 787,
      ignore: true,
      pageCenter: 'vX5Qb24W'
    }
  },
  {
    path: '/tab',
    component: () => import('./tab'),
    meta: {
      id: 916,
      ignore: true,
      pageCenter: '2xZL26Yc'
    }
  },
  {
    path: '/box-lottery',
    component: () => import('./lottery/box-lottery.vue'),
    meta: {
      id: 1225,
      ignore: true,
      pageCenter: 'sPzNWNh2'
    }
  },
  {
    path: '/vote-wrapper',
    component: () => import('./vote-wrapper/index.vue'),
    meta: {
      id: 1601,
      ignore: true,
      pageCenter: 'C3DEyIB6'
    }
  },
  {
    path: '/image-test',
    component: () => import('./img/index.vue'),
    meta: {
      ignore: true,
      pageCenter: 'NAdG471G'
    }
  },
  {
    path: '/chip-state',
    component: () => import('./chip/chip-state.vue'),
    meta: {
      id: 1754,
      ignore: true,
      pageCenter: 'C3DEyIB6'
    }
  },
  {
    path: '/button-lottery',
    component: () => import('./lottery/b-button-lottery-wrapper.vue'),
    meta: {
      id: 1754,
      ignore: true,
      pageCenter: 'sPzNWNh2'
    }
  },
  {
    path: '/reward-swiper-no-back',
    component: () => import('./reward/reward-swiper-no-back.vue'),
    meta: {
      id: 1754,
      ignore: true,
      pageCenter: 'NAdG471G'
    }
  }
]
