<template lang="pug">
a-section.ui-reward-swiper(v-bind='{ ...attrs, ...listWrapStyle }')
  a-position.ui-reward-swiper__swiper-wrapper(wh='100%', pc='x')
    swiper.swiper-reward-detail.ui-reward-swiper__swiper-wrapper__swiper-list(ref='mySwiper', :options='swiperOptions', @click-slide='preview')
      swiper-slide.ui-reward-swiper__swiper-wrapper__swiper-list__item(v-for='(r, i) in rewardsList', :key='i')
        slot(v-if='$scopedSlots["rewardItem"]', name='rewardItem', v-bind='{ data: r }')
        a-position.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper(v-else, v-bind='{ w: 1.4, h: 1.4, ...getCustomizeStyle("rewardItemStyle") }', pc='x', :pt='rewardItemPt')
          //- 默认奖励背景图
          a-img.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper__img--1(v-if='images.rewardItemBg', :src='getAssets(images.rewardItemBg)', pc, wh='100%')
          //- 已获取状态奖励背景盖板图
          a-img.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper__img--2(v-if='images.rewardItemBgGained && r.isGained', :src='getAssets(images.rewardItemBgGained)', pc, wh='100%')
          //- 奖励
          ui-prop.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper__prop(:data='r', v-bind='{ wh: "100%", ...getCustomizeStyle("rewardStyle") }', pc, :extra='{ canPreview: false, stylesConfig }')
            a-position.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper__prop__position(wh='100%', pc)
          //- 角标
          ui-text.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper__unit(
            v-if='r.unit',
            v-bind='{ ...rewardSwiperStyle.subscriptStyle, fc: subscriptStyle.color, strokeColor: subscriptStyle.borderColor, ...getCustomizeStyle("subscriptPosition") }',
            :style='{ ...rewardSwiperStyle.subscriptStyle, ...getCustomizeStyle("subscriptPosition"), strokeColor: undefined, strokeWidth: undefined }',
            :content='$getPropUnit(r.id, r.unit)'
          )
          //- 奖励名称
          ui-text.txt-c.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper__reward-name(
            v-bind='{ ...rewardSwiperStyle.rewardNameStyle, fc: textStyle.rewardNameColor, ...getCustomizeStyle("rewardNameStyle") }',
            :style='{ ...rewardSwiperStyle.rewardNameStyle, ...getCustomizeStyle("rewardNameStyle"), strokeColor: undefined, strokeWidth: undefined }',
            :content='isRuntime ? r.name : r[textStyle.name || "name1"]'
          )
          //- 标签
          a-img.ui-reward-swiper__swiper-wrapper__swiper-list__item__wrapper__label-icon(v-if='r.labelIcon', ref='labelIcon', v-bind='{ ...rewardSwiperStyle.labelIconStyle, ...getCustomizeStyle("labelIconStyle") }', :src='r.labelIcon', bg-s='contain')

  a-dialog.ui-reward-swiper__dialog(ref='swiper-reward-preview', name='swiperRewardPreview', wh='6.06 4.48')
    template(slot-scope='scope', v-if='scope.data')
      a-position.ui-reward-swiper__dialog__prop-wrapper(wh='6.06 2.88', pc='x', pt='0', bdr='.18rem .18rem 0 0', bg-c='#B5D3FF')
        ui-prop.ui-reward-swiper__dialog__prop-wrapper__prop(:data='scope.data', wh='2.5', pc, :extra='{ canPreview: false }')
      a-position.fx-col.fx-cl-center.fx-m-around.fx-c-center.ui-reward-swiper__dialog__name-wrapper(wh='6.06 1.6', pc='x', pb='0', pdt='.2', pdb='.2', bdr='0 0 .18rem .18rem', bg-c='#fff')
        a-section.txt-c.ui-reward-swiper__dialog__name-wrapper__name(w='5', f='.32 #333333 500') {{ scope.data.name }}
</template>

<script>
import { get } from 'lodash'
import { rewardSwiperStyle } from './utils/reward-swiper-config'
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  mixins: [attrs],

  components: { Swiper, SwiperSlide },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    rewards: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      rewardSwiperStyle
    }
  },

  computed: {
    images({ uiMeta }) {
      return get(uiMeta, 'props.image', {})
    },

    stylesConfig({ uiMeta }) {
      return get(uiMeta, 'props.stylesConfig', {}) || {}
    },

    listWrapStyle({ uiMeta }) {
      return get(uiMeta, 'props.listWrapStyle', {})
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.textStyle', {})
    },

    isRuntime() {
      return this.$base.extra().isRuntime
    },

    rewardsList({ uiMeta }) {
      if (this.rewards?.length) {
        return this.rewards
      } else {
        const typeMap = {
          title: 999,
          gift: 1000
        }
        return get(uiMeta, 'props.rewardsList', []).map((item) => {
          return {
            ...item,
            type: typeMap[item.type_str] || item.type
          }
        })
      }
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {}) || {}
    },

    subscriptStyle({ uiMeta }) {
      return get(uiMeta, 'props.subscriptStyle', {}) || {}
    },

    autoplayDelay({ uiMeta }) {
      return get(uiMeta, 'props.autoplayDelay', 0)
    },

    swiperOptions({ uiMeta, autoplayDelay }) {
      const wrapperWidth = this.attrs.w || this.attrs.wh?.split(' ')?.[0] || 7
      const rewardItemWidth = +this.getCustomizeStyle('rewardItemStyle')?.w || 1.4
      // 根据单个奖励大小自动计算每页显示的奖励个数
      const slidesPerView = +wrapperWidth?.replace('rem', '') / (rewardItemWidth + rewardItemWidth / 8)

      const defaultOptions = {
        speed: 200,
        loop: false,
        slidesPerView,
        direction: 'horizontal',
        autoplay: autoplayDelay
          ? {
              disableOnInteraction: false,
              delay: autoplayDelay * 1000
            }
          : false
      }

      return {
        ...defaultOptions,
        ...get(uiMeta, 'props.swiperOptions', {})
      }
    },

    rewardItemPt() {
      const pt = this.getCustomizeStyle('labelIconStyle')?.pt || rewardSwiperStyle.labelIconStyle.pt || 0
      const haveLabelIcon = this.rewardsList.some((item) => item.labelIcon)

      return haveLabelIcon ? Math.abs(pt) : 0
    }
  },

  created() {},

  mounted() {
    this.$fixDialogPosition(this.$getRef('swiper-reward-preview').$el)
  },

  methods: {
    getAssets(key) {
      return get(this.$route.meta.assets, key, key) || key
    },

    preview(idx) {
      const data = this.rewardsList[idx] || {}
      console.log('🐲 gwl ~ preview ~ data:', data)
      const { id, type } = data

      if (!id && !type) {
        return
      }

      // 实物奖励预览
      if (+type === 4) {
        this.$dialogs.swiperRewardPreview.open({ data: this.rewardsList[idx], options: { bgClose: true } })
      } else {
        this.$propPreview(id, this.isRuntime ? { data } : undefined)
      }
    },

    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-reward-detail {
  width: 100%;
  height: 100%;
}
</style>
