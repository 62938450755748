export default [
  {
    path: `/activity/v2/20240410/gategory-j/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 1820,
      // TODO:
      pageCenter: 'AjsxxJYw'
    }
  }
]
