import { IS_WEPLAY, PAGE_REGION } from '@/utils/env'
import { isA } from '../../utils/config'
import proxyAssets from '@sub/utils/proxy-assets'

const commonBtnStyleLeft = { pb: 0.74, pl: 0.69, 'bg-s': 'contain' }
const commonBtnStyleRight = { pb: 0.74, pr: 0.69, 'bg-s': 'contain' }
const commonAttr = isA ? 'pl' : 'pr'

const recvTextFs = {
  M: 0.28,
  A: 0.28,
  J: 0.24,
  U: 0.24
}
const recvInvAvatarPt = {
  U: 2.18,
  A: 2.59,
  HW: 1.73
}

const uiTeamJoinSuccess = {
  A: { fw: '700', fs: 0.24 }
}
const confirmTextWrap = {
  U: { wh: '5.6 1.56', pt: 1.46 },
  A: { wh: '5.6 1.56', pt: 1.46 }
}
const inviteFailTextWrap = {
  A: { pt: 1.11, pb: 0 },
  M: { lh: 0.28 },
  U: { w: 5.6, pb: 3.87, fs: 0.2, lh: 0.32 }
}

export const teamStyleConfig = {
  uiTeamInviteFriends: {
    teamMembersWrap: { wh: '1.5 2', pt: 2.52, [isA ? 'pr' : 'pl']: 1.33 },
    avatarStyle: { wh: 1.5, pt: 0, pc: 'x' },
    nickname: { w: '100%', pb: 0 },
    lookBtn: commonBtnStyleLeft,
    inviteFriends: commonBtnStyleRight
  },

  uiTeamSearchContacts: {
    closeBtn: { [commonAttr]: 0, pt: -0.6, pz: 10 },
    searchInputStyle: {
      wrap: { wh: '5 .68', pc: 'x', pt: 1.58 },
      input: { [isA ? 'paddingRight' : 'paddingLeft']: '0.72rem' }
    },
    searchList: {
      wrap: { wh: '5.4 4.16', pc: 'x', pt: 2.93 },
      item: { wh: '100% 1.06' },
      avatarStyle: { wh: '.84', [isA ? 'pr' : 'pl']: 0.2, pt: 0.08 },
      nickname: { w: 1.6, [isA ? 'pr' : 'pl']: 1.14, pt: 0.32, ta: isA ? 'r' : 'l' },
      line: { pc: 'x', pb: 0 },
      inviteBtn: { wh: '1.14 .5', [isA ? 'pr' : 'pl']: 4.06, pt: 0.25 },
      emptyText: { w: 4, pc: 'pc' }
    },
    close: { pt: 0, [isA ? 'pl' : 'pr']: 0.3 }
  },

  uiTeamInviteConfirm: {
    userStyle: {
      avatar: { wh: 1.2, pc: 'x', pt: PAGE_REGION === 'A' ? 1.68 : 1.73 },
      nickname: { pt: PAGE_REGION === 'A' ? 3.08 : 3.05, w: 2, pc: 'x' }
    },
    cancelBtn: commonBtnStyleLeft,
    inviteFriends: commonBtnStyleRight
  },

  uiTeamRecvInvitation: {
    recvTextStyle: { w: 5.6, pt: PAGE_REGION === 'A' ? 1.07 : 1.04, fs: recvTextFs[PAGE_REGION] || 0.32, fw: 600, pc: 'x', ta: 'c' },
    userStyle: {
      avatar: { wh: 1.2, pc: 'x', pt: recvInvAvatarPt[PAGE_REGION] || 2.15 },
      nickname: { pt: (recvInvAvatarPt[PAGE_REGION] || 2.15) + 1.32, w: 2, pc: 'x' }
    },
    rejectBtn: commonBtnStyleLeft,
    agreeBtn: commonBtnStyleRight
  },

  uiTeamConfirm: {
    textWrap: { wh: '5.6 .76', pc: 'x', pt: 1.7, fs: 0.28, lh: 0.38, ...(confirmTextWrap[PAGE_REGION] || {}) },
    cancelBtn: commonBtnStyleLeft,
    confirmBtn: commonBtnStyleRight
  },

  uiTeamJoinSuccess: {
    teamNameWrap: { wh: '2.02 .58', lh: 0.58, fw: 500, pt: 2.84, pc: 'x', pz: 1, ...(uiTeamJoinSuccess[PAGE_REGION] || {}) },
    teamMembersStyle: {
      wrap: { wh: '3.88 2', pt: 3.08, pc: 'x' },
      item: { wh: '1.5 2' },
      avatar: { wh: 1.5, pt: 0 },
      nickname: { w: '100%', pt: 1.66 }
    },
    successBtn: { pc: 'x', pb: 0.74 }
  },

  uiTeamInviteFail: {
    textWrap: { w: 5.5, pb: 4.02, pc: 'x', lh: 0.35, ...(inviteFailTextWrap[PAGE_REGION] || {}) },
    userStyle: {
      avatar: { wh: 1.2, pc: 'x', pt: PAGE_REGION === 'A' ? 2.32 : 2.08 },
      nickname: { pt: PAGE_REGION === 'A' ? 3.64 : 3.4, w: 2, pc: 'x' }
    },
    okBtn: { pc: 'x', pb: 0.74 }
  },

  uiTeamEditTeamName: {
    closeBtn: { [commonAttr]: 0, pt: -0.6, pz: 10 },
    input: { wh: '4.5 1.21', pc: 'x', pt: 1.8 },
    confirmLong: { pc: 'x', pb: 0.74 }
  }
}

const textConfig = {
  hw: (color) => ({
    searchPlaceholder: '搜索最近联系人',
    searchListEmpty: '暂无最近联系人',
    sendInvite: '组队邀请已发出～',
    recvInvitationText: '“{{}}”邀你一起组队',
    agreeConfirm: `确认和“<span style="color:${color}">{{}}</span>”组队吗？<br/>确认组队后，队伍<span style="color:${color}">不可更改</span>`,
    rejectConfirm: `真的要狠心拒绝“<span style="color:${color}">{{}}</span>”的组队邀请吗？<br/>拒绝后不可更改`,
    otherTeamFull: `玩家“<span style="color:${color}">{{}}”</span>邀请你组队<br/>由于对方队伍满员，<span style="color:${color}">该请求自动失效</span>。`,
    youHaveTeam: `玩家“<span style="color:${color}">{{}}”</span>邀请你组队<br/>由于您已组队，<span style="color:${color}">该请求自动失效</span>。`,
    editNamePlaceholder: '请输入队伍名',
    editEmptyTip: '队名不能为空',
    haveRejected: '您已经拒绝过他了'
  }),
  C: (color) => ({
    searchPlaceholder: '搜索最近聯繫人',
    searchListEmpty: '暫無最近聯繫人',
    sendInvite: '組隊邀請已發出～',
    recvInvitationText: '“{{}}“邀你一起組隊',
    agreeConfirm: `確認和“<span style="color:${color}">{{}}</span>”組隊嗎？<br/>確認組隊後，隊伍<span style="color:${color}">不可更改</span>`,
    rejectConfirm: `真的要狠心拒絕“<span style="color:${color}">{{}}</span>”的組隊邀請嗎？<br/>拒絕後不可更改`,
    otherTeamFull: `玩家“<span style="color:${color}">{{}}”</span>邀請你組隊<br/>由於對方已組隊，<span style="color:${color}">該請求自動失效</span>。`,
    youHaveTeam: `玩家“<span style="color:${color}">{{}}”</span>邀請你組隊<br/>由於您已組隊，<span style="color:${color}">該請求自動失效</span>。`,
    editNamePlaceholder: '請輸入隊伍名稱',
    editEmptyTip: '隊名不能為空',
    haveRejected: '您已經拒絕過他了'
  }),
  J: (color) => ({
    searchPlaceholder: '検索',
    searchListEmpty: '連絡先候補からチームを結成できるフレンドがいません',
    sendInvite: 'チーム招待を送信しました～',
    recvInvitationText: '{{}}はあなたをチームに招待しています',
    agreeConfirm: `<span style="color:${color}">{{}}</span>とチームを結成しますか？<br/>  確認すると<span style="color:${color}">変更できませんよ〜</span>`,
    rejectConfirm: `<span style="color:${color}">{{}}</span>からのチーム招待を拒否しますか？<br/>拒否すると変更できませんよ〜`,
    otherTeamFull: `<span style="color:${color}">{{}}があなたをチームに招待していました</span>相手が既にチームを結成したため、<span style="color:${color}">この招待は自動的に無効になりました。</span>。`,
    youHaveTeam: `<span style="color:${color}">{{}}があなたをチームに招待していました</span>あなたが既にチームを結成したため、<span style="color:${color}">この招待は自動的に無効になりました。</span>。`,
    editNamePlaceholder: 'チーム名を入力してください',
    editEmptyTip: 'チーム名を入力してください',
    haveRejected: 'すでに拒否しました'
  }),
  K: (color) => ({
    searchPlaceholder: '최근 연락한 친구 검색',
    searchListEmpty: '최근 연락한 사람이 없습니다!',
    sendInvite: '초대장을 이미 발송되었습니다~',
    recvInvitationText: '{{}}님의 초대장',
    agreeConfirm: `<span style="color:${color}">{{}}님</span>과 팀을 구성하시겠어요?<br/><span style="color:${color}">확인 후 변경 불가</span>`,
    rejectConfirm: `<span style="color:${color}">{{}}님</span>의 팀 초대를 거절하시겠어요?<br/><span style="color:${color}">거절 후 변경 불가</span>`,
    otherTeamFull: `<span style="color:${color}">{{}}님</span> 당신에게 팀 구성 초대를 보내왔습니다.<br/>상대방이 이미 팀 구성 완료, <br/><span style="color:${color}">해당 초대 자동 무효 처리되었습니다.</span>`,
    youHaveTeam: `<span style="color:${color}">{{}}님</span>당신에게 팀 구성 초대를 보내왔습니다.<br/>당신이 이미 팀 구성 완료, <br/><span style="color:${color}">해당 초대 자동 무효 처리되었습니다.</span>`,
    editNamePlaceholder: '팀 이름을 입력하세요.',
    editEmptyTip: '팀 이름은 비워둘 수 없습니다.',
    haveRejected: '당신은 이미 상대방을 거절했습니다!'
  }),
  A: (color) => ({
    searchPlaceholder: 'البحث في جهات الإتصال',
    searchListEmpty: 'لا يوجد جهات الإتصال مؤخرا',
    sendInvite: 'تم إرسال الدعوة~',
    recvInvitationText: 'يدعوك اللاعب "{{}}" للانضمام إلى فريقه',
    agreeConfirm: `هل أنت متأكد من الانضمام إلى الفريق مع "<span style="color:${color}">{{}}</span>"؟ <br/>بعد التأكيد، <span style="color:${color}"> لا يمكن تغيير الفريق</span>.`,
    rejectConfirm: `هل أنت حقاً تريد أن ترفض دعوة "<span style="color:${color}">{{}}</span>"؟ <br/>لا يمكن تغييرها بعد الرفض.`,
    otherTeamFull: `يدعوك اللاعب "<span style="color:${color}">{{}}</span>" للانضمام إلى فريقه،<br/> ولكن نظرًا لإكتمال عدد الفريق، <br/><span style="color:${color}">فسيتم إبطال هذا الطلب تلقائيًا</span>.`,
    youHaveTeam: `يدعوك اللاعب "<span style="color:${color}">{{}}</span>" للانضمام إلى فريقه،<br/> ولكن نظرًا لأن بالفعل لديك فريق، <br/><span style="color:${color}">فسيتم إبطال هذا الطلب تلقائيًا</span>.`,
    editNamePlaceholder: 'الرجاء إدخال اسم الفريق',
    editEmptyTip: 'لا يمكن أن يكون اسم الفريق فارغ',
    haveRejected: 'لقد رفضت دعوته'
  }),
  // <span style="color:${color}"></span>
  M: (color) => ({
    searchPlaceholder: 'Cari kawan',
    searchListEmpty: 'Tidak ada kawan',
    sendInvite: 'Permintaan untuk pasukan telah hantar',
    recvInvitationText: `<span style="color:${color}">“{{}}”</span>jemput anda untuk berpasukan`,
    agreeConfirm: `Adakah anda mahu berpasukan dengan "<span style="color:${color}">{{}}</span>"? <br/>Selepas mengesahkan pembentukan pasukan, pasukan <span style="color:${color}">tidak boleh ditukar</span>`,
    rejectConfirm: `Adakah anda mahu menolak jemputan pasukan "<span style="color:${color}">{{}}</span>"? <br/>Selepas penolakan, <span style="color:${color}">tidak boleh ditukar</span>`,
    otherTeamFull: `Pemain <span style="color:${color}">"{{}}"</span> menjemput anda untuk membentuk pasukan kerana pasukan ini sudah penuh, <span style="color:${color}" > permintaan itu secara automatik tidak sah </span>`,
    youHaveTeam: `Pemain "<span style="color:${color}">{{}}"</span> menjemput anda untuk membentuk pasukan. Tapi anda telah membentuk pasukan, <span style="color:${color}"> permintaan ini secara automatik tidak sah</span>`,
    editNamePlaceholder: 'Sila masukan nama pasuakn',
    editEmptyTip: 'Nama pasukan tidak boleh kosong',
    haveRejected: 'Anda telah menolak dia'
  }),

  T: (color) => ({
    searchPlaceholder: 'ค้นหาผู้ติดต่อล่าสุด',
    searchListEmpty: 'ไม่มีผู้ติดต่อล่าสุด',
    sendInvite: 'ส่งคำเชิญเข้าทีมแล้ว～',
    recvInvitationText: `<span style="color:${color}">“{{}}”</span>เชิญคุณเข้าทีม`,
    agreeConfirm: `ยืนยันทีจะเข้าทีมกับ“<span style="color:${color}">{{}}</span>”ไหม？<br/>หลังจากยืนยัน <span style="color:${color}">ไม่สามารถเปลี่ยนทีมได้นะ</span>`,
    rejectConfirm: `จะปฏิเสธคำเชิญเข้าทีมของ“<span style="color:${color}">{{}}</span>”จริงเหรอ？<br/>หลังปฏิเสธ<span style="color:${color}">ไม่สามารถเปลี่ยนได้นะ</span>`,
    otherTeamFull: `ผู้เล่น“<span style="color:${color}">{{}}”</span>ได้เชิญคุณเข้าทีม แต่ทีมเต็มแล้ว<span style="color:${color}">คำเชิญนี้จึงหมดอายุอัตโนมัติ</span>`,
    youHaveTeam: `ผู้เล่น“<span style="color:${color}">{{}}”</span>ได้เชิญคุณเข้าทีม แต่เนื่องจากคุณจัดทีมเรียบร้อยแล้ว<span style="color:${color}">คำเชิญนี้จึงหมดอายุอัตโนมัติ</span>`,
    editNamePlaceholder: 'โปรดใส่ชื่อทีม',
    editEmptyTip: 'ชื่อทีมไม่ควรว่าง',
    haveRejected: 'คุณเคยปฏิเสธเขาไปแล้ว'
  }),
  U: (color) => ({
    searchPlaceholder: 'Search for recent contacts',
    searchListEmpty: 'No recent contacts available.',
    sendInvite: 'Team invitation has been sent.',
    recvInvitationText: "'{{}}' invites you to team up",
    agreeConfirm: `Confirm teaming up with <span style="color:${color}">'{{}}'</span>?<br/>Once confirmed, the team <span style="color:${color}">cannot be changed</span>`,
    rejectConfirm: `Are you sure you want to heartlessly reject <span style="color:${color}">'{{}}'s'</span> team invitation?</br>Once confirmed,  he/she will <span style="color:${color}">no longer be able to invite you.</span>`,
    otherTeamFull: `Player <span style="color:${color}">'{{}}'</span> invited you to team up.<br/><span style="color:${color}">This request is automatically invalid</span> as the other party has already formed a team.`,
    youHaveTeam: `Player <span style="color:${color}">'{{}}'</span> has invited you to team up.<br/>Since you have already formed a team, <span style="color:${color}">This request is automatically invalid</span>.`,
    editNamePlaceholder: 'Please enter your team name',
    editEmptyTip: 'Team name cannot be empty.',
    haveRejected: 'You have already declined him before.'
  }),
  P: (color) => ({
    searchPlaceholder: 'Search for recent contacts',
    searchListEmpty: 'No recent contacts available.',
    sendInvite: 'Team invitation has been sent.',
    recvInvitationText: "'{{}}' invites you to team up",
    agreeConfirm: `Confirm teaming up with <span style="color:${color}">'{{}}'</span>?<br/>Once confirmed, the team <span style="color:${color}">cannot be changed</span>`,
    rejectConfirm: `Are you sure you want to heartlessly reject <span style="color:${color}">'{{}}'s'</span> team invitation?</br>Once confirmed,  he/she will <span style="color:${color}">no longer be able to invite you.</span>`,
    otherTeamFull: `Player <span style="color:${color}">'{{}}'</span> invited you to team up.<br/><span style="color:${color}">This request is automatically invalid</span> as the other party has already formed a team.`,
    youHaveTeam: `Player <span style="color:${color}">'{{}}'</span> has invited you to team up.<br/>Since you have already formed a team, <span style="color:${color}">This request is automatically invalid</span>.`,
    editNamePlaceholder: 'Please enter your team name',
    editEmptyTip: 'Team name cannot be empty.',
    haveRejected: 'You have already declined him before.'
  }),
  Q: (color) => ({
    searchPlaceholder: 'Son İletişim Kurulan Kişileri Ara',
    searchListEmpty: 'Son İletişim Kurulan Kişi yok',
    sendInvite: 'Davet gönderdi~',
    recvInvitationText: '“{{}}”sizi takıma davet ediyor',
    agreeConfirm: ` “<span style="color:${color}">{{}}</span>”ile bir ekip oluşturmayı onaylıyor musunuz?<br/>Onayladıktan sonra，takım<span style="color:${color}">değiştirilemez</span>`,
    rejectConfirm: `“<span style="color:${color}">{{}}</span>”adlı kişinin takım davetini reddetmeyi mi istiyorsun？<br/>Reddettikten sonra değiştirilemez.`,
    otherTeamFull: `“<span style="color:${color}">{{}}”</span>seni takımına davet etti. <br/> Ancak karşı tarafın takımı doldu，<span style="color:${color}"> bu istek otomatik olarak geçersiz </span>。`,
    youHaveTeam: `“<span style="color:${color}">{{}}”</span>seni takımına davet etti <br/> Ancak siz zaten bir takıma katıldığınız için<span style="color:${color}"> bu istek otomatik olarak geçersiz.</span>。`,
    editNamePlaceholder: 'Lütfen Takım Adını Girin',
    editEmptyTip: 'Takım Adını boş olmaz',
    haveRejected: 'Onu zaten reddettin'
  }),
  V: (color) => ({
    searchPlaceholder: 'Tìm liên hệ',
    searchListEmpty: 'Chưa có liên hệ',
    sendInvite: 'Đã gửi lời mời～',
    recvInvitationText: '“{{}}”Mời bạn lập đội',
    agreeConfirm: `Xác nhận tạo đội với“<span style="color:${color}">{{}}</span>” đúng không?？<br/> Sau khi xác nhận tạo đội，đội <span style="color:${color}"> sẽ không thể thay đổi </span>`,
    rejectConfirm: `Bạn chắc chắn muốn từ chối lời mời lập đội của “<span style="color:${color}">{{}}</span>”？<br/> Sau khi từ chối không thể thay đổi`,
    otherTeamFull: `Người chơi “<span style="color:${color}">{{}}”</span> mời bạn tạo đội <br/> Do đối phương đã có đội，<span style="color:${color}"> lời mời vô hiệu </span>。`,
    youHaveTeam: `Người chơi“<span style="color:${color}">{{}}”</span> mời bạn vào đội <br/>Do bạn đã có đội，<span style="color:${color}"> nên lời mời sẽ vô hiệu </span>。`,
    editNamePlaceholder: 'Vui lòng nhập tên đội',
    editEmptyTip: 'Không được để trống tên đội',
    haveRejected: 'Bạn đã từ chối đối phương rồi'
  })
}

export const teamTextConfig = IS_WEPLAY ? textConfig[PAGE_REGION] : textConfig.hw

const imgConfig = proxyAssets({
  hw: {
    line: 'https://quick-page.afunapp.com/assets/A9016250-0851-4E9F-B61D-B86E325C4BEB.png'
  },
  wp: {
    line: 'https://quick-page.weplayapp.com/assets/4A91F52E-AAA9-4631-82CF-E9BD2C990BAD.png?_w=500&_h=2'
  }
})

export const teamImgConfig = IS_WEPLAY ? imgConfig.wp : imgConfig.hw

const regionConfig = {
  hw: {
    teamNameLength: 5,
    nicknameLength: 5
  },
  C: {
    teamNameLength: 5,
    nicknameLength: 5
  },
  J: {
    teamNameLength: 5,
    nicknameLength: 5
  },
  K: {
    teamNameLength: 5,
    nicknameLength: 5
  },
  A: {
    teamNameLength: 16,
    nicknameLength: 8
  },
  M: {
    teamNameLength: 15,
    nicknameLength: 5
  },
  T: {
    teamNameLength: 15,
    nicknameLength: 5
  },
  U: {
    teamNameLength: 10,
    nicknameLength: 5
  },
  Q: {
    teamNameLength: 10,
    nicknameLength: 5
  },
  V: {
    teamNameLength: 10,
    nicknameLength: 5
  },
  P: {
    teamNameLength: 10,
    nicknameLength: 5
  }
}

export const teamRegionConfig = IS_WEPLAY ? regionConfig[PAGE_REGION] : regionConfig.hw
