<template lang="pug">
portal.ui-portal(:to='to', v-bind='$attrs')
  slot
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: 'page-container'
    }
  }
}
</script>

<style></style>
