import { IS_APP } from '@/utils/env'
import { getQuery1 } from './utils'
import weSDK from '@/lib/we-sdk'
import { getActivityConfig } from '@sub/utils/page-center'
import get from 'lodash/get'

const { region } = getQuery1()

export default async function judgeWhiteUid() {
  try {
    if (!IS_APP) {
      return Promise.resolve(false)
    }

    const [{ uid }, { data }] = await Promise.all([weSDK('getUserInfo'), getActivityConfig()])
    const whiteUids = get(base642Obj(data.config.activityJson), 'whiteUids', {}) || {}

    return Promise.resolve((whiteUids[region] || []).includes(+uid))
  } catch {
    return Promise.resolve(false)
  }
}

function base642Obj(str) {
  return JSON.parse(decodeURI(atob(str)))
}
