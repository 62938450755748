import { PAGE_REGION } from '@/utils/env'
import switchEnvRequest from '@sub/utils/switchEnvRequest'

const env = { ...process.env }
delete env.APP_CONFIG

const app = process.env.APP_CONFIG[PAGE_REGION]
app.$env = { ...env, ...process.env.APP_CONFIG.$env }

export default switchEnvRequest(app)
