<template lang="pug">
#m-rank
  a-section.ui-rank2-single-top3(v-bind='attrs', w='7.5')
    //- 1-3
    a-section.ui-rank2-single-top3__head(:bg-i='image.head')
      a-position.ui-rank2-single-top3__head-position(wh='6.58 2.32', pc='x', pb='0')
        a-section(wh='3.04 100%', pc='x', pt='0')
          a-avatar(wh='1.65', v-imgLazy='top3[0].avatar || image.empty', bg-s='100%', :uid='top3[0].uid', pt='-1.48', pc='x', pz='10', :style='top3[0].uid ? `border: .03rem solid ${borderColor.top1};` : ""')
            a-position(v-if='!isA', :bg-i='image.crown1 || crownMap[1]', pt='-.28', pl='-.08')
            a-position.mirror(v-else, :bg-i='image.crown1 || crownMap[1]', pt='-.28', pr='-.08')
          a-position(wh='1.84 .27', lh='.32', ta, pc='x', pt='1.26', :f='`.23 ${textColor.first} 500`') {{ $ts(top3[0].nickname, 6) }}
          a-position.fx-row.fx-center(v-if='showVal', wh='2 .34', pc='x', pt='1.69')
            a-section(:bg-i='image.scoreIcon1')
            a-section(:f='`.23 ${textColor.first} 500`', h='.34', lh='.35') {{ props.notCompress ? top3[0].value1 : top3[0].value }}
          a-position(v-if='top3[0].ringImg', wh='.8', pc='x', pz='11', pt='-.2')
            a-section(v-if='ringImgBgColor.first', wh='100%', :bg-c='ringImgBgColor.first', bdr='50%', b='.01rem solid #fff')
            a-position(:bg-i='top3[0].ringImg', wh='100%', pc)

        a-section(wh='2.1 100%', pl='0', pt='0')
          a-avatar(wh='1.4', v-imgLazy='top3[`${isA ? "2" : "1"}`].avatar || image.empty', :uid='top3[`${isA ? "2" : "1"}`].uid', pt='-0.95', pc='x', pz='10', :style='top3[`${isA ? "2" : "1"}`].uid ? `border: .03rem solid ${isA ? borderColor.top2 : borderColor.top3};` : ""', bg-s='100%')
            a-position(v-if='!isA', :bg-i='image.crown2 || crownMap[2]', pt='-.2', pl='-.08')
            a-position.mirror(v-else, :bg-i='image.crown3 || crownMap[3]', pt='-.2', pr='-.08')
          a-position(wh='100% .27', lh='.27', ta, pc='x', pt='1.4', :f='`.19 ${isA ? textColor.third : textColor.second} 500`') {{ $ts(top3[`${isA ? '2' : '1'}`].nickname, 6) }}
          a-position.fx-row.fx-center(v-if='showVal', wh='100% .34', pc='x', pt='1.69')
            a-section(:bg-i='isA ? image.scoreIcon3 : image.scoreIcon2')
            a-section(:f='`.23 ${isA ? textColor.third : textColor.second} 500`', h='.32', lh='.32') {{ top3[isA ? '2' : '1'][props.notCompress ? `value1` : `value`] }}
          a-position(v-if='top3[`${isA ? "2" : "1"}`].ringImg', wh='.6', pc='x', pz='11', pt='0.2')
            a-section(v-if='ringImgBgColor.second', wh='100%', :bg-c='ringImgBgColor.second', bdr='50%', b='.01rem solid #fff')
            a-position(:bg-i='top3[`${isA ? "2" : "1"}`].ringImg', wh='100%', pc)

        a-section(wh='2.1 100%', pr='0', pt='0')
          a-avatar(wh='1.4', v-imgLazy='top3[`${isA ? "1" : "2"}`].avatar || image.empty', :uid='top3[`${isA ? "1" : "2"}`].uid', pt='-0.95', pc='x', pz='10', :style='top3[`${isA ? "1" : "2"}`].uid ? `border: .03rem solid ${borderColor.top3};` : ""', bg-s='100%')
            a-position(v-if='!isA', :bg-i='image.crown3 || crownMap[3]', pt='-.2', pl='-.08')
            a-position.mirror(v-else, :bg-i='image.crown2 || crownMap[2]', pt='-.2', pr='-.08')
          a-position(wh='100% .27', lh='.27', ta, pc='x', pt='1.4', :f='`.19 ${isA ? textColor.second : textColor.third} 500`') {{ $ts(top3[`${isA ? '1' : '2'}`].nickname, 6) }}
          a-position.fx-row.fx-center(v-if='showVal', wh='100% .34', pc='x', pt='1.69')
            a-section(:bg-i='isA ? image.scoreIcon2 : image.scoreIcon3')
            a-section(:f='`.23 ${isA ? textColor.second : textColor.third} 500`', h='.32', lh='.32') {{ top3[`${isA ? '1' : '2'}`][props.notCompress ? `value1` : `value`] }}
          a-position(v-if='top3[`${isA ? "1" : "2"}`].ringImg', wh='.6', pc='x', pz='11', pt='0.2')
            a-section(v-if='ringImgBgColor.third', wh='100%', :bg-c='ringImgBgColor.third', bdr='50%', b='.01rem solid #fff')
            a-position(:bg-i='top3[`${isA ? "1" : "2"}`].ringImg', wh='100%', pc)

    //- 4-10
    a-section.repeat.ui-rank2-single-top3__4_n(:h='1.46 * top4_n.length', bg-s='100%', :bg-i='image.rowWrapper')
      a-section.ui-rank2-single-top3__4_n-section(v-for='(item, index) in top4_n', :key='index', wh='7.5 1.46')
        a-position(:bg-i='image.commonBg', pc, bg-s='100%')
          a-position.fx-row.fx-c-center.fx-m-center(:bg-i='image.star', ta, pc='y', v-bind='commonConfig[1]', :f='`.24 ${textColor.star} 600`') {{ index + 4 }}
          a-avatar(wh='.9', v-imgLazy='item.avatar || image.empty', :uid='item.uid', pc='y', v-bind='commonConfig[2]', :style='item.uid ? `border: .02rem solid ${borderColor.other};` : ""', bg-s='100%')
          a-position.ws-nowrap(wh='1.5 .27', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.other} 500`', :class='{ "txt-r": _isA }') {{ $ts(item.nickname, 6) }}
          a-position.fx-row.fx-c-center(v-if='showVal', h='.34', pc='y', v-bind='commonConfig[4]')
            a-section(:bg-i='image.scoreIconOther')
            a-section(:f='`.23 ${textColor.score} 500`', h='.34', lh='.35') {{ props.notCompress ? item.value1 : item.value }}
          a-position(v-if='item.ringImg', wh='.8', pc='y', v-bind='commonConfig[5]', :bg-i='item.ringImg')

    //- 自己的一栏
    a-section.ui-rank2-single-top3__fixed(v-if='isFixed', bg-s='100%', :bg-i='image.footer', mt='-.01')
    a-section.ui-rank2-single-top3__mine(v-bind='mineConfig')
      a-position.ui-rank2-single-top3__mine-item(v-bind='mineConfig2', v-for='(item, index) in [mine]', :key='index')
        a-section.fx-row.fx-c-center.fx-m-center.ui-rank2-single-top3__mine-item-inRank(v-if='isInRank', :bg-i='image.star', ta, pc='y', v-bind='commonConfig[1]', :f='`.24 ${textColor.star} 600`') {{ item.rank }}
        a-section.ui-rank2-single-top3__mine-item-notInRank(v-else, :bg-i='image.notInRank', v-bind='commonConfig[6]', pc='y', bg-s='100%')
        a-avatar.ui-rank2-single-top3__mine-item-avatar(wh='.86', v-imgLazy='item.avatar', :data-default='image.empty', :uid='item.uid', pc='y', v-bind='commonConfig[2]', :class='{ "white-border": item.uid }', :style='`border: .02rem solid ${borderColor.mine};`', bg-s='100%')
        a-position.ui-rank2-single-top3__mine-item-nickname(wh='1.5 .27', lh='.27', pc='y', v-bind='commonConfig[3]', :f='`.22 ${textColor.mineName || textColor.other} 500`', :class='{ "txt-r": _isA }') {{ $ts(item.nickname, 6) }}
        a-position.fx-row.fx-c-center.ui-rank2-single-top3__mine-item-val(v-if='showVal', h='.34', pc='y', v-bind='commonConfig[4]')
          a-section(:bg-i='image.scoreIconMine || image.scoreIconOther')
          a-section(:f='`.23 ${textColor.mineScore || textColor.score} 500`', h='.34', lh='.35') {{ props.notCompress ? item.value1 : item.value }}
        a-position.ui-rank2-single-top3__mine-item-ring(v-if='item.ringImg', wh='.8', pc='y', v-bind='commonConfig[5]', :bg-i='item.ringImg')
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import { formatItem } from './utils/config'
import proxyAssets from '@sub/utils/proxy-assets'

import top3 from './components/top3.vue'
import topCommon from './components/top4-n.vue'
import mine from './components/mine.vue'
import mineFixed from './components/mine-fixed.vue'
import { isA } from '@sub/ui-components/utils/config'
import { IS_WEPLAY } from '@/utils/env'

export default {
  mixins: [attrs],

  components: { top3, topCommon, mine, mineFixed },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    'amApiParam.uid': {
      handler(val) {
        if (val) {
          this.init()
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      isA,
      crownMap: proxyAssets({
        1: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/kMiCb0gQ.png?_w=74&_h=74' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/7v0lQTp9.png?_w=74&_h=74',
        2: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/8bqSkRqH.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/OfrodgbS.png?_w=66&_h=66',
        3: IS_WEPLAY ? 'https://fe-center.weplayapp.com/page-center/assets/YCOq4hGl/c3KYzrd3.png?_w=66&_h=66' : 'https://fe-center.afunapp.com/page-center/assets/PmwoTMS8/elemls2N.png?_w=66&_h=66'
      })
    }
  },

  computed: {
    // 有些区服有隐藏分数的需求
    showVal({ mine }) {
      return this.props?.showValLength ? mine.rank && mine.rank <= this.props?.showValLength : true
    },
    commonConfig() {
      return {
        1: { [this._plr]: '.4' }, //星星的位置
        2: { [this._plr]: '1.22' }, //头像框的位置
        3: { [this._plr]: '2.32' }, //名字的位置
        4: { [this._plr]: '5.19' }, //戒指的位置
        5: { [this._prl]: '1.55' }, // 分数的位置
        6: { [this._plr]: '.12' } //未上榜icon的位置
      }
    },

    mineConfig({ isFixed, image }) {
      return isFixed ? { 'bg-i': image.fixedBg, p: 'fixed', pb: '0' } : { 'bg-i': image.footer }
    },

    mineConfig2({ isFixed, image }) {
      return isFixed ? { wh: '6.58 1.3', pc: 'pc' } : { wh: '6.58 1.3', pc: 'x', pt: '.04', 'bg-i': image.mineBg }
    },

    amApiParam() {
      return this.$base.amApiParam()
    },

    amBasic() {
      return this.$base.amBasic()
    },

    props() {
      return this.uiMeta?.props
    },

    act_id({ props }) {
      return props?.act_id || 0
    },

    //页面使用多个不同类型的ui榜单组件时必传（值不可相同），同类型时可以忽略
    rankKey({ props }) {
      return props?.rankKey || 'total'
    },

    isFixed({ props }) {
      return props?.isFixed
    },

    image({ props }) {
      return { ...props?.image }
    },

    textColor({ props }) {
      return props?.textColor
    },

    borderColor() {
      return this.props?.borderColor || {}
    },

    ringImgBgColor({ props }) {
      return props?.ringImgBgColor || {}
    },

    // 对于state和getters可以像下面这样以computed接收，也可以直接用
    top1_3({ rankKey }) {
      return this.$rank.rankUse()?.[rankKey]?.list1_3 || []
    },

    top3() {
      return this.top1_3
    },
    top4_n({ rankKey }) {
      return this.$rank.rankUse()?.[rankKey]?.list.slice(3) || []
    },
    mine({ rankKey }) {
      return this.$rank.rankUse()?.[rankKey]?.mine || {}
    },

    isInRank() {
      return this.mine.rank && this.mine.rank <= this.props?.rankConfig?.rankLength
    },

    rankConfig({ props }) {
      return { ...props?.rankConfig }
    }
  },

  methods: {
    async init() {
      this.$rank.initDefault({
        format: {
          list: formatItem,
          mine: formatItem
        },
        namespace: this.rankKey
      })
      // 相当于之前给mixins中传参那步
      const { rankLength } = this.rankConfig
      this.$store.commit('rank/setRank', [this.rankKey, { bonus: 0, list: [], mine: {} }])
      this.$store.commit('rank/setRankAttr', [this.rankKey, 'length', rankLength])
      // 加载榜单信息
      this.loadRank()
    },

    loadRank() {
      const { rankLength, rankType, periodType, extra = {} } = this.rankConfig
      // 相当于之前调用mixins中的方法
      let params = { limit: rankLength, rank_type: rankType, period_type: periodType, ...extra, ...this.amApiParam }
      this.act_id && (params.act_id = this.act_id)
      this.$rank.getRankList([this.rankKey, params])
      this.$rank.getRankInfo([this.rankKey, params])
    }
  }
}
</script>

<style lang="scss" scoped>
.repeat {
  background-repeat: repeat-y;
}
</style>
