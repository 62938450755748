export default [
  {
    path: '/activity/v2/long/internetCelebrity-p/index.html',
    component: () => import('./index.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
        to.meta.expire = to.meta.json.expire
      },
      id: 341,
      pageCenter: 'U8IJcXND' // TODO
    }
  }
]
