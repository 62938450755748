let installed = false

async function install() {
  if (installed) {
    return window.AF_SMART_SCRIPT
  }

  await import(/* webpackChunkName: "AF_SMART_SCRIPT" */ './onelink-smart-script-v2.0.1')

  installed = true

  return window.AF_SMART_SCRIPT
}

export default install
