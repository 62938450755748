import { textCompiler } from './text-methods'
import { PAGE_REGION } from '@/utils/env'
import $store from '@/store'
/**
 * @module pagecenter
 */

/**
 * @description 将数据注入到某个组件当前区服的所有文案中
 * @param {string} componentType 组件名
 * @param {object} context 运行时上下文
 *  @param {object} context.region 当前区服（特殊的：马来: ms，印尼: id）
 * @param {object} [data] 变量的数据源
 * @returns {object} 文案配置
 */
export function genComponentCurRegionText(componentType, context, data = {}) {
  const { region } = context || {}
  const text = getComponentMultiConfig(componentType)[region || PAGE_REGION]?.text || {}

  return Object.keys(text).reduce((total, key) => {
    total[key] = textCompiler(text[key], data)
    return total
  }, {})
}

/**
 * @description 获取组件当前区服的图片配置
 * @param {string} componentType 组件名
 * @param {object} context 运行时上下文
 *  @param {object} context.region 当前区服（特殊的：马来: ms，印尼: id）
 * @returns {object} 图片配置
 */
export function getComponentCurRegionImage(componentType, context) {
  const { region } = context
  const images = getComponentMultiConfig(componentType)[region]?.images || {}
  return images
}

/**
 * @description 获取某个组件的多语言配置
 * @param {string} componentType 组件名
 * @returns {object} 某个组件的多语言配置
 */
export function getComponentMultiConfig(componentType) {
  const uiMetaPageCenterSnapshot = $store.state.runtime.uiMetaPageCenterSnapshot
  const res = uiMetaPageCenterSnapshot?.[componentType]?.multiLanguageConfig
  if (!res) {
    // console.log('🐲 gwl ~ getComponentMultiConfig ~ ', `源码活动暂未支持或者组件${componentType}暂未支持 page-center 配置`)
    return {}
  }
  return res
}

// 设置「pageCenter快照」到全局，调用时机：amGetRuntimeActInfo()、iframe handleBuilderMessage()
export function setUiMetaPageCenterSnapshot({ ui_meta }) {
  let pageCenterSnapshot = ui_meta?.pageCenterSnapshot || {}
  const uiMetaPageCenterSnapshot = formatMultiLanguageConfig(pageCenterSnapshot)
  window.uiMetaPageCenterSnapshot = uiMetaPageCenterSnapshot
  $store.commit('runtime/setState', { uiMetaPageCenterSnapshot })
  console.warn('🐲 gwl ~ uiMetaPageCenterSnapshot:', uiMetaPageCenterSnapshot)
}

// 格式化多语言配置 multiLanguageConfig
function formatMultiLanguageConfig(pageCenterSnapshot) {
  return Object.keys(pageCenterSnapshot).reduce((res, componentName) => {
    const componentMultiConfig = pageCenterSnapshot[componentName]?.multiLanguageConfig || {}
    res[componentName] = {}
    res[componentName].multiLanguageConfig = Object.keys(componentMultiConfig).reduce((total, region) => {
      const componentCurRegionConfig = componentMultiConfig[region] || {}
      const { images, text } = componentCurRegionConfig

      total[region] = {
        ...componentCurRegionConfig,
        // To Format:
        images: Array.isArray(images) ? arr2obj(images) : images,
        text: Array.isArray(text) ? arr2obj(text) : text
      }
      return total
    }, {})

    return res
  }, {})
}
/**
 * 将数组转换为对象。
 * @param {Array} [arr=[]] - 要转换的数组。
 * @param {string} [k='key'] - 指定数组元素中作为对象键的字段名。
 * @param {string|null} [v='value'] - 指定数组元素中作为对象值的字段名。如果为 null，则整个数组元素将作为值。
 * @returns {Object} 返回转换后的对象。
 * @example
 * // 示例：
 * // 将数组转换为对象，数组元素中 'name' 字段作为键，'age' 字段作为值
 * const arr = [{ name: 'John', age: 30 }, { name: 'Jane', age: 25 }];
 * const obj = arr2obj(arr, 'name', 'age');
 * console.log(obj); // { 'John': 30, 'Jane': 25 }
 * @example
 * // 示例：
 * // 将数组转换为对象，数组元素整体作为键，不指定值字段
 * const arr = [{ name: 'John', age: 30 }, { name: 'Jane', age: 25 }];
 * const obj = arr2obj(arr);
 * console.log(obj); // { '[object Object]': { name: 'Jane', age: 25 } }
 */

function arr2obj(arr = [], k = 'key', v = 'value') {
  return arr.reduce((total, cur) => {
    total[cur[k]] = v ? cur[v] : cur
    return total
  }, {})
}
