import { isA } from '@sub/ui-components/utils/config'
import { IS_WEPLAY, PAGE_REGION, IS_IOS, IS_ANDROID, IS_DEV, PAGE_LANG, IS_APP, IS_IPAD } from '@/utils/env'
import config from '@/config'
import { getQuery1 } from '@/utils/utils'
import { commonImage, commonText } from '@sub/ui-components/utils/config'

const { scene } = getQuery1()

const activityComponentsQuickProps = {
  // 非阿语服 => pl 阿语服 => pr
  _plr: !isA ? 'pl' : 'pr',
  // 非阿语服 => pr 阿语服 => pl
  _prl: !isA ? 'pr' : 'pl',
  // 非阿语服 => ml 阿语服 => mr
  _mlr: !isA ? 'ml' : 'mr',
  // 非阿语服 => mr 阿语服 => ml
  _mrl: !isA ? 'mr' : 'ml',
  // 非阿语服 => pdl 阿语服 => pdr
  _pdlr: !isA ? 'pdl' : 'pdr',
  // 非阿语服 => pdr 阿语服 => pdl
  _pdrl: !isA ? 'pdr' : 'pdl'
}

const envQuickProps = {
  // 是否是阿语服
  _isA: isA,
  // 语言是否是阿语
  _isAr: PAGE_LANG === 'ar',
  // 区服参数
  _region: PAGE_REGION,
  // 是否是weplay
  _isWeplay: IS_WEPLAY,
  // 是否是ios
  _isIos: IS_IOS,
  // 是否是安卓
  _isAndroid: IS_ANDROID,
  // 是否是dev环境（含 beta 环境）
  _isDev: IS_DEV,
  // 是否是beta环境
  _isBeta: process.env.APP_CONFIG.$env.BETA,
  // 是否是本地环境
  _isLocal: config.$env.LOCAL,
  // 是否在语音房内
  _isInVoice: scene === 'voice_room',
  // 是否在 APP 内
  _isApp: IS_APP,
  // 是否是ipad
  _isIpad: IS_IPAD,
  // 是否是runtime
  _isRuntime: window.location.pathname.startsWith('/runtime')
}
/**
 * 将快速属性和环境属性挂载到 Vue 实例上。
 * @param {Vue} vm - 要挂载属性的 Vue 实例。
 */
export const mountedProps = (vm) => {
  const quickProps = { ...activityComponentsQuickProps, ...envQuickProps }
  Object.keys(quickProps).forEach((key) => {
    vm[key] = quickProps[key]
  })
  vm['_commonImage'] = commonImage
  vm['_commonText'] = commonText
}
