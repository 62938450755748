import activityModule from '@wepie-fe/activity-module'

import weSDK from '@/lib/we-sdk'
import store from '@/store'

import config from '@/config'

import dayjs from '@/utils/dayjs'
import { reportTTI } from '@/utils/global-point'
import { IS_APP, IS_DEV } from '@/utils/env'
import { buildApi, buildApi1 } from '@/utils/request-next'
import { getQuery1 } from '../../utils/utils'

const { region } = getQuery1()

export function install(app) {
  app.use(activityModule, {
    isDev: IS_DEV,
    isApp: IS_APP,

    lib: {
      dayjs,
      utc: config.utc
    },

    modules: {
      basic: {
        user: store.state.user,
        request: {
          getNowTime: (p) => buildApi1('/activity_v1/common/get_now_time')(p).then((t) => t * 1000),
          getUserInfo: (p) =>
            buildApi('/activity_v1/common/get_userinfo')(p).then((r) => ({
              nickname: r.nickname,
              avatar: r.headimgurl,
              frame: r.pic_frame,
              gender: r.gender || 2
            })),
          setPageDot: buildApi('/activity_v1/common/handle_red_dot'),
          getActInfo: buildApi('/activity_v2/common/get_act_info'),
          getAvatarGender: buildApi('/activity_v2/common/get_user_avatar_sex')
        },
        native: {
          hideShareBtn: () => weSDK('hideShareBtn'),
          getUserInfo: () => weSDK('getUserInfo')
        },
        methods: {
          apiParam: (basic) => ({
            act_id: basic.id,
            uid: basic.user.uid,
            sid: basic.user.sid
          }),
          // activityOver(vm) {
          activityOver() {
            const container = document.querySelector('body>.page-container')
            const el = document.createElement('div')
            el.classList.add(`layer-finish-${region}`)
            container.insertBefore(el, container.firstChild)
          },
          initAfter(vm) {
            if (vm.$point) {
              vm.amBasic.user.uid && vm.$point.login(vm.amBasic.user.uid)
            }
            reportTTI(vm.$route.meta)
          }
        }
      },
      contact: {
        native: {
          getContactData: () => weSDK('getRecentContact')
        }
      },
      gift: {
        request: {
          getGiftCount: (p) =>
            buildApi('/activity_v2/collect_gift/gift_count')({
              ...p,
              target_uid: p.uid
            }).then((r) => r.count)
        }
      },
      rank: {
        request: {
          getRankData: buildApi('/activity_v1/common/rank'),
          getRankJackpot: buildApi('activity_v1/common/get_day_pool'),
          getRankResults: (p) => Promise.all([buildApi('/activity_v1/common/get_day_money_reward')(p), buildApi('/activity_v1/common/get_day_lucky_user')(p)]).then(([list, luck]) => ({ list, luck })),
          getMineRankData: buildApi('/activity_v1/common/get_user_money_reward'),
          getRankList: buildApi('/activity_v2/rank/rank_list'),
          getRankInfo: buildApi('/activity_v2/rank/rank_info')
        }
      },
      newRank: {
        request: {
          getRankData: buildApi('/activity_v1/common/rank'),
          getRankJackpot: buildApi('activity_v1/common/get_day_pool'),
          getRankResults: (p) => Promise.all([buildApi('/activity_v1/common/get_day_money_reward')(p), buildApi('/activity_v1/common/get_day_lucky_user')(p)]).then(([list, luck]) => ({ list, luck })),
          getMineRankData: buildApi('/activity_v1/common/get_user_money_reward'),
          getRankList: buildApi('/activity_v2/rank/rank_list'),
          getRankInfo: buildApi('/activity_v2/rank/rank_info')
        }
      },
      task: {
        request: {
          getTaskStatus: buildApi('/activity_v1/common/get_task_reward_status'),
          getTaskTotal: buildApi('/activity_v1/common/get_task_total_num'),
          getTaskReward: buildApi('/activity_v1/common/take_total_task_reward')
        }
      },
      newTask: {
        request: {
          getDailyTaskStatus: buildApi('/activity_v2/daily_task_repeat/get_task_status_list'),
          getDailyTaskReward: buildApi('/activity_v2/daily_task_repeat/recv_reward'),
          getStageTaskStatus: buildApi('/activity_v2/level_task/get_task_status'),
          getStageTaskReward: buildApi('/activity_v2/level_task/recv_reward'),
          getStageTeamTaskStatus: buildApi('/activity_v2/level_task/get_team_status'),
          getStageTeamTaskStatus1: buildApi('/activity_v2/level_task/get_team_info')
        }
      },
      checkin: {
        request: {
          getChargeStatus: buildApi('/activity_v1/dragon_boat/get_charge_status'),
          userSign: buildApi('/activity_v1/common/user_sign'),
          getSignRecord: buildApi('/activity_v1/common/get_sign_record'),
          getBroadcast: buildApi('/activity_v1/common/get_checkin_broadcast')
        }
      },
      team: {
        request: {
          getMultiTeaSStatus: buildApi('/activity_v1/children/get_multi_team_status'),
          getTeamInfo: buildApi('/activity_v1/common/get_team_info'),
          sendTeamInvite: buildApi('/activity_v1/common/send_team_invite'),
          acceptTeamInvite: buildApi('/activity_v1/common/accept_team_invite'),
          refuseTeamInvite: buildApi('/activity_v1/common/refuse_team_invite'),
          getPetName: buildApi('/activity_v1/common/get_pet_name'),
          editPetName: buildApi('/activity_v1/common/edit_pet_name'),
          getTeamMembersProp: buildApi('/activity_v1/common/get_user_chip_change'),
          getTeamSendCount: buildApi('/activity_v1/common/get_team_send_count')
        }
      },
      lottery: {
        request: {
          levelLottery: buildApi('/activity_v1/qi_xi/bridge_level_lottery'),
          getUserCoin: buildApi('/activity_v1/common/user/get_coin'),
          getLotteryLog: buildApi('/activity_v1/common/get_lottery_log'),
          getLotteryCount: buildApi('/activity_v1/common/get_lotteryChip_count'),
          buyLotteryProp: buildApi('/activity_v1/common/buy_lotteryChip'),
          getUserLuckyValue: (e) => e
        }
      }
    }
  })
}
