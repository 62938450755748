<template lang="pug">
a-section.txt-c
  a-dialog.txt-c(:id='dialogName', :name='dialogName', :bg-i='imageExchange.dialogBg')
    template(slot-scope='scope', v-if='scope.data')
      a-position(:bg-i='closeIcon', pr='5.82', pt='-0.78', v-bind='getCustomizeStyle("closeStyle")', @a-tap='$dialogs[dialogName].close()')
      slot(name='exchangeConfirm')
      //- 奖励展示
      span(:data-var='(rewardInfo = scope.data)')
      a-position(v-bind='exchangeConfirmStyleConfig.reward.wrap', :bg-i='imageExchange.rewardBg')
        //- 道具
        ui-prop(:data='$propsConfig(rewardInfo.exchange_id)', wh='100%', pc)
        //- 角标
        a-border-text1(v-bind='{ ...exchangeConfirmStyleConfig.reward.subscript, fc: textStyle.subscriptColor }', borderWidth='3', :borderColor='textStyle.subscriptBorderColor', :borderContent='$getPropUnit(rewardInfo.exchange_id, Number(rewardInfo.exchange_num || 1) * value)')
        //- 奖励名称
        a-position.txt-no-wrap(v-bind='exchangeConfirmStyleConfig.reward.rewardName', :fc='textStyle.propsNameColor', v-html='$propsConfig(rewardInfo.exchange_id)?.name4')
      template
        //- 可兑换数量
        a-position(v-bind='exchangeConfirmStyleConfig.canExchangeNum', :fc='textStyle.maxNumColor', v-html='$tf(textWords.maxText || textConfig.maxText, Math.floor(buyChipInfo.chipCount / rewardInfo.exchange_price))')
        ui-input-control(:propValue='value', :uiMeta='inputControl', @change-value='changeValue', pc, :propLimit='limit(rewardInfo.exchange_price)', pt='5.41')

        //- 总花费
        a-position(v-bind='exchangeConfirmStyleConfig.totalExchangeNum', v-html='$tf(textWords.totalExpensesText || textConfig.totalExpensesText, value * rewardInfo.exchange_price)', :fc='textStyle.totalNumColor')

      //- 确认、取消
      a-button1(:bg-i='imageExchange.cancel', v-bind='exchangeConfirmStyleConfig.cancelBtn', @a-tap='handleClose()')
      a-button1(:bg-i='imageExchange.confirm', v-bind='exchangeConfirmStyleConfig.confirmBtn', @a-tap='onExchange(rewardInfo)')
</template>
<script>
import { getNumUnit } from '@/utils/utils'
import { buildApi, concatCatch } from '@sub/utils/request'
import { isA } from '@sub/ui-components/utils/config'

import { exchangeConfirmStyleConfig, textConfig } from './utils/confirmConfig'
import { get, isEmpty } from 'lodash'

const doExchange = buildApi('/activity_v2/exchange_store/exchange')

export default {
  props: {
    amApiParam: {
      type: Object,
      default: () => {}
    },

    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      exchangeConfirmStyleConfig,
      isA,
      value: 1 // 兑换数量
    }
  },

  computed: {
    imageExchange({ uiMeta }) {
      return get(uiMeta, 'props.image.exchange', {})
    },

    inputControl({ uiMeta, textConfig }) {
      const inputConfig = get(uiMeta, 'props.input', {})
      if (!inputConfig.props.overToast) {
        inputConfig.props.overToast = textConfig.overToast
      }
      if (!inputConfig.props.leastToast) {
        inputConfig.props.leastToast = textConfig.leastToast
      }
      return inputConfig
    },

    textStyle({ uiMeta }) {
      return get(uiMeta, 'props.text.style', {})
    },

    textWords({ uiMeta }) {
      return get(uiMeta, 'props.text.words', {})
    },
    textAccentColor({ textStyle }) {
      return get(textStyle, 'accentColor', '#000000')
    },

    textConfig() {
      let textMap = {}
      const { region = 'A' } = this.$route.query

      if (!this._isRuntime || isEmpty(textMap)) {
        textMap = textConfig(this.textAccentColor)[region]
      }

      return textMap
    },

    dialogName({ uiMeta: { key } }) {
      return key
    },
    rewardDialogKey({ uiMeta: { rewardDialogKey } }) {
      return rewardDialogKey
    },

    buyConfig({ uiMeta }) {
      return get(uiMeta, 'props.buyConfig', [])
    },

    chipId({ uiMeta }) {
      return get(uiMeta, 'props.chipId', 1)
    },

    customizeStyle({ uiMeta }) {
      return get(uiMeta, 'props.customizeStyle', {}) || {}
    },

    // 兑换信息
    buyChipInfo({ chipId }) {
      return {
        coin: this.$store.state.base.coin,
        chipCount: this.$store.state.base.chipInfo[chipId]
      }
    },

    // 最大兑换数 默认 总/单价
    limit({ buyChipInfo, inputControl }) {
      return (price) => {
        if (inputControl.props?.limit) return inputControl.props?.limit
        const max = Math.floor(buyChipInfo.chipCount / price)
        return max === 0 ? 1 : max
      }
    },

    closeIcon({ uiMeta }) {
      return get(uiMeta, 'props.closeIcon', '')
    },

    storageKey({ dialogName }) {
      return `${dialogName}-uiExchangeConfirmTip`
    },
    // 兑换请求字段
    exchangePayload({ uiMeta }) {
      return get(uiMeta, 'props.exchangePayload', { exchange_type: 1, store_name: 'national_day_store' })
    }
  },

  methods: {
    getCustomizeStyle(name) {
      return get(this.customizeStyle, name, {}) || {}
    },

    handleClose() {
      this.$dialogs[this.dialogName].close()
      this.value = 1
    },

    updateStorage(flag) {
      this.$store.commit('storage/update', { [this.storageKey]: !!flag })
    },

    updateChipInfo() {
      this.$base.getChipInfo()
      this.$emit('exchange')
    },

    async onExchange(data) {
      const exchangeNum = this.value
      if (!exchangeNum) {
        return this.$toast(textConfig.leastToast)
      }
      this.handleClose()

      const [err, res] = await concatCatch(
        doExchange({
          target_uid: this.$base.amApiParam().uid,
          exchange_count: exchangeNum,
          exchange_type: data.exchange_id,
          ...this.exchangePayload
        })
      )
      if (err) {
        console.error('🐲 gwl ~ onExchange ~ err:', err)
        return
      }

      const { rewards } = res
      this.$dialogs[this.rewardDialogKey].open({
        data: {
          rewardList: [{ ...this.$propsConfig(data.exchange_id), reward_name: rewards[0].reward_type_name || rewards[0].reward_name, unit: getNumUnit(rewards[0].reward_num) }]
        }
      })

      this.updateChipInfo()
    },

    // exchangeConfirmText(key, data) {
    //   return this.$text(key, {
    //     costNum: data.totalCost,
    //     chipName: this.textWords.chipName,
    //     rewardName: data.rewardName,
    //     exchangeNum: data.exchangeNum
    //   })
    // },

    changeValue(value) {
      this.value = value
    }
  }
}
</script>

<style lang="scss" scoped></style>
