<template lang="pug">
  a-position(v-bind="attrs")
    ui-team-info-4-members(:uiMeta="teamInfo4members")
    ui-team-4-members(
      :uiMeta="team4members"
    )
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'

export default {
  mixins: [attrs],

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    teamInfo4members() {
      return { props: this.uiMeta.props.teamInfo4members }
    },

    team4members() {
      return { props: this.uiMeta.props.team4members }
    }
  },

  onHide() {},

  onShow() {},

  created() {
    // this.$store.commit('base/initDefault', { meta: this.$route.meta })
  },

  async mounted() {
    // await this.$store.dispatch('base/amInit')
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
#activity--main {
  overflow-x: hidden;
  height: 100vh;
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
