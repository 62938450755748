<template lang="pug">
a-section.ui-reward
  ui-cumulative.ui-reward__cumulative(v-bind='$attrs', :nodeList='nodeList', :val='recharge_amount')

  //- 奖励展示部分
  a-position.ui-reward__box-list(v-for='(item, i) in rechargeGiftList', :key='i', :bg-i='`icon/bg-${getTypeByStatus(stageStatus[i]?.state)}${item.bg_num}`', bg-s='contain', v-bind='item.domPosition')
    //- 底部的点状态icon
    a-position.ui-reward__box-list__icon(:bg-i='`icon/${getTypeByStatus(stageStatus[i]?.state)}`', bg-s='contain', v-bind='item.iconPosition || defaultConfig.iconPosition')
    //- 累计数值展示
    a-position.ws-nowrap.ui-reward__box-list__value(
      fs='.2',
      fw='500',
      :fc='getColor(colorConfig.num_color_map, stageStatus[i]?.state)',
      v-html='$tf(pointNumTxt || "cumulative/value", nodeList[i]?.special_val || nodeList[i]?.value)',
      v-bind='item.textPosition || defaultConfig.textPosition'
    )

    a-position.ui-reward__box-list__gift-list(v-for='(gift, idx) in exhibitRewardGiftList[i]', :key='idx', :wh='gift.bg_config.wh || gift.wh || defaultConfig.default_wh', v-bind='gift.bg_config')
      //- 奖励
      prop.ui-reward__box-list__gift-list__prop(v-for='(propConfig, k) in gift.list', :key='k', :extra='extra', v-bind='propConfig')
      a-section.ws-nowrap.ui-reward__box-list__gift-list__or1(v-if='gift.orConfig', v-bind='gift.orConfig', v-html='gift.orText')
      a-border-text1.ws-nowrap.ui-reward__box-list__gift-list__or2(v-if='gift.orConfig2', v-bind='gift.orConfig2', :borderContent='gift.orText')

      //- 数量icon
      a-border-text1.ws-nowrap.ui-reward__box-list__gift-list__icon(
        f='.22 #fff 600',
        borderWidth='2',
        :borderColor='getColor(colorConfig.text_shadow_color_map, stageStatus[i]?.state)',
        :borderContent='gift.list[0]?.icon_text',
        v-bind='gift.icon_ps || icon_ps'
      )
      a-border-text1.ws-nowrap.ui-reward__box-list__gift-list__icon2(
        v-if='gift.icon_ps2',
        f='.22 #fff 600',
        borderWidth='2',
        :borderColor='getColor(colorConfig.text_shadow_color_map, stageStatus[i]?.state)',
        :borderContent='gift.list[1]?.icon_text',
        v-bind='gift.icon_ps2 || icon_ps'
      )

      //- 奖励名称
      a-position.ui-reward__box-list__gift-list__name(
        :fs='gift.fs || 0.16',
        :fc='getColor(colorConfig.name_color_map, stageStatus[i]?.state)',
        fw='500',
        v-bind='gift.name_ps || defaultConfig.name_ps',
        :class='{ "ws-nowrap": !gift.width }',
        :style='getStyle(gift)'
      )
        template(v-if='isAr')
          span {{ propsConfig[gift?.list[0]?.id]?.name2 }}
          //- span(v-if='propsConfig[gift?.list[0]?.id]?.type === 23 || propsConfig[gift?.list[0]?.id]?.type === 999 || propsConfig[gift?.list[0]?.id]?.type === 6') {{ propsConfig[gift?.list[0]?.id]?.name2 }}
          //- span(v-else) {{ propsConfig[gift?.list[0]?.id]?.name }}
        span(v-else) {{ defaultConfig?.isName2 ? propsConfig[gift?.list[0]?.id]?.name2 : propsConfig[gift?.list[0]?.id]?.name }}

      //- 奖励名称
      a-position.ui-reward__box-list__gift-list__name2(
        v-if='gift.name_ps2',
        :fs='gift.fs || 0.16',
        :fc='getColor(colorConfig.name_color_map, stageStatus[i]?.state)',
        fw='500',
        v-bind='gift.name_ps2 || defaultConfig.name_ps',
        :class='{ "ws-nowrap": !gift.width }',
        :style='getStyle(gift)'
      )
        template(v-if='isAr')
          span(v-if='propsConfig[gift?.list[1]?.id]?.type === 23 || propsConfig[gift?.list[1]?.id]?.type === 999 || propsConfig[gift?.list[1]?.id]?.type === 6') {{ propsConfig[gift?.list[1]?.id]?.name2 }}
          span(v-else) {{ propsConfig[gift?.list[1]?.id]?.name }}
        span(v-else) {{ defaultConfig?.isName2 ? propsConfig[gift?.list[1]?.id]?.name2 : propsConfig[gift?.list[1]?.id]?.name }}

      //- 特殊的icon
      a-position.ui-reward__box-list__gift-list__special-icon(v-if='gift.special_icon', v-bind='gift.special_icon')

  slot
</template>

<script>
import prop from './prop'

import positionConfig1 from './positionConfig1'
import positionConfig2 from './positionConfig2'

export default {
  components: { prop },

  props: {
    // 累计任务id
    taskId: {
      type: Number,
      default: 1
    },

    // 奖励定位类型
    // 传入String选择已有的奖励定位配置
    // 传入Object使用新的奖励定位配置
    positionType: {
      type: [String, Object],
      default: ''
    },

    // 节点列表
    nodeList: {
      type: Array,
      default: () => []
    },

    // 颜色配置
    colorConfig: {
      type: Object,
      default: () => {}
    },

    // 礼物配置
    giftConfig: {
      type: Object,
      default: () => {}
    },

    // 等同于ui-prop里面的extra
    extra: {
      type: Object
    },

    pointNumTxt: {
      type: String,
      default: ''
    },

    propRechargeValue: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      positionConfig1,
      positionConfig2
    }
  },

  computed: {
    amApiParam() {
      return this.$amApiParam()
    },

    isAr() {
      return this.$route.query?.region === 'A'
    },

    propsConfig() {
      return this.$propsConfig()
    },

    exhibitRewardGiftList() {
      return this.exhibitRewardList.map((giftList) => {
        return giftList.map((gift) => {
          const list = gift.list?.map((i) => {
            return {
              ...i,
              ...this.giftConfig[i.id_idx]
            }
          })
          return { ...gift, list }
        })
      })
    },

    positionConfig() {
      return typeof this.positionType === 'string' ? this[this.positionType] : this.positionType
    },

    defaultConfig() {
      return this.positionConfig.defaultConfig
    },

    icon_ps() {
      if (this.isAr) {
        return this.defaultConfig.a_icon_ps
      } else {
        return this.defaultConfig.icon_ps
      }
    },

    rechargeGiftList() {
      let list = this.positionConfig.rechargeGiftList
      if (this.isAr) {
        list = list.map((item) => {
          let domPosition = item.domPosition
          if (domPosition.pl) {
            domPosition.pr = domPosition.pl
            delete domPosition.pl
          } else if (domPosition.pr) {
            domPosition.pl = domPosition.pr
            delete domPosition.pr
          }
          return {
            bg_num: item.bg_num,
            iconPosition: item.iconPosition,
            textPosition: item.textPosition,
            domPosition
          }
        })
      }
      return list
    },

    exhibitRewardList() {
      return this.positionConfig.exhibitRewardList
    },

    levelTaskInfo() {
      return this.$store.state.task.levelTasksInfo[this.taskId]
    },

    // 值
    recharge_amount() {
      return this.levelTaskInfo.value || this.propRechargeValue || 0
    },

    // 累充状态
    stageStatus() {
      if (this.levelTaskInfo.stages) {
        return this.levelTaskInfo.stages
      } else {
        return new Array(this.nodeList.length).fill({ state: 0 })
      }
    },

    // 获取累计按钮状态
    recharge_button_status() {
      return this.levelTaskInfo.totalState || 0
    }
  },

  created() {
    this.$store.commit('task/initLevelTaskItemDefault', this.taskId)
  },

  async mounted() {},

  beforeDestroy() {},

  methods: {
    init() {
      this.getTaskStatus()
    },

    getStyle(gift) {
      const newStyle = gift.width ? { width: `${gift.width}rem`, 'text-align': `center` } : {}
      return { ...gift.style, ...newStyle }
    },

    // 累计状态得到展示的图片类型
    getTypeByStatus(status) {
      const type_map = {
        0: 'not-reached',
        1: 'get',
        2: 'geted'
      }
      return type_map[status]
    },

    // 通过累计状态获取颜色值
    getColor(map, status) {
      return map[status]
    },

    // 获取任务状态
    async getTaskStatus() {
      await this.$store.dispatch('task/getTasksGroupList')
      this.$emit('taskData', this.levelTaskInfo)
    },

    // 领取奖励
    async getReward() {
      const res = await this.$store.dispatch('task/recvTaskReward', this.taskId)
      this.$emit('getRewards', res?.rewards)
      await this.getTaskStatus()
    }
  }
}
</script>

<style lang="scss" scoped></style>
