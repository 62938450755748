export default [
  {
    path: '/activity-20221210-main-ja',
    component: () => import('./pages/main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2023/03/31 23:59:59 UTC+8',
      quickConfig: '096e1ff30361040ec31674e76cedd685'
    }
  },
  {
    path: '/activity-20221210-rank-ja',
    component: () => import('./pages/rank/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      // expire: '2023/03/31 23:59:59 UTC+8',
      quickConfig: 'b2d133e01c46d346637f49306470a512'
    }
  },
  {
    path: '/activity-receive-goods-ja',
    component: () => import('./pages/goods/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.title = to.meta.params.title
      },
      ignore: true,
      // expire: '2023/03/01 23:59:59 UTC+8',
      quickConfig: 'b2201cbb4889fd03d27a8ec47810f61d'
    }
  }
]
