import config from '@/config'
import axios from 'axios'
import appsflyerInstall from '../appsflyer'
import linkedmeInstall from '../linkedme'
import { IS_ANDROID } from '@/utils/env'

// 这个域名不用改，可以用版本的
const domain = config.$env.DEV ? 'https://static-h5-dev.weplayapp.com' : 'https://static-h5.weplayapp.com'
let linkedme
let appsflyer
const { dynamicLink } = config
let getAsyncLink
/**
 * 获取appsflyer深链
 * @param {object} params afCustom自定义参数
 * @param {boolean} needTransfer 是否需要将参数转成key-value的数组
 * @returns URL
 */
function appsflyerFetch(params, needTransfer = true) {
  const { clickURL } = appsflyer.generateOneLinkURL({
    oneLinkURL: dynamicLink.data.oneLinkURL,
    afParameters: {
      afCustom: needTransfer
        ? Object.keys(params)
            .map((key) => {
              const value = params[key]
              return { paramKey: key, defaultValue: typeof value === 'string' ? value : encodeURIComponent(JSON.stringify(value)) }
            })
            .filter((i) => i.defaultValue)
        : params
    }
  })
  return clickURL
}
/**
 * 获取firebase深链
 * @param {object} params link字段的param
 * @returns URL
 */
function firebaseFetch(params) {
  const litePkgName = 'com.wejoy.weplay.lite'
  const isLite = new URLSearchParams(window.location.search).get('pkg') === litePkgName
  if (isLite) {
    dynamicLink.data.androidInfo.androidPackageName = litePkgName
    dynamicLink.data.domainUriPrefix = 'https://weplaylite.page.link'
  }

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: dynamicLink.data.domainUriPrefix || 'https://weplay1.page.link',
          link: `${domain}/firdp?param=${encodeURIComponent(JSON.stringify(params))}`,
          androidInfo: dynamicLink.data.androidInfo,
          iosInfo: dynamicLink.data.iosInfo
        }
      },
      {
        params: {
          key: dynamicLink.key
        }
      }
    )
    .then(({ data: { shortLink } }) => (IS_ANDROID ? `intent:${shortLink}#Intent;end` : shortLink))
    .catch((err) => {
      console.error(err)
    })
}
/**
 * 获取linkedme深链
 * @param {object} params
 * @returns URL
 */
function linkedmeFetch(params) {
  return new Promise((resolve) => {
    linkedme.init(dynamicLink.key, { type: config.$env.DEV ? 'test' : 'live' }, (err) => {
      if (err) {
        return
      }

      linkedme.link({ params: JSON.stringify(params), android_custom_url: 'https://play.google.com/store/apps/details?id=com.wejoy.weplay' }, (err2, data) => {
        if (err2) {
          return
        }

        resolve(data.url)
      })
    })
  })
}

async function install() {
  if (getAsyncLink) return { getAsyncLink }
  // NOTE:方便测试，只上测试环境
  if (config.$env.DEV && new URLSearchParams(window.location.search).get('linkType') === 'linkedme') {
    dynamicLink.type = 'linkedme'
    dynamicLink.key = config.linkedme
  }
  switch (dynamicLink.type) {
    case 'linkedme':
      if (!linkedme) {
        linkedme = await linkedmeInstall()
      }
      getAsyncLink = linkedmeFetch
      break
    case 'appsflyer':
      if (!appsflyer) {
        appsflyer = await appsflyerInstall()
      }
      getAsyncLink = appsflyerFetch
      break
    case 'firebase':
      getAsyncLink = firebaseFetch
      break
    default:
      getAsyncLink = () => {
        return ''
      }
      break
  }
  return { getAsyncLink }
}
export default install
