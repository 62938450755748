<template lang="pug">
a-dialog.ws-nowrap(name='uiVoteConfirmDialog', :bg-i='useImages.bg')
  //- 作品名称
  a-position(pc='x', pt='2.06', :style='useStyle.workName') {{ $text(text.workName, { workName: item.video_name || '' }) }}
  //- 播放按钮
  play-btn(pc='x', pt='2.48', :bg-i='images.playBtn', :item='item')
  //- 剩余票数
  a-position(pc='x', pt='3.53', :style='useStyle.remainVoteNum') {{ $text(text.remainVoteNum, { remainVoteNum: ticketCount }) }}

  input-control(pl='1.62', pt='4.06', v-model='number', :max='ticketCount', :useImages='useImages', :useStyle='useStyle')

  a-button1(pl='0.59', pb='0.74', v-bind='btnStyle', :bg-i='useImages.cancelBtn', @a-tap='close()')
  component(, pr='0.59', pb='0.74', v-bind='btnStyle', :bg-i='useImages.confirmBtn', @a-tap='confirmVote()', :is='isCanVote ? "a-button1" : "a-position"', :class='{ grey: !isCanVote }')
</template>

<script>
import playBtn from '../components/play-btn.vue'
import inputControl from '../components/input-control.vue'

import { genUsePropsMixin } from '../mixins'
import { VOTE_CONFIRM_DIALOG } from '../const'

import { buildApi, concatCatch } from '@sub/utils/request'
const doVote = buildApi('/activity_v2/vote/do_vote')

const btnStyle = {
  w: '2.5',
  h: '0.9',
  bgS: 'contain'
}
export default {
  components: {
    playBtn,
    inputControl
  },

  mixins: [genUsePropsMixin(VOTE_CONFIRM_DIALOG)],

  data() {
    return {
      btnStyle,

      item: {},

      // 用户输入的票数
      number: 1
    }
  },

  computed: {
    ticketChipId() {
      return this._storeState('ticketChipId')
    },
    ticketCount() {
      return this.$base.chipInfo()[this.ticketChipId]
    },

    localStorage() {
      return this.$storage.localStorage()
    },

    isCanVote() {
      return this.ticketCount > 0
    }
  },

  created() {
    this._storeCommit('setState', {
      openVoteConfirmDialog: this.open.bind(this)
    })
  },

  methods: {
    open({ item }) {
      this.number = Math.min(this.ticketCount, 1)
      this.item = item
      this.$dialogs.uiVoteConfirmDialog.open({ options: { bgClose: true } })
    },
    close() {
      this.$dialogs.uiVoteConfirmDialog.close()
    },

    async confirmVote() {
      if (!this.isCanVote) {
        return
      }

      const [err] = await concatCatch(
        doVote({
          id: this.item.id,
          number: this.number
        })
      )

      if (err) {
        console.warn('doVote has error', err)
        return
      }

      this.close()

      // update
      this._storeDispatch('getRankList')
      this._storeDispatch('getRankInfo')
      this.$base.getChipInfo()
      const searchVal = this._storeState('searchVal')
      searchVal && this._storeDispatch('doSearch')

      // success
      if (!this.localStorage.noSuccessTip) {
        this.$dialogs.uiVoteSuccessDialog.open({ data: { item: this.item } })
      }
    }
  }
}
</script>

<style></style>
