export default [
  {
    path: `/activity/v2/20240605/main-a/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 2235,
      // TODO:
      expire: '2024/8/06 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'F1yFq5qs'
    }
  },
  {
    path: `/activity/v2/20240605/share-a/index.html`,
    component: () => import('./share.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      // TODO:
      id: 2235,
      // TODO:
      expire: '2024/8/06 23:59:59 UTC+8',
      // TODO:
      pageCenter: 'F1yFq5qs'
    }
  }
]
