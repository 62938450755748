<template lang="pug">
a-section(v-if='taskItem && type === "normal"', v-bind='$attrs', bg-s='100%', :bg-i='itemBg', :wh='config.uiRechargeItem.mainConfig.itemWh')
  a-position(:bg-i='lockIcon', bg-s='100%', v-bind='config.uiRechargeItem.mainConfig.lock')
  a-position.fx-row.fx-m-end.ltr(v-bind='config.uiRechargeItem.mainConfig.rewardContent')
    a-section(v-for='(r, i) in taskItem.rewards', wh='1', ml='.14', :bg-i='rewardItemBg', bg-s='100%')
      ui-prop-wrapper(:id='r.reward_id', wh='100%', pc)
      a-border-text1.txt-no-wrap(:f='`.22 ${config.uiRechargeItem.subscriptStyle.color} 600`', borderWidth='2', :borderColor='config.uiRechargeItem.subscriptStyle.borderColor', :borderContent='$getPropUnit(r.reward_id, r.reward_val)', pb='0', pl='0')
      a-position(w='100%', pt='1.01', lh='.22', :f='`.16 ${rewardNameColor} 500`') {{ r?.reward_merge_info?.reward_type_name }}
  a-position(:f='`.2 ${rewardNameColor} 600`', v-bind='config.uiRechargeItem.mainConfig.textContent', v-html='$tf(`${config.uiRechargeItem.mainConfig.textContent.tf}`, taskItem.condition_map[1])')
a-section(v-else-if='taskItem && type === "dialog"', v-bind='$attrs', bg-s='100%', :bg-i='itemBg', :wh='config.uiRechargeItem.dialogConfig.itemWh')
  a-position(:bg-i='lockIcon', bg-s='100%', v-bind='config.uiRechargeItem.dialogConfig.lock')
  a-position.fx-row.fx-m-end.ltr(v-bind='config.uiRechargeItem.dialogConfig.rewardContent')
    a-section(v-for='(r, i) in taskItem.rewards', ml='.14', wh='.88', :bg-i='rewardItemBg', bg-s='100%')
      ui-prop-wrapper(:id='r.reward_id', wh='100%', pc)
      a-border-text1.txt-no-wrap(:f='`.22 ${config.uiRechargeItem.subscriptStyle.color} 600`', borderWidth='2', :borderColor='config.uiRechargeItem.subscriptStyle.borderColor', :borderContent='$getPropUnit(r.reward_id, r.reward_val)', pb='0', pl='0')
      a-position(w='100%', pt='.92', lh='.18', :f='`.14 ${rewardNameColor} 500`') {{ r?.reward_merge_info?.reward_type_name }}
  a-position.txt-r(:f='`.2 ${rewardNameColor} 600`', v-bind='config.uiRechargeItem.dialogConfig.textContent', v-html='$tf(`${config.uiRechargeItem.dialogConfig.textContent.tf}`, taskItem.condition_map[1])')
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'normal'
    },

    config: {
      type: Object,
      default: () => {}
    },

    taskItem: {
      type: Object,
      default: () => null
    }
  },

  data() {
    return {}
  },

  computed: {
    itemBg({ taskItem }) {
      return taskItem.state ? this.config.uiRechargeItem.itemBgUnlock : this.config.uiRechargeItem.itemBgLock
    },

    rewardItemBg({ taskItem }) {
      return taskItem.state ? this.config.uiRechargeItem.rewardBgUnlock : this.config.uiRechargeItem.rewardBgLock
    },

    lockIcon({ taskItem }) {
      return this.config.uiRechargeItem[`lockIconState${taskItem.state}`]
    },

    rewardNameColor({ taskItem }) {
      return taskItem.state ? this.config.uiRechargeItem?.color1 : this.config.uiRechargeItem?.color2
    }
  },

  mounted() {},

  afterInit() {},

  onPageShow() {},

  onPageHide() {},

  methods: {}
}
</script>

<style scoped></style>
