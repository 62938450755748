import { cloneDeep } from 'lodash'
import { IS_DEV } from '@/utils/env'
import { getCryptType, encrypt } from './crypto'

// 数据加密
export const requestCrypto = (config) => {
  // const { __custom: custom = {} } = config
  const cryptType = getCryptType()
  if (cryptType !== -1) {
    if (IS_DEV) {
      console.info(
        `request-info：
        ${config.url} 
        ${cloneDeep(config.data)}`
      )
    }
    config.headers[config.method]['X-ENCRYPTED-VERSION'] = '4'

    if (cryptType === 2) {
      config.headers[config.method]['X-CA'] = 1
    }
    config.data = encrypt(config.data)
  }

  return config
}
