export default [
  {
    path: '/activity/v2/20221110/main-a/index.html',
    component: () => import('./main/page.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: '3baf575358bcab76a026447a6bb88ac9',
      language: 'ar'
    }
  },
  {
    path: '/activity/v2/20221110/promotion-a/index.html',
    component: () => import('./promotion.vue'),
    meta: {
      setMeta(to) {
        to.meta.id = to.meta.params.id
        to.meta.expire = to.meta.params.expire
        to.meta.title = to.meta.params.title
        to.meta.language = to.meta.params.language
      },
      quickConfig: 'b100480675dfea3593eaf473ab183c8c',
      language: 'ar'
    }
  }
]
