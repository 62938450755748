export default [
  {
    path: `/activity/v2/20240705/main-k/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 2405,
      expire: '2024/08/11 23:59:59 UTC+8',
      pageCenter: 'dcZNpSvm'
    }
  },
  {
    path: `/activity/v2/20240705/main-j/index.html`,
    component: () => import('./page.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title
      },
      id: 2405,
      expire: '2024/08/11 23:59:59 UTC+8',
      pageCenter: '8ykkHT2k'
    }
  }
]
