export default [
  {
    path: '/activity/v2/ambassador/main-a/index.html',
    component: () => import('./main.vue'),
    meta: {
      setMeta(to) {
        to.meta.title = to.meta.json.title1
        to.meta.id = to.meta.json.id
      },
      pageCenter: 'ij9jkCHu' // TODO
    }
  }
]
