<template lang="pug">
a-section.ui-pag(v-bind='$attrs')
  canvas.ui-pag__canvas(:ref='id', style='width: 100%; height: 100%')
  slot
</template>

<script>
import isArray from 'lodash/isArray'
import PAG from '@sub/store/common/PAG'

export default {
  props: {
    canvasWidth: {
      type: [String, Number],
      default: ''
    },
    canvasHeight: {
      type: [String, Number],
      default: ''
    },
    urls: {
      type: [String, Array],
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    defaultPAG: {
      type: String,
      default: ''
    },

    autoPlay: {
      type: Boolean,
      default: true
    },
    showKey: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      formatUrls: [],
      id: `pag${Date.now()}`,
      pagView: null,
      PAG: null,
      initSuccess: false,
      pagComposition: null,
      pagFiles: {}
    }
  },

  computed: {
    formatShowKeys() {
      return this.showKey.map((item) => this.getAssets(item))
    },
    defaultKey() {
      return this.defaultPAG || this.formatUrls[0]
    }
  },

  created() {
    if (Object.keys(this.$PAG || {}) == 4) {
      console.error('页面PAG数量不能超过4个！！')
    }
    if (!this.name) {
      return console.warn('pag name is required!!!')
    }
    this.formatUrl(this.urls)
    if (!this[PAG.PAGProperty][this.name]) {
      this[PAG.PAGProperty][this.name] = this
    }
    PAG.init(this.name)
      .then(() => {
        this.PAG = PAG.pagIns
        if (!this.PAG) {
          console.info(`PAGIns不存在，加载失败，降级为非动效模式`)
          this.$store.commit('base/setForceIsOpenEffect', false)
        } else {
          console.info(`PAG.init,$PAG.${this.name}挂载成功`)
          this.pagInit(this.formatUrls)
        }
      })
      .catch((e) => {
        this.$store.commit('base/setForceIsOpenEffect', false)
        console.error('pagInit ERROR=', e)
        // TODO: 上报错误埋点
      })
  },

  async mounted() {},

  beforeDestroy() {
    try {
      delete this[PAG.PAGProperty][this.name]
      !this.pagView.isDestroyed && this.pagView.destroy()
    } catch {
      console.warn('pag destroy error')
    }
  },

  methods: {
    getPx(val) {
      return this.$getRemToPx(val)
    },
    getPx1(val) {
      return val * 100
    },
    // 格式化url
    formatUrl(urls) {
      if (!isArray(urls)) {
        urls = [urls]
      }

      this.formatUrls = urls.map((url) => this.getAssets(url))
    },

    addLayer({ key, url }) {
      this.pagFileInit({
        url,
        key
      })
      this.pagComposition.addLayer(this.pagFiles[key])
    },
    //----- 废弃 ----
    //针对某个key的位置进行设置位置，
    async setPosition({ key, style = { scale: 1, pl: 0, pt: 0 } }) {
      if (!this.initSuccess) {
        return console.warn('PAG初始化未完成')
      }
      if (!this.pagView) {
        return console.warn('setPosition() pagView不存在')
      }
      const file = this.getPAGFile(key)
      const scale = typeof style.scale == 'undefined' ? 1 : style.scale
      if (style.w || style.h || style.wh) {
        file.setContentSize(this.getPx(style.w || style.wh), this.getPx(style.h || style.wh))
      }
      await file.setMatrix(this.PAG.Matrix.makeAll(scale, 0, this.getPx(+style.pl || 0), 0, scale, this.getPx(+style.pt || 0)))
      this.pagView.flush()
    },
    //----- 废弃 ----

    // 画布内使用的标准尺寸，即 size 为100，与figma取值一致。
    async setPosition1({ key, style = { scale: 1, pl: 0, pt: 0 } }) {
      if (!this.initSuccess) {
        return console.warn('PAG初始化未完成')
      }
      if (!this.pagView) {
        return console.warn('setPosition() pagView不存在')
      }
      const file = this.getPAGFile(key)
      const scale = typeof style.scale == 'undefined' ? 1 : style.scale
      if (style.w || style.h || style.wh) {
        file.setContentSize(this.getPx1(style.w || style.wh), this.getPx1(style.h || style.wh))
      }
      await file.setMatrix(this.PAG.Matrix.makeAll(scale, 0, this.getPx1(+style.pl || 0), 0, scale, this.getPx1(+style.pt || 0)))
      this.pagView.flush()
    },

    setAllFilePosition({ style = { scale: 1, pl: 0, pt: 0 } }) {
      Object.keys(this.pagFiles).forEach((key) => {
        this.setPosition({ key, style })
      })
    },

    // 获取资源
    getAssets(url) {
      return this.$route.meta.assets?.[url] || url
    },

    // 获取pag file
    getPAGFile(key) {
      return this.pagFiles[this.getAssets(key)]
    },

    // 请求pag资源
    requestPAG(src) {
      return fetch(src).then((response) => {
        return response.arrayBuffer()
      })
    },

    // 加载pag文件
    async loadPAGFile(data, key) {
      const pagFile = await this.PAG.PAGFile.load(data)
      return {
        key,
        data: pagFile
      }
    },

    // 初始化pag
    async pagInit(urls) {
      const infos = urls.map((url) => {
        return {
          key: url,
          url: url
        }
      })
      await this.pagFileInit(infos)
      this.pagViewInit()
    },
    async pagFileInit(infos) {
      const promises = []
      for (let i = 0; i < infos.length; i++) {
        promises.push(this.requestPAG(this.getAssets(infos[i].url)))
      }
      const filesBuffer = await Promise.all(promises)

      const filePromises = []
      for (let i = 0; i < filesBuffer.length; i++) {
        const fileBuffer = filesBuffer[i]
        filePromises.push(this.loadPAGFile(fileBuffer, infos[i].key))
      }
      const pagFiles = await Promise.all(filePromises)
      pagFiles.forEach((pagFile) => {
        this.pagFiles[pagFile.key] = pagFile.data
      })
    },

    loadImage(src) {
      return new Promise((resolve) => {
        const image = new Image()
        image.onload = () => {
          resolve(image)
        }
        image.onerror = () => {
          resolve(false)
        }
        image.crossOrigin = 'anonymous'
        image.src = this.getAssets(src) || src
      })
    },

    // key: 待替换PAG的key
    async replaceImage({ key, imageIndex, imageSrc }) {
      if (!this.initSuccess) {
        return console.warn('PAG初始化未完成')
      }
      if (!this.pagView) {
        return console.warn('replaceImage() pagView不存在')
      }
      key = key || this.defaultKey
      const image = await this.loadImage(imageSrc)
      if (!image) return
      const pagImage = this.PAG.PAGImage.fromSource(image)
      const file = this.getPAGFile(key)
      await file.replaceImage(imageIndex, pagImage)
      this.pagView.flush()
    },

    setVisible(key, value) {
      this.getPAGFile(key).setVisible(value)
      this.pagView.flush()
    },

    // 初始化pagView
    async pagViewInit() {
      const canvas = this.$refs[this.id]
      if (!canvas) return
      const file = this.getPAGFile(this.defaultKey)
      const widthRem = this.canvasWidth || file.width() / 100
      const heightRem = this.canvasHeight || file.height() / 100
      const widthPx = widthRem * 100
      const heightPx = heightRem * 100
      canvas.style.width = `${widthRem}rem`
      canvas.style.height = `${heightRem}rem`
      // 在部分安卓手机上展示有问题
      // canvas.width = widthPx * window.devicePixelRatio
      // canvas.height = heightPx * window.devicePixelRatio
      canvas.width = widthPx * 2
      canvas.height = heightPx * 2
      this.pagComposition = this.PAG.PAGComposition.make(widthPx, heightPx)
      Object.keys(this.pagFiles).forEach((key) => {
        const file = this.pagFiles[key]
        if (this.formatShowKeys.find((item) => item === key)) {
          this.pagFiles[key].setVisible(true)
        } else {
          this.pagFiles[key].setVisible(false)
        }
        this.pagComposition.addLayer(file)
      })
      this.pagView = await this.PAG.PAGView.init(this.pagComposition, canvas, { useScale: false })
      // canvas.style.width = '100%'
      // canvas.style.height = '100%'
      // this.pagView.setScaleMode(types.PAGScaleMode.Zoom)

      // 加载成功
      this.initSuccess = true
      console.info('ui-pag initSuccess,发送loadSuccess事件')
      this.$emit('loadSuccess')

      if (this.autoPlay) {
        // autoPlay 默认播放第一个
        file.setVisible(true)
        this.pagView.setRepeatCount(0)
        this.pagView.play()
      }
    },
    hideAll() {
      Object.values(this.pagFiles).forEach((item) => {
        item.setVisible(false)
      })
      this.pagView.flush()
    },

    /**
     * @description pag播放方法
     * @param {Object} config 播放的配置
     * @param {string} config.key 资源key
     * @param {number} config.repeatCount 重复次数
     * @param {number} config.startProgress 开始播放时动效的进度 范围0-1
     * @param {number} config.endFrame 结束播放后停止的进度 范围0-1
     */
    async playPAG({ key, repeatCount = 0, startProgress = 0, endFrame = 0 }) {
      try {
        console.info('playPag,key=', key)
        key = key || this.defaultKey
        const keys = typeof key == 'string' ? [key] : key
        // 初始化成功判断
        if (!this.initSuccess) {
          return console.warn('PAG初始化未完成')
        }
        if (!this.pagView) {
          return console.warn('playPAG() pagView不存在')
        }
        this.pagView.removeListener('onAnimationEnd')

        this.pagView.pause()
        this.hideAll()
        keys.forEach((k) => {
          // this.pagComposition.setLayerIndex(this.getPAGFile(k), 0)
          this.getPAGFile(k).setVisible(true)
        })
        this.pagView.setRepeatCount(repeatCount)
        this.pagView.setProgress(startProgress)
        this.pagView.play()

        return new Promise((resolve) => {
          const animationEnd = () => {
            resolve()
            this.pagView.removeListener('onAnimationEnd', animationEnd)
            this.pagView.setProgress(endFrame)
            this.pagView.flush()
          }
          this.pagView.addListener('onAnimationEnd', animationEnd)
        })
      } catch (error) {
        console.error(error)
        return Promise.resolve()
      }
    },

    /**
     * @description 设置当前PAG展示的进度
     * @param {number} endFrame 结束播放后停止的进度 范围0-1
     */
    setPAGFrame(frame) {
      this.pagView.setProgress(frame)
      this.pagView.flush()
    },

    /**
     * @description pag切换默认动效
     * @param {Object} config 切换的配置
     * @param {string} config.key 资源key
     * @param {number} config.progress 动效的进度 范围0-1* @param {Object} config 播放的配置
     */
    async switchPAG({ key, progress = 0 }) {
      // 初始化成功判断
      if (!this.initSuccess) {
        return console.warn('PAG初始化未完成')
      }
      if (!this.pagView) {
        return console.warn('switchPAG() pagView不存在')
      }
      key = key || this.defaultKey
      const keys = typeof key == 'string' ? [key] : key
      this.hideAll()
      keys.forEach((k) => {
        this.getPAGFile(k).setVisible(true)
      })
      this.pagView.setProgress(progress)
      this.pagView.flush()
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-pag {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
