import qucikConfig from '@/store/quick-config'
import { useIntersectionObserver } from '@vueuse/core'
import get from 'lodash/get'
// 适用于加载gif大文件
function observer(target) {
  return useIntersectionObserver(target, ([{ isIntersecting }]) => {
    const isSupport = Object.prototype.toString.call(isIntersecting) === '[object Boolean]'
    target.isShow = isSupport ? isIntersecting : true

    if (!isSupport || isIntersecting) {
      target && showImage(target, target.dataSrc)
    } else {
      // 反向懒加载
      target && showImage(target, '')
    }
  })
}

function showImage(el, imgSrc) {
  const assetsConfig = get(qucikConfig.getQuickConfig(window.location.pathname), 'assets', {})
  imgSrc = assetsConfig[imgSrc] || imgSrc

  if (el.src === imgSrc) {
    return
  }

  el.style.background = ''
  el.style.backgroundImage = imgSrc ? `url(${imgSrc})` : ''
  el.style.backgroundSize = el.style.backgroundSize || 'cover'
  el.src = imgSrc || ''
}

export default {
  inserted(el, binding) {
    showImage(el, el.dataset.default)
    el.dataSrc = binding.value

    observer(el)
  },

  update(el, binding) {
    if (el.dataSrc !== binding.value && el.isShow) {
      showImage(el, binding.value)
    }

    el.dataSrc = binding.value
  }
}

/* 
  a-avatar(wh='1.01' v-imgLazy="item.u1.avatar" data-default="rank/empty.png")
  data-default传自定义的默认图片
*/
