import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'

import toast from '@/lib/page-toast'

import config from '@/config'

import store from '@/store'

import { IS_WEPLAY, IS_DEV } from '@/utils/env'
import { requestCrypto } from '@sub/service/interceptors'
import { getCryptType, decrypt } from '@sub/service/crypto'

import proxyAssets from '@sub/utils/proxy-assets'
import point from '@sub/utils/point-methods'
import * as iframe from '@sub/ui-runtime/core/iframe'
/**
 * @module requestApi
 */

/**
 * 构建实例
 *  - host
 *  - options
 *  - result.code
 *  - result.msg
 *  - result.data
 *  - interceptors.request
 *  - interceptors.response
 */
export function genService({ host, options, result, interceptors } = {}, showToast) {
  const service = axios.create({ baseURL: host, withCredentials: false, timeout: 10000, ...options })

  service.interceptors.request.use(requestCrypto)
  service.interceptors.request.use(
    (config) => {
      interceptors && interceptors.request && interceptors.request(config)

      if (config.headers[config.method]['Content-Type'] === 'application/x-www-form-urlencoded' && Object.prototype.toString.call(config.data || {}) !== '[object FormData]') {
        config.data = qs.stringify(config.data)
      }

      // 记录请求开始时间
      config.startTime = performance.now()

      return config
    },

    async (e) => requestError({ msg: e }, showToast)
  )

  service.interceptors.response.use(
    async (response) => {
      let {
        data: res,
        config: { url }
      } = response

      if (getCryptType() !== -1) {
        res = await decrypt(res, response.headers['x-ca'] == '1')
        if (IS_DEV) {
          console.info('返回值：', url, res)
        }
      }

      interceptors && interceptors.response && interceptors.response(res)

      // 计算请求耗时
      if (response.config.isPointTrack) {
        const duration = performance.now() - response.config.startTime
        point.loadPage(duration, '单个接口耗时', 0.1, { request_url: response.config.url })
      }

      return res[result.code] === 200 ? proxyAssets(res[result.data]) : responseError({ code: res[result.code] || 403, msg: res[result.msg] || res }, showToast)
    },
    async (e) => responseError({ code: e?.response?.status, msg: e.message || e.msg }, showToast)
  )

  return service
}
async function requestError({ msg }, showToast) {
  showToast && serviceToast(msg)
  return Promise.reject({ code: -1, msg })
}
async function responseError({ code, msg = 'Error' }, showToast) {
  if (iframe.isInIframe()) {
    return Promise.reject({ code, msg })
  }

  showToast && serviceToast(msg)
  return Promise.reject({ code, msg })
}
/**
 * 根据错误消息显示相应的提示信息。
 * 如果在非 WePlay 平台上，则根据预定义的错误消息列表进行匹配，显示对应的提示信息。
 * @param {string} msg - 错误消息或需要显示的提示信息。
 */
function serviceToast(msg) {
  if (!IS_WEPLAY) {
    const toastList = [
      { key: 'timeout', value: '请求超时' },
      { key: '账号或设备被封禁', value: '网络繁忙，请稍后再试试' }
    ]
    if (typeof msg === 'string') {
      for (let i = 0; i < toastList.length; i++) {
        if (msg?.includes(toastList[i].key)) {
          msg = toastList[i].value
          break
        }
      }
    }
  }
  toast(msg)
}

// 错误处理
/**
 * 异步函数装饰器，用于处理异步函数执行过程中的 loading 状态，并返回结果或错误。
 * @param {Function} fn - 要执行的异步函数。
 * @param {string} [loading='loading'] - 加载状态的类名，默认为 'loading'。
 * @returns {Promise<Array>} 返回一个包含结果或错误的数组。
 * @example
 * // 使用示例
 * const [error, data] = await asyncDecorator(yourAsyncFunction, 'loading');
 * if (error) {
 *     console.error('An error occurred:', error);
 * } else {
 *     console.log('Data received:', data);
 * }
 */
export const concatCatch = async (fn, loading = 'loading') => {
  loading && document.body.classList.add(loading)
  const result = await fn.then((data) => [null, data]).catch((error) => [error, null])
  loading && document.body.classList.remove(loading)
  return result
}
export const concatCatch1 = (fn) => concatCatch(fn, false)
export const concatCatch2 = (fn) => concatCatch(fn, false)

/**
 * 由配置构建 service
 */
export function getGenApi(service, method, concatCatch = false) {
  const key = method === 'get' ? 'params' : 'data'

  // 默认拿到vuex中的amApiParam作为参数
  if (concatCatch) {
    return (url, config = {}) =>
      (d = {}, o = {}) => {
        const amApiParam = (store && store.getters && store.getters['base/amApiParam']) || {}
        return service({ url, method, [key]: { ...amApiParam, ...d }, ...o, ...config })
          .then((d) => [0, d])
          .catch((e) => [e, 0])
      }
  } else {
    return (url, config = {}) =>
      (d = {}, o = {}) => {
        const amApiParam = (store && store.getters && store.getters['base/amApiParam']) || {}
        return service({ url, method, [key]: { ...amApiParam, ...d }, ...o, ...config })
      }
  }
}
function getConfigServices(request, options, showToast = true) {
  return Object.keys(request).reduce((obj, name) => {
    // 返回axios实例
    const service = genService({ host: config.request[name], ...options[name] }, showToast)
    // getGenApi返回一个接收url的方法，‘接收url的方法’又返回一个接收参数的axios实例方法
    service.genGetApi = getGenApi(service, 'get')
    service.genPostApi = getGenApi(service, 'post')
    service.genGetApiCatch = getGenApi(service, 'get', true)
    service.genPostApiCatch = getGenApi(service, 'post', true)

    obj[name] = service

    return obj
  }, {})
}

function interceptorsRequestOperation(config) {
  const { locale } = Vue.prototype.$app?.$i18n || {}
  const langMap = { 'zh-hans': 'zh-Hans', 'zh-tw': 'zh-TW' }

  const key = config.method.toLowerCase() === 'post' ? 'data' : 'params'
  config[key] = { ...config[key], lang: langMap[locale] || locale }
}

/**
 * 所有的 request 实例
 */
export const service = getConfigServices(config.request, {
  h1: {
    result: { code: 'code', msg: 'msg', data: 'result' },
    interceptors: {
      // 在 genService 中调用
      request(config) {
        IS_WEPLAY && interceptorsRequestOperation(config)
      }
    }
  },
  h2: {
    result: { code: 'code', msg: 'msg', data: 'result' },
    interceptors: {
      request(config) {
        IS_WEPLAY && interceptorsRequestOperation(config)
      }
    }
  }
})

export const service1 = getConfigServices(
  config.request,
  {
    h1: {
      result: { code: 'code', msg: 'msg', data: 'result' },
      interceptors: {
        request(config) {
          IS_WEPLAY && interceptorsRequestOperation(config)
        }
      }
    },
    h2: {
      result: { code: 'code', msg: 'msg', data: 'result' },
      interceptors: {
        request(config) {
          IS_WEPLAY && interceptorsRequestOperation(config)
        }
      }
    }
  },
  false
)

export default service.h1

export const buildApi = IS_WEPLAY ? service.h1.genPostApi : service.h2.genPostApi
export const buildApi1 = IS_WEPLAY ? service1.h1.genPostApi : service.h2.genPostApi

export const buildApi2 = service.h2.genPostApi
export const buildApi3 = service.h2.genPostApi
