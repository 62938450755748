import weSDK from '@/lib/we-sdk'
import { rulePoint } from '@/utils/global-point'
import antelope, { checkSupportsWebp } from '@wepie-fe/activity-component'

export async function install(app) {
  await checkSupportsWebp()
  app.use(antelope, {
    imageSizeAutoLoader: true,
    imageSizeUnit: 'rem',
    imageTimes: 0.01,

    avatarOnTap: ({ uid, rid } = {}) => {
      if (uid) {
        window.event.stopPropagation()
        weSDK('jumpUserInfo', +uid)
      } else if (rid) {
        window.event.stopPropagation()
        weSDK('jumpDeepLink', `wespydeeplink://voice_room?rid=${rid}`)
      }
    },

    dialogOpenPoint(vm) {
      if (vm.name === 'rule') {
        rulePoint(vm.$route?.meta)
      }
    }
  })
}
