<template lang="pug">
#m-rank.ui-rank-4-members(v-if="image")
  a-section.ui-rank-4-members__wrapper(v-bind="attrs" w='7.5')
    a-section.ui-rank-4-members__wrapper__head(:bg-i="image.head" wh='7.5 0.54' )
    
    a-section.ui-rank-4-members__wrapper__body-wrapper(:bg-i="image.body" :wh='`7.5 ${rankList && (rankList.length * (1.7 + 0.12) + 1.7)}`' style="padding-top: 0.12rem")
      rank-item.ui-rank-4-members__wrapper__body-wrapper__item(v-for='(item, index) in rankList' :key='index' wh='6.9 1.7' m='0 auto' mb='.12' :index='index+1' :itemObj='item' type='row' :image='image' :textStyle="props.textStyle" :props='props')

  //- fixed mine
  mine.ui-rank-4-members__mine(v-if="mine" wh="7.5 1.7" p="fixed" :itemObj='mine' pb="0"  type='mine' :mineBG='image.mine' :image='image' :index='mine.rank' :rankConfig="props.rankConfig" :textStyle="props.textStyle" :props='props')
</template>

<script>
import { attrs } from '@sub/ui-runtime/core/mixins/attrs'
import amNewRank from '@sub/ui-runtime/core/activity-modules/newRank'
import event from '@sub/event'

import { formatItem } from './utils/config'
import get from 'lodash/get'

import rankItem from './components/rank-item.vue'
import mine from './components/mine.vue'

export default {
  mixins: [
    attrs,
    amNewRank({
      format: {
        list: formatItem,
        mine: formatItem,
        luck: formatItem
      }
    })
  ],

  components: { rankItem, mine },

  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    },

    amBasic: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  mounted() {
    this.init()
  },

  computed: {
    props() {
      return this.uiMeta?.props
    },

    amApiParam() {
      return this.$base.amApiParam()
    },

    act_id({ props }) {
      return props?.act_id || 0
    },

    image({ props }) {
      return props?.image
    },

    targetList({ amNewRankUse }) {
      return amNewRankUse?.total || {}
    },

    rankList({ targetList }) {
      return targetList?.list
    },

    mine({ targetList }) {
      return get(targetList, 'mine', null)
    }
  },

  methods: {
    init() {
      this.loadRank()

      event.onTeamInfoChange(this.loadRank)
    },

    loadRank() {
      const { rankLength, rankType, periodType } = this.props?.rankConfig

      let opt = {
        // 强制换行
        cache: false,
        value: this.$dayjs().format('YYYYMMDD'),
        params: {
          // 强制换行
          limit: rankLength,
          offset: 0,
          rank_type: rankType,
          period_type: periodType,
          support_rank_type: 1,
          support_period_type: 0,
          support_limit: 4,
          support_rank_member_type: 0,
          ...this.amApiParam
        }
      }
      this.act_id && (opt.params.act_id = this.act_id)

      this.amNewRankGet({
        period_type: 0,
        name: 'total',
        ...opt
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-border {
  border: 0.02px solid #fff;
}
</style>
