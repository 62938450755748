<template lang="pug">
a-section.ui-recharge-col-ar(v-bind='$attrs')
  //- 进度文案
  a-section.txt-no-wrap.ui-recharge-col-ar__text--1(v-if='rechargeTextShowState.line1', v-bind='config.uiRechargeWrapper.line1', v-html='$tf(`${config.uiRechargeWrapper.line1.tf}`, taskState.value)')
  a-section.ui-recharge-col-ar__text--2(v-if='rechargeTextShowState.line2', v-bind='config.uiRechargeWrapper.line2', v-html='$tf(`${config.uiRechargeWrapper.line2.tf}`, nextNeedValue)')

  a-position.ui-recharge-col-ar__bg(:bg-i='config.uiRechargeWrapper.record.bg', v-bind='config.uiRechargeWrapper.record', @a-tap='openRecord', bg-s='100%')

  a-position.fx-col.fx-c-center.ui-recharge-col-ar__content(v-bind='config.uiRechargeWrapper.content')
    a-section.ui-recharge-col-ar__content__item(v-for='(item, index) in config.uiRechargeWrapper.mainShowArr')
      recharge-item.ui-recharge-col-ar__content__item__recharge(v-if='item !== "button"', :taskItem='taskInfos[item]', mb='.13', :config='config')
      ui-button.ui-recharge-col-ar__content__item__button(v-else, bg-i='recharge/look-all', mb='.13', @a-tap='$dialogs.rechargeDetail.open({ options: { bgClose: true } })')

  a-dialog.ui-recharge-col-ar__dialog(name='rechargeDetail', :bg-i='config.dialog.bg')
    template(slot-scope='scope', v-if='scope.data')
      a-position.scroll-y.ui-recharge-col-ar__dialog__content(v-bind='config.dialog.content')
        recharge-item.ui-recharge-col-ar__dialog__content__task(type='dialog', v-for='(t, i) in taskInfos', :key='i', :taskItem='t', mb='.13', :config='config')
      ui-button.ui-recharge-col-ar__dialog__button(bg-i='button/comfirm-b', pc='x', pb='.28', @a-tap='$dialogs.rechargeDetail.close()')
</template>

<script>
import rechargeItem from './components/recharge-item-ar'
import { rechargeDefaultConfigAr } from './config'
export default {
  props: {
    uiMeta: {
      type: Object,
      default: () => {}
    }
  },

  components: { rechargeItem },
  data() {
    return {
      taskId: 1,
      config: { ...rechargeDefaultConfigAr, ...this.uiMeta }
    }
  },

  onPageShow() {
    this.$task.getTasksGroupList()
  },

  created() {
    this.$task.getTaskConfig()
    this.$task.getTasksGroupList()
  },

  computed: {
    userGender() {
      return this.$base.amBasic().user.gender
    },
    taskInfos({ taskState, taskRewards, userGender }) {
      const stateStages = taskState?.stages || []
      const rewardsStages = taskRewards?.stages || []
      return stateStages.map((item, index) => {
        const rewards = rewardsStages[index]?.rewards.map((r) => (r.length > 1 ? (userGender === 1 ? r[0] : r[1]) : r[0])) || []
        return {
          ...item,
          rewards
        }
      })
    },

    taskState() {
      return this.$task.levelTasksInfo()[this.config.taskId]
    },

    taskRewards() {
      return this.$task.taskConfig()[this.config.taskId] || {}
    },

    rechargeTextShowState({ taskState }) {
      const lastIdx = taskState?.stages?.length - 1
      const totalState = taskState?.name && !taskState?.stages[lastIdx]?.state

      // 达标后则隐藏
      const defaultConfig = { line1: totalState, line2: totalState }
      return defaultConfig
    },

    levelTaskValues({ taskRewards }) {
      return taskRewards?.stages ? taskRewards.stages.map((item) => item.condition_map[1]) : []
    },

    nextNeedValue({ taskState }) {
      return taskState?.next_stage_condition_left?.[1]
    },

    curRecvLevel({ taskState }) {
      let idx = 0
      taskState.stages?.forEach((item, index) => {
        if (item.state === 2) {
          idx = index
        }
      })
      return idx > 2 ? 2 : idx
    }
  },

  methods: {
    openRecord() {
      this.config.uiRechargeWrapper.record?.key && this.$record.openRecord()[`${this.config.uiRechargeWrapper.record?.key}`]()
    }
  }
}
</script>

<style scoped></style>
