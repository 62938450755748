import Vue from 'vue'
import { isFunction } from 'lodash'
import { createBtnPlugin } from '@sub/utils/eruda-plugins/utils'

const clickCallback = () => {
  const vm = Vue.prototype.$app
  Object.keys(vm.$dialogs).forEach((item, index) => {
    if (isFunction(vm.$dialogs[item]?.open)) {
      setTimeout(() => {
        console.log('dialogName:', item)
        vm.$dialogs[item]?.open({ options: { bgClose: true } })
      }, 400 * index)
    }
  })
}

// 一键打开所有弹窗
export default (parent) => createBtnPlugin({ parent, btnClassName: 'all-dialogs', btnText: '打开所有弹窗', clickCallback })
