import { methodsInit } from '../../utils'
import { amConfig } from '../config'
import { merge } from 'lodash'
const {
  lib: { dayjs, utc },
  modules: {
    rank: { request }
  }
} = amConfig

const { getState: getBaseState } = methodsInit('base')

export default {
  namespaced: true,

  state: {
    rankReward: null,
    rankPublicity: null,
    rank: {},
    myRankInfo: {},
    extra: {
      total: {
        utcOffset: utc || '+08:00'
      }
    }
  },

  getters: {
    toDayString({ extra }) {
      return Object.keys(extra).reduce((obj, name) => {
        const { utcOffset } = extra[name]
        const { amBasic } = getBaseState() || {}
        obj[name] = dayjs(amBasic?.time || Date.now())
          .utcOffset(utcOffset)
          .format('YYYYMMDD')
        return obj
      }, {})
    },

    rankDays({ extra }, getters) {
      return Object.keys(extra).reduce((obj, name) => {
        const { begin, end, utcOffset, labelFormat, today } = extra[name]
        let dayOptions = []
        let beginDate = dayjs(begin).utcOffset(utcOffset).valueOf()
        let endDate = dayjs(end).utcOffset(utcOffset).valueOf()
        let prevStatus = -1
        while (beginDate <= endDate) {
          const push = {
            label: dayjs(beginDate).utcOffset(utcOffset).format(labelFormat),
            date: dayjs(beginDate).utcOffset(utcOffset).format(labelFormat),
            value: dayjs(beginDate).utcOffset(utcOffset).format('YYYYMMDD')
          }
          if (prevStatus === 0) {
            prevStatus = 1
          } else if (push.value === getters.toDayString[name]) {
            push.label = today
            prevStatus = 0
          }
          push.status = prevStatus
          dayOptions.push(push)
          beginDate += 24 * 60 * 60 * 1000
        }
        if (utcOffset === '+03:00') {
          dayOptions = dayOptions.map((i) => {
            return { ...i, label: getTimeDesc(i.label) }
          })
        }
        obj[name] = dayOptions
        return obj
      }, {})
    },

    rankUse({ rank, extra }) {
      return Object.keys(rank).reduce((obj, name) => {
        const { bonus = 0, list, luck, mine, length } = rank[name]
        const { format } = extra[name] || extra['total']
        const rankList = [...Array(length)].map((n, i) => format.list({ name, type: 'list', index: i, item: list[i], bonus }))
        obj[name] = {
          bonus,
          list: rankList,
          list1_3: rankList.slice(0, 3),
          list4_10: rankList.slice(3, 10),
          [`list4_${length}`]: rankList.slice(3),
          luck: format.luck ? [...Array(2)].map((n, i) => format.luck({ name, type: 'luck', item: luck[i], bonus })) : [],
          mine: format.mine ? format.mine({ name, type: 'mine', item: mine, bonus }) : {}
        }
        return obj
      }, {})
    }
  },

  mutations: {
    // 初始化一些参数
    initDefault(
      state,
      {
        begin = '',
        end = '',
        format = {
          list: (i) => i,
          mine: (i) => i,
          luck: (i) => i,
          reward: (i) => i
        },
        utcOffset = utc || '+08:00',
        labelFormat = 'M月D日',
        today = '今日',
        namespace = 'total',
        requestApi = {}
      } = {}
    ) {
      state.extra[namespace] = {
        begin,
        end,
        format,
        utcOffset,
        labelFormat,
        today
      }
      merge(request, requestApi)
    },

    setRank(state, [name = '', obj = {}]) {
      state.rank[name] = obj
      state.rank = { ...state.rank }
    },

    // 设置rank里某个name的属性
    setRankAttr(state, [name = '', attr = '', value = '']) {
      state.rank[name][attr] = value
      state.rank = { ...state.rank }
    },

    setMyRankInfo(state, payload) {
      state.myRankInfo = payload
    }
  },

  actions: {
    async getRank({ state, commit, dispatch, rootState, rootGetters }, payload) {
      /**
       * 接口参数：
       * params-可承载所有接口参数
       * 兼容直接传参作为接口参数：
       * rank_type-排行榜类型（send_gift）
       * period_type-周期（0：活动期间，1：日榜）
       * fields-查询信息列表（vip,avatar,last_rank,car_in_use,room_info）
       *
       * 前端参数：
       * cache-是否缓存（不传默认true）
       * name-周期类型（不传默认total或者day20230404）
       * value-日期（不传默认当前时间，用于datatime: 20230404）
       * bonus-是否查询奖池（不传默认false）
       */
      let { cache = true, name = '', value = '', bonus = false, params = {}, rank_type = 'send_gift', period_type = 0, fields = '' } = payload || {}
      const amBasic = rootState.base.amBasic
      const amApiParam = rootGetters['base/amApiParam']
      const { utcOffset } = state.extra[name || 'total']

      value = value || dayjs(amBasic.time).utcOffset(utcOffset).format('YYYYMMDD')
      name = name || (period_type || params.period_type ? 'day' + value : 'total')

      if (state.rank[name] && cache) {
        return
      }

      if (!state.rank[name]) {
        commit('setRank', [name, { bonus: 0, list: [], luck: [], mine: {} }])
      }
      commit('setRankAttr', [name, 'length', params.limit || 10])

      const opt = { ...amApiParam, datetime: value }
      const reqParams = {
        ...opt,
        period_type,
        fields,
        rank_type,
        ...params
      }

      bonus && dispatch('getBonus', [name, opt])
      dispatch('getRankInfo', [name, reqParams])
      dispatch('getRankList', [name, reqParams])
    },

    // 获取每日奖池
    async getBonus({ getters, commit }, [name, opt]) {
      const dayOpt = getters.rankDays.find((i) => i.value === opt.datetime)
      const status = dayOpt ? dayOpt.status : -1
      const bonusVal = status !== 1 ? await request.getRankJackpot(opt) : 0
      commit('setRankAttr', [name, 'bonus', bonusVal])
    },

    async getRankInfo({ commit }, [name, reqParams]) {
      const { info } = await request.getRankInfo(reqParams)
      commit('setRankAttr', [name, 'mine', info])
    },

    async getRankList({ commit }, [name, reqParams]) {
      const { list } = await request.getRankList(reqParams)
      commit('setRankAttr', [name, 'list', list])
    },

    // 单独使用，例如玩法页单独展示自己的排名
    async getMyRankInfo({ commit, rootGetters }, params = {}) {
      const amApiParam = rootGetters['base/amApiParam']
      const { info } = await request.getRankInfo({
        ...amApiParam,
        ...params
      })
      commit('setMyRankInfo', info)
    }
  }
}

function getTimeDesc(label = '') {
  let desc = ''
  // replaceAll的顺序最好不要乱动，因为有可能前面的匹配把后面的匹配弄乱了
  desc = label
    .slice(0)
    .replaceAll('今日', 'اليوم')
    .replaceAll('11月', 'نوفمبر|')
    .replaceAll('12月', 'ديسمبر|')
    .replaceAll('1月', 'يناير|')
    .replaceAll('2月', 'فبراير|')
    .replaceAll('3月', 'مارس|')
    .replaceAll('4月', 'أبريل|')
    .replaceAll('5月', 'مايو|')
    .replaceAll('6月', 'يونيو|')
    .replaceAll('7月', 'يوليو|')
    .replaceAll('8月', 'أغسطس|')
    .replaceAll('9月', 'سبتمبر|')
    .replaceAll('10月', 'أكتوبر|')
    .replaceAll('日', '')
    .split('|')
    .reverse()
    .join(' ')
  return desc
}
