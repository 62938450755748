import config from '@/config'
import judgeWhiteUid from '@/utils/white-user'
import { preProdHosts } from '@sub/utils/env'

import { install as ActivityTools } from '@sub/utils/eruda-plugins/activity-tools'
import { getQuery1 } from '@/utils/utils'
import { IS_APP } from '@/utils/env'
import actInfo from '@sub/store/common/act-info'

export async function install() {
  // 线上环境时，白名单才初始化
  if (preProdHosts.includes(window.location.host) || config.$env.PROD) {
    const isWhite = await judgeWhiteUid()
    actInfo.setState('isWhite', isWhite)
    if (isWhite) {
      await init()
    }
    return
  }

  // 非线上环境，默认初始化
  if (!config.$env.PROD) {
    await init()
  }
}

async function init() {
  // mac 电脑下不初始化 eruda
  const { debug } = getQuery1()
  // mac 电脑下默认不初始化 eruda
  if (debug !== 'true' && !IS_APP) {
    return
  }

  const [{ default: VConsole }, { default: erudaMonitor }, { default: erudaTiming }] = await Promise.all([import('vconsole'), import('eruda-monitor'), await import('eruda-timing')])

  // const { default: VConsole } = await import('vconsole')
  // const { default: erudaMonitor } = await import('eruda-monitor')
  // const { default: erudaTiming } = await import('eruda-timing')

  // eruda 的 network 功能不是很好用
  var vConsole = new VConsole()
  var autoSetOverflowPlugin = new VConsole.VConsolePlugin('auto_set_overflow', '自动设置滚动')
  autoSetOverflowPlugin.on('showConsole', function () {
    document.body.style.overflow = 'hidden'
  })
  autoSetOverflowPlugin.on('hideConsole', function () {
    document.body.style.overflow = null
  })
  vConsole.addPlugin(autoSetOverflowPlugin)

  // 初始化 eruda
  var script = document.createElement('script')
  script.src = '//cdn.jsdelivr.net/npm/eruda'
  document.body.appendChild(script)
  script.onload = function () {
    // 引入插件，自定义初始化时插件的顺序
    window.eruda.ActivityTools = ActivityTools
    window.eruda.init({
      tool: ['console', 'elements', 'ActivityTools', 'resources', 'info', 'snippets']
    })
    window.eruda.add(erudaMonitor)
    window.eruda.add(erudaTiming)
  }
}
